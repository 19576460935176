export default {
    "Learn how it works": "Aprender cómo funciona",
    "Buy parcel": "Comprar parcela",
    "Wallets": "Carteras",
    "To GymNetwork": "Ir a GymNetwork",
    "Connect Wallet": "Conectar cartera",
    "Pending rewards": "Recompensas pendientes",
    "Coming Soon": "Próximamente",
    "Claim": "Reclamar",
    "Inventory": "Inventario",
    "Lands": "Terrenos",
    "Miners": "Mineros",
    "Attach": "Vincular",
    "Attach to parcel": "Vincular a parcela",
    "Investment": "Inversión",
    "Wallet": "Cartera",
    "Your address": "Tu dirección",
    "History": "Historial",
    "Map": "Mapa",
    "Buy miner": "Comprar minero",
    "Buy Miner": "Comprar Minero",
    "Get ": "Obtener ",
    "Get Miner": "Obtener Minero",
    "Upgrade parcel": "Mejorar parcela",
    "Soon": "Pronto",
    "Upgraded":"Mejorado",
    "Upgraded land has 10 slots for putting miner NFTs. Upgraded land does not have more physical space, it’s only that you can stake more miners.": "Los terrenos mejorados tienen 10 ranuras para poner mineros NFT. Estos terrenos no tienen más espacio físico, sino que se puede hacer staking con más mineros.",
    "Next": "Siguiente",
    "Back": "Regresar",
    "Number of miners you want to buy": "Número de mineras duales que quieres comprar",
    "Attach Miner": "Vincular Minero",
    "Upgrade for": "Mejora para",
    "On Sale": "A la venta",
    "Type": "Tipo",
    "Location": "Ubicación",
    "Size": "Tamaño",
    "Buy now": "Comprar ahora",
    "Email": "Email",
    "Username": "Usuario",
    "Verify Email": "Verificar Email",
    "Index": "Índice",
    "Verification Code": "Código de verificación",
    "Log out": "Cerrar sesión",
    "Select all": "Seleccionar todo",
    "Cart": "Carrito",
    "Total": "Total",
    "Register": "Registro",
    "Dashboard": "Panel",
    "Transaction success!": "¡Transacción exitosa!",
    "I`ve read Terms & Conditions": "He leído los Términos y Condiciones",
    "Terms & conditions": "Términos y Condiciones",
    "Affiliate Terms & Conditions": "Términos y Condiciones para afiliados",
    "Accept Terms & Conditions": "Aceptar Términos y Condiciones",
    "Loading! Please Wait ...": "¡Cargando! Por favor, espera...",
    "Initializing Wallet! Please Wait ...": "¡Inicializando Cartera! Por favor, espera...",
    "Login In Progress! Please Wait ...": "¡Inicio de sesión en progreso! Por favor, espera...",
    "Enter your email": "Ingresa tu email",
    "Confirm your Email": "Confirmar tu email",
    "Enter username": "Ingresar usuario",
    "To dashboard": "Al dashboard",
    "You successfully verified your email": "Has verificado correctamente tu email",
    "View map": "Ver mapa",
    "Log in to buy": "Inicie sesión para comprar",
    "Available miners": "Mineros disponibles",
    "Congratulations": "Felicidades",
    "Buy": "Comprar",
    "Miner": "Minero",
    "Parcel": "Parcela",
    "Upgrade": "Mejora",
    "Load more": "Cargar más",
    "All": "Todo",
    "Basic": "Básico",
    "Activate": "Activar",
    "Basic Miner": "Minero básico",
    "In use": "En uso",
    "Expired": "Caducado",
    "Available": "Disponible",
    "Standard parcel": "Parcela estándar",
    "Success": "Éxito",
    "Something Went Wrong": "Algo salió mal",
    "Loading": "Cargando",
    "Invalid Verification Code": "Código de verificación inválido",
    "Invalid Email Address": "Dirección de email inválida",
    "Invalid Arguments Supplied": "Argumentos no válidos suministrados",
    "Email Already Exists": "El email ya está en uso",
    "UnAuthorized": "Sin autorización",
    "User Update Failed": "Error en actualización del usuario",
    "Permission Denied": "Permiso denegado",
    "Basic miner": "Minero básico",
    "Remove": "Eliminar",
    "Special offers": "Ofertas especiales",
    "Please Wait": "Por favor, espera",
    "Transaction Rejected": "Transacción rechazada",
    "sold": "vendido",
    "The parcel price will be increased after certain percentage of parcels will be sold. Bundle prices will be increased accordingly.": "El precio de la parcela subirá después de que se venda un determinado porcentaje de parcelas. Los precios de los paquetes también subirán proporcionalmente.",
    "Sold": "Vendido",
    "Parcel price index": "Índice de precio de parcela",
    "Current parcel price": "Precio actual de parcela",
    "Parcels sold": "Parcelas vendidas",
    "Parcel stats": "Estadísticas de la parcela",
    "Help center": "Centro de ayuda",
    "Partner area": "Área de partners",
    "Transaction Success": "Transacción exitosa",
    "parcels available": "parcelas disponibles",
    "Apply": "Aplicar",
    "Ocean": "Océano",
    "Near Ocean": "Cerca del océano",
    "Insufficient USDT amount": "Cantidad insuficiente de USDT",
    "Insufficient {asset} amount": "Cantidad insuficiente de {asset}",
    "Miner Rewards": "Recompensas de los mineros",
    "No data found": "No se han encontrado datos",
    "Full": "Lleno",
    "Standard": "Estándar",
    "Attach miners": "Vincular mineros",
    "Upgraded Parcel": "Parcela mejorada",
    "Discount": "Descuento",
    "save": "guardar",
    "Copied": "Copiado",
    "Marketplace": "Marketplace",
    "Contract Address": "Dirección del contrato",
    "Wallet Address": "Dirección de la billetera",
    "Inland": "Interior",
    "Business": "Negocio",
    "parcels": "paquetes",
    "parcel": "paquete",
    "Parcel name": "Nombre del paquete",
    "Basic Bundle": "Paquete básico",
    "4 Parcels Bundle": "Paquete de 4 paquetes",
    "10 Parcels Bundle": "Paquete de 10 paquetes",
    "40 Parcels Bundle": "Paquete de 40 paquetes",
    "80 Parcels Bundle": "Paquete de 80 paquetes",
    "140 Parcels Bundle": "Paquete de 140 paquetes",
    "240 Parcels Bundle": "Paquete de 240 paquetes",
    "4 Basic Miner Bundle": "Paquete de 4 mineros",
    "10 Basic Miner Bundle": "Paquete de 10 mineros",
    "40 Basic Miner Bundle": "Paquete de 40 mineros",
    "80 Basic Miner Bundle": "80 Paquete de minero",
    "140 Basic Miner Bundle": "140 Paquete de minero",
    "240 Basic Miner Bundle": "240 Paquete de minero",
    "Hashpower": "Hashpower",
    "Something went wrong, please refresh the page and try again": "Algo salió mal, por favor actualice la página e intente nuevamente",
    "Gallery": "Galería",
    "Accepted": "Aceptado",
    "Pending": "Pendiente",
    "Upload": "Subir",
    "Uploading image": "Subiendo imagen",
    "Something went wrong, try again later": "Algo salió mal, intente nuevamente más tarde",
    "You need to be authenticated to upload images to Gallery.": "Debe estar autenticado para cargar imágenes a la Galería.",
    "Assign image": "Asignar imagen",
    "Assign image to parcels": "Asignar imagen a parcelas",
    "Remove current image": "Eliminar la imagen actual",
    "Change image": "Cambiar imagen",
    "Assign to parcels": "Asignar a parcelas",
    "Reselect image assignment area?": "¿Redestar el área de asignación de imágenes?",
    "You have not applied changes. Do you want to reselect image assignment area?": "No ha aplicado cambios.¿Quieres volver a seleccionar el área de asignación de imágenes?",
    "Image has been successfully changed": "La imagen ha cambiado con éxito",
    "Image has been successfully assigned to parcels": "La imagen ha sido asignada con éxito a las parcelas",
    "Image has been successfully removed from the parcels": "La imagen ha sido eliminada con éxito de las parcelas",
    "Yes": "Sí",
    "No": "no",
    "Cancel": "Cancelar",
    "You have no images": "No tienes imágenes",
    "Notifications": "Notifications",
    "No notifications": "No notifications",
    "years ago": "Notificaciones",
    "year ago": "No Notificaciones",
    "months ago": "hace años que",
    "month ago": "Hace años",
    "weeks ago": "Hace meses",
    "week ago": "hace un mes",
    "days ago": "hace semanas",
    "day ago": "hace una semana",
    "hours ago": "hace días",
    "hour ago": "hace un dia",
    "minutes ago": "horas atras",
    "minutes": "hace una hora",
    "minute": "hace minutos",
    "seconds": "minutos",
    "second": "minuto",
    "Your image was approved": "Tu imagen fue aprobada",
    "Your image was rejected": "Tu imagen fue rechazada",
    "New": "Nueva",
    "Gallery guide" : "Guía de la galería",
    "Skip guide": "Guía de salto",
    "Go to gallery": "Ir a la galería",
    "How it works": "Cómo funciona",
    "Removing image from parcels..." : "Eliminar la imagen de las parcelas ...",
    "Removing image" : "Eliminación de la imagen",
    "Image has been successfully removed" : "La imagen se ha eliminado con éxito",
    "Wrong file size" : "Tamaño de archivo incorrecto",
    "Please upload image files with size less than 10MB": "Cargue archivos de imagen con un tamaño de menos de 10 MB",
    "Drag to Reposition": "Arrastrar a la reposicionamiento",
    "Pinch or use the mouse wheel to zoom in or out": "Pellizcar o usar la rueda del mouse para acercar o salir",
    "Drag and move the image with fingers or using your mouse to select area": "Arrastre y mueva la imagen con dedos o use su mouse para seleccionar área",
    "It can take up to 48 hours for the image to be approved. You can view your images awaiting approval in the Pending tab.": "La imagen puede tardar hasta 48 horas.Puede ver sus imágenes en espera de aprobación en la pestaña pendiente.",
    "Delete Image": "Eliminar Imagen",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable. Land containing this image will also be cleared.": "¿Estás seguro de que quieres eliminar permanentemente la imagen de tu galería?Esta operación no es recuperable.La tierra que contenga esta imagen también será limpiada.",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable." : "¿Estás seguro de que quieres eliminar permanentemente la imagen de tu galería?Esta operación no es recuperable.",
    "Wrong File Type": "Tipo de archivo incorrecto",
    "Please upload jpg/jpeg image files": "Sube archivos de imagen JPG/JPEG",
    "Upload Images to Your Gallery": "Sube imágenes en tu galería",
    "You can upload images to your gallery from your computer, mobile phone or tablet. Currently only jpg/jpeg image file types are supported. Press the upload button or simply drag and drop the image from computer to upload your image. Image size must be up to 10 MB." : "Puede cargar imágenes en su galería desde su computadora, teléfono móvil o tableta.Actualmente, solo se admiten los tipos de archivos de imagen JPG/JPEG.Presione el botón de carga o simplemente arrastre y suelte la imagen de la computadora para cargar su imagen.El tamaño de la imagen debe ser de hasta 10 MB.",
    "Upload image": "Cargar imagen",
    "Image Approval": "Aprobación de la imagen",
    "Uploaded images need to be approved before they appear in the approved section of your gallery. This procedure is necessary to filter out inappropriate, explicit content and copyrighted material. Approval can take up to 48 hours. You'll get notified about the verdict whenever you're online." : "Las imágenes cargadas deben ser aprobadas antes de que aparezcan en la sección aprobada de su galería.Este procedimiento es necesario para filtrar contenido inapropiado y explícito y material con derechos de autor.La aprobación puede demorar hasta 48 horas.Te notificarán sobre el veredicto cada vez que esté en línea.",
    "Approval": "Aprobación",
    "Select Your Land to Assign Image to": "Seleccione su tierra para asignar imagen a",
    "You can assign your uploaded images to your owned land. To do so, simply select the parcels by dragging mouse on the land part you want to assign an image to. Please note that image can only be assigned to a rectangular area that is owned by you only." : "Puede asignar sus imágenes cargadas a su tierra de propiedad.Para hacerlo, simplemente seleccione las parcelas arrastrando el mouse en la parte terrestre a la que desea asignar una imagen.Tenga en cuenta que la imagen solo se puede asignar a un área rectangular que solo es propiedad de usted.",
    "Land Selection": "Selección de tierras",
    "Crop Image": "Delimitar imagen",
    "You can crop a custom part of your image that you want to assign to your land section. You can close in to any area of your image and crop it by dragging the image with mouse or your finger if you're on a mobile device. You can also zoom in and out by using mouse wheel or pinch gesture." : "Puede recortar una parte personalizada de su imagen que desea asignar a su sección de tierras.Puede cerrar a cualquier área de su imagen y recortarlo arrastrando la imagen con el mouse o su dedo si está en un dispositivo móvil.También puede acercarse y salir usando el ratón o el gesto de pellizco.",
    "Activate multiple selection": "Activar selección múltiple",
    "Exit multiple selection": "Salir selección múltiple",
    "Owned": "Propietaria",
    "Owned by": "Propiedad de",
    "Multiple types": "Múltiples tipos",
    "seconds ago": "hace segundos",
    "Super Bundle": "Súper paquete",
    "Balance": "Balance",
    "View your purchased Bundles and claim to have them from your balance": "Vea sus paquetes comprados y reclame tenerlos de su saldo",
    "Feel free to choose lands on Map.": "Siéntase libre de elegir tierras en el Mapa.",
    "Earn money by using miners for generating rewards.": "Gana dinero usando mineros para generar recompensas.",
    "Upgrade your owned lands from 4 slots to 10.": "Mejora las tierras de tu propiedad de 4 ranuras a 10.",
    "Confirm": "Confirmar",
    "You don`t have any available parcels in your inventory": "No tienes paquetes disponibles en tu inventario",
    "Months": "Meses",
    "Super Bundle R6": "Súper Paquete R6",
    "Super Bundle R10": "Súper Paquete R10",
    "Super Bundle R15": "Súper Paquete R15",
    "Super Bundle R20": "Súper Paquete R20",
    "Super Bundle R22 S": "Súper Paquete R22 S",
    "Super Bundle R22 M": "Súper Paquete R22 M",
    "Super Bundle R22 L": "Súper Paquete R22 L",
    "Super Bundle R22 XL": "Súper Paquete R22 XL",
    "Super Bundle R22 XXL": "Súper Paquete R22 XXL",
    "1 Claim your land": "1 Reclama tu tierra",
    "2 Claim your miner": "2 Reclama tu minero",
    "3 Upgrade your land": "3 Mejora tu tierra",
    "Your NFT balance": "Su saldo NFT",
    "Next price changes in": "Próximos cambios de precio en",
    "Parcels": "Paquetes",
    "Get to know the platform usage": "Conozca el uso de la plataforma",
    "What is a Parcel?": "¿Qué es un paquete?",
    "Land is a unit of area on the GYM Street map and it is also an NFT. You can own as much land on GYM Street as you want.": "Land es una unidad de área en el mapa de GYM Street y también es un NFT. Puede poseer tanto terreno en GYM Street como desee.",
    "What is a Miner?": "¿Qué es un minero?",
    "A miner is what generates income for you at GYM Street. You can attach up to 4 miners on each of your standard land parcels.": "Un minero es lo que te genera ingresos en GYM Street. Puede adjuntar hasta 4 mineros en cada una de sus parcelas de tierra estándar.",
    "Miner rewards": "Recompensas de minero",
    "My Miners": "Mis mineros",
    "My Hashpower": "Mi Hashpower",
    "Total Claimed": "Total reclamado",
    "Total pending rewards": "Total de recompensas pendientes",
    "Claim all": "reclamar todo",
    "Daily rewards": "Recompensas diarias",
    "Weekly rewards": "Recompensas semanales",
    "Monthly rewards": "Recompensas mensuales",
    "Yearly rewards": "Recompensas anuales",
    "ROI": "ROI",
    "ROI Calculator": "Calculadora de ROI",
    "Global Statistics": "Estadísticas globales",
    "Global Hashpower": "Global Hashpower",
    "Your Shares": "Tus acciones",
    "Daily Global Rewards": "Recompensas globales diarias",
    "Price": "Precio",
    "Total Minted": "Total acuñado",
    "total": "total",
    "standard": "estándar",
    "upgraded": "actualizado",
    "active": "activo",
    "inactive": "inactivo",
    "Attach miner": "Adjuntar minero",
    "Total Miners": "Mineros totales",
    "Total Minted Rewards": "Recompensas acuñadas totales",
    "What is parcel and how to use it?": "Una parcela es una unidad de área en el mapa {metaverse} equipada con capacidades de acuñación. Poseer Parcelas y generar ingresos.",
    "What is a miner and how to use it?": "El Minero es tu generador de ingresos en {metaverso}. Mejore sus ganancias adjuntando mineros a su parcela. <br/> <br/> GYMNET Miner y Dual Miner operan a una velocidad de minería de 1.000 Mh/s.",
    "Your active/inactive hashpower, 1 miner = 1,000 Mh/s, your Miners 0 x 1,000Mh/s = 0.00": "Tu activo/inactivo hashpower, 1 minero = 1000 Mh/s, tus mineros 0 x 1000 Mh/s = 0,00",
    "The amount of the cumulated claimed rewards": "El monto de las recompensas reclamadas acumuladas",
    "Your current available pending rewards.": "Sus recompensas pendientes disponibles actualmente.",
    "Congratulations!": "¡Felicidades!",
    "Upgrade your parcels to mine even more?": "¿Actualizar tus paquetes a los míos aún más?",
    "You have NUM parcels that are not upgraded.": "Tienes {parcels} parcelas que\n no están actualizadas.",
    "Go to Map": "Ir al mapa",
    "You're mining less than the average user.": "Estás minando menos que el usuario promedio.",
    "Purchase more miners to improve your results": "Compra más mineros para mejorar tus resultados",
    "Buy more miners": "Compra más mineros",
    "Thanks, I'll stay below average": "Gracias, me mantendré por debajo del promedio.",
    "You aren't mining at full speed.": "No estás minando a toda velocidad.",
    "Do you want to buy more parcels now ?": "¿Quieres comprar más paquetes ahora?",
    "I definitely do": "definitivamente lo hago",
    "No, I'll let my miners to rust": "No, dejaré que mis mineros se oxiden",
    "You have no miners": "no tienes mineros",
    "Buy miners": "Comprar mineras duales",
    "You haven't upgradeable parcels": "No tienes parcelas actualizables",
    "You haven't inactive miners": "No tienes mineros inactivos",
    "Close": "Cerca",
    "Read more": "Lee mas",
    "Drag and drop an image, or Browse": "Arrastra y suelta una imagen, o <label class=\"browse-button\" for=\"upload-image\">Explorar</label>",
    "Browse an image": "<label class=\"browse-button\" for=\"upload-image\">Explorar</label> una imagen",
    "Drop to upload": "Caer a la carga",
    "File not supported": "Archivo no compatible",
    "Upload your parcel image": "Sube tu imagen de paquete",
    "Up to 10MB, jpg/jpeg": "Hasta 10 MB, JPG/JPEG",
    "High resolution images, should align with your land shape (you can crop after upload)": "<span class=\"bold-info\">Imágenes de alta resolución,</span> deben alinearse con la forma de su terreno (puede recortar después de cargar)",
    "Same image may be used on different lands owned by the user": "<span class=\"bold-info\">La misma imagen puede ser utilizada en</span> diferentes terrenos propiedad del usuario",
    "No violence, offensive, graphic or 18+ content": "Sin violencia, contenido ofensivo, gráfico o <span class=\"bold-info\">para mayores de 18 añost</span>",
    "No third-party copyrighted content, no logos or screenshots from Gymstreet and Gymnetwork": "<span class=\"bold-info\">Sin contenido con derechos de autor de terceros,</span> sin logotipos ni capturas de pantalla de <span class=\"bold-info\">Gymstreet y Gymnetwork</span>",
    "No images already used by another user, no images from DeFi listing website images": "<span class=\"bold-info\">No hay imágenes ya utilizadas por otro usuario,</span> no hay imágenes de DeFi que incluyan imágenes del sitio web",
    "No religious images": "<span class=\"bold-info\">Imágenes no religiosas</span>",
    "Unlock miners by purchasing at least 1 land parcel": "Desbloquea mineras comprando al menos 1 parcela de tierra",
    "OK": "OK",
    "Your parcels": "Tus parcelas",
    "Your miners": "Tus mineros",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcels to start mining": "No está minando a toda velocidad, tiene {miners} minero que debe adjuntarse a sus paquetes para comenzar a minar",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcel to start mining": "No está minando a toda velocidad, tiene {miners} minero que debe adjuntarse a su paquete para comenzar a minar",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcels to start mining": "No está minando a toda velocidad, tiene {miners} mineros que deben adjuntarse a sus paquetes para comenzar a minar",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcel to start mining": "No está minando a toda velocidad, tiene {miners} mineros que deben adjuntarse a su paquete para comenzar a minar",
    "Info Center": "Centro de información",
    "Standard Parcel": "Paquete estándar",
    "You have no parcels": "no tienes paquetes",
    "Single Products": "Productos individuales",
    "Parcels sold / Total parcels": "Parcelas vendidas / Parcelas totales",
    "You can mine even more. Purchase more miners to improve your results": "Puedes minar aún más. Compra más mineros para mejorar tus resultados",
    "In Progress": "En progreso",
    "Active": "Activo",
    "Learn More": "Aprende más",
    "Add to wallet": "Agregar a la billetera",
    "Your Balance": "Tu balance",
    "The Promotion starts in:": "La Promoción comienza en:",
    "Please go to your wallet, which you just connected, and verify your login by confirming the requested transaction. It's free of charge!": "Vaya a su billetera, que acaba de conectar, y verifique su inicio de sesión confirmando la transacción solicitada. ¡Es gratis!",
    "Contract addresses": "Direcciones de contrato",
    "Add TOKEN to wallet": "Agregar {token} a la billetera",
    "I accept the terms and conditions": "Acepto los términos y condiciones",
    "Log In": "Acceso",
    "Sign Up": "Inscribirse",
    "Connect My Wallet": "Conectar mi billetera",
    "Change Email": "Cambiar e-mail",
    "Reset Password": "Restablecer la contraseña",
    "Your password has been changed.": "Tu contraseña ha sido cambiada.",
    "Please log in to your account.": "Por favor, ingrese a su cuenta.",
    "Confirm Email": "Confirmar correo electrónico",
    "Emails don't match": "Los correos electrónicos no coinciden",
    "Or": "O",
    "Sign Up With Email": "Ingresa con e-mail",
    "Already have an account?": "¿Ya tienes una cuenta?",
    "Recover": "Recuperar",
    "Enter New Password": "Introduzca nueva contraseña",
    "Confirm New Password": "Confirmar nueva contraseña",
    "Password": "Clave",
    "Forgot password?": "¿Se te olvidó tu contraseña?",
    "Password is required": "Se requiere contraseña",
    "Password confirmation is required": "Se requiere confirmación de contraseña",
    "Passwords do not match": "Las contraseñas no coinciden",
    "Password must contain at least one uppercase letter, one lowercase letter and one number": "La contraseña debe contener al menos una letra mayúscula, una letra minúscula y un número",
    "Password must be at least 8 characters": "La contraseña debe tener al menos 8 caracteres",
    "Password must not exceed 24 characters": "Las contraseñas no deben superar los 24 caracteres",
    "Password must contain at least one special character": "La contraseña debe contener al menos un personaje especial",
    "Password cannot contain spaces": "La contraseña no puede contener espacios",
    "Email is required": "Correo electronico es requerido",
    "Email confirmation is required": "Se requiere confirmación por correo electrónico",
    "Please enter a valid email": "Por favor introduzca una dirección de correo electrónico válida",
    "Step NUM1 of NUM2": "Paso {num1} de {num2}",
    "Create Password": "Crear contraseña",
    "Get Started": "Empezar",
    "Create a Wallet": "Crear una billetera",
    "Confirm on this screen": "Confirmar en esta pantalla",
    "Pick a password on the next step. This needs to be at least 8 symbols long.": "Elija una contraseña en el siguiente paso. Esto debe tener al menos 8 símbolos de largo.",
    "Wallet Ready": "Listo para la billetera",
    "Add Binance Smart Chain": "Agregar Binance Smart Chain",
    "Click on the button below to add Binance Smart Chain Network to your MetaMask wallet.": "Haga clic en el botón a continuación para agregar Binance Smart Chain Network a su billetera MetaMask.",
    "Confirm the connection request": "Confirmar la solicitud de conexión",
    "Get Started!": "¡Empezar!",
    "Secure Wallet": "Monedero seguro",
    "Confirm your password to continue.": "Confirma tu contraseña para continuar.",
    "You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.": "Lo necesitará para recuperar su billetera si pierde el acceso a su computadora, y debe guardarlo en un lugar seguro. Cualquiera que tenga acceso a su frase de respaldo de 12 palabras podrá recuperar sus fondos, así que manténgalo en secreto.",
    "You have successfully set up Your wallet.Welcome to the world of WEB3!": "Has configurado correctamente tu monedero.\n¡Bienvenido al mundo de WEB3!",
    "Confirm Password": "Confirmar contraseña",
    "Install here.": "Instalar aquí.",
    "Something went wrong, please try verifying your email again.": "Algo salió mal, intente verificar su correo electrónico nuevamente.",
    "Looks like your password reset link has expired. Please try again.": "Parece que su enlace de reinicio de contraseña ha expirado. Inténtalo de nuevo.",
    "Looks like your password reset link has already been used. Please request it again.": "Parece que su enlace de reinicio de contraseña ya se ha utilizado.Por favor solicite nuevamente.",
    "Couldn't recover password": "No se pudo recuperar la contraseña",
    "Couldn't reset your password.": "No se pudo restablecer su contraseña.",
    "Couldn't login.": "No pudo iniciar sesión.",
    "Couldn't sign you up.": "No pudo inscribirte.",
    "Resend Email": "Reenviar e-mail",
    "Couldn't resend confirmation email.": "No se pudo reiniciar el correo electrónico de confirmación.",
    "Provided email is already in use.": "El correo electrónico proporcionado ya está en uso.",
    "You have already verified your email address": "Ya ha verificado su dirección de correo electrónico",
    "Invalid or expired verification code": "Código de verificación no válido o caducado",
    "An error occurred while logging in. Please try again.": "Se produjo un error al iniciar sesión. Vuelva a intentarlo.",
    "We sent an email to {EMAIL} with a link to finalize your registration": "Enviamos un correo electrónico a {EMAIL} con un enlace para finalizar su registro",
    "We sent an email to {EMAIL} with a link to change your password": "Enviamos un correo electrónico a {EMAIL} con un enlace para cambiar su contraseña",
    "Referral ID": "Remisión ID",
    "On the next step, tap on 'Create a wallet'.": "En el siguiente paso, toque \n\"Create a wallet\".",
    "On the next step, tap on 'Create a new wallet'.": "En el siguiente paso, toque \n\"Create a new wallet\".",
    "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower case letters, symbols and numbers.": "Recomendamos utilizar una contraseña completamente única que no se haya utilizado en ningún otro lugar y que contenga una combinación de letras mayúsculas y minúsculas, símbolos y números.",
    "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.": "Ordena las palabras memorizadas en el paso anterior. Toque el botón \"Complete\" cuando haya terminado con la copia de seguridad.",
    "On this step you can choose. We recommend you do this now as you might otherwise lose access to your wallet. Tap on 'Start' to continue.": "En este paso puedes elegir. \nLe recomendamos que haga esto ahora, ya que de lo contrario podría perder el acceso a su billetera. \nToca \"Start\" para continuar.",
    "On this step tap on 'Start' to continue.": "En este paso, toca \"Start\" \npara continuar.",
    "Tap on the burger menu of the app in the top left corner of the screen.": "Toque el menú de hamburguesas de la aplicación en la esquina superior izquierda de la pantalla.",
    "Next, tap on Settings in the menu to the left.": "A continuación, toque \"Settings\" en el menú de la izquierda.",
    "Next, tap on Networks.": "A continuación, toque \"Networks\".",
    "Add More Tokens": "Agregar más fichas",
    "In the search field, type in BNB Smart Chain and add it to your network.": "En el campo de búsqueda, escriba \"BNB Smart Chain\" y agréguelo a su red.",
    "Next, select Custom Token. Paste the address of the smart contract you copied into the token address field. In the Token Symbol field, type SP, and in the Token Decimal, put 0. Then click the 'Import' button to complete your setup.": "A continuación, seleccione \"Custom Token\". Pegue la dirección del \"smart contract\" que copió en el campo de dirección del token. En el campo \"Token Symbol\", escriba \"SP\", y en \"Token Decimal\", coloque 0. Luego haga clic en el botón \"Import\" para completar su configuración",
    "Add Network": "Agregar red",
    "Give permission to the platform to add a network.": "Dar permiso a la plataforma para agregar una red.",
    "Extra PERCENT% Discount": "{PERCENT}% de descuento adicional",
    "Tap on the 'Import Tokens' link to add a Custom Token.": "Toque el enlace \"Import Tokens\" para agregar un token personalizado.",
    "Click on the button 'Approve' to allow the connection request.": "Haga clic en el botón \"Approve\" para permitir la solicitud de conexión.",
    "Confirm Secret Phrase": "Confirmar frase secreta",
    "Click on the button 'Switch network' to allow the connection request.": "Haga clic en el botón \"Switch network\" para permitir la solicitud de conexión.",
    "Payment Method": "Método de pago",
    "Select cryptocurrency": "Seleccionar criptomoneda",
    "Please use an Ethereum BEP-20 address.": "Utilice una dirección Ethereum BEP-20.",
    "Connect to Gymstreet": "Conéctese a Gym Street",
    "Now to access the Gym Street Metaverse, you need to open it with the MetaMask browser. Go to your MetaMask wallet app, choose 'Browser' from the side navigation menu, search for Gym Street in the browser tab.": "Ahora, para acceder al Gym Street Metaverse, debe abrirlo con el navegador MetaMask. Vaya a su aplicación de billetera MetaMask, elija \"Browser\" en el menú de navegación lateral, busque Gym Street en la pestaña del navegador.",
    "First, let's set up your cryptocurrency wallet.": "Primero, configuremos su billetera de criptomonedas.",
    "Follow the next steps to continue the setup.": "Siga los siguientes pasos para continuar con la configuración.",
    "Your balance was updated": "Tu saldo fue actualizado",
    "Once installed, you should see the below splash screen. Click the 'Get Started' button to begin creating your Ethereum wallet using MetaMask.": "Una vez instalado, debería ver la siguiente pantalla de inicio. Haga clic en el botón \"Get Started\" para comenzar a crear su billetera Ethereum usando MetaMask.",
    "You will then be asked if you want to help improve MetaMask. Click 'No, Thanks' if this doesn't interest you, otherwise click 'I agree'.": "Luego se le preguntará si desea ayudar a mejorar MetaMask. Haga clic en \"No, Thanks\" si esto no le interesa, de lo contrario, haga clic en \"I agree\".",
    "MetaMask will then present you with your 12-word backup phrase. You will need to write this down in the same order displayed on your screen.": "MetaMask luego le presentará su frase de respaldo de 12 palabras. Deberá escribir esto en el mismo orden que se muestra en su pantalla.",
    "After Downloading and opening MetaMask, tap on 'Get Started'.": "Después de descargar y abrir MetaMask, toque \"Get Started\".",
    "Improve Metamask": "Mejorar MetaMask",
    "Congratulations! You have set up your MetaMask wallet. Confirm the next few pop-ups on the screen to access your wallet.": "¡Felicidades! Ha configurado su billetera MetaMask. Confirme las siguientes ventanas emergentes en la pantalla para acceder a su billetera.",
    "Import Binance Smart Chain": "Importar Binance Smart Chain",
    "Next, copy the NFT Smart Contract by tapping on the button below.": "A continuación, copie el contrato inteligente de NFT tocando el botón de abajo.",
    "Copy NFT Contract": "Copie NFT Contract",
    "Install MetaMask": "Instalar MetaMask",
    "Install MetaMask here.": "Instale MetaMask aquí.",
    "Guide for MetaMask Wallet connection process.": "Guía para el proceso de conexión de MetaMask Wallet.",
    "MetaMask will then present you with your 12-word backup phrase.": "MetaMask luego le presentará su frase de respaldo de 12 palabras.",
    "MetaMask Secret Phrase": "Frase secreta de MetaMask",
    "Copy NFT Smart Contract": "Copie NFT Smart Contract",
    "You are almost finished with the MetaMask setup process. Just click 'All done' on the last page, and you will be automatically logged into MetaMask. If you ever log out, you can log back in by clicking on the icon added to your web browser (usually, it's next to the URL bar).": "Ya casi ha terminado con el proceso de configuración de MetaMask. Simplemente haga clic en \"All done\" en la última página, e iniciará sesión automáticamente en MetaMask. Si alguna vez cierra la sesión, puede volver a iniciar sesión haciendo clic en el ícono agregado a su navegador web (generalmente, está al lado de la barra de URL).",
    "Your payment processing is complete. Your NFT balance has been updated!": "Su procesamiento de pago está completo. ¡Su saldo NFT ha sido actualizado!",
    "Your payment is received but is incomplete. Please pay AMOUNT CURRENCY more to complete the payment.": "Se recibió su pago pero está incompleto. Pague {amount} {currency} más para completar el pago.",
    "Terms & Conditions": "Términos y condiciones",
    "Email Verification": "verificacion de email",
    "Transaction history": "Historial de transacciones",
    "Order history": "Historial de Pedidos",
    "Product": "Producto",
    "Quantity": "Cantidad",
    "Payment UID": "UID de pago",
    "Status": "Estado",
    "Currency": "Moneda",
    "Autoship":"Autoenvío",
    "Amount": "Cantidad",
    "Date": "Fecha",
    "Tx ID": "Tx ID",
    "Completed": "Terminada",
    "MetaMask is not installed": "MetaMask no está instalado",
    "If you have already installed MetaMask, please": "Si ya ha instalado {MetaMask}, por favor",
    "refresh page": "actualizar página",
    "to continue.": "continuar.",
    "You have successfully installed MetaMask. Now you can add a Network.": "Ha instalado correctamente MetaMask. Ahora puede agregar una red.",
    "You already have this network": "Ya tienes esta red",
    "Continue": "Continuar",
    "Finalize Registration": "Finalizar registro",
    "Please confirm your email to continue": "Confirme su correo electrónico para continuar",
    "New email must be different from current email": "El nuevo correo electrónico debe ser diferente del correo electrónico actual",
    "Actions": "Comportamiento",
    "Request to change the chain has been rejected": "Solicitud de cambiar la cadena ha sido rechazada",
    "Switch Chain": "Cadena de interruptor",
    "Choose your Metaverse": "Elige tu Metaverso",
    "You need to choose your Metaverse to continue. Maps, Dashboard and My Products information will vary depending on your selection. Currently {METAVERSE} Metaverse is selected.": "Debes elegir tu Metaverso para continuar. La información de Mapas, Panel de control y Mis productos variará según su selección. Actualmente {METAVERSE} Metaverso está seleccionado.",
    "Can't check your purchases": "No puedo verificar sus compras",
    "Attach {COUNT} miners": "Adjuntar {COUNT} mineros",
    "Attach 1 miner": "Adjuntar 1 minero",
    "View on map": "Ver en el mapa",
    "Buy {COUNT} parcels": "Comprar {COUNT} paquetes",
    "Buy 1 parcel": "Compra 1 paquete",
    "Buy {COUNT} miners": "Compre {COUNT} mineros duales",
    "Buy 1 miner": "Comprar 1 minera doble",
    "Metaverses": "Metaversos",
    "My NFTs": "Mis NFT",
    "Total Hashpower (Mh/s)": "Hashpower total (Mh/s)",
    "Available Miner Slots": "Ranuras de minero disponibles",
    "Average ROI past 9 months": "ROI promedio de los últimos 9 meses",
    "Login Via Crypto Wallet": "Iniciar sesión a través de Crypto Wallet",
    "Land reserved for PARTNER": "Terreno reservado para {PARTNER}",
    "About PARTNER": "Sobre {PARTNER}",
    "swap": "intercambio",
    "transfer": "transferir",
    "approve": "aprobar",
    "max": "máximo",
    "The minimum amount to claim is N": "La cantidad mínima para reclamar es {n}",
    "paginationText": "Mostrando {from} - {to} de {count}",
    "turnovers": "Volumen de negocios",
    "Total partners": "Total de socios",
    "refreshData": "Actualizar datos",
    "NFT Turnover": "NFT Rotación",
    "Single Pool Turnover": "Facturación de un solo pool",
    "depth": "Profundidad",
    "turnover": "Rotación",
    "weekly": "Semanalmente",
    "monthly": "Mensual",
    "wallet": "cartera",
    "columns": "Columnas",
    "filters": "Filtros",
    "level": "Nivel",
    "search": "Búsqueda",
    "partners": "Socios",
    "Farming Turnover": "Facturación agrícola",
    "Vault Turnover": "Volumen de negocios de la bóveda",
    "Careers status": "Estado de la carrera",
    "GYMNET Balance": "Saldo GYMNET",
    "Save": "Ahorrar",
    "Swap": "Intercambio ",
    "Turnover pool": "Fondo de facturación",
    "Global Line Bonus": "Bono de línea global",
    "Turnover pools": "Pools de facturación",
    "Tax pools": "Pools de impuestos ({n}mo)",
    "Total qualified turnover": "Facturación total cualificada",
    "My claimed rewards": "Mis recompensas reclamadas",
    "My pending rewards": "Mis recompensas pendientes",
    "Week": "Semana",
    "Month": "Mes",
    "In order to get qualified for any pool, you’ll have to follow the 50% turnover rule: The maximum share each direct partner can contribute to the required turnover is up to 50% of the turnover coming from NFT sales.": "Para calificar para cualquier grupo, deberá seguir la regla del 50% de facturación: la participación máxima que cada socio directo puede contribuir a la facturación requerida es de hasta el 50% de la facturación proveniente de las ventas de NFT.",
    "View more": "Ver más",
    "Turnover progress": "Progreso de la facturación",
    "Thank you for your participation in Global Line Incentive!": "¡Gracias por su participación en Global Line Incentive!",
    "Global line before June 2023": "Línea global antes de junio de 2023",
    "Global line before March 2023": "Línea global antes de marzo de 2023",
    "More info": "Más información",
    "Your Position": "Tu posición",
    "Last Position": "Ultima posicion",
    "My line length": "La longitud de mi línea",
    "Global Line Turnover": "Facturación de línea global",
    "The turnover generated from all participants who joined the Global Line after you.": "La facturación generada por todos los participantes que se unieron a la Línea Global después de usted.",
    "Your purchases": "Tus compras",
    "You need purchases of at least $100 to become a Global Line member.": "Necesita compras de al menos $100 para convertirse en miembro de Global Line.",
    "Your pending income": "Tus ingresos pendientes",
    "Buy more NFTs to increase your shares": "Compre más <strong><i>NFT</i></strong> para aumentar sus <strong>acciones</strong>",
    "New Registrations": "Nuevos Registros",
    "Turnover": "Rotación",
    "Global level": "Nivel global",
    "Joining time": "Tiempo de unión",
    "Global Line Total Turnover": "Gestión total de líneas globales",
    "Global Line end": "Fin de línea global",
    "Please confirm your claim": "Por favor confirme su reclamo",
    "After you confirm, you will receive your rewards on your balance.": "Después de confirmar, recibirá sus recompensas en su saldo.",
    "Select Language": "Seleccionar idioma",
    "Download": "Descargar",
    "Shares": "Comparte",
    "Single Miner": "Minero único",
    "Super Bundles": "Súper Paquetes",
    "Regular Bundles": "Paquetes regulares",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%.": "La participación máxima que cada socio directo puede aportar a la facturación requerida es de hasta el 50%.",
    "Your partners": "Tus compañeras",
    "Generated turnover": "Facturación generada",
    "Qualified turnover": "Facturación cualificada",
    "Generated": "Generada",
    "Qualified": "Calificada",
    "Your partners turnover statistics will appear here.": "Las estadísticas de facturación de sus socios aparecerán aquí.",
    "Pools": "Pools",
    "Qualification": "Calificación",
    "Rewards": "Recompensas",
    "Participants": "Participantes",
    "Total rewards": "Recompensas totales",
    "Rewards per participant": "Recompensas por participante",
    "Land sales contribution": "Contribución por ventas de terrenos",
    "Maintainance contribution": "Contribución de mantenimiento",
    "Distributed": "Distribuido",
    "Refresh data": "Actualizar datos",
    "You will receive your rewards directly on your wallet": "Recibirás tus recompensas directamente en tu billetera",
    "Total claimed": "Total reclamado",
    "Next distribution": "Próxima distribución",
    "Unclaimed BUSD rewards": "Recompensas de BUSD sin reclamar",
    "Required level": "Nivel requerido",
    "NFT sales percent": "Ventas NFT {n}%",
    "Total qualified participants": "Total de participantes calificados",
    "TxID": "TxID",
    "Tx Type": "Tipo de transmisión",
    "Global line reward": "Recompensa de línea global",
    "Your share": "Tu parte",
    "Income before": "Ingresos antes",
    "Income after": "Ingresos después",
    "NFT Income": "Ingresos NFT",
    "NFT Commissions Total": "Total de comisiones NFT",
    "NFT Statistic": "Estadísticas de NFT",
    "Level": "Nivel",
    "User": "Usuario",
    "Your current pin": "Your current pin",
    "Turnover for the next pin": "Volumen de negocios para el siguiente pin",
    "Your next incentive": "Tu próximo incentivo",
    "Your next bonus": "Tu próximo bono",
    "Incentives": "incentivos",
    "Partners with pin qualification": "Socios con {n} pin de calificación",
    "Team of": "Equipo de",
    "Partners": "Socios",
    "Cash Bonus": "Bono en efectivo",
    "Your pin": "Su pin",
    "Incentive": "Incentiva",
    "Pin": "Pin",
    "Level pool": "Nivel Pool",
    "Go to PARTNER": "Ir a {PARTNER}",
    "Partnerships": "Asociación",
    "Cannot find EMAIL_OR_WALLET": "No se puede encontrar {EMAIL_OR_WALLET}",
    "Incentive Ends In: ": "El incentivo <strong>termina en</strong>: ",
    "Your Rewards History": "Tu historial de recompensas",
    "Dubai & Sofia Trip": "Viaje a Dubái y Sofía",
    "No Rewards": "Sin recompensas",
    "No Partners": "No socios",
    "Reward": "Premio",
    "Granted": "Otorgada",
    "Transaction": "Transacción",
    "Free BUNDLE": "Gratis {BUNDLE}",
    "Team Purchases": "Compras en equipo",
    "Direct Sales": "Ventas directas",
    "You have won this reward": "Has ganado esta recompensa",
    "50% Turnover Rule is applied for all incentives!": "¡Se aplica una regla de rotación del 50% para todos los incentivos!",
    "Please note that you have the chance to earn a complimentary R8 Bundle in two different ways: either by reaching 20k in direct sales or by achieving 100k in team purchases.": "Tenga en cuenta que tiene la oportunidad de ganar un paquete R8 de dos maneras diferentes: alcanzando 20k en ventas directas o alcanzando 100k en compras de equipo.",
    "Insufficient funds for gas, please add BNB to your wallet": "Fondos insuficientes para gasolina, agregue BNB a su billetera",
    "Please note that you will require up to AMOUNT worth of CURRENCY to complete the transaction.": "Por favor, tenga en cuenta que necesitará hasta {amount} en {currency} para completar la transacción.",
    "Please note that AMOUNT worth of CURRENCY is required to complete the transaction.": "Tenga en cuenta que se requiere {amount} de {currency} para completar la transacción.",
    "Please note that AMOUNT_CURRENCY is required for gas fee": "Tenga en cuenta que se requiere {amount_currency} para la tarifa de gas",
    "Select a payment method": "Seleccione un método de pago",
    "Use your account's wallet balances to purchase the product.": "Utilice el saldo de la cartera de su cuenta para comprar el producto.",
    "Connect a WEB3 wallet like Metamask or Trustwallet and pay with {currency}. This requires a small amount of BNB ($0.30) to cover the transaction fees!": "Conecte una cartera WEB3 como Metamask o Trustwallet y pague con {currency}. ¡Esto requiere una pequeña cantidad de BNB (0,30 $) para cubrir las tasas de transacción!",
    "Select one of the available blockchains and cryptocurrencies, and proceed with the payment via an external wallet application.": "Seleccione una de las blockchains y criptomonedas disponibles, y proceda al pago a través de una aplicación de cartera externa.",
    "Internal wallet": "Cartera interna",
    "Web3 Wallet": "Cartera Web3",
    "External wallet": "Cartera externa",
    "Select Currency": "Seleccionar la Divisa",
    "Select from the available balances": "Seleccionar entre los saldos disponibles",
    "Purchase for myself": "Comprar para mí",
    "Select Blockchain": "Seleccionar Blockchain",
    "Select Cryptocurrency": "Seleccionar Criptomoneda",
    "Please select another payment method": "Por favor, seleccione otro método de pago",
    "Connect my wallet": "Conectar mi cartera",
    "Select a product owner": "Seleccione un propietario de producto",
    "Please select who you are purchasing for:": "Por favor, seleccione para quién está comprando:",
    "Product Owner Tooltip": "Tooltip del Propietario de Producto",
    "Owner e-mail": "Correo electrónico del propietario",
    "Purchase Summary": "Resumen de la Compra",
    "Network": "Red",
    "Fees": "Tasas",
    "Rate": "Tarifa",
    "Send to wallet address": "Enviar a la dirección de la cartera",
    "Or scan the code with your wallet app to fill in address automatically.": "O escanee el código con su aplicación de cartera para rellenar la dirección automáticamente.",
    "Send only CURRENCY to this wallet address!": "¡Envíe sólo {currency} a esta dirección de cartera!",
    "Please send your crypto within 30 minutes to ensure a fixed exchange rate": "Por favor, envíe su cripto en un plazo de 30 minutos para asegurar un tipo de cambio fijo",
    "I sent the crypto": "He enviado el cripto",
    "Insufficient funds": "Fondos insuficientes",
    "Purchase Successful": "¡Compra realizada!",
    "The NFT balance was successfully updated and can be viewed in My NFTs tab.": "Puede supervisar el estado de su transacción en \"Mis Pedidos\" - Si se realiza correctamente, su saldo NFT se actualizará en la pestaña \"Mis NFTs\".",
    "Available balance": "Saldo disponible",
    "scanning_for_transaction_text": "Puede supervisar el estado de su transacción en \"Mis Pedidos\" - Si se realiza correctamente, su saldo NFT se actualizará en la pestaña \"Mis NFTs\".",
    "This window will close automatically once we receive your crypto.": "Esta ventana se cerrará automáticamente cuando recibamos su cripto.",
    "We did receive your crypto, but it was less than required. Please transfer the missing amount to complete your transaction.": "Hemos recibido su cripto, pero era inferior al requerido. Por favor, transfiera la cantidad que falta para completar su transacción.",
    "Please do not attempt to do another transaction. Send your Payment UID to our support.": "La transacción ha caducado: por favor, no intente realizar otra transacción. Envíe su ID de Transacción a nuestro soporte.",
    "Scanning for transaction": "Escaneando la transacción",
    "Go to My Orders": "Ir a Mis Pedidos",
    "remaining": "restante",
    "Please send your crypto within 3 hours to ensure a fixed exchange rate": "Envíe su criptografía dentro de las 3 horas para garantizar un tipo de cambio fijo",
    "Please send your crypto within n minutes to ensure a fixed exchange rate": "Envíe su criptografía en {N} minutos para garantizar un tipo de cambio fijo",
    "Missing crypto amount": "Cantidad de cripto que falta",
    "Missing amount": "Cantidad faltante",
    "Recived amount": "Cantidad recibida",
    "Required amount": "Cantidad requerida",
    "Continue with payment": "Continuar con el pago",
    "Your payment has expired. To resolve this issue, please contact customer support": "Su pago ha caducado. Para resolver este problema, póngase en contacto con el servicio de atención al cliente",
    "Your payment was refunded. AMOUNT CURRENCY was returned to your wallet.": "Su pago fue reembolsado. Se devolvió {amount} {currency} a su billetera.",
    "You can monitor the transaction status in the Order History tab. If it is successful, the NFT balance will be updated in the My NFTs tab.": "Puede monitorear el estado de la transacción en la pestaña \"Historial de pedidos\". Si tiene éxito, el saldo de NFT se actualizará en la pestaña \"Mis NFT\".",
    "OFF": "APAGADO",
    "Received Amount.": "Cantidad recibida.",
    "Processing Fee": "Cuota de procesamiento",
    "Estimated gas fee": "Tarifa de gas estimada",
    "Please indicate the intended recipient of the product. You have the option to purchase it for yourself or choose 'Another user' as the recipient.": "Por favor, indique el destinatario del producto. Tiene la opción de comprarlo para usted o elegir \"Otro usuario\" como destinatario.",
    "Please choose your preferred payment method to finalize your purchase.": "Por favor, elija el método de pago preferido para finalizar su compra.",
    "Your payment has expired. To resolve this issue, please contact customer support.": "Su pago ha caducado. Para resolver este problema, por favor, póngase en contacto con el soporte al cliente.",
    "Your payment has been received and is currently being processed. We will notify you once the process is complete.": "Su pago ha sido recibido y está siendo procesado. Le avisaremos una vez finalizado el proceso.",
    "Your transaction has been declined. Please attempt the transaction again.": "Su transacción ha sido rechazada. Por favor, intente realizar la transacción de nuevo.",
    "Monitor the status of your transaction by visiting the provided link:": "Compruebe el estado de su transacción visitando el enlace proporcionado:",
    "Apologies, an error occurred on the blockchain side. Please try again.": "Disculpe, se ha producido un error por parte de blockchain. Por favor, inténtelo de nuevo.",
    "Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.": "Por favor, espere a que se complete la transacción en curso en el blockchain antes de iniciar una nueva.",
    "Transaction Expired": "Transacción vencida",
    "Enable Payment": "Habilitar pago",
    "Email does not exist": "El correo electrónico no existe",
    "Request another quote": "Solicitar otra cotización",
    "Purchase Success!": "¡Compra exitosa!",
    "Purchase for another user": "Compra para otra usuaria",
    "Pay": "Pagar",
    "You purchased GIFT for RECEIVER!": "¡Has comprado {gift} para {receiver}!",
    "You've purchased GIFT for RECEIVER!": "¡Has comprado {gift} para \"{receiver}\"!",
    "You've purchased a GIFT for RECEIVER!": "¡Compraste un {regalo} para \"{receiver}\"!",
    "Rewards claimed successfully": "Recompensas reclamadas con éxito",
    "Commissions claimed successfully.": "Comisiones reclamadas con éxito.",
    "You received GIFT from SENDER!": "¡Has recibido {gift} de {sender}!",
    "You've received GIFT from SENDER!": "¡Has recibido {gift} de \"{sender}\"!",
    "You've received a GIFT from SENDER!": "¡Has recibido un {gift} de \"{sender}\"!",
    "Transaction Failed": "Transacción fallida",
    "The transaction has failed. Please try again.": "La transacción ha fracasado. Inténtalo de nuevo.",
    "Your transaction has been declined. Please add BNB to your wallet and attempt the transaction again.": "Su transacción ha sido rechazada. Agregue BNB a su billetera e intente realizar la transacción nuevamente.",
    "Blockchain Notification: Apologies, an error occurred on the blockchain side. Please try again.": "Notificación de Blockchain: disculpas, se produjo un error en el lado de la cadena de bloques. Inténtalo de nuevo.",
    "Apologies, an unexpected error occurred. Please retry your request. If the issue persists, please contact support.": "Disculpas, ocurrió un error inesperado. Vuelva a intentar su solicitud. Si el problema persiste, póngase en contacto con el soporte.",
    "Received Amount": "Cantidad recibida",
    "Dual Miner": "Minero dual",
    "Bitopex": "Bitopex",
    "Gymnet": "Gymnet",
    "No enough CURRENCY to cover gas fee.": "No hay suficiente {currency} para cubrir la tarifa del gas.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover gas fee.": "Recargue su billetera con {amount_currency} para cubrir la tarifa de gas.",
    "Scanning": "Exploración",
    "To continue, please enable payment.": "Para continuar, habilite el pago.",
    "Kindly be aware that AMOUNT_CURRENCY may be necessary to cover the gas fee.": "Tenga en cuenta que {amount_currency} puede ser necesario para cubrir la tarifa del gas.",
    "Insufficient funds in CURRENCY to cover the gas fee.": "Fondos insuficientes en {currency} para cubrir la tarifa del gas.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover the gas fee.": "Recargue su billetera con {amount_currency} para cubrir la tarifa de gasolina.",
    "We will cover the gas fee for this transaction on your behalf.": "Cubriremos la tarifa de gas para esta transacción en su nombre.",
    "Value": "Valor",
    "Reach Rank": "Rango de Alcance <b>{n}</b>",
    "Rewards in Gymnet": "Recompensas en Gymnet",
    "Rewards in Bitcoin": "Recompensas en Bitcoin",
    "For Limited Time Only": "Sólo Por <strong>Tiempo Limitado</strong> ",
    "Seconds": "Segundos",
    "Minutes": "Minutos",
    "Days": "Días",
    "Hours": "Horas",
    "Unclaimed parcels": "Parcelas no reclamadas",
    "Upgraded parcels": "Parcelas actualizadas",
    "Inactive miners": "Mineros inactivos",
    "Active miners": "Mineros activos",
    "Available Balance": "Saldo Disponible",
    "Utility Balance": "Saldo Utility",
    "Bitopex Balance": "Saldo Bitopex",
    "Moonberg Balance": "Saldo Moonberg",
    "Balance History": "Historial de Saldo",
    "Miner Hashpower": "Hashpower Minero",
    "Dual Miners": "Mineros Duales",
    "Dual miners info": "El número de mineros duales activos y totales.",
    "Dual Miner Hashpower": "Hashpower de Mineros Duales",
    "Dual miners hashpower info": "El hashpower que posees se determina multiplicando el número de tus mineros duales activos por el hashpower del minero unitario, que se establece en 1000Mh/s por unidad.",
    "Unlock the full potential of your Bitopex rewards as they come to life following your purchase of any of our special bundles. Once your Bitopex Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Desbloquea todo el potencial de tus recompensas Bitopex a medida que cobran vida tras la compra de cualquiera de nuestros paquetes especiales. Una vez que tu Saldo Bitopex esté disponible, tendrás acceso a una visión general de tus recompensas totales, recompensas recibidas y recompensas esperadas. Además, un temporizador de cuenta atrás muestra la próxima distribución de recompensas, asegurando que nunca te pierdas las recompensas.",
    "Min": "Min",
    "Sec": "Seg",
    "Total Rewards Expected": "Total de Recompensas Esperadas",
    "Monthly Distribution": "Distribución Mensual",
    "Price Projection": "Proyección de Precio",
    "Server error": "Error del servidor",
    "Average Gymnet ROI for the last 9 months": "ROI medio de Gymnet en los últimos 9 meses",
    "Dual Miners will generate rewards in Bitopex tokens, which will be distributed every Friday.":"Los Mineros Duales generarán recompensas en tokens Bitopex, que se distribuirán cada viernes.",
    "Introducing the Utility Balance feature, a new and convenient payment method within our payment ecosystem. With this feature, you'll enjoy a separate balance that can be used alongside other payment methods. It provides you with the flexibility to partially utilize your utility balance, up to 25% of the total when purchasing single Land NFTs or making payments for Land NFT bundles.":"Presentamos la función Utility Balance, un método de pago nuevo y conveniente dentro de nuestro ecosistema de pagos. Con esta función, disfrutará de un saldo separado que se puede usar junto con otros métodos de pago. Le brinda la flexibilidad de utilizar parcialmente su saldo de servicios públicos, hasta el 25% del total al comprar Land NFT individuales o realizar pagos por paquetes de Land NFT.",
    "Balance before": "Saldo antes",
    "Balance after": "Saldo después",
    "TXID": "TXID",
    "The number of active and total miners you have in Gymstreet and Kabutochō, respectively.": "El número de mineros activos y totales que posees en Gymstreet y Kabutochō, respectivamente.",
    "The hashpower you possess is determined by multiplying the number of your active miners with the unit miner hashpower, which is set at 1000Mh/s per unit.": "El hashpower que posees se determina multiplicando el número de tus mineros activos por el hashpower del minero unitario, que se establece en 1000Mh/s por unidad.",
    "Token": "Token",
    "Buy Bundles": "Comprar Paquetes",
    "Total Received": "Recibido Total",
    "Next Rewards Distribution In:": "Próxima Distribución de Recompensas En:",
    "Total Rewards": "Recompensas Totales",
    "Payout": "Pago",
    "Weekly": "Semanal",
    "Next Distribution Amount": "Próxima cantidad de distribución",
    "Internal transaction": "transacción interna",
    "Minimum claimable amount is (AMOUNT)": "El monto mínimo reclamable es {amount}",
    "User Profile": "Perfil de Usuario",
    "Name": "Nombre",
    "Nationality": "Nacionalidad",
    "Contact Information": "Información de Contacto",
    "Country": "País",
    "City": "Ciudad",
    "Street": "Calle",
    "Zip": "Código postal",
    "E-Mail": "Correo electrónico",
    "Wallet address": "Dirección de cartera",
    "Name is required": "Nombre requerido",
    "Nationality is required": "Nacionalidad requerida",
    "Country is required": "País requerido",
    "City is required": "Ciudad requerida",
    "Street is required": "Calle requerida",
    "Zip is required": "Código postal requerido",
    "Successfully updated": "Actualizado con éxito",
    "Zip contain only numbers and min length is 3": "El código postal sólo contiene números y su longitud mínima es 3",
    "Please enter a valid email address": "Por favor, introduzca una dirección de correo electrónico válida",
    "Personal information": "Información Personal",
    "T&C": "T&C",
    "Field is required": "Campo requerido",
    "Password successfully changed": "Contraseña cambiada con éxito",
    "Old password is incorrect": "Contraseña antigua incorrecta",
    "Oops...Something went wrong. Please try again": "Oops...Algo ha ido mal. Por favor, inténtelo de nuevo",
    "Name must be at least 3 characters": "Nombre debe tener al menos 3 caracteres",
    "City must be at least 3 characters": "Ciudad debe tener al menos 3 caracteres",
    "Street must be at least 3 characters": "Calle debe tener al menos 3 caracteres",
    "Affiliate Terms and conditions": "Términos y condiciones del afiliado",
    "Affiliate links": "Enlaces del afiliado",
    "Affiliate link successfully changed": "Enlace de afiliado cambiado con éxito",
    "Please enter the Bitopex affiliate link here. Kindly note that you can only save the link once.": "Por favor, introduzca aquí el enlace de afiliado de Bitopex. Tenga en cuenta que sólo puede guardar el enlace una vez.",
    "Input cannot be only spaces": "La entrada no puede ser solo espacios",
    "To proceed with the registration, kindly accept the above 2 conditions by checking the corresponding boxes.": "Para proceder con el registro, acepte las 2 condiciones anteriores marcando las casillas correspondientes.",
    "I hereby confirm that I am not a US citizen": "Por la presente confirmo que no soy ciudadano estadounidense.",
    "I hereby confirm that I am not a UAE citizen": "Por la presente confirmo que no soy ciudadano de los EAU",
    "Enter Old Password": "Ingrese la contraseña anterior",
    "Change username & password": "Cambiar nombre de usuario y contraseña",
    "My wallet": "Mi billetera",
    "Purchase": "Compra",
    "Username required to proceed": "Nombre de usuario requerntiido para continuar",
    "Enter preferred username here": "Ingrese el nombre de usuario preferido aquí",
    "Username must be at least 8 characters, starting with a letter. Use only lowercase letters, numbers, hyphens, dots, and underscores.": "El nombre de usuario debe tener al menos 8 caracteres, comenzando con una letra. Use solo letras minúsculas, números, guiones, puntos y guiones bajos.",
    "Username is already taken. Please choose a different username.": "Nombre de usuario ya tomado. Por favor escoja un nombre de usuario diferente.",
    "Email or username": "Correo electrónico o nombre de usuario",
    "Username is available": "Nombre de usuario disponible",
    "Provided username is already taken.": "El nombre de usuario proporcionado ya está en uso.",
    "Invalid credentials. Please try again.": "Credenciales no válidas.\n Inténtalo de nuevo.",
    "Username successfully set": "Nombre de usuario establecido con éxito",
    "Dear User, due to ongoing updates to our platform, you will need to enter a username to continue. The username needs to be unique and cannot be changed": "Estimado usuario, debido a las continuas actualizaciones de nuestra plataforma, deberá ingresar un nombre de usuario para continuar. El nombre de usuario debe ser único y no se puede cambiar.",
    "You have successfully set your username": "Ha establecido correctamente su nombre de usuario",
    "Continue to dashboard": "Continuar al tablero",
    "Username is required": "Se requiere nombre de usuario",
    "Rank Transfer": "Transferencia de rango",
    "Review": "Revisar",
    "Top Up": "Completar",
    "The claim button is currently disabled for maintenance. We apologize for the inconvenience. Please come back shortly.": "El botón de reclamo está actualmente deshabilitado por mantenimiento. Pedimos disculpas por las molestias. Por favor regrese pronto.",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / BUSD": "Cuando se está implementando un nuevo edificio de negocios, usted tendrá \n la opción de comprar terrenos pagando con GYMNET / BUSD",
    "Parcel NFT": "Parcela NFT",
    "Miner NFT": "Minero NFT",
    "BUSD Balance": "Saldo BUSD",
    "Your payment has been received. It is currently being processed. You will receive a notification when done.": "Su pago ha sido recibido. Se está procesando actualmente. Recibirá una notificación cuando haya finalizado.",
    "Error": "Error",
    "Please enter valid email": "Por favor, introduzca un correo electrónico válido",
    "Your sponsor is": "Su patrocinador es",
    "Credentials": "Credenciales",
    "Enter Wallet Address (i.e. 0xb794f...79268)": "Introduzca la Dirección de la Cartera (ej. 0xb794f...79268)",
    "Contact Customer Support": "Contactar con el Servicio de Atención al Cliente",
    "Change Wallet": "Cambiar Cartera",
    "Account": "Cuenta",
    "Please leave an address here, in which we can also mint your NFTs afterwards.": "Por favor, deje aquí una dirección en la que también podamos acuñar sus NFT después.",
    "Important!": "¡Importante!",
    "It should be a BEP-20 address - i.e. a BNB Smart Chain wallet address. We recommend the wallet Metamask or Trustwallet.": "Debe ser una dirección BEP-20, es decir, una dirección de cartera BNB Smart Chain. Recomendamos la cartera Metamask o Trustwallet.",
    "NEVER USE AN EXCHANGE Wallet ADRESS !!!": "¡¡¡NO UTILICE NUNCA UNA DIRECCIÓN DE CARTERA DE INTERCAMBIO!!!",
    "Failed": "Fallido",
    "Account changed": "Cuenta cambiada",
    "Your Profile has been updated": "Su Perfil ha sido actualizado",
    "You already have an attached wallet address to this account.": "Ya tiene una dirección de cartera vinculada a esta cuenta.",
    "Login": "Iniciar sesión",
    "Existing wallet": "Cartera existente",
    "Referral ID is required": "Se requiere ID de referencia",
    "Pending Transaction": "Transacción Pendiente",
    "Processing": "Procesando",
    "Send this payment ID to a customer support when you are requested to do so.": "Envíe este ID de pago al servicio de atención al cliente cuando se le solicite.",
    "To be able to claim your NFTs you need to connect the same wallet address attached to your account": "Para poder reclamar sus NFTs necesita conectar la misma dirección de cartera adjunta a su cuenta",
    "Please confirm your email to continue.": "Por favor, confirme su correo electrónico para continuar.",
    "Valid address": "Dirección válida",
    "This social account is used by another user. Verify your email first or contact Customer Support": "Esta cuenta social está siendo utilizada por otro usuario. Verifique primero su correo electrónico o póngase en contacto con el Soporte al cliente",
    "Invalid wallet connection": "Conexión de cartera no válida",
    "Registration completed successfully": "Registro completado con éxito",
    "Failed to complete registration of the user": "No se ha podido completar el registro del usuario",
    "Failed to register user": "No se ha podido registrar al usuario",
    "Signature grant failed": "Fallo en la concesión de firma",
    "Unsupported chain": "Cadena no soportada",
    "This wallet address is already associated with another user account. Please use another one!": "Esta dirección de cartera ya está asociada a otra cuenta de usuario. ¡Por favor, utilice otra!",
    "Failed to add address to your wallet": "No se ha podido añadir la dirección a su cartera",
    "Address has been successfully added to your wallet": "La dirección se ha añadido correctamente a su cartera",
    "Failed to accept Affiliate TMS": "Fallo al aceptar Affiliate TMS",
    "Wallet address has been successfully applied": "La dirección de la cartera se ha aplicado correctamente",
    "Your email is verified.": "Su correo electrónico ha sido verificado.",
    "Failed to add Token": "Fallo al añadir Token",
    "Failed to upload image": "Fallo al cargar imagen",
    "Wrong file size. Please upload image files with size less than 10MB": "Tamaño de archivo incorrecto. Por favor, cargue archivos de imagen con un tamaño inferior a 10MB",
    "We've verified your email address. You can now continue using the site.": "Hemos verificado su dirección de correo electrónico. Ya puede seguir utilizando el sitio.",
    "You need to switch to Binance Smart Chain": "Necesita cambiar a Binance Smart Chain",
    "Switch to Binance Smart Chain to continue using GymStreet": "Cambie a Binance Smart Chain para continuar utilizando GymStreet",
    "To CashFT": "A CashFT",
    "Buy a Super Bundle in the next": "Compre un Super Bundle en el siguiente",
    "and get an EXTRA discount!": "¡y consigue un descuento EXTRA!",
    "See All Offers": "Ver Todas las Ofertas",
    "I accept the affiliate terms & conditions": "Acepto los términos y condiciones de afiliación",
    "Special offer!": "¡Oferta especial!",
    "Just one": "Sólo uno",
    "1000 Mh/s": "1000 Mh/s",
    "View Claimed Coordinates": "Ver Coordenadas Reclamadas",
    "Your Parcel Coordinates": "Coordenadas de su Parcela",
    "Number of miners you want to attach": "Número de mineros que desea adjuntar",
    "Referral Link": "Enlace de Referencia",
    "Affiliate Link": "Enlace de Afiliación",
    "Invalid Wallet": "Cartera No Válida",
    "Back to Log In": "Volver al Inicio de Sesión",
    "You need a valid referral ID to finalize your registration": "Necesita un ID de referencia válido para finalizar su registro",
    "I have read and accept the": "He leído y acepto las",
    "Your Sponsor": "Su patrocinador",
    "Forgot Your Password": "Olvidó Su Contraseña",
    "Enter your email address and we will send you instructions to reset your password.": "Introduzca su dirección de correo electrónico y le enviaremos instrucciones para restablecer su contraseña.",
    "We just sent you an email to confirm your e-mail address. Please click on the link in the e-mail to reset your password.": "Le acabamos de enviar un correo electrónico para confirmar su dirección de correo electrónico. Por favor, haga clic en el enlace del correo electrónico para restablecer su contraseña.",
    "Welcome To Metaverse": "Bienvenido A {n} Metaverso",
    "Commissions": "Comisiones",
    "Refund of electricity vouchers": "Devolución de vales de electricidad",
    "Import Custom Token": "Importar Token Personalizado",
    "Add Token To Wallet": "Añadir Token a Cartera",
    "Token contract address": "Dirección del contrato de token",
    "Token symbol": "Símbolo Token",
    "Provided address is not a supported token address": "La dirección proporcionada no es una dirección de token admitida",
    "Are you sure you want to claim your rewards": "¿Está seguro de que desea reclamar sus recompensas?",
    "OTP Code": "Código OTP",
    "OTP Code is required": "Se requiere código OTP",
    "Enter OTP code you have received at email": "Introduzca el código OTP que ha recibido en {email}",
    "Asset": "Activo",
    "Gas": "Gas",
    "Send crypto": "Enviar cripto",
    "Enter wallet address": "Introduzca la dirección de la cartera",
    "NFTs": "NFTs",
    "Activity": "Actividad",
    "Assets": "Activos",
    "Import token": "Importar token",
    "Don't see your token?": "¿No ve su token?",
    "more": "más",
    "Send": "Enviar",
    "Sending success": "Envío exitoso",
    "View account in explorer": "Ver cuenta en el explorador",
    "estimated": "estimado",
    "Copy your address": "Copiar su dirección",
    "Export private key": "Exportar clave privada",
    "Receive": "Recibir",
    "Account Details": "Detalles de la Cuenta",
    "View on blockchain": "Ver en blockchain",
    "GymStreet Parcels": "Parcelas GymStreet",
    "GymStreet Miners": "Mineros GymStreet",
    "Kabutocho Parcels": "Parcelas Kabutocho",
    "Kabutocho Miners": "Mineros Kabutocho",
    "Invalid wallet address": "Dirección de cartera no válida",
    "Calculate": "Calcular",
    "Sending": "Enviando",
    "Reject": "Rechazar",
    "Confirmation": "Confirmación",
    "Gas fees are paid to crypto miners who process transactions on the network. Gas fees are set by the network and fluctate based on network traffic and transaction complexity.": "Las tasas de gas se pagan a los mineros de cripto que procesan transacciones en la red. Las tasas de gas son establecidas por la red y fluctúan en función del tráfico de la red y la complejidad de la transacción.",
    "Insufficient TOKEN balance": "Saldo {token} insuficiente",
    "Confirm Swap": "Confirmar Permuta",
    "Approve Allowance": "Aprobar Permiso",
    "Help Center": "Centro de Ayuda",
    "Confirm the Allowance Transaction in order to be able to use Token through our services. Please note that this is a one-time action.": "Confirme la Transacción de Permiso para poder utilizar <strong>{n}</strong> Token a través de nuestros servicios. Por favor, tenga en cuenta que esta es una acción de una sola vez.",
    "No Activities yet": "No hay actividades todavía",
    "To": "A",
    "Contract interaction": "Interacción del contrato",
    "Are you sure you want to delete this token": "¿Está seguro de que desea eliminar este token?",
    "Send only using  {CHAIN}  network to receive funds on this deposit address.": "Envíe solo usando la red {CHAIN} para recibir fondos en esta dirección de depósito.",
    "Leadership Incentives": "Incentivos de Liderazgo",
    "Pool statistics": "Estadísticas del grupo",
    "Hourly": "Por hora",
    "Turnover rule": "Regla de rotación",
    "Your Income": "Su Ingreso",
    "Turnover rule percentage": "{n}% Regla de rotación",
    "Miner sales contribution": "Contribución de ventas del minero",
    "Pool": "Grupo",
    "percent of NFT sales": "{n}% de ventas NFT",
    "partner": "socio",
    "Main area": "Área principal",
    "Turnover percentage rule": "Regla de {n}% de rotación",
    "With dollar each": "Con dólar cada uno",
    "Direct partners": "Socios directos",
    "Your next bonus will appear here": "Su próximo bono aparecerá aquí",
    "Your incentive": "Su incentivo",
    "Your cash bonus": "Su bono en efectivo",
    "Your bonus history": "Su historial de bonos",
    "At the current moment you don't have any rewards": "En este momento no tiene ninguna recompensa",
    "Total generated turnover": "Rotación total generada",
    "Active Miners": "Mineros Activos",
    "Total pool rewards": "Total de recompensas del grupo",
    "Your pool share": "Su cuota del grupo",
    "Total gGymnet": "Total gGymnet",
    "GYMNET Commissions Total": "Total de Comisiones GYMNET",
    "Your attached wallet is {walletAddress}. You have tried to make request with wallet {currentWallet}. Please change connected wallet to {walletAddress}": "Su cartera conectada es <b>{walletAddress}</b>. Ha intentado realizar una solicitud con la cartera <b>{currentWallet}</b>. Por favor, cambie la cartera conectada a <b>{walletAddress}</b>",
    "Available for limited time": "Disponible por tiempo limitado",
    "Bonus": "Bono",
    "Utility": "Utilidad",
    "The URL must begin with 'https://bitopex.io/home/' and include your referral ID": "La URL debe comenzar por 'https://bitopex.io/home/' e incluir su ID de referencia",
    "Insufficient funds: to continue with payment use utility balance": "Fondos insuficientes: para continuar con el pago utilice el saldo de utilidad",
    "My Team": "Mi Equipo",
    "Team Statistics": "Estadísticas del Equipo",
    "All partners": "Todos los socios",
    "Active partners": "Socios activos",
    "Inactive partners": "Socios inactivos",
    "Binary Points": "Puntos Binarios",
    "Points in left leg": "Puntos en pierna izquierda",
    "Points in right leg": "Puntos en pierna derecha",
    "Left leg": "Pierna izquierda",
    "Right leg": "Pierna derecha",
    "Holding Tank": "Tanque de Retención",
    "Enrollment Tree": "Árbol de Inscripción",
    "Binary Tree": "Árbol Binario",
    "CV Points History": "Historial de Puntos CV",
    "Time Left To Activation": "Tiempo Restante <b>Para la Activación</b>",
    "Search by email or wallet": "Buscar por correo electrónico o cartera",
    "Refresh Data": "Actualizar Datos",
    "Joined at": "Se unió en",
    "Placement": "Colocación",
    "Select": "Seleccionar",
    "Own": "Propio",
    "Team": "Equipo",
    "Position": "Posición",
    "CV Points": "Puntos CV",
    "CV = commission volume": "CV = volumen de comisiones",
    "Genealogy": "Genealogía",
    "Unilevel": "Uninivel",
    "Select view": "Seleccionar vista",
    "Team size": "Tamaño del equipo",
    "Rank": "Rango",
    "Own purchases": "Compras propias",
    "Navigation": "Navegación",
    "Search by wallet": "Buscar por cartera",
    "no user": "ningún usuario",
    "Wallet address is not valid": "La dirección de la cartera no es válida",
    "Free Spot": "Plaza Libre",
    "Search by wallet or username": "Buscar por cartera o nombre de usuario",
    "Info": "Info",
    "Benefits": "Beneficios",
    "Objectives Completed": "Objetivos Completados",
    "TO DO`s": "TAREAS A REALIZAR",
    "Purchase NFTs In Total Value Of 100 USDT": "Comprar NFTs Por Valor Total De 100 USDT",
    "Purchase Binary Pass - 19 USDT": "Comprar Pase Binario - 19 USDT",
    "Team Turnover": "Rotación de Equipo",
    "BASED ON TEAM": "{n}% + BASADO EN EQUIPO",
    "Qualification Turnover": "Rotación de Clasificación",
    "Current Rank": "Rango Actual",
    "Next Rank": "Rango Siguiente",
    "Binary Spot Reservation": "Reserva de Plaza Binaria",
    "Holding Tank Ends In": "Tanque de Retención <b>Finaliza en</b>",
    "With every parcel purchase, you will receive two types of tokens—Gymnet and Bitcoin.": "Con cada compra de paquete, recibirá dos tipos de tokens: Gymnet y Bitcoin.",
    "A minimum of USDT is required for Purchase": "Se requiere un mínimo de ${n} USDT para la Compra",
    "Become Affiliate Partner": "Conviértase en Socio Afiliado",
    "Placement in Unilevel": "Colocación en el Uninivel",
    "Matching bonuses": "Bonos de igualación",
    "Placement in Binary": "Colocación en Binario",
    "Product Autoship Incentive": "Incentivo de Autoenvío de Producto",
    "For limited time only": "Sólo por tiempo limitado",
    "Free 30 days Product Autoship": "Envío Automático de Producto gratuito durante 30 días",
    "Fast Start Incentive": "Incentivo de Inicio Rápido",
    "Receive Direct Sales Commissions": "Reciba Comisiones de Ventas Directas",
    "Please select your role": "Por favor seleccione su rol",
    "Choose your role before diving in. Specify why user should choose each role and what opportunities are there.": "Elija su rol antes de sumergirse. Especifique por qué el usuario debe elegir cada rol y qué oportunidades hay.",
    "is left to get": "<span>${n}</span> queda por conseguir",
    "Go to Products": "Ir a Productos",
    "Rental Income": "Ingreso de Renta",
    "Left Leg Affiliate Link": "Enlace de Afiliado de la Pierna Izquierda",
    "Right Leg Affiliate Link": "Enlace de Afiliado de la Pierna Derecha",
    "Qualification Conditions": "Condiciones de Cualificación",
    "Buy Products": "Comprar Productos",
    "My Current Rank": "Mi Rango Actual",
    "To Reach Rank You Need:": "Para Alcanzar el Rango {n} Necesita:",
    "NFT Turnover In Weak Leg": "Rotación NFT En Pierna Débil",
    "NFT Own purchases": "NFT compras Propias",
    "Required Qualified Partners": "Socios Cualificados Requeridos",
    "Progress Until Rank": "Progreso Hasta Rango {n}",
    "See Details": "Ver Detalles",
    "Default Advantages": "Ventajas por Defecto",
    "Binary Commission": "Comisión Binaria",
    "Upgrade Your Weekly Cap": "Mejore Su Límite Semanal",
    "Rank Benefits": "Beneficios de Rango",
    "For 30 Days ONLY You Receive 10% Binary Commission Instead Of NUM": "¡SÓLO Durante 30 Días Recibirá un 10% de Comisión Binaria en lugar de {n}%!",
    "My Rank Progress": "Mi Progreso de Rango",
    "Own Invest": "Inversión Propia",
    "Qualified Partners": "Socios Cualificados",
    "You need to buy products in value of": "Necesita comprar productos por valor de ${n}",
    "You need to generate turnover in value of in your weak leg.": "Necesita generar rotación en valor de ${n} en su pierna débil.",
    "Remaining": "Restante",
    "CV Points weak leg": "CV Puntos pierna débil",
    "Details": "Detalles",
    "Partner": "Socio",
    "leg": "pierna",
    "Right": "Derecha",
    "Left": "Izquierda",
    "You need qualified partner in left leg and qualified partner in right leg to complete this requirement.": "Necesita {l} socio cualificado en la pierna izquierda y {r} socio cualificado en la pierna derecha para completar este requisito.",
    "partners in left leg and in right leg": "{l} {partner1} en pierna izquierda y {r} socio en pierna derecha restantes",
    "partners in left leg remaining": "{l} {partner1} en pierna izquierda restante",
    "partners in right leg remaining": "{r} socios en pierna derecha restantes",
    "partner in right leg remaining": "{r} socio en pierna derecha restante",
    "Holding Tank is over": "Tanque de Retención ha terminado",
    "Back To Rank Progress Details": "Volver A Detalles De Progreso De Rango",
    "Super Bundle R10 or above": "Super Bundle R10\n o superior",
    "Required Monthly Product Autoship": "Autoenvío Mensual de Producto Requerido",
    "All Customer Benefits": "Todos los Beneficios del Cliente",
    "Product Autoship": "Autoenvío de Producto",
    "Product Autoships": "Autoenvíos de Producto",
    "Products are resellable": "Productos son revendibles",
    "Activate binary commission": "Activar comisión binaria",
    "Activate direct sales commission": "Activar comisión de ventas directas",
    "Left before": "Izquierda antes",
    "Left after": "Izquierda después",
    "Right before": "Derecha antes",
    "Right after": "Derecha después",
    "Activate your referral link to be able to invite your referrals to the platform and earn additional commissions.": "Active su enlace de referencia para poder invitar a sus referidos a la plataforma y ganar comisiones adicionales.",
    "Activate referral link": "Activar enlace de referencia",
    "Yes, I confirm": "Sí, confirmo",
    "Inactive": "Inactivo",
    "Finance": "Finanzas",
    "Total Income": "Ingreso Total",
    "Products": "Productos",
    "GymStreet Map": "Mapa GymStreet",
    "Kabutocho Map": "Mapa Kabutocho",
    "Don't ask again": "No volver a pregunta",
    "Filecoin Balance": "Saldo Filecoin",
    "Filecoin": "Filecoin",
    "Total available": "Total disponible",
    "Total generated": "Total generado",
    "Total used": "Total utilizado",
    "Transaction type": "Tipo de transacción",
    "To claim your commission, you must have a minimum of 1 USDT or (AMOUNT) GYMNET.": "Para reclamar su comisión, debe tener un mínimo de 1 USDT o {n} GYMNET.",
    "Loading balance...": "Cargando saldo...",
    "Total Commissions": "Comisiones Totales",
    "Claimed Commissions": "Comisiones Reclamadas",
    "Available Commissions": "Comisiones Disponibles",
    "Total Commissions By Source": "Comisiones Totales Por Fuente",
    "Filter by currency": "Filtrar por divisa",
    "No data to display": "No hay datos para mostrar",
    "Year": "Año",
    "Career Status": "Estado de Carrera",
    "Current Incentive": "Incentivo Actual",
    "Fast Start": "Inicio Rápido",
    "Product Autoship incentive": "Incentivo de Autoenvío de Producto",
    "Direct partner - left leg": "Socio directo - pierna izquierda",
    "Direct partner - right leg": "Socio directo - pierna derecha",
    "Receive direct sales commission": "Recibir comisión de venta directa",
    "Direct sales commission": "Comisión de ventas directas",
    "Receive binary commission": "Recibir comisión binaria",
    "Affiliate ranks": "Rangos de afiliados",
    "1 Left, 1 Right": "1 Izquierda, 1 Derecha",
    "See Benefits": "Ver Beneficios",
    "2 Silver (1 in different teams)": "2 Plata (1 en equipos diferentes)",
    "2 Gold (1 in different teams)": "2 Oro (1 en diferentes equipos)",
    "3 Platin (1 in different teams)": "3 Platino (1 en diferentes equipos)",
    "3 Double Platin (1 in different teams)": "3 Doble Platino (1 en diferentes equipos)",
    "4 Diamond (1 in different teams)": "4 Diamante (1 en diferentes equipos)",
    "5 Double Diamond (1 in different teams)": "5 Doble Diamante (1 en diferentes equipos)",
    "5 Red Diamond (1 in different teams)": "5 Diamante Rojo (1 en diferentes equipos)",
    "5 White Diamond (1 in different teams)": "5 Diamante Blanco (1 en diferentes equipos)",
    "Please note that $100 dollar will correspond to 100 points.": "Por favor, tenga en cuenta que $100 dólares corresponderán a 100 puntos.",
    "Home": "Inicio",
    "Last Left": "Última Izquierda",
    "Last Right": "Última Derecha",
    "Binary leg": "Pierna binaria",
    "by admin": "por admin",
    "missed commission": "comisión perdida",
    "payment": "pago",
    "commission": "comisión",
    "Payment Currency": "Divisa de Pago",
    "BUSD Amount": "Importe BUSD",
    "CV Ratio": "CV Ratio",
    "Hrs": "Hrs",
    "Mins": "Mins",
    "Secs": "Segs",
    "Please enter a valid referral ID": "Por favor, introduzca un ID de referencia válido",
    "Commission TXID": "Comisión TXID",
    "Qualification turnover": "Rotación de cualificación",
    "Binary commission": "Comisión binaria",
    "Additional Advantages": "Ventajas Adicionales",
    "The referral ID you've entered is incorrect. Please copy and paste the correct referral link to proceed.": "El ID de referencia que ha introducido es incorrecto. Por favor, copie y pegue el enlace de referencia correcto para continuar.",
    "In order to receive binary commissions you need to have an active product autoship.": "Para recibir comisiones binarias necesitas tener un autoenvío de producto activo.",
    "In order to receive binary commissions you need to have an active product autoship and a purchase for at least $100.": "Para recibir comisiones binarias es necesario tener un autoenvío de producto activo y una compra de al menos 100$.",
    "To qualify for binary commissions, a minimum product purchase of $100 is required.": "Para calificar para comisiones binarias, se requiere una compra mínima de producto de $100.",
    "Transaction in Process": "Transacción en proceso",
    "Claim your parcel": "Reclama tu paquete",
    "You do not have any claimed slots to attach miners. To enable miner attachment, please claim parcels on the map.": "No tienes espacios reclamados para adjuntar mineros. Para habilitar la conexión de mineros, reclame paquetes en el mapa.",
    "Active binary commission": "Comisión binaria activa",
    "Active direct sales commission": "Comisión activa de venta directa",
    "Own purchase min {AMOUNT}": "Compra propia mínima {AMOUNT}",
    "Active product autoship": "Envío automático de producto activo",
    "Right partner own purchase min {AMOUNT}": "Compra propia del socio adecuado mínimo {AMOUNT}",
    "Left partner own purchase min {AMOUNT}": "Compra propia del socio izquierdo mínimo {AMOUNT}",
    "Unfortunately, you won't receive the bonus for this rank since you met the qualification conditions (more than 50%) before September 18, 2023": "Desafortunadamente, no recibirás el bono para este rango ya que cumpliste las condiciones de calificación (más del 50%) antes del 18 de septiembre de 2023.",
    "You will be eligible for cash bonus based on your rank if you meet all qualification conditions between September 18 and November 8.Those who met the qualification conditions (more than 50%) before September 18, 2023 will not receive the Super Cash Bonus.": "Será elegible para recibir un bono en efectivo según su rango si cumple con todas las condiciones de calificación entre el 18 de septiembre y el 31 de enero. Aquellos que cumplieron las condiciones de calificación (más del 50%) antes del 18 de septiembre de 2023 no recibirán el Super Bono en Efectivo.",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / USDT": "Cuando se está implementando un nuevo edificio de negocios, usted tendrá \n la opción de comprar terrenos pagando con GYMNET / USDT",
    "yearly": "Anualmente",
    "Unknown": "Desconocido",
    "Claim processing may take up to 2 minutes. Thank you for your patience.": "El procesamiento de reclamaciones puede tardar hasta 2 minutos. Gracias por su paciencia.",
    "1 Person": "1 Persona",
    "2 Persons": "2 Personas",
    "Extended Referral Link": "Enlace de referencia ampliado",
    "Enter a username": "Introduzca un nombre de usuario",
    "Possible only for usernames in your binary team!": "¡Sólo es posible para los nombres de usuario de su equipo binario!",
    "Select Team": "Seleccionar equipo",
    "Create Link": "Crear enlace",
    "New users will be placed under in the team": "Los nuevos usuarios se colocarán bajo {n} en el equipo {p}",
    "Invalid username!": "¡Nombre de usuario inválido!",
    "Left team": "Equipo izquierdo",
    "Right team": "Equipo derecho",
    "Distribution In Progress": "Distribución en curso",
    "Next Binary Bonus Distribution In": "Próxima distribución de bonos binarios en",
    "Matching Bonus Commissions": "Comisiones de bonos de igualación",
    "Binary Commissions": "Comisiones binarias",
    "Binary Weekly Cap": "Límite semanal binario",
    "Binary Cycles": "Ciclos binarios",
    "Matching Bonus": "Bonos de igualación",
    "Incomes from Product Autoships": "Ingresos por autoenvío de productos",
    "Missed Commissions": "Comisiones perdidas",
    "Plus Max Weekly Payout": "Plus máximo pago semanal",
    "Import Token With Address": "Importar token con dirección",
    "Import Supported Token": "Importar token soportado",
    "Shop": "Tienda",
    "Filter by": "Filtrar por",
    "My Purchases": "Mis compras",
    "Purchases for other members": "Compras para otros miembros",
    "Product Autoship Purchases": "Compras de productos por autoenvío",
    "Sort By": "Ordenar por",
    "Top up": "Recargar",
    "Attach your miners": "Adjuntar sus mineros",
    "Buy parcels": "Comprar parcelas",
    "Number of parcels you want to buy": "Número de parcelas que quiere comprar",
    "Use utility balance": "Usar saldo de utility",
    "My Products": "Mis productos",
    "USDT Amount": "Importe USDT",
    "Next Matching Bonus Distribution In": "Próxima distribución de bonos de igualación en",
    "Please top up your wallet with AMOUNT_CURRENCY USDT for upgrade.": "Por favor, recargue su monedero con {AMOUNT_CURRENCY} USDT para la actualización.",
    "Please note that worth of USDT is required to complete the transaction.": "Por favor, tenga en cuenta que se requiere {n} USDT para completar la transacción.",
    "Incentive Ends In:": "Incentivo finaliza en:",
    "Bonus History": "Historial de bonos",
    "Incentive has ended": "Incentivo ha finalizado",
    "Direct Sales Turnover": "Volumen de ventas directas",
    "Commissions are paid out following a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "Las comisiones se pagan siguiendo una proporción 70/30, con un 70% en stablecoins y un 30% en tokens GYMNET.",
    "(effective as of 28.02.2024)": "(en vigor a partir del 28.02.2024)",
    "Free Product Autoship": "Envío automático de productos gratis",
    "10% Binary Commission": "10% de comisión binaria",
    "Enjoy More, Benefit More!": "¡Disfruta más, beneficia más!",
    "To qualify for Binary Commissions, you must have a new member with at least $100 on your right team and another on your left team.": "Para calificar para Comisiones Binarias, debe tener un nuevo miembro con al menos $100 en su equipo derecho y otro en su equipo izquierdo.",
    "Buy products for at least $100 within 30 days to receive these benefits.": "Compre productos por al menos <span>$100</span> dentro de <span>30 días</span> para recibir estos beneficios.",
    "Getting started": "Empezando",
    "Confirm your email to continue": "Confirma tu correo electrónico para continuar",
    "Resend confirmation email": "Reenviar email",
    "Super Bundle Special Discount Expires In": "El descuento especial del súper paquete vence en",
    "Done": "Hecho",
    "Get your bundle": "Consigue tu paquete",
    "Gain Fresh Perspectives and Exclusive Benefits": "Obtenga nuevas perspectivas y beneficios exclusivos",
    "Super Bundle R8": "Súper Paquete R8",
    "Super Bundle R17": "Súper Paquete R17",
    "Starter Bundle": "Paquete de inicio",
    "Travel": "Promoción de viaje",
    "Cash Promo": "Promoción en efectivo",
    "Monthly Product Autoship is required to be an Affiliate Partner and earn commissions.": "Se requiere envío automático de productos mensual para ser socio afiliado y ganar comisiones.",
    "Affiliate": "Afiliada",
    "Recommended": "Recomendada",
    "Binary Commissions based on the rank": "Comisiones binarias basadas en el rango",
    "Customer": "Cliente",
    "NFT Turbo": "Turbo NFT",
    "Purchase Digital Land": "Compra de terrenos digitales",
    "Participate in Partnership Deals": "Participe en acuerdos de asociación",
    "Receive minting Rewards": "Reciba recompensas de acuñación",
    "Up to": "Hasta el {n}%",
    "Weekly Distribution": "Distribución Semanal",
    "A parcel with minting power will generate rewards in Filecoin tokens, which will be distributed every Monday.": "Un paquete con poder de acuñación generará recompensas en tokens Filecoin, que se distribuirán todos los lunes.",
    "It seems that a some request is already pending. Please check your wallet app or extension and try again.": "Parece que ya hay alguna solicitud pendiente. Verifique la aplicación o extensión de su billetera e inténtelo nuevamente.",
    "Add miner NFT to wallet": "Agregar Miner NFT a la billetera",
    "Go to My NFTs": "Ir a Mis NFT",
    "Thank you for your participation": "Gracias por su participación",
    "No owner": "Sin dueño",
    "Ownership of this bundle is limited to one per user. Please purchase for yourself and for those who do not have it.": "La propiedad de este paquete está limitada a uno por usuario. Cómprelo para usted y para aquellos que no lo tienen.",
    "Buy parcels with minting power and generate income.": "Compra parcelas con poder de acuñación y genera ingresos.",
    // Mystery box
    "Limited time offer": "Oferta por tiempo limitado",
    "See Options": "Ver opciones",
    "Please select one of the currencies": "Seleccione una de las divisas",
    "Current balance": "Saldo actual",
    "Try your luck": "Pruebe suerte",
    "Offer ends in": "La oferta termina en",
    "Parcel and Dual Miner": "Parcela y Minero dual",
    "Mystery Box": "Caja misteriosa",
    "Try your luck and win incredible Bundles, Parcels and Dual Miners to boost your earnings!": "¡Pruebe suerte y gane increíbles paquetes, paquetes y mineros duales para aumentar sus ganancias!",
    "from MIN to MAX": "de <b>{min}</b> a <b>{max}</b>",
    "Choose a Box and Discover Rewards": "Elija una caja y descubra recompensas",
    "Prizes you can win": "Premios que puede ganar",
    "Item": "Artículo",
    "Chance": "Oportunidad",
    "Promos": "Promociones",
    "Mystery Box results": "Resultados de la caja misteriosa",
    "Total reward value": "Valor total de la recompensa",
    "My Rewards": "Mi recompensa",
    "Rewards Community Claimed": "Comunidad de recompensas reclamadas",
    "Hero image": "Imagen de héroe",
    "No rewards yet": "Aún no hay recompensas",
    "No winners yet": "Aún no hay ganadores",
    "While no lucky participants have claimed their prizes yet, the excitement continues to build.": "Si bien ningún participante afortunado ha reclamado sus premios todavía, el entusiasmo continúa aumentando.",
    "Mystery Box is Here!": "¡La Caja Misteriosa ya está aquí!",
    "Be the First to Discover Mystery Rewards": "Sé el primero en descubrir recompensas misteriosas",
    "Select Balance": "Seleccionar Saldo",
    "Current Balance": "Saldo actual",
    "Opening Mystery Box": "Abrir la caja misteriosa",
    "Oops, not this time 😔": "Ups, esta vez no 😔",
    "Luck is on your side. Enjoy your prize!": "La suerte está de tu lado. ¡Disfrute de su premio!",
    "The more you try, the better your chances of winning. Keep going!": "Cuanto más lo intentes, mayores serán tus posibilidades de ganar. ¡Sigue adelante!",
    "Try another box": "Prueba con otra caja",
    "Go to My Products": "Ir a Mis Productos",
    "See promo results": "Ver los resultados de la promoción",
    "Payment enabled": "Pago habilitado",
    "Please select the wallet that is attached to your account.": "Seleccione la billetera adjunta a su cuenta.",
    "Explore rewards": "Explorar las recompensas",
    "View rewards claimed by you and the community": "Ver recompensas reclamadas por ti y por la comunidad",
    "Open a Mystery Box to get rewards starting from Parcels to Super bundles.": "Abre una Caja misteriosa para conseguir recompensas que van desde paquetes hasta superpaquetes.",
    "Insufficient funds, please choose another box.": "Fondos insuficientes, elija otra casilla.",
    "Blockchain error": "Error de Blockchain",
    "Rewards claimed": "Recompensas solicitadas",
    "We have encountered difficulties while processing the result of the reward. Please contact our support team for prompt assistance.": "Hemos encontrado dificultades al procesar el resultado de la recompensa. Póngase en contacto con nuestro equipo de asistencia para obtener ayuda lo antes posible.",
    "Boxes opened": "Cajas abiertas",
    // Mystery box

    // Partnership details
    "About": "Acerca de",
    "Offer": "Oferta",
    "Social Media": "Redes sociales",
    "Additional Content": "Contenido adicional",
    "Photo": "Foto",
    "Video": "Vídeo",
    // Partnership details

    // Miner Rewards
    "Single Miners": "Mineros solteros",
    "price today": "precio hoy",
    "Estimated daily rewards": "Recompensas diarias estimadas",
    "day": "día",
    "day at current rate": "día al ritmo actual",
    "Estimated rewards until 2025": "Recompensas estimadas hasta 2025",
    "at current rate": "al ritmo actual",
    "Estimated rewards from today to December 1, 2025. It may change based on your shares in the pool.": "Recompensas estimadas desde hoy hasta el 1 de diciembre de 2025. Puede variar en función de sus participaciones en el fondo común.",
    "Expected rewards calculator": "Calculadora de recompensas esperadas",
    "price": "precio",
    "My total GYMNET value in USD": "Mi valor total de GYMNET en USD",
    "claimed": "reclamado",
    "My Balances": "Mis saldos",
    "Tokens to be burned": "Fichas para quemar",
    "You dont have any Claimed or Pending GYMNET rewards.": "No tienes ninguna recompensa de GYMNET reclamada o pendiente.",
    "Claim now": "Reclama ahora",
    "No claimed GYMNET rewards are found.": "No se encontraron recompensas de GYMNET reclamadas.",
    // Miner Rewards

    "Please note that the leg with the lesser amount of points is called weak leg and the one with the higher amount of points is called strong or power leg, and it can change over time based on which leg the most turnover is generated.": "Tenga en cuenta que la pierna con la menor cantidad de puntos se llama \"pierna débil\" y la que tiene la mayor cantidad de puntos se llama \"fuerte\" o \"pierna poderosa\", y puede cambiar con el tiempo según en qué pierna se pierda más puntos. es generado.",
    "Mystery Box price": "Precio de la caja misteriosa",

    "Registration Date": "Fecha de Registro",
    "First Purchase Date": "Primera fecha de compra",
    "Product Autoship Valid Until": "Autoenvío del producto válido hasta",

    "Own Deposit": "Depósito propio",
    "Rank Achievement Bonus": "Bonificación por logro de rango",
    "Rank achievement bonus eligibility is based on rank achieved through qualifications.": "La elegibilidad para la bonificación por logro de rango se basa en el rango alcanzado a través de las calificaciones.",
    "Bonuses": "Bonificaciones",
    "Bonuses are paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET tokens": "Las bonificaciones se pagan siguiendo una proporción de 70/30, con un 70% en USDT y un 30% en tokens GYMNET.",
    "Rank current": "Rango {n}",
    "You will receive the bonus after the incentive is over, and you can view it in the 'My Wallet' section.": "Recibirá la bonificación una vez finalizado el incentivo, y podrá consultarla en la sección \"Mi cartera\".",
    "You must have at least RANK partners, with at least one in a different leg.": "Debes tener al menos {rank} compañeros, con al menos uno en una pierna diferente.",
    "Additional Assets": "Activos adicionales",
    "Back to My wallet": "Volver a Mi cartera",
    "Bonus points": "Puntos de bonificación",
    "Fee": "Tarifa",
    "Bonus Details": "Detalles de la bonificación",
    "Details / weekly cap": "Detalles / límite semanal",
    "User / amount": "Usuario / importe",
    "Extended Ref Link": "Enlace de referencia ampliado",
    "Leadership": "Liderazgo",
    "Travel Incentive": "Incentivo de viaje",
    "Unlock the full potential of your Filecoin rewards as they come to life following your purchase of any of our special bundles. Once your Filecoin Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Desbloquee todo el potencial de sus recompensas Filecoin a medida que cobran vida tras la compra de cualquiera de nuestros paquetes especiales. Una vez que su saldo de Filecoin esté disponible, tendrá acceso a una descripción general de sus recompensas totales, recompensas recibidas y recompensas esperadas. Además, un temporizador de cuenta atrás muestra la próxima distribución de recompensas, asegurando que nunca se pierda las recompensas.",
    "is left to qualify": "${n} queda para calificar",
    "Check transactions": "Transacciones de cheques",
    "Direct partner own purchases": "Compras propias directas de socios",
    "Each of your direct partners on the left and right legs needs to generate a turnover value of $100.00.": "Cada uno de sus socios directos en los tramos izquierdo y derecho debe generar un valor de facturación de 100,00 dólares.",
    // Feature Prioritization
    "Participation to GYMSTREETs Elite Vote": "Participación en la votación de élite de GYMSTREET",
    "Prioritization ends in": "La priorización termina en",
    "Start Prioritizing": "Empezar a priorizar",
    "Your changes have been saved": "Tus cambios se han guardado",
    "features are still remaining": "Aún quedan <b>{n} funciones</b>",
    "feature is still remaining": "Aún queda <b>{n} característica</b>",
    "All prioritized": "Todas las prioridades",
    "GYMSTREETs Elite Vote Statistics": "Estadísticas de voto de élite de GYMSTREET",
    "Just NUM features to go! Complete your prioritization to help us shape community's vision.": "¡Sólo falta <b>{countText}</b>! Complete su priorización para ayudarnos a dar forma a la visión de la comunidad.",
    "You've prioritized the features. Please review your choices and hit Submit to finalize.": "You've prioritized the features. Please review your choices and hit <b>“Submit”</b> to finalize.",
    "Back to All Features": "Volver a Todas las funciones",
    "Changes saved": "Cambios guardados",
    "No changes": "Sin cambios",
    "Submit": "Enviar",
    "priority": "prioridad",
    "Set priority": "Establecer prioridad",
    "Feature details": "Detalles de la función",
    "Select a feature to see more info": "Seleccione una función para ver más información",
    "Prioritization Submitted": "Prioridad enviada",
    "The results will be available after the voting period ends. You'll be able to change your priorities until then.": "Los resultados estarán disponibles una vez finalizado el periodo de votación. Podrás cambiar tus prioridades hasta entonces.",
    "Go to Career Status page": "Ir a la página Situación profesional",
    "Keep Prioritizing": "Seguir priorizando",
    "Continue Prioritizing": "Seguir priorizando",
    "Review and Submit": "Revisar y enviar",
    "Thinking of reprioritizing?": "¿Está pensando en volver a priorizar?",
    "You've already submitted your priorities. If you want to reprioritize them, please make sure you hit Resubmit.": "Ya has enviado tus prioridades. Si desea volver a priorizarlos, asegúrese de presionar <b>“Volver a enviar”</b>.",
    "Got it": "Entiendo",
    "Resubmit": "Reenviar",
    "Priorities submitted": "Prioridades presentadas",
    "Submitted": "Enviado",
    "View Statistics": "Ver estadísticas",
    "Continue Prioritization": "Continuar priorizando",
    "Reprioritize": "Repriorizar",
    "Start Prioritization": "Iniciar priorización",
    "features": "características",
    "feature": "característica",
    "GYMSTREET Elite Vote": "Voto Elite de GYMSTREET",
    "Thanks for submitting! You can still reprioritize and resubmit your choices until the voting period ends.": "Gracias por enviar tu candidatura. Todavía puedes volver a priorizar y presentar tus opciones hasta que finalice el periodo de votación.",
    "Youre not done yet. Continue prioritizing the upcoming features and submit your final choices.": "Aún no has terminado. Siga priorizando las próximas funciones y envíe sus opciones definitivas.",
    "Exclusive for Platin and higher rank members. Prioritize the features you want to see next on GYMSTREET.": "Exclusivo para miembros Platin y de rango superior. Prioriza las funciones que quieres ver próximamente en GYMSTREET.",
    "View rules": "Ver normas",
    "Prioritization has ended. See the community's vote results.": "La priorización ha finalizado. Consulte los resultados de la votación de la comunidad.",
    "Stay tuned": "Permanezca atento",
    "See current community prioritization statistics below. In 7 days, we'll also announce our decision right here, revealing the selected new features and their estimated release dates.": "Consulte a continuación las estadísticas actuales de priorización de la comunidad. Dentro de 7 días también anunciaremos nuestra decisión aquí mismo, revelando las nuevas funciones seleccionadas y sus fechas de lanzamiento estimadas.",
    "Terms and Conditions for GYMSTREETs Elite Vote": "Condiciones para el voto de élite de GYMSTREET",
    // Suspicous activity
    "You have one attempt left": "Le queda un intento. Después, tus intentos para esta cuenta estarán temporalmente restringidos.",
    "You have no attempts left": "No le quedan intentos para esta cuenta. Inténtalo de nuevo en {minutesText} <b>minutos</b> o restablece tu contraseña.",
    "Sorry, you’ve been blocked": "Lo sentimos, ha sido bloqueado",
    "Your IP address has been temporarily blocked for security reasons.": "Su dirección IP ha sido bloqueada temporalmente por razones de seguridad. Por favor, espere, pronto será redirigido automáticamente.",
    "If you believe this was a mistake, please contact us through our Customer Support.": "Si cree que se trata de un error, póngase en contacto con nosotros a través de nuestro <a href=\"mailto:support@gymstreet.io?subject=Suspicious Activity&body=I am blocked due to suspicious activity, but I think It's a mistake\" class=\"customer-support-button\">Servicio de Atención al Cliente.</a>",
    // Instant commissions
    "The period for earning mining rewards in BITOPEX tokens has officially concluded. Thanks for your participation. Enjoy your BPX earnings.": "El período para obtener recompensas mineras en tokens BITOPEX ha concluido oficialmente. Gracias por tu participación. Disfrute de sus ganancias de BPX.",
    "We're enhancing our claim feature, so it will be offline, for the next {hours} hours. Thank you and stay tuned!": "Estamos mejorando nuestra función de reclamaciones, por lo que estará desconectada durante las próximas {hours} horas. Gracias y permanezca atento.",
    "Eligibility for a one-time 10% commission, based on the total turnover generated from direct sales, requires referring team members and achieving a turnover of $5,000 or more in direct sales.": "La elegibilidad para una comisión única del 10%, basada en la facturación total generada por las ventas directas, requiere recomendar a miembros del equipo y lograr una facturación de $5,000 o más en ventas directas.",

    "Turnover Incentive": "Incentivo de facturación",
    "Available For Limited time only": "Disponible solo por <b>tiempo limitado</b>",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving $20,000 in direct sales.": "Tenga en cuenta que es elegible para recibir un paquete R8 de cortesía al alcanzar $20,000 en ventas directas.",
    "Commission payments for direct sales are now distributed following a 70/30% ratio: 70% in USDT and 30% in GYMNET": "Los pagos de comisiones por ventas directas se distribuyen ahora siguiendo una proporción del 70/30%: 70% en USDT y 30% en GYMNET.",
    "Minting Rewards": "Recompensas de acuñación",
    "Please check transactions if your {asset} balance is not visible": "Por favor verifique las transacciones si el saldo de su {asset} no está visible",
    "In order to send {asset} you need to select {chain} network and wallet that is connected to your account.": "Para enviar {asset} debes seleccionar la red {chain} y la billetera que esté conectada a tu cuenta.",
    "Cash bonus eligibility is based on rank achieved through qualifications. Participants qualifying with over 50% before March 4th, 2024, will not receive the bonus.": "La elegibilidad para la bonificación en efectivo se basa en el rango alcanzado a través de las calificaciones. Los participantes que califiquen con más del 50% antes del 4 de marzo de 2024 no recibirán el bono.",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving 100k in team purchases.": "Tenga en cuenta que es elegible para recibir un paquete R8 de cortesía al alcanzar 100k en compras de equipo.",

    "Convert": "Convertir",
    "Convert your unused miners into hashpower to earn rewards in Gymnet and Filecoin": "Convierte tus mineros no utilizados en hashpower para ganar recompensas en Gymnet y Filecoin.",
    "The 50% Turnover Rule is applied to this incentive": "A este incentivo se le aplica la Regla del 50% de Rotación",

    "Super Bundle Rank Benefits": "Beneficios de rango del súper paquete",
    "Matching bonus up to NUM levels": "Bonificación equivalente hasta {num} niveles",
    "Up to NUM levels": "Hasta {num} niveles",
    "Left leg partner": "Compañero de pierna izquierda",
    "Right leg partner": "Compañero de pierna derecha",
    "Commission Activation Requirements": "Requisitos de activación de la comisión",
    "Up to 1 level": "Hasta 1 nivel",
    "Bonus is paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET": "El bono se paga siguiendo una proporción de 70/30, con 70% en USDT y 30% en GYMNET",
    "Current Turnover": "Facturación actual",
    "You will receive the bonus once the incentive period is over and it will be deposited directly into your wallet.": "Recibirás el bono una vez finalizado el período de incentivo y se depositará directamente en tu billetera.",
    "Purchase Amount": "Monto de la compra",
    "Spring": "Primavera",
    "Spring Incentive": "Incentivo de primavera",
    "Commission Earned": "Comisión ganada: <b>${n}</b>",
    "Bonus Earned": "Bono ganado: <b>${n}</b>",
    "Earn 1 PAP point for every dollar spent on purchases using a utility balance or when you purchase a product autoship.": "Gane 1 punto PAP por cada dólar gastado en compras utilizando un saldo de servicios públicos o cuando compre un producto de envío automático.",
    "Member": "Affiliate",
    "Trainee": "Trainee",
    "Rookie": "Rookie",
    "Silver": "Silver",
    "Gold": "Gold",
    "Platinum": "Platinum",
    "Double Platinum": "Double Platinum",
    "Diamond": "Diamond",
    "Double Diamond": "Double Diamond",
    "Red Diamond": "Red Diamond",
    "White Diamond": "White Diamond",
    "Crown Diamond": "Crown Diamond",
    "Platin": "Platinum",
    "Double Platin": "Double Platinum",
    "Single Pool": "Single Pool",
    "Explorer": "Explorer",
    "Mastery": "Mastery",

    // CashFT ----
    "Features expected timeframes revealed": "Presenta los plazos esperados revelados",
    "Find estimated timelines for features prioritized by our community and aligned with our strategy.": "Encuentre cronogramas estimados para funciones priorizadas por nuestra comunidad y alineadas con nuestra estrategia.",
    "Estimated timeframe": "Plazo estimado",
    "Undefined": "Indefinido",
    "No Longer Valid": "Ya no es valido",
    "Deposit to Single pool": "Depósito a grupo individual",
    "Max": "máx.",
    "Received": "Recibió",
    "About voting rights": "Sobre los derechos de voto",
    "Your first deposit must be over $25": "Su primer depósito debe ser superior a $25",
    "Staking period ends": "Finaliza el periodo de apuesta",
    "December": "Diciembre",
    "Deposit & Stake": "Depósito y apuesta",
    "Purchasing the card requires passing the KYC procedure.": "Para comprar la tarjeta es necesario pasar el procedimiento KYC.",
    "Continue to KYC": "Continuar a KYC",
    "KYC Verification": "Verificación KYC",
    "Step": "Paso {n}",
    "Upload ID": "Cargar ID",
    "Male": "Masculino",
    "Female": "Femenino",
    "Gender": "Género",
    "ID Type is required": "Se requiere tipo de identificación",
    "ID Document Language is required": "Se requiere el idioma del documento de identidad",
    "ID Expiration Date is required": "Se requiere fecha de vencimiento de la identificación",
    "ID Issue Date is required": "Se requiere fecha de emisión de identificación",
    "State must be at least 3 characters": "El estado debe tener al menos 3 caracteres.",
    "ID Type": "tipo de identificación",
    "National Identity Card": "Documento de identificación nacional",
    "Driver's License": "Licencia de conducir",
    "Passport": "Pasaporte",
    "ID Document Language": "Idioma del documento de identidad",
    "ID Number be at least 3 characters": "El número de identificación debe tener al menos 3 caracteres",
    "ID Issue Date": "Fecha de emisión de identificación",
    "ID Expiration Date": "Fecha de vencimiento del documento de identidad",
    "You need to upload a photo of your document Please upload a png or jpeg, not a pdf.": "Debes cargar una foto de tu documento. \n Sube un <span style='color: #1A6C99'>png o jpeg, no un pdf</span>.",
    "File size exceeds {n} MB.": "El tamaño del archivo supera los {n} MB.",
    "Front side of ID": "Parte frontal de la identificación",
    "Back side of ID": "Parte posterior de la identificación",
    "Upload proof of address document": "Cargar documento de comprobante de domicilio",
    "Upload proof of address, which must not be older than 75 days.": "Cargar comprobante de domicilio, el cual no debe tener una antigüedad mayor a 75 días.",
    "Acceptable forms of proof of address include a credit card statement, utility bill, bank statement or bank letter.": "Las formas aceptables de comprobante de domicilio incluyen un extracto de la tarjeta de crédito, una factura de servicios públicos, un extracto bancario o una carta bancaria.",
    "Address Document Type is required": "Dirección Tipo de documento es obligatorio",
    "Document Issued by is required": "Se requiere documento emitido por",
    "Document Issued Date is required": "Se requiere la fecha de emisión del documento",
    "Address Document Type": "Tipo de documento de dirección",
    "Credit Card Statement": "Extracto de tarjeta de crédito",
    "Utility Bill": "Factura de servicios públicos",
    "Bank Statement": "Extracto de cuenta",
    "Bank Letter": "Carta bancaria",
    "Document Issued by": "Documento emitido por",
    "Document Issued Date": "Fecha de emisión del documento",
    "Please upload a legible document in png or jpeg format.": "Cargue un documento legible <span style='color: #1A6C99'>en formato png o jpeg.</span>",
    "Submit for Verification": "Enviar para verificación",
    "Your documents have been submitted for verification. Once verification has been approved, you can continue your purchase.": "Sus documentos han sido enviados para su verificación. Una vez aprobada la verificación, podrás continuar con tu compra.",
    "You need more GYMNET to make a deposit in Single Pool": "Necesitas <b>{n} GYMNET más</b> para realizar un depósito en Single Pool",
    "You need more USDT to purchase a bundle": "Necesitas <b>{n} USDT más</b> para comprar un paquete",
    "Security Assurance": "Seguridad\nGarantía",
    "Global Accessibility": "Accesibilidad\nglobal",
    "Affiliate Earnings": "Afiliado\nGanancias",
    "Effortless Integration": "Integración sin esfuerzo\n",
    "Cards": "Tarjetas",
    "Overview": "Descripción general",
    "Information": "Información",
    "Invoices": "Facturas",
    "KYC Status": "Estado KYC",
    "Try again": "Intentar otra vez",
    "Apply here": "Aplicar aquí",
    "Not verified": "No verificado",
    "Pending verification": "Verificación pendiente",
    "Rejected": "Rechazado",
    "Verified": "Verificado",
    "Next service fee in": "Próxima tarifa de servicio en",
    "No active membership": "Sin membresía activa",
    "Card": "Tarjeta",
    "Card number": "Número de tarjeta",
    "PIN Set Successfully": "PIN establecido correctamente",
    "Go to Dashboard": "Ir al panel de control",
    "4-digit PIN": "PIN de 4 dígitos",
    "Re-enter 4-digit PIN": "Vuelva a ingresar el PIN de 4 dígitos",
    "Set PIN": "Establecer PIN",
    "Your account is now verified and you can continue your purchase of the bundle.": "Su cuenta ahora está verificada y puede continuar con la compra del paquete.",
    "Continue purchase": "Continuar compra",
    "Your documents have been rejected from KYC provider.": "Sus documentos han sido rechazados por el proveedor KYC.",
    "Please try again": "Inténtalo de nuevo",
    "Your KYC is approved": "Tu KYC está aprobado",
    "Your KYC is rejected": "Su KYC es rechazado",
    "File is required": "Se requiere archivo",
    "Seamless Spending with GYMSTREET Cards": "Gaste sin problemas con las tarjetas GYMSTREET",
    "Our cards open a world of smooth transactions and rewarding opportunities for you.": "Nuestras tarjetas le abren un mundo de transacciones fluidas y oportunidades gratificantes.",
    "Card Types Tailored to Your Needs": "Tipos de tarjetas adaptadas a sus necesidades",
    "Card design": "Diseño de tarjeta",
    "Card information": "Información de la tarjeta",
    "Your go-to choice for daily transactions and affiliate commissions, offering practical benefits and reliable service within GYMSTREET and beyond.": "Su opción preferida para transacciones diarias y comisiones de afiliados, que ofrece beneficios prácticos y un servicio confiable dentro de GYMSTREET y más allá.",
    "Elevate your financial experience with this premium option with expanded benefits, designed for those who seek luxury and exclusivity.": "Eleva tu experiencia financiera con esta opción premium con beneficios ampliados, diseñada para quienes buscan lujo y exclusividad.",
    "Cards come in Bundles": "Las tarjetas vienen en paquetes",
    "Explorer card and 2 parcels": "Tarjeta Explorer y 2 paquetes.",
    "Mastery card and 3 parcels": "Tarjeta de Mastery y 3 paquetes.",
    "Getting Started with Your GYMSTREET Card Experience": "Comenzando con su experiencia con la tarjeta GYMSTREET",
    "Start securely with our simple KYC process, ensuring a safe and personalized Card experience.": "Comience de forma segura con nuestro sencillo proceso KYC, que garantiza una experiencia de Tarjeta segura y personalizada.",
    "Deposit Tokens": "Fichas de depósito",
    "Stake the required GYMNET tokens - 500 for Explorer or 1500 for Mastery - to initiate your application.": "Apueste los tokens GYMNET necesarios (500 para Explorer o 1500 para Mastery) para iniciar su solicitud.",
    "Order Your Card": "Ordene su tarjeta",
    "Make your final decision on card type and place your order buying one of the 2 card bundles.": "Tome su decisión final sobre el tipo de tarjeta y haga su pedido comprando uno de los 2 paquetes de tarjetas.",
    "Get Your Card": "Obtenga su tarjeta",
    "Anticipate the arrival of your new financial companion, securely shipped and ready to integrate into your life.": "Anticipe la llegada de su nuevo compañero financiero, enviado de forma segura y listo para integrarse a su vida.",
    "Use Your Card": "Utilice su tarjeta",
    "Begin making purchases with your card and start earning affiliate commissions and more.": "Comience a realizar compras con su tarjeta y comience a ganar comisiones de afiliado y más.",
    "Card Operation Fees": "Tarifas de operación de tarjeta",
    "Fee Type": "Tipo de tarifa",
    "Both Cards (Explorer & Mastery)": "Ambas cartas (Explorer y Mastery)",
    "POS Transactions": "Transacciones POS",
    "ATM Withdrawals": "Retiros en cajeros automáticos",
    "Foreign Currency Conversion": "Conversión de moneda extranjera",
    "Card Replacement": "Reemplazo de tarjeta",
    "Simple, Transparent Pricing": "Precios simples y transparentes",
    "Monthly Membership Fee": "Cuota de membresía mensual",
    "Monthly Membership": "Membresía mensual",
    "Get Your Card Now": "Obtenga su tarjeta ahora",
    "Choose the card that best suits your lifestyle. With straightforward fees, a seamless onboarding process, and an 11-level deep affiliate commission reward system, these cards are your partner in financial empowerment.": "Elige la tarjeta que mejor se adapta a tu estilo de vida. Con tarifas sencillas, un proceso de incorporación fluido y un sistema de recompensas de comisiones de afiliados de 11 niveles, estas tarjetas son su socio en el empoderamiento financiero.",
    "How do I get a card?": "¿Cómo obtengo una tarjeta?",
    "Start by filling out the KYC form on our site. After we verify you, you'll need to deposit tokens for the card you want in Single Pool: 500 for Explorer or 1500 for Mastery. Then, you can order your card.": "Comience completando el formulario KYC en nuestro sitio. Después de que lo verifiquemos, deberá depositar tokens para la tarjeta que desea en Single Pool: 500 para Explorer o 1500 para Mastery. Entonces, puedes pedir tu tarjeta.",
    "How many tokens do I need to deposit for each card?": "¿Cuántos tokens necesito depositar por cada tarjeta?",
    "You need 500 tokens for the Explorer card and 1500 tokens for the Mastery card.": "Necesitas 500 fichas para la tarjeta Explorer y 1500 fichas para la tarjeta Mastery.",
    "When will my card arrive?": "¿Cuándo llegará mi tarjeta?",
    "After you order, your card will be sent out and should arrive quickly, so you can start using it soon.": "Después de realizar el pedido, se enviará su tarjeta y debería llegar rápidamente, para que pueda comenzar a usarla pronto.",
    "Can I earn affiliate commissions with my card?": "¿Puedo ganar comisiones de afiliado con mi tarjeta?",
    "Yes, activate your card, and you can begin earning through our 11-level deep affiliate program. Check our website for how the commissions work.": "Sí, active su tarjeta y podrá comenzar a ganar a través de nuestro programa de afiliados de 11 niveles. Consulte nuestro sitio web para saber cómo funcionan las comisiones.",
    "Total Value At Time of Deposit": "Valor total al momento del depósito",
    "Current Value": "Valor actual",
    "My claimed GYMNET rewards": "Mis recompensas GYMNET reclamadas",
    "My pending GYMNET rewards": "Mis recompensas pendientes de GYMNET",
    "Deposit": "Depósito",
    "Connected": "Conectado",
    "No deposit fee for burn": "Sin tarifa de depósito por quema",
    "Min amount": "Cantidad mínima",
    "Total GYMNET locked": "Total GYMNET bloqueado",
    "APR": "ABR",
    "Daily APR": "TAE diaria",
    "Total own claimed": "Total propio reclamado",
    "Reward per block": "Recompensa por bloque",
    "My Deposit": "Mi deposito",
    "Locked period until Dec 2027": "Período bloqueado hasta diciembre de 2027",
    "With Locking Period": "Con período de bloqueo",
    "Total Gymnet Deposit": "Depósito total de Gymnet",
    "Gymnet Rewards": "Recompensas de Gymnet",
    "Total Gymnet earned": "Total Gymnet ganado",
    "Active holdings": "Participaciones activas",
    "With locking period": "Con período de bloqueo",
    "Withdrawn holdings": "Participaciones retiradas",
    "Locked": "bloqueado",
    "Unlocked": "desbloqueado",
    "Deposit amount": "Cantidad del depósito",
    "Claimed rewards": "Recompensas reclamadas",
    "Start date": "Fecha de inicio",
    "End date": "Fecha final",
    "Withdraw": "Retirar",
    "First name": "Nombre de pila",
    "Last name": "Apellido",
    "Address": "DIRECCIÓN",
    "State": "Estado",
    "Phone number": "Número de teléfono",
    "Birth Date": "Fecha de nacimiento",
    "Submit & Continue": "Enviar y continuar",
    "ID Number": "Número de identificación",
    "ID Issued By": "Identificación emitida por",
    "Proof of address document": "Documento de comprobante de domicilio",
    "Proof of address document (optional)": "Documento de comprobante de domicilio (opcional)",
    "Statistics": "Estadísticas",
    "Personal Information": "Informacion personal",
    "First name is required": "Se requiere el primer nombre",
    "Last name is required": "Se requiere apellido",
    "Address is required": "La dirección es necesaria",
    "Please enter your email address": "Por favor, introduzca su dirección de correo electrónico",
    "Phone number is required": "Se requiere número de teléfono",
    "Date of birth is required": "Se requiere fecha de nacimiento",
    "ID Number is required": "Se requiere número de identificación",
    "ID Issued by is required": "Se requiere identificación emitida por",
    "Select asset to auto swap and stake": "Seleccione el activo para intercambiar y apostar automáticamente",
    "Giving swap allowance": "Dar asignación de intercambio",
    "Swap allowance given": "Asignación de intercambio otorgada",
    "Swapping tokens to GYMNET": "Cambiar tokens a GYMNET",
    "Tokens swapped to GYMNET": "Tokens intercambiados a GYMNET",
    "Giving stake allowance": "Dar asignación de participación",
    "Stake allowance given": "Asignación de participación otorgada",
    "Staking to single pool": "Apostar a un solo grupo",
    "Staked to single pool": "Apostado a un solo grupo",
    "1. Swap allowance (Only for USDT)": "1. Asignación de swap (solo para USDT)",
    "2. Swap to GYMNET": "2. Cambia a GYMNET",
    "3. Stake allowance": "3. Subsidio de participación",
    "4. Stake": "4. Estaca",
    "Actual balance:": "Saldo real:",
    "The transaction gas fee is reserved from the actual BNB balance.": "La comisión por gas de transacción se reserva del saldo real del BNB.",
    "Insufficient balance to make a swap": "Saldo insuficiente para realizar un swap",
    "Transactions gas fee is considered in BNB available balance": "La tarifa de gas de las transacciones se considera en el saldo disponible de BNB",
    "We will ask for allowance and try to swap your tokens to GYMNET, and after, stake them in single pool.": "Le pediremos una asignación e intentaremos intercambiar sus tokens por GYMNET y, luego, apostarlos en un solo grupo.",
    "Auto swap and stake": "Intercambio automático y apuesta",
    "Continue transactions": "Continuar transacciones",
    "Pause": "Pausa",
    "Swap & Stake": "Intercambiar y apostar",
    "Topup": "Recarga",
    "Proceed anyways": "Proceder de todos modos",
    "Explorer Card": "Tarjeta Explorer",
    "Mastery Card": "Tarjeta Mastery",
    // CashFT ----
    "Monthly spending max": "Gasto máximo mensual",
    "Monthly Balance limit": "Límite de saldo mensual",
    "Go to Cards": "Ir a Tarjetas",
    "Contact Support": "Soporte de contacto",
    "Deposit Fee": "Tarifa de depósito",
    "Switch to 'Utility' to view the price for each product with USDT & Utility. To partially pay for a product using Utility, you need to have enough USDT and Utility in your balance.": "Cambie a 'Utility' para ver el precio de cada producto con USDT y Utility. Para pagar parcialmente un producto usando Utility, debes tener suficientes USDT y Utility en tu saldo.",
    "Deposit with locking period": "Depósito con periodo de bloqueo",
    "The state must be in the form of a two-letter abbreviation in uppercase.": "El estado debe presentarse en forma de abreviatura de dos letras en mayúscula.",
    "You need to upload a photo in JPEG or PNG format, not a PDF. The document can be in either English or Spanish.": "Debes cargar una foto en formato <span style='color: #1A6C99'>jpeg</span> o <span style='color: #1A6C99'>png</span>, no en formato pdf. \n El documento puede estar en <span style='color: #1A6C99'>inglés</span> o <span style='color: #1A6C99'>español</span>.",
    "You need to upload a legible document in jpeg or png format, not a pdf. The document can be in either English or Spanish.": "Debes cargar un documento legible en formato <span style='color: #1A6C99'>jpeg</span> o <span style='color: #1A6C99'>png</span>, no en formato pdf. \n El documento puede estar en <span style='color: #1A6C99'>inglés</span> o <span style='color: #1A6C99'>español</span>.",
    "Commissions are paid out using a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "Las comisiones se pagan en una proporción de 70/30, con un 70% en monedas estables y un 30% en tokens GYMNET.",
    "This policy is effective as of September 25, 2023.": "Esta política entra en vigor a partir del 25 de septiembre de 2023.",
    "Required turnover": "Volumen de negocios requerido",
    "Switch to TOKEN to view the price for each product with USDT & TOKEN. To partially pay for a product using TOKEN, you need to have enough USDT and TOKEN in your balance.": "Cambie a '{TOKEN}' para ver el precio de cada producto con USDT y {TOKEN}. Para pagar parcialmente un producto usando {TOKEN}, debes tener suficientes USDT y {TOKEN} en tu saldo.",
    "Alternatively, you can use your CURRENCY balance to cover the gas fee.": "Alternativamente, puedes usar tu saldo de {currency_yg} para cubrir la tarifa del gas.",
    "Insufficient funds: to continue with payment use Utility or Gymnet balance": "Fondos insuficientes: para continuar con el pago utilice el saldo de Utility o Gymnet",
    "Use": "Usar",
    "Use Utility or Gymnet": "Utilice Utility o Gymnet",
    "Achieved PRICE turnover": "Se logró una facturación de ${price}",
    "Your current milestone": "Tu hito actual",
    "Reward History": "Historial de recompensas",
    "Leading Direct Partner": "Socio directo líder",
    "Remaining Direct Partners": "Socios directos restantes",
    "Bonus Split": "División de bonificación",
    "Monthly Countdown": "Cuenta regresiva mensual",
    "No direct partners": "Sin socios directos",
    "To receive the reward, you must have an active product autoship.": "Para recibir la recompensa, debe tener un envío automático de producto activo.",
    "This incentive is subject to the 50% Turnover Rule.": "Este incentivo está sujeto a la Regla de Rotación del 50%.",
    "Income From Product Authoship": "Ingresos por autoría de producto",
    "Your documents have been submitted for verification. The bank may take up to 3 business days to review your application. Once approved, you can continue with your purchase.": "Sus documentos han sido enviados para su verificación. El banco puede tardar hasta 3 días hábiles en revisar su solicitud. Una vez aprobado, podrás continuar con tu compra.",
    "To purchase the card, you need to agree to the T&C and complete the KYC verification process.": "Para comprar la tarjeta, debe aceptar los términos y condiciones y completar el proceso de verificación KYC.",
    "Only Latin letters are allowed": "Sólo se permiten letras latinas.",
    "First name must be at least 3 characters": "El nombre debe tener al menos 3 caracteres.",
    "First name should not exceed 10 characters": "El nombre no debe exceder los 10 caracteres.",
    "Should be only letters": "Deberían ser solo letras",
    "Address must be at least 3 characters": "La dirección debe tener al menos 3 caracteres.",
    "Only latin letters, numbers and special characters are allowed": "Sólo se permiten letras latinas, números y caracteres especiales.",
    "Email is not valid": "El correo no es válido",
    "Zip must contain only numbers with min length of 3": "El zip debe contener solo números con una longitud mínima de 3",
    "The phone number must be at least 6 digits": "El número de teléfono debe tener al menos 6 dígitos.",
    "The phone number must not exceed 12 digits": "El número de teléfono no debe exceder los 12 dígitos.",
    "Phone number should contain only numbers": "El número de teléfono debe contener solo números",
    "ID Number must have at least 3 characters": "El número de identificación debe tener al menos 3 caracteres",
    "ID Issued by must have at least 3 characters": "El ID emitido por debe tener al menos 3 caracteres.",
    "ID Issued by should not exceed 191 characters": "El ID emitido por no debe exceder los 191 caracteres.",
    "Last name must be at least 3 characters": "El apellido debe tener al menos 3 caracteres.",
    "State is required": "Se requiere estado",
    "The incentive countdown starts at the beginning of each month and ends at the end of the month. If you do not qualify for the bonus within that month, you will need to start again in the next month.": "La cuenta atrás del incentivo comienza al principio de cada mes y finaliza al final del mes. Si no califica para el bono dentro de ese mes, deberá comenzar nuevamente el mes siguiente.",
    "Activated": "Activada",
    "Deposit & fee commission": "Comisión de depósito y honorarios",
    "Web3 wallet not connected. Please connect to proceed.": "La billetera Web3 no está conectada. Por favor conéctese para continuar.",
    "Total investors profit": "Beneficio total de los inversores",
    "Commissions from vault investors": "Comisiones de inversores en bóveda",
    "Please Claim your rewards from to activate this section.": "Reclame sus recompensas de {n} para activar esta sección.",
    "Or use Button Below": "O use el botón de abajo",
    "Partner Activity": "Actividad de socios",
    "Pending Commissions": "Comisiones Pendientes",
    "Binary Turnover": "Rotación binaria",
    "Unilevel Turnover": "Rotación Uninivel",
    "Right Direct Partners": "Socios directos adecuados",
    "Left Direct Partners": "Socios directos de izquierda",
    "50% Compensation:": "50% Compensación:",
    "When you reach the next rank, you will receive 50% of the lost bonus from the previous rank.": "Cuando alcances el siguiente rango, recibirás el 50 % del bono perdido del rango anterior.",
    "Please note that username can be changed only once in 6 months.": "Tenga en cuenta que el nombre de usuario solo se puede cambiar una vez cada 6 meses.",
    "Binary Turnover in weak leg": "Giro binario en la pierna débil",
    "Unilevel turnover requirement": "Requisito de volumen de negocios uninivel",
    "Connect a WEB3 wallet such as Metamask or Trustwallet and pay with {currency}. This process requires a small BNB fee ($0.30) to handle transaction costs!": "Conecte una billetera WEB3 como Metamask o Trustwallet y pague con {currency}. ¡Este proceso requiere una pequeña tarifa de BNB ($0,30) para manejar los costos de transacción!",
    "Use your account's wallet balance to purchase the product.": "Utilice el saldo de la billetera de su cuenta para comprar el producto.",
    "Seems like your crypto hasn't arrived yet": "Parece que tu cripto aún no ha llegado",
    "Wait more": "Espera más",
    "Or topup with": "O recargar con",
    "Connected to web3": "Conectado a web3",
    "Reset": "Reiniciar",
    "Top Up Successful": "Recarga exitosa",
    "Top Up Successful. Card balance will be updated soon": "Recarga exitosa. El saldo de la tarjeta se actualizará pronto",
    "Please activate your card and top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "Por favor, active su tarjeta y recargue el saldo para cubrir la cuota mensual. Si el saldo no se actualiza, tu tarjeta se desactivará y tendrás que comprar una nueva.",
    "PERCENTAGE allocation from Global Pool": "{perc}% de asignación del Fondo Global",
    "You need to reach rank RANK_NAME to participate in this pool.": "Debes alcanzar el rango \"{rank_name}\" para participar en este grupo.",
    "Global Pool": "Fondo Mundial",
    "Next Distribution In:": "Próxima Distribución En:",
    "50% Rule": "Regla del 50%",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%": "La cuota máxima que cada socio directo puede aportar al volumen de negocios exigido es de hasta el 50%.",
    "Sub Pools": "Subgrupos",
    "Congratulations! You have qualified for a share of the Global Pool.": "¡Felicidades! Usted ha calificado para recibir una parte del Fondo Global.",
    "You will receive your share once the countdown is complete.": "Recibirás tu parte una vez que se complete la cuenta regresiva.",
    "Please top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "Por favor, recargue el saldo para cubrir la cuota mensual. Si el saldo no se actualiza, tu tarjeta se desactivará y tendrás que comprar una nueva.",
    "You need to deposit VALUE worth of GYMNET to upgrade your weekly binary cap": "Necesitas depositar {valor} de GYMNET para mejorar tu tope binario semanal",
    "Binary Max Weekly Payout": "Pago semanal máximo binario",
    "Required Single Pool Deposit To Upgrade": "Depósito único requerido para subir de categoría",
    "Upgrade Binary Max Weekly Payout": "Actualizar pago semanal máximo binario",
    "Weekly Cap Upgraded": "Cap semanal actualizado",
    "Your 8 months period for earning token rewards in Filecoin has officially concluded. Thank you for your participation. Enjoy your FIL earnings.": "Su periodo de 8 meses para ganar tokens de recompensa en Filecoin ha concluido oficialmente. Gracias por su participación. Disfrute de sus ganancias FIL.",
    "As previously announced Filecoin will be replaced by Moonberg token, which will be distributed for a period of 24 months. The Moonberg launch is expected for August 2024 and the distribution will start one week after that.": "Como se anunció anteriormente, Filecoin será sustituido por el token Moonberg, que se distribuirá durante un periodo de 24 meses. El lanzamiento de Moonberg está previsto para agosto de 2024 y la distribución comenzará una semana después.",
    "Username must not exceed 255 characters": "El nombre de usuario no debe superar los 255 caracteres",
    "Upgrade": "Actualización",
    "Product Autoship Monthly": "Envío automático de producto mensual",
    "Product Autoship Yearly": "Envío automático de producto anual",
    "Product Owner": "Propietario del producto",
    "Myself": "Yo mismo",
    "Another user": "Otro usuario",
    "Payment": "Pago",
    "PAP Balance": "Saldo PAP",
    "Purchased By": "Comprado por",
    "Purchased For": "Comprado para",
    "Single parcel": "Un solo paquete",
    "Single miner": "Un solo minero",
    "completed": "Terminada",
    "pending": "Pendiente",
    "expired": "Caducado",

    "Select {n} blockchain to send the transaction": "Selecciona {n} blockchain para enviar la transacción",
    "We recommend waiting to claim your rewards when the gas fee is lower than the amount you are claiming.": "Te recomendamos esperar para reclamar tus recompensas cuando la tarifa de gas sea menor que el monto que estás reclamando.",
    "Total Value Of Purchases": "Valor total de las compras",
    "The total value of all purchases excluding purchases related to Card Bundles, granted products and product autoship subscriptions.": "El valor total de todas las compras, excluidas las compras relacionadas con paquetes de tarjetas, productos otorgados y suscripciones de envío automático de productos.",
    "Total Receivable Tokens": "Total de tokens por cobrar",
    "The number of Moonberg tokens you will receive over the next 24 months.": "La cantidad de tokens Moonberg que recibirá en los próximos 24 meses.",
    "Realtime ROI": "Retorno de la inversión en tiempo real",
    "Current Return on Investment (ROI) based on the real-time price of the Moonberg token.": "Retorno de la inversión (ROI) actual basado en el precio en tiempo real del token Moonberg.",
    "Token Calculation Breakdown": "Desglose del cálculo de tokens",
    "{n}% of your total purchase value is allocated for purchasing {asset} tokens": "El {n} % del valor total de su compra se asigna a la compra de tokens {asset}",
    "Moonberg tokens will be distributed upon the official release of the Moonberg token.": "Los tokens Moonberg se distribuirán tras el lanzamiento oficial del token Moonberg.",
    "Moonberg Allocation": "Asignación de Moonberg",
    "BTC Balance": "Saldo de BTC",
    "The funds will be transferred via the BEP-20 BNB network.": "Los fondos se transferirán a través de la red BEP-20 BNB.",
    "A bundle with minting power will generate BTC token rewards, which will be distributed every Monday.": "Un paquete con poder de acuñación generará recompensas en tokens BTC, que se distribuirán todos los lunes.",
    "Weekly rewards expected": "Se esperan recompensas semanales",
    "Direct Sales Incentive": "Incentivo de venta directa",
    "You are about to activate your affiliate referral link.": "Estás a punto de activar tu enlace de referencia de afiliado.",
    "Upon activation, a period of free Product Autoship will be initiated starting from the date of your registration.": "Tras la activación, se iniciará un período de envío automático de productos gratuito a partir de la fecha de tu registro.",
    "If you confirm the activation, a mandatory monthly Product Autoship will be required to be eligible to receive commissions.": "Si confirmas la activación, se requerirá un envío automático de productos mensual obligatorio para poder recibir comisiones.",
    "Keep your product autoship active to maintain your commission eligibility for commission ranks Platinum and above.": "Mantenga activo el envío automático de su producto para mantener su elegibilidad de comisión para los rangos de comisión Platino y superiores.",
    "Buy Product Autoship": "Comprar producto con envío automático",
    "Your current rank is achieved through qualification.": "Tu rango actual se obtiene a través de la calificación.",
    "Your commission rank is determined by your actual rank or a super bundle purchase—whichever is higher will be considered.": "Tu rango de comisión se determina por tu rango actual o por la compra de un paquete de bonificación; se tendrá en cuenta el que sea más alto.",
    "Your card has been deactivated due to an unpaid monthly subscription. For assistance or any questions, please reach out to our customer support team.": "Su tarjeta {card_name} ha sido desactivada debido a una suscripción mensual impaga. Si necesita ayuda o tiene alguna pregunta, comuníquese con nuestro equipo de atención al cliente.",

    "Subject": "Asunto",
    "topup": "Recarga",
    "withdraw": "Retirada",
    "failed": "Error",
    "3rd Anniversary": "3.er aniversario",
    "3rd Anniversary Promo": "Promoción del 3.er aniversario",
    "Your current bonus amount": "Su monto de bonificación actual",
    "All products are eligible for the incentive, except for Product Autoship. Rewards will be distributed on November 1, 2024, and the bonus will be deposited directly into your internal wallet.": "Todos los productos son elegibles para el incentivo, excepto el envío automático de productos. Las recompensas se distribuirán el 1 de noviembre de 2024 y la bonificación se depositará directamente en su billetera interna.",

    

























    // Affility translations
    "close": "Cerca",
    "cancel": "Cancelar",
    "yes_i_confirm": "Sí, lo confirmo",
    "something_went_wrong_try_again": "Algo salió mal. Inténtalo de nuevo",
    "or": "O",
    "success": "Éxito",
    "ok": "OK",
    "retry": "Rever",
    "home": "Hogar",
    "log_in": "Acceso",
    "join_now": "Únete ahora",
    "toggle_notifications": "Alternar notificaciones",
    "active": "Activo",
    "inactive": "Inactivo",
    "product_autoship": "Envío automático del producto",
    "balances": "Saldos",
    "contract_addresses": "Direcciones de contrato",
    "address_add_to_wallet_success": "La dirección se ha agregado correctamente a su billetera",
    "language": "Idioma",
    "choose_language": "Elige lengua",
    "profile": "Perfil",
    "settings": "Ajustes",
    "learn": "Aprender",
    "help_center": "Centro de ayuda",
    "log_out": "Cerrar sesión",
    "theme": "Tema",
    "back": "Atrás",
    "forward": "Adelante",
    "previous": "Anterior",
    "next": "Próximo",
    "jump_to_top": "Saltar a la cima",
    "load_more": "Carga más",
    "proceed": "Proceder",
    "notifications": "Notificaciones",
    "notifications_loading_error_description": "Asegúrate de que el dispositivo está conectado a Internet, o prueba a reiniciar el router o el módem.",
    "notifications_empty_title": "La lista de notificaciones está vacía.",
    "notifications_empty_description": "Realice un seguimiento de todas sus actualizaciones y recordatorios en un lugar conveniente con nuestra función de notificaciones.",
    "email_verification_sent_title": "verificacion de email",
    "email_verification_sent_description": "Le enviamos un correo electrónico a {email} con un enlace para finalizar su registro",
    "email_verification_required": "Se requiere verificación por correo electrónico",
    "email_verification_confirm": "Por favor confirma tu correo electrónico para continuar",
    "email_verification_verify": "Verifica tu correo electrónico para continuar",
    "new_email": "Nuevo Email",
    "enter_new_email": "Introduce un nuevo correo electrónico",
    "change_email": "Cambiar e-mail",
    "resend_email": "Reenviar email",
    "upgrade_to_affiliate": "Actualizar a afiliado",
    "upgrade_to_affiliate_success": "Has realizado con éxito la actualización a Afiliado",
    "become_affiliate": "Conviértete en afiliado",
    "upgrade_to_affiliate_description_free_autoship": "Estás a punto de activar tu enlace de referencia de afiliado. Tras la activación, se iniciará un período de envío automático de productos gratuito a partir de la fecha de su registro.",
    "upgrade_to_affiliate_description_mandatory_autoship": "Estás a punto de activar tu enlace de referencia de afiliado. Si confirma la activación, se requerirá un envío automático de producto mensual obligatorio para ser elegible para recibir comisiones.",
    "t&c": "Términos y condiciones",
    "accept_t&c": "Aceptar y cerrar",
    "affiliate_t&c": "Términos y condiciones de afiliados",
    "cards_t&c": "Términos y condiciones de las tarjetas",
    "i_have_read_and_accept_the_{prop}": "He leído y acepto la {prop}",
    "switch_language": "Cambiar idioma",
    "switch_theme": "Cambiar tema",
    "enjoy_more_benefit_more": "¡Disfruta más! ¡Benefíciese más!",
    "buy_products_at_least_${n}": "Compra productos por al menos ${n}",
    "go_to_products": "Ir a productos",
    "free_autoship": "Envío automático de productos gratuito para Platinum y superiores",
    "{n}%_binary_commission": "{n}% de comisión binaria",
    "rank": "Rango",
    "role": "Role",
    "bundle": "Manojo",
    "current_rank": "Posición actual",
    "current_role": "Rol actual",
    "customer": "Cliente",
    "get_your_bundle": "Consigue tu paquete",
    "nft_turbo_expires_in": "El descuento especial del súper paquete vence en",
    "left_leg_link": "Enlace de la pierna izquierda",
    "right_leg_link": "Enlace de la pierna derecha",
    "sponsor_id": "Documento de identidad del patrocinador",
    "total_income": "Ingresos totales",
    "current_cv_balance": "Saldo CV actual",
    "progress_until_rank_{n}": "Progreso hasta el rango {n}",
    "to_reach_rank_{n}_you_need": "Para alcanzar el rango {n} necesitas",
    "nft_own_purchases": "Compras propias de NFT",
    "direct_partner_own_purchases": "Compras propias del socio directo",
    "team_turnover": "Rotación del equipo",
    "required_qualified_partners": "Socios calificados requeridos",
    "see_details": "Ver detalles",
    "soon": "Pronto",
    "new": "Nueva",
    "promos": "Promociones",
    "coming_soon": "Muy pronto",
    "team_statistics": "Estadísticas del equipo",
    "all_time": "Todo el tiempo",
    "weekly": "Semanalmente",
    "monthly": "Mensual",
    "yearly": "Anual",
    "left_leg": "Pierna izquierda",
    "right_leg": "Pierna derecha",
    "all_partners": "Todos los socios",
    "direct_partners": "Socios directos",
    "all_active_partners": "Todos los socios activos",
    "all_inactive_partners": "Todos los socios inactivos",
    "become_affiliate_partner": "Conviértase en socio afiliado",
    "gain_fresh_perspectives": "Obtenga nuevas perspectivas y beneficios exclusivos.",
    "autoship_required_to_be_affiliate": "Se requiere envío automático de productos mensual para ser socio afiliado y ganar comisiones.",
    "for_limited_time_only": "Sólo por tiempo limitado",
    "placement_in_unilevel": "Colocación en Uninivel",
    "placement_in_binary": "Colocación en binario",
    "cv_points": "Puntos CV",
    "matching_bonuses": "Bonos equivalentes",
    "leadership_incentives": "Incentivos de liderazgo",
    "autoship_incentive": "Incentivo de envío automático de productos",
    "binary_tree_statistics": "Estadísticas de árboles binarios",
    "unilevel_tree_statistics": "Estadísticas de árboles uninivel",
    "active_partners": "Socios activos",
    "inactive_partners": "Socios inactivos",
    "The minimum amount should be equal to or greater than NUM GYMNET": "El monto mínimo debe ser igual o mayor a {num} GYMNET",
    "select_the_team": "Selecciona el equipo",
    "new_users_will_be_placed_under_username_in_the_left_team": "Los nuevos usuarios serán ubicados bajo {USERNAME} en el equipo {LEG}.",
    "done": "Listo",
    "right": "derecha",
    "left": "izquierda",
};
