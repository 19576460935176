export default {
    "Learn how it works": "Aprenda como funciona",
    "Buy parcel": "Comprar pacote",
    "Wallets": "Carteiras",
    "To GymNetwork": "Para o GymNetwork",
    "Connect Wallet": "Conectar Carteira",
    "Pending rewards": "Recompensas pendentes",
    "Coming Soon": "Em breve",
    "Claim": "Resgatar",
    "Inventory": "Inventário",
    "Lands": "Terras",
    "Miners": "Mineradoras",
    "Attach": "Anexar",
    "Attach to parcel": "Anexar a um pacote",
    "Investment": "Investimento",
    "Wallet": "Carteira",
    "Your address": "Seu endereço",
    "History": "Histórico",
    "Map": "Mapa",
    "Buy miner": "Buy mineradora",
    "Buy Miner": "Buy Mineradora",
    "Get ": "Receber ",
    "Get Miner": "Receber Mineradora",
    "Upgrade parcel": "Melhorar pacote",
    "Soon": "Breve",
    "Upgraded":"Atualizado",
    "Upgraded land has 10 slots for putting miner NFTs. Upgraded land does not have more physical space, it’s only that you can stake more miners.": "A terra atualizada tem 10 vagas para colocar mineradoras de NFT. A terra atualizada não possui mais espaço físico, é que você poderá colocar mais mineradoras de NFT.",
    "Next": "Próximo",
    "Back": "Voltar",
    "Skip guide": "Pular tutorial",
    "Number of miners you want to buy": "Número de mineradores duplos que você deseja comprar",
    "Attach Miner": "Anexar Mineradora",
    "Upgrade for": "Atualizar para",
    "On Sale": "A venda",
    "Type": "Tipo",
    "Location": "Localização",
    "Size": "Tamanho",
    "Buy now": "Comprar agora",
    "Email": "E-mail",
    "Username": "Nome de usuário",
    "Verify Email": "Verificar E-mail",
    "Index": "Índice",
    "Verification Code": "Código de Verificação",
    "Log out": "Sair",
    "Select all": "Selecionar todos",
    "Cart": "Carrinho",
    "Total": "Total",
    "Register": "Registrar",
    "Dashboard": "Painel",
    "Transaction success!": "Transação com sucesso!",
    "I`ve read Terms & Conditions": "Eu li os Termos e Condições",
    "Terms & conditions": "Termos e condições",
    "Affiliate Terms & Conditions": "Termos e Condições de Afiliados",
    "Accept Terms & Conditions": "Aceitar Termos e Condições",
    "Loading! Please Wait ...": "Carregando! Por favor aguarde...",
    "Initializing Wallet! Please Wait ...": "Inicializando a Carteira! Por favor, aguarde ...",
    "Login In Progress! Please Wait ...": "Entrada em progresso! Por favor, aguarde ...",
    "Enter your email": "Digite seu e-mail",
    "Confirm your Email": "Confirmar seu E-mail",
    "Enter username": "Digite o nome de usuário",
    "To dashboard": "Ao painel",
    "You successfully verified your email": "Você verificou com sucesso seu e-mail",
    "View map": "Ver mapa",
    "Log in to buy": "Faça login para comprar",
    "Congratulations": "Parabéns",
    "Buy" : "Comprar",
    "Miner" : "Mineradora",
    "Parcel" : "Pacote",
    "Upgrade": "Atualização",
    "Load more": "Carregar mais",
    "All": "Todos",
    "Basic": "Básico",
    "Activate": "Ativar",
    "Basic Miner": "Mineradora Básica",
    "In use": "Em uso",
    "Expired": "Vencido",
    "Available": "Disponível",
    "Standard parcel": "Pacote padrão",
    "Success": "Sucesso",
    "Something Went Wrong": "Algo Deu Errado",
    "Loading": "Carregando",
    "Invalid Verification Code": "Código Inválido de Verificação",
    "Invalid Email Address": "Endereço de E-mail Inválido",
    "Invalid Arguments Supplied": "Argumentos Fornecidos Inválidos",
    "Email Already Exists": "E-mail Já Existe",
    "UnAuthorized": "Não Autorizado",
    "User Update Failed": "Atualização do Usuário Falhou",
    "Permission Denied": "Permissão Negada",
    "Basic miner": "Mineradora básica",
    "Remove": "Remover",
    "Special offers": "Ofertas especiais",
    "Please Wait": "Por favor, aguarde",
    "Transaction Rejected": "Transação Rejeitada",
    "sold": "vendido",
    "The parcel price will be increased after certain percentage of parcels will be sold. Bundle prices will be increased accordingly.": "O preço do pacote aumentará após um certo percentual de pacotes serem vendidos. Os preços para mais pacotes aumentarão proporcionalmente.",
    "Sold": "Vendido",
    "Parcel price index": "Índice do preço do pacote",
    "Current parcel price": "Preço atual do pacote",
    "Parcels sold": "Parcelas vendidas",
    "Parcel stats": "Estatísticas do pacote",
    "Help center": "Centro de ajuda",
    "Partner area": "Área do parceiro",
    "to_dashboard": "Para o painel",
    "Transaction Success": "Transação feita com sucesso",
    "parcels available": "pacotes disponíveis",
    "Apply": "Aplicar",
    "Ocean": "Oceano",
    "Near Ocean": "Oceano próximo",
    "Insufficient USDT amount": "Quantia insuficiente de USDT",
    "Insufficient {asset} amount": "Quantia insuficiente de {asset}",
    "Miner Rewards": "Recompensas de Mineradora",
    "No data found": "Nenhum dado encontrado",
    "Full": "Completo",
    "Standard": "Padrão",
    "Attach miners": "Anexar mineradoras",
    "Upgraded Parcel": "Pacote Atualizado",
    "Discount": "Desconto",
    "save": "salvar",
    "Copied": "Copiado",
    "Marketplace": "Marketplace",
    "Contract Address": "Endereço do contrato",
    "Wallet Address": "Endereço da carteira",
    "Inland": "Interior",
    "Business": "O negócio",
    "parcels": "parcelas",
    "parcel": "parcela",
    "Parcel name": "Nome do pacote",
    "Basic Bundle": "Pacote Básico",
    "4 Parcels Bundle": "Pacote de 4 Parcelas",
    "10 Parcels Bundle": "Pacote de 10 Parcelas",
    "40 Parcels Bundle": "Pacote de 40 Parcelas",
    "80 Parcels Bundle": "Pacote de 80 Parcelas",
    "140 Parcels Bundle": "Pacote de 140 Parcelas",
    "240 Parcels Bundle": "Pacote de 240 Parcelas",
    "4 Basic Miner Bundle": "4 Pacote Mineiro",
    "10 Basic Miner Bundle": "10 Pacote Mineiro",
    "40 Basic Miner Bundle": "40 Pacote Mineiro",
    "80 Basic Miner Bundle": "80 Pacote Mineiro",
    "140 Basic Miner Bundle": "140 Pacote Mineiro",
    "240 Basic Miner Bundle": "Pacote Mineiro 240",
    "Hashpower": "Hashpower",
    "Something went wrong, please refresh the page and try again": "Algo deu errado, atualize a página e tente novamente",
    "Gallery": "Galeria",
    "Accepted": "Aceito",
    "Pending": "Pendente",
    "Upload": "Carregar",
    "Uploading image": "Carregando imagem",
    "Something went wrong, try again later": "Algo deu errado, tente novamente mais tarde",
    "You need to be authenticated to upload images to Gallery.": "Você precisa estar autenticado para carregar imagens para a Galeria.",
    "Assign image": "Atribuir imagem",
    "Assign image to parcels": "Atribuir imagem a parcelas",
    "Remove current image": "Remova a imagem atual",
    "Change image": "Mude a imagem",
    "Assign to parcels": "Atribua a parcelas",
    "Reselect image assignment area?": "Reseletar a área de atribuição de imagem?",
    "You have not applied changes. Do you want to reselect image assignment area?": "Você não aplicou alterações.Deseja revender a área de atribuição de imagem?",
    "Image has been successfully changed": "A imagem foi alterada com sucesso",
    "Image has been successfully assigned to parcels": "A imagem foi atribuída com sucesso às parcelas",
    "Image has been successfully removed from the parcels": "A imagem foi removida com sucesso das parcelas",
    "Yes": "Sim",
    "No": "Não",
    "Cancel": "Cancelar",
    "You have no images": "Você não tem imagens",
    "Your image was approved": "Sua imagem foi aprovada",
    "Your image was rejected": "Sua imagem foi rejeitada",
    "Notifications": "Notifications",
    "No notifications": "No notifications",
    "year ago": "Nenhuma notificação",
    "months ago": "anos atrás",
    "month ago": "ano atrás",
    "weeks ago": "meses antes",
    "week ago": "mês atrás",
    "days ago": "semanas atrás",
    "day ago": "uma semana atrás",
    "hours ago": "dias atrás",
    "hour ago": "dia atrás",
    "minutes ago": "horas atrás",
    "minutes": "hora atrás",
    "minute": "minutos atrás",
    "seconds": "minutos",
    "second": "minuto",
    "New": "Nova",
    "Gallery guide" : "Guia da galeria",
    "Go to gallery": "Vá para a galeria",
    "How it works": "Como funciona",
    "Removing image from parcels..." : "Removendo a imagem de parcelas ...",
    "Removing image" : "Removendo a imagem",
    "Image has been successfully removed" : "A imagem foi removida com sucesso",
    "Wrong file size" : "Tamanho errado do arquivo",
    "Please upload image files with size less than 10MB": "Faça o upload de arquivos de imagem com tamanho menor que 10 MB",
    "Drag to Reposition": "Arraste para reposicionar",
    "Pinch or use the mouse wheel to zoom in or out": "Prenda ou use a roda do mouse para aumentar ou diminuir o zoom",
    "Drag and move the image with fingers or using your mouse to select area": "Arraste e mova a imagem com os dedos ou usando o mouse para selecionar a área",
    "It can take up to 48 hours for the image to be approved. You can view your images awaiting approval in the Pending tab.": "Pode levar até 48 horas para que a imagem seja aprovada.Você pode visualizar suas imagens aguardando aprovação na guia Pendente.",
    "Delete Image": "Excluir imagem",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable. Land containing this image will also be cleared.": "Tem certeza de que deseja excluir permanentemente a imagem da sua galeria?Esta operação não é recuperável.Terras que contêm esta imagem também serão limpas.",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable." : "Tem certeza de que deseja excluir permanentemente a imagem da sua galeria?Esta operação não é recuperável.",
    "Wrong File Type": "Tipo de arquivo errado",
    "Please upload jpg/jpeg image files": "Faça o upload de arquivos de imagem JPG/JPEG",
    "Upload Images to Your Gallery": "Carregue imagens para sua galeria",
    "You can upload images to your gallery from your computer, mobile phone or tablet. Currently only jpg/jpeg image file types are supported. Press the upload button or simply drag and drop the image from computer to upload your image. Image size must be up to 10 MB." : "Você pode fazer upload de imagens na sua galeria do seu computador, telefone celular ou tablet.Atualmente, apenas os tipos de arquivo de imagem JPG/JPEG são suportados.Pressione o botão Upload ou simplesmente arraste e solte a imagem do computador para fazer upload da sua imagem.O tamanho da imagem deve ser de até 10 MB.",
    "Upload image": "Enviar Imagem",
    "Image Approval": "Aprovação da imagem",
    "Uploaded images need to be approved before they appear in the approved section of your gallery. This procedure is necessary to filter out inappropriate, explicit content and copyrighted material. Approval can take up to 48 hours. You'll get notified about the verdict whenever you're online." : "As imagens carregadas precisam ser aprovadas antes de aparecer na seção aprovada da sua galeria.Este procedimento é necessário para filtrar conteúdo inapropriado e explícito e material protegido por direitos autorais.A aprovação pode levar até 48 horas.Você será notificado sobre o veredicto sempre que estiver online.",
    "Approval": "Aprovação",
    "Select Your Land to Assign Image to": "Selecione sua terra para atribuir imagem a",
    "You can assign your uploaded images to your owned land. To do so, simply select the parcels by dragging mouse on the land part you want to assign an image to. Please note that image can only be assigned to a rectangular area that is owned by you only." : "Você pode atribuir suas imagens enviadas à sua terra própria.Para fazer isso, basta selecionar as parcelas arrastando o mouse na parte da terra para a qual deseja atribuir uma imagem.Observe que a imagem só pode ser atribuída a uma área retangular que pertence apenas a você.",
    "Land Selection": "Seleção de terras",
    "Crop Image": "Cortar imagem",
    "You can crop a custom part of your image that you want to assign to your land section. You can close in to any area of your image and crop it by dragging the image with mouse or your finger if you're on a mobile device. You can also zoom in and out by using mouse wheel or pinch gesture." : "Você pode cortar uma parte personalizada da sua imagem que deseja atribuir à sua seção de terras.Você pode fechar em qualquer área da sua imagem e cortá -la arrastando a imagem com mouse ou seu dedo, se estiver em um dispositivo móvel.Você também pode aumentar e diminuir o zoom usando a roda do mouse ou o gesto de beliscão.",
    "Activate multiple selection": "Ative seleção múltipla",
    "Exit multiple selection": "Saia de seleção múltipla",
    "Owned": "Controlada",
    "Owned by": "De propriedade de",
    "Multiple types": "Vários tipos",
    "seconds ago": "segundos atrás",
    "Super Bundle": "Superpacote",
    "Balance": "Equilíbrio",
    "View your purchased Bundles and claim to have them from your balance": "Visualize seus Pacotes comprados e reivindique tê-los do seu saldo",
    "Feel free to choose lands on Map.": "Sinta-se à vontade para escolher terrenos no Mapa.",
    "Earn money by using miners for generating rewards.": "Ganhe dinheiro usando mineradores para gerar recompensas.",
    "Upgrade your owned lands from 4 slots to 10.": "Atualize suas terras de 4 slots para 10.",
    "Confirm": "confirme",
    "You don`t have any available parcels in your inventory": "Você não tem nenhum pacote disponível em seu inventário",
    "Months": "Meses",
    "Super Bundle R6": "Super Pacote R6",
    "Super Bundle R10": "Super Pacote R10",
    "Super Bundle R15": "Super Pacote R15",
    "Super Bundle R20": "Super Pacote R20",
    "Super Bundle R22 S": "Super Pacote R22 S",
    "Super Bundle R22 M": "Super Pacote R22 M",
    "Super Bundle R22 L": "Super Pacote R22 L",
    "Super Bundle R22 XL": "Super Pacote R22 XL",
    "Super Bundle R22 XXL": "Super Pacote R22 XXL",
    "1 Claim your land": "1 Reivindique sua terra",
    "2 Claim your miner": "2 Reivindique seu minerador",
    "3 Upgrade your land": "3 Atualize sua terra",
    "Your NFT balance": "Seu saldo NFT",
    "Next price changes in": "Próximas mudanças de preço em",
    "Parcels": "Encomendas",
    "Get to know the platform usage": "Conheça o uso da plataforma",
    "What is a Parcel?": "O que é uma Parcela?",
    "Land is a unit of area on the GYM Street map and it is also an NFT. You can own as much land on GYM Street as you want.": "Terreno é uma unidade de área no mapa GYM Street e também é um NFT. Você pode ter quantos terrenos quiser na GYM Street.",
    "What is a Miner?": "O que é um Mineiro?",
    "A miner is what generates income for you at GYM Street. You can attach up to 4 miners on each of your standard land parcels.": "Um mineiro é o que gera renda para você na GYM Street. Você pode anexar até 4 mineiros em cada uma das suas parcelas de terra padrão.",
    "Miner rewards": "Recompensas do mineiro",
    "My Miners": "Meus mineiros",
    "My Hashpower": "Meu Hashpower",
    "Total Claimed": "Total reclamado",
    "Total pending rewards": "Total de Recompensas Pendentes",
    "Claim all": "Reivindicar tudo",
    "Daily rewards": "Recompensas diárias",
    "Weekly rewards": "Recompensas semanais",
    "Monthly rewards": "Recompensas mensais",
    "Yearly rewards": "Recompensas anuais",
    "ROI": "ROI",
    "ROI Calculator": "Calculadora de ROI",
    "Global Statistics": "Estatísticas globais",
    "Global Hashpower": "Global Hashpower",
    "Your Shares": "Suas Ações",
    "Daily Global Rewards": "Recompensas globais diárias",
    "Price": "Preço",
    "Total Minted": "Total Cunhado",
    "total": "total",
    "standard": "padrão",
    "upgraded": "atualizado",
    "active": "ativo",
    "inactive": "inativo",
    "Attach miner": "Anexar minerador",
    "Total Miners": "Mineiros totais",
    "Total Minted Rewards": "Total de recompensas cunhadas",
    "What is parcel and how to use it?": "Um pacote é uma unidade de área no mapa do {metaverse} equipada com recursos de cunhagem. Possuir Parcelas e gerar renda.",
    "What is a miner and how to use it?": "O Mineiro é o seu gerador de renda no {metaverso}. Aumente seus ganhos anexando mineiros ao seu pacote. <br/> <br/> O GYMNET Miner e o Dual Miner operam a uma velocidade de mineração de 1.000 Mh/s.",
    "Your active/inactive hashpower, 1 miner = 1,000 Mh/s, your Miners 0 x 1,000Mh/s = 0.00": "Seu hashpower ativo/inativo, 1 minerador = 1.000 Mh/s, seus Mineiros 0 x 1.000Mh/s = 0,00",
    "The amount of the cumulated claimed rewards": "O valor das recompensas reivindicadas acumuladas",
    "Your current available pending rewards.": "Suas recompensas pendentes disponíveis no momento.",
    "Congratulations!": "Parabéns!",
    "Upgrade your parcels to mine even more?": "Atualize suas parcelas para minerar ainda mais?",
    "You have NUM parcels that are not upgraded.": "Você tem {parcels} parcelas que\n não foram atualizadas.",
    "Go to Map": "Ir para o mapa",
    "You're mining less than the average user.": "Você está minerando menos do que o usuário médio.",
    "Purchase more miners to improve your results": "Compre mais mineradores para melhorar seus resultados",
    "Buy more miners": "Compre mais mineradores",
    "Thanks, I'll stay below average": "Obrigado, vou ficar abaixo da média",
    "You aren't mining at full speed.": "Você não está minerando a toda velocidade.",
    "Do you want to buy more parcels now ?": "Você quer comprar mais parcelas agora?",
    "I definitely do": "eu definitivamente faço",
    "No, I'll let my miners to rust": "Não, vou deixar meus mineiros enferrujarem",
    "You have no miners": "Você não tem mineradores",
    "Buy miners": "Comprar mineradores duplos",
    "You haven't upgradeable parcels": "Você não tem parcelas atualizáveis",
    "You haven't inactive miners": "Você não tem mineradores inativos",
    "Close": "Perto",
    "Read more": "Consulte Mais informação",
    "Drag and drop an image, or Browse": "Arraste e solte uma imagem ou <label class=\"browse-button\" for=\"upload-image\">Procurar</label>",
    "Browse an image": "<label class=\"browse-button\" for=\"upload-image\">Procurar</label> uma imagem",
    "Drop to upload": "Soltar para fazer upload",
    "File not supported": "Arquivo não suportado",
    "Upload your parcel image": "Carregue sua imagem de encomenda",
    "Up to 10MB, jpg/jpeg": "Até 10 MB, JPG/JPEG",
    "High resolution images, should align with your land shape (you can crop after upload)": "As <span class=\"bold-info\">imagens de alta resolução</span> devem se alinhar com o formato do terreno (você pode recortar após o upload)",
    "Same image may be used on different lands owned by the user": "<span class=\"bold-info\">A mesma imagem pode ser usada em</span> terrenos diferentes de propriedade do usuário",
    "No violence, offensive, graphic or 18+ content": "Sem violência, conteúdo ofensivo, gráfico ou <span class=\"bold-info\">maiores de 18 anos</span>",
    "No third-party copyrighted content, no logos or screenshots from Gymstreet and Gymnetwork": "<span class=\"bold-info\">Sem conteúdo protegido por direitos autorais de terceiros,</span> sem logotipos ou capturas de tela de <span class=\"bold-info\">Gymstreet and Gymnetwork</span>",
    "No images already used by another user, no images from DeFi listing website images": "<span class=\"bold-info\">Nenhuma imagem já usada</span> por outro usuário, nenhuma imagem de imagens de sites de listagem DeFi",
    "No religious images": "<span class=\"bold-info\">Nenhuma imagem religiosa</span>",
    "Unlock miners by purchasing at least 1 land parcel": "Desbloqueie mineradores comprando pelo menos 1 terreno",
    "OK": "OK",
    "Your parcels": "Suas encomendas",
    "Your miners": "Seus mineiros",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcels to start mining": "Você não está minerando a toda velocidade, você tem mineiros {miners} que precisam ser anexados às suas parcelas para começar a minerar",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcel to start mining": "Você não está minerando a toda velocidade, você tem {miners} mineradores que precisam ser anexados ao seu pacote para começar a minerar",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcels to start mining": "Você não está minerando a toda velocidade, você tem {miners} mineradores que precisam ser anexados às suas parcelas para começar a minerar",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcel to start mining": "Você não está minerando a toda velocidade, você tem {miners} mineradores que precisam ser anexados ao seu pacote para começar a minerar",
    "Info Center": "Centro de informações",
    "Standard Parcel": "Parcela padrão",
    "You have no parcels": "Você não tem parcelas",
    "Single Products": "Produtos individuais",
    "Parcels sold / Total parcels": "Parcelas vendidas / Total parcelas",
    "You can mine even more. Purchase more miners to improve your results": "Você pode minerar ainda mais. Compre mais mineradores para melhorar seus resultados",
    "In Progress": "Em andamento",
    "Active": "Ativo",
    "Learn More": "Saber mais",
    "Add to wallet": "Adicione à carteira",
    "The Promotion starts in:": "A Promoção começa em:",
    "Your Balance": "Seu balanço",
    "Please go to your wallet, which you just connected, and verify your login by confirming the requested transaction. It's free of charge!": "Por favor, vá para sua carteira, que você acabou de conectar, e verifique seu login confirmando a transação solicitada. É grátis!",
    "Contract addresses": "endereços de contrato",
    "Add TOKEN to wallet": "Adicionar {token} à carteira",
    "I accept the terms and conditions": "eu aceito os termos e condições",
    "Log In": "Conecte-se",
    "Sign Up": "Inscrever-se",
    "Connect My Wallet": "Conecte minha carteira",
    "Change Email": "Mude o e-mail",
    "Reset Password": "Redefinir senha",
    "Your password has been changed.": "Sua senha foi mudada.",
    "Please log in to your account.": "Faça login na sua conta.",
    "Confirm Email": "Confirmar e-mail",
    "Emails don't match": "Os e-mails não correspondem",
    "Or": "Ou",
    "Sign Up With Email": "Inscreva-se com e-mail",
    "Already have an account?": "Já tem uma conta?",
    "Recover": "Recuperar",
    "Enter New Password": "Insira a nova senha",
    "Confirm New Password": "Confirme a nova senha",
    "Password": "Senha",
    "Forgot password?": "Esqueceu sua senha?",
    "Password is required": "Senha requerida",
    "Password confirmation is required": "A confirmação de senha é necessária",
    "Passwords do not match": "As senhas não coincidem",
    "Password must contain at least one uppercase letter, one lowercase letter and one number": "A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula e um número",
    "Password must be at least 8 characters": "A senha deve conter pelo menos 8 caracteres",
    "Password must not exceed 24 characters": "As palavras-passe não devem exceder 24 caracteres",
    "Password must contain at least one special character": "A senha deve conter pelo menos um personagem especial",
    "Password cannot contain spaces": "Senhas não podem conter espaços",
    "Email is required": "O e-mail é necessário",
    "Email confirmation is required": "A confirmação por e-mail é necessária",
    "Please enter a valid email": "por favor digite um e-mail válido",
    "Step NUM1 of NUM2": "Etapa {num1} de {num2}",
    "Create Password": "Criar senha",
    "Get Started": "Iniciar",
    "Create a Wallet": "Criar uma Carteira",
    "Confirm on this screen": "Confirme nesta tela",
    "Pick a password on the next step. This needs to be at least 8 symbols long.": "Escolha uma senha na próxima etapa. Isso precisa ter pelo menos 8 símbolos de comprimento.",
    "Wallet Ready": "carteira pronta",
    "Add Binance Smart Chain": "Adicionar Binance Smart Chain",
    "Click on the button below to add Binance Smart Chain Network to your MetaMask wallet.": "Clique no botão abaixo para adicionar a Rede Binance Smart Chain à sua carteira MetaMask.",
    "Confirm the connection request": "Confirme a solicitação de conexão",
    "Get Started!": "Iniciar!",
    "Secure Wallet": "Carteira segura",
    "Confirm your password to continue.": "Confirme sua senha para continuar.",
    "You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.": "Você precisará dela para recuperar sua carteira se perder o acesso ao seu computador e ela deve ser guardada em local seguro. Qualquer pessoa que tenha acesso à sua frase de backup de 12 palavras poderá recuperar seus fundos, portanto, mantenha isso em segredo.",
    "You have successfully set up Your wallet.Welcome to the world of WEB3!": "Você configurou sua carteira com sucesso.\nBem-vindo ao mundo da WEB3!",
    "Confirm Password": "Confirme sua senha",
    "Install here.": "Instale aqui.",
    "Something went wrong, please try verifying your email again.": "Algo deu errado, tente verificar seu e-mail novamente.",
    "Looks like your password reset link has expired. Please try again.": "Parece que o seu link de redefinição de senha expirou. Por favor, tente novamente.",
    "Looks like your password reset link has already been used. Please request it again.": "Parece que seu link de redefinição de senha já foi usado.Por favor, solicite novamente.",
    "Couldn't recover password": "Não foi possível recuperar a senha",
    "Couldn't reset your password.": "Não foi possível redefinir sua senha.",
    "Couldn't login.": "Não foi possível fazer o login.",
    "Couldn't sign you up.": "Não foi possível assiná-lo.",
    "Resend Email": "Reenviar e-mail",
    "Couldn't resend confirmation email.": "Não foi possível reenviar o e-mail de confirmação.",
    "Provided email is already in use.": "O e-mail desde que já está em uso.",
    "You have already verified your email address": "Você já verificou seu endereço de e-mail",
    "Invalid or expired verification code": "Código de verificação inválido ou expirado",
    "An error occurred while logging in. Please try again.": "Ocorreu um erro ao fazer login. Tente novamente.",
    "We sent an email to {EMAIL} with a link to finalize your registration": "Enviamos um email para {EMAIL} com um link para finalizar seu registro",
    "We sent an email to {EMAIL} with a link to change your password": "Enviamos um email para {EMAIL} com um link para alterar sua senha",
    "Referral ID": "ID de referência",
    "On the next step, tap on 'Create a new wallet'.": "Na próxima etapa, toque em \n“Create a new wallet”.",
    "On the next step, tap on 'Create a wallet'.": "Na próxima etapa, toque em \"Create a wallet\".",
    "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower case letters, symbols and numbers.": "Recomendamos o uso de uma senha completamente exclusiva que não foi usada em nenhum outro lugar e contém uma mistura de letras, símbolos e números superiores e minúsculos.",
    "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.": "Coloque as palavras memorizadas na etapa anterior em ordem.Toque no botão \"Complete\" quando terminar o backup.",
    "On this step you can choose. We recommend you do this now as you might otherwise lose access to your wallet. Tap on 'Start' to continue.": "Nesta etapa você pode escolher. \nRecomendamos que você faça isso agora, caso contrário, poderá perder o acesso à sua carteira. \nToque em \"Start\" para continuar.",
    "On this step tap on 'Start' to continue.": "Nesta etapa toque em \"Start\" \n para continuar.",
    "Tap on the burger menu of the app in the top left corner of the screen.": "Toque no menu de hambúrguer do aplicativo no canto superior esquerdo da tela.",
    "Next, tap on Settings in the menu to the left.": "Em seguida, toque nas \"Settings\" no menu à esquerda.",
    "Next, tap on Networks.": "Em seguida, toque em \"Networks\".",
    "Add More Tokens": "Adicione mais tokens",
    "In the search field, type in BNB Smart Chain and add it to your network.": "No campo de pesquisa, digite \"BNB Smart Chain\" e adicione-o à sua rede.",
    "Next, select Custom Token. Paste the address of the smart contract you copied into the token address field. In the Token Symbol field, type SP, and in the Token Decimal, put 0. Then click the 'Import' button to complete your setup.": "Em seguida, selecione \"Custom Token\". Cole o endereço do contrato inteligente que você copiou no campo \"Token Address\". No campo \"Token Symbol\" digite SP e em \"Token Decimal\" coloque 0. Em seguida, clique no botão \"Import\" para concluir a configuração.",
    "Add Network": "Adicionar rede",
    "Give permission to the platform to add a network.": "Dê permissão à plataforma para adicionar uma rede.",
    "Extra PERCENT% Discount": "Extra {PERCENT}% desconto",
    "Tap on the 'Import Tokens' link to add a Custom Token.": "Toque no link \"Import Tokens\" para adicionar um token personalizado.",
    "Click on the button 'Approve' to allow the connection request.": "Clique no botão \"Approve\" para permitir a solicitação de conexão.",
    "Confirm Secret Phrase": "Confirme a frase secreta",
    "Click on the button 'Switch network' to allow the connection request.": "Clique no botão \"Switch network\" para permitir a solicitação de conexão.",
    "Payment Method": "Forma de pagamento",
    "Select cryptocurrency": "Selecione Criptomoeda",
    "Please use an Ethereum BEP-20 address.": "Use um endereço Ethereum BEP-20.",
    "Connect to Gymstreet": "Conecte-se a Gym Street",
    "Now to access the Gym Street Metaverse, you need to open it with the MetaMask browser. Go to your MetaMask wallet app, choose 'Browser' from the side navigation menu, search for Gym Street in the browser tab.": "Agora, para acessar o Gym Street Metaverse, você precisa abri-lo com o navegador MetaMask. Acesse seu aplicativo de carteira MetaMask, escolha \"Browser\" no menu de navegação lateral, procure por Gym Street na guia do navegador.",
    "First, let's set up your cryptocurrency wallet.": "Primeiro, vamos configurar sua carteira de criptomoeda.",
    "Follow the next steps to continue the setup.": "Siga as próximas etapas para continuar a configuração.",
    "Your balance was updated": "Seu saldo foi atualizado",
    "Once installed, you should see the below splash screen. Click the 'Get Started' button to begin creating your Ethereum wallet using MetaMask.": "Uma vez instalado, você deve ver a tela inicial abaixo. Clique no botão \"Get Started\" para começar a criar sua carteira Ethereum usando MetaMask.",
    "You will then be asked if you want to help improve MetaMask. Click 'No, Thanks' if this doesn't interest you, otherwise click 'I agree'.": "Você será perguntado se deseja ajudar a melhorar o MetaMask. Clique em \"No, Thanks\" se não for do seu interesse, caso contrário, clique em \"I agree\".",
    "MetaMask will then present you with your 12-word backup phrase. You will need to write this down in the same order displayed on your screen.": "O MetaMask apresentará sua frase de backup de 12 palavras.Você precisará anotar isso na mesma ordem exibida na tela.",
    "After Downloading and opening MetaMask, tap on 'Get Started'.": "Depois de baixar e abrir o MetaMask, toque em \"Get Started\".",
    "Improve Metamask": "Melhorar o MetaMask",
    "Congratulations! You have set up your MetaMask wallet. Confirm the next few pop-ups on the screen to access your wallet.": "Parabéns! Você configurou sua carteira de MetaMask.Confirme os próximos pop-ups na tela para acessar sua carteira.",
    "Import Binance Smart Chain": "Importar Binance Smart Chain",
    "Next, copy the NFT Smart Contract by tapping on the button below.": "Em seguida, copie o NFT Smart Contract tocando no botão abaixo.",
    "Copy NFT Contract": "Copiar NFT Contract",
    "Install MetaMask": "Instale o MetaMask",
    "Install MetaMask here.": "Instale o MetaMask aqui.",
    "Guide for MetaMask Wallet connection process.": "Guia para o processo de conexão da carteira de metamask.",
    "MetaMask will then present you with your 12-word backup phrase.": "O MetaMask apresentará sua frase de backup de 12 palavras.",
    "MetaMask Secret Phrase": "Frase Secreta MetaMask",
    "Copy NFT Smart Contract": "Copiar NFT Smart Contract",
    "You are almost finished with the MetaMask setup process. Just click 'All done' on the last page, and you will be automatically logged into MetaMask. If you ever log out, you can log back in by clicking on the icon added to your web browser (usually, it's next to the URL bar).": "Você está quase terminando o processo de configuração do MetaMask. Basta clicar em \"All done\" na última página e você será automaticamente conectado ao MetaMask. Se você sair, poderá fazer login novamente clicando no ícone adicionado ao seu navegador da Web (geralmente, ao lado da barra de URL).",
    "Your payment processing is complete. Your NFT balance has been updated!": "O processamento do seu pagamento está concluído. Seu saldo NFT foi atualizado!",
    "Your payment is received but is incomplete. Please pay AMOUNT CURRENCY more to complete the payment.": "Seu pagamento foi recebido, mas está incompleto. Pague {amount} {currency} a mais para concluir o pagamento.",
    "Terms & Conditions": "termos e Condições",
    "Email Verification": "verificação de e-mail",
    "Transaction history": "Histórico de transações",
    "Order history": "Histórico de Pedidos",
    "Product": "Produtos",
    "Quantity": "Quantidade",
    "Payment UID": "UID de pagamento",
    "Status": "Status",
    "Currency": "Moeda",
    "Autoship":"Envio atomático",
    "Amount": "Montante",
    "Date": "Encontro",
    "Tx ID": "Tx ID",
    "Completed": "Concluída",
    "MetaMask is not installed": "MetaMask não está instalado",
    "If you have already installed MetaMask, please": "Se você já instalou {MetaMask}, por favor",
    "refresh page": "atualizar a página",
    "to continue.": "continuar.",
    "You have successfully installed MetaMask. Now you can add a Network.": "Você instalou o MetaMask com sucesso. Agora você pode adicionar uma rede.",
    "You already have this network": "Você já tem esta rede",
    "Continue": "Prosseguir",
    "Finalize Registration": "Finalizar registro",
    "Please confirm your email to continue": "Por favor, confirme seu e-mail para continuar",
    "New email must be different from current email": "Novo e-mail deve ser diferente do e-mail atual",
    "Actions": "Ações",
    "Request to change the chain has been rejected": "Pedido para mudar a cadeia foi rejeitado",
    "Switch Chain": "Corrente de comutação",
    "Choose your Metaverse": "Escolha seu Metaverso",
    "You need to choose your Metaverse to continue. Maps, Dashboard and My Products information will vary depending on your selection. Currently {METAVERSE} Metaverse is selected.": "Você precisa escolher seu Metaverso para continuar. As informações de Mapas, Painel e Meus Produtos variam dependendo da sua seleção. Atualmente {METAVERSE} Metaverso está selecionado.",
    "Can't check your purchases": "Não posso verificar suas compras",
    "Attach {COUNT} miners": "Anexar {COUNT} mineradores",
    "Attach 1 miner": "Anexar 1 mineiro",
    "View on map": "Ver no mapa",
    "Buy {COUNT} parcels": "Compre {COUNT} parcelas",
    "Buy 1 parcel": "Compre 1 pacote",
    "Buy {COUNT} miners": "Compre {COUNT} mineradores duplos",
    "Buy 1 miner": "Compre 1 minerador duplo",
    "Metaverses": "Metaversos",
    "My NFTs": "Meus NFTs",
    "Total Hashpower (Mh/s)": "Potência de hash total (Mh/s)",
    "Available Miner Slots": "Slots disponíveis para mineradores",
    "Personal extra discount": "Desconto extra pessoal",
    "Average ROI past 9 months": "ROI médio dos 9 meses",
    "Login Via Crypto Wallet": "Login através da Carteira Crypto",
    "Land reserved for PARTNER": "Terreno reservado para {PARTNER}",
    "About PARTNER": "Sobre {PARTNER}",
    "swap": "trocar",
    "transfer": "transferir",
    "approve": "aprovar",
    "max": "máximo",
    "The minimum amount to claim is N": "O valor mínimo para reivindicar é {n}",
    "paginationText": "Exibindo {from} - {to} de {count}",
    "turnovers": "Volume de negócios",
    "Total partners": "Total parceiros",
    "refreshData": "Atualizar dados",
    "NFT Turnover": "NFT Volume de negócios",
    "Single Pool Turnover": "Volume de negócios de piscina única",
    "depth": "Profundidade",
    "turnover": "Volume de negócios",
    "weekly": "Semanalmente",
    "monthly": "Por mês",
    "wallet": "carteira",
    "columns": "Colunas",
    "filters": "Filtros",
    "level": "Nível",
    "search": "Procurar",
    "partners": "Parceiros",
    "Farming Turnover": "Volume de negócios agrícola",
    "Vault Turnover": "Volume de negócios do cofre",
    "Careers status": "Estado de carreira",
    "GYMNET Balance": "Saldo GYMNET",
    "Save": "Salvar",
    "Swap": "Troca",
    "Turnover pool": "Pool de faturamento",
    "Global Line Bonus": "Bônus de Linha Global",
    "Turnover pools": "Pools de faturamento",
    "Tax pools": "Pools de impostos ({n}mo)",
    "Total qualified turnover": "Volume de negócios total qualificado",
    "My claimed rewards": "Minhas recompensas reivindicadas",
    "My pending rewards": "Minhas recompensas pendentes",
    "Week": "Semana",
    "Month": "Mês",
    "View more": "Veja mais",
    "Turnover progress": "Progresso do faturamento",
    "Thank you for your participation in Global Line Incentive!": "Obrigado por participar do Global Line Incentives!",
    "Global line before June 2023": "Linha global antes de junho de 2023",
    "Global line before March 2023": "Linha global antes de março de 2023",
    "More info": "Mais informações",
    "Your Position": "Sua posição",
    "Last Position": "Última posição",
    "My line length": "Comprimento da minha linha",
    "Global Line Turnover": "Retorno da linha global",
    "The turnover generated from all participants who joined the Global Line after you.": "O volume de negócios gerado por todos os participantes que ingressaram na Global Line depois de você.",
    "Your purchases": "Suas compras",
    "You need purchases of at least $100 to become a Global Line member.": "Você precisa de compras de pelo menos $ 100 para se tornar um membro Global Line.",
    "Your pending income": "Sua renda pendente",
    "Buy more NFTs to increase your shares": "Compre mais <strong><i>NFTs</i></strong> para aumentar seus <strong>compartilhamentos.</strong>",
    "New Registrations": "Novos Registros",
    "Turnover": "Volume de negócios",
    "Global level": "Nível global",
    "Joining time": "Hora de entrar",
    "Global Line Total Turnover": "Global Line Total Turnover",
    "Global Line end": "Fim da linha global",
    "Please confirm your claim": "귀하의 주장을 확인하십시오",
    "After you confirm, you will receive your rewards on your balance.": "확인 후 잔액에 대한 보상을 받게 됩니다.",
    "Select Language": "Selecionar idioma",
    "Download": "Download",
    "Your Income": "Sua renda",
    "Shares": "Ações",
    "Single Miner": "Único Minerador",
    "Super Bundles": "Super Pacotes",
    "Regular Bundles": "Pacotes Regulares",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%.": "A participação máxima que cada sócio direto pode contribuir para o volume de negócios exigido é de até 50%.",
    "Your partners": "Seus parceiros",
    "Generated turnover": "Faturamento gerado",
    "Qualified turnover": "Faturamento qualificado",
    "Generated": "Gerada",
    "Qualified": "Qualificada",
    "Your partners turnover statistics will appear here.": "As estatísticas de rotatividade de seus parceiros aparecerão aqui.",
    "Pools": "Pools",
    "Qualification": "Qualificação",
    "Rewards": "Prêmios",
    "Participants": "Participantes",
    "Total rewards": "Recompensas totais",
    "Rewards per participant": "Recompensas por participante",
    "Land sales contribution": "Contribuição de vendas de terrenos",
    "Maintainance contribution": "Contribuição de manutenção",
    "Distributed": "Distribuído",
    "Refresh data": "Atualizar dados",
    "You will receive your rewards directly on your wallet": "Você receberá suas recompensas diretamente em sua carteira",
    "Total claimed": "Total Reivindicado",
    "Next distribution": "Próxima distribuição",
    "Unclaimed BUSD rewards": "Recompensas BUSD não resgatadas",
    "Required level": "Nível requerido",
    "NFT sales percent": "Vendas NFT {n}%",
    "Total qualified participants": "Total de participantes qualificados",
    "TxID": "TxID",
    "Tx Type": "Tipo de Tx",
    "Global line reward": "Recompensa de linha global",
    "Your share": "Seu compartilhamento",
    "Income before": "Renda antes",
    "Income after": "Renda depois",
    "NFT Income": "Renda NFT",
    "NFT Commissions Total": "Total de Comissões NFT",
    "NFT Statistic": "Estatística NFT",
    "Level": "Nível",
    "User": "Usuário",
    "Your current pin": "Seu pino atual",
    "Turnover for the next pin": "Volume de negócios para o próximo pino",
    "Your next incentive": "Seu próximo incentivo",
    "Your next bonus": "Seu próximo bônus",
    "Incentives": "Incentivos",
    "Partners with pin qualification": "Parceiros com {n} qualificação de pin",
    "Team of": "Equipe de",
    "Partners": "Parceiros",
    "Cash Bonus": "Bônus em dinheiro",
    "Your pin": "Seu alfinete",
    "Incentive": "Incentiva",
    "Pin": "Alfinete",
    "Level pool": "Nível Pool",
    "Go to PARTNER": "Vá para {PARTNER}",
    "Partnerships": "Parcerias",
    "Cannot find EMAIL_OR_WALLET": "Não foi possível encontrar EMAIL_OR_WALLET",
    "Incentive Ends In: ": "Incentivo <strong>Termina em</strong>: ",
    "Your Rewards History": "Seu histórico de recompensas",
    "Dubai & Sofia Trip": "Viagem Dubai e Sófia",
    "No Rewards": "Sem recompensas",
    "No Partners": "Sem parceiros",
    "Reward": "Recompensa",
    "Granted": "Garantido",
    "Transaction": "Transação",
    "Free BUNDLE": "Grátis {BUNDLE}",
    "Team Purchases": "Compras de equipe",
    "Direct Sales": "Vendas diretas",
    "You have won this reward": "Você ganhou esta recompensa",
    "50% Turnover Rule is applied for all incentives!": "A regra de rotatividade de 50% é aplicada a todos os incentivos!",
    "Please note that you have the chance to earn a complimentary R8 Bundle in two different ways: either by reaching 20k in direct sales or by achieving 100k in team purchases.": "Observe que você tem a chance de ganhar um Pacote R8 gratuito de duas maneiras diferentes: atingindo 20.000 em vendas diretas ou atingindo 100.000 em compras de equipe.",
    "In order to get qualified for any pool, you’ll have to follow the 50% turnover rule: The maximum share each direct partner can contribute to the required turnover is up to 50% of the turnover coming from NFT sales.": "Para se qualificar para qualquer pool, você terá que seguir a regra do faturamento de 50%: A parcela máxima que cada parceiro direto pode contribuir para o faturamento exigido é de até 50% do faturamento proveniente de vendas de NFT.",
    "Insufficient funds for gas, please add BNB to your wallet": "Fundos insuficientes para gás, adicione BNB à sua carteira",
    "Please note that you will require up to AMOUNT worth of CURRENCY to complete the transaction.": "Por favor, note que você precisará de até {amount} de {currency} para concluir a transação.",
    "Please note that AMOUNT worth of CURRENCY is required to complete the transaction.": "Observe que {amount} no valor de {currency} é necessário para concluir a transação.",
    "Please note that AMOUNT_CURRENCY is required for gas fee": "Observe que {amount_currency} é necessário para a taxa de gasolina",
    "Select a payment method": "Selecionar um método de pagamento",
    "Use your account's wallet balances to purchase the product.": "Use o saldo da carteira da sua conta para comprar o produto.",
    "Connect a WEB3 wallet like Metamask or Trustwallet and pay with {currency}. This requires a small amount of BNB ($0.30) to cover the transaction fees!": "Conecte uma carteira WEB3 como a Metamask ou a Trustwallet e pague com {currency}. Isso requer uma pequena quantia de BNB ($0.30) para cobrir as taxas de  transação!",
    "Select one of the available blockchains and cryptocurrencies, and proceed with the payment via an external wallet application.": "Selecione uma das blockchains e criptomoedas disponíveis e prossiga com o pagamento através de uma aplicação de carteira externa.",
    "Internal wallet": "Carteira interna",
    "Web3 Wallet": "Carteira Web3",
    "External wallet": "Carteira externa",
    "Select Currency": "Selecionar Moeda",
    "Purchase for myself": "Comprar para mim",
    "Select Blockchain": "Selecionar Blockchain",
    "Select Cryptocurrency": "Selecionar Criptomoeda",
    "Please select another payment method": "Por favor, selecione outro método de pagamento",
    "Connect my wallet": "Conectar a minha carteira",
    "Select a product owner": "Selecionar um titular de produto",
    "Please select who you are purchasing for:": "Por favor, selecione para quem você está a comprar:",
    "Product Owner Tooltip": "Tooltip do Proprietário do Produto",
    "Owner e-mail": "E-mail do proprietário",
    "Please choose your preferred payment method to finalize your purchase.": "Por favor, escolha o seu método de pagamento preferido para finalizar a compra.",
    "Purchase Summary": "Resumo Da Compra",
    "Rate": "Rede",
    "Fees": "Taxa",
    "Send to wallet address": "Enviar para o endereço da carteira",
    "Or scan the code with your wallet app to fill in address automatically.": "Ou digitalize o código com a sua aplicação da carteira para preencher     o endereço automaticamente.",
    "Send only CURRENCY to this wallet address!": "Envie apenas {currency} para este endereço de carteira!",
    "Please send your crypto within 30 minutes to ensure a fixed exchange rate": "Envie a sua cripto dentro de 30 minutos para garantir uma taxa de câmbio fixa",
    "I sent the crypto": "Enviei cripto",
    "Insufficient funds": "Fundos insuficientes",
    "Purchase Successful": "Compra Bem Sucedida!",
    "You can monitor the transaction status in the Order History tab. If it is successful, the NFT balance will be updated in the My NFTs tab.": "pode monitorizar o estado da sua transação em \"Os Meus Pedidos\" - se for bem sucedida, o seu saldo NFT será atualizado no separador \"Os Meus NFTs\".",
    "Available balance": "Saldo Disponível",
    "This window will close automatically once we receive your crypto.": "esta janela será fechada automaticamente assim que recebermos a sua criptomoeda.",
    "We did receive your crypto, but it was less than required. Please transfer the missing amount to complete your transaction.": "recebemos a sua criptomoeda, mas o montante é inferior ao necessário. Por favor, transfira o montante em falta para concluir a transação.",
    "Please do not attempt to do another transaction. Send your Payment UID to our support.": "A transação expirou: por favor, não tente fazer outra transação. Envie seu ID de Transação para o nosso suporte.",
    "Scanning for transaction": "A procurar a transação",
    "Go to My Orders": "Ir para os Meus Pedidos",
    "remaining": "restante",
    "Please send your crypto within 3 hours to ensure a fixed exchange rate": "Envie sua criptografia dentro de 3 horas para garantir uma taxa de câmbio fixa",
    "Please send your crypto within n minutes to ensure a fixed exchange rate": "Envie sua criptografia em {N} minutos para garantir uma taxa de câmbio fixa",
    "Missing crypto amount": "Montante de criptomoeda em falta",
    "Missing amount": "Montante em falta",
    "Received Amount.": "Montante recebido",
    "Required amount": "Montante necessário",
    "Continue with payment": "Continuar com o pagamento",
    "Your payment has expired. To resolve this issue, please contact customer support": "O seu pagamento expirou. Pare resolver este problema, por favor, contacte o apoio ao cliente.",
    "Your payment was refunded. AMOUNT CURRENCY was returned to your wallet.": "Seu pagamento foi reembolsado. {amount} {currency} foi devolvido à sua carteira.",
    "Network": "Rede",
    "The NFT balance was successfully updated and can be viewed in My NFTs tab.": "O saldo da NFT foi atualizado com sucesso e pode ser visualizado na aba \"Minhas NFTs\".",
    "OFF": "DESLIGADO",
    "Processing Fee": "Taxa de processamento",
    "Estimated gas fee": "Taxa de gás estimada",
    "Please indicate the intended recipient of the product. You have the option to purchase it for yourself or choose 'Another user' as the recipient.": "Por favor, indique o destinatário pretendido para o produto. Você tem a opção de o comprar para si próprio ou escolher “Outro utilizador” como destinatário.",
    "Your payment has expired. To resolve this issue, please contact customer support.": "O seu pagamento expirou. Pare resolver este problema, por favor, contacte o apoio ao cliente.",
    "Your payment has been received and is currently being processed. We will notify you once the process is complete.": "O seu pagamento foi recebido e encontra-se a ser processado. Nós o notificaremos quando o processo estiver concluído.",
    "Your transaction has been declined. Please attempt the transaction again.": "A sua transação foi declinada. Por favor, tente de novo.",
    "Monitor the status of your transaction by visiting the provided link:": "Acompanhe o estado da sua transação através do link fornecido:",
    "Apologies, an error occurred on the blockchain side. Please try again.": "Pedimos desculpa, ocorreu um erro do lado da blockchain. Por favor, tente de novo.",
    "Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.": "Por favor, aguarde que a atual transação da blockchain termine antes de iniciar uma nova. ",
    "Transaction Expired": "Transação expirada",
    "Enable Payment": "Ativar pagamento",
    "Email does not exist": "e-mail não existe",
    "Request another quote": "Solicite outro orçamento",
    "Purchase Success!": "Compra Sucesso!",
    "Purchase for another user": "Compra para outro usuário",
    "Pay": "Pagar",
    "You purchased GIFT for RECEIVER!": "Você comprou {gift} para {receiver}!",
    "You've purchased GIFT for RECEIVER!": "Você comprou {gift} para \"{receiver}\"!",
    "You've purchased a GIFT for RECEIVER!": "Você comprou um {gift} para \"{receiver}\"!",
    "Rewards claimed successfully": "Recompensas reivindicadas com sucesso",
    "Commissions claimed successfully.": "Comissões reivindicadas com sucesso.",
    "You received GIFT from SENDER!": "Você recebeu {gift} de {sender}!",
    "You've received GIFT from SENDER!": "Você recebeu {gift} de \"{sender}\"!",
    "You've received a GIFT from SENDER!": "Você recebeu um {gift} de \"{sender}\"!",
    "Transaction Failed": "Falha na transação",
    "The transaction has failed. Please try again.": "A transação falhou. Por favor, tente novamente.",
    "Your transaction has been declined. Please add BNB to your wallet and attempt the transaction again.": "Sua transação foi recusada. Por favor, adicione BNB à sua carteira e tente a transação novamente.",
    "Blockchain Notification: Apologies, an error occurred on the blockchain side. Please try again.": "Notificação Blockchain: Desculpas, ocorreu um erro no lado blockchain. Por favor, tente novamente.",
    "Apologies, an unexpected error occurred. Please retry your request. If the issue persists, please contact support.": "Desculpas, ocorreu um erro inesperado. Tente novamente sua solicitação. Se o problema persistir, entre em contato com o suporte.",
    "Received Amount": "Valor recebido",
    "Dual Miner": "Dual Miner",
    "Bitopex": "Bitopex",
    "Gymnet": "Gymnet",
    "No enough CURRENCY to cover gas fee.": "Não há {currency} suficiente para cobrir a taxa de gasolina.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover gas fee.": "Reabasteça sua carteira com {amount_currency} para cobrir a taxa de gasolina.",
    "Scanning": "Digitalizando",
    "To continue, please enable payment.": "Para continuar, habilite o pagamento.",
    "Kindly be aware that AMOUNT_CURRENCY may be necessary to cover the gas fee.": "Por favor, esteja ciente de que {amount_currency} pode ser necessário para cobrir a taxa de gás.",
    "Insufficient funds in CURRENCY to cover the gas fee.": "Fundos insuficientes em {currency} para cobrir a taxa de gás.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover the gas fee.": "Reabasteça sua carteira com {amount_currency} para cobrir a taxa de gasolina.",
    "We will cover the gas fee for this transaction on your behalf.": "Abordaremos a taxa de gás para esta transação em seu nome.",
    "Value": "Valor",
    "Reach Rank": "Alcança o nível <b>{n}</b>",
    "Rewards in Gymnet": "Recompensas em Gymnet",
    "Rewards in Bitcoin": "Recompensas em Bitcoin",
    "For Limited Time Only": "Por <strong>Tempo Limitado</strong> Apenas",
    "Seconds": "Segundos",
    "Minutes": "Minutos",
    "Days": "Dias",
    "Hours": "Horas",
    "Unclaimed parcels": "Parcelas não reclamadas",
    "Upgraded parcels": "Parcelas com upgrade",
    "Inactive miners": "Miners inativos",
    "Active miners": "Miners ativos",
    "Available Balance": "Saldo Disponível",
    "Utility Balance": "Saldo Utility",
    "Bitopex Balance": "Saldo Bitopex",
    "Moonberg Balance": "Saldo Moonberg",
    "Balance History": "Histórico de Saldo",
    "Miner Hashpower": "Mineiro Hashpower",
    "Dual Miners": "Dual Miners",
    "Dual miners info": "O número de dual miners ativos e totais.",
    "Dual Miner Hashpower": "Minerador duplo Hashpower",
    "Dual miners hashpower info": "O hashpower que você possui é determinado pela multiplicação do número dos seus dual miners ativos pela unidade de miner hashpower, que é de 1000Mh/s por unidade.",
    "Unlock the full potential of your Bitopex rewards as they come to life following your purchase of any of our special bundles. Once your Bitopex Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Desbloqueie todo o potencial das suas recompensas Bitopex à medida que elas ganham vida após a sua compra de qualquer um dos nossos bundles especiais. Assim que o seu Saldo Bitopex ficar disponível, você ganha acesso a uma visão geral do total das suas recompensas, das recompensas recebidas, e das recompensas previstas. Além disso, um contador mostra o tempo restante até à próxima distribuição de recompensas, para que você nunca as perca.",
    "Sec": "Seg",
    "Min": "Min",
    "Total Rewards Expected": "Total de Recompensas Previstas",
    "Monthly Distribution": "Distribuição Mensal",
    "Price Projection": "Projeção do Prémio",
    "Server error": "Erro de servidor",
    "Average Gymnet ROI for the last 9 months": "Média do ROI Gymnet nos últimos 9 meses",
    "Dual Miners will generate rewards in Bitopex tokens, which will be distributed every Friday.": "Dual Miners vai gerar recompensas em tokens Bitopex, que serão distribuídos todas as sextas-feiras.",
    "Introducing the Utility Balance feature, a new and convenient payment method within our payment ecosystem. With this feature, you'll enjoy a separate balance that can be used alongside other payment methods. It provides you with the flexibility to partially utilize your utility balance, up to 25% of the total when purchasing single Land NFTs or making payments for Land NFT bundles.": "Apresentamos o recurso Utility Balance, um novo e conveniente método de pagamento em nosso ecossistema de pagamentos. Com esse recurso, você terá um saldo separado que pode ser usado juntamente com outros métodos de pagamento. Ele fornece a você a flexibilidade de utilizar parcialmente seu saldo de serviços públicos, até 25% do total, ao comprar NFTs de terrenos individuais ou efetuar pagamentos de pacotes de NFT de terrenos.",
    "Balance before": "Saldo antes",
    "Balance after": "Saldo após",
    "TXID": "TXID",
    "The number of active and total miners you have in Gymstreet and Kabutochō, respectively.": "O número de miners totais e ativos que você possui no Gymstreet e Kabutochō, respetivamente.",
    "The hashpower you possess is determined by multiplying the number of your active miners with the unit miner hashpower, which is set at 1000Mh/s per unit.": "O hashpower que você possui é determinado pela multiplicação do número dos seus dual miners ativos pela unidade de miner hashpower, que é de 1000Mh/s por unidade.",
    "Token": "Token",
    "Buy Bundles": "Comprar Bundles",
    "Total Received": "Total Recebido",
    "Next Rewards Distribution In:": "Próxima Distribuição de Recompensas Em:",
    "Total Rewards": "Total de Recompensas",
    "Payout": "Pagamento",
    "Weekly": "Semanalmente",
    "Next Distribution Amount": "Valor da Próxima Distribuição",
    "Internal transaction": "transação interna",
    "Minimum claimable amount is (AMOUNT)": "O valor mínimo exigível é {amount}",
    "User Profile": "Perfil do Utilizador",
    "Name": "Nome",
    "Nationality": "Nacionalidade",
    "Contact Information": "Dados de Contato",
    "Country": "PaÃ­s",
    "City": "Cidade",
    "Street": "Rua",
    "Zip": "Código postal",
    "E-Mail": "E-Mail",
    "Wallet address": "Morada da carteira",
    "Name is required": "Nome Ã© obrigatÃ³rio",
    "Nationality is required": "Nacionalidade Ã© obrigatÃ³ria",
    "Country is required": "PaÃ­s Ã© obrigatÃ³rio",
    "City is required": "Cidade Ã© obrigatÃ³ria",
    "Street is required": "Rua Ã© obrigatÃ³ria",
    "Zip is required": "CÃ³digo postal Ã© obrigatÃ³rio",
    "Successfully updated": "Atualizado com successo",
    "Zip contain only numbers and min length is 3": "CÃ³digo postal deve conter apenas nÃºmeros e o mÃ­nimo de 3 dÃ­gitos",
    "Please enter a valid email address": "Por favor, introduza uma morada de e-mail vÃ¡lida",
    "Personal information": "Informações pessoais",
    "T&C": "T&C",
    "Field is required": "Campo obrigatÃ³rio",
    "Password successfully changed": "Palavra-passe alterada com sucesso",
    "Old password is incorrect": "Palavra-passe antiga errada",
    "Oops...Something went wrong. Please try again": "Oops...Algo correu mal. Por favor, tente outra vez",
    "Name must be at least 3 characters": "O nome tem de ter pelo menos 3 caracteres",
    "City must be at least 3 characters": "A cidade tem de ter pelo menos 3 caracteres",
    "Street must be at least 3 characters": "A rua tem de ter pelo menos 3 caracteres",
    "Affiliate Terms and conditions": "Termos e condições do afiliado",
    "Affiliate links": "Links de afiliados",
    "Affiliate link successfully changed": "Link de afiliado alterado com sucesso",
    "Please enter the Bitopex affiliate link here. Kindly note that you can only save the link once.": "Por favor, insira o link de afiliado Bitopex aqui. Observe que você só pode salvar o link uma vez.",
    "Input cannot be only spaces": "A entrada não pode ser apenas espaços",
    "To proceed with the registration, kindly accept the above 2 conditions by checking the corresponding boxes.": "Para prosseguir com o registro, aceite as 2 condições acima, marcando as caixas correspondentes.",
    "I hereby confirm that I am not a US citizen": "Confirmo que não sou cidadão dos US",
    "I hereby confirm that I am not a UAE citizen": "Confirmo que não sou um cidadão dos UAE",
    "Enter Old Password": "Insira a senha antiga",
    "Change username & password": "Alterar nome de usuário e senha",
    "My wallet": "Minha carteira",
    "Purchase": "Comprar",
    "Username required to proceed": "Nome de usuário necessário para prosseguir",
    "Enter preferred username here": "Digite o nome de usuário preferido aqui",
    "Username must be at least 8 characters, starting with a letter. Use only lowercase letters, numbers, hyphens, dots, and underscores.": "O nome de usuário deve ter pelo menos 8 caracteres, começando com uma letra. Use apenas letras minúsculas, números, hífens, pontos e sublinhados.",
    "Username is already taken. Please choose a different username.": "Nome de usuário já utilizado. Por favor, escolha um nome de usuário diferente.",
    "Email or username": "Email ou nome de usuário",
    "Username is available": "Nome de usuário está disponível",
    "Provided username is already taken.": "O nome de usuário fornecido já está em uso.",
    "Invalid credentials. Please try again.": "Credenciais inválidas.\n Por favor, tente novamente.",
    "Username successfully set": "Nome de usuário definido com sucesso",
    "Dear User, due to ongoing updates to our platform, you will need to enter a username to continue. The username needs to be unique and cannot be changed": "Caro usuário, devido às atualizações em andamento em nossa plataforma, você precisará inserir um nome de usuário para continuar. O nome de usuário precisa ser único e não pode ser alterado",
    "You have successfully set your username": "Você definiu seu nome de usuário com sucesso",
    "Continue to dashboard": "Continuar para o painel",
    "Username is required": "Nome de usuário é requerido",
    "Rank Transfer": "Transferência de Classificação",
    "Review": "Análise",
    "Top Up": "Completar",
    "The claim button is currently disabled for maintenance. We apologize for the inconvenience. Please come back shortly.": "O botão de reclamação está atualmente desativado para manutenção. Pedimos desculpas pela inconveniência. Por favor, volte em breve.",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / BUSD": "Quando um novo edifício de negócios estiver a ser implementado, você terá \n a opção de comprar um terreno pagando com GYMNET / BUSD",
    "Parcel NFT": "Parcela NFT",
    "Miner NFT": "Mineiro NFT",
    "BUSD Balance": "Saldo BUSD",
    "Your payment has been received. Está actualmente a ser processado. You will receive a notification when done.": "O seu pagamento foi recebido. Está actualmente a ser processado. Você receberá uma notificação quando estiver terminado.",
    "Error": "Erro",
    "Please enter valid email": "Por favor, introduza um e-mail válido",
    "Your sponsor is": "O seu sponsor é",
    "Credentials": "Credenciais",
    "Enter Wallet Address (i.e. 0xb794f...79268)": "Introduza o endereço da carteira (i.e. 0xb794f...79268)",
    "Contact Customer Support": "Contactar O Apoio Ao Cliente",
    "Change Wallet": "Alterar a Carteira",
    "Account": "Conta",
    "Please leave an address here, in which we can also mint your NFTs afterwards.": "Por favor, deixe um endereço aqui, no qual também podemos cunhar os seus NFTs posteriormente.",
    "Important!": "Importante!",
    "It should be a BEP-20 address - i.e. a BNB Smart Chain wallet address. We recommend the wallet Metamask or Trustwallet.": "Deve ser um endereço BEP-20 ou seja, um endereço de carteira BNB Smart Chain. Recomendamos a carteira Metamask ou Trustwallet.",
    "NEVER USE AN EXCHANGE Wallet ADRESS !!!": "NUNCA USE UM ENDEREÇO de Carteira EXCHANGE !!!",
    "Failed": "Falhou",
    "Account changed": "Conta alterada",
    "Your Profile has been updated": "O Seu Perfil foi atualizado",
    "You already have an attached wallet address to this account.": "Você já tem um endereço de carteira conectado a esta conta.",
    "Login": "Login",
    "Existing wallet": "Carteira existente",
    "Referral ID is required": "ID de referência é necessário",
    "Pending Transaction": "Transação Pendente",
    "Processing": "Processando",
    "Send this payment ID to a customer support when you are requested to do so.": "Envie este ID de pagamento ao suporte de cliente quando lhe for solicitado.",
    "To be able to claim your NFTs you need to connect the same wallet address attached to your account": "Para poder reivindicar os seus NFTs, você precisa conectar o mesmo endereço de carteira anexado à sua conta",
    "Please confirm your email to continue.": "Por favor, confirme o seu e-mail para continuar.",
    "Valid address": "Endereço válido",
    "This social account is used by another user. Verify your email first or contact Customer Support": "Esta conta social é utilizada por outro utilizador. Verifique seu e-mail primeiro ou entre em contato com o Suporte ao Cliente",
    "Invalid wallet connection": "Conexão de carteira inválida",
    "Registration completed successfully": "Registo concluído com êxito",
    "Failed to complete registration of the user": "Não foi possível concluir o registo do utilizador",
    "Failed to register user": "Falha ao registar o utilizador",
    "Signature grant failed": "Falha na concessão da assinatura",
    "Unsupported chain": "Cadeia não suportada",
    "This wallet address is already associated with another user account. Please use another one!": "Este endereço de carteira já está associado a outra conta de utilizador. Por favor, use outro!",
    "Failed to add address to your wallet": "Falha ao adicionar o endereço à sua carteira",
    "Address has been successfully added to your wallet": "O endereço foi adicionado com sucesso à sua carteira",
    "Failed to accept Affiliate TMS": "Falha ao aceitar o Afiliado TMS",
    "Wallet address has been successfully applied": "O endereço da carteira foi aplicado com sucesso",
    "Your email is verified.": "O seu e-mail está verificado.",
    "Failed to add Token": "Falha ao adicionar Token",
    "Failed to upload image": "Falha ao carregar a imagem",
    "Wrong file size. Please upload image files with size less than 10MB": "Tamanho de arquivo errado. Por favor, carregue ficheiros de imagem com tamanho inferior a 10MB",
    "We've verified your email address. You can now continue using the site.": "Verificámos o seu endereço de e-mail. Você pode continuar a utilizar o site.",
    "You need to switch to Binance Smart Chain": "Você precisa de mudar para Binance Smart Chain",
    "Switch to Binance Smart Chain to continue using GymStreet": "Mude para o Binance Smart Chain para continuar a usar o GymStreet",
    "To CashFT": "Para CashFT",
    "Buy a Super Bundle in the next": "Compre um Super Bundle em",
    "and get an EXTRA discount!": "e ganhe um desconto EXTRA!",
    "See All Offers": "Ver Todas As Ofertas",
    "I accept the affiliate terms & conditions": "Aceito os Termos e Condições de Afiliado",
    "Special offer!": "Oferta especial!",
    "Just one": "Apenas um",
    "1000 Mh/s": "1000 Mh/s",
    "View Claimed Coordinates": "Ver Coordenadas Reivindicadas",
    "Your Parcel Coordinates": "Coordenadas da Sua Parcela",
    "Number of miners you want to attach": "Número de mineiros que você quer conectar",
    "Referral Link": "Link de Referência",
    "Affiliate Link": "Link de Afiliado",
    "Invalid Wallet": "Carteira Inválida",
    "Back to Log In": "Voltar ao Log In",
    "You need a valid referral ID to finalize your registration": "Você precisa de um ID de referência válido para finalizar o seu registo",
    "I have read and accept the": "Li e aceito",
    "Your Sponsor": "O Seu Patrocinador",
    "Forgot Your Password": "Esqueceu a Sua Palavra-Passe",
    "Enter your email address and we will send you instructions to reset your password.": "Introduza o seu endereço de e-mail e enviar-lhe-emos instruções para repor a sua palavra-passe.",
    "We just sent you an email to confirm your e-mail address. Please click on the link in the e-mail to reset your password.": "Acabámos de lhe enviar um e-mail para confirmar o seu endereço de e-mail. Por favor, clique no link do e-mail para repor a palavra-passe.",
    "Welcome To Metaverse": "Bem-vindo ao {n} Metaverse",
    "Commissions": "Comissões",
    "Refund of electricity vouchers": "Reembolso dos cupões de eletricidade",
    "Import Custom Token": "Importar um Token Personalizado",
    "Add Token To Wallet": "Adicionar um Token à Carteira",
    "Token contract address": "Endereço do contrato de Token",
    "Token symbol": "símbolo de Token",
    "Provided address is not a supported token address": "O endereço fornecido não é um endereço de token suportado",
    "Are you sure you want to claim your rewards": "Tem certeza de que deseja reivindicar suas recompensas",
    "OTP Code": "Código OTP",
    "OTP Code is required": "O código OTP Code é necessário",
    "Enter OTP code you have received at email": "Introduza o código OTP que você recebeu em {email}",
    "Asset": "Ativo",
    "Gas": "Gás",
    "Send crypto": "Enviar cripto",
    "Enter wallet address": "Introduza o endereço da carteira",
    "NFTs": "NFTs",
    "Activity": "Ativiade",
    "Assets": "Ativos",
    "Import token": "Importar token",
    "Don't see your token?": "Não vê o seu token?",
    "more": "mais",
    "Send": "Enviar",
    "Sending success": "Enviado com sucesso",
    "View account in explorer": "Ver a conta no explorador",
    "estimated": "estimado",
    "Copy your address": "Copiar o seu endereço",
    "Export private key": "Exportar chave privada",
    "Receive": "Receber",
    "Account Details": "Detalhes da Conta",
    "View on blockchain": "Ver no blockchain",
    "GymStreet Parcels": "Parcelas GymStreet",
    "GymStreet Miners": "Mineiros GymStreet",
    "Kabutocho Parcels": "Parcelas Kabutocho",
    "Kabutocho Miners": "Mineiros Kabutocho",
    "Invalid wallet address": "Endereço de carteira inválido",
    "Calculate": "Calcular",
    "Sending": "A enviar",
    "Reject": "Rejeitar",
    "Confirmation": "Confirmação",
    "Gas fees are paid to crypto miners who process transactions on the network. Gas fees are set by the network and fluctate based on network traffic and transaction complexity.": "Gas fees are paid to crypto miners who process transactions on the network. As taxas de gás são definidas pela rede e flutuam com base no tráfego da rede e na complexidade das transações.",
    "Insufficient TOKEN balance": "Saldo de {token} insuficiente",
    "Confirm Swap": "Confirmar o Swap",
    "Approve Allowance": "Aprovar a Allowance",
    "Help Center": "Centro de Ajuda",
    "Confirm the Allowance Transaction in order to be able to use Token through our services. Please note that this is a one-time action.": "Confirme a Transação da Allowance para poder usar o <strong>{n}</strong> Token em todos os nossos serviços. Por favor, note que esta é uma ação que só terá lugar uma vez.",
    "No Activities yet": "Ainda sem atividades",
    "To": "Para",
    "Contract interaction": "Interação do contrato",
    "Are you sure you want to delete this token": "Tem a certeza de que deseja excluir este token",
    "Send only using  {CHAIN}  network to receive funds on this deposit address.": "Envie apenas usando a rede {CHAIN} para receber fundos neste endereço de depósito.",
    "Leadership Incentives": "Incentivos de Liderança",
    "Pool statistics": "Estatísticas de Pool",
    "Hourly": "Por hora",
    "Turnover rule": "Regra de turnover",
    "Turnover rule percentage": "{n}% Regra de turnover",
    "Miner sales contribution": "Contribuição das vendas dos mineiros",
    "Pool": "Pool",
    "percent of NFT sales": "{n}% de vendas de NFT",
    "partner": "parceiro",
    "Main area": "Área principal",
    "Turnover percentage rule": "Regra {n}% de turnover",
    "With dollar each": "Com cada dólar",
    "Direct partners": "Parceiros diretos",
    "Your next bonus will appear here": "O seu próximo bónus vai aparecer aqui",
    "Your incentive": "O seu incentivo",
    "Your cash bonus": "O seu bónus em dinheiro",
    "Your bonus history": "O seu histórico de bónus",
    "At the current moment you don't have any rewards": "No momento atual você não tem nenhuma recompensa",
    "Total generated turnover": "Turnover total gerado",
    "Active Miners": "Mineiros Ativos",
    "Total pool rewards": "Total de recompensas do pool",
    "Your pool share": "A sua parte do pool",
    "Total gGymnet": "Total gGymnet",
    "GYMNET Commissions Total": "Total de Comissões GYMNET",
    "Your attached wallet is {walletAddress}. You have tried to make request with wallet {currentWallet}. Please change connected wallet to {walletAddress}": "A sua carteira conectada é <b>{walletAddress}</b>. You have tried to make request with wallet <b>{currentWallet}</b>. Por favor, altere a carteira conectada para <b>{walletAddress}</b>",
    "Available for limited time": "Disponível por um período limitado",
    "Bonus": "Bónus",
    "Utility": "Ferramenta",
    "The URL must begin with 'https://bitopex.io/home/' and include your referral ID": "O URL deve começar com 'https://bitopex.io/home /' e incluir o seu ID de referência",
    "Insufficient funds: to continue with payment use utility balance": "Fundos insuficientes: para continuar com o pagamento use a ferramenta de saldo",
    "My Team": "A Minha Equipa",
    "Team Statistics": "Estatísticas da Equipa",
    "All partners": "Todos os parceiros",
    "Active partners": "Parceiros ativos",
    "Inactive partners": "Parceiros inativos",
    "Binary Points": "Pontos Binários",
    "Points in left leg": "Pontos na perna esquerda",
    "Points in right leg": "Pontos na perna direita",
    "Left leg": "Perna esquerda",
    "Right leg": "Perna direita",
    "Holding Tank": "Tanque de Armazenamento",
    "Enrollment Tree": "Árvore de Registo",
    "Binary Tree": "Árvore Binária",
    "CV Points History": "Histórico de Pontos CV",
    "Time Left To Activation": "Tempo restante <b>Até a Ativação</b>",
    "Search by email or wallet": "Procurar por e-mail ou carteira",
    "Refresh Data": "Atualizar Dados",
    "Joined at": "Aderiu em",
    "Placement": "Colocação",
    "Select": "Selecionar",
    "Own": "Património",
    "Team": "Equipa",
    "Position": "Posição",
    "CV Points": "Pontos CV",
    "CV = commission volume": "CV = volume de comissão",
    "Genealogy": "Genealogia",
    "Unilevel": "Unilevel",
    "Select view": "Selecionar a vista",
    "Team size": "Tamanho da equipa",
    "Rank": "Rank",
    "Own purchases": "Compras próprias",
    "Navigation": "Navegação",
    "Search by wallet": "Procurar por carteira",
    "no user": "sem utilizador",
    "Wallet address is not valid": "Endereço da carteira inválido",
    "Free Spot": "Lugar Livre",
    "Search by wallet or username": "Procurar por carteira ou utilizador",
    "Info": "Informação",
    "Benefits": "Benefícios",
    "Objectives Completed": "Objetivos Completados",
    "TO DO`s": "TAREFAS",
    "Purchase NFTs In Total Value Of 100 USDT": "Comprar NFTs Num Valor Total De 100 USDT",
    "Purchase Binary Pass - 19 USDT": "Comprar Passe Binário - 19 USDT",
    "Team Turnover": "Turnover da Equipa",
    "BASED ON TEAM": "{n}% + BASEADO NA EQUIPA",
    "Qualification Turnover": "Qualificação de Turnover",
    "Current Rank": "Rank Atual",
    "Next Rank": "Próximo Rank",
    "Binary Spot Reservation": "Reserva de Lugar Binário",
    "Holding Tank Ends In": "Tanque de Retenção <b>Termina Em</b>",
    "With every parcel purchase, you will receive two types of tokens—Gymnet and Bitcoin.": "A cada compra de pacote, você receberá dois tipos de tokens – Gymnet e Bitcoin.",
    "A minimum of USDT is required for Purchase": "Um mínimo de ${n} USDT é necessário para Comprar",
    "Become Affiliate Partner": "Tornar-se Parceiro Afiliado",
    "Placement in Unilevel": "Colocação em Unilevel",
    "Matching bonuses": "Matching bónus",
    "Placement in Binary": "Colocação em Binário",
    "Product Autoship incentive": "Incentivo de Produto Autoship",
    "For limited time only": "Apenas por um período limitado",
    "Free 30 days Product Autoship": "30 dias grátis de Produto Autoship",
    "Fast Start Incentive": "Incentivo de Início Rápido",
    "Receive Direct Sales Commissions": "Receba Comissões de Vendas Diretas",
    "Please select your role": "Por favor, selecione a sua função",
    "Choose your role before diving in. Specify why user should choose each role and what opportunities are there.": "Escolha a sua função antes de começar. Especifique porque o utilizador deve escolher cada função e quais oportunidades existentes.",
    "is left to get": "<span>${n}</span> restam para obter",
    "Go to Products": "Ir Para Produtos",
    "Rental Income": "Rendimento de Renda",
    "Left Leg Affiliate Link": "Link Afiliado da Perna Esquerda",
    "Right Leg Affiliate Link": "Link Afiliado da Perna Direita",
    "Qualification Conditions": "Condições para Qualificação",
    "Buy Products": "Comprar Produtos",
    "My Current Rank": "O Meu Rank Atual",
    "To Reach Rank You Need:": "Para Alcançar o Rank {n} Você Precisa:",
    "NFT Turnover In Weak Leg": "Turnover NFT Turnover Na Perna Fraca",
    "NFT Own purchases": "Compras Próprias de NFT",
    "Required Qualified Partners": "Parceiros Qualificados Necessários",
    "Progress Until Rank": "Progresso Até ao Rank {n}",
    "See Details": "Ver Detalhes",
    "Default Advantages": "Vantagens Padrão",
    "Binary commission": "Comissão Binária",
    "Upgrade Your Weekly Cap": "Atualize o Seu Limite Semanal",
    "Rank Benefits": "Benefícios do Rank",
    "For 30 Days ONLY You Receive 10% Binary Commission Instead Of NUM": "Durante APENAS 30 Dias Você Recebe 10% de Comissão Binária, Em Vez de {n}%!",
    "My Rank Progress": "O Meu Progresso de Rank",
    "Own Invest": "Investimento Próprio",
    "Qualified Partners": "Parceiros Qualificados",
    "You need to buy products in value of": "Você tem de comprar produtos no valor de ${n}",
    "You need to generate turnover in value of in your weak leg.": "Você tem de gerar turnover no valor de ${n} na sua perna fraca.",
    "Remaining": "Restantes",
    "CV Points weak leg": "Pontos CV perna fraca",
    "Details": "Detalhes",
    "leg": "perna",
    "Right": "Direita",
    "Left": "Esquerda",
    "You need qualified partner in left leg and qualified partner in right leg to complete this requirement.": "Você necessita de {l} parceiro qualificado na perna esquerda e {r} parceiro qualificado na perna direita para completar este requisito.",
    "partners in left leg and in right leg": "{l} {partner1} na perna esquerda e {r} na perna direita",
    "partners in left leg remaining": "{l} {partner1} na perna esquerda restantes",
    "partners in right leg remaining": "{r} parceiros na perna direita restantes",
    "partner in right leg remaining": "{r} parceiro na perna direita restante",
    "Holding Tank is over": "Holding Tank terminou",
    "Back To Rank Progress Details": "Voltar aos Detalhes da Progressão do Rank",
    "Super Bundle R10 or above": "Super Bundle R10 \n ou acima",
    "Required Monthly Product Autoship": "Produto Autoship Mensal Necessário",
    "All Customer Benefits": "Todos os Benefícios do Cliente",
    "Product Autoship": "Produto Autoship",
    "Product Autoships": "Produtos Autoships",
    "Products are resellable": "Produtos podem ser revendidos",
    "Activate binary commission": "Ativar comissão binária",
    "Activate direct sales commission": "Ativar comissão de vendas diretas",
    "Left before": "Esquerda antes",
    "Left after": "Esquerda depois",
    "Right before": "Direita antes",
    "Right after": "Direita depois",
    "Activate your referral link to be able to invite your referrals to the platform and earn additional commissions.": "Ative o seu link de referência para que possa convidar os seus referrals para a plataforma e ganhar comissões adicionais.",
    "Activate referral link": "Ativar o link de referência",
    "Yes, I confirm": "Sim, confirmo",
    "Inactive": "Inativo",
    "Finance": "Financiar",
    "Total Income": "Rendimento Total",
    "Products": "Produtos",
    "GymStreet Map": "Mapa GymStreet",
    "Kabutocho Map": "Mapa Kabutocho",
    "Don't ask again": "Não voltar a perguntar",
    "Filecoin Balance": "Saldo Filecoin",
    "Filecoin": "Filecoin",
    "Total available": "Total disponível",
    "Total generated": "Total gerado",
    "Total used": "Total usado",
    "Transaction type": "Tipo de transação",
    "To claim your commission, you must have a minimum of 1 USDT or (AMOUNT) GYMNET.": "Para reivindicar as sua comissão, você tem de ter um mínimo de 1 USDT ou {n} GYMNET.",
    "Loading balance...": "A carregar o saldo...",
    "Total Commissions": "Total de Comissões",
    "Claimed Commissions": "Comissões Reivindicadas",
    "Available Commissions": "Comissões disponíveis",
    "Total Commissions By Source": "Total de Comissões por Fonte",
    "Filter by currency": "Filtrar por moeda",
    "No data to display": "Sem dados para mostrar",
    "Year": "Ano",
    "Career Status": "Status de Carreira",
    "Current Incentive": "Incentivo Atual",
    "Fast Start": "Início Rápido",
    "Direct partner - left leg": "Parceiro direto - perna esquerda",
    "Direct partner - right leg": "Parceiro direto - perna direita",
    "Receive direct sales commission": "Receber comissão de vendas diretas",
    "Direct sales commission": "Comissão de vendas diretas",
    "Receive binary commission": "Receber comissão binária",
    "Affiliate ranks": "Ranks afiliados",
    "1 Left, 1 Right": "1 Esquerda, 1 Direita",
    "See Benefits": "Ver Benefícios",
    "2 Silver (1 in different teams)": "2 Prata (1 em equipas diferentes)",
    "2 Gold (1 in different teams)": "2 Ouro (1 em equipas diferentes)",
    "3 Platin (1 in different teams)": "3 Platina (1 em equipas diferentes)",
    "3 Double Platin (1 in different teams)": "3 Dupla Platina (1 em equipas diferentes)",
    "4 Diamond (1 in different teams)": "4 Diamante (1 em equipas diferentes)",
    "5 Double Diamond (1 in different teams)": "5 Duplo Diamante (1 em equipas diferentes)",
    "5 Red Diamond (1 in different teams)": "5 Diamante Vermelho (1 em equipas diferentes)",
    "5 White Diamond (1 in different teams)": "5 Diamante Branco (1 em equipas diferentes)",
    "Please note that $100 dollar will correspond to 100 points.": "Por favor, note que $100 dólares correspondem a 100 pontos.",
    "Home": "Página Inicial",
    "Last Left": "Última Esquerda",
    "Last Right": "Última Direita",
    "Binary leg": "Perna binária",
    "by admin": "pelo admin",
    "missed commission": "comissão falhada",
    "payment": "pagamento",
    "commission": "comissão",
    "Payment Currency": "Moeda de Pagamento",
    "BUSD Amount": "Quantidade de BUSD",
    "CV Ratio": "Rácio CV",
    "Hrs": "Hrs",
    "Mins": "Mins",
    "Secs": "Segs",
    "Please enter a valid referral ID": "Por favor, introduza uma ID de referência válido",
    "Commission TXID": "Comissão TXID",
    "Additional Advantages": "Vantagens Adicionais",
    "The referral ID you've entered is incorrect. Please copy and paste the correct referral link to proceed.": "O ID de referência que você introduziu não está correto. Por favor, copie e cole o link de referência correto para prosseguir.",
    "In order to receive binary commissions you need to have an active product autoship.": "Para receber comissões binárias, é necessário ter um produto ativo de venda automática.",
    "In order to receive binary commissions you need to have an active product autoship and a purchase for at least $100.": "Para receber comissões binárias, é necessário ter um produto ativo e uma compra de pelo menos $100.",
    "To qualify for binary commissions, a minimum product purchase of $100 is required.": "Para se qualificar para comissões binárias, é necessária uma compra de produto mínima de $100.",
    "Transaction in Process": "Transação em processo",
    "Claim your parcel": "Reivindique seu pacote",
    "You do not have any claimed slots to attach miners. To enable miner attachment, please claim parcels on the map.": "Você não tem nenhum slot reivindicado para anexar mineradores. Para ativar o anexo do minerador, reivindique os pacotes no mapa.",
    "Active binary commission": "Comissão binária ativa",
    "Active direct sales commission": "Comissão ativa de vendas diretas",
    "Own purchase min {AMOUNT}": "Compra própria no mínimo {AMOUNT}",
    "Active product autoship": "Envio automático de produto ativo",
    "Right partner own purchase min {AMOUNT}": "Compra própria do parceiro certo, no mínimo {AMOUNT}",
    "Left partner own purchase min {AMOUNT}": "Deixou a compra do próprio parceiro no mínimo de {AMOUNT}",
    "Unfortunately, you won't receive the bonus for this rank since you met the qualification conditions (more than 50%) before September 18, 2023": "Infelizmente, você não receberá o bônus para esta classificação porque atendeu às condições de qualificação (mais de 50%) antes de 18 de setembro de 2023",
    "You will be eligible for cash bonus based on your rank if you meet all qualification conditions between September 18 and November 8.Those who met the qualification conditions (more than 50%) before September 18, 2023 will not receive the Super Cash Bonus.": "Você será elegível para bônus em dinheiro com base em sua classificação se atender a todas as condições de qualificação entre 18 de setembro e 31 de janeiro. Aqueles que atenderam às condições de qualificação (mais de 50%) antes de 18 de setembro de 2023 não receberão o Super Cash Bonus.",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / USDT": "Quando um novo edifício estiver a ser implementado, você terá a opção de comprar um terreno pagando com GYMNET / USDT",
    "Your payment has been received. It is currently being processed. You will receive a notification when done.": "O seu pagamento foi recebido. Encontra-se em processamento. Você vai receber uma notificação quando estiver concluído.",
    "yearly": "Anualmente",
    "Unknown": "Desconhecido",
    "Product Autoship Incentive": "Incentivo de Produto Autoship",
    "Binary Commission": "Comissão Binária",
    "Partner": "Parceiro",
    "Qualification turnover": "Qualificação de rendimento",
    "Claim processing may take up to 2 minutes. Thank you for your patience.": "O processamento do seu pedido pode demorar até 2 minutos. Obrigado pela sua paciência.",
    "1 Person": "1 Pessoa",
    "2 Persons": "2 Pessoas",
    "Extended Referral Link": "Referral Link Prolongado",
    "Enter a username": "Introduza um nome de utilizador",
    "Possible only for usernames in your binary team!": "É possível apenas para nomes de utilizadores da sua equipa binária!",
    "Select Team": "Selecione uma Equipa",
    "Create Link": "Criar um Link",
    "New users will be placed under in the team": "Novos utilizadores serão colocados {n} na {p} equipa",
    "Invalid username!": "Nome de utilizador inválido!",
    "Left team": "Equipa esquerda",
    "Right team": "Equipa direita",
    "Distribution In Progress": "Distribuição Em Curso",
    "Next Binary Bonus Distribution In": "Próxima Distribuição de Bónus Binário Em",
    "Matching Bonus Commissions": "Comissões de Matching Bónus",
    "Binary Commissions": "Comissões Binárias",
    "Binary Weekly Cap": "Limite Semanal Binário",
    "Binary Cycles": "Ciclos Binários",
    "Matching Bonus": "Bónus de Matching",
    "Incomes from Product Autoships": "Rendimentos dos Produtos Autoships",
    "Missed Commissions": "Comissões Perdidas",
    "Plus Max Weekly Payout": "Pagamento Semanal Plus Max",
    "Import Token With Address": "Importar Token Com Morada",
    "Import Supported Token": "Importar Token Suportado",
    "Shop": "Loja",
    "Filter by": "Filtrar por",
    "My Purchases": "As Minhas Compras",
    "Purchases for other members": "Compras para outros membros",
    "Product Autoship Purchases": "Compras de Produto Autoship",
    "Sort By": "Ordenar Por",
    "Top up": "Recarregar",
    "Attach your miners": "Conecte os seus mineiros",
    "Buy parcels": "Comprar parcelas",
    "Number of parcels you want to buy": "Número de parcelas que você quer comprar",
    "Use utility balance": "Usar saldo de utility",
    "My Products": "Os Meus Produtos",
    "USDT Amount": "Quantidade de USDT",
    "Next Matching Bonus Distribution In": "Próxima Distribuição de Bónus de Matching Em",
    "Please top up your wallet with AMOUNT_CURRENCY USDT for upgrade.": "Por favor, recarregue a sua carteira com {AMOUNT_CURRENCY} USDT para o upgrade.",
    "Please note that worth of USDT is required to complete the transaction.": "Por favor, note que {n} de USDT é necessário para completar a transação.",
    "Incentive Ends In:": "O Incentivo Termina Em:",
    "Bonus History": "Histórico de Bónus",
    "Incentive has ended": "O Incentivo Terminou",
    "Direct Sales Turnover": "Rendimento de Vendas Diretas",
    "Commissions are paid out following a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "As comissões são pagas segundo um rácio 70/30, sendo 70% em stablecoins e 30% em tokens GYMNET.",
    "(effective as of 28.02.2024)": "(em vigor a partir de 28.02.2024)",
    "Free Product Autoship": "Envio automático de produto grátis",
    "10% Binary Commission": "Comissão Binária de 10%",
    "Enjoy More, Benefit More!": "Aproveite mais, beneficie mais!",
    "To qualify for Binary Commissions, you must have a new member with at least $100 on your right team and another on your left team.": "Para se qualificar para as Comissões Binárias, você deve ter um novo membro com pelo menos US$ 100 na equipe direita e outro na equipe esquerda.",
    "Buy products for at least $100 within 30 days to receive these benefits.": "Compre produtos por pelo menos <span>US$ 100</span> em <span>30 dias</span> para receber esses benefícios.",
    "Getting started": "Começando",
    "Confirm your email to continue": "Confirme seu e-mail para continuar",
    "Resend confirmation email": "Reenviar email",
    "Super Bundle Special Discount Expires In": "O desconto especial do Super Bundle expira em",
    "Done": "Feita",
    "Get your bundle": "Obtenha seu pacote",
    "Gain Fresh Perspectives and Exclusive Benefits": "Obtenha novas perspectivas e benefícios exclusivos",
    "Super Bundle R8": "Super Pacote R8",
    "Super Bundle R17": "Super Pacote R17",
    "Starter Bundle": "Pacote de iniciação",
    "Travel": "Promoção de viagens",
    "Cash Promo": "Promoção em dinheiro",
    "Monthly Product Autoship is required to be an Affiliate Partner and earn commissions.": "O envio automático mensal de produtos é necessário para ser um parceiro afiliado e ganhar comissões.",
    "Affiliate": "Afiliada",
    "Recommended": "Recomendada",
    "Binary Commissions based on the rank": "Comissões binárias baseadas na classificação",
    "Customer": "Cliente",
    "NFT Turbo": "NFT Turbo",
    "Purchase Digital Land": "Comprar terreno digital",
    "Participate in Partnership Deals": "Participe de acordos de parceria",
    "Receive minting Rewards": "Receba recompensas de cunhagem",
    "Up to": "Até {n}%",
    "Weekly Distribution": "Distribuição Semanal",
    "A parcel with minting power will generate rewards in Filecoin tokens, which will be distributed every Monday.": "Um pacote com poder de cunhagem gerará recompensas em tokens Filecoin, que serão distribuídos todas as segundas-feiras.",
    "It seems that a some request is already pending. Please check your wallet app or extension and try again.": "Parece que alguma solicitação já está pendente. Verifique seu aplicativo ou extensão de carteira e tente novamente.",
    "Add miner NFT to wallet": "Adicione Miner NFT à carteira",
    "Go to My NFTs": "Vá para Meus NFTs",
    "Thank you for your participation": "Obrigado pela sua participação",
    "No owner": "Nenhum proprietário",
    "Ownership of this bundle is limited to one per user. Please purchase for yourself and for those who do not have it.": "A propriedade deste pacote é limitada a um por usuário. Compre para você e para quem não tem.",
    "Buy parcels with minting power and generate income.": "Compre parcelas com poder de cunhagem e gere renda.",
    // Mystery box
    "Limited time offer": "Oferta por tempo limitado",
    "See Options": "Ver opções",
    "Please select one of the currencies": "Seleccione uma das moedas",
    "Current balance": "Saldo atual",
    "Try your luck": "Tente a sua sorte",
    "Offer ends in": "A oferta termina em",
    "Parcel and Dual Miner": "Pacote e Dual Miner",
    "Mystery Box": "Caixa mistério",
    "Try your luck and win incredible Bundles, Parcels and Dual Miners to boost your earnings!": "Tente a sorte e ganhe pacotes, pacotes e mineradores duplos incríveis para aumentar seus ganhos!",
    "from MIN to MAX": "de <b>{min}</b> a <b>{max}</b>",
    "Choose a Box and Discover Rewards": "Escolha uma caixa e descubra recompensas",
    "Prizes you can win": "Prémios que pode ganhar",
    "Item": "Item",
    "Chance": "Chance",
    "Promos": "Promoções",
    "Mystery Box results": "Resultados da Caixa Misteriosa",
    "Total reward value": "Valor total da recompensa",
    "My Rewards": "Minhas recompensas",
    "Rewards Community Claimed": "Comunidade de recompensas reivindicada",
    "Hero image": "Imagem do herói",
    "No rewards yet": "Ainda não há recompensas",
    "No winners yet": "Ainda não há vencedores",
    "While no lucky participants have claimed their prizes yet, the excitement continues to build.": "Embora nenhum participante sortudo tenha resgatado seus prêmios ainda, a emoção continua aumentando.",
    "Mystery Box is Here!": "A caixa misteriosa está aqui!",
    "Be the First to Discover Mystery Rewards": "Seja o primeiro a descobrir recompensas misteriosas",
    "Select Balance": "Selecione Saldo",
    "Current Balance": "Saldo Atual",
    "Opening Mystery Box": "Abrir a Caixa Misteriosa",
    "Oops, not this time 😔": "Ops, desta vez não 😔",
    "Luck is on your side. Enjoy your prize!": "A sorte está do vosso lado. Desfrute do seu prémio!",
    "The more you try, the better your chances of winning. Keep going!": "Quanto mais você tentar, maiores serão suas chances de ganhar. Continue!",
    "Try another box": "Experimente outra caixa",
    "Go to My Products": "Acesse Meus Produtos",
    "See promo results": "Ver resultados da promoção",
    "Payment enabled": "Pagamento ativado",
    "Please select the wallet that is attached to your account.": "Selecione a carteira que está anexada à sua conta.",
    "Explore rewards": "Explorar recompensas",
    "View rewards claimed by you and the community": "Ver as recompensas reclamadas por si e pela comunidade",
    "Open a Mystery Box to get rewards starting from Parcels to Super bundles.": "Abra uma caixa misteriosa para obter recompensas que vão desde Parcels a Super bundles.",
    "Insufficient funds, please choose another box.": "Fundos insuficientes, por favor seleccione outra caixa.",
    "Blockchain error": "Erro de cadeia de blocos",
    "Rewards claimed": "Prémios solicitados",
    "We have encountered difficulties while processing the result of the reward. Please contact our support team for prompt assistance.": "Encontrámos dificuldades ao processar o resultado do prémio. Contacte a nossa equipa de apoio para obter assistência imediata.",
    "Boxes opened": "Caixas abertas",
    // Mystery box

    // Partnership details
    "About": "Sobre a",
    "Offer": "Oferta",
    "Social Media": "Redes sociais",
    "Additional Content": "Conteúdo adicional",
    "Photo": "Fotografia",
    "Video": "Vídeo",
    // Partnership details

    // Miner Rewards
    "Single Miners": "Mineiros individuais",
    "price today": "preço hoje",
    "Estimated daily rewards": "Recompensas diárias estimadas",
    "day": "dia",
    "day at current rate": "dia à taxa atual",
    "Estimated rewards until 2025": "Recompensas estimadas até 2025",
    "at current rate": "à taxa atual",
    "Estimated rewards from today to December 1, 2025. It may change based on your shares in the pool.": "Recompensas estimadas de hoje a 1 de dezembro de 2025. Pode mudar com base nas suas acções no fundo comum.",
    "Expected rewards calculator": "Calculadora de recompensas esperadas",
    "price": "preço",
    "My total GYMNET value in USD": "Meu valor total GYMNET em dólares americanos",
    "claimed": "reivindicado",
    "My Balances": "Os meus saldos",
    "Tokens to be burned": "Fichas a queimar",
    "You dont have any Claimed or Pending GYMNET rewards.": "Você não tem nenhuma recompensa GYMNET reivindicada ou pendente.",
    "Claim now": "Reivindique agora",
    "No claimed GYMNET rewards are found.": "Nenhuma recompensa GYMNET reivindicada foi encontrada.",
    // Miner Rewards

    "Please note that the leg with the lesser amount of points is called weak leg and the one with the higher amount of points is called strong or power leg, and it can change over time based on which leg the most turnover is generated.": "Observe que a perna com menor quantidade de pontos é chamada de \"perna fraca\" e aquela com maior quantidade de pontos é chamada de \"perna forte\" ou \"perna poderosa\", e pode mudar com o tempo com base em qual perna tem maior rotatividade. é gerado.",
    "Mystery Box price": "Preço da Caixa Mistério",

    "Registration Date": "Data de registro",
    "First Purchase Date": "Data da primeira compra",
    "Product Autoship Valid Until": "Envio automático do produto válido até",

    "Own Deposit": "Depósito próprio",
    "years ago": "anos atrás",
    "Rank Achievement Bonus": "Bônus de conquista de classificação",
    "Rank achievement bonus eligibility is based on rank achieved through qualifications.": "A elegibilidade para o bônus de conquista de classificação é baseada na classificação alcançada por meio de qualificações.",
    "Bonuses": "Bónus",
    "Bonuses are paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET tokens": "Os bónus são pagos segundo um rácio de 70/30, com 70% em USDT e 30% em tokens GYMNET",
    "Rank current": "Rank {n}",
    "You will receive the bonus after the incentive is over, and you can view it in the 'My Wallet' section.": "Você receberá o bônus após o término do incentivo e poderá visualizá-lo na seção ‘Minha Carteira’.",
    "You must have at least RANK partners, with at least one in a different leg.": "Você deve ter pelo menos {rank} parceiros, com pelo menos um em uma etapa diferente.",
    "Additional Assets": "Ativos Adicionais",
    "Back to My wallet": "Voltar para Minha carteira",
    "Bonus points": "Pontos bônus",
    "Fee": "Taxa",
    "Bonus Details": "Detalhes do bônus",
    "Details / weekly cap": "Detalhes/limite semanal",
    "User / amount": "Usuário/quantidade",
    "Extended Ref Link": "Link de referência estendido",
    "Leadership": "Liderança",
    "Travel Incentive": "Incentivo a viagens",
    "Unlock the full potential of your Filecoin rewards as they come to life following your purchase of any of our special bundles. Once your Filecoin Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Desbloqueie todo o potencial de suas recompensas Filecoin à medida que elas ganham vida após a compra de qualquer um de nossos pacotes especiais. Assim que seu Saldo Filecoin estiver disponível, você terá acesso a uma visão geral de suas recompensas totais, recompensas recebidas e recompensas esperadas. Além disso, uma contagem regressiva mostra a próxima distribuição de recompensas, garantindo que você nunca perca as recompensas.",
    "is left to qualify": "Resta ${n} para se qualificar",
    "Check transactions": "Verifique transações",
    "Direct partner own purchases": "Compras próprias do parceiro direto",
    "Each of your direct partners on the left and right legs needs to generate a turnover value of $100.00.": "Cada um de seus parceiros diretos nas pernas esquerda e direita precisa gerar um valor de faturamento de US$ 100,00.",
    // Feature Prioritization
    "Participation to GYMSTREETs Elite Vote": "Participação na Votação Elite do GYMSTREET",
    "Prioritization ends in": "A priorização termina em",
    "Start Prioritizing": "Comece a priorizar",
    "Your changes have been saved": "suas alterações foram salvas",
    "features are still remaining": "<b>{n} recursos</b> ainda restam",
    "feature is still remaining": "<b>{n} recurso</b> ainda resta",
    "All prioritized": "Todos priorizados",
    "GYMSTREETs Elite Vote Statistics": "Estatísticas de voto de elite do GYMSTREET",
    "Just NUM features to go! Complete your prioritization to help us shape community's vision.": "Falta apenas <b>{countText}</b>! Conclua sua priorização para nos ajudar a moldar a visão da comunidade.",
    "You've prioritized the features. Please review your choices and hit Submit to finalize.": "Você priorizou os recursos. Revise suas escolhas e clique em <b>“Enviar”</b> para finalizar.",
    "Back to All Features": "Voltar para todos os recursos",
    "Changes saved": "Alterações salvas",
    "No changes": "Sem alterações",
    "Submit": "Enviar",
    "priority": "prioridade",
    "Set priority": "Definir prioridade",
    "Feature details": "Detalhes do recurso",
    "Select a feature to see more info": "Selecione um recurso para ver mais informações",
    "Prioritization Submitted": "Priorização enviada",
    "The results will be available after the voting period ends. You'll be able to change your priorities until then.": "Os resultados estarão disponíveis após o término do período de votação. Você poderá mudar suas prioridades até então.",
    "Go to Career Status page": "Vá para a página Status da carreira",
    "Keep Prioritizing": "Continue priorizando",
    "Continue Prioritizing": "Continuar priorizando",
    "Review and Submit": "Revise e envie",
    "Thinking of reprioritizing?": "Pensando em redefinir prioridades?",
    "You've already submitted your priorities. If you want to reprioritize them, please make sure you hit Resubmit.": "Você já enviou suas prioridades. Se você quiser repriorizá-los, certifique-se de clicar em <b>“Reenviar”</b>.",
    "Got it": "Entendi",
    "Resubmit": "Reenviar",
    "Priorities submitted": "Prioridades enviadas",
    "Submitted": "Submetido",
    "View Statistics": "Ver estatísticas",
    "Continue Prioritization": "Continuar a priorização",
    "Reprioritize": "Repriorizar",
    "Start Prioritization": "Iniciar priorização",
    "features": "características",
    "feature": "recurso",
    "GYMSTREET Elite Vote": "Voto de elite do GYMSTREET",
    "Thanks for submitting! You can still reprioritize and resubmit your choices until the voting period ends.": "Obrigado por enviar! Você ainda pode redefinir prioridades e reenviar suas escolhas até o final do período de votação.",
    "Youre not done yet. Continue prioritizing the upcoming features and submit your final choices.": "Você ainda não terminou. Continue priorizando os próximos recursos e envie suas escolhas finais.",
    "Exclusive for Platin and higher rank members. Prioritize the features you want to see next on GYMSTREET.": "Exclusivo para membros Platin e de nível superior. Priorize os recursos que você deseja ver a seguir no GYMSTREET.",
    "View rules": "Ver regras",
    "Prioritization has ended. See the community's vote results.": "A priorização terminou. Veja os resultados da votação da comunidade.",
    "Stay tuned": "Fique atento",
    "See current community prioritization statistics below. In 7 days, we'll also announce our decision right here, revealing the selected new features and their estimated release dates.": "Veja as estatísticas atuais de priorização da comunidade abaixo. Em 7 dias, também anunciaremos nossa decisão aqui mesmo, revelando os novos recursos selecionados e suas datas estimadas de lançamento.",
    "Terms and Conditions for GYMSTREETs Elite Vote": "Termos e Condições para Votação Elite do GYMSTREET",

    // Suspicous activity
    "You have one attempt left": "Só lhe resta uma tentativa. Depois disso, as suas tentativas para esta conta serão temporariamente restringidas.",
    "You have no attempts left": "Já não tem tentativas para esta conta. Tente novamente em {minutesText} <b>minutos</b> ou redefina a sua palavra-passe.",
    "Sorry, you’ve been blocked": "Lamentamos, mas foi bloqueado",
    "Your IP address has been temporarily blocked for security reasons.": "O seu endereço IP foi temporariamente bloqueado por motivos de segurança. Aguarde, será redireccionado automaticamente em breve.",
    "If you believe this was a mistake, please contact us through our Customer Support.": "Se acredita que isto foi um erro, contacte-nos através do nosso <a href=\"mailto:support@gymstreet.io?subject=Suspicious Activity&body=I am blocked due to suspicious activity, but I think It's a mistake\" class=\"customer-support-button\">Suporte ao Cliente.</a>",
    // Instant commissions
    "The period for earning mining rewards in BITOPEX tokens has officially concluded. Thanks for your participation. Enjoy your BPX earnings.": "O período para ganhar recompensas de mineração em tokens BITOPEX foi oficialmente concluído. Obrigado pela sua participação. Aproveite seus ganhos BPX.",
    "We're enhancing our claim feature, so it will be offline, for the next {hours} hours. Thank you and stay tuned!": "Estamos a melhorar a nossa funcionalidade de reclamação, pelo que estará offline durante as próximas {hours} horas. Obrigado e fiquem atentos!",
    "Eligibility for a one-time 10% commission, based on the total turnover generated from direct sales, requires referring team members and achieving a turnover of $5,000 or more in direct sales.": "A elegibilidade para uma comissão única de 10%, com base no faturamento total gerado pelas vendas diretas, exige a indicação de membros da equipe e a obtenção de um faturamento de US$ 5.000 ou mais em vendas diretas.",

    "Turnover Incentive": "Incentivo à rotatividade",
    "Available For Limited time only": "Disponível apenas por <b>tempo limitado</b>",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving $20,000 in direct sales.": "Observe que você está qualificado para receber um pacote R8 gratuito ao atingir US$ 20.000 em vendas diretas.",

    "Minting Rewards": "Recompensas de cunhagem",
    "Please check transactions if your {asset} balance is not visible": "Verifique as transações se o saldo de {asset} não estiver visível",
    "In order to send {asset} you need to select {chain} network and wallet that is connected to your account.": "Para enviar {asset} precisa de selecionar a rede {chain} e a carteira que está ligada à sua conta.",
    "Cash bonus eligibility is based on rank achieved through qualifications. Participants qualifying with over 50% before March 4th, 2024, will not receive the bonus.": "A elegibilidade para bônus em dinheiro é baseada na classificação alcançada por meio de qualificações. Os participantes qualificados com mais de 50% antes de 4 de março de 2024 não receberão o bônus.",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving 100k in team purchases.": "Observe que você está qualificado para receber um pacote R8 gratuito ao atingir 100 mil em compras de equipe.",

    "Convert": "Converter",
    "Convert your unused miners into hashpower to earn rewards in Gymnet and Filecoin": "Converta os seus mineiros não utilizados em hashpower para ganhar recompensas em Gymnet e Filecoin",
    "The 50% Turnover Rule is applied to this incentive": "A regra de rotatividade de 50% é aplicada a este incentivo",

    "Super Bundle Rank Benefits": "Benefícios de classificação do Super Bundle",
    "Matching bonus up to NUM levels": "Bônus correspondente até {num} níveis",
    "Up to NUM levels": "Até {num} níveis",
    "Left leg partner": "Parceiro da perna esquerda",
    "Right leg partner": "Parceiro da perna direita",
    "Commission Activation Requirements": "Requisitos de ativação de comissão",
    "Up to 1 level": "Até 1 nível",
    "Bonus is paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET": "O bônus é pago seguindo uma proporção de 70/30, com 70% em USDT e 30% em GYMNET",
    "Current Turnover": "Faturamento Atual",
    "You will receive the bonus once the incentive period is over and it will be deposited directly into your wallet.": "Você receberá o bônus assim que o período de incentivo terminar e ele será depositado diretamente em sua carteira",
    "Purchase Amount": "Valor da compra",
    "Spring": "Primavera",
    "Spring Incentive": "Incentivo de Primavera",
    "Commission Earned": "Comissão ganha: <b>${n}</b>",
    "Bonus Earned": "Bónus ganho: <b>${n}</b>",
    "Earn 1 PAP point for every dollar spent on purchases using a utility balance or when you purchase a product autoship.": "Ganhe 1 ponto PAP para cada dólar gasto em compras usando um saldo de serviços públicos ou ao comprar um produto com envio automático.",
    "Member": "Affiliate",
    "Trainee": "Trainee",
    "Rookie": "Rookie",
    "Silver": "Silver",
    "Gold": "Gold",
    "Platinum": "Platinum",
    "Double Platinum": "Double Platinum",
    "Diamond": "Diamond",
    "Double Diamond": "Double Diamond",
    "Red Diamond": "Red Diamond",
    "White Diamond": "White Diamond",
    "Crown Diamond": "Crown Diamond",
    "Platin": "Platinum",
    "Double Platin": "Double Platinum",
    "Single Pool": "Single Pool",
    "Explorer": "Explorer",
    "Mastery": "Mastery",

    // CashFT ---
    "Features expected timeframes revealed": "Apresenta prazos esperados revelados",
    "Find estimated timelines for features prioritized by our community and aligned with our strategy.": "Encontre cronogramas estimados para recursos priorizados por nossa comunidade e alinhados com nossa estratégia.",
    "Estimated timeframe": "Prazo estimado",
    "Undefined": "Indefinido",
    "No Longer Valid": "Não muito valido",
    "Deposit to Single pool": "Depósito para single pool",
    "Max": "Máx.",
    "Received": "Recebido",
    "About voting rights": "Sobre direitos de voto",
    "Your first deposit must be over $25": "Seu primeiro depósito deve ser superior a $ 25",
    "Staking period ends": "O período de piquetagem termina",
    "December": "dezembro",
    "Deposit & Stake": "Depósito e aposta",
    "Purchasing the card requires passing the KYC procedure.": "A compra do cartão requer aprovação no procedimento KYC.",
    "Continue to KYC": "Continuar para KYC",
    "KYC Verification": "Verificação KYC",
    "Step": "Etapa {n}",
    "Upload ID": "Carregar ID",
    "Male": "Macho",
    "Female": "Fêmea",
    "Gender": "Gênero",
    "ID Type is required": "O tipo de ID é obrigatório",
    "ID Document Language is required": "O idioma do documento de identificação é obrigatório",
    "ID Expiration Date is required": "A data de expiração do ID é obrigatória",
    "ID Issue Date is required": "A data de emissão do documento de identidade é obrigatória",
    "State must be at least 3 characters": "O estado deve ter pelo menos três caracteres",
    "ID Type": "Tipo de identificação",
    "National Identity Card": "Bilhete de Identidade Nacional",
    "Driver's License": "Carteira de motorista",
    "Passport": "Passaporte",
    "ID Document Language": "Idioma do documento de identificação",
    "ID Number be at least 3 characters": "O número de identificação deve ter pelo menos 3 caracteres",
    "ID Issue Date": "Data de emissão do documento de identificação",
    "ID Expiration Date": "Data de expiração do ID",
    "You need to upload a photo of your document Please upload a png or jpeg, not a pdf.": "Você precisa enviar uma foto do seu documento \n Envie um <span style='color: #1A6C99'>png ou jpeg, não um pdf</span>.",
    "File size exceeds {n} MB.": "O tamanho do arquivo excede {n} MB.",
    "Front side of ID": "Parte frontal do documento de identidade",
    "Back side of ID": "Verso do documento de identidade",
    "Upload proof of address document": "Carregar documento comprovativo de morada",
    "Upload proof of address, which must not be older than 75 days.": "Carregue comprovante de endereço, que não deve ter mais de 75 dias.",
    "Acceptable forms of proof of address include a credit card statement, utility bill, bank statement or bank letter.": "As formas aceitáveis de comprovante de endereço incluem extrato de cartão de crédito, conta de serviços públicos, extrato bancário ou carta bancária.",
    "Address Document Type is required": "O tipo de documento de endereço é obrigatório",
    "Document Issued by is required": "Documento emitido por é obrigatório",
    "Document Issued Date is required": "A data de emissão do documento é obrigatória",
    "Address Document Type": "Tipo de documento de endereço",
    "Credit Card Statement": "Extrato do cartão de crédito",
    "Utility Bill": "Conta de serviços públicos",
    "Bank Statement": "Extrato bancário",
    "Bank Letter": "Carta Bancária",
    "Document Issued by": "Documento emitido por",
    "Document Issued Date": "Data de emissão do documento",
    "Please upload a legible document in png or jpeg format.": "Faça upload de um documento legível <span style='color: #1A6C99'>em formato png ou jpeg.</span>",
    "Submit for Verification": "Enviar para verificação",
    "Your documents have been submitted for verification. Once verification has been approved, you can continue your purchase.": "Seus documentos foram enviados para verificação. Depois que a verificação for aprovada, você poderá continuar sua compra.",
    "You need more GYMNET to make a deposit in Single Pool": "Você precisa de <b>{n} mais GYMNET</b> para fazer um depósito no Single Pool",
    "You need more USDT to purchase a bundle": "Você precisa de <b>{n} USDT adicionais</b> para comprar um pacote",
    "Security Assurance": "Segurança\nGarantia",
    "Global Accessibility": "Global\nAcessibilidade",
    "Affiliate Earnings": "Afiliado\nGanhos",
    "Effortless Integration": "Integração\nFácil",
    "Cards": "Cartões",
    "Overview": "Visão geral",
    "Information": "Informação",
    "Invoices": "Faturas",
    "KYC Status": "Status KYC",
    "Try again": "Tente novamente",
    "Apply here": "Inscreva-se aqui",
    "Not verified": "Não verificado",
    "Pending verification": "Verificação pendente",
    "Rejected": "Rejeitado",
    "Verified": "Verificado",
    "Next service fee in": "Próxima taxa de serviço em",
    "No active membership": "Nenhuma associação ativa",
    "Card": "Cartão",
    "Card number": "Número do cartão",
    "PIN Set Successfully": "PIN definido com sucesso",
    "Go to Dashboard": "Ir para a dashboard",
    "4-digit PIN": "PIN de 4 dígitos",
    "Re-enter 4-digit PIN": "Digite novamente o PIN de 4 dígitos",
    "Set PIN": "Definir PIN",
    "Your account is now verified and you can continue your purchase of the bundle.": "Sua conta agora foi verificada e você pode continuar comprando o pacote.",
    "Continue purchase": "Continuar compra",
    "Your documents have been rejected from KYC provider.": "Seus documentos foram rejeitados pelo provedor KYC.",
    "Please try again": "Por favor, tente novamente",
    "Your KYC is approved": "Seu KYC foi aprovado",
    "Your KYC is rejected": "Seu KYC foi rejeitado",
    "File is required": "O arquivo é obrigatório",
    "Seamless Spending with GYMSTREET Cards": "Gastos perfeitos com cartões GYMSTREET",
    "Our cards open a world of smooth transactions and rewarding opportunities for you.": "Nossos cartões abrem um mundo de transações tranquilas e oportunidades gratificantes para você.",
    "Card Types Tailored to Your Needs": "Tipos de cartão adaptados às suas necessidades",
    "Card design": "Design de cartão",
    "Card information": "Informações do cartão",
    "Your go-to choice for daily transactions and affiliate commissions, offering practical benefits and reliable service within GYMSTREET and beyond.": "Sua escolha preferida para transações diárias e comissões de afiliados, oferecendo benefícios práticos e serviço confiável dentro do GYMSTREET e além.",
    "Elevate your financial experience with this premium option with expanded benefits, designed for those who seek luxury and exclusivity.": "Eleve sua experiência financeira com esta opção premium com benefícios ampliados, pensada para quem busca luxo e exclusividade.",
    "Cards come in Bundles": "Os cartões vêm em pacotes",
    "Explorer card and 2 parcels": "Cartão Explorer e 2 parcelas",
    "Mastery card and 3 parcels": "Cartão Mastery e 3 parcelas",
    "Getting Started with Your GYMSTREET Card Experience": "Primeiros passos com sua experiência com o cartão GYMSTREET",
    "Start securely with our simple KYC process, ensuring a safe and personalized Card experience.": "Comece com segurança com nosso processo simples de KYC, garantindo uma experiência de cartão segura e personalizada.",
    "Deposit Tokens": "Tokens de Depósito",
    "Stake the required GYMNET tokens - 500 for Explorer or 1500 for Mastery - to initiate your application.": "Aposte os tokens GYMNET necessários - 500 para Explorer ou 1500 para Mastery - para iniciar sua inscrição.",
    "Order Your Card": "Encomende o seu cartão",
    "Make your final decision on card type and place your order buying one of the 2 card bundles.": "Tome sua decisão final sobre o tipo de cartão e faça seu pedido comprando um dos 2 pacotes de cartões.",
    "Get Your Card": "Obtenha seu cartão",
    "Anticipate the arrival of your new financial companion, securely shipped and ready to integrate into your life.": "Antecipe a chegada do seu novo companheiro financeiro, enviado com segurança e pronto para ser integrado à sua vida.",
    "Use Your Card": "Use seu cartão",
    "Begin making purchases with your card and start earning affiliate commissions and more.": "Comece a fazer compras com seu cartão e comece a ganhar comissões de afiliados e muito mais.",
    "Card Operation Fees": "Taxas de operação de cartão",
    "Fee Type": "Tipo de taxa",
    "Both Cards (Explorer & Mastery)": "Ambas as cartas (Explorer e Mastery)",
    "POS Transactions": "Transações PDV",
    "ATM Withdrawals": "Saques em caixas eletrônicos",
    "Foreign Currency Conversion": "Conversão de moeda estrangeira",
    "Card Replacement": "Substituição de cartão",
    "Simple, Transparent Pricing": "Preços simples e transparentes",
    "Monthly Membership Fee": "Taxa mensal de adesão",
    "Monthly Membership": "Assinatura Mensal",
    "Get Your Card Now": "Obtenha seu cartão agora",
    "Choose the card that best suits your lifestyle. With straightforward fees, a seamless onboarding process, and an 11-level deep affiliate commission reward system, these cards are your partner in financial empowerment.": "Escolha o cartão que melhor se adapta ao seu estilo de vida. Com taxas simples, um processo de integração contínuo e um sistema de recompensa de comissões de afiliados de 11 níveis, esses cartões são seus parceiros na capacitação financeira.",
    "How do I get a card?": "Como faço para obter um cartão?",
    "Start by filling out the KYC form on our site. After we verify you, you'll need to deposit tokens for the card you want in Single Pool: 500 for Explorer or 1500 for Mastery. Then, you can order your card.": "Comece preenchendo o formulário KYC em nosso site. Depois de verificarmos você, você precisará depositar tokens para o cartão que deseja no Single Pool: 500 para Explorer ou 1500 para Mastery. Então você pode solicitar seu cartão.",
    "How many tokens do I need to deposit for each card?": "Quantos tokens preciso depositar para cada cartão?",
    "You need 500 tokens for the Explorer card and 1500 tokens for the Mastery card.": "Você precisa de 500 tokens para o cartão Explorer e 1.500 tokens para o cartão Mastery.",
    "When will my card arrive?": "Quando meu cartão chegará?",
    "After you order, your card will be sent out and should arrive quickly, so you can start using it soon.": "Após o pedido, seu cartão será enviado e deverá chegar rapidamente, para que você possa começar a utilizá-lo em breve.",
    "Can I earn affiliate commissions with my card?": "Posso ganhar comissões de afiliados com meu cartão?",
    "Yes, activate your card, and you can begin earning through our 11-level deep affiliate program. Check our website for how the commissions work.": "Sim, ative seu cartão e você poderá começar a ganhar por meio de nosso programa de afiliados profundo de 11 níveis. Confira em nosso site como funcionam as comissões.",
    "Total Value At Time of Deposit": "Valor total no momento do depósito",
    "Current Value": "Valor atual",
    "My claimed GYMNET rewards": "Minhas recompensas GYMNET reivindicadas",
    "My pending GYMNET rewards": "Minhas recompensas GYMNET pendentes",
    "Deposit": "Depósito",
    "Connected": "Conectado",
    "No deposit fee for burn": "Sem taxa de depósito para queimar",
    "Min amount": "Quantidade mínima",
    "Total GYMNET locked": "Total GYMNET bloqueado",
    "APR": "ABRIL",
    "Daily APR": "ABRIL diário",
    "Total own claimed": "Total próprio reclamado",
    "Reward per block": "Recompensa por bloco",
    "My Deposit": "Meu depósito",
    "Locked period until Dec 2027": "Período bloqueado até dezembro de 2027",
    "With Locking Period": "Com período de bloqueio",
    "Total Gymnet Deposit": "Depósito total da Gymnet",
    "Gymnet Rewards": "Recompensas Gymnet",
    "Total Gymnet earned": "Total Gymnet ganho",
    "Active holdings": "Participações ativas",
    "With locking period": "Com período de bloqueio",
    "Withdrawn holdings": "Participações retiradas",
    "Locked": "Bloqueado",
    "Unlocked": "Desbloqueado",
    "Deposit amount": "Valor do depósito",
    "Claimed rewards": "Recompensas reivindicadas",
    "Start date": "Data de início",
    "End date": "Data final",
    "Withdraw": "Retirar",
    "First name": "Primeiro nome",
    "Last name": "Sobrenome",
    "Address": "Endereço",
    "State": "Estado",
    "Phone number": "Número de telefone",
    "Birth Date": "Data de nascimento",
    "Submit & Continue": "Enviar e continuar",
    "ID Number": "Número de identidade",
    "ID Issued By": "ID emitido por",
    "Proof of address document": "Documento comprovante de endereço",
    "Proof of address document (optional)": "Documento comprovante de endereço (opcional)",
    "Statistics": "Estatisticas",
    "Personal Information": "Informações pessoais",
    "First name is required": "O primeiro nome é necessário",
    "Last name is required": "O sobrenome é obrigatório",
    "Address is required": "Endereço é necessário",
    "Please enter your email address": "Por favor, indique o seu endereço de e-mail",
    "Phone number is required": "O número de telefone é obrigatório",
    "Date of birth is required": "A data de nascimento é obrigatória",
    "ID Number is required": "O número de identificação é obrigatório",
    "ID Issued by is required": "ID emitido por é obrigatório",
    "Select asset to auto swap and stake": "Selecione o ativo para troca automática e aposta",
    "Giving swap allowance": "Dando subsídio de troca",
    "Swap allowance given": "Subsídio de troca concedido",
    "Swapping tokens to GYMNET": "Troca de tokens para GYMNET",
    "Tokens swapped to GYMNET": "Tokens trocados para GYMNET",
    "Giving stake allowance": "Dando subsídio de participação",
    "Stake allowance given": "Subsídio de participação concedido",
    "Staking to single pool": "Piquetagem em single pool",
    "Staked to single pool": "Apostado em single pool",
    "1. Swap allowance (Only for USDT)": "1. Subsídio de swap (somente para USDT)",
    "2. Swap to GYMNET": "2. Troque para GYMNET",
    "3. Stake allowance": "3. Subsídio de participação",
    "4. Stake": "4. Participação",
    "Actual balance:": "Saldo real:",
    "The transaction gas fee is reserved from the actual BNB balance.": "A taxa de gás da transação é reservada do saldo real do BNB.",
    "Insufficient balance to make a swap": "Saldo insuficiente para fazer uma troca",
    "Transactions gas fee is considered in BNB available balance": "A taxa de gás das transações é considerada no saldo disponível do BNB",
    "We will ask for allowance and try to swap your tokens to GYMNET, and after, stake them in single pool.": "Pediremos mesada e tentaremos trocar seus tokens para GYMNET, e após, apostá-los em single pool.",
    "Auto swap and stake": "Troca automática e participação",
    "Continue transactions": "Continuar transações",
    "Pause": "Pausa",
    "Swap & Stake": "Troca e aposta",
    "Topup": "Completar",
    "Proceed anyways": "Prossiga de qualquer maneira",
    "Explorer Card": "CartãoExplorer",
    "Mastery Card": "CartãoMastery",
    // CashFT ---
    "Monthly spending max": "Gasto mensal máximo",
    "Monthly Balance limit": "Limite de saldo mensal",
    "Go to Cards": "Ir para Cartões",
    "Contact Support": "Contate o suporte",
    "Deposit Fee": "Taxa de depósito",
    "Switch to 'Utility' to view the price for each product with USDT & Utility. To partially pay for a product using Utility, you need to have enough USDT and Utility in your balance.": "Mude para 'Utility' para ver o preço de cada produto com USDT e Utility. Para pagar parcialmente por um produto usando Utility, você precisa ter USDT e Utility suficientes em seu saldo.",
    "Deposit with locking period": "Depósito com período de bloqueio",
    "The state must be in the form of a two-letter abbreviation in uppercase.": "O estado deve estar na forma de uma abreviatura de duas letras maiúsculas.",
    "You need to upload a photo in JPEG or PNG format, not a PDF. The document can be in either English or Spanish.": "Você precisa enviar uma foto no formato <span style='color: #1A6C99'>jpeg</span> ou <span style='color: #1A6C99'>png</span>, não em pdf. \n O documento pode estar em <span style='color: #1A6C99'>inglês</span> ou em <span style='color: #1A6C99'>espanhol</span>.",
    "You need to upload a legible document in jpeg or png format, not a pdf. The document can be in either English or Spanish.": "Você precisa fazer upload de um documento legível no formato <span style='color: #1A6C99'>jpeg</span> ou <span style='color: #1A6C99'>png</span>, não em pdf. \n O documento pode estar em <span style='color: #1A6C99'>inglês</span> ou em <span style='color: #1A6C99'>espanhol</span>.",
    "Commissions are paid out using a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "As comissões são pagas na proporção de 70/30, sendo 70% em stablecoins e 30% em tokens GYMNET.",
    "This policy is effective as of September 25, 2023.": "Esta política entra em vigor em 25 de setembro de 2023.",
    "Required turnover": "Rotatividade necessária",
    "Switch to TOKEN to view the price for each product with USDT & TOKEN. To partially pay for a product using TOKEN, you need to have enough USDT and TOKEN in your balance.": "Mude para '{TOKEN}' para ver o preço de cada produto com USDT e {TOKEN}. Para pagar parcialmente por um produto usando {TOKEN}, você precisa ter USDT e {TOKEN} suficientes em seu saldo.",
    "Alternatively, you can use your CURRENCY balance to cover the gas fee.": "Alternativamente, você pode usar seu saldo de {currency_yg} para cobrir a taxa de gás.",
    "Insufficient funds: to continue with payment use Utility or Gymnet balance": "Fundos insuficientes: para continuar com o pagamento, use o saldo Utility ou Gymnet",
    "Use": "Usar",
    "Use Utility or Gymnet": "Use Utility ou Gymnet",
    "Achieved PRICE turnover": "Atingiu um faturamento de ${price}",
    "Your current milestone": "Seu marco atual",
    "Reward History": "Histórico de recompensas",
    "Leading Direct Partner": "Parceiro direto líder",
    "Remaining Direct Partners": "Restantes Parceiros Diretos",
    "Bonus Split": "Divisão de bônus",
    "Monthly Countdown": "Contagem regressiva mensal",
    "No direct partners": "Sem parceiros diretos",
    "To receive the reward, you must have an active product autoship.": "Para receber a recompensa, você deve ter um envio automático de produto ativo.",
    "This incentive is subject to the 50% Turnover Rule.": "Este incentivo está sujeito à regra do volume de negócios de 50%.",
    "Income From Product Authoship": "Receita de autoria de produtos",
    "Your documents have been submitted for verification. The bank may take up to 3 business days to review your application. Once approved, you can continue with your purchase.": "귀하의 문서가 확인을 위해 제출되었습니다. 은행에서 신청서를 검토하는 데 영업일 기준 최대 3일이 걸릴 수 있습니다. 승인되면 구매를 계속할 수 있습니다.",
    "To purchase the card, you need to agree to the T&C and complete the KYC verification process.": "Para adquirir o cartão, você precisa concordar com os T&C e concluir o processo de verificação KYC.",
    "Only Latin letters are allowed": "Somente letras latinas são permitidas",
    "First name must be at least 3 characters": "O primeiro nome deve ter pelo menos 3 caracteres",
    "First name should not exceed 10 characters": "O primeiro nome não deve exceder 10 caracteres",
    "Should be only letters": "Deveria ser apenas letras",
    "Address must be at least 3 characters": "O endereço deve ter pelo menos 3 caracteres",
    "Only latin letters, numbers and special characters are allowed": "Somente letras latinas, números e caracteres especiais são permitidos",
    "Email is not valid": "E-mail não é válido",
    "Zip must contain only numbers with min length of 3": "O zip deve conter apenas números com comprimento mínimo de 3",
    "The phone number must be at least 6 digits": "O número de telefone deve ter pelo menos 6 dígitos",
    "The phone number must not exceed 12 digits": "O número de telefone não deve exceder 12 dígitos",
    "Phone number should contain only numbers": "O número de telefone deve conter apenas números",
    "ID Number must have at least 3 characters": "O número de identificação deve ter pelo menos 3 caracteres",
    "ID Issued by must have at least 3 characters": "ID emitido por deve ter pelo menos 3 caracteres",
    "ID Issued by should not exceed 191 characters": "O ID emitido por não deve exceder 191 caracteres",
    "Last name must be at least 3 characters": "O sobrenome deve ter pelo menos 3 caracteres",
    "State is required": "O estado é obrigatório",
    "The incentive countdown starts at the beginning of each month and ends at the end of the month. If you do not qualify for the bonus within that month, you will need to start again in the next month.": "A contagem regressiva do incentivo começa no início de cada mês e termina no final do mês. Se você não se qualificar para o bônus naquele mês, precisará começar novamente no mês seguinte.",
    "Activated": "Ativada",
    "Deposit & fee commission": "Comissão de depósito e taxa",
    "Web3 wallet not connected. Please connect to proceed.": "Carteira Web3 não conectada. Conecte-se para continuar.",
    "Total investors profit": "Lucro total dos investidores",
    "Commissions from vault investors": "Comissões de investidores em cofres",
    "Please Claim your rewards from to activate this section.": "Reivindique suas recompensas de {n} para ativar esta seção.",
    "Or use Button Below": "Ou use o botão abaixo",
    "Partner Activity": "Atividade do parceiro",
    "Pending Commissions": "Comissões Pendentes",
    "Binary Turnover": "Rotatividade binária",
    "Unilevel Turnover": "Rotatividade Uninível",
    "Right Direct Partners": "Parceiros diretos certos",
    "Left Direct Partners": "Parceiros Diretos Esquerdos",
    "50% Compensation:": "Compensação de 50%:",
    "When you reach the next rank, you will receive 50% of the lost bonus from the previous rank.": "Ao atingir a próxima classificação, você receberá 50% do bônus perdido da classificação anterior.",
    "Please note that username can be changed only once in 6 months.": "Observe que o nome de usuário pode ser alterado apenas uma vez a cada 6 meses.",
    "Binary Turnover in weak leg": "Binary Turnover na perna fraca",
    "Unilevel turnover requirement": "Requisito de volume de negócios Unilevel",
    "Connect a WEB3 wallet such as Metamask or Trustwallet and pay with {currency}. This process requires a small BNB fee ($0.30) to handle transaction costs!": "Conecte uma carteira WEB3 como Metamask ou Trustwallet e pague com {currency}. Este processo requer uma pequena taxa do BNB (US$ 0,30) para lidar com os custos de transação!",
    "Use your account's wallet balance to purchase the product.": "Use o saldo da carteira da sua conta para comprar o produto.",
    "Seems like your crypto hasn't arrived yet": "Parece que sua criptografia ainda não chegou",
    "Wait more": "Espere mais",
    "Or topup with": "Ou recarregar com",
    "Connected to web3": "Conectado ao web3",
    "Reset": "Reiniciar",
    "Top Up Successful": "Recarga bem-sucedida",
    "Top Up Successful. Card balance will be updated soon": "Recarga bem-sucedida. O saldo do cartão será atualizado em breve",
    "Please activate your card and top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "Active o seu cartão e carregue o saldo para cobrir a taxa mensal. Se o saldo não for atualizado, o seu cartão será desativado e terá de adquirir um novo.",
    "PERCENTAGE allocation from Global Pool": "{perc}% de alocação do pool global",
    "You need to reach rank RANK_NAME to participate in this pool.": "Você precisa atingir a classificação \"{rank_name}\" para participar deste pool.",
    "Global Pool": "Grupo Global",
    "Next Distribution In:": "Próxima distribuição em:",
    "50% Rule": "Regra dos 50%",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%": "A quota máxima que cada parceiro direto pode contribuir para o volume de negócios exigido é de 50%",
    "Sub Pools": "Subgrupos",
    "Congratulations! You have qualified for a share of the Global Pool.": "Parabéns! Você se qualificou para uma parte do Global Pool.",
    "You will receive your share once the countdown is complete.": "Você receberá sua parte assim que a contagem regressiva terminar.",
    "Please top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "Por favor, carregue o saldo para cobrir a taxa mensal. Se o saldo não for atualizado, o seu cartão será desativado e terá de adquirir um novo.",
    "You need to deposit VALUE worth of GYMNET to upgrade your weekly binary cap": "Precisa de depositar o valor {dvalue} de GYMNET para atualizar o seu limite binário semanal",
    "Binary Max Weekly Payout": "Pagamento Máximo Semanal de Binárias",
    "Required Single Pool Deposit To Upgrade": "Depósito único necessário para atualizar",
    "Upgrade Binary Max Weekly Payout": "Atualização do Pagamento Máximo Semanal das Binárias",
    "Weekly Cap Upgraded": "Limite semanal atualizado",
    "Your 8 months period for earning token rewards in Filecoin has officially concluded. Thank you for your participation. Enjoy your FIL earnings.": "O seu período de 8 meses para ganhar recompensas em fichas no Filecoin terminou oficialmente. Obrigado pela vossa participação. Desfrute dos seus ganhos na FIL.",
    "As previously announced Filecoin will be replaced by Moonberg token, which will be distributed for a period of 24 months. The Moonberg launch is expected for August 2024 and the distribution will start one week after that.": "Como anunciado anteriormente, o Filecoin será substituído pelo token Moonberg, que será distribuído por um período de 24 meses. O lançamento do Moonberg está previsto para agosto de 2024 e a distribuição começará uma semana depois disso.",
    "Username must not exceed 255 characters": "O nome de utilizador não deve exceder 255 caracteres",
    "Upgrade": "Atualização",
    "Product Autoship Monthly": "Produto Autoship Mensal",
    "Product Autoship Yearly": "Produto Autoship Yearly",
    "Product Owner": "Proprietário do produto",
    "Myself": "Eu próprio",
    "Another user": "Outro utilizador",
    "Payment": "Pagamento",
    "PAP Balance": "Equilíbrio PAP",
    "Purchased By": "Comprado por",
    "Purchased For": "Comprado para",
    "Single parcel": "Parcela única",
    "Single miner": "Ministro único",
    "completed": "Concluída",
    "pending": "Pendente",
    "expired": "Vencido",

    "Select {n} blockchain to send the transaction": "Selecione {n} blockchain para enviar a transação",
    "We recommend waiting to claim your rewards when the gas fee is lower than the amount you are claiming.": "Recomendamos que espere para resgatar as suas recompensas quando a taxa do gás for inferior ao valor que está a reclamar.",
    "Total Value Of Purchases": "Valor total das compras",
    "The total value of all purchases excluding purchases related to Card Bundles, granted products and product autoship subscriptions.": "O valor total de todas as compras, excluindo compras relacionadas com pacotes de cartões, produtos concedidos e subscrições de envio automático de produtos.",
    "Total Receivable Tokens": "Total de tokens a receber",
    "The number of Moonberg tokens you will receive over the next 24 months.": "O número de tokens Moonberg que receberá nos próximos 24 meses.",
    "Realtime ROI": "ROI em tempo real",
    "Current Return on Investment (ROI) based on the real-time price of the Moonberg token.": "Retorno do investimento (ROI) atual com base no preço em tempo real do token Moonberg.",
    "Token Calculation Breakdown": "Detalhamento do cálculo do token",
    "{n}% of your total purchase value is allocated for purchasing {asset} tokens": "{n}% do valor total da sua compra é alocado à compra de tokens de {asset}",
    "Moonberg tokens will be distributed upon the official release of the Moonberg token.": "Os tokens de Moonberg serão distribuídos após o lançamento oficial do token de Moonberg.",
    "Moonberg Allocation": "Alocação de Moonberg",
    "BTC Balance": "Saldo BTC",
    "The funds will be transferred via the BEP-20 BNB network.": "Os fundos serão transferidos através da rede BEP-20 BNB.",
    "A bundle with minting power will generate BTC token rewards, which will be distributed every Monday.": "Um pacote com energia de mineração irá gerar recompensas de tokens BTC, que serão distribuídas todas as segundas-feiras.",
    "Weekly rewards expected": "Recompensas semanais esperadas",
    "Direct Sales Incentive": "Incentivo de vendas diretas",
    "You are about to activate your affiliate referral link.": "Está prestes a ativar o seu link de recomendação de afiliados.",
    "Upon activation, a period of free Product Autoship will be initiated starting from the date of your registration.": "Após a ativação, será iniciado um período de Envio Automático de Produto gratuito a partir da data do seu registo.",
    "If you confirm the activation, a mandatory monthly Product Autoship will be required to be eligible to receive commissions.": "Se confirmar a ativação, será necessário um envio automático mensal obrigatório do produto para ter direito a receber comissões.",
    "Keep your product autoship active to maintain your commission eligibility for commission ranks Platinum and above.": "Mantenha o envio automático do seu produto ativo para manter a sua elegibilidade de comissões para classificações de comissões Platinum e superiores.",
    "Buy Product Autoship": "Comprar envio automático de produto",
    "Your current rank is achieved through qualification.": "A sua classificação atual é alcançada por meio de qualificação.",
    "Your commission rank is determined by your actual rank or a super bundle purchase—whichever is higher will be considered.": "A sua classificação de comissão é determinada pela sua classificação real ou pela compra de um super pacote – o que for mais elevado será considerado.",
    "Your card has been deactivated due to an unpaid monthly subscription. For assistance or any questions, please reach out to our customer support team.": "O seu cartão {card_name} foi desativado devido a uma subscrição mensal não paga. Para obter assistência ou qualquer dúvida, contacte a nossa equipa de apoio ao cliente.",

    "Subject": "Assunto",
    "topup": "Recarregar",
    "withdraw": "Retirar",
    "failed": "Falhou",
    "3rd Anniversary": "3º aniversário",
    "3rd Anniversary Promo": "Promoção do 3º aniversário",
    "Your current bonus amount": "O seu valor de bónus atual",
    "All products are eligible for the incentive, except for Product Autoship. Rewards will be distributed on November 1, 2024, and the bonus will be deposited directly into your internal wallet.": "Todos os produtos são elegíveis para o incentivo, exceto o Autoenvio de Produtos. As recompensas serão distribuídas a 1 de novembro de 2024, e o bónus será depositado diretamente na sua carteira interna.",























    // Affility translations
    "close": "Perto",
    "cancel": "Cancelar",
    "yes_i_confirm": "Sim, confirmo",
    "something_went_wrong_try_again": "Algo correu mal. Por favor tente novamente",
    "or": "Ou",
    "success": "Sucesso",
    "ok": "OK",
    "retry": "Tentar novamente",
    "home": "Casa",
    "log_in": "Conecte-se",
    "join_now": "Entrar",
    "toggle_notifications": "Alternar notificações",
    "active": "Ativo",
    "inactive": "Inativo",
    "product_autoship": "Envio automático de produto",
    "balances": "Saldos",
    "contract_addresses": "Endereços de contrato",
    "address_add_to_wallet_success": "O endereço foi adicionado com sucesso à sua carteira",
    "language": "Idioma",
    "choose_language": "Escolha o seu idioma",
    "profile": "Perfil",
    "settings": "Configurações",
    "learn": "Aprender",
    "help_center": "Centro de ajuda",
    "log_out": "Sair",
    "theme": "Tema",
    "back": "Costas",
    "forward": "Encaminhar",
    "previous": "Anterior",
    "next": "Seguinte",
    "jump_to_top": "Ir para o topo",
    "load_more": "Carregue mais",
    "proceed": "Continuar",
    "notifications": "Notificações",
    "notifications_loading_error_description": "Certifique-se de que o seu dispositivo está ligado à Internet ou tente reiniciar o router ou o modem.",
    "notifications_empty_title": "A lista de notificações está vazia",
    "notifications_empty_description": "Acompanhe todas as suas atualizações e lembretes num local conveniente com a nossa funcionalidade de notificações.",
    "email_verification_sent_title": "verificação de e-mail",
    "email_verification_sent_description": "Enviámos um e-mail para {email} com um link para finalizar o seu registo",
    "email_verification_required": "A verificação de e-mail é obrigatória",
    "email_verification_confirm": "Por favor confirme o seu e-mail para continuar",
    "email_verification_verify": "Verifique o seu e-mail para continuar",
    "new_email": "Novo Email",
    "enter_new_email": "Introduza novo e-mail",
    "change_email": "Mude o e-mail",
    "resend_email": "Reenviar email",
    "upgrade_to_affiliate": "Atualizar para afiliado",
    "upgrade_to_affiliate_success": "Atualizou com sucesso para Afiliado",
    "become_affiliate": "Torne-se Afiliado",
    "upgrade_to_affiliate_description_free_autoship": "Está prestes a ativar o seu link de recomendação de afiliados. Após a ativação, será iniciado um período de Envio Automático de Produto gratuito a partir da data do seu registo.",
    "upgrade_to_affiliate_description_mandatory_autoship": "Está prestes a ativar o seu link de recomendação de afiliados. Se confirmar a ativação, será necessário um envio automático mensal obrigatório do produto para ter direito a receber comissões.",
    "t&c": "Termos e Condições",
    "accept_t&c": "Aceitar e fechar",
    "affiliate_t&c": "Termos e Condições do Afiliado",
    "cards_t&c": "Termos e Condições dos Cartões",
    "i_have_read_and_accept_the_{prop}": "Eu li e aceito o {prop}",
    "switch_language": "Mudar idioma",
    "switch_theme": "Mudar tema",
    "enjoy_more_benefit_more": "Aproveite mais! Beneficie mais!",
    "buy_products_at_least_${n}": "Compre produtos por, pelo menos, ${n}",
    "go_to_products": "Ir para produtos",
    "free_autoship": "Envio automático gratuito de produtos para Platinum e superiores",
    "{n}%_binary_commission": "{n}% Comissão Binária",
    "rank": "Classificação",
    "role": "Papel",
    "bundle": "Fardo",
    "current_rank": "Rank atual",
    "current_role": "Função atual",
    "customer": "Cliente",
    "get_your_bundle": "Obtenha o seu pacote",
    "nft_turbo_expires_in": "O desconto especial do super pacote expira em",
    "left_leg_link": "Elo da perna esquerda",
    "right_leg_link": "Ligação da perna direita",
    "sponsor_id": "ID do Patrocinador",
    "total_income": "Renda total",
    "current_cv_balance": "Saldo CV atual",
    "progress_until_rank_{n}": "Progresso até à classificação {n}",
    "to_reach_rank_{n}_you_need": "Para alcançar a classificação {n} precisa",
    "nft_own_purchases": "Compras próprias NFT",
    "direct_partner_own_purchases": "Compras próprias do parceiro direto",
    "team_turnover": "Rotatividade da equipa",
    "required_qualified_partners": "Parceiros qualificados necessários",
    "see_details": "Ver detalhes",
    "soon": "Em breve",
    "new": "Novo",
    "promos": "Promoções",
    "coming_soon": "Em breve",
    "team_statistics": "Estatísticas da equipa",
    "all_time": "Tempo todo",
    "weekly": "Semanalmente",
    "monthly": "Por mês",
    "yearly": "Anual",
    "left_leg": "Perna esquerda",
    "right_leg": "Perna direita",
    "all_partners": "Todos os parceiros",
    "direct_partners": "Parceiros Diretos",
    "all_active_partners": "Todos os parceiros ativos",
    "all_inactive_partners": "Todos os parceiros inativos",
    "become_affiliate_partner": "Torne-se um parceiro afiliado",
    "gain_fresh_perspectives": "Obtenha novas perspetivas e benefícios exclusivos.",
    "autoship_required_to_be_affiliate": "O envio automático mensal de produtos é necessário para ser um parceiro afiliado e ganhar comissões.",
    "for_limited_time_only": "Apenas por tempo limitado",
    "placement_in_unilevel": "Colocação em Unilevel",
    "placement_in_binary": "Colocação em binário",
    "cv_points": "Pontos CV",
    "matching_bonuses": "Bónus correspondentes",
    "leadership_incentives": "Incentivos de liderança",
    "autoship_incentive": "Incentivo ao envio automático de produtos",
    "binary_tree_statistics": "Estatísticas de árvores binárias",
    "unilevel_tree_statistics": "Estatísticas de Árvores Unilevel",
    "active_partners": "Parceiros activos",
    "inactive_partners": "Parceiros inactivos",
    "The minimum amount should be equal to or greater than NUM GYMNET": "O valor mínimo deve ser igual ou superior a {num} GYMNET",
    "select_the_team": "Selecione a equipa",
    "new_users_will_be_placed_under_username_in_the_left_team": "Os novos utilizadores serão colocados como {USERNAME} na equipa {LEG}.",
    "done": "Concluído",
    "right": "certo",
    "left": "esquerda",
};
