export default {
    "Full": "가득한",
    "Standard": "기본",
    "Attach miners": "채굴기배치",
    "Upgraded Parcel": "업그레이드된 소포",
    "Discount": "할인",
    "save": "저장",
    "Copied": "복사됨",
    "Contract Address": "컨트랙트주소",
    "Wallet Address": "지갑 주소",
    "Inland": "내륙",
    "Business": "사업",
    "parcels": "소포",
    "parcel": "소포",
    "Ocean": "대양",
    "Near Ocean": "바다 근처",
    "Upgraded": "업그레이드됨",
    "Learn how it works": "작동 방식 알아보기",
    "Buy parcel": "소포 구매",
    "Wallets": "지갑",
    "To GymNetwork": "짐네트워크로",
    "Connect Wallet": "지갑 연결",
    "Pending rewards": "보류 중인 보상",
    "Coming Soon": "출시 예정",
    "Claim": "주장하다",
    "Inventory": "목록",
    "Lands": "토지",
    "Miners": "광부",
    "Attach": "붙이다",
    "Attach to parcel": "소포에 부착",
    "Investment": "투자",
    "Wallet": "지갑",
    "Your address": "주소",
    "History": "역사",
    "Map": "지도",
    "Buy miner": "광부 구매",
    "Buy Miner": "광부 구매",
    "Get ": "얻다",
    "Get Miner": "광부 얻기",
    "Upgrade parcel": "업그레이드 소포",
    "Soon": "곧",
    "Upgraded land has 10 slots for putting miner NFTs. Upgraded land does not have more physical space, it’s only that you can stake more miners.": "업그레이드된 땅에는 광부 NFT를 넣을 수 있는 슬롯이 10개 있습니다. 업그레이드된 토지에는 더 많은 물리적 공간이 없으며 더 많은 광부를 스테이킹할 수 있을 뿐입니다.",
    "Next": "다음",
    "Back": "뒤",
    "Skip guide": "가이드 건너뛰기",
    "Number of miners you want to buy": "구매하려는 듀얼 광부 수",
    "Attach Miner": "광부 연결",
    "Upgrade for": "업그레이드",
    "On Sale": "판매 중",
    "Type": "유형",
    "Location": "위치",
    "Size": "크기",
    "Buy now": "지금 구매",
    "Email": "이메일",
    "Username": "사용자 이름",
    "Verify Email": "이메일 확인",
    "Index": "색인",
    "Verification Code": "확인 코드",
    "Log out": "로그 아웃",
    "Select all": "모두 선택",
    "Cart": "카트",
    "Total": "총",
    "Register": "등록하다",
    "Dashboard": "계기반",
    "Transaction success!": "거래 성공!",
    "I`ve read Terms & Conditions": "이용약관을 읽었습니다",
    "Terms & conditions": "이용약관",
    "Affiliate Terms & Conditions": "제휴 이용약관",
    "Accept Terms & Conditions": "이용약관 동의",
    "Loading! Please Wait ...": "로딩 중! 기다리세요 ...",
    "Initializing Wallet! Please Wait ...": "지갑 초기화 중! 기다리세요 ...",
    "Login In Progress! Please Wait ...": "로그인 진행 중! 기다리세요 ...",
    "Enter your email": "이메일을 입력하세요",
    "Confirm your Email": "이메일 확인",
    "Enter username": "사용자 이름 입력",
    "To dashboard": "대시보드로",
    "You successfully verified your email": "이메일을 성공적으로 확인했습니다.",
    "View map": "지도보기",
    "Log in to buy": "구매하려면 로그인하십시오",
    "Congratulations": "축하합니다",
    "Buy": "구입하다",
    "Miner": "갱부",
    "Parcel": "소포",
    "Upgrade": "업그레이드",
    "Load more": "더 로드",
    "All": "모두",
    "Basic": "기초적인",
    "Activate": "활성화",
    "Basic Miner": "기본 광부",
    "In use": "사용",
    "Expired": "만료됨",
    "Available": "사용 가능",
    "Standard parcel": "표준 소포",
    "Success": "성공",
    "Something Went Wrong": "문제가 발생했습니다.",
    "Loading": "로딩 중",
    "Invalid Verification Code": "유효하지 않은 인증 코드",
    "Invalid Email Address": "잘못된 이메일 주소",
    "Invalid Arguments Supplied": "잘못된 인수가 제공됨",
    "Email Already Exists": "이메일이 이미 존재합니다",
    "UnAuthorized": "승인되지 않음",
    "User Update Failed": "사용자 업데이트 실패",
    "Permission Denied": "권한 거부됨",
    "Basic miner": "기본 광부",
    "Remove": "제거하다",
    "Special offers": "특별 제공",
    "Please Wait": "기다리세요",
    "Transaction Rejected": "거래 거부됨",
    "sold": "판매된",
    "The parcel price will be increased after certain percentage of parcels will be sold. Bundle prices will be increased accordingly.": "일정 비율의 소포가 판매되면 소포 가격이 인상됩니다. 이에 따라 번들 가격이 인상됩니다.",
    "Sold": "판매된",
    "Parcel price index": "소포 가격 지수",
    "Current parcel price": "현재 소포 가격",
    "Parcels sold": "소포 판매",
    "Parcel stats": "소포 통계",
    "Help center": "지원 센터",
    "Partner area": "파트너 영역",
    "to_dashboard": "대시보드로",
    "Transaction Success": "거래 성공",
    "parcels available": "소포 사용 가능",
    "Apply": "적용하다",
    "Insufficient USDT amount": "USDT 금액 부족",
    "Insufficient {asset} amount": "{asset} 금액 부족",
    "Miner Rewards": "광부 보상",
    "No data found": "데이터가 없습니다",
    "Marketplace": "시장",
    "Parcel name": "소포 이름",
    "Basic Bundle": "기본 번들",
    "4 Parcels Bundle": "4 소포 번들",
    "10 Parcels Bundle": "10 소포 번들",
    "40 Parcels Bundle": "40 소포 번들",
    "80 Parcels Bundle": "80 소포 번들",
    "140 Parcels Bundle": "140 소포 번들",
    "240 Parcels Bundle": "240 소포 번들",
    "4 Basic Miner Bundle": "4 광부 번들",
    "10 Basic Miner Bundle": "10 광부 번들",
    "40 Basic Miner Bundle": "40 광부 번들",
    "80 Basic Miner Bundle": "80 광부 번들",
    "140 Basic Miner Bundle": "140 광부 번들",
    "240 Basic Miner Bundle": "240 광부 번들",
    "Hashpower": "Hashpower",
    "Something went wrong, please refresh the page and try again": "문제가 발생했습니다. 페이지를 새로 고치고 다시 시도하십시오.",
    "Gallery": "갤러리",
    "Accepted": "수락됨",
    "Pending": "보류 중",
    "Upload": "업로드",
    "Uploading image": "이미지 업로드 중",
    "Something went wrong, try again later": "문제가 발생했습니다. 나중에 다시 시도하십시오.",
    "You need to be authenticated to upload images to Gallery.": "갤러리에 이미지를 업로드하려면 인증해야 합니다.",
    "Assign image": "이미지 할당",
    "Assign image to parcels": "구획에 이미지 지정",
    "Remove current image": "현재 이미지를 제거하십시오",
    "Change image": "이미지 변경",
    "Assign to parcels": "소포에 할당하십시오",
    "Reselect image assignment area?": "이미지 할당 영역을 다시 선택 하시겠습니까?",
    "You have not applied changes. Do you want to reselect image assignment area?": "변경 사항을 적용하지 않았습니다.이미지 할당 영역을 다시 선택 하시겠습니까?",
    "Image has been successfully changed": "이미지가 성공적으로 변경되었습니다",
    "Image has been successfully assigned to parcels": "이미지는 소포에 성공적으로 할당되었습니다",
    "Image has been successfully removed from the parcels": "이미지는 성공적으로 소포에서 제거되었습니다",
    "Yes": "예",
    "No": "아니",
    "Cancel": "취소",
    "You have no images": "이미지가 없습니다",
    "Notifications": "알림",
    "No notifications": "알림 없음",
    "year ago": "년 전",
    "months ago": "몇달 전",
    "month ago": "한 달 전",
    "weeks ago": "몇주 전에",
    "week ago": "주 전",
    "days ago": "며칠 전에",
    "day ago": "하루 전",
    "hours ago": "몇 시간 전",
    "hour ago": "시간 전",
    "minutes ago": "몇분 전에",
    "minutes": "분",
    "minute": "분",
    "seconds": "초",
    "second": "초",
    "Your image was approved": "이미지가 승인되었습니다",
    "Your image was rejected": "이미지가 거부되었습니다.",
    "New": "새로운",
    "Gallery guide" : "갤러리 가이드",
    "Go to gallery": "갤러리로 이동하십시오",
    "How it works": "작동 방식",
    "Removing image from parcels..." : "소포에서 이미지 제거 ...",
    "Removing image" : "이미지 제거",
    "Image has been successfully removed" : "이미지가 성공적으로 제거되었습니다",
    "Wrong file size" : "잘못된 파일 크기",
    "Please upload image files with size less than 10MB": "크기가 10MB 미만인 이미지 파일을 업로드하십시오",
    "Drag to Reposition": "재배치로 드래그하십시오",
    "Pinch or use the mouse wheel to zoom in or out": "마우스 휠을 꼬집거나 사용하여 확대 또는 축소하십시오.",
    "Drag and move the image with fingers or using your mouse to select area": "손가락으로 이미지를 끌고 이동하거나 마우스를 사용하여 영역을 선택하십시오.",
    "It can take up to 48 hours for the image to be approved. You can view your images awaiting approval in the Pending tab.": "이미지를 승인하는 데 최대 48 시간이 걸릴 수 있습니다.보류중인 탭에서 승인을 기다리는 이미지를 볼 수 있습니다.",
    "Delete Image": "이미지 삭제",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable. Land containing this image will also be cleared.": "갤러리에서 이미지를 영구적으로 삭제 하시겠습니까?이 작업은 복구 할 수 없습니다.이 이미지를 포함하는 토지도 지워집니다.",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable." : "갤러리에서 이미지를 영구적으로 삭제 하시겠습니까?이 작업은 복구 할 수 없습니다.",
    "Wrong File Type": "잘못된 파일 유형",
    "Please upload jpg/jpeg image files": "JPG/JPEG 이미지 파일을 업로드하십시오",
    "Upload Images to Your Gallery": "갤러리에 이미지를 업로드하십시오",
    "You can upload images to your gallery from your computer, mobile phone or tablet. Currently only jpg/jpeg image file types are supported. Press the upload button or simply drag and drop the image from computer to upload your image. Image size must be up to 10 MB." : "컴퓨터, 휴대폰 또는 태블릿에서 갤러리에 이미지를 업로드 할 수 있습니다.현재 JPG/JPEG 이미지 파일 유형 만 지원됩니다.업로드 버튼을 누르거나 컴퓨터에서 이미지를 드래그하여 떨어 뜨려 이미지를 업로드하십시오.이미지 크기는 최대 10MB이어야합니다.",
    "Upload image": "이미지 업로드",
    "Image Approval": "이미지 승인",
    "Uploaded images need to be approved before they appear in the approved section of your gallery. This procedure is necessary to filter out inappropriate, explicit content and copyrighted material. Approval can take up to 48 hours. You'll get notified about the verdict whenever you're online." : "업로드 된 이미지는 갤러리의 승인 된 섹션에 표시되기 전에 승인해야합니다.이 절차는 부적절하고 명시적인 콘텐츠 및 저작권이있는 자료를 필터링하는 데 필요합니다.승인은 최대 48 시간이 소요될 수 있습니다.온라인 상태가있을 때마다 판결에 대해 알림을받을 수 있습니다.",
    "Approval": "승인",
    "Select Your Land to Assign Image to": "이미지를 할당하려면 토지를 선택하십시오",
    "You can assign your uploaded images to your owned land. To do so, simply select the parcels by dragging mouse on the land part you want to assign an image to. Please note that image can only be assigned to a rectangular area that is owned by you only." : "업로드 된 이미지를 자신의 토지에 할당 할 수 있습니다.이렇게하려면 이미지를 할당하려는 육상 부품에서 마우스를 드래그하여 소포를 선택하십시오.이미지는 귀하가 소유 한 직사각형 영역에만 할당 할 수 있습니다.",
    "Land Selection": "토지 선택",
    "Crop Image": "이미지 자르기",
    "You can crop a custom part of your image that you want to assign to your land section. You can close in to any area of your image and crop it by dragging the image with mouse or your finger if you're on a mobile device. You can also zoom in and out by using mouse wheel or pinch gesture." : "토지 섹션에 할당하려는 이미지의 사용자 정의 부분을자를 수 있습니다.모바일 장치에있는 경우 이미지의 모든 영역에 닫고 마우스 나 손가락으로 이미지를 끌어 자르고 자르십시오.마우스 휠 또는 핀치 제스처를 사용하여 확대 및 축소 할 수도 있습니다.",
    "Activate multiple selection": "다중 선택을 활성화합니다",
    "Exit multiple selection": "여러 선택을 종료하십시오",
    "Owned": "소유",
    "Owned by": "에게 소유 된",
    "Multiple types": "다중 유형",
    "seconds ago": "초 전",
    "Super Bundle": "슈퍼 번들",
    "Balance": "균형",
    "View your purchased Bundles and claim to have them from your balance": "구매한 번들을 보고 잔액에서 가져오기를 요청하세요.",
    "Feel free to choose lands on Map.": "지도에서 토지를 자유롭게 선택하십시오.",
    "Earn money by using miners for generating rewards.": "보상을 생성하기 위해 광부를 사용하여 돈을 벌 수 있습니다.",
    "Upgrade your owned lands from 4 slots to 10.": "소유한 토지를 4칸에서 10칸으로 업그레이드하십시오.",
    "Confirm": "확인하다",
    "You don`t have any available parcels in your inventory": "귀하의 인벤토리에 사용 가능한 소포가 없습니다",
    "Months": "개월",
    "Super Bundle R6": "슈퍼 번들 R6",
    "Super Bundle R10": "슈퍼 번들 R10",
    "Super Bundle R15": "슈퍼 번들 R15",
    "Super Bundle R20": "슈퍼 번들 R20",
    "Super Bundle R22 S": "슈퍼 번들 R22 S",
    "Super Bundle R22 M": "슈퍼 번들 R22 M",
    "Super Bundle R22 L": "슈퍼 번들 R22 L",
    "Super Bundle R22 XL": "슈퍼 번들 R22 XL",
    "Super Bundle R22 XXL": "슈퍼 번들 R22 XXL",
    "1 Claim your land": "1 당신의 땅을 주장하십시오",
    "2 Claim your miner": "2 광부를 요구하십시오",
    "3 Upgrade your land": "3 토지 업그레이드",
    "Your NFT balance": "NFT 잔액",
    "Next price changes in": "다음 가격 변경:",
    "Parcels": "소포",
    "Get to know the platform usage": "플랫폼 사용 알아보기",
    "What is a Parcel?": "소포란 무엇입니까?",
    "Land is a unit of area on the GYM Street map and it is also an NFT. You can own as much land on GYM Street as you want.": "Land는 GYM Street 맵의 영역 단위이며 NFT이기도 합니다. GYM Street에서 원하는 만큼의 토지를 소유할 수 있습니다.",
    "What is a Miner?": "광부란 무엇입니까?",
    "A miner is what generates income for you at GYM Street. You can attach up to 4 miners on each of your standard land parcels.": "광부는 GYM Street에서 수입을 창출하는 것입니다. 각 표준 토지 구획에 최대 4개의 채굴기를 부착할 수 있습니다.",
    "Miner rewards": "광부 보상",
    "My Miners": "내 광부들",
    "My Hashpower": "내 Hashpower",
    "Total Claimed": "청구된 총계",
    "Total pending rewards": "보류 중인 총 보상",
    "Claim all": "모두 청구",
    "Daily rewards": "일일 보상",
    "Weekly rewards": "주간 보상",
    "Monthly rewards": "월간 보상",
    "Yearly rewards": "연간 보상",
    "ROI": "ROI",
    "ROI Calculator": "ROI 계산기",
    "Global Statistics": "글로벌 통계",
    "Global Hashpower": "글로벌 Hashpower",
    "Your Shares": "당신의 주식",
    "Daily Global Rewards": "일일 글로벌 보상",
    "Price": "가격",
    "Total Minted": "총 주조",
    "total": "총",
    "standard": "기준",
    "upgraded": "업그레이드",
    "active": "활동적인",
    "inactive": "비활성",
    "Attach miner": "광부 부착",
    "Total Miners": "총 광부",
    "Total Minted Rewards": "발행된 총 보상",
    "What is parcel and how to use it?": "소포는 주조 기능을 갖춘 {metaverse} 지도의 지역 단위입니다. 소포를 소유하고 수입을 창출하세요.",
    "What is a miner and how to use it?": "광부는 {metaverse}에서 귀하의 수입 창출원입니다. 소포에 광부를 부착하여 수입을 늘리십시오. <br/> <br/> GYMNET Miner와 Dual Miner는 모두 1,000 Mh/s의 채굴 속도로 작동합니다.",
    "Your active/inactive hashpower, 1 miner = 1,000 Mh/s, your Miners 0 x 1,000Mh/s = 0.00": "활성/비활성 hashpower, 1 광부 = 1,000 Mh/s, 광부 0 x 1,000Mh/s = 0.00",
    "The amount of the cumulated claimed rewards": "누적 청구 보상 금액",
    "Your current available pending rewards.": "현재 사용 가능한 보류 보상.",
    "Congratulations!": "축하합니다!",
    "Upgrade your parcels to mine even more?": "더 많은 것을 채굴하기 위해 소포를 업그레이드 하시겠습니까?",
    "You have NUM parcels that are not upgraded.": "업그레이드되지 않은\n 소포가 {parcels}개 있습니다.",
    "Go to Map": "지도로 이동",
    "You're mining less than the average user.": "일반 사용자보다 적게 채굴하고 있습니다.",
    "Purchase more miners to improve your results": "더 많은 광부를 구매하여 결과를 개선하세요",
    "Buy more miners": "더 많은 광부 구입",
    "Thanks, I'll stay below average": "감사합니다. 평균 이하로 유지하겠습니다.",
    "You aren't mining at full speed.": "당신은 전속력으로 채굴하고 있지 않습니다.",
    "Do you want to buy more parcels now ?": "지금 소포를 더 사고 싶습니까?",
    "I definitely do": "나는 확실히",
    "No, I'll let my miners to rust": "아니, 내 광부를 녹슬게 두겠어",
    "You have no miners": "당신은 광부가 없습니다",
    "Buy miners": "듀얼 광부 구입",
    "You haven't upgradeable parcels": "업그레이드 가능한 소포가 없습니다.",
    "You haven't inactive miners": "비활성 광부가 없습니다.",
    "Close": "닫다",
    "Read more": "더 읽어보기",
    "Drag and drop an image, or Browse": "이미지를 드래그 앤 드롭하거나 <label class=\"browse-button\" for=\"upload-image\">찾아보기</label>",
    "Browse an image": "<label class=\"browse-button\" for=\"upload-image\">찾아보기</label> 이미지",
    "Drop to upload": "업로드하려면 떨어 뜨립니다",
    "File not supported": "지원되지 않습니다",
    "Upload your parcel image": "소포 이미지를 업로드하십시오",
    "Up to 10MB, jpg/jpeg": "최대 10MB, JPG/JPEG",
    "High resolution images, should align with your land shape (you can crop after upload)": "<span class=\"bold-info\">고해상도 이미지</span>는 토지 모양과 일치해야 합니다(업로드 후 자를 수 있음).",
    "Same image may be used on different lands owned by the user": "사용자가 소유한 다른 토지에서 <span class=\"bold-info\">동일한 이미지를 사용할 수 있습니다</span>.",
    "No violence, offensive, graphic or 18+ content": "폭력, 모욕적, 노골적 또는 <span class=\"bold-info\">18세 이상의 콘텐츠</span> 금지",
    "No third-party copyrighted content, no logos or screenshots from Gymstreet and Gymnetwork": "<span class=\"bold-info\">제3자 저작권 콘텐츠 없음</span>, <span class=\"bold-info\">Gymstreet 및 Gymnetwork</span>의 로고 또는 스크린샷 없음",
    "No images already used by another user, no images from DeFi listing website images": "다른 사용자가 <span class=\"bold-info\">이미 사용한 이미지 없음</span>, DeFi 목록 웹사이트 이미지의 이미지 없음",
    "No religious images": "<span class=\"bold-info\">종교 이미지 없음</span>",
    "Unlock miners by purchasing at least 1 land parcel": "최소 1개의 토지를 구매하여 광부 잠금 해제",
    "OK": "확인",
    "Your parcels": "당신의 소포",
    "Your miners": "광부",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcels to start mining": "당신은 최고 속도로 채굴하고 있지 않습니다. 채굴을 시작하기 위해 소포에 부착해야 하는 {miners} 광부가 있습니다.",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcel to start mining": "당신은 최고 속도로 채굴하고 있지 않습니다. 채굴을 시작하기 위해 소포에 부착해야 하는 {miners} 광부가 있습니다.",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcels to start mining": "당신은 최고 속도로 채굴하고 있지 않습니다. 채굴을 시작하기 위해 소포에 부착해야 하는 {miners} 광부가 있습니다.",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcel to start mining": "당신은 최고 속도로 채굴하고 있지 않습니다. 채굴을 시작하기 위해 소포에 부착해야 하는 {miners} 광부가 있습니다.",
    "Info Center": "정보 센터",
    "Standard Parcel": "표준 소포",
    "You have no parcels": "당신은 소포가 없습니다",
    "Single Products": "단일 제품",
    "Parcels sold / Total parcels": "소포 판매 / 총 소포",
    "You can mine even more. Purchase more miners to improve your results": "더 많이 채굴할 수 있습니다. 더 많은 광부를 구매하여 결과를 개선하세요",
    "In Progress": "진행 중",
    "Active": "활동적인",
    "Learn More": "더 알아보기",
    "Add to wallet": "지갑에 추가하십시오",
    "Your Balance": "당신의 균형",
    "The Promotion starts in:": "프로모션 시작:",
    "Please go to your wallet, which you just connected, and verify your login by confirming the requested transaction. It's free of charge!": "방금 연결한 지갑으로 이동하여 요청한 거래를 확인하여 로그인을 확인하십시오. 그것은 무료입니다!",
    "Contract addresses": "계약 주소",
    "Add TOKEN to wallet": "지갑에 {token} 추가",
    "I accept the terms and conditions": "나는 이용 약관에 동의합니다",
    "Log In": "로그인",
    "Sign Up": "가입하기",
    "Connect My Wallet": "내 지갑을 연결하십시오",
    "Change Email": "이메일을 바꾸다",
    "Reset Password": "암호를 재설정",
    "Your password has been changed.": "당신의 비밀번호가 변경되었습니다.",
    "Please log in to your account.": "계정에 로그인하십시오.",
    "Confirm Email": "이메일 확인",
    "Emails don't match": "이메일이 일치하지 않습니다",
    "Or": "또는",
    "Sign Up With Email": "이메일로 가입하십시오",
    "Already have an account?": "이미 계정이 있습니까?",
    "Recover": "다시 덮다",
    "Enter New Password": "새로운 암호를 입력하세요",
    "Confirm New Password": "새 암호를 확인합니다",
    "Password": "비밀번호",
    "Forgot password?": "비밀번호를 잊으 셨나요?",
    "Password is required": "비밀번호가 필요합니다",
    "Password confirmation is required": "비밀번호 확인이 필요합니다",
    "Passwords do not match": "비밀번호가 일치하지 않습니다",
    "Password must contain at least one uppercase letter, one lowercase letter and one number": "비밀번호는 최소 1 개의 대문자, 1 개의 소문자 및 1 개의 숫자에 하나가 있어야합니다.",
    "Password must be at least 8 characters": "비밀번호는 8 자 이상이어야합니다",
    "Password must not exceed 24 characters": "비밀번호는 24자를 넘지 않아야 합니다.",
    "Password must contain at least one special character": "비밀번호에는 하나 이상의 특수 문자가 포함되어야합니다",
    "Password cannot contain spaces": "암호에는 공백이 포함될 수 없습니다",
    "Email is required": "이메일이 필요합니다",
    "Email confirmation is required": "이메일 확인이 필요합니다",
    "Please enter a valid email": "유효한 이메일을 입력하십시오",
    "Step NUM1 of NUM2": "{num2} 단계 중 {num1} 단계",
    "Create Password": "비밀번호 만들기",
    "Get Started": "시작하다",
    "Create a Wallet": "지갑 만들기",
    "Confirm on this screen": "이 화면에서 확인",
    "Pick a password on the next step. This needs to be at least 8 symbols long.": "다음 단계에서 비밀번호를 선택하세요. 최소 8개의 기호 길이여야 합니다.",
    "Wallet Ready": "지갑 준비",
    "Add Binance Smart Chain": "Binance Smart Chain 추가",
    "Click on the button below to add Binance Smart Chain Network to your MetaMask wallet.": "아래 버튼을 클릭하여 Binance Smart Chain 네트워크를 MetaMask 지갑에 추가하세요.",
    "Confirm the connection request": "연결 요청 확인",
    "Get Started!": "시작하다!",
    "Secure Wallet": "안전한 지갑",
    "Confirm your password to continue.": "계속하려면 비밀번호를 확인하세요.",
    "You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.": "컴퓨터에 액세스할 수 없는 경우 지갑을 복구하는 데 필요하며 안전한 장소에 보관해야 합니다. 12단어 백업 문구에 액세스할 수 있는 사람은 누구나 자금을 복구할 수 있으므로 비밀로 유지하십시오.",
    "You have successfully set up Your wallet.Welcome to the world of WEB3!": "지갑을 성공적으로 설정했습니다.\nWEB3의 세계에 오신 것을 환영합니다!",
    "Confirm Password": "비밀번호 확인",
    "Install here.": "여기에서 설치하십시오.",
    "Something went wrong, please try verifying your email again.": "뭔가 잘못되었고, 이메일을 다시 확인해보십시오.",
    "Looks like your password reset link has expired. Please try again.": "비밀번호 재설정 링크가 만료 된 것 같습니다.다시 시도하십시오.",
    "Looks like your password reset link has already been used. Please request it again.": "비밀번호 재설정 링크가 이미 사용 된 것 같습니다.다시 요청하십시오.",
    "Couldn't recover password": "암호를 복구 할 수 없었습니다",
    "Couldn't reset your password.": "비밀번호를 재설정 할 수 없습니다.",
    "Couldn't login.": "로그인 할 수 없었습니다.",
    "Couldn't sign you up.": "가입 할 수 없었습니다.",
    "Resend Email": "이메일을 재현하십시오",
    "Couldn't resend confirmation email.": "확인 이메일을 재현 할 수 없습니다.",
    "Provided email is already in use.": "제공된 이메일이 이미 사용 중입니다.",
    "You have already verified your email address": "이미 이메일 주소를 확인했습니다",
    "Invalid or expired verification code": "유효하지 않거나 만료 된 검증 코드",
    "An error occurred while logging in. Please try again.": "로그인하는 동안 오류가 발생했습니다. 다시 시도하십시오.",
    "We sent an email to {EMAIL} with a link to finalize your registration": "당사는 귀하의 등록을 마무리하기위한 링크와 함께 {EMAIL}에 이메일을 보냈습니다.",
    "We sent an email to {EMAIL} with a link to change your password": "비밀번호를 변경할 수있는 링크와 함께 이메일을 {EMAIL}에 보냈습니다.",
    "Referral ID": "추천 ID",
    "On the next step, tap on 'Create a new wallet'.": "다음 단계에서 \n\"Create a new wallet\"를 누릅니다.",
    "On the next step, tap on 'Create a wallet'.": "다음 단계에서 \"Create a wallet\"을 누릅니다.",
    "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower case letters, symbols and numbers.": "다른 곳에서는 사용되지 않은 완전히 고유 한 비밀번호를 사용하는 것이 좋으며 상류 및 소문자, 기호 및 숫자가 혼합되어 있습니다.",
    "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.": "이전 단계에서 암기 된 단어를 순서대로 올려 놓으십시오.백업이 완료되면 \"Complete\"버튼을 누릅니다.",
    "On this step you can choose. We recommend you do this now as you might otherwise lose access to your wallet. Tap on 'Start' to continue.": "이 단계에서 선택할 수 있습니다. \n지갑에 대한 액세스 권한을 잃을 수 있으므로 지금 이 작업을 수행하는 것이 좋습니다. \n계속하려면 \"Start\"을 누르십시오.",
    "On this step tap on 'Start' to continue.": "이 단계에서 \"Start\"을 탭하여\n계속하십시오.",
    "Tap on the burger menu of the app in the top left corner of the screen.": "화면의 왼쪽 상단에있는 앱의 버거 메뉴를 누릅니다.",
    "Next, tap on Settings in the menu to the left.": "그런 다음 왼쪽 메뉴에서 \"Settings\"을 누릅니다..",
    "Next, tap on Networks.": "다음으로 \"Networks\"를 탭합니다.",
    "Add More Tokens": "더 많은 토큰을 추가하십시오",
    "In the search field, type in BNB Smart Chain and add it to your network.": "검색 필드에 \"BNB Smart Chain\"을 입력하고 네트워크에 추가하세요.",
    "Next, select Custom Token. Paste the address of the smart contract you copied into the token address field. In the Token Symbol field, type SP, and in the Token Decimal, put 0. Then click the 'Import' button to complete your setup.": "다음으로 \"Custom Token\"을 선택합니다. 복사한 스마트 계약의 주소를 \"Token Address\" 필드에 붙여넣습니다. \"Token Symbol\" 필드에 SP를 입력하고 \"Token Decimal\"에 0을 입력합니다. 그런 다음 \"Import\" 버튼을 클릭하여 설정을 완료합니다.",
    "Add Network": "네트워크 추가",
    "Give permission to the platform to add a network.": "네트워크를 추가하기 위해 플랫폼에 허가를 받으십시오.",
    "Extra PERCENT% Discount": "추가 {PERCENT}% 할인",
    "Tap on the 'Import Tokens' link to add a Custom Token.": "커스텀 토큰을 추가하려면 \"Import Tokens\" 링크를 탭하세요.",
    "Click on the button 'Approve' to allow the connection request.": "연결 요청을 허용하려면 \"Approve\" 버튼을 클릭하세요.",
    "Confirm Secret Phrase": "비밀 문구를 확인하십시오",
    "Click on the button 'Switch network' to allow the connection request.": "연결 요청을 허용하려면 \"Switch network\" 버튼을 클릭하세요.",
    "Payment Method": "지불 방법",
    "Select cryptocurrency": "암호화폐 선택",
    "Please use an Ethereum BEP-20 address.": "Ethereum BEP-20 주소를 사용하십시오.",
    "Connect to Gymstreet": "Gym Street에 연결",
    "Now to access the Gym Street Metaverse, you need to open it with the MetaMask browser. Go to your MetaMask wallet app, choose 'Browser' from the side navigation menu, search for Gym Street in the browser tab.": "이제 Gym Street Metaverse에 액세스하려면 MetaMask 브라우저로 열어야 합니다. MetaMask 지갑 앱으로 이동하여 측면 탐색 메뉴에서 \"Browser\"를 선택하고 브라우저 탭에서 Gym Street를 검색하세요.",
    "First, let's set up your cryptocurrency wallet.": "먼저 암호화폐 지갑을 설정합시다.",
    "Follow the next steps to continue the setup.": "다음 단계를 따라 설정을 계속하십시오.",
    "Your balance was updated": "잔액이 업데이트되었습니다",
    "Once installed, you should see the below splash screen. Click the 'Get Started' button to begin creating your Ethereum wallet using MetaMask.": "설치가 완료되면 아래 스플래시 화면이 표시됩니다. \"Get Started\" 버튼을 클릭하여 MetaMask를 사용하여 Ethereum 지갑 생성을 시작하세요.",
    "You will then be asked if you want to help improve MetaMask. Click 'No, Thanks' if this doesn't interest you, otherwise click 'I agree'.": "그러면 MetaMask 개선에 도움을 주고 싶은지 묻는 메시지가 표시됩니다. 관심이 없으면 \"No, Thanks\" 를 클릭하고 그렇지 않으면 \"I agree\" 를 클릭하십시오.",
    "MetaMask will then present you with your 12-word backup phrase. You will need to write this down in the same order displayed on your screen.": "그러면 MetaMask가 12단어 백업 문구를 제시합니다. 화면에 표시된 순서대로 적어야 합니다.",
    "After Downloading and opening MetaMask, tap on 'Get Started'.": "MetaMask를 다운로드하고 연 후 \"Get Started\"를 누릅니다.",
    "Improve Metamask": "메타 마스크를 향상시킵니다",
    "Congratulations! You have set up your MetaMask wallet. Confirm the next few pop-ups on the screen to access your wallet.": "축하합니다!MetaMask 지갑을 설정했습니다..화면의 다음 몇 가지 팝업을 확인하여 지갑에 액세스하십시오.",
    "Import Binance Smart Chain": "Binance Smart Chain 가져오기",
    "Next, copy the NFT Smart Contract by tapping on the button below.": "다음으로 아래 버튼을 탭하여 NFT Smart Contract을 복사합니다.",
    "Copy NFT Contract": "NFT Contract 복사",
    "Install MetaMask": "MetaMask 설치",
    "Install MetaMask here.": "여기에 MetaMask 설치.",
    "Guide for MetaMask Wallet connection process.": "메타 마스크 지갑 연결 프로세스 가이드.",
    "MetaMask will then present you with your 12-word backup phrase.": "그런 다음 메타 마스크가 12 단어 백업 문구를 제시합니다.",
    "MetaMask Secret Phrase": "MetaMask 비밀 문구",
    "Copy NFT Smart Contract": "NFT 스마트 계약을 복사하십시오",
    "You are almost finished with the MetaMask setup process. Just click 'All done' on the last page, and you will be automatically logged into MetaMask. If you ever log out, you can log back in by clicking on the icon added to your web browser (usually, it's next to the URL bar).": "MetaMask 설정 프로세스가 거의 완료되었습니다. 마지막 페이지에서 \"All done\"를 클릭하면 자동으로 MetaMask에 로그인됩니다. 로그아웃한 경우 웹 브라우저에 추가된 아이콘(일반적으로 URL 표시줄 옆에 있음)을 클릭하여 다시 로그인할 수 있습니다.",
    "Your payment processing is complete. Your NFT balance has been updated!": "결제 처리가 완료되었습니다. NFT 잔액이 업데이트되었습니다!",
    "Your payment is received but is incomplete. Please pay AMOUNT CURRENCY more to complete the payment.": "결제가 완료되었지만 완료되지 않았습니다. 결제를 완료하려면 {amount} {currency}를 더 지불하세요.",
    "Terms & Conditions": "이용약관",
    "Email Verification": "이메일 확인",
    "Transaction history": "거래 내역",
    "Order history": "주문 내역",
    "Product": "제품",
    "Quantity": "수량",
    "Payment UID": "지불 UID",
    "Status": "상태",
    "Currency": "통화",
    "Autoship":"자동 배송",
    "Amount": "양",
    "Date": "날짜",
    "Tx ID": "Tx ID",
    "Completed": "완전한",
    "MetaMask is not installed": "MetaMask 가 설치되지 않았습니다.",
    "If you have already installed MetaMask, please": "이미 {MetaMask}를 설치했다면",
    "refresh page": "페이지 새로 고침",
    "to continue.": "계속하다.",
    "You have successfully installed MetaMask. Now you can add a Network.": "MetaMask를 성공적으로 설치했습니다. 이제 네트워크를 추가할 수 있습니다.",
    "You already have this network": "이미 이 네트워크가 있습니다.",
    "Continue": "계속하다",
    "Finalize Registration": "등록 완료",
    "Please confirm your email to continue": "계속해서 이메일을 확인하십시오",
    "New email must be different from current email": "새 이메일은 현재 이메일과 달라야합니다",
    "Actions": "행위",
    "Request to change the chain has been rejected": "체인 변경 요청이 거부되었습니다",
    "Switch Chain": "스위치 체인",
    "Choose your Metaverse": "메타버스를 선택하세요",
    "You need to choose your Metaverse to continue. Maps, Dashboard and My Products information will vary depending on your selection. Currently {METAVERSE} Metaverse is selected.": "계속하려면 메타버스를 선택해야 합니다. 지도, 대시보드 및 내 제품 정보는 선택 사항에 따라 달라집니다. 현재는 {METAVERSE} 메타버스가 선택되어 있습니다.",
    "Can't check your purchases": "구매를 확인할 수 없습니다",
    "Attach {COUNT} miners": "{COUNT} 광부 연결",
    "Attach 1 miner": "광부 1개 부착",
    "View on map": "지도에서 보기",
    "Buy {COUNT} parcels": "{COUNT} 소포 구매",
    "Buy 1 parcel": "소포 1개 구매",
    "Buy {COUNT} miners": "{COUNT}개의 이중 채굴기 구매",
    "Buy 1 miner": "듀얼 마이너 1개 구매",
    "Metaverses": "메타버스",
    "My NFTs": "내 NFT",
    "Total Hashpower (Mh/s)": "총 해시파워(Mh/s)",
    "Available Miner Slots": "사용 가능한 광부 슬롯",
    "Average ROI past 9 months": "지난 9 개월 지난 평균 ROI",
    "Login Via Crypto Wallet": "암호화폐 지갑을 통한 로그인",
    "Land reserved for PARTNER": "{PARTNER}용 토지",
    "About PARTNER": "{PARTNER} 정보",
    "swap": "교환",
    "transfer": "옮기다",
    "approve": "승인하다",
    "max": "최대치",
    "The minimum amount to claim is N": "청구할 수 있는 최소 금액은 {n}입니다.",
    "paginationText": "보기{from} - {to} of {count}",
    "turnovers": "턴오버",
    "qualified_level_farm": "자격을 갖춘 레벨 팜",
    "unlimited_depth_farm": "무제한 깊이 팜",
    "qualified_depth_single_pool": "자격을 갖춘 깊이 싱글풀",
    "unlimited_depth_single_pool": "무제한 깊이 싱글풀",
    "gymstreet_turnover": "Gymstreet 회전율",
    "Total partners": "총 파트너수",
    "refreshData": "데이터 새로고침",
    "NFT Turnover": "NFT 회전율",
    "Single Pool Turnover": "단일 풀 회전율",
    "depth": "깊이",
    "turnover": "회전율",
    "weekly": "주간",
    "monthly": "월간 간행물",
    "wallet": "지갑",
    "columns": "열",
    "filters": "필터",
    "level": "레벨",
    "search": "검색",
    "partners": "파트너",
    "Farming Turnover": "농업 회전율",
    "Vault Turnover": "금고 회전율",
    "Careers status": "커리어통계",
    "GYMNET Balance": "GYMNET Balance",
    "Save": "구하다",
    "Swap": "교환하기",
    "Turnover pool": "턴오버풀",
    "Global Line Bonus": "글로벌 라인 보너스",
    "Turnover pools": "턴오버풀",
    "Tax pools": "세금풀 ({n}mo)",
    "Total qualified turnover": "총 적격 회전율",
    "My claimed rewards": "내가 청구한 보상",
    "My pending rewards": "내 보류 보상",
    "Week": "주",
    "Month": "달",
    "In order to get qualified for any pool, you’ll have to follow the 50% turnover rule: The maximum share each direct partner can contribute to the required turnover is up to 50% of the turnover coming from NFT sales.": "모든 풀에 대한 자격 을 얻으려면 50% 회전율 규칙을 따라야 합니다. 각 직접 파트너가 필요한 회전율에 기여할 수 있는 최대 공유는 NFT 판매에서 발생하는 회전율의 최대 50%입니다.",
    "View more": "더보기",
    "Turnover progress": "턴오버프로그레스",
    "Thank you for your participation in Global Line Incentive!": "글로벌 라인 인센티브에 참여해 주셔서 감사합니다!",
    "Global line before June 2023": "2023년 6월 이전 글로벌 라인",
    "Global line before March 2023": "2023년 3월 이전 글로벌 라인",
    "More info": "추가 정보",
    "Your Position": "너의 위치",
    "Last Position": "마지막 위치",
    "My line length": "내 라인 길이",
    "Global Line Turnover": "글로벌 라인 턴오버",
    "The turnover generated from all participants who joined the Global Line after you.": "귀하 이후에 Global Line에 가입한 모든 참가자로부터 발생한 매출입니다.",
    "Your purchases": "귀하의 구매 내역",
    "You need purchases of at least $100 to become a Global Line member.": "Global Line 회원이 되려면 최소 $100 이상 구매해야 합니다.",
    "Your pending income": "미결 소득",
    "Buy more NFTs to increase your shares": "<strong><i>NFT</i></strong>를 더 구입하여 <strong>공유</strong>를 늘리십시오.",
    "New Registrations": "신규 등록",
    "Turnover": "회전율",
    "Global level": "글로벌 수준",
    "Joining time": "합류 시간",
    "Global Line Total Turnover": "글로벌 라인 총 회전율",
    "Global Line end": "글로벌 라인 끝",
    "Please confirm your claim": "귀하의 주장을 확인하십시오",
    "After you confirm, you will receive your rewards on your balance.": "확인 후 잔액에 대한 보상을 받게 됩니다.",
    "Select Language": "언어 선택",
    "Download": "다운로드",
    "Your Income": "귀하의 수입",
    "Shares": "주식",
    "Single Miner": "싱글 광부",
    "Super Bundles": "슈퍼 번들",
    "Regular Bundles": "일반 번들",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%.": "각 직접 파트너가 필요한 매출에 기여할 수 있는 최대 지분은 최대 50%입니다.",
    "Your partners": "파트너",
    "Generated turnover": "생산된 턴오버",
    "Qualified turnover": "권환이 부여된 턴오버",
    "Generated": "생성됨",
    "Qualified": "자격 있는",
    "Your partners turnover statistics will appear here.": "파트너 회전율 통계가 여기에 표시됩니다.",
    "Pools": "풀들",
    "Qualification": "권한",
    "Rewards": "보상",
    "Participants": "참여자들",
    "Total rewards": "총 보상",
    "Rewards per participant": "참가자당 보상",
    "Land sales contribution": "토지판매 기여금",
    "Maintainance contribution": "유지 기여금",
    "Distributed": "분배됨",
    "Refresh data": "데이터 새로고침",
    "You will receive your rewards directly on your wallet": "지갑에서 직접 보상을 받게 됩니다.",
    "Total claimed": "총 청구된 금액",
    "Next distribution": "다음 배포",
    "Unclaimed BUSD rewards": "Unclaimed BUSD rewards",
    "Required level": "필수 레벨",
    "NFT sales percent": "NFT 판매 {n}%",
    "Total qualified participants": "총 적격 참가자",
    "TxID": "TxID",
    "Tx Type": "전송 유형",
    "Global line reward": "글로벌 라인 보상",
    "Your share": "당신의 몫",
    "Income before": "이전 소득",
    "Income after": "이후 수입",
    "NFT Income": "NFT 수입금",
    "NFT Commissions Total": "총 NFT커미션",
    "NFT Statistic": "NFT 통계",
    "Level": "레벨",
    "User": "사용자",
    "Your current pin": "현재 핀",
    "Turnover for the next pin": "다음 핀에 대한 턴오버",
    "Your next incentive": "다음 인센티브",
    "Your next bonus": "다음 보너스",
    "Incentives": "인센티브",
    "Team of": "팀",
    "Partners": "파트너",
    "Cash Bonus": "현금 보너스",
    "Your pin": "귀하의 핀",
    "Incentive": "자극",
    "Pin": "핀",
    "Level pool": "레벨 풀",
    "Go to PARTNER": "{PARTNER}로 이동",
    "Partnerships": "파트너십",
    "Cannot find EMAIL_OR_WALLET": "{EMAIL_OR_WALLET}을 찾을 수 없습니다.",
    "Incentive Ends In: ": "인센티브 <strong>종료</strong>: ",
    "Your Rewards History": "당신의 보상 역사",
    "Dubai & Sofia Trip": "두바이 & 소피아 여행",
    "No Rewards": "보상이 없습니다",
    "No Partners": "파트너가 없습니다",
    "Reward": "보상",
    "Granted": "부여된",
    "Transaction": "거래",
    "Free BUNDLE": "무료 {BUNDLE}",
    "Team Purchases": "팀 구매",
    "Direct Sales": "직접 판매",
    "You have won this reward": "당신은이 보상을 얻었습니다",
    "50% Turnover Rule is applied for all incentives!": "모든 인센티브에 50% 이직 규칙이 적용됩니다!",
    "Please note that you have the chance to earn a complimentary R8 Bundle in two different ways: either by reaching 20k in direct sales or by achieving 100k in team purchases.": "직접 판매에서 20,000에 도달하거나 팀 구매에서 100,000을 달성하는 두 가지 방법으로 무료 R8 번들을 얻을 수 있습니다.",
    "Insufficient funds for gas, please add BNB to your wallet": "가스에 대한 자금이 충분하지 않으면 지갑에 BNB를 추가하십시오.",
    "Please note that you will require up to AMOUNT worth of CURRENCY to complete the transaction.": "거래를 완료하려면 최대 {amount} 상당의 {currency}가 필요합니다.",
    "Select a payment method": "결제 방법을 선택",
    "Use your account's wallet balances to purchase the product.": "계정의 지갑 잔액을 사용하여 제품을 구매하세요.",
    "Connect a WEB3 wallet like Metamask or Trustwallet and pay with {currency}. This requires a small amount of BNB ($0.30) to cover the transaction fees!": "Metamask 또는 Trustwallet과 같은 WEB3 지갑을 연결하고 {currency}로 지불합니다. 거래 수수료를 충당하기 위해 소액의 BNB($0.30)가 필요합니다!",
    "Select one of the available blockchains and cryptocurrencies, and proceed with the payment via an external wallet application.": "사용 가능한 블록체인 및 암호화폐 중 하나를 선택하고 외부 지갑 애플리케이션을 통해 결제를 진행합니다.",
    "Internal wallet": "내부 지갑",
    "Web3 Wallet": "Web3 지갑",
    "External wallet": "외부 지갑",
    "Select Currency": "통화 선택",
    "Select from the available balances": "사용 가능한 잔액에서 선택",
    "Purchase for myself": "나를 위해 구매",
    "Select Blockchain": "블록체인 선택",
    "Select Cryptocurrency": "암호화폐 선택",
    "Please select another payment method": "다른 결제 수단을 선택하십시오",
    "Connect my wallet": "내 지갑 연결",
    "Select a product owner": "제품 소유자 선택",
    "Please select who you are purchasing for:": "구매 대상을 선택하십시오:",
    "Product Owner Tooltip": "제품 소유자 도구 설명",
    "Owner e-mail": "소유자 이메일",
    "Purchase Summary": "구매 요약",
    "Network": "네트워크",
    "Fees": "수수료",
    "Rate": "비율",
    "Send to wallet address": "지갑 주소로 보내기",
    "Or scan the code with your wallet app to fill in address automatically.": "또는 지갑 앱으로 코드를 스캔하여 주소를 자동으로 입력하세요.",
    "Send only CURRENCY to this wallet address!": "이 지갑 주소로 {currency}만 보내세요!",
    "Please send your crypto within 30 minutes to ensure a fixed exchange rate":"고정 환율을 보장하기 위해 30분 이내에 암호화폐를 보내주세요",
    "I sent the crypto": "암호화폐를 보냈습니다",
    "Insufficient funds": "잔액 부족",
    "Purchase Successful": "구매 성공!",
    "The NFT balance was successfully updated and can be viewed in My NFTs tab.": "\"내 주문\"에서 거래 상태를 모니터링할 수 있습니다. 성공하면 \"내 NFT\" 탭에서 NFT 잔액이 업데이트됩니다.",
    "Available balance": "사용 가능한 잔액",
    "scanning_for_transaction_text": "\"내 주문\"에서 거래 상태를 모니터링할 수 있습니다. 성공하면 \"내 NFT\" 탭에서 NFT 잔액이 업데이트됩니다.",
    "This window will close automatically once we receive your crypto.": "이 창은 암호화폐를 받으면 자동으로 닫힙니다.",
    "We did receive your crypto, but it was less than required. Please transfer the missing amount to complete your transaction.": "귀하의 암호화폐를 수취했으나 요구되는 금액보다 적습니다. 거래를 완료하려면 누락된 금액을 이체하십시오.",
    "Please do not attempt to do another transaction. Send your Payment UID to our support.": "거래가 만료되었습니다. 다른 거래를 시도하지 마십시오. 거래 ID를 지원팀에 보내주세요.",
    "Scanning for transaction": "트랜잭션 스캔 중",
    "Go to My Orders": "내 주문으로 이동",
    "remaining": "나머지",
    "Please send your crypto within 3 hours to ensure a fixed exchange rate": "고정 환율을 보장하려면 3시간 이내에 암호화폐를 보내주세요.",
    "Please send your crypto within n minutes to ensure a fixed exchange rate": "고정 환율을 보장하려면 {N}분 이내에 암호화폐를 보내주세요.",
    "Missing crypto amount": "누락된 암호화폐 금액",
    "Missing amount": "누락된 금액",
    "Recived amount": "받은 금액",
    "Required amount": "필요 금액",
    "Continue with payment": "결제 계속",
    "Please choose your preferred payment method to finalize your purchase.": "구매를 완료하려면 선호 결제 방식을 선택하십시오.",
    "Your payment has expired. To resolve this issue, please contact customer support.": "결제가 만료되었습니다. 이 문제를 해결하려면 고객 지원팀에 문의하십시오.",
    "Your payment has been received and is currently being processed. We will notify you once the process is complete.": "결제가 접수되었으며 현재 처리 중입니다. 결제프로세스 완료 시 알려드리겠습니다.",
    "Please indicate the intended recipient of the product. You have the option to purchase it for yourself or choose 'Another user' as the recipient.": "제품 수령인을 지정하십시오. 본인을 위해 구매하거나 수신자로 \"다른 사용자\"를 선택할 수도 있습니다.",
    "Your transaction has been declined. Please attempt the transaction again.": "거래가 거부되었습니다. 거래를 다시 시도하십시오.",
    "Monitor the status of your transaction by visiting the provided link:": "제공된 링크를 방문하여 거래 상태를 모니터링하십시오.",
    "Apologies, an error occurred on the blockchain side. Please try again.": "죄송합니다. 블록체인 측면에서 오류가 발생했습니다. 다시 시도하십시오.",
    "Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.": "새 거래를 시작하기 전에 블록체인에서 진행 중인 거래가 완료될 때까지 기다리십시오.",
    "Your payment has expired. To resolve this issue, please contact customer support": "결제가 만료되었습니다. 이 문제를 해결하려면 고객 지원에 문의하십시오.",
    "Your payment was refunded. AMOUNT CURRENCY was returned to your wallet.": "결제가 환불되었습니다. {amount} {currency}이(가) 귀하의 지갑으로 반환되었습니다.",
    "You can monitor the transaction status in the Order History tab. If it is successful, the NFT balance will be updated in the My NFTs tab.": "\"주문 내역\" 탭에서 거래 상태를 모니터링할 수 있습니다. 성공하면 \"내 NFT\" 탭에서 NFT 잔액이 업데이트됩니다.",
    "OFF": "끄다",
    "Received Amount.": "받은 금액.",
    "Processing Fee": "처리 수수료",
    "Estimated gas fee": "예상 가스 요금",
    "Transaction Expired": "거래가 만료됨",
    "Enable Payment": "결제 활성화",
    "Email does not exist": "이메일이 존재하지 않습니다",
    "Request another quote": "다른 견적 요청",
    "Purchase Success!": "구매 성공!",
    "Purchase for another user": "다른 사용자를 위해 구매",
    "Pay": "지불하다",
    "You purchased GIFT for RECEIVER!": "{receiver}을(를) 위해 {gift}을(를) 구매하셨습니다!",
    "You've purchased GIFT for RECEIVER!": "\"{receiver}\"을(를) 위해 {gift}을(를) 구매하셨습니다!",
    "You've purchased a GIFT for RECEIVER!": "\"{receiver}\"님을 위해 {gift}을 구매하셨습니다!",
    "Rewards claimed successfully": "리워드가 성공적으로 청구됨",
    "Commissions claimed successfully.": "커미션을 신청했습니다.",
    "You received GIFT from SENDER!": "{sender}로부터 {gift}을(를) 받았습니다!",
    "You've received GIFT from SENDER!": "\"{sender}\"님으로부터 {gift}을(를) 받았습니다!",
    "You've received a GIFT from SENDER!": "\"{sender}\"님으로부터 {gift}을(를) 받았습니다!",
    "Transaction Failed": "거래 실패",
    "The transaction has failed. Please try again.": "거래가 실패했습니다. 다시 시도해 주세요.",
    "Your transaction has been declined. Please add BNB to your wallet and attempt the transaction again.": "거래가 거부되었습니다. 지갑에 BNB를 추가하고 거래를 다시 시도하십시오.",
    "Blockchain Notification: Apologies, an error occurred on the blockchain side. Please try again.": "블록체인 알림: 죄송합니다. 블록체인 측에서 오류가 발생했습니다. 다시 시도해 주세요.",
    "Apologies, an unexpected error occurred. Please retry your request. If the issue persists, please contact support.": "죄송합니다. 예기치 않은 오류가 발생했습니다. 요청을 다시 시도하십시오. 문제가 지속되면 지원팀에 문의하세요.",
    "Received Amount": "받은 금액",
    "Dual Miner": "듀얼 마이너",
    "Bitopex": "Bitopex",
    "Gymnet": "Gymnet",
    "No enough CURRENCY to cover gas fee.": "가스 요금을 충당하기에 {currency}이(가) 충분하지 않습니다.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover gas fee.": "가스 요금을 충당하려면 {amount_currency}로 지갑을 충전하세요.",
    "Scanning": "스캐닝",
    "To continue, please enable payment.": "계속하려면 결제를 활성화하십시오.",
    "Kindly be aware that AMOUNT_CURRENCY may be necessary to cover the gas fee.": "{amount_currency}는 가스비를 충당하는 데 필요할 수 있습니다.",
    "Insufficient funds in CURRENCY to cover the gas fee.": "가스 요금을 충당하기 위한 {currency}의 자금이 부족합니다.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover the gas fee.": "가스 수수료를 충당하려면 {amount_currency}로 지갑을 충전하세요.",
    "We will cover the gas fee for this transaction on your behalf.": "우리는 귀하를 대신 하여이 거래의 가스 수수료를 충당 할 것입니다.",
    "Value": "값",
    "Reach Rank": "리치 랭크 <b>{n}</b>",
    "Rewards in Gymnet": "Gymnet에 대한 보상",
    "Rewards in Bitcoin": "Bitcoin에 대한 보상",
    "For Limited Time Only": "<strong>제한된 시간</strong> 동안만 유효합니다",
    "Seconds": "초들",
    "Minutes": "분들",
    "Days": "일들",
    "Hours": "사간",
    "Unclaimed parcels": "언클레임드 소포들",
    "Upgraded parcels": "업그레이드된 소포들",
    "Inactive miners": "비활성 마이너들",
    "Active miners": "활성 마이너들",
    "Available Balance": "이용가능 밸런스",
    "Utility Balance": "Utility 잔액",
    "Bitopex Balance": "Bitopex 잔액",
    "Moonberg Balance": "Moonberg 잔액",
    "Balance History": "밸런스 기록",
    "Miner Hashpower": "광부 Hashpower",
    "Dual Miner Hashpower": "듀얼 마이너 Hashpower",
    "Dual miners hashpower info": "보유하고 있는 hashpower는 활성 이중 채굴자 수에 단위당 1000Mh/s로 설정된 단위 채굴자 hashpower를 곱하여 결정됩니다.",
    "The hashpower you possess is determined by multiplying the number of your active miners with the unit miner hashpower, which is set at 1000Mh/s per unit.": "보유하고 있는 hashpower는 활성 채굴자 수에 단위당 1000Mh/s로 설정된 단위 채굴자 hashpower를 곱하여 결정됩니다.",
    "Dual Miners": "듀얼 마이너",
    "Dual miners info": "활성한 마이너 및 총 듀얼 마이너 수.",
    "Unlock the full potential of your Bitopex rewards as they come to life following your purchase of any of our special bundles. Once your Bitopex Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Bitopex 보상 최대한 잠재력을 활용해서 특별한 번들을 구매한 후에도 그들이 생생하게 됩니다. Bitopex 밸런스를 사용할 수 있게되면 총 보상에 대한 개요, 받은 보상, 예상되는 보상에 액세스할 수 있습니다. 또한, 카운트 다운 타이머는 보상을 놓치지 않도록 다음 보상 배포를 보여 줍니다.",
    "Sec": "초",
    "Min": "분",
    "Total Rewards Expected": "예상되는 총 보상 수",
    "Monthly Distribution": "월별 분포",
    "Price Projection": "가격 예측",
    "Server error": "서버 오류",
    "Average Gymnet ROI for the last 9 months": "지난 9개월 동안 평균 Gymnet ROI",
    "Dual Miners will generate rewards in Bitopex tokens, which will be distributed every Friday.":"듀얼 마이너는 Bitopex 토큰으로 보상을 생성하며 매주 금요일에 배포됩니다.",
    "Introducing the Utility Balance feature, a new and convenient payment method within our payment ecosystem. With this feature, you'll enjoy a separate balance that can be used alongside other payment methods. It provides you with the flexibility to partially utilize your utility balance, up to 25% of the total when purchasing single Land NFTs or making payments for Land NFT bundles.":"결제 생태계 내 새롭고 편리한 결제 수단인 Utility 잔액 기능을 소개합니다. 이 기능을 사용하면 다른 결제 수단과 함께 사용할 수 있는 별도의 잔액을 이용할 수 있습니다. 단일 Land NFT를 구매하거나 Land NFT 번들에 대한 지불을 할 때 전체의 최대 25%까지 유틸리티 잔고를 부분적으로 활용할 수 있는 유연성을 제공합니다.",
    "Balance before": "전 밸런스",
    "Balance after": "이후 밸런스",
    "TXID": "거래 아이디",
    "The number of active and total miners you have in Gymstreet and Kabutochō, respectively.": "Gymstreet 및 Kabutochō에 가지고 있는 활동하는 마니어 및 총 마이너 수.",
    "Token": "토큰",
    "Buy Bundles": "번들 구입하기",
    "Total Received": "받은 총계",
    "Next Rewards Distribution In:": "다음 보상 분포 대상:",
    "Total Rewards": "총 보상 수",
    "Payout": "지불",
    "Weekly": "매주",
    "Next Distribution Amount": "다음 배포 금액",
    "Internal transaction": "내부 거래",
    "Minimum claimable amount is (AMOUNT)": "청구 가능한 최소 금액은 {amount}입니다.",
    "User Profile": "사용자 프로필",
    "Name": "이름",
    "Nationality": "국적",
    "Contact Information": "연락처",
    "Country": "국가",
    "City": "도시",
    "Street": "거리",
    "Zip": "우편 번호",
    "E-Mail": "E-Mail",
    "Wallet address": "지갑의 주소",
    "Name is required": "이름이 필요합니다",
    "Nationality is required": "국적을 입력해야 합니다",
    "Country is required": "국가를 입력해야 합니다",
    "City is required": "도시명을 입력해야 합니다",
    "Street is required": "거리 이름을 입력해야 합니다",
    "Zip is required": "우편 번호를 입력해야 합니다",
    "Successfully updated": "성공적으로 업데이트되었습니다",
    "Zip contain only numbers and min length is 3": "우편 번호는 숫자만 포함하고 최소 길이는 3개입니다",
    "Please enter a valid email address": "유효한 이메일 주소를 입력하세요",
    "Personal information": "개인 정보",
    "T&C": "T&C",
    "Field is required": "장이 요구됩니다",
    "Password successfully changed": "암호가 성공적으로 변경되었습니다",
    "Old password is incorrect": "이전 암호가 잘못되었습니다",
    "Oops...Something went wrong. Please try again": "이런... 뭔가 잘못됐어요. 다시 시도하십시오",
    "Name must be at least 3 characters": "이름은 3자 이상이어야 합니다",
    "City must be at least 3 characters": "도시명은 3자 이상이어야 합니다",
    "Street must be at least 3 characters": "거리이름은 3자 이상이어야 합니다",
    "Affiliate Terms and conditions": "제휴사 이용 약관",
    "Affiliate links": "제휴 링크",
    "Affiliate link successfully changed": "제휴 링크가 성공적으로 변경되었습니다",
    "Please enter the Bitopex affiliate link here. Kindly note that you can only save the link once.": "여기에 Bitopex 제휴 링크를 입력하십시오. 링크는 한 번만 저장할 수 있는 것을 알아두세요.",
    "Input cannot be only spaces": "공백만 입력할 수 없습니다.",
    "To proceed with the registration, kindly accept the above 2 conditions by checking the corresponding boxes.": "등록을 진행하려면 해당 상자를 선택하여 위의 2가지 조건에 동의하십시오.",
    "I hereby confirm that I am not a US citizen": "본인은 미국 시민이 아님을 확인합니다.",
    "I hereby confirm that I am not a UAE citizen": "본인은 UAE 시민이 아님을 확인합니다.",
    "Enter Old Password": "이전 비밀번호 입력",
    "Change username & password": "사용자 이름 및 비밀번호 변경",
    "My wallet": "내 지갑",
    "Purchase": "구입",
    "Username required to proceed": "진행하려면 사용자 이름이 필요합니다.",
    "Enter preferred username here": "여기에 원하는 사용자 이름을 입력하세요.",
    "Username must be at least 8 characters, starting with a letter. Use only lowercase letters, numbers, hyphens, dots, and underscores.": "사용자 이름은 문자로 시작하여 8자 이상이어야 합니다. 소문자, 숫자, 하이픈, 점, 밑줄만 사용하세요.",
    "Username is already taken. Please choose a different username.": "이미 사용중인 이름입니다. 다른 사용자 이름을 선택하십시오.",
    "Email or username": "이메일 또는 사용자 이름",
    "Username is available": "사용자 이름을 사용할 수 있습니다",
    "Provided username is already taken.": "제공된 사용자 이름은 이미 사용 중입니다.",
    "Invalid credentials. Please try again.": "잘못된 자격 증명.\n 다시 시도해 주세요.",
    "Username successfully set": "사용자 이름이 성공적으로 설정되었습니다.",
    "Dear User, due to ongoing updates to our platform, you will need to enter a username to continue. The username needs to be unique and cannot be changed": "친애하는 사용자님, 당사 플랫폼의 지속적인 업데이트로 인해 계속하려면 사용자 이름을 입력해야 합니다. 사용자 이름은 고유해야 하며 변경할 수 없습니다.",
    "You have successfully set your username": "사용자 이름을 성공적으로 설정했습니다.",
    "Continue to dashboard": "대시보드로 계속",
    "Username is required": "사용자 이름이 필요합니다",
    "Rank Transfer": "순위 이전",
    "Review": "검토",
    "Top Up": "충전",
    "The claim button is currently disabled for maintenance. We apologize for the inconvenience. Please come back shortly.": "현재 유지관리를 위해 소유권 주장 버튼이 비활성화되어 있습니다. 불편을 끼쳐드려 죄송합니다. 곧 다시 오세요.",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / BUSD" : "새로운 사업장을 구현하는 경우, GYMNET / BUSD 으로 결제하여 토지를 구입할 수 있는 옵션이 있습니다",
    "Parcel NFT": "NFT 소포",
    "Miner NFT": "NFT 마이너",
    "BUSD Balance": "BUSD 잔액",
    "Your payment has been received. It is currently being processed. You will receive a notification when done.": "결제가 완료되었습니다. 현재 진행 중입니다. 완료되면 알림을 받게 됩니다.",
    "Error": "오류",
    "Please enter valid email": "올바른 메일 주소를 입력하십시오",
    "Your sponsor is": "귀하의 스폰서는 …입니다",
    "Credentials": "자격 인증서",
    "Enter Wallet Address (i.e. 0xb794f...79268)": "지갑 주소를 입력합니다 (i.e. 0xb794f...79268)",
    "Contact Customer Support": "고객지원부를 연락합니다",
    "Change Wallet": "지갑을 바꿉니다",
    "Account": "계정",
    "Please leave an address here, in which we can also mint your NFTs afterwards.": "우리는  당신의 NFT를 나중에 민팅할 수 있다는 주소를 여기에 남겨주십시오.",
    "Important!": "중요합니다!",
    "It should be a BEP-20 address - i.e. a BNB Smart Chain wallet address. We recommend the wallet Metamask or Trustwallet.": "BEP-20 지갑 주소가 필요합니다. 즉, BNB 스마트 체인 지갑 주소와 같은 것입니다. 메타마스크 또는 트러스트월렛 지갑을 사용하도록 권장됩니다. ",
    "NEVER USE AN EXCHANGE Wallet ADRESS !!!": "교환 지갑 주소를 절대 사용하지 마십시오!!!",
    "Failed": "실패했습니다.",
    "Account changed": "계정이 변경되었습니다.",
    "Your Profile has been updated": "프로필이 업데이트되었습니다.",
    "You already have an attached wallet address to this account.": "이 계정에 이미 인증된 지갑 주소가 있습니다.",
    "Login": "로그인",
    "Existing wallet": "기존지갑",
    "Referral ID is required": "추천인 ID가 필요합니다.",
    "Pending Transaction": "보류중인 트랜잭션",
    "Processing": "프로세싱",
    "Send this payment ID to a customer support when you are requested to do so.": "요청할 때 이 결제 ID를 고객 지원으로 보내십시오.",
    "To be able to claim your NFTs you need to connect the same wallet address attached to your account": " NFT를 청구하려면 계정에 등록된 동일한 지갑 주소를 연결해야 합니다. ",
    "Please confirm your email to continue.": "계속하려면 이메일을 확인하십시오.",
    "Valid address": "유효한 이메일 주소",
    "This social account is used by another user. Verify your email first or contact Customer Support": "다른 사용자가 이 소셜 계정을  사용합니다. 먼저 이메일을 확인하거나 고객 지원팀에 문의하십시오.",
    "Invalid wallet connection": "지갑 연결이 올바르지 않습니다.",
    "Registration completed successfully": "등록이 성공적으로 완료되었습니다.",
    "Failed to complete registration of the user": "사용자 등록이 실패하여 실행되지 못했습니다.",
    "Failed to register user": "사용자를 등록하지 못했습니다.",
    "Signature grant failed": "Signature grant failed",
    "Unsupported chain": "지원되지 않는 체인",
    "This wallet address is already associated with another user account. Please use another one!": "이 지갑 주소는 이미 다른 사용자 계정과 관련이 있습니다. 다른 주소를 사용하십시오!",
    "Failed to add address to your wallet": "지갑에 주소를 추가하지 못했습니다.",
    "Address has been successfully added to your wallet": "주소가 지갑에 성공적으로 추가되었습니다.",
    "Failed to accept Affiliate TMS": "제휴TMS를 수락하지 못했습니다",
    "Wallet address has been successfully applied": "지갑 주소가 성공적으로 적용되었습니다",
    "Your email is verified.": "귀하의 이메일이 확인되었습니다.",
    "Failed to add Token": "토큰을 추가하지 못했습니다.",
    "Failed to upload image": "이미지 업로드에 실패했습니다",
    "Wrong file size. Please upload image files with size less than 10MB": "파일의 크기가 잘못됩니다. 크기가 10MB 미만인 이미지 파일을 업로드하십시오",
    "We've verified your email address. You can now continue using the site.": "우리는 귀하의 이메일 주소를 확인했습니다. 이제 사이트를 계속 사용할 수 있습니다.",
    "You need to switch to Binance Smart Chain": " Binance Smart 체인으로 전환해야 합니다",
    "Switch to Binance Smart Chain to continue using GymStreet": " Gymstreet를 계속 사용하려면 Binance Smart 체인으로 전환하십시오",
    "To CashFT": "CashFT으로",
    "Buy a Super Bundle in the next": "다음에Super Bundle을 구입하십시오",
    "and get an EXTRA discount!": "그리고 추가 할인을 받으십시오!",
    "See All Offers": "모든 제안을 보기",
    "I accept the affiliate terms & conditions": "제휴 약관에 동의합니다",
    "Special offer!": "특가 판매!",
    "Just one": "하나만",
    "1000 Mh/s": "1000 Mh/s",
    "View Claimed Coordinates": "청구 된 좌표를 보기",
    "Your Parcel Coordinates": "귀하의 구획 좌표 ",
    "Number of miners you want to attach": "부착할 생각하는 채굴기 수",
    "Referral Link": "레퍼럴 링크",
    "Affiliate Link": "제휴 링크",
    "Invalid Wallet": "잘못된 지갑",
    "Back to Log In": "로그인으로 돌아가기",
    "You need a valid referral ID to finalize your registration": "등록을 완료하려면 유효한 추천인 ID가 필요합니다",
    "I have read and accept the": "저는 ….. 읽었고 그 점을 받아들인다.",
    "Your Sponsor": "귀하의 스폰서",
    "Forgot Your Password": "비밀번호를 잊었습니다",
    "Enter your email address and we will send you instructions to reset your password.": "이메일 주소를 입력하면 비밀번호를 재설정하도록 지침을 보내드겠습니다.",
    "We just sent you an email to confirm your e-mail address. Please click on the link in the e-mail to reset your password.": "방금 귀하의 이메일 주소를 확인하기 위해서 이메일을 보냈습니다.이메일의 링크를 클릭하여 비밀번호를 재설정하십시오",
    "Welcome To Metaverse": " {n} Metaverse에 오신 것을 환영합니다",
    "Commissions": "수수료",
    "Refund of electricity vouchers": "전기 바우처 환불",
    "Import Custom Token": "사용자 지정 토큰 가져오기",
    "Add Token To Wallet": "지갑에 토큰을추가하기",
    "Token contract address": "토큰 계약 주소",
    "Token symbol": "토큰기호",
    "Provided address is not a supported token address": "제공된 주소는 토큰 주소를 지원하지 않습니다.",
    "Are you sure you want to claim your rewards": "보상을 받으시겠습니까?",
    "OTP Code": "일회용 패스워드 코드",
    "OTP Code is required": "일회용 패스워드 코드가 필요합니다",
    "Enter OTP code you have received at email": "{email}로 받은 에서 받은 일회용 패스워드 코드를입력하십시오",
    "Asset": "자산",
    "Gas": "가스",
    "Send crypto": "암호를 보내기",
    "Enter wallet address": "지갑주소를 입력하기",
    "NFTs": "NFTs",
    "Activity": "활동",
    "Assets": "자산",
    "Import token": "토큰 가져오기",
    "Don't see your token?": "토큰이 안 보입니까?",
    "more": "더 보기",
    "Send": "보내기",
    "Sending success": "발송성공",
    "View account in explorer": " Explorer에서 계정 보기",
    "estimated": "추산되었습니다",
    "Copy your address": "주소를 복사합니다.",
    "Export private key": "개인 키를 내보내기",
    "Receive": "받기",
    "Account Details": "계정 상세 내역",
    "View on blockchain": "블록체인에서 보기",
    "GymStreet Parcels": "GymStreet 소포",
    "GymStreet Miners": "GymStreet 마이너들",
    "Kabutocho Parcels": "Kabutocho 소포",
    "Kabutocho Miners": "Kabutocho 마이너들",
    "Invalid wallet address": "지갑 주소가 잘못되었습니다.",
    "Calculate": "계산합니다.",
    "Sending": "보낸는 중",
    "Reject": "거부합니다.",
    "Confirmation": "확인",
    "Gas fees are paid to crypto miners who process transactions on the network. Gas fees are set by the network and fluctate based on network traffic and transaction complexity.": "가스 수수료는 네트워크에서 거래를 처리하는 암호화 마이너에게 지불됩니다.가스 수수료는 네트워크에 의해 설정되며, 네트워크 트래픽 및 거래 복잡성에 따라 변동합니다.",
    "Insufficient TOKEN balance": "불충분한 {token} 잔액",
    "Confirm Swap": "스왑 확인하기",
    "Approve Allowance": "수당 승인",
    "Help Center": "도우미센터",
    "Confirm the Allowance Transaction in order to be able to use Token through our services. Please note that this is a one-time action.": "우리 서비스를 통해 <strong> {n} </strong> 토큰을 사용할 수 있도록 수당 거래를 확인하십시오. 이것은 일회적인 조치임을 유의하십시오",
    "No Activities yet": "아직 활동이 없습니다.",
    "To": "로",
    "Contract interaction": "계약과의 상호작용",
    "Are you sure you want to delete this token": "이 토큰을 삭제하시겠습니까?",
    "Send only using  {CHAIN}  network to receive funds on this deposit address.": "이 입금 주소로 자금을 받으려면 {CHAIN} 네트워크를 통해서만 보내세요.",
    "Leadership Incentives": "리더십 인센티브",
    "Pool statistics": "풀 통계량",
    "Hourly": "시간당",
    "Turnover rule": "턴오버 규칙",
    "Turnover rule percentage": "턴오버 규칙의 {n}%",
    "Miner sales contribution": "광부들의 기여금",
    "Pool": "풀",
    "percent of NFT sales": "NFT 매출의 {n}% ",
    "partner": "파트너",
    "Main area": "주요 분야",
    "Turnover percentage rule": "턴오버 {n}% 규칙",
    "With dollar each": "각각 달러로",
    "Direct partners": "직접 파트너들",
    "Your next bonus will appear here": "다음 보너스가 여기에 표시됩니다",
    "Your incentive": "얻은 인센티브",
    "Your cash bonus": "현금보너스",
    "Your bonus history": "보너스 내역",
    "At the current moment you don't have any rewards": "현재 보상이 없습니다",
    "Partners with pin qualification": " {n} 핀의 자격을 갖춘 파트너들",
    "Total generated turnover": "총 발생 턴오버",
    "Active Miners": "실제 마이너들",
    "Total pool rewards": "총 풀 보상금",
    "Your pool share": "풀 점유율",
    "Total gGymnet": "총 gGymnet",
    "GYMNET Commissions Total": "총GYMNET 수수료",
    "Your attached wallet is {walletAddress}. You have tried to make request with wallet {currentWallet}. Please change connected wallet to {walletAddress}": "귀하가 첨부한 지갑은 <b>{walletAddress}</b>입니다. <b>{currentWallet}</b>으로 요청을 시도했습니다. 연결된 지갑을 <b>{walletAddress}</b>로 변경하십시오.",
    "Available for limited time": "제한된 시간에만 가능하다.",
    "Bonus": "보너스",
    "Please note that AMOUNT worth of CURRENCY is required to complete the transaction.": "Plea거래를 완료하려면 {currrency}의  {amount }가 필요한다는 점을 주의해 주십시오.",
    "Please note that AMOUNT_CURRENCY is required for gas fee": "가스 수수료의 경우 {amount_currency}가 필요합니다",
    "Utility": "유틸리티",
    "The URL must begin with 'https://bitopex.io/home/' and include your referral ID": " URL은 'https://bitopex.io/home/'로 시작하고 추천인 ID를 포함해야 합니다. ",
    "Insufficient funds: to continue with payment use utility balance": "예금 부족: 지불을 계속하기 위해 유틸리티 잔액을 사용합니다",
    "My Team": "마이 팀",
    "Team Statistics": "팀 통계",
    "All partners": "모든 파트너들",
    "Active partners": "활동적인 파트너들",
    "Inactive partners": "비활동적인 파트너들",
    "Binary Points": "바이너리 포인트",
    "Points in left leg": "왼쪽 레그에 포인트",
    "Points in right leg": "오른쪽 레그에 포인트",
    "Left leg": "왼쪽 다리",
    "Right leg": "오른쪽 다리",
    "Holding Tank": "저장 탱크",
    "Enrollment Tree": "등록 트리",
    "Binary Tree": "바이너리 트리",
    "CV Points History": "CV 포인트 내역",
    "Time Left To Activation": "활성화까지 <b>남은 시간</b>",
    "Search by email or wallet": "이메일 또는 지갑으로 검색하기",
    "Refresh Data": "데이터를 새로고치기",
    "Joined at": "…에 참여합니다",
    "Placement": "배치",
    "Select": "선택하기",
    "Own": "자신의",
    "Team": "팀",
    "Position": "위치",
    "CV Points": "CV P퍼인트",
    "CV = commission volume": "CV = 커미션 볼륨",
    "Genealogy": "계보",
    "Unilevel": "유니레버",
    "Select view": "견해를 택하기",
    "Team size": "팀 사이즈",
    "Rank": "순위",
    "Own purchases": "자신의 구매",
    "Navigation": "내비게이션",
    "Search by wallet": "지갑으로 검색하기",
    "no user": "사용자가 없습니다",
    "Wallet address is not valid": "지갑 주소가 올바르지 않습니다",
    "Free Spot": "프리스팟",
    "Search by wallet or username": "지갑 또는 사용자 이름으로 검색하기",
    "Info": "정보",
    "Benefits": "혜택",
    "Objectives Completed": "목표가 완료되었습니다",
    "TO DO`s": "목적",
    "Purchase NFTs In Total Value Of 100 USDT": "총 100 USDT의를 사용하여NFT를 구매합니다",
    "Purchase Binary Pass - 19 USDT": "바이너리 패스 구매 - 19 USDT",
    "Team Turnover": "팀 턴오버",
    "BASED ON TEAM": "{n}% + 팀에 기반을 둔",
    "Qualification Turnover": "자격 턴오버",
    "Current Rank": "현재의 순위",
    "Next Rank": "다음 순위",
    "Binary Spot Reservation": "바이너리 스팟 예약",
    "Holding Tank Ends In": "저장 탱크가 <b>에서</b> 끝납니다.",
    "With every parcel purchase, you will receive two types of tokens—Gymnet and Bitcoin.": "모든 소포를 구매할 때마다 Gymnet과 Bitcoin이라는 두 가지 유형의 토큰을 받게 됩니다.",
    "A minimum of USDT is required for Purchase": "구매하려면 최소 ${n}USDT가 필요합니다",
    "Become Affiliate Partner": "제휴 파트너 되기",
    "Placement in Unilevel": "Unilevel에 배치",
    "Matching bonuses": "매칭보너스",
    "Placement in Binary": "Binary에 배치 ",
    "Product Autoship Incentive": "제품 오토쉽 인센티브",
    "For limited time only": "제한된 시간 동안만 유효하다",
    "Free 30 days Product Autoship": "무료 30 일제품 오토쉽",
    "Fast Start Incentive": "빠른 시작 인센티브",
    "Receive Direct Sales Commissions": "직접 판매 수수료 받기",
    "Please select your role": "역할을 선택하십시오",
    "Choose your role before diving in. Specify why user should choose each role and what opportunities are there.": "다이빙을 하기 전에 자신의 역할을 선택하십시오. 사용자가 각 역할을 선택 해야 하는 이유 및 어떤 기회가 있는지 지정하십시오.",
    "is left to get": "<span>${n}</span> is left to get",
    "Go to Products": "제품으로 이동하기",
    "Rental Income": "수취임대료",
    "Left Leg Affiliate Link": "왼쪽 레그 제휴 링크",
    "Right Leg Affiliate Link": "오른쪽 레그 제휴 링크",
    "Qualification Conditions": "자격조건",
    "Buy Products": "제품을 구입하기",
    "My Current Rank": "내 현재 순위",
    "To Reach Rank You Need:": "필요하는 순위 {n}에 도달합니다:",
    "NFT Turnover In Weak Leg": "NFT Turnover In Weak Leg",
    "NFT Own purchases": " NFT 자체 구매",
    "Required Qualified Partners": "필요한 자격을 갖춘 필수 파트너들",
    "Progress Until Rank": "{n}순위가 될 때까지 진행하기",
    "See Details": "세부항목을 보기",
    "Default Advantages": "디폴트 이점",
    "Binary Commission": "바이너리 수수료",
    "Upgrade Your Weekly Cap": "주간 지급 한도 업그레이드하기",
    "Rank Benefits": "순위에 대한 혜택",
    "For 30 Days ONLY You Receive 10% Binary Commission Instead Of NUM": "30 일 동안만 {n}% 대신 10%의 바이너리 수수료를 받습니다!",
    "My Rank Progress": "개인 랭크 진행도",
    "Own Invest": "개인 투자",
    "Qualified Partners": "자격을 갖춘 파트너들",
    "You need to buy products in value of": "{n} 달러의 제품을 구입해야 합니다.",
    "You need to generate turnover in value of in your weak leg.": "당신은 ${n}의 금액으로 매출액을 위크 레그에서 창출해야 합니다 .",
    "Remaining": "남아 있는",
    "CV Points weak leg": " CV 포인트 위크 레그",
    "Details": "세부 사항",
    "Partner": "파트너",
    "leg": "레그",
    "Right": "오른쪽",
    "Left": "왼쪽",
    "You need qualified partner in left leg and qualified partner in right leg to complete this requirement.": "본 요구 사항을 완료하려면 {l} 왼쪽 레그와 {r} 오른쪽 레그에 자격을 갖춘 파트너가 필요합니다.",
    "partners in left leg and in right leg": "{l} 왼쪽 레그에 {partner1} 및  {r} 오른쪽 레그에 파트너들",
    "partners in left leg remaining": "{l} 왼쪽 레그에 {partner1} 가 남아 있습니다",
    "partners in right leg remaining": "{r} 오른쪽 레그에 파트너들이 남아 있습니다",
    "partner in right leg remaining": "{r} 오른쪽 레그에 파트너가 남아 있습니다",
    "Holding Tank is over": "저장 탱크가 끝났습니다",
    "Back To Rank Progress Details": "랭크 진행에 대한 세부항목 으로 돌아가기",
    "Super Bundle R10 or above": "슈퍼 번들 R10\n 또는 이상",
    "Required Monthly Product Autoship": "월 필수 제품 오토쉽",
    "All Customer Benefits": "모든 고객의 혜택",
    "Product Autoship": "제품 오토쉽",
    "Product Autoships": "제품 오토쉽들",
    "Products are resellable": "Products are resellable",
    "Activate binary commission": "바이너리 수수료를 활성화하기",
    "Activate direct sales commission": "직접판매수수료를 활성화하기",
    "Left before": "왼쪽 앞",
    "Left after": "왼쪽 뒤",
    "Right before": "오른쪽 앞",
    "Right after": "오른쪽 뒤",
    "Activate your referral link to be able to invite your referrals to the platform and earn additional commissions.": "추천 링크를 활성화한 후에 귀하의 추천인을 플랫폼에 초대하고 추가 커미션을 받을 수 있습니다.",
    "Activate referral link": "추천 링크를 활성화합니다.",
    "Yes, I confirm": "네, 확인합니다.",
    "Inactive": "사용되지 않습니다.",
    "Finance": "자금",
    "Total Income": "총수입",
    "Products": "제품들",
    "GymStreet Map": "GymStreet 맵",
    "Kabutocho Map": "Kabutocho 맵",
    "Don't ask again": "다시 묻지 마십시오",
    "Filecoin Balance": "파일코인 잔액보유량",
    "Filecoin": "파일코인",
    "Total available": "사용 가능한 총 금액",
    "Total generated": "발생한 총 금액",
    "Total used": "사용한 총 금액",
    "Transaction type": "트랜잭션 유형",
    "To claim your commission, you must have a minimum of 1 USDT or (AMOUNT) GYMNET.": "커미션을 청구하려면 최소 1 USDT나 {n} GYMNET이 있어야 합니다.",
    "Loading balance...": "로딩 밸런스...",
    "Total Commissions": "총 수수료",
    "Claimed Commissions": "청구된 수수료",
    "Available Commissions": "사용 가능한 수수료",
    "Total Commissions By Source": "소스별 총 수수료",
    "Filter by currency": "통화 필터",
    "No data to display": "표시할 데이터가 없습니다",
    "Year": "년",
    "Career Status": "경력현황",
    "Current Incentive": "현재 인센티브",
    "Fast Start": "빠른 스타트",
    "Product Autoship incentive": "제품 오토쉽 인센티브",
    "Direct partner - left leg": "직접 파트너 - 왼쪽 다리",
    "Direct partner - right leg": "직접 파트너 - 오른쪽 다리",
    "Receive direct sales commission": "직접판매 수수료를 받습니다.",
    "Direct sales commission": "직접판매 수수료",
    "Receive binary commission": "바이너리 커미션을 받습니다.",
    "Affiliate ranks": "계열사순위",
    "1 Left, 1 Right": "왼쪽 1번, 오른쪽 1번",
    "See Benefits": "이점을 확인합니다",
    "2 Silver (1 in different teams)": "실버 2개(각 팀별 1개)",
    "2 Gold (1 in different teams)": "골드 2개(각 팀별 1개)",
    "3 Platin (1 in different teams)": "백금3 개 (각 팀별 1개)",
    "3 Double Platin (1 in different teams)": "더블 백금3 개 (각 팀별 1개)",
    "4 Diamond (1 in different teams)": "다이아몬드 4개 (각 팀별 1개)",
    "5 Double Diamond (1 in different teams)": "더블 다이아몬드 5개 (각 팀별 1개)",
    "5 Red Diamond (1 in different teams)": "레드 다이아몬드 5개 (각 팀별 1개)",
    "5 White Diamond (1 in different teams)": "화이트 다이아몬드 5개 (각 팀별 1개)",
    "Please note that $100 dollar will correspond to 100 points.": "100달러는 100포인트에 해당한다는 점을 참고해 주십시오.",
    "Home": "홈",
    "Last Left": "Last Left",
    "Last Right": "Last Right",
    "Binary leg": "Binary leg",
    "by admin": "관리자에 의해서",
    "missed commission": "누락된 수수료",
    "payment": "결제",
    "commission": "수수료",
    "Payment Currency": "결제 통화",
    "BUSD Amount": "BUSD 금액",
    "CV Ratio": "변동계수비율",
    "Hrs": "시간",
    "Mins": "분",
    "Secs": "초",
    "Please enter a valid referral ID": "올바른 추천인 ID를 입력하십시오",
    "Commission TXID": "트랜잭션 아이디 수수료",
    "Qualification turnover": "자격회전율",
    "Binary commission": "바이너리 커미션",
    "Additional Advantages": "추가적인 이점",
    "The referral ID you've entered is incorrect. Please copy and paste the correct referral link to proceed.": "입력하신 추천인 아이디가 올바르지 않습니다. 올바른 추천 링크를 복사하여 붙여 넣으십시오.",
    "In order to receive binary commissions you need to have an active product autoship.": "바이너리 커미션을 받으려면 제품 오토십이 활성화되어 있어야 합니다.",
    "In order to receive binary commissions you need to have an active product autoship and a purchase for at least $100.": "바이너리 커미션을 받으려면 제품 오토십이 활성화되어 있고 최소 $100를 구매해야 합니다.",
    "To qualify for binary commissions, a minimum product purchase of $100 is required.": "바이너리 커미션을 받으려면 최소 $100의 제품을 구매해야 합니다.",
    "Transaction in Process": "거래 진행 중",
    "Claim your parcel": "소포를 청구하세요",
    "You do not have any claimed slots to attach miners. To enable miner attachment, please claim parcels on the map.": "채굴자를 연결할 슬롯이 없습니다. 광부 연결을 활성화하려면 지도에서 소포를 요청하세요.",
    "Active binary commission": "활성 바이너리 커미션",
    "Active direct sales commission": "활성 직접 판매 수수료",
    "Own purchase min {AMOUNT}": "자체 구매 최소 {AMOUNT}",
    "Active product autoship": "활성 제품 자동 배송",
    "Right partner own purchase min {AMOUNT}": "올바른 파트너 자체 구매 최소 {AMOUNT}",
    "Left partner own purchase min {AMOUNT}": "왼쪽 파트너가 직접 구매한 금액은 {AMOUNT}입니다.",
    "Unfortunately, you won't receive the bonus for this rank since you met the qualification conditions (more than 50%) before September 18, 2023": "안타깝게도 2023년 9월 18일 이전에 자격 조건(50% 이상)을 충족했으므로 이 등급에 대한 보너스를 받을 수 없습니다.",
    "You will be eligible for cash bonus based on your rank if you meet all qualification conditions between September 18 and November 8.Those who met the qualification conditions (more than 50%) before September 18, 2023 will not receive the Super Cash Bonus.": "9월 18일부터 1월 31일까지 모든 자격 조건을 충족하면 직급에 따라 현금 보너스를 받을 수 있습니다. 2023년 9월 18일 이전에 자격 조건(50% 이상)을 충족한 사람은 슈퍼 캐시 보너스를 받을 수 없습니다.",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / USDT": "새로운 비즈니스 빌딩이 구현될 때,  귀하는 GYMNET / USDT로 지불하여 토지를 구입할 수있는 \ n 옵션을 가질 것입니다.",
    "yearly": "연간",
    "Unknown": "알려지지 않은 것",
    "Claim processing may take up to 2 minutes. Thank you for your patience.": "클레임 처리에 최대 2분이 소요될 수 있습니다. 기디려 주셔서 감사합니다.",
    "1 Person": "1명",
    "2 Persons": "2 명",
    "Extended Referral Link": "확장 참조 링크",
    "Enter a username": "사용자 이름을 입력하시오. ",
    "Possible only for usernames in your binary team!": "바이너리 팀의 사용자 이름에 대해서만 가능!",
    "Select Team": "팀을 선택하기",
    "Create Link": "링크 만들기",
    "New users will be placed under in the team": "새 사용자는 {p} 팀의 {n} 아래에 배치될 것입니다",
    "Invalid username!": "잘못된 사용자 이름!",
    "Left team": "좌변팀",
    "Right team": "우변팀",
    "Distribution In Progress": "배포 진행 중",
    "Next Binary Bonus Distribution In": "다음으로 바이너리 보너스를 배포하는 것",
    "Matching Bonus Commissions": "매칭 보너스 수수료",
    "Binary Commissions": "바이너리 수수료",
    "Binary Weekly Cap": "바이너리 주간 캡",
    "Binary Cycles": "바이너리 사이클",
    "Matching Bonus": "매칭 보너스",
    "Incomes from Product Autoships": "제품 오토쉽에서 받는 수입",
    "Missed Commissions": "누락된 커미션",
    "Plus Max Weekly Payout": "플러스 최대 주별 지급액",
    "Import Token With Address": "주소가 있는 토큰 가져오기",
    "Import Supported Token": "지원되는 토큰 가져오기",
    "Shop": "상점",
    "Filter by": "필터링 기준",
    "My Purchases": "제가 구매한 것",
    "Purchases for other members": "따른 회원에게 구매한 것",
    "Product Autoship Purchases": "제품 오토쉽 구매",
    "Sort By": "정렬 기준",
    "Top up": "추가하기",
    "Attach your miners": "귀하의 마이너들을 부착하십시오",
    "Buy parcels": "소포를 구매합니다",
    "Number of parcels you want to buy": "구입하려는 소포 수량",
    "Use utility balance": "utility 잔액 사용",
    "My Products": "내 상품",
    "USDT Amount": "USDT 금액",
    "Next Matching Bonus Distribution In": "다음으로 바이너리 보너스를 배포하는 것",
    "Please top up your wallet with AMOUNT_CURRENCY USDT for upgrade.": "업그레이드하려면 {AMOUNT_CURRENCY} USDT 지갑을 추가하십시오.",
    "Please note that worth of USDT is required to complete the transaction.": "거래를 완료하려면 USDT의 {n}가치가 필요합니다.",
    "Incentive Ends In:": "인센티브 부여가 종료되는 시점:",
    "Bonus History": "보너스 내역",
    "Incentive has ended": "인센티브 지급이 중단되었습니다",
    "Direct Sales Turnover": "직접 판매회전율",
    "Commissions are paid out following a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "수수료는 70/30 비율에 따라 지급되며, 70%는 스테이블코인으로, 30%는 짐넷 토큰으로 지급됩니다.",
    "(effective as of 28.02.2024)": "(2024.02.28.부터 적용)",
    "Free Product Autoship": "무료 제품 자동 배송",
    "10% Binary Commission": "10% 바이너리 커미션",
    "Enjoy More, Benefit More!": "더 많이 즐기고, 더 많은 혜택을 누리세요!",
    "To qualify for Binary Commissions, you must have a new member with at least $100 on your right team and another on your left team.": "바이너리 커미션을 받으려면 오른쪽 팀에 최소 $100를 보유한 새 회원이 있어야 하고 왼쪽 팀에 또 다른 회원이 있어야 합니다.",
    "Buy products for at least $100 within 30 days to receive these benefits.": "이러한 혜택을 받으려면 <span>30일</span> 이내에 최소 <span>$100</span>의 제품을 구매하세요.",
    "Getting started": "시작하기",
    "Confirm your email to continue": "계속하려면 이메일을 확인하세요.",
    "Resend confirmation email": "확인 이메일 다시 보내기",
    "Super Bundle Special Discount Expires In": "슈퍼 번들 특별 할인이 만료됩니다",
    "Done": "완료",
    "Get your bundle": "번들 받기",
    "Gain Fresh Perspectives and Exclusive Benefits": "새로운 관점과 독점 혜택을 얻으세요",
    "Super Bundle R8": "슈퍼 번들 R8",
    "Super Bundle R17": "슈퍼 번들 R17",
    "Starter Bundle": "스타터 번들",
    "Travel": "여행 프로모션",
    "Cash Promo": "현금 프로모션",
    "Monthly Product Autoship is required to be an Affiliate Partner and earn commissions.": "제휴 파트너가 되어 커미션을 받으려면 월별 제품 자동 배송이 필요합니다.",
    "Affiliate": "제휴하다",
    "Recommended": "추천",
    "Binary Commissions based on the rank": "순위에 따른 바이너리 커미션",
    "Customer": "고객",
    "NFT Turbo": "NFT 터보",
    "Purchase Digital Land": "디지털 랜드 구매",
    "Participate in Partnership Deals": "파트너십 딜에 참여하세요",
    "Receive minting Rewards": "채굴 보상 받기",
    "Up to": "최대 {n}%",
    "Weekly Distribution": "주간 배포",
    "A parcel with minting power will generate rewards in Filecoin tokens, which will be distributed every Monday.": "주조 능력이 있는 소포는 매주 월요일에 배포되는 Filecoin 토큰으로 보상을 생성합니다.",
    "It seems that a some request is already pending. Please check your wallet app or extension and try again.": "일부 요청이 이미 대기 중인 것 같습니다. 지갑 앱이나 확장 프로그램을 확인하신 후 다시 시도해 주세요.",
    "Add miner NFT to wallet": "지갑에 Miner NFT 추가",
    "Go to My NFTs": "내 NFT로 이동",
    "Thank you for your participation": "참여해 주셔서 감사합니다",
    "No owner": "소유자 없음",
    "Ownership of this bundle is limited to one per user. Please purchase for yourself and for those who do not have it.": "이 번들의 소유권은 사용자당 하나로 제한됩니다. 본인을 위해 구입하시고, 없으신 분들은 구입하시기 바랍니다.",
    "Buy parcels with minting power and generate income.": "채굴력으로 소포를 구매하고 수익을 창출해보세요.",
    // Mystery box
    "Limited time offer": "기간 한정 혜택",
    "See Options": "옵션 보기",
    "Please select one of the currencies": "다음 통화 중 하나를 선택하세요.",
    "Current balance": "현재 잔액",
    "Try your luck": "행운을 시험해보세요",
    "Offer ends in": "혜택은 다음에서 종료됩니다.",
    "Parcel and Dual Miner": "소포 및 듀얼 마이너",
    "Mystery Box": "미스터리 박스",
    "Try your luck and win incredible Bundles, Parcels and Dual Miners to boost your earnings!": "행운을 시험해보고 놀라운 번들, 소포, 듀얼 마이너를 획득해 수입을 늘려보세요!",
    "from MIN to MAX": "<b>{min}</b>부터 <b>{max}</b>까지",
    "Choose a Box and Discover Rewards": "상자를 선택하고 보상을 찾아보세요",
    "Prizes you can win": "받을 수 있는 상품",
    "Item": "안건",
    "Chance": "가능성",
    "Promos": "프로모션",
    "Mystery Box results": "미스터리 박스 결과",
    "Total reward value": "총 보상 가치",
    "My Rewards": "내 보상",
    "Rewards Community Claimed": "보상 커뮤니티 청구",
    "Hero image": "히어로 이미지",
    "No rewards yet": "아직 보상이 없습니다.",
    "No winners yet": "아직 승자가 없습니다.",
    "While no lucky participants have claimed their prizes yet, the excitement continues to build.": "아직 상품을 수령한 행운의 참가자는 없지만, 흥미는 계속 커지고 있습니다.",
    "Mystery Box is Here!": "미스터리 박스가 왔습니다!",
    "Be the First to Discover Mystery Rewards": "미스터리 보상을 가장 먼저 발견하세요",
    "Select Balance": "잔액 선택",
    "Current Balance": "현재의 균형",
    "Opening Mystery Box": "미스터리 상자 열기",
    "Oops, not this time 😔": "앗, 이번에는 아니네요 😔",
    "Luck is on your side. Enjoy your prize!": "행운은 당신의 편입니다. 상품을 즐기세요!",
    "The more you try, the better your chances of winning. Keep going!": "더 많이 시도할수록 승리할 확률이 높아집니다. 계속하세요!",
    "Try another box": "다른 상자를 사용해 보세요",
    "Go to My Products": "내 제품으로 이동",
    "See promo results": "프로모션 결과 보기",
    "Payment enabled": "결제 가능",
    "Please select the wallet that is attached to your account.": "귀하의 계정에 연결된 지갑을 선택해주세요.",
    "Explore rewards": "보상 살펴보기",
    "View rewards claimed by you and the community": "나와 커뮤니티가 받은 리워드 보기",
    "Open a Mystery Box to get rewards starting from Parcels to Super bundles.": "미스터리 상자를 열어 소포부터 슈퍼 번들까지 다양한 보상을 받으세요.",
    "Insufficient funds, please choose another box.": "금액이 부족하면 다른 상자를 선택하세요.",
    "Blockchain error": "블록체인 오류",
    "Rewards claimed": "청구된 보상",
    "We have encountered difficulties while processing the result of the reward. Please contact our support team for prompt assistance.": "리워드 결과를 처리하는 동안 문제가 발생했습니다. 지원팀에 문의하시면 신속하게 도와드리겠습니다.",
    "Boxes opened": "상자 열기",
    // Mystery box

    // Partnership details
    "About": "소개",
    "Offer": "오퍼",
    "Social Media": "소셜 미디어",
    "Additional Content": "추가 콘텐츠",
    "Photo": "사진",
    "Video": "비디오",
    // Partnership details

    // Miner Rewards
    "Single Miners": "싱글 채굴자",
    "price today": "오늘 가격",
    "Estimated daily rewards": "예상 일일 보상",
    "day": "낮",
    "day at current rate": "현재 환율로 하루",
    "Estimated rewards until 2025": "2025년까지 예상 보상",
    "at current rate": "현재 속도로",
    "Estimated rewards from today to December 1, 2025. It may change based on your shares in the pool.": "오늘부터 2025년 12월 1일까지의 예상 보상입니다. 풀에서 보유한 지분에 따라 변경될 수 있습니다.",
    "Expected rewards calculator": "기대 보상 계산기",
    "price": "가격",
    "My total GYMNET value in USD": "내 총 GYMNET 가치(USD)",
    "claimed": "주장하다",
    "My Balances": "내 잔액",
    "Tokens to be burned": "소각할 토큰",
    "You dont have any Claimed or Pending GYMNET rewards.": "요청했거나 대기 중인 GYMNET 보상이 없습니다.",
    "Claim now": "지금 청구하세요",
    "No claimed GYMNET rewards are found.": "청구된 GYMNET 보상이 없습니다.",
    // Miner Rewards

    "Please note that the leg with the lesser amount of points is called weak leg and the one with the higher amount of points is called strong or power leg, and it can change over time based on which leg the most turnover is generated.": "포인트가 적은 레그를 \"약한 레그\", 포인트가 높은 레그를 \"강한 레그\" 또는 \"파워 레그\"라고 부르며, 어느 레그의 회전율이 가장 높은지에 따라 시간이 지남에 따라 달라질 수 있습니다. 생성됩니다.",
    "Mystery Box price": "미스터리 박스 가격",

    "Registration Date": "등록 날짜",
    "First Purchase Date": "첫 구매일",
    "Product Autoship Valid Until": "제품 자동배송 유효기간",

    // Suspicous activity
    "You have one attempt left": "시도가 한 번 남았습니다. 이후에는 이 계정에 대한 시도가 일시적으로 제한됩니다.",
    "You have no attempts left": "이 계정에 남은 시도가 없습니다. {minutesText}<b>분</b> 후에 다시 시도하거나 비밀번호를 재설정하세요.",
    "Sorry, you’ve been blocked": "죄송합니다, 차단되었습니다.",
    "Your IP address has been temporarily blocked for security reasons.": "보안상의 이유로 IP 주소가 일시적으로 차단되었습니다. 잠시만 기다려 주세요. 곧 자동으로 리디렉션됩니다.",
    "If you believe this was a mistake, please contact us through our Customer Support.": "실수라고 생각되면 <a href=\"mailto:support@gymstreet.io?subject=Suspicious Activity&body=I am blocked due to suspicious activity, but I think It's a mistake\" class=\"customer-support-button\">고객 지원을 통해 문의하시기 바랍니다.</a>",
    // Instant commissions
    "Own Deposit": "자체 보증금",
    "years ago": "몇 년 전",
    "Rank Achievement Bonus": "순위 달성 보너스",
    "Rank achievement bonus eligibility is based on rank achieved through qualifications.": "순위 달성 보너스 자격은 자격을 통해 달성한 순위를 기준으로 합니다.",
    "Bonuses": "보너스",
    "Bonuses are paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET tokens": "보너스는 70/30 비율로 지급되며, 70%는 USDT, 30%는 GYMNET 토큰으로 이루어집니다.",
    "Rank current": "순위 {n}",
    "You will receive the bonus after the incentive is over, and you can view it in the 'My Wallet' section.": "인센티브가 종료된 후 보너스를 받게 되며, '내 지갑' 섹션에서 확인할 수 있습니다",
    "You must have at least RANK partners, with at least one in a different leg.": "최소한 {rank} 파트너가 있어야 하며, 다른 레그에 최소한 한 명은 있어야 합니다.",
    "Additional Assets": "추가 자산",
    "Back to My wallet": "내 지갑으로 돌아가기",
    "Bonus points": "보너스 포인트",
    "Fee": "요금",
    "Bonus Details": "보너스 세부 정보",
    "Details / weekly cap": "세부 정보/주간 한도",
    "User / amount": "사용자/금액",
    "Extended Ref Link": "확장 참조 링크",
    "Leadership": "지휘자의 지위",
    "Travel Incentive": "여행 인센티브",
    "Unlock the full potential of your Filecoin rewards as they come to life following your purchase of any of our special bundles. Once your Filecoin Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "특별 번들을 구입한 후 파일코인 보상의 잠재력을 최대한 발휘해 보세요. 파일코인 잔액을 사용할 수 있게 되면 총 보상, 받은 보상, 예상 보상에 대한 개요에 액세스할 수 있습니다. 또한 카운트다운 타이머가 다음 보상 분배를 보여주기 때문에 보상을 놓치지 않을 수 있습니다",
    "is left to qualify": "자격을 갖추려면 ${n} 남았습니다",
    "Check transactions": "거래 확인",
    "Direct partner own purchases": "직접 파트너 자체 구매",
    "Each of your direct partners on the left and right legs needs to generate a turnover value of $100.00.": "왼쪽과 오른쪽 다리의 직접 파트너는 각각 $100.00의 매출 가치를 창출해야 합니다",
    "Participation to GYMSTREETs Elite Vote": "짐스트리트 엘리트 투표 참여",
    "Prioritization ends in": "우선순위 지정 종료",
    "Start Prioritizing": "우선순위 지정 시작",
    "Your changes have been saved": "GYMSTREET 엘리트 투표 참여",
    "features are still remaining": "<b>{n} 기능</b>이 아직 남아 있습니다.",
    "feature is still remaining": "<b>{n} 기능</b>이 아직 남아 있습니다.",
    "All prioritized": "모두 우선순위",
    "GYMSTREETs Elite Vote Statistics": "GYMSTREET의 엘리트 투표 통계",
    "Just NUM features to go! Complete your prioritization to help us shape community's vision.": "<b>{countText}</b>만 입력하면 됩니다! 우선순위 지정을 완료하여 커뮤니티의 비전 수립에 도움을 주세요.",
    "You've prioritized the features. Please review your choices and hit Submit to finalize.": "기능을 우선시했습니다. 선택 사항을 검토하고 <b>'제출'</b>을 눌러 완료하세요.",
    "Back to All Features": "모든 기능으로 돌아가기",
    "Changes saved": "변경 사항 저장됨",
    "No changes": "변경 사항 없음",
    "Submit": "제출",
    "priority": "우선순위",
    "Set priority": "우선순위 설정",
    "Feature details": "기능 세부 정보",
    "Select a feature to see more info": "자세한 정보를 보려면 기능을 선택하세요",
    "Prioritization Submitted": "우선순위 제출됨",
    "The results will be available after the voting period ends. You'll be able to change your priorities until then.": "결과는 투표 기간이 종료된 후 확인할 수 있습니다. 그때까지 우선순위를 변경할 수 있습니다",
    "Go to Career Status page": "경력 상태 페이지로 이동",
    "Keep Prioritizing": "우선순위 지정 유지",
    "Continue Prioritizing": "우선순위 지정 계속",
    "Review and Submit": "검토 및 제출",
    "Thinking of reprioritizing?": "우선순위를 다시 정할 생각인가요?",
    "You've already submitted your priorities. If you want to reprioritize them, please make sure you hit Resubmit.": "이미 우선순위를 제출하셨습니다. 우선순위를 다시 지정하려면 <b>'다시 제출'</b>을 누르시기 바랍니다.",
    "Got it": "알았다",
    "Resubmit": "다시 제출",
    "Priorities submitted": "제출된 우선순위",
    "Submitted": "제출됨",
    "View Statistics": "통계 보기",
    "Continue Prioritization": "우선순위 지정 계속",
    "Reprioritize": "우선순위 변경",
    "Start Prioritization": "우선순위 지정 시작",
    "features": "기능들",
    "feature": "기능",
    "GYMSTREET Elite Vote": "GYMSTREET의 엘리트 투표",
    "Thanks for submitting! You can still reprioritize and resubmit your choices until the voting period ends.": "제출해 주셔서 감사합니다! 투표 기간이 종료될 때까지 선택 항목의 우선순위를 변경하고 다시 제출할 수 있습니다",
    "Youre not done yet. Continue prioritizing the upcoming features and submit your final choices.": "아직 끝나지 않았습니다. 향후 출시될 기능의 우선순위를 계속 지정하고 최종 선택 사항을 제출하세요",
    "Exclusive for Platin and higher rank members. Prioritize the features you want to see next on GYMSTREET.": "Platin 이상 등급 회원에게만 제공됩니다. GYMSTREET에서 다음에 보고 싶은 기능의 우선순위를 정하세요.",
    "View rules": "규칙 보기",
    "Prioritization has ended. See the community's vote results.": "우선순위 지정이 종료되었습니다. 커뮤니티의 투표 결과를 확인하세요",
    "Stay tuned": "계속 지켜봐 주세요",
    "See current community prioritization statistics below. In 7 days, we'll also announce our decision right here, revealing the selected new features and their estimated release dates.": "아래에서 현재 커뮤니티 우선순위 통계를 확인하세요. 7일 후에 여기에서 결정된 새 기능들과 예상 출시일을 공개할 예정입니다",
    "Terms and Conditions for GYMSTREETs Elite Vote": "GYMSTREET 엘리트 투표 이용 약관",
    "The period for earning mining rewards in BITOPEX tokens has officially concluded. Thanks for your participation. Enjoy your BPX earnings.": "BITOPEX 토큰 채굴 보상 획득 기간이 공식적으로 종료되었습니다. 참여해 주셔서 감사합니다. BPX 수익을 즐기세요.",
    "We're enhancing our claim feature, so it will be offline, for the next {hours} hours. Thank you and stay tuned!": "청구 기능을 개선하는 중이므로 앞으로 {hours}시간 동안 오프라인 상태가 될 예정입니다. 양해해 주셔서 감사합니다.",
    "Eligibility for a one-time 10% commission, based on the total turnover generated from direct sales, requires referring team members and achieving a turnover of $5,000 or more in direct sales.": "직접 판매에서 발생한 총 매출액을 기준으로 일회성 10% 커미션을 받으려면 팀원을 추천하고 직접 판매에서 $5,000 이상의 매출액을 달성해야 합니다.",
    "Member": "Affiliate",
    "Trainee": "Trainee",
    "Rookie": "Rookie",
    "Silver": "Silver",
    "Gold": "Gold",
    "Platinum": "Platinum",
    "Double Platinum": "Double Platinum",
    "Diamond": "Diamond",
    "Double Diamond": "Double Diamond",
    "Red Diamond": "Red Diamond",
    "White Diamond": "White Diamond",
    "Crown Diamond": "Crown Diamond",
    "Platin": "Platinum",
    "Double Platin": "Double Platinum",
    "Single Pool": "Single Pool",
    "Explorer": "Explorer",
    "Mastery": "Mastery",
    "Features expected timeframes revealed": "기능 예상 기간 공개",
    "Find estimated timelines for features prioritized by our community and aligned with our strategy.": "커뮤니티에서 우선순위를 정하고 전략에 부합하는 기능에 대한 예상 타임라인을 찾아보세요.",
    "Estimated timeframe": "예상 기간",
    "Undefined": "한정되지 않은",
    "No Longer Valid": "더이상 유효하지 않은",
    "Statistics": "통계",
    "Turnover Incentive": "이직 인센티브",
    "Available For Limited time only": "<b>제한된 시간</b> 동안만 사용 가능",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving $20,000 in direct sales.": "직접 판매액 $20,000를 달성하면 무료 R8 번들을 받을 수 있습니다.",

    "Minting Rewards": "주조 보상",
    "Please check transactions if your {asset} balance is not visible": "{asset} 잔액이 보이지 않을 경우 거래를 확인하세요.",
    "In order to send {asset} you need to select {chain} network and wallet that is connected to your account.": "{asset}을 보내려면 계정에 연결된 {chain} 네트워크와 지갑을 선택해야 합니다.",
    "Cash bonus eligibility is based on rank achieved through qualifications. Participants qualifying with over 50% before March 4th, 2024, will not receive the bonus.": "현금 보너스 자격은 자격을 통해 획득한 순위에 따라 결정됩니다. 2024년 3월 4일 이전에 50% 이상 자격을 갖춘 참가자는 보너스를 받을 수 없습니다.",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving 100k in team purchases.": "팀 구매 100,000개를 달성하면 무료 R8 번들을 받을 수 있습니다.",

    "Convert": "변환",
    "Convert your unused miners into hashpower to earn rewards in Gymnet and Filecoin": "사용하지 않는 채굴기를 해시파워로 전환하여 짐넷과 파일코인에서 보상을 받으세요.",
    "The 50% Turnover Rule is applied to this incentive": "이 인센티브에는 50% 회전율 규칙이 적용됩니다",

    "Super Bundle Rank Benefits": "슈퍼 번들 등급 혜택",
    "Matching bonus up to NUM levels": "최대 {num} 레벨까지 매칭 보너스",
    "Up to NUM levels": "최대 {num} 레벨",
    "Left leg partner": "왼쪽 다리 파트너",
    "Right leg partner": "오른쪽 다리 파트너",
    "Commission Activation Requirements": "커미션 활성화 요구 사항",
    "Up to 1 level": "최대 1레벨",
    "Bonus is paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET": "보너스는 70/30 비율로 지급되며, 70%는 USDT, 30%는 GYMNET입니다.",
    "Current Turnover": "현재 매출액",
    "You will receive the bonus once the incentive period is over and it will be deposited directly into your wallet.": "인센티브 기간이 끝나면 보너스를 받게 되며 해당 보너스는 지갑에 직접 입금됩니다.",
    "Purchase Amount": "구매 금액",
    "Spring": "봄",
    "Spring Incentive": "봄 인센티브",
    "Commission Earned": "획득한 커미션: <b>${n}</b>",
    "Bonus Earned": "보너스 획득: <b>${n}</b>",
    "Earn 1 PAP point for every dollar spent on purchases using a utility balance or when you purchase a product autoship.": "유틸리티 잔액을 사용하여 구매하거나 제품 자동 배송을 구매할 때 지출한 1달러당 1 PAP 포인트를 적립하세요.",
    "Deposit to Single pool": "Single pool입금",
    "Max": "최대",
    "Received": "수신됨",
    "About voting rights": "투표권에 대하여",
    "Your first deposit must be over $25": "첫 입금액은 $25 이상이어야 합니다.",
    "Staking period ends": "스테이킹 기간 종료",
    "December": "12월",
    "Deposit & Stake": "입금 및 스테이킹",
    "Purchasing the card requires passing the KYC procedure.": "카드를 구매하려면 KYC 절차를 통과해야 합니다.",
    "Continue to KYC": "KYC로 계속 진행하기",
    "KYC Verification": "KYC 인증",
    "Step": "단계 {n}",
    "Upload ID": "ID 업로드",
    "Male": "남성",
    "Female": "여성",
    "Gender": "성별",
    "ID Type is required": "ID 유형은 필수입니다.",
    "ID Document Language is required": "ID 문서 언어가 필요합니다",
    "ID Expiration Date is required": "ID 만료일이 필요합니다",
    "ID Issue Date is required": "ID 발급 날짜가 필요합니다.",
    "State must be at least 3 characters": "상태는 3자 이상이어야 합니다.",
    "ID Type": "ID 유형",
    "National Identity Card": "국가 신분증",
    "Driver's License": "운전면허증",
    "Passport": "여권",
    "ID Document Language": "ID 문서 언어",
    "ID Number be at least 3 characters": "ID 번호는 3자 이상이어야 합니다.",
    "ID Issue Date": "ID 발급 날짜",
    "ID Expiration Date": "ID 만료일",
    "You need to upload a photo (up to mb) of your document Please upload an image not a pdf.": "문서 사진을 업로드해야 합니다. PDF가 아닌 이미지를 업로드하세요.",
    "File size exceeds 3.3 MB.": "파일 크기가 3.3MB를 초과합니다.",
    "Front side of ID": "신분증 앞면",
    "Back side of ID": "신분증 뒷면",
    "Upload proof of address document": "주소 증명 문서를 업로드하세요.",
    "Upload proof of address, which must not be older than 75 days.": "75일 이내의 주소 증명을 업로드하세요.",
    "Acceptable forms of proof of address include a credit card statement, utility bill, bank statement or bank letter.": "허용되는 주소 증명 서류에는 신용카드 명세서, 공과금 청구서, 은행 명세서 또는 은행 서신 등이 있습니다. ",
    "Address Document Type is required": "주소 문서 유형은 필수입니다.",
    "Document Issued by is required": "발행인 문서가 필요합니다.",
    "Document Issued Date is required": "문서 발급 날짜가 필요합니다.",
    "Address Document Type": "주소 문서 유형",
    "Credit Card Statement": "신용 카드 명세서",
    "Utility Bill": "공공 요금 청구서",
    "Bank Statement": "은행 명세서",
    "Bank Letter": "은행 편지",
    "Document Issued by": "문서 발행자",
    "Document Issued Date": "문서 발행 날짜",
    "Please upload a legible document in image format not pdf, up to mb in size.": "최대 MB 크기의 읽기 쉬운 이미지 형식의 PDF가 아닌 문서를 업로드하세요.",
    "Submit for Verification": "확인을 위해 제출하기",
    "Your documents have been submitted for verification. Once verification has been approved, you can continue your purchase.": "확인을 위해 서류가 제출되었습니다. 인증이 승인되면 구매를 계속할 수 있습니다.",
    "You need GYMNET to make a deposit in Single Pool": "Single Pool 입금하려면 GYMNET이 필요합니다.",
    "Security Assurance": "보안 보증",
    "Global Accessibility": "글로벌 접근성",
    "Affiliate Earnings": "ア제휴사 수익",
    "Effortless Integration": "손쉬운 통합",
    "Cards": "카드",
    "Overview": "개요",
    "Information": "정보",
    "Invoices": "인보이스",
    "KYC Status": "KYC 상태",
    "Try again": "다시 시도해 보세요.",
    "Apply here": "여기에서 신청하기",
    "Not verified": "확인되지 않음",
    "Pending verification": "확인 대기 중",
    "Rejected": "거부됨",
    "Verified": "확인됨",
    "Next service fee in": "다음 서비스 요금",
    "No active membership": "활성 멤버십이 없습니다.",
    "Card": "카드",
    "Card number": "카드 번호",
    "PIN Set Successfully": "비밀번호 설정에 성공했습니다.",
    "Go to Dashboard": "대시보드로 가기",
    "4-digit PIN": "4자리 비밀번호",
    "Re-enter 4-digit PIN": "4자리 비밀번호 다시 입력",
    "Set PIN": "비밀번호 설정",
    "Your account is now verified and you can continue your purchase of the bundle.": "이제 계정이 인증되었으며 번들 구매를 계속할 수 있습니다.",
    "Continue purchase": "구매 계속하기",
    "Your documents have been rejected from KYC provider.": "KYC 제공업체에서 문서를 거부했습니다.",
    "Please try again": "다시 시도하세요",
    "Your KYC is approved": "KYC가 승인되었습니다.",
    "Your KYC is rejected": "KYC가 거부되었습니다.",
    "File is required": "파일이 필요합니다.",
    "Seamless Spending with GYMSTREET Cards": "GYMSTREET 카드로 원활한 지출",
    "Our cards open a world of smooth transactions and rewarding opportunities for you.": "저희 카드는 원활한 거래와 보람 있는 기회의 세계를 열어드립니다.",
    "Card Types Tailored to Your Needs": "필요에 따른 맞춤형 카드 유형",
    "Card design": "카드 디자인",
    "Card information": "카드 정보",
    "Your go-to choice for daily transactions and affiliate commissions, offering practical benefits and reliable service within GYMSTREET and beyond.": "일일 거래 및 제휴 수수료에 대한 우리의 선택은 GYMSTREET 내외에서 실용적인 혜택과 안정적인 서비스를 제공합니다.", // DONT TRANSLATE (GYMSTREET)
    "Elevate your financial experience with this premium option with expanded benefits, designed for those who seek luxury and exclusivity.": "고급스러움과 특별함을 추구하는 분들을 위해 확장된 혜택을 제공하는 이 프리미엄 옵션으로 금융 경험을 향상하세요.",
    "Cards come in Bundles": "카드는 번들로 제공됩니다.",
    "Explorer card and 2 parcels": "Explorer카드와 토지 구획 2개",
    "Mastery card and 3 parcels": "Mastery카드와 토지 구획 3개",
    "Getting Started with Your GYMSTREET Card Experience": "GYMSTREET카드 경험 시작하기",
    "Start securely with our simple KYC process, ensuring a safe and personalized Card experience.": "간단한 KYC 프로세스로 안전하게 시작하여 안전하고 개인화된 카드 경험을 보장합니다.",
    "Deposit Tokens": "토큰 입금",
    "Stake the required GYMNET tokens - 500 for Explorer or 1500 for Mastery - to initiate your application.": "신청을 시작하려면 필수 GYMNET 토큰(Explorer의 경우 500, Mastery의 경우 1500)을 스테이킹하세요.",  // DONT TRANSLATE (GYMNET, MASTERY )
    "Order Your Card": "카드를 주문하세요",
    "Make your final decision on card type and place your order buying one of the 2 card bundles.": "카드 유형을 최종 결정하고 2개의 카드 번들 중 하나를 선택하여 주문하세요.",
    "Get Your Card": "카드를 받으세요",
    "Anticipate the arrival of your new financial companion, securely shipped and ready to integrate into your life.": "안전하게 배송되어 생활에 통합할 준비가 된 새로운 금융 동반자의 도착을 기대하세요.",
    "Use Your Card": "카드를 사용하세요",
    "Begin making purchases with your card and start earning affiliate commissions and more.": "카드로 구매를 시작하고 제휴 커미션 등을 적립하세요.",
    "Card Operation Fees": "카드 운영 수수료",
    "Fee Type": "요금 유형",
    "Both Cards (Explorer & Mastery)": "두 카드 (Explorer ＆ Mastery)",
    "POS Transactions": "POS 거래",
    "ATM Withdrawals": "ATM 인출",
    "Foreign Currency Conversion": "외화 환전",
    "Card Replacement": "카드 교체",
    "Simple, Transparent Pricing": "간단하고 투명한 가격",
    "Monthly Membership Fee": "월 멤버십 요금",
    "Monthly Membership": "월 멤버십",
    "Get Your Card Now": "지금 카드를 받으세요",
    "Choose the card that best suits your lifestyle. With straightforward fees, a seamless onboarding process, and an 11-level deep affiliate commission reward system, these cards are your partner in financial empowerment.": "라이프스타일에 가장 적합한 카드를 선택하세요. 간단한 수수료, 원활한 온보딩 프로세스, 11단계의 심층적인 제휴사 수수료 보상 시스템을 갖춘 이 카드는 금융 역량 강화의 파트너입니다.",
    "How do I get a card?": "카드를 받으려면 어떻게 해야 하나요?",
    "Start by filling out the KYC form on our site. After we verify you, you'll need to deposit tokens for the card you want in Single Pool: 500 for Explorer or 1500 for Mastery. Then, you can order your card.": "당사 사이트에서 KYC 양식을 작성하는 것부터 시작하세요. 귀하를 확인한 후 단일 풀에 원하는 카드에 대한 토큰을 입금해야 합니다:Explorer의 경우 500, Mastery의 경우 1500. 그러면 다른 카드를 주문할 수 있어요.", // DONT TRANSLATE (Explorer & Mastery, KYC )
    "How many tokens do I need to deposit for each card?": "각 카드당 몇 개의 토큰을 입금해야 하나요?",
    "You need 500 tokens for the Explorer card and 1500 tokens for the Mastery card.": "Explorer 카드에는 500개의 토큰이 필요하고 Mastery 카드에는 1500개의 토큰이 필요합니다.", // NOT TRANSLATE (Explorer & Mastery)
    "When will my card arrive?": "카드는 언제 도착하나요?",
    "After you order, your card will be sent out and should arrive quickly, so you can start using it soon.": "주문 후 카드가 발송되며 빠르게 도착하므로 곧 사용할 수 있습니다.",
    "Can I earn affiliate commissions with my card?": "카드로 제휴 수수료를 받을 수 있나요?",
    "Yes, activate your card, and you can begin earning through our 11-level deep affiliate program. Check our website for how the commissions work.": "예, 카드를 활성화하면 11단계의 심층 제휴 프로그램을 통해 적립을 시작할 수 있습니다. 커미션 지급 방식은 웹사이트에서 확인하세요.",
    "Total Value At Time of Deposit": "입금 시점의 총 가치",
    "Current Value": "현재 가치",
    "My claimed GYMNET rewards": "내가 청구한 GYMNET 보상",
    "My pending GYMNET rewards": "대기 중인 내 GYMNET 보상",
    "Deposit": "입금",
    "Connected": "연결됨",
    "No deposit fee for burn": "굽기에 대한 보증금 수수료 없음",
    "Min amount": "최소 금액",
    "Total GYMNET locked": "전체 GYMNET 잠김",
    "APR": "연간 비율",
    "Daily APR": "일일 비율",
    "Total own claimed": "적립된 총 금액",
    "Reward per block": "블록당 보상",
    "My Deposit": "내 입금",
    "Locked period until Dec 2027": "2027년 12월까지 잠금 기간",
    "With Locking Period": "잠금 기간 포함",
    "Total Gymnet Deposit": "총 Gymnet 예치금",
    "Gymnet Rewards": "Gymnet 보상",
    "Total Gymnet earned": "획득한 총Gymnet ",
    "Active holdings": "활성 보유",
    "With locking period": "잠금 기간 포함",
    "Withdrawn holdings": "회수된 보유자산",
    "Locked": "잠김",
    "Unlocked": "잠금 해제됨",
    "Deposit amount": "입금 금액",
    "Claimed rewards": "청구된 보상",
    "Start date": "시작 날짜",
    "End date": "종료 날짜",
    "Withdraw": "인출",
    "First name": "이름",
    "Last name": "성",
    "Address": "주소",
    "State": "도",
    "Phone number": "전화 번호",
    "Birth Date": "생년월일",
    "Submit & Continue": "제출 및 계속하기",
    "ID Number": "ID 번호",
    "ID Issued By": "ID 발급된",
    "Proof of address document": "주소 증명 서류",
    "Proof of address document (optional)": "주소 증명 서류(선택 사항)",
    "Personal Information": "개인 정보",
    "First name is required": "이름은 필수 항목입니다.",
    "Last name is required": "성은 필수 항목입니다.",
    "Address is required": "주소가 필요합니다",
    "Please enter your email address": "당신의 이메일 주소를 입력하십시오",
    "Phone number is required": "전화번호는 필수 항목입니다.",
    "Date of birth is required": "생년월일은 필수 항목입니다.",
    "ID Number is required": "ID 번호가 필요합니다",
    "ID Issued by is required": "발급기관 신분증이 필요합니다",
    "You need to upload a photo of your document Please upload a png or jpeg, not a pdf.": "문서 사진을 업로드해야 합니다. \n <span style='color: #1A6C99'>pdf가 아닌 png 또는 jpeg</span>를 업로드해 주세요.",
    "File size exceeds {n} MB.": "파일 크기가 {n}MB를 초과합니다.",
    "Please upload a legible document in png or jpeg format.": "<span style='color: #1A6C99'>PNG 또는 JPEG 형식</span>으로 읽을 수 있는 문서를 업로드하세요.",
    "Select asset to auto swap and stake": "자동 스왑 및 스테이킹할 자산을 선택하세요",
    "Giving swap allowance": "스왑 수당 지급",
    "Swap allowance given": "스왑 수당이 지급되었습니다",
    "Swapping tokens to GYMNET": "GYMNET으로 토큰 교환",
    "Tokens swapped to GYMNET": "토큰이 GYMNET으로 교환되었습니다",
    "Giving stake allowance": "스테이크 수당 지급",
    "Stake allowance given": "스테이크 수당 지급",
    "Staking to single pool": "단일 풀에 스테이킹",
    "Staked to single pool": "단일 풀에 스테이킹되었습니다",
    "1. Swap allowance (Only for USDT)": "1. 스왑 허용량(USDT에만 해당)",
    "2. Swap to GYMNET": "2. GYMNET으로 전환",
    "3. Stake allowance": "3. 스테이크 허용량",
    "4. Stake": "4. 스테이크",
    "Actual balance:": "실제 잔액:",
    "The transaction gas fee is reserved from the actual BNB balance.": "거래 가스 수수료는 실제 BNB 잔액에서 예약됩니다.",
    "Insufficient balance to make a swap": "스왑할 잔액이 부족합니다",
    "Transactions gas fee is considered in BNB available balance": "거래 가스 수수료는 BNB 사용 가능한 잔액으로 간주됩니다",
    "We will ask for allowance and try to swap your tokens to GYMNET, and after, stake them in single pool.": "우리는 허용량을 요청하고 토큰을 GYMNET으로 교환한 후 단일 풀에 스테이킹을 시도할 것입니다.",
    "Auto swap and stake": "자동 스왑 및 스테이킹",
    "Continue transactions": "거래를 계속하세요",
    "Pause": "일시 중지",
    "You need more GYMNET to make a deposit in Single Pool": "Single Pool에 입금하려면 <b>{n}개 이상의 GYMNET이 필요합니다.",
    "Swap & Stake": "스왑 및 스테이크",
    "You need more USDT to purchase a bundle": "번들을 구매하려면 <b>{n} USDT가 더 필요합니다</b>",
    "Topup": "충전",
    "Proceed anyways": "어쨌든 진행하세요",
    "Explorer Card": "Explorer카드",
    "Mastery Card": "Mastery카드",
    "Monthly spending max": "월별 지출 최대치",
    "Monthly Balance limit": "월간 잔액 한도",
    "Go to Cards": "카드로 이동",
    "Contact Support": "연락처 지원",
    "Deposit Fee": "입금 수수료",
    "Switch to 'Utility' to view the price for each product with USDT & Utility. To partially pay for a product using Utility, you need to have enough USDT and Utility in your balance.": "USDT 및 Utility가 포함된 각 제품의 가격을 보려면 'Utility'로 전환하세요. Utility를 사용하여 상품을 부분 결제하려면 잔액에 충분한 USDT와 Utility가 있어야 합니다.",
    "Deposit with locking period": "잠금 기간이 있는 입금",
    "The state must be in the form of a two-letter abbreviation in uppercase.": "상태는 대문자로 된 두 글자 약어 형식이어야 합니다.",
    "You need to upload a photo in JPEG or PNG format, not a PDF. The document can be in either English or Spanish.": "PDF가 아닌 <span style='color: #1A6C99'>jpeg</span> 또는 <span style='color: #1A6C99'>png</span> 형식의 사진을 업로드해야 합니다. \n 문서는 <span style='color: #1A6C99'>영어</span> 또는 <span style='color: #1A6C99'>스페인어</span>로 작성될 수 있습니다.",
    "You need to upload a legible document in jpeg or png format, not a pdf. The document can be in either English or Spanish.": "PDF가 아닌 <span style='color: #1A6C99'>jpeg</span> 또는 <span style='color: #1A6C99'>png</span> 형식으로 읽을 수 있는 문서를 업로드해야 합니다. \n 문서는 <span style='color: #1A6C99'>영어</span> 또는 <span style='color: #1A6C99'>스페인어</span>로 작성될 수 있습니다.",
    "Commissions are paid out using a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "커미션은 70/30 비율로 지급되며, 70%는 스테이블 코인으로, 30%는 GYMNET 토큰으로 지급됩니다.",
    "This policy is effective as of September 25, 2023.": "이 정책은 2023년 9월 25일부터 시행됩니다.",
    "Required turnover": "필요한 회전율",
    "Switch to TOKEN to view the price for each product with USDT & TOKEN. To partially pay for a product using TOKEN, you need to have enough USDT and TOKEN in your balance.": "USDT 및 {TOKEN}가 포함된 각 제품의 가격을 보려면 '{TOKEN}'로 전환하세요. {TOKEN}를 사용하여 상품을 부분 결제하려면 잔액에 충분한 USDT와 {TOKEN}가 있어야 합니다.",
    "Alternatively, you can use your CURRENCY balance to cover the gas fee.": "또는 {currency_yg} 잔액을 사용하여 가스 요금을 충당할 수 있습니다.",
    "Insufficient funds: to continue with payment use Utility or Gymnet balance": "자금 부족: 결제를 계속하려면 Utility 또는 Gymnet 잔액을 사용하세요.",
    "Use": "사용",
    "Use Utility or Gymnet": "Utility 또는 Gymnet를 사용하세요.",
    "Achieved PRICE turnover": "${price} 매출 달성",
    "Your current milestone": "현재 이정표",
    "Reward History": "보상 내역",
    "Leading Direct Partner": "선도적인 직접 파트너",
    "Remaining Direct Partners": "나머지 직접 파트너",
    "Bonus Split": "보너스 분할",
    "Monthly Countdown": "월간 카운트다운",
    "No direct partners": "직접적인 파트너 없음",
    "To receive the reward, you must have an active product autoship.": "보상을 받으려면 활성화된 제품 자동 배송이 있어야 합니다.",
    "This incentive is subject to the 50% Turnover Rule.": "이 인센티브에는 50% 이직률 규칙이 적용됩니다.",
    "Income From Product Authoship": "제품 승인으로 인한 수입",
    "Your documents have been submitted for verification. The bank may take up to 3 business days to review your application. Once approved, you can continue with your purchase.": "귀하의 문서가 확인을 위해 제출되었습니다. 은행에서 신청서를 검토하는 데 영업일 기준 최대 3일이 걸릴 수 있습니다. 승인되면 구매를 계속할 수 있습니다.",
    "To purchase the card, you need to agree to the T&C and complete the KYC verification process.": "카드를 구매하려면 T&C에 동의하고 KYC 인증 절차를 완료해야 합니다.",
    "Only Latin letters are allowed": "라틴 문자만 허용됩니다.",
    "First name must be at least 3 characters": "이름은 3자 이상이어야 합니다.",
    "First name should not exceed 10 characters": "이름은 10자를 초과할 수 없습니다.",
    "Should be only letters": "문자로만 이루어져야 함",
    "Address must be at least 3 characters": "주소는 3자 이상이어야 합니다.",
    "Only latin letters, numbers and special characters are allowed": "라틴 문자, 숫자, 특수 문자만 허용됩니다.",
    "Email is not valid": "이메일이 유효하지 않습니다",
    "Zip must contain only numbers with min length of 3": "우편번호에는 최소 길이가 3인 숫자만 포함되어야 합니다.",
    "The phone number must be at least 6 digits": "전화번호는 6자리 이상이어야 합니다.",
    "The phone number must not exceed 12 digits": "전화번호는 12자리를 초과할 수 없습니다.",
    "Phone number should contain only numbers": "전화번호에는 숫자만 포함되어야 합니다.",
    "ID Number must have at least 3 characters": "ID 번호는 3자 이상이어야 합니다.",
    "ID Issued by must have at least 3 characters": "발급자 ID는 3자 이상이어야 합니다.",
    "ID Issued by should not exceed 191 characters": "발급자 ID는 191자를 초과할 수 없습니다.",
    "Last name must be at least 3 characters": "성은 3자 이상이어야 합니다.",
    "State is required": "상태는 필수 항목입니다.",
    "The incentive countdown starts at the beginning of each month and ends at the end of the month. If you do not qualify for the bonus within that month, you will need to start again in the next month.": "인센티브 카운트다운은 매월 초에 시작되어 월말에 종료됩니다. 해당 달 내에 보너스를 받을 자격이 없으면 다음 달에 다시 시작해야 합니다.",
    "Activated": "활성화됨",
    "Deposit & fee commission": "보증금 및 수수료 수수료",
    "Web3 wallet not connected. Please connect to proceed.": "Web3 지갑이 연결되지 않았습니다. 계속하려면 연결해 주세요.",
    "Total investors profit": "총 투자자 이익",
    "Commissions from vault investors": "볼트 투자자로부터의 수수료",
    "Please Claim your rewards from to activate this section.": "이 섹션을 활성화하려면 {n}에서 보상을 요청하세요.",
    "Or use Button Below": "또는 아래 버튼을 사용하세요.",
    "Partner Activity": "파트너 활동",
    "Pending Commissions": "보류 중인 커미션",
    "Binary Turnover": "이진 회전율",
    "Unilevel Turnover": "균일한 회전율",
    "Right Direct Partners": "올바른 직접 파트너",
    "Left Direct Partners": "왼쪽 직접 파트너",
    "50% Compensation:": "50% 보상:",
    "When you reach the next rank, you will receive 50% of the lost bonus from the previous rank.": "다음 등급에 도달하면 이전 등급에서 손실된 보너스의 50%를 받게 됩니다.",
    "Please note that username can be changed only once in 6 months.": "사용자 이름은 6개월에 한 번만 변경할 수 있습니다.",
    "Binary Turnover in weak leg": "약한 다리에서 이진 회전율",
    "Unilevel turnover requirement": "단일 레벨 이직률 요구 사항",
    "Connect a WEB3 wallet such as Metamask or Trustwallet and pay with {currency}. This process requires a small BNB fee ($0.30) to handle transaction costs!": "Metamask, Trustwallet 등 WEB3 지갑을 연결하고 {currency}로 결제하세요. 이 프로세스에는 거래 비용을 처리하기 위해 소액의 BNB 수수료($0.30)가 필요합니다!",
    "Use your account's wallet balance to purchase the product.": "계정의 지갑 잔액을 사용하여 제품을 구매하세요.",
    "Seems like your crypto hasn't arrived yet": "암호화폐가 아직 도착하지 않은 것 같습니다",
    "Wait more": "좀 더 기다려",
    "Or topup with": "또는 다음으로 충전하세요.",
    "Connected to web3": "web3에 연결됨",
    "Reset": "초기화",
    "Top Up Successful": "충전 성공",
    "Top Up Successful. Card balance will be updated soon": "충전에 성공했습니다. 카드 잔액이 곧 업데이트됩니다.",
    "Please activate your card and top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "카드를 활성화하고 잔액을 충전하여 월 요금을 충당하세요. 잔액이 업데이트되지 않으면 카드가 비활성화되며 새 카드를 구매해야 합니다.",
    "PERCENTAGE allocation from Global Pool": "글로벌 풀에서 {perc}% 할당",
    "You need to reach rank RANK_NAME to participate in this pool.": "이 풀에 참여하려면 \"{rank_name}\" 등급에 도달해야 합니다.",
    "Global Pool": "글로벌 풀",
    "Next Distribution In:": "다음 배포 위치:",
    "50% Rule": "50% 규칙",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%": "각 직접 파트너가 필수 매출에 기여할 수 있는 최대 지분은 최대 50%입니다.",
    "Sub Pools": "하위 풀",
    "Congratulations! You have qualified for a share of the Global Pool.": "축하해요! 귀하는 글로벌 풀의 지분을 받을 자격이 있습니다.",
    "You will receive your share once the countdown is complete.": "카운트다운이 완료되면 공유를 받게 됩니다.",
    "Please top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "월 요금을 충당할 수 있도록 잔액을 충전하세요. 잔액을 업데이트하지 않으면 카드가 비활성화되며 새 카드를 구매해야 합니다.",
    "You need to deposit VALUE worth of GYMNET to upgrade your weekly binary cap": "주간 바이너리 상한을 업그레이드하려면 {dvalue} 상당의 GYMNET을 입금해야 합니다.",
    "Binary Max Weekly Payout": "바이너리 최대 주간 지불금",
    "Required Single Pool Deposit To Upgrade": "업그레이드에 필요한 단일 풀 예치금",
    "Upgrade Binary Max Weekly Payout": "바이너리 최대 주간 지불금 업그레이드",
    "Weekly Cap Upgraded": "주간 캡 업그레이드",
    "Your 8 months period for earning token rewards in Filecoin has officially concluded. Thank you for your participation. Enjoy your FIL earnings.": "Filecoin에서 토큰 보상을 받을 수 있는 8개월간의 기간이 공식적으로 종료되었습니다. 참여해 주셔서 감사합니다. FIL 수입을 즐기십시오.",
    "As previously announced Filecoin will be replaced by Moonberg token, which will be distributed for a period of 24 months. The Moonberg launch is expected for August 2024 and the distribution will start one week after that.": "이전에 발표한 바와 같이 Filecoin은 Moonberg 토큰으로 대체되며 24개월 동안 배포됩니다. Moonberg 출시는 2024년 8월로 예상되며 배포는 그로부터 일주일 후에 시작됩니다.",
    "Username must not exceed 255 characters": "사용자 이름은 255자를 초과할 수 없습니다.",
    "Upgrade": "업그레이드",
    "Product Autoship Monthly": "제품 자동 배송 월별",
    "Product Autoship Yearly": "제품 자동 배송 연간",
    "Product Owner": "제품 소유자",
    "Myself": "나 자신",
    "Another user": "다른 사용자",
    "Payment": "결제",
    "PAP Balance": "PAP 잔액",
    "Purchased By": "구매자",
    "Purchased For": "구매 대상",
    "Single parcel": "단일 소포",
    "Single miner": "단일 채굴자",
    "completed": "완전한",
    "pending": "보류 중",
    "expired": "만료됨",

    "Select {n} blockchain to send the transaction": "거래를 보내려면 {n} 블록체인을 선택하세요",
    "We recommend waiting to claim your rewards when the gas fee is lower than the amount you are claiming.": "가스 수수료가 청구하는 금액보다 낮을 때 보상을 청구하는 것을 기다리는 것이 좋습니다.",
    "Total Value Of Purchases": "구매 총 가치",
    "The total value of all purchases excluding purchases related to Card Bundles, granted products and product autoship subscriptions.": "카드 번들, 부여된 제품 및 제품 자동 배송 구독과 관련된 구매를 제외한 모든 구매의 총 가치.",
    "Total Receivable Tokens": "총 수취 가능 토큰",
    "The number of Moonberg tokens you will receive over the next 24 months.": "향후 24개월 동안 받게 될 Moonberg 토큰 수.",
    "Realtime ROI": "실시간 ROI",
    "Current Return on Investment (ROI) based on the real-time price of the Moonberg token.": "Moonberg 토큰의 실시간 가격을 기준으로 한 현재 투자 수익률(ROI).",
    "Token Calculation Breakdown": "토큰 계산 세부 정보",
    "{n}% of your total purchase value is allocated for purchasing {asset} tokens": "총 구매 가치의 {n}%가 {asset} 토큰 구매에 할당됩니다.",
    "Moonberg tokens will be distributed upon the official release of the Moonberg token.": "Moonberg 토큰은 Moonberg 토큰이 공식 출시되면 배포됩니다.",
    "Moonberg Allocation": "Moonberg 할당",
    "BTC Balance": "BTC 잔액",
    "The funds will be transferred via the BEP-20 BNB network.": "자금은 BEP-20 BNB 네트워크를 통해 이체됩니다.",
    "A bundle with minting power will generate BTC token rewards, which will be distributed every Monday.": "채굴 파워가 있는 번들은 매주 월요일에 배포되는 BTC 토큰 보상을 생성합니다.",
    "Weekly rewards expected": "주간 보상 예상",
    "Direct Sales Incentive": "직접 판매 인센티브",
    "You are about to activate your affiliate referral link.": "제휴 추천 링크를 활성화하려고 합니다.",
    "Upon activation, a period of free Product Autoship will be initiated starting from the date of your registration.": "활성화하면 등록일로부터 무료 제품 자동 배송 기간이 시작됩니다.",
    "If you confirm the activation, a mandatory monthly Product Autoship will be required to be eligible to receive commissions.": "활성화를 확인하면 커미션을 받을 자격을 얻으려면 의무적인 월별 제품 자동 배송이 필요합니다.",
    "Keep your product autoship active to maintain your commission eligibility for commission ranks Platinum and above.": "플래티넘 이상의 커미션 등급에 대한 커미션 자격을 유지하려면 제품 자동 배송을 활성화해 두세요.",
    "Buy Product Autoship": "제품 자동배송 구매",
    "Your current rank is achieved through qualification.": "귀하의 현재 순위는 자격을 통해 달성됩니다.",
    "Your commission rank is determined by your actual rank or a super bundle purchase—whichever is higher will be considered.": "귀하의 커미션 순위는 귀하의 실제 순위 또는 슈퍼 번들 구매에 따라 결정됩니다. 어느 쪽이 더 높은지가 고려됩니다.",
    "Your card has been deactivated due to an unpaid monthly subscription. For assistance or any questions, please reach out to our customer support team.": "귀하의 {card_name} 카드는 미납 월 구독으로 인해 비활성화되었습니다. 도움이 필요하거나 질문이 있으시면 고객 지원팀에 문의하세요.",

    "Subject": "제목",
    "topup": "충전",
    "withdraw": "인출",
    "failed": "실패",
    "3rd Anniversary": "3주년 기념",
    "3rd Anniversary Promo": "3주년 기념 프로모션",
    "Your current bonus amount": "현재 보너스 금액",
    "All products are eligible for the incentive, except for Product Autoship. Rewards will be distributed on November 1, 2024, and the bonus will be deposited directly into your internal wallet.": "제품 자동 배송을 제외한 모든 제품이 인센티브를 받을 수 있습니다. 보상은 2024년 11월 1일에 배포되며 보너스는 내부 지갑에 직접 입금됩니다.",























    // Affility translations
    "close": "닫기",
    "cancel": "취소",
    "yes_i_confirm": "예, 확인합니다",
    "something_went_wrong_try_again": "문제가 발생했습니다. 다시 시도하세요",
    "or": "또는",
    "success": "성공",
    "ok": "확인",
    "retry": "다시 해 보다",
    "home": "홈",
    "log_in": "로그인",
    "join_now": "지금 가입",
    "toggle_notifications": "알림 토글",
    "active": "활동중인",
    "inactive": "활동하지 않는",
    "product_autoship": "제품 멤버십",
    "balances": "잔액",
    "contract_addresses": "계약 주소",
    "address_add_to_wallet_success": "지갑에 주소가 성공적으로 추가되었습니다.",
    "language": "언어",
    "choose_language": "언어 선택",
    "profile": "프로필",
    "settings": "설정",
    "learn": "배우기",
    "help_center": "헬프 센터",
    "log_out": "로그아웃",
    "theme": "테마",
    "back": "뒤로",
    "forward": "앞으로",
    "previous": "이전",
    "next": "다음",
    "jump_to_top": "맨 위로 이동",
    "load_more": "로드 더하기",
    "proceed": "진행",
    "notifications": "알림",
    "notifications_loading_error_description": "디바이스가 인터넷에 연결되어 있는지 확인하거나 라우터 또는 모뎀을 다시 시작해보세요.",
    "notifications_empty_title": "알림 목록이 비어 있습니다.",
    "notifications_empty_description": "알림 기능으로 모든 업데이트와 알림을 한 곳에서 편리하게 추적하세요.",
    "email_verification_sent_title": "이메일 인증",
    "email_verification_sent_description": "등록을 완료할 수 있는 링크가 포함된 이메일을 보냈습니다.",
    "email_verification_required": "이메일 인증이 필요합니다.",
    "email_verification_confirm": "계속하려면 이메일을 확인해 주세요.",
    "email_verification_verify": "계속하려면 이메일을 인증하세요",
    "new_email": "새 이메일",
    "enter_new_email": "새 이메일 입력",
    "change_email": "이메일 변경",
    "resend_email": "이메일 다시 보내기",
    "upgrade_to_affiliate": "제휴사로 업그레이드",
    "upgrade_to_affiliate_success": "제휴사로 성공적으로 업그레이드되었습니다.",
    "become_affiliate": "제휴사 되기",
    "upgrade_to_affiliate_description_free_autoship": "제휴사 추천 링크를 활성화하려고 합니다. 활성화되면 등록한 날로부터 무료 제품 멤버십 기간이 시작됩니다.",
    "upgrade_to_affiliate_description_mandatory_autoship": "제휴 추천 링크를 활성화하려고 합니다. 활성화를 확인하면 커미션을 받으려면 필수 월 제품 멤버십 요금을 지불해야 합니다.",
    "t&c": "이용 약관",
    "accept_t&c": "수락 및 닫기",
    "affiliate_t&c": "제휴 이용 약관",
    "cards_t&c": "카드 이용 약관",
    "i_have_read_and_accept_the_{prop}": "나는 제안을 읽었고 수락합니다",
    "switch_language": "언어 전환",
    "switch_theme": "테마 전환",
    "enjoy_more_benefit_more": "더 많이 즐기세요! 더 많은 혜택을 누리세요!",
    "buy_products_at_least_${n}": "최소 ${n}에 제품을 구매하세요",
    "go_to_products": "제품으로 이동",
    "free_autoship": "플래티넘 이상 무료 제품 자동 배송",
    "{n}%_binary_commission": "{n}% 바이너리 커미션",
    "rank": "랭크",
    "role": "역할",
    "bundle": "번들",
    "current_rank": "현재 등급",
    "current_role": "현재 역할",
    "customer": "고객",
    "get_your_bundle": "번들을 받으세요",
    "nft_turbo_expires_in": "슈퍼 번들 특별 할인이 만료됩니다.",
    "left_leg_link": "왼쪽 다리 링크",
    "right_leg_link": "오른쪽 다리 링크",
    "sponsor_id": "스폰서 ID",
    "total_income": "총 수입",
    "current_cv_balance": "현재 CV 잔액",
    "progress_until_rank_{n}": "{n} 등급까지 진행률",
    "to_reach_rank_{n}_you_need": "{n}등급에 도달하려면 필요한 것 ",
    "nft_own_purchases": "NFT 자체 구매",
    "direct_partner_own_purchases": "직접 파트너 자체 구매",
    "team_turnover": "팀 이직률",
    "required_qualified_partners": "필수 적격 파트너",
    "see_details": "자세히 보기",
    "soon": "곧",
    "new": "새로운",
    "promos": "프로모션",
    "coming_soon": "곧 출시 예정",
    "team_statistics": "팀 통계",
    "all_time": "모든 시간",
    "weekly": "매주",
    "monthly": "매달",
    "yearly": "매년",
    "left_leg": "왼쪽 다리",
    "right_leg": "오른쪽 다리",
    "all_partners": "모든 파트너",
    "direct_partners": "직접 파트너",
    "all_active_partners": "모든 활동 파트너",
    "all_inactive_partners": "모든 비활동 파트너",
    "become_affiliate_partner": "제휴 파트너 되기",
    "gain_fresh_perspectives": "새로운 관점과 독점적 인 혜택을 얻으십시오.",
    "autoship_required_to_be_affiliate": "제휴 파트너가 되어 커미션을 받으려면 월별 제품 멤버십이 필요합니다.",
    "for_limited_time_only": "제한된 기간 동안만",
    "placement_in_unilevel": "유니레벨에 배치",
    "placement_in_binary": "바이너리로 배치",
    "cv_points": "CV 포인트",
    "matching_bonuses": "매칭 보너스",
    "leadership_incentives": "리더십 인센티브",
    "autoship_incentive": "제품 멤버십 인센티브",
    "binary_tree_statistics": "바이너리 트리 통계",
    "unilevel_tree_statistics": "유니레벨 트리 통계",
    "active_partners": "활성 파트너",
    "inactive_partners": "비활성 파트너",
    "The minimum amount should be equal to or greater than NUM GYMNET": "최소 금액은 {num} GYMNET 이상이어야 합니다.",
    "select_the_team": "팀 선택",
    "new_users_will_be_placed_under_username_in_the_left_team": "새로운 사용자는 {LEG} 팀의 {USERNAME} 아래에 배치됩니다.",
    "done": "완료",
    "right": "오른쪽",
    "left": "왼쪽",
};
