export default {
    "Full": "フル",
    "Standard": "標準",
    "Attach miners": "マイナーを設置する",
    "Upgraded Parcel": "アップグレードされた区画",
    "Discount": "割引",
    "save": "保存",
    "Copied": "コピー済み",
    "Marketplace": "マーケットプレイス",
    "Contract Address": "契約アドレス",
    "Wallet Address": "ウォレットアドレス",
    "Inland": "内陸",
    "Business": "仕事",
    "parcels": "小包",
    "parcel": "小包",
    "Ocean": "海洋",
    "Near Ocean": "海の近く",
    "Upgraded": "アップグレード",
    "Learn how it works": "仕組みを学ぶ",
    "Buy parcel": "小包を購入する",
    "Wallets": "財布",
    "To GymNetwork": "ジムネットワークへ",
    "Connect Wallet": "ウォレットを接続",
    "Pending rewards": "保留中の報酬",
    "Coming Soon": "近日公開",
    "Claim": "請求",
    "Inventory": "在庫",
    "Lands": "土地",
    "Miners": "鉱夫",
    "Attach": "取り付ける",
    "Attach to parcel": "小包に添付",
    "Investment": "投資",
    "Wallet": "財布",
    "Your address": "あなたの住所",
    "History": "歴史",
    "Map": "地図",
    "Buy miner": "マイナーを購入",
    "Buy Miner": "マイナーを購入",
    "Get ": "得る",
    "Get Miner": "マイナーを取得",
    "Upgrade parcel": "区画のアップグレード",
    "Soon": "後で",
    "Upgraded land has 10 slots for putting miner NFTs. Upgraded land does not have more physical space, it’s only that you can stake more miners.": "アップグレードされた土地には、マイナー NFT を置くための 10 のスロットがあります。アップグレードされた土地には物理的なスペースはありません。より多くのマイナーをステークできるというだけです。",
    "Next": "次",
    "Back": "戻る",
    "Skip guide": "ガイドをスキップ",
    "Number of miners you want to buy": "購入したいデュアルマイナーの数",
    "Attach Miner": "アタッチマイナー",
    "Upgrade for": "のアップグレード",
    "On Sale": "発売中",
    "Type": "タイプ",
    "Location": "位置",
    "Size": "サイズ",
    "Buy now": "今買う",
    "Email": "Eメール",
    "Username": "ユーザー名",
    "Verify Email": "Eメールを確認します",
    "Index": "索引",
    "Verification Code": "検証コード",
    "Log out": "ログアウト",
    "Select all": "すべて選択",
    "Cart": "カート",
    "Total": "合計",
    "Register": "登録",
    "Dashboard": "ダッシュボード",
    "Transaction success!": "取引成功！",
    "I`ve read Terms & Conditions": "利用規約を読みました",
    "Terms & conditions": "利用規約",
    "Affiliate Terms & Conditions": "アフィリエイト利用規約",
    "Accept Terms & Conditions": "利用規約に同意する",
    "Loading! Please Wait ...": "読み込んでいます！お待ちください ...",
    "Initializing Wallet! Please Wait ...": "ウォレットを初期化しています!お待ちください ...",
    "Login In Progress! Please Wait ...": "ログイン中！お待ちください ...",
    "Enter your email": "メールアドレスを入力",
    "Confirm your Email": "メールを確認する",
    "Enter username": "ユーザーネームを入力してください",
    "To dashboard": "ダッシュボードへ",
    "You successfully verified your email": "メールの確認に成功しました",
    "View map": "地図を見ます",
    "Log in to buy": "ログインして購入します",
    "Congratulations": "おめでとう",
    "Buy": "買う",
    "Miner": "マイナー",
    "Parcel": "小包",
    "Upgrade": "アップグレード",
    "Load more": "もっと読み込む",
    "All": "全て",
    "Basic": "基本",
    "Activate": "活性化",
    "Basic Miner": "基本マイナー",
    "In use": "使用中で",
    "Expired": "期限切れ",
    "Available": "利用可能",
    "Standard parcel": "標準小包",
    "Success": "成功",
    "Something Went Wrong": "何かがうまくいかなかった",
    "Loading": "読み込み中",
    "Invalid Verification Code": "無効な検証コード",
    "Invalid Email Address": "無効な電子メールアドレス",
    "Invalid Arguments Supplied": "無効な引数が指定されました",
    "Email Already Exists": "メールは既に存在します",
    "UnAuthorized": "無許可",
    "User Update Failed": "ユーザーの更新に失敗しました",
    "Permission Denied": "アクセス拒否",
    "Basic miner": "基本マイナー",
    "Remove": "削除する",
    "Special offers": "特別オファー",
    "Please Wait": "お待ちください",
    "Transaction Rejected": "取引拒否",
    "sold": "売った",
    "The parcel price will be increased after certain percentage of parcels will be sold. Bundle prices will be increased accordingly.": "一定の割合の区画が販売されると、区画の価格が引き上げられます。バンドルの価格はそれに応じて増加します。",
    "Sold": "売却",
    "Parcel price index": "小包価格指数",
    "Current parcel price": "現在の小包価格",
    "Parcels sold": "販売された小包",
    "Parcel stats": "小包統計",
    "Help center": "ヘルプセンター",
    "Partner area": "パートナーエリア",
    "to_dashboard": "ダッシュボードへ",
    "Transaction Success": "取引成功",
    "parcels available": "利用可能な小包",
    "Apply": "申し込み",
    "Insufficient USDT amount": "不十分な USDT 額",
    "Insufficient {asset} amount": "不十分な {asset} 額",
    "Miner Rewards": "マイナー報酬",
    "No data found": "何もデータが見つかりませんでした",
    "Parcel name": "「区画名」",
    "Basic Bundle": "基本バンドル",
    "4 Parcels Bundle": "4 Parcels Bundle",
    "10 Parcels Bundle": "10 小包バンドル",
    "40 Parcels Bundle": "40 小包バンドル",
    "80 Parcels Bundle": "80 小包バンドル",
    "140 Parcels Bundle": "140 小包バンドル",
    "240 Parcels Bundle": "240 小包バンドル",
    "4 Basic Miner Bundle": "4 マイナーバンドル",
    "10 Basic Miner Bundle": "10 マイナーバンドル",
    "40 Basic Miner Bundle": "40 マイナーバンドル",
    "80 Basic Miner Bundle": "80 マイナーバンドル",
    "140 Basic Miner Bundle": "140 マイナーバンドル",
    "240 Basic Miner Bundle": "240 マイナーバンドル",
    "Hashpower": "Hashpower",
    "Something went wrong, please refresh the page and try again": "何かが間違っていました。ページを更新してもう一度お試しください",
    "Gallery": "ギャラリー",
    "Accepted": "受け入れ",
    "Pending": "保留中",
    "Upload": "アップロード",
    "Uploading image": "画像をアップロードしています",
    "Something went wrong, try again later": "何かが間違っていました。後でもう一度お試しください",
    "You need to be authenticated to upload images to Gallery.": "ギャラリーに画像をアップロードするには、認証する必要があります。",
    "Assign image": "画像を割り当てる",
    "Assign image to parcels": "イメージを区画に割り当てる",
    "Remove current image": "現在の画像を削除します",
    "Change image": "画像を変更します",
    "Assign to parcels": "小包に割り当てます",
    "Reselect image assignment area?": "画像割り当て領域を再選択しますか？",
    "You have not applied changes. Do you want to reselect image assignment area?": "変更を適用していません。画像割り当て領域を再選択しますか？",
    "Image has been successfully changed": "画像は正常に変更されました",
    "Image has been successfully assigned to parcels": "画像は小包に正常に割り当てられています",
    "Image has been successfully removed from the parcels": "画像は小包から正常に削除されました",
    "Yes": "はい",
    "No": "いいえ",
    "Cancel": "キャンセル",
    "You have no images": "画像がありません",
    "Notifications": "「お知らせ",
    "No notifications": "「通知なし",
    "year ago": "一年前",
    "months ago": "数か月前",
    "month ago": "ひと月前",
    "weeks ago": "何週間前",
    "week ago": "一週間前",
    "days ago": "数日前",
    "day ago": "「前日」",
    "hours ago": "時間前",
    "hour ago": "一時間前",
    "minutes ago": "数分前",
    "minutes": "分",
    "minute": "分",
    "seconds": "秒",
    "second": "2番目",
    "Your image was approved": "画像が承認されました",
    "Your image was rejected": "画像が拒否されました",
    "New": "新しい",
    "Gallery guide" : "ギャラリーガイド",
    "Go to gallery": "ギャラリーに行きます",
    "How it works": "使い方",
    "Removing image from parcels..." : "区画から画像を削除します...",
    "Removing image" : "画像を削除します",
    "Image has been successfully removed" : "画像は正常に削除されました",
    "Wrong file size" : "間違ったファイルサイズ",
    "Please upload image files with size less than 10MB": "10MB未満のサイズの画像ファイルをアップロードしてください",
    "Drag to Reposition": "ドラッグして再配置します",
    "Pinch or use the mouse wheel to zoom in or out": "ピンチまたはマウスホイールを使用してズームインまたはアウトします",
    "Drag and move the image with fingers or using your mouse to select area": "指で画像をドラッグして移動するか、マウスを使用して領域を選択します",
    "It can take up to 48 hours for the image to be approved. You can view your images awaiting approval in the Pending tab.": "画像が承認されるまでに最大48時間かかる場合があります。保留中のタブで承認を待っている画像を表示できます。",
    "Delete Image": "画像を削除します",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable. Land containing this image will also be cleared.": "ギャラリーから画像を永久に削除したいですか？この操作は回復できません。この画像を含む土地もクリアされます。",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable." : "ギャラリーから画像を永久に削除したいですか？この操作は回復できません。",
    "Wrong File Type": "間違ったファイルタイプ",
    "Please upload jpg/jpeg image files": "JPG/JPEG画像ファイルをアップロードしてください",
    "Upload Images to Your Gallery": "ギャラリーに画像をアップロードします",
    "You can upload images to your gallery from your computer, mobile phone or tablet. Currently only jpg/jpeg image file types are supported. Press the upload button or simply drag and drop the image from computer to upload your image. Image size must be up to 10 MB." : "コンピューター、携帯電話、タブレットからギャラリーに画像をアップロードできます。現在、JPG/JPEG画像ファイルのタイプのみがサポートされています。アップロードボタンを押すか、コンピューターから画像をドラッグしてドロップして画像をアップロードします。画像サイズは最大10 MBでなければなりません。",
    "Upload image": "画像をアップロードします",
    "Image Approval": "画像の承認",
    "Uploaded images need to be approved before they appear in the approved section of your gallery. This procedure is necessary to filter out inappropriate, explicit content and copyrighted material. Approval can take up to 48 hours. You'll get notified about the verdict whenever you're online." : "アップロードされた画像は、ギャラリーの承認されたセクションに表示される前に承認する必要があります。この手順は、不適切で明示的なコンテンツと著作権で保護された資料を除外するために必要です。承認には最大48時間かかる場合があります。あなたがオンラインであるときはいつでも、評決について通知されます。",
    "Approval": "承認",
    "Select Your Land to Assign Image to": "画像を割り当てる土地を選択します",
    "You can assign your uploaded images to your owned land. To do so, simply select the parcels by dragging mouse on the land part you want to assign an image to. Please note that image can only be assigned to a rectangular area that is owned by you only." : "アップロードされた画像を自分の土地に割り当てることができます。そのためには、画像を割り当てたい土地部分にマウスをドラッグして、小包を選択するだけです。画像は、あなたのみが所有する長方形の領域にのみ割り当てることができることに注意してください。",
    "Land Selection": "土地の選択",
    "Crop Image": "クロップ画像",
    "You can crop a custom part of your image that you want to assign to your land section. You can close in to any area of your image and crop it by dragging the image with mouse or your finger if you're on a mobile device. You can also zoom in and out by using mouse wheel or pinch gesture." : "土地に割り当てたい画像のカスタム部分をトリミングできます。モバイルデバイスを使用している場合は、画像のあらゆる領域に閉じて、マウスまたは指で画像をドラッグすることでトリミングできます。マウスホイールまたはピンチジェスチャーを使用してズームインおよびアウトすることもできます。",
    "Activate multiple selection": "複数の選択をアクティブにします",
    "Exit multiple selection": "複数の選択を終了します",
    "Owned": "所有",
    "Owned by": "が所有している",
    "Multiple types": "複数のタイプ",
    "seconds ago": "数秒前",
    "Super Bundle": "スーパーバンドル",
    "Balance": "バランス",
    "View your purchased Bundles and claim to have them from your balance": "購入したバンドルを表示し、残高から持っていると主張する",
    "Feel free to choose lands on Map.": "マップ上の土地を自由に選択してください。",
    "Earn money by using miners for generating rewards.": "報酬を生成するためにマイナーを使用してお金を稼ぎます。",
    "Upgrade your owned lands from 4 slots to 10.": "所有する土地を 4 スロットから 10 スロットにアップグレードします。",
    "Confirm": "確認",
    "You don`t have any available parcels in your inventory": "在庫に利用可能な小包がありません",
    "Months": "月",
    "Super Bundle R6": "スーパーバンドル R6",
    "Super Bundle R10": "スーパーバンドル R10",
    "Super Bundle R15": "スーパーバンドル R15",
    "Super Bundle R20": "スーパーバンドル R20",
    "Super Bundle R22 S": "スーパーバンドル R22 S",
    "Super Bundle R22 M": "スーパーバンドル R22 M",
    "Super Bundle R22 L": "スーパーバンドル R22 L",
    "Super Bundle R22 XL": "スーパーバンドル R22 XL",
    "Super Bundle R22 XXL": "スーパーバンドル R22 XXL",
    "1 Claim your land": "1 自分の土地を主張する",
    "2 Claim your miner": "2 マイナーを要求する",
    "3 Upgrade your land": "3 土地をアップグレードする",
    "Your NFT balance": "あなたのNFT残高",
    "Next price changes in": "次の価格変更まで",
    "Parcels": "小包",
    "Get to know the platform usage": "プラットフォームの使用状況を知る",
    "What is a Parcel?": "パーセルとは？",
    "Land is a unit of area on the GYM Street map and it is also an NFT. You can own as much land on GYM Street as you want.": "ランドはGYMストリートマップ上の面積の単位であり、NFTでもあります。 GYMストリートの土地は好きなだけ所有できます。",
    "What is a Miner?": "マイナーとは？",
    "A miner is what generates income for you at GYM Street. You can attach up to 4 miners on each of your standard land parcels.": "マイナーは、GYM Street であなたに収入をもたらすものです。各標準土地区画に最大 4 人の採掘者を配置できます。",
    "Miner rewards": "マイナー報酬",
    "My Miners": "私の鉱夫",
    "My Hashpower": "私の Hashpower",
    "Total Claimed": "請求合計",
    "Total pending rewards": "保留中の報酬の合計",
    "Claim all": "すべてを主張",
    "Daily rewards": "毎日の報酬",
    "Weekly rewards": "ウィークリー報酬",
    "Monthly rewards": "毎月の報酬",
    "Yearly rewards": "年間報酬",
    "ROI": "ROI",
    "ROI Calculator": "ROI計算機",
    "Global Statistics": "グローバル統計",
    "Global Hashpower": "グローバル Hashpower",
    "Your Shares": "あなたの株",
    "Daily Global Rewards": "毎日のグローバル報酬",
    "Price": "価格",
    "Total Minted": "合計鋳造",
    "total": "合計",
    "standard": "標準",
    "upgraded": "アップグレード",
    "active": "アクティブ",
    "inactive": "非活性",
    "Attach miner": "マイナーをアタッチ",
    "Total Miners": "総マイナー",
    "Total Minted Rewards": "合計鋳造報酬",
    "What is parcel and how to use it?": "パーセルは、ミント機能を備えた {metaverse} マップ上のエリア単位です。 区画を所有して収入を生み出します。",
    "What is a miner and how to use it?": "The Miner は、{metaverse} の収入源です。 鉱夫をパーセルに追加して収益を高めます。 <br/> <br/> GYMNET Miner と Dual Miner はどちらも 1,000 Mh/s のマイニング速度で動作します。",
    "Your active/inactive hashpower, 1 miner = 1,000 Mh/s, your Miners 0 x 1,000Mh/s = 0.00": "アクティブ/非アクティブ hashpower、1 マイナー = 1,000 Mh/s、マイナー 0 x 1,000Mh/s = 0.00",
    "The amount of the cumulated claimed rewards": "累積請求報酬の金額",
    "Your current available pending rewards.": "現在利用可能な保留中の報酬。",
    "Congratulations!": "おめでとう！",
    "Upgrade your parcels to mine even more?": "区画をアップグレードしてさらにマイニングしますか?",
    "You have NUM parcels that are not upgraded.": "アップグレードされていない\n {parcels} 個の区画があります。",
    "Go to Map": "マップへ",
    "You're mining less than the average user.": "あなたは平均的なユーザーよりも少ないマイニングを行っています。",
    "Purchase more miners to improve your results": "より多くのマイナーを購入して、結果を改善してください",
    "Buy more miners": "マイナーをさらに購入する",
    "Thanks, I'll stay below average": "ありがとう、私は平均以下にとどまります",
    "You aren't mining at full speed.": "あなたは全速力で採掘していません。",
    "Do you want to buy more parcels now ?": "今すぐさらに小包を購入しますか?",
    "I definitely do": "私は間違いなくそうします",
    "No, I'll let my miners to rust": "いいえ、鉱山労働者を錆びさせます",
    "You have no miners": "マイナーはいません",
    "Buy miners": "デュアルマイナーを購入する",
    "You haven't upgradeable parcels": "アップグレード可能な区画がありません",
    "You haven't inactive miners": "非アクティブなマイナーはいません",
    "Close": "近い",
    "Read more": "続きを読む",
    "Drag and drop an image, or Browse": "画像をドラッグ アンド ドロップするか、<label class=\"browse-button\" for=\"upload-image\">参照</label>",
    "Browse an image": "<label class=\"browse-button\" for=\"upload-image\">ブラウズ</label> 画像",
    "Drop to upload": "ドロップしてアップロードします",
    "File not supported": "サポートされていないファイル",
    "Upload your parcel image": "小包画像をアップロードします",
    "Up to 10MB, jpg/jpeg": "最大10MB、jpg/jpeg",
    "High resolution images, should align with your land shape (you can crop after upload)": "<span class=\"bold-info\">高解像度の画像</span>は、土地の形状に合わせてください (アップロード後にトリミングできます)",
    "Same image may be used on different lands owned by the user": "<span class=\"bold-info\">ユーザーが所有する異なる土地で同じ画像を使用することができます</span>",
    "No violence, offensive, graphic or 18+ content": "暴力、攻撃的、生々しい、<span class=\"bold-info\">18 歳以上のコンテンツ</span>の禁止",
    "No third-party copyrighted content, no logos or screenshots from Gymstreet and Gymnetwork": "<span class=\"bold-info\">サードパーティの著作権で保護されたコンテンツの禁止</span> <span class=\"bold-info\">Gymstreet および Gymnetwork</span> のロゴやスクリーンショットの禁止",
    "No images already used by another user, no images from DeFi listing website images": "<span class=\"bold-info\">別のユーザーが既に使用している画像はありません</span>。DeFi リスト Web サイトの画像からの画像はありません",
    "No religious images": "<span class=\"bold-info\">宗教的なイメージはありません</span>",
    "Unlock miners by purchasing at least 1 land parcel": "少なくとも 1 つの土地区画を購入して、鉱夫のロックを解除します",
    "OK": "わかった",
    "Your parcels": "あなたの小包",
    "Your miners": "あなたの鉱夫",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcels to start mining": "あなたは全速力で採掘していません。採掘を開始するには、区画に {miners} 人の採掘者を取り付ける必要があります",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcel to start mining": "あなたは全速力で採掘していません。採掘を開始するには、区画に {miners} 人の採掘者を取り付ける必要があります",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcels to start mining": "あなたは全速力で採掘していません。採掘を開始するには、{miners} 人の採掘者を区画に取り付ける必要があります",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcel to start mining": "あなたは全速力で採掘していません。採掘を開始するには、区画に {miners} 人の採掘者を取り付ける必要があります",
    "Info Center": "インフォセンター",
    "Standard Parcel": "標準的な小包",
    "You have no parcels": "荷物がありません",
    "Single Products": "単品",
    "Parcels sold / Total parcels": "販売された小包 / 総小包",
    "You can mine even more. Purchase more miners to improve your results": "あなたはさらに多くを採掘することができます。 結果を改善するために、より多くのマイナーを購入する",
    "In Progress": "進行中",
    "Active": "アクティブ",
    "Learn More": "もっと詳しく知る",
    "We just released a new update on Gym Street. Head over to the Release Notes page to learn more.": "ジム ネットワークの新しいアップデートをリリースしました。 詳細については、リリース ノート ページにアクセスしてください。",
    "Add to wallet": "ウォレットに追加します",
    "Your Balance": "あなたのバランス",
    "The Promotion starts in:": "プロモーション開始まで:",
    "Please go to your wallet, which you just connected, and verify your login by confirming the requested transaction. It's free of charge!": "接続したばかりのウォレットに移動し、要求されたトランザクションを確認してログインを確認してください。 無料です！",
    "Contract addresses": "契約住所",
    "Add TOKEN to wallet": "{token} をウォレットに追加",
    "I accept the terms and conditions": "利用規約に同意します",
    "Log In": "ログイン",
    "Sign Up": "サインアップ",
    "Connect My Wallet": "私の財布を接続します",
    "Change Email": "メールアドレスを変更",
    "Reset Password": "パスワードを再設定する",
    "Your password has been changed.": "あなたのパスワードは変更されました。",
    "Please log in to your account.": "アカウントにログインしてください。",
    "Confirm Email": "Eメール確認",
    "Emails don't match": "メールは一致しません",
    "Or": "または",
    "Sign Up With Email": "メールでサインアップしてください",
    "Already have an account?": "すでにアカウントをお持ちですか？",
    "Recover": "回復",
    "Enter New Password": "新しいパスワードを入力してください",
    "Confirm New Password": "新しいパスワードを確認",
    "Password": "パスワード",
    "Forgot password?": "パスワードをお忘れですか？",
    "Password is required": "パスワードが必要です",
    "Password confirmation is required": "パスワードの確認が必要です",
    "Passwords do not match": "パスワードが一致していません",
    "Password must contain at least one uppercase letter, one lowercase letter and one number": "パスワードには、少なくとも1つの大文字、1つの小文字、1つの番号が含まれている必要があります",
    "Password must be at least 8 characters": "パスワードは8文字以上でなければなりません",
    "Password must not exceed 24 characters": "パスワードは24文字以内",
    "Password must contain at least one special character": "パスワードには、少なくとも1つの特殊文字が含まれている必要があります",
    "Password cannot contain spaces": "パスワードにはスペースを含めることはできません",
    "Email is required": "メールが必要です",
    "Email confirmation is required": "確認の確認が必要です",
    "Please enter a valid email": "正しいメールアドレスを入力してください",
    "Step NUM1 of NUM2": "ステップ {num1}/{num2}",
    "Create Password": "パスワードの作成",
    "Get Started": "始めましょう",
    "Create a Wallet": "ウォレットを作成する",
    "Confirm on this screen": "この画面で確認",
    "Pick a password on the next step. This needs to be at least 8 symbols long.": "次のステップでパスワードを選択します。 これは、少なくとも 8 シンボルの長さである必要があります。",
    "Wallet Ready": "ウォレット対応",
    "Add Binance Smart Chain": "Binance Smart Chain を追加",
    "Click on the button below to add Binance Smart Chain Network to your MetaMask wallet.": "下のボタンをクリックして、Binance Smart Chain ネットワークを MetaMask ウォレットに追加します。",
    "Confirm the connection request": "接続要求を確認する",
    "Get Started!": "始めましょう！",
    "Secure Wallet": "安全なウォレット",
    "Confirm your password to continue.": "パスワードを確認して続行します。",
    "You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.": "これは、コンピューターにアクセスできなくなった場合にウォレットを復元するために必要になるため、安全な場所に保管する必要があります。 あなたの 12 語のバックアップ フレーズにアクセスできる人は誰でもあなたの資金を取り戻すことができるので、秘密にしておいてください。",
    "You have successfully set up Your wallet.Welcome to the world of WEB3!": "ウォレットの設定が完了しました。\nWEB3 の世界へようこそ!",
    "Confirm Password": "パスワードを認証する",
    "Install here.": "ここにインストールします。",
    "Something went wrong, please try verifying your email again.": "何かがうまくいかなかったので、もう一度メールを確認してみてください。",
    "Looks like your password reset link has expired. Please try again.": "パスワードリセットリンクの有効期限が切れているようです。もう一度やり直してください。",
    "Looks like your password reset link has already been used. Please request it again.": "パスワードリセットリンクはすでに使用されているようです。もう一度リクエストしてください。",
    "Couldn't recover password": "パスワードを回復できませんでした",
    "Couldn't reset your password.": "パスワードをリセットできませんでした。",
    "Couldn't login.": "ログインできませんでした。",
    "Couldn't sign you up.": "サインアップできませんでした。",
    "Resend Email": "メールを再送",
    "Couldn't resend confirmation email.": "確認メールを再送信できませんでした。",
    "Provided email is already in use.": "提供されているメールが既に使用されています。",
    "You have already verified your email address": "あなたはすでにあなたのメールアドレスを確認しています",
    "Invalid or expired verification code": "無効または期限切れ検証コード",
    "An error occurred while logging in. Please try again.": "ログイン中にエラーが発生しました。もう一度やり直してください。",
    "We sent an email to {EMAIL} with a link to finalize your registration": "登録を完成させるためのリンクを{EMAIL}に電子メールを送信しました",
    "We sent an email to {EMAIL} with a link to change your password": "パスワードを変更するためのリンクを使用して{EMAIL}にメールを送信しました",
    "Referral ID": "紹介ID",
    "On the next step, tap on 'Create a new wallet'.": "次のステップで、\n\"Create a new wallet\"をタップします。",
    "On the next step, tap on 'Create a wallet'.": "次のステップで、\"Create a wallet\" をタップします。",
    "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower case letters, symbols and numbers.": "他の場所では使用されていない完全に一意のパスワードを使用することをお勧めします。また、上限と小文字、記号、数字の混合物を含むことをお勧めします。",
    "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.": "前のステップで記憶された言葉を整理してください。バックアップが完了したら、\"Complete\"ボタンをタップします。",
    "On this step you can choose. We recommend you do this now as you might otherwise lose access to your wallet. Tap on 'Start' to continue.": "このステップで選択できます。 \nウォレットにアクセスできなくなる可能性があるため、今すぐこれを行うことをお勧めします。 \n\"Start\"をタップして続行します。",
    "On this step tap on 'Start' to continue.": "このステップで \"Start\" をタップして\n続行します。",
    "Tap on the burger menu of the app in the top left corner of the screen.": "画面の左上隅にあるアプリのハンバーガーメニューをタップします。",
    "Next, tap on Settings in the menu to the left.": "次に、左側のメニューで\"Settings\"をタップします。",
    "Next, tap on Networks.": "次に、\"Networks\" をタップします。",
    "Add More Tokens": "トークンを追加します",
    "In the search field, type in BNB Smart Chain and add it to your network.": "検索フィールドに \"BNB Smart Chain\" と入力し、ネットワークに追加します。",
    "Next, select Custom Token. Paste the address of the smart contract you copied into the token address field. In the Token Symbol field, type SP, and in the Token Decimal, put 0. Then click the 'Import' button to complete your setup.": "次に\"Custom Token\"を選択します。 コピーしたスマート コントラクトのアドレスを \"Token Address\" フィールドに貼り付けます。 \"Token Symbol\" フィールドに SP と入力し、\"Token Decimal\" に 0 を入力します。\"Import\" ボタンをクリックしてセットアップを完了します。",
    "Add Network": "ネットワークを追加します",
    "Give permission to the platform to add a network.": "ネットワークを追加するためのプラットフォームに許可を与えます。",
    "Extra PERCENT% Discount": "追加{PERCENT}%割引",
    "Tap on the 'Import Tokens' link to add a Custom Token.": "\"Import Tokens\"リンクをタップして、カスタムトークンを追加します。",
    "Click on the button 'Approve' to allow the connection request.": "\"Approve\"ボタンをクリックして、接続要求を許可します。",
    "Confirm Secret Phrase": "秘密のフレーズを確認します",
    "Click on the button 'Switch network' to allow the connection request.": "\"Switch network\"ボタンをクリックして、接続要求を許可します。",
    "Payment Method": "支払方法",
    "Select cryptocurrency": "暗号通貨を選択します",
    "Please use an Ethereum BEP-20 address.": "Ethereum EBEP-20アドレスを使用してください。",
    "Connect to Gymstreet": "Gym Streetに接続",
    "Now to access the Gym Street Metaverse, you need to open it with the MetaMask browser. Go to your MetaMask wallet app, choose 'Browser' from the side navigation menu, search for Gym Street in the browser tab.": "Gym Street Metaverse にアクセスするには、MetaMask ブラウザで開く必要があります。 MetaMask ウォレット アプリに移動し、サイド ナビゲーション メニューから \"Browser\" を選択し、ブラウザ タブで Gym Street を検索します。",
    "First, let's set up your cryptocurrency wallet.": "まず、暗号通貨ウォレットをセットアップしましょう。",
    "Follow the next steps to continue the setup.": "次の手順に従って、セットアップを続行します。",
    "Your balance was updated": "あなたの残高が更新されました",
    "Once installed, you should see the below splash screen. Click the 'Get Started' button to begin creating your Ethereum wallet using MetaMask.": "インストールしたら、以下のスプラッシュ画面が表示されます。\"Get Started\" ボタンをクリックして、メタマスクを使用してイーサリアムウォレットの作成を開始します。",
    "You will then be asked if you want to help improve MetaMask. Click 'No, Thanks' if this doesn't interest you, otherwise click 'I agree'.": "次に、MetaMaskの改善に協力するかどうかを尋ねられます。 興味がない場合は\"No, Thanks\"をクリックしてください。 それ以外の場合は、\"I agree\" をクリックします。",
    "MetaMask will then present you with your 12-word backup phrase. You will need to write this down in the same order displayed on your screen.": "MetaMask は、12 語のバックアップ フレーズを提示します。画面に表示されているのと同じ順序でこれを書き留める必要があります。",
    "After Downloading and opening MetaMask, tap on 'Get Started'.": "メタマスクをダウンロードして開いた後、\"Get Started\"をタップします。",
    "Improve Metamask": "MetaMask を改善する",
    "Congratulations! You have set up your MetaMask wallet. Confirm the next few pop-ups on the screen to access your wallet.": "おめでとう！MetaMask ウォレットをセットアップしました。画面上の次のいくつかのポップアップを確認して、ウォレットにアクセスします。",
    "Import Binance Smart Chain": "Binance Smart Chain をインポート",
    "Next, copy the NFT Smart Contract by tapping on the button below.": "次に、下のボタンをタップしてNFT Smart Contractをコピーします。",
    "Copy NFT Contract": "NFT Contractをコピー",
    "Install MetaMask": "MetaMask をインストール",
    "Install MetaMask here.": "MetaMask をここにインストールします。",
    "Guide for MetaMask Wallet connection process.": "メタマスクウォレット接続プロセスのガイド。",
    "MetaMask will then present you with your 12-word backup phrase.": "MetaMask は、12 語のバックアップ フレーズを提示します。",
    "MetaMask Secret Phrase": "MetaMask  秘密のフレーズ",
    "Copy NFT Smart Contract": "NFT Smart Contractをコピー",
    "You are almost finished with the MetaMask setup process. Just click 'All done' on the last page, and you will be automatically logged into MetaMask. If you ever log out, you can log back in by clicking on the icon added to your web browser (usually, it's next to the URL bar).": "MetaMask のセットアップ プロセスはほぼ完了です。 最後のページで\"All done\"をクリックするだけで、MetaMask に自動的にログインされます。 ログアウトした場合は、Web ブラウザに追加されたアイコン (通常は URL バーの横にあります) をクリックして再度ログインできます。",
    "Your payment processing is complete. Your NFT balance has been updated!": "결제 처리가 완료되었습니다. NFT 잔액이 업데이트되었습니다!",
    "Your payment is received but is incomplete. Please pay AMOUNT CURRENCY more to complete the payment.": "あなたの支払いは受領されましたが、不完全です。 支払いを完了するには、さらに {amount} {currency} をお支払いください。",
    "Terms & Conditions": "利用規約",
    "Email Verification": "メール認証",
    "Transaction history": "取引履歴",
    "Order history": "注文履歴",
    "Product": "製品",
    "Quantity": "量",
    "Payment UID": "支払いUID",
    "Status": "スターテス",
    "Currency": "通貨",
    "Autoship":"オートシップ",
    "Amount": "額",
    "Date": "日にち",
    "Tx ID": "Tx ID",
    "Completed": "完了しました",
    "MetaMask is not installed": "MetaMask がインストールされていません",
    "If you have already installed MetaMask, please": "{MetaMask} を既にインストールしている場合は、",
    "refresh page": "ページの更新",
    "to continue.": "続ける。",
    "You have successfully installed MetaMask. Now you can add a Network.": "MetaMask が正常にインストールされました。 これで、ネットワークを追加できます。",
    "You already have this network": "あなたはすでにこのネットワークを持っています",
    "Continue": "続く",
    "Finalize Registration": "登録の確定",
    "Please confirm your email to continue": "継続するためにメールを確認してください",
    "New email must be different from current email": "新しい電子メールは、現在のメールとは異なる必要があります",
    'Failed to add address to your wallet': 'ウォレットにアドレスを追加できませんでした',
    "Actions": "行動",
    "Request to change the chain has been rejected": "チェーンを変更するリクエストは拒否されました",
    "Switch Chain": "スイッチチェーン",
    "Choose your Metaverse": "メタバースを選択してください",
    "You need to choose your Metaverse to continue. Maps, Dashboard and My Products information will vary depending on your selection. Currently {METAVERSE} Metaverse is selected.": "続行するには、メタバースを選択する必要があります。 マップ、ダッシュボード、およびマイ製品の情報は、選択内容に応じて異なります。 現在、{METAVERSE} メタバースが選択されています。",
    "Can't check your purchases": "購入を確認できません",
    "Attach {COUNT} miners": "{COUNT} 人のマイナーを接続します",
    "Attach 1 miner": "マイナーを1人付ける",
    "View on map": "地図で見る",
    "Buy {COUNT} parcels": "{COUNT} 個の荷物を購入する",
    "Buy 1 parcel": "1 個の小包を購入する",
    "Buy {COUNT} miners": "{COUNT} 個のデュアルマイナーを購入する",
    "Buy 1 miner": "デュアルマイナーを 1 つ購入する",
    "Metaverses": "メタバース",
    "My NFTs": "私のNFT",
    "Total Hashpower (Mh/s)": "総ハッシュパワー (Mh/s)",
    "Available Miner Slots": "利用可能なマイナー スロット",
    "Average ROI past 9 months": "9か月過去の平均ROI",
    "Login Via Crypto Wallet": "暗号化ウォレットでログイン",
    "Land reserved for PARTNER": "{PARTNER}用に予約された土地",
    "About PARTNER": "{PARTNER}について",
    "swap": "スワップ",
    "transfer": "移行",
    "approve": "承認",
    "max": "最大",
    "The minimum amount to claim is N": "最低請求金額は {n} です",
    "paginationText": "{from}〜{to}の{count}を表示",
    "turnovers": "ターンオーバー",
    "Total partners": "パートナー合計",
    "refreshData": "データを更新する",
    "NFT Turnover": "NFT ひっくり返す",
    "Single Pool Turnover": "単一プールのターンオーバー",
    "depth": "深さ",
    "turnover": "ひっくり返す",
    "weekly": "毎週",
    "monthly": "毎月",
    "wallet": "ウォレット",
    "columns": "コラム",
    "filters": "フィルター",
    "level": "レベル",
    "search": "探す",
    "partners": "パートナー",
    "Farming Turnover": "農業売上高",
    "Vault Turnover": "ボールト ターンオーバー",
    "Careers status": "キャリアステータス",
    "GYMNET Balance": "GYMNET Balance",
    "Save": "保存",
    "Swap": "スワップ",
    "Turnover pool": "ターンオーバープール",
    "Global Line Bonus": "グローバルラインボーナス",
    "Turnover pools": "ターンオーバープール",
    "Tax pools": "税プール ({n}mo)",
    "Total qualified turnover": "資格のある売上高の合計",
    "My claimed rewards": "受け取った報酬",
    "My pending rewards": "保留中の報酬",
    "Week": "一週間",
    "Month": "一か月",
    "In order to get qualified for any pool, you’ll have to follow the 50% turnover rule: The maximum share each direct partner can contribute to the required turnover is up to 50% of the turnover coming from NFT sales.": "任意のプールの資格を 得るには、50% の売上高ルールに従う必要があります。各直接パートナーが必要な売上高に貢献できる最大シェアは、NFT 販売による売上高の最大 50% です。",
    "View more": "もっと見る",
    "Turnover progress": "ターンオーバーの進捗",
    "Thank you for your participation in Global Line Incentive!": "Global Line インセンティブにご参加いただきありがとうございます。",
    "Global line before June 2023": "グローバルライン 2023 年 6 月以前",
    "Global line before March 2023": "グローバルライン 2023 年 3 月以前",
    "More info": "詳細",
    "Your Position": "あなたの位置",
    "Last Position": "最後の位置",
    "My line length": "私の行の長さ",
    "Global Line Turnover": "グローバルラインターンオーバー",
    "The turnover generated from all participants who joined the Global Line after you.": "あなたの後にグローバルラインに参加したすべての参加者から生成された売上高。",
    "Your purchases": "あなたの購入",
    "You need purchases of at least $100 to become a Global Line member.": "グローバル ラインのメンバーになるには、少なくとも 100 ドルの購入が必要です。",
    "Your pending income": "保留中の収入",
    "Buy more NFTs to increase your shares": "<strong><i>NFT</i></strong> をさらに購入して、<strong>シェア</strong>を増やします。",
    "New Registrations": "新規登録",
    "Turnover": "ひっくり返す",
    "Global level": "グローバルレベル",
    "Joining time": "入社時期",
    "Global Line Total Turnover": "グローバルライン総売上高",
    "Global Line end": "グローバル ライン エンド",
    "Please confirm your claim": "主張を確認してください",
    "After you confirm, you will receive your rewards on your balance.": "確認後、残高に報酬を受け取ります。",
    "Select Language": "言語を選択",
    "Download": "ダウンロード",
    "Your Income": "あなたの収入",
    "Shares": "株式",
    "Single Miner": "シングルマイナー",
    "Super Bundles": "スーパーバンドル",
    "Regular Bundles": "通常のバンドル",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%.": "各直接パートナーが必要な売上高に貢献できる最大シェアは、最大 50% です。",
    "Your partners": "あなたのパートナー",
    "Generated turnover": "生成されたターンオーバー",
    "Qualified turnover": "有資格者ターンオーバー",
    "Generated": "生成された",
    "Qualified": "認定済み",
    "Your partners turnover statistics will appear here.": "パートナーの売上統計がここに表示されます。",
    "Pools": "プール",
    "Qualification": "資格",
    "Rewards": "報酬",
    "Participants": "参加者",
    "Total rewards": "総報酬",
    "Rewards per participant": "参加者ごとの報酬",
    "Land sales contribution": "土地売上額",
    "Maintainance contribution": "維持管理費",
    "Distributed": "分散型",
    "Refresh data": "データを更新する",
    "You will receive your rewards directly on your wallet": "報酬はウォレットで直接受け取ります",
    "Total claimed": "総獲得額",
    "Next distribution": "次回の分配額",
    "Unclaimed BUSD rewards": "BUSDの未受領の報酬",
    "Required level": "必須レベル",
    "NFT sales percent": "NFT 売上 {n}%",
    "Total qualified participants": "資格のある参加者の総数",
    "TxID": "TxID",
    "Tx Type": "送信タイプ",
    "Global line reward": "グローバルライン報酬",
    "Your share": "あなたのシェア",
    "Income before": "前の収入",
    "Income after": "後の収入",
    "NFT Income": "NFT収入",
    "NFT Commissions Total": "NFTコミッション合計",
    "NFT Statistic": "NFT統計",
    "Level": "レベル",
    "User": "ユーザー名",
    "Your current pin": "現在のピン",
    "Turnover for the next pin": "次のピンのターンオーバー",
    "Your next incentive": "次のインセンティブ",
    "Your next bonus": "次のボーナス",
    "Incentives": "インセンティブ",
    "Team of": "のチーム",
    "Partners": "パートナー",
    "Cash Bonus": "現金ボーナス",
    "Your pin": "あなたのピン",
    "Incentive": "インセンティブ",
    "Pin": "ピン",
    "Level pool": "レベルプール",
    "Go to PARTNER": "{PARTNER}に行く",
    "Partnerships": "パートナーシップ",
    "Cannot find EMAIL_OR_WALLET": "{EMAIL_OR_WALLET}が見つかりません",
    "Incentive Ends In: ": "インセンティブの<strong>終了期間</strong>:",
    "Your Rewards History": "あなたの報酬履歴",
    "Dubai & Sofia Trip": "ドバイとソフィア旅行",
    "No Rewards": "報酬はありません",
    "No Partners": "パートナーはいません",
    "Reward": "褒美",
    "Granted": "承諾する",
    "Transaction": "取引",
    "Free BUNDLE": "無料{BUNDLE}",
    "Team Purchases": "チームの購入",
    "Direct Sales": "直接販売",
    "You have won this reward": "あなたはこの報酬を獲得しました",
    "50% Turnover Rule is applied for all incentives!": "すべてのインセンティブに50％の売上高ルールが適用されます！",
    "Please note that you have the chance to earn a complimentary R8 Bundle in two different ways: either by reaching 20k in direct sales or by achieving 100k in team purchases.": "無料の R8 バンドルを 2 つの異なる方法で獲得するチャンスがあることに注意してください。直接販売で 20,000 に到達するか、チーム購入で 100,000 に到達するかのいずれかです。",
    "Insufficient funds for gas, please add BNB to your wallet": "ガスのための資金が不十分です、財布にBNBを追加してください",
    "Please note that you will require up to AMOUNT worth of CURRENCY to complete the transaction.": "取引を完了するためには、最大で{amount} 分の{currency} 通貨が必要になります。",
    "Please note that AMOUNT worth of CURRENCY is required to complete the transaction.": "取引を完了するには、{amount} 相当の {currency} が必要であることに注意してください。",
    "Please note that AMOUNT_CURRENCY is required for gas fee": "ガス料金には {amount_currency} が必要であることに注意してください。",
    "Select a payment method": "お支払方法を選択してください",
    "Use your account's wallet balances to purchase the product.": "アカウントのウォレット残高で製品を購入",
    "Connect a WEB3 wallet like Metamask or Trustwallet and pay with {currency}. This requires a small amount of BNB ($0.30) to cover the transaction fees!": " MetaMaskやTrustWalletなどのWEB3ウォレットを接続し、{currency}で支払いができます。その際、取引手数料として少額のBNB（0.30ドル）が必要になります！",
    "Select one of the available blockchains and cryptocurrencies, and proceed with the payment via an external wallet application.": "利用可能なブロックチェーンと仮想通貨の中から1つを選択し、外部のウォレットアプリを介して支払いを進めてください。",
    "Internal wallet": "内部ウォレット",
    "Web3 Wallet": "Web3 ウォレット",
    "External wallet": "外部ウォレット",
    "Select Currency": "通貨を選択",
    "Select from the available balances": "利用可能な残高から選択",
    "Purchase for myself": "自分用に購入",
    "Select Blockchain": "ブロックチェーンを選択",
    "Select Cryptocurrency": "仮想通貨を選択",
    "Please select another payment method": "他の支払い方法を選択してください",
    "Connect my wallet": "自分のウォレットを接続",
    "Select a product owner": "製品オーナーを選択してください",
    "Please select who you are purchasing for:": "どなたのために購入するのか選択してください:",
    "Product Owner Tooltip": "製品オーナーツール",
    "Owner e-mail": "オーナーのメールアドレス",
    "Purchase Summary": "購入の概要",
    "Network": "ネットワーク",
    "Fees": "手数料",
    "Rate": "レート",
    "Send to wallet address": "ウォレットアドレスに送金",
    "Or scan the code with your wallet app to fill in address automatically.": "またはコードをウォレットアプリで読み取り、アドレスを自動入力する",
    "Send only CURRENCY to this wallet address!": "このウォレットアドレスには{currency}通貨のみ送金してください!",
    "Please send your crypto within 30 minutes to ensure a fixed exchange rate": " 30分以内に仮想通貨を送金すれば固定為替レートで支払いができます",
    "I sent the crypto": "仮想通貨送金済み",
    "Insufficient funds": "残高が不足しています",
    "Purchase Successful": "購入成功!",
    "The NFT balance was successfully updated and can be viewed in My NFTs tab.": "取引状況は、\"My Orders\"で確認できます。成功した場合、お客様のNFT残高は \"My NFTs\" タブで更新されます。",
    "Available balance": "利用可能な残高",
    "scanning_for_transaction_text": "取引状況は、\"My Orders\"で確認できます。成功した場合、お客様のNFT残高は \"My NFTs\" タブで更新されます。",
    "This window will close automatically once we receive your crypto.": "このウィンドウは、お客様の仮想通貨を受け取ると自動的に閉じます。",
    "We did receive your crypto, but it was less than required. Please transfer the missing amount to complete your transaction.": "お客様の仮想通貨を受け取りましたが、必要な量に不足しています。不足分を送金して取引を完了させてください。",
    "Please do not attempt to do another transaction. Send your Payment UID to our support.": "取引の有効期限が切れています: 次の取引を実行せずに取引IDをサポートに送信してください。",
    "Scanning for transaction": "取引を検索中",
    "Go to My Orders": "注文履歴へ",
    "remaining": "残り",
    "Please send your crypto within 3 hours to ensure a fixed exchange rate": "固定為替レートを確保するために、3時間以内に暗号通貨を送金してください。",
    "Please send your crypto within n minutes to ensure a fixed exchange rate": "固定為替レートを確保するため、暗号通貨を {N} 分以内に送信してください。",
    "Missing crypto amount": "Missing crypto amount",
    "Missing amount": "不足分",
    "Received amount": "受領額",
    "Required amount": "必要量",
    "Continue with payment": "購入手続きへ進む",
    "No enough CURRENCY to cover gas fee.": "ガス料金を賄うのに十分な {currency} がありません。",
    "Please top up your wallet with AMOUNT_CURRENCY to cover gas fee.": "ガソリン代を賄うために、ウォレットに {amount_currency} をチャージしてください。",
    "Scanning": "走査",
    "To continue, please enable payment.": "続行するには、支払いを有効にしてください。",
    "Kindly be aware that AMOUNT_CURRENCY may be necessary to cover the gas fee.": "ガス料金をカバーするために {amount_currency} が必要になる場合があることにご注意ください。",
    "Insufficient funds in CURRENCY to cover the gas fee.": "ガス料金をカバーするには {currency} の資金が不足しています。",
    "Please top up your wallet with AMOUNT_CURRENCY to cover the gas fee.": "ガソリン代をカバーするために、ウォレットに {amount_currency} をチャージしてください。",
    "We will cover the gas fee for this transaction on your behalf.": "この取引のガソリン料金は、お客様に代わってカバーします。",
    "Please choose your preferred payment method to finalize your purchase.": "ご希望のお支払い方法をお選びいただき、購入を確定してください。",
    "Your payment has expired. To resolve this issue, please contact customer support.": "支払い期限が過ぎてしまいました。この問題を解決するには、カスタマーサポートにお問い合わせください。",
    "Your payment has been received and is currently being processed. We will notify you once the process is complete.": "ご入金が確認され、現在処理中です。処理が完了しましたら、ご連絡いたします。",
    "Please indicate the intended recipient of the product. You have the option to purchase it for yourself or choose 'Another user' as the recipient.": "製品の受取人をご記入ください。ご自身用の購入、または受取人として「別のユーザー」を選択することが可能です。",
    "Your transaction has been declined. Please attempt the transaction again.": "取引が拒否されました。再度取引を試みてください。",
    "Monitor the status of your transaction by visiting the provided link:": "こちらのリンクから取引状況が確認できます：",
    "Apologies, an error occurred on the blockchain side. Please try again.": "申し訳ございません、ブロックチェーン側でエラーが発生しました。もう一度お試しください。",
    "Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.": "新しい取引を開始するには、現在ブロックチェーン上で進行中の取引が完了するのをお待ちください。",
    "Your payment has expired. To resolve this issue, please contact customer support": "お支払いの有効期限が切れました。 この問題を解決するには、カスタマー サポートにお問い合わせください。",
    "Your payment was refunded. AMOUNT CURRENCY was returned to your wallet.": "お支払いは返金されました。 {amount} {currency} がウォレットに返されました。",
    "You can monitor the transaction status in the Order History tab. If it is successful, the NFT balance will be updated in the My NFTs tab.": "「注文履歴」タブで取引ステータスを監視できます。 成功すると、「My NFT」タブで NFT 残高が更新されます。",
    "OFF": "オフ",
    "Received Amount.": "受取金額。",
    "Processing Fee": "手数料",
    "Estimated gas fee": "ガス代の目安",
    "Transaction Expired": "トランザクションの期限が切れました",
    "Enable Payment": "支払いを有効にする",
    "Email does not exist": "電子メールが存在しません",
    "Request another quote": "別の見積もりをリクエストする",
    "Purchase Success!": "購入成功！",
    "Purchase for another user": "別のユーザーのために購入する",
    "Pay": "支払い",
    "You purchased GIFT for RECEIVER!": "{receiver} のために {gift} を購入しました。",
    "You've purchased GIFT for RECEIVER!": "\"{receiver}\" のために {gift} を購入しました。",
    "You've purchased a GIFT for RECEIVER!": "\"{receiver}\" の {gift} を購入しました。",
    "Rewards claimed successfully": "報酬の受け取りに成功しました",
    "Commissions claimed successfully.": "コミッションは無事に請求されました。",
    "You received GIFT from SENDER!": "{sender} から {gift} を受け取りました!",
    "You've received GIFT from SENDER!": "\"{sender}\" から {gift} を受け取りました!",
    "You've received a GIFT from SENDER!": "\"{sender}\" から {gift} を受け取りました!",
    "Transaction Failed": "処理に失敗しました",
    "The transaction has failed. Please try again.": "トランザクションは失敗しました。 もう一度試してください。",
    "Your transaction has been declined. Please add BNB to your wallet and attempt the transaction again.": "あなたの取引は拒否されました。 BNB をウォレットに追加して、取引を再試行してください。",
    "Blockchain Notification: Apologies, an error occurred on the blockchain side. Please try again.": "ブロックチェーン通知: 申し訳ありませんが、ブロックチェーン側でエラーが発生しました。 もう一度試してください。",
    "Apologies, an unexpected error occurred. Please retry your request. If the issue persists, please contact support.": "申し訳ありませんが、予期しないエラーが発生しました。 リクエストを再試行してください。 問題が解決しない場合は、サポートにお問い合わせください。",
    "Received Amount": "受取金額",
    "Dual Miner": "デュアルマイナー",
    "Bitopex": "Bitopex",
    "Gymnet": "Gymnet",
    "Value": "値",
    "Reach Rank": "ランク達成 <b>{n}</b>",
    "Rewards in Gymnet": "Gymnetでのリワード",
    "Rewards in Bitcoin": "Bitcoinでのリワード",
    "For Limited Time Only": "<strong>限定された期間</strong>のみ",
    "Seconds": "秒",
    "Minutes": "分",
    "Days": "日々",
    "Hours": "時間",
    "Unclaimed parcels": "未請求のパーセル",
    "Upgraded parcels": "アップグレードされたパーセル",
    "Inactive miners": "非アクティブなマイナー",
    "Active miners": "アクティブなマイナー",
    "Available Balance": "利用可能なバランス",
    "Utility Balance": "Utilityバランス",
    "Bitopex Balance": "Bitopexバランス",
    "Moonberg Balance": "Moonbergバランス",
    "Balance History": "バランス史",
    "Miner Hashpower": "マイナー Hashpower",
    "Dual Miners": "デュアルマイナー",
    "Dual miners info": "アクティブなデュアルマイナーの数とデュアルマイナーの合計数。",
    "Dual Miner Hashpower": "デュアルマイナー Hashpower",
    "Dual miners hashpower info": "あなたが所有する hashpower は、アクティブなデュアル マイナーの数とユニット マイナーの hashpower を乗算して決定されます。ユニットあたり 1000Mh/s に設定されています。",
    "Unlock the full potential of your Bitopex rewards as they come to life following your purchase of any of our special bundles. Once your Bitopex Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "特別バンドルの一つを購入したら、Bitopexリワードを有効にし、それらのポテンシャルを最大限に開けてください。Bitopexバランスが利用可能になると、合計リワード、獲得したリワード、および期待されるリワードにアクセスできるようになります。さらに、リワードを逃さないように、カウントダウンタイマーで次のリワードの配布が表示されます。",
    "Sec": "秒",
    "Min": "分",
    "Total Rewards Expected": "期待されるリワードの合計",
    "Monthly Distribution": "毎月の配布",
    "Price Projection": "価格予測",
    "Server error": "サーバーエラー",
    "Average Gymnet ROI for the last 9 months": "過去9か月のGymnetの平均 ROI",
    "Dual Miners will generate rewards in Bitopex tokens, which will be distributed every Friday.":"デュアルマイナーは毎週金曜日に配布されるBitopexトークンでリワードを生成します。",
    "Introducing the Utility Balance feature, a new and convenient payment method within our payment ecosystem. With this feature, you'll enjoy a separate balance that can be used alongside other payment methods. It provides you with the flexibility to partially utilize your utility balance, up to 25% of the total when purchasing single Land NFTs or making payments for Land NFT bundles.":"弊社の支払いエコシステム内の新しくて便利な支払い方法である Utility 残高機能を導入します。 この機能を使用すると、他の支払い方法と併用できる別の残高をお楽しみいただけます。 これにより、単一の Land NFT を購入するとき、または Land NFT バンドルの支払いを行うときに、ユーティリティ残高を部分的に利用できる柔軟性が提供されます (合計の最大 25%)。",
    "Balance before": "前のバランス",
    "Balance after": "後のバランス",
    "TXID": "TXID",
    "The number of active and total miners you have in Gymstreet and Kabutochō, respectively.": "GymstreetとKabutochōにそれぞれいるアクティブなマイナー数と合計マイナー数。",
    "The hashpower you possess is determined by multiplying the number of your active miners with the unit miner hashpower, which is set at 1000Mh/s per unit.": "あなたが所有する hashpower は、アクティブなマイナーの数にユニット マイナーの hashpower を乗じて決定されます。ユニットあたり 1000Mh/s に設定されています。",
    "Token": "トークン",
    "Buy Bundles": "バンドルを購入",
    "Total Received": "受け入れた合計",
    "Next Rewards Distribution In:": "次回のリワード配布まで:",
    "Total Rewards": "リワード合計",
    "Payout": "支払い",
    "Weekly": "毎週",
    "Next Distribution Amount": "次回分配金",
    "Internal transaction": "内部取引",
    "Minimum claimable amount is (AMOUNT)": "請求可能な最低金額は {amount} です",
    "User Profile": "ユーザーのプロファイル",
    "Name": "名前",
    "Nationality": "国籍",
    "Contact Information": "連絡先情報",
    "Country": "国",
    "City": "都市",
    "Street": "通り",
    "Zip": "郵便番号",
    "E-Mail": "電子メール",
    "Wallet address": "ウォレットアドレス",
    "Name is required": "名前は必要です",
    "Nationality is required": "国籍は必要です",
    "Country is required": "国は必要です",
    "City is required": "都市は必要です",
    "Street is required": "通りは必要です",
    "Zip is required": "郵便番号は必要です",
    "Successfully updated": "正常に更新されました",
    "Zip contain only numbers and min length is 3": "郵便番号には数字のみが含まれ、最小の長さは３つです",
    "Please enter a valid email address": "有効な電子メール アドレスを入力してください",
    "Personal information": "個人的情報",
    "T&C": "利用規約",
    "Field is required": "フィールドは必要です",
    "Password successfully changed": "パスワードが正常に変更されました",
    "Old password is incorrect": "古いパスワードが間違っています",
    "Oops...Something went wrong. Please try again": "おっと...問題が発生しました。もう一度お試しください",
    "Name must be at least 3 characters": "名前は少なくとも３つの文字からなる必要があります",
    "City must be at least 3 characters": "都市の名称は少なくとも３つの文字からなる必要があります",
    "Street must be at least 3 characters": "通りの名称は少なくとも３つの文字からなる必要があります",
    "Affiliate Terms and conditions": "アフィリエイト利用規約",
    "Affiliate links": "アフィリエイトリンク",
    "Affiliate link successfully changed": "アフィリエイトリンクが正常に変更されました",
    "Please enter the Bitopex affiliate link here. Kindly note that you can only save the link once.": "ここにBitopexアフィリエイトリンクを入力してください。リンクは1回しか保存できないことにご注意ください。",
    "Input cannot be only spaces": "スペースのみを入力することはできません",
    "To proceed with the registration, kindly accept the above 2 conditions by checking the corresponding boxes.": "登録を進めるには、上記の 2 つの条件に同意し、該当するボックスにチェックを入れてください。",
    "I hereby confirm that I am not a US citizen": "私はここに私が米国国民ではないことを確認します",
    "I hereby confirm that I am not a UAE citizen": "私はここに私が UAE 国民ではないことを確認します",
    "Enter Old Password": "古いパスワードを入力してください",
    "Change username & password": "ユーザー名とパスワードを変更する",
    "My wallet": "私の財布",
    "Purchase": "購入",
    "Username required to proceed": "続行するにはユーザー名が必要です",
    "Enter preferred username here": "ここに優先ユーザー名を入力してください",
    "Username must be at least 8 characters, starting with a letter. Use only lowercase letters, numbers, hyphens, dots, and underscores.": "ユーザー名は文字で始まる少なくとも 8 文字である必要があります。 小文字、数字、ハイフン、ドット、アンダースコアのみを使用してください。",
    "Username is already taken. Please choose a different username.": "ユーザー名はすでに使用されています。 別のユーザー名を選択してください。",
    "Email or username": "メールアドレスまたはユーザ名",
    "Username is available": "ユーザー名は利用可能です",
    "Provided username is already taken.": "指定されたユーザー名はすでに使用されています。",
    "Invalid credentials. Please try again.": "無効な資格情報。\n もう一度試してください。",
    "Username successfully set": "ユーザー名が正常に設定されました",
    "Dear User, due to ongoing updates to our platform, you will need to enter a username to continue. The username needs to be unique and cannot be changed": "ユーザーの皆様、当社のプラットフォームは継続的に更新されているため、続行するにはユーザー名を入力する必要があります。 ユーザー名は一意である必要があり、変更できません",
    "You have successfully set your username": "ユーザー名の設定が完了しました",
    "Continue to dashboard": "ダッシュボードに進む",
    "Username is required": "ユーザー名は必須です",
    "Rank Transfer": "ランク移行",
    "Review": "レビュー",
    "Top Up": "補充する",
    "The claim button is currently disabled for maintenance. We apologize for the inconvenience. Please come back shortly.": "現在メンテナンスのため、請求ボタンは無効になっています。 ご不便をおかけして申し訳ございません。 すぐに戻ってきてください。",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / BUSD": "新しいビジネスビルが建設される場合、GYMNET / BUSDで支払いを行って土地を購入することができます。",
    "Parcel NFT": "パーセルNFT",
    "Miner NFT": "マイナーNFT",
    "BUSD Balance": "BUSDバランス",
    "Your payment has been received. It is currently being processed. You will receive a notification when done.": "お支払いは受領されました。現在処理中です。完了すると通知が届きます。",
    "Error": "エラー",
    "Please enter valid email": "有効な電子メールを入力してください",
    "Your sponsor is": "あなたのスポンサーは",
    "Credentials": "証明",
    "Enter Wallet Address (i.e. 0xb794f...79268)": "ウォレットアドレス(例えば 0xb794f...79268)を入力してください",
    "Contact Customer Support": "カスタマーサポートに連絡",
    "Change Wallet": "ウォレットを交換",
    "Account": "アカウント",
    "To complete purchase, you must link a wallet address to your account. Go to your profile and set a wallet address.": "購入を完了するには、ウォレットアドレスをアカウントにリンクしなければなりません。プロフィールに移動し、ウォレットアドレスを設定してください。",
    "Please leave an address here, in which we can also mint your NFTs afterwards.": "後でNFTをマイントすることもできるように、ここにアドレスを残してください。",
    "Important!": "大切です!",
    "It should be a BEP-20 address - i.e. a BNB Smart Chain wallet address. We recommend the wallet Metamask or Trustwallet.": "これはBEP-20アドレス、つまりBNBスマート チェーン ウォレット アドレスである必要があります。MetamaskまたはTrustwalletのウォレットをお勧めします。",
    "NEVER USE AN EXCHANGE Wallet ADRESS !!!": "交換ウォレットアドレスは決して使用しないでください!!!",
    "Failed": "失敗",
    "Account changed": "アカウントは変更されました",
    "Your Profile has been updated": "プロフィールが更新されました",
    "You already have an attached wallet address to this account.": "このアカウントにはすでにウォレットアドレスが関連付けられています。",
    "Login": "ログイン",
    "Existing wallet": "存在しているウォレット",
    "Referral ID is required": "紹介IDは必要です",
    "Pending Transaction": "保留中のトランザクション",
    "Processing": "処理中",
    "Send this payment ID to a customer support when you are requested to do so.": "要求された場合は、この支払いIDをカスタマー サポートに送信してください。",
    "To be able to claim your NFTs you need to connect the same wallet address attached to your account": "NFTを請求できるようになるには、アカウントに関連付けられているのと同じウォレットアドレスを接続しなければなりません",
    "Please confirm your email to continue.": "続行するにはメールをご確認ください。",
    "Valid address": "有効なアドレス",
    "This social account is used by another user. Verify your email first or contact Customer Support": "このソーシャル アカウントは別のユーザーによって使用されています。まずメールアドレスを確認したり、カスタマーサポートにお問い合わせたりしてください",
    "Invalid wallet connection": "無効なウォレット接続",
    "Registration completed successfully": "登録は正常に完了しました",
    "Failed to complete registration of the user": "ユーザーの登録が完了できませんでした",
    "Failed to register user": "ユーザーの登録ができませんでした",
    "Signature grant failed": "署名の付与ができませんでした",
    "Unsupported chain": "サポートされていないチェーン",
    "This wallet address is already associated with another user account. Please use another one!": "このウォレット アドレスはすでに別のユーザー アカウントに関連付けられています。別のを使用してください。",
    "Address has been successfully added to your wallet": "アドレスがウォレットに正常に追加されました",
    "Failed to accept Affiliate TMS": "アフィリエイトTMSを受け入れることができませんでした",
    "Wallet address has been successfully applied": "ウォレットアドレスが正常に適用されました",
    "Your email is verified.": "メールアドレスが確認されました。",
    "Failed to add Token": "トークンを追加することができませんでした",
    "Failed to upload image": "イメージをアップロードすることができませんでした",
    "Wrong file size. Please upload image files with size less than 10MB": "ファイルサイズが間違っています。イメージファイルは10MB以下でアップロードしてください",
    "We've verified your email address. You can now continue using the site.": "あなたのメールアドレスを確認しました。これで、サイトを引き続き使用できるようになります。",
    "You need to switch to Binance Smart Chain": "Binance Smart Chainに切り替えなければなりません",
    "Switch to Binance Smart Chain to continue using GymStreet": "GymStreetを引き続き使用するには、Binance Smart Chainに切り替えてください",
    "To CashFT": "CashFTへ",
    "Buy a Super Bundle in the next": "次のSuper Bundleを購入して",
    "and get an EXTRA discount!": "さらに割引を受けられます!",
    "See All Offers": "全てのオファーを見る",
    "I accept the affiliate terms & conditions": "アフィリエイト利用規約に同意します",
    "Special offer!": "特別なオファー!",
    "Just one": "一つだけ",
    "1000 Mh/s": "1000 Mh/s",
    "View Claimed Coordinates": "請求された座標を表示する",
    "Your Parcel Coordinates": "あなたのパーセルの座標",
    "Number of miners you want to attach": "追加したいマイナーの数",
    "Referral Link": "紹介リンク",
    "Affiliate Link": "アフィリエイトリンク",
    "Invalid Wallet": "無効なウォレット",
    "Back to Log In": "ログインに戻る",
    "You need a valid referral ID to finalize your registration": "登録を完了するには有効な紹介IDが必要です",
    "I have read and accept the": "以下のものを読んで同意します",
    "Your Sponsor": "あなたのスポンサー",
    "Forgot Your Password": "パスワードをお忘れですか",
    "Enter your email address and we will send you instructions to reset your password.": "メールアドレスを入力したら、パスワードをリセットするための手順が送信されます。",
    "We just sent you an email to confirm your e-mail address. Please click on the link in the e-mail to reset your password.": "あなたの電子メールアドレスを確認するために電子メールを送信しました。電子メール内のリンクをクリックしてパスワードをリセットしてください。",
    "Welcome To Metaverse": "{n} Metaverseへようこそ",
    "Commissions": "コミッション",
    "Refund of electricity vouchers": "電気料金の払い戻し",
    "Import Custom Token": "カスタムトークンのインポート",
    "Add Token To Wallet": "ウォレットにトークンを追加",
    "Token contract address": "トークンコントラクトアドレス",
    "Token symbol": "トークンシンボル",
    "Provided address is not a supported token address": "指定されたアドレスはサポートされているトークン アドレスではありません",
    "Are you sure you want to claim your rewards": "リワードを実際に受け取りたいですか。",
    "OTP Code": "OTPコード",
    "OTP Code is required": "OTPコードは必要です",
    "Enter OTP code you have received at email": "{email}で受け取ったOTPコードを入力してください",
    "Asset": "アセット",
    "Gas": "ガス",
    "Send crypto": "暗号通貨を送信",
    "Enter wallet address": "ウォレットアドレスを入力してください",
    "NFTs": "NFTs",
    "Activity": "アクティビティ",
    "Assets": "アセット",
    "Import token": "トークンをインポート",
    "Don't see your token?": "トークンが表示されていないのですか。",
    "more": "もっと",
    "Send": "送信",
    "Sending success": "送信成功",
    "View account in explorer": "エクスプローラーでアカウントを表示する",
    "estimated": "推定",
    "Copy your address": "アドレスをコピー",
    "Export private key": "秘密キーをエクスポート",
    "Receive": "受け取る",
    "Account Details": "アカウントの詳細",
    "View on blockchain": "ブロックチェーンで見る",
    "GymStreet Parcels": "GymStreetパーセル",
    "GymStreet Miners": "GymStreetマイナー",
    "Kabutocho Parcels": "Kabutochoパーセル",
    "Kabutocho Miners": "Kabutochoマイナー",
    "Invalid wallet address": "無効なウォレットアドレス",
    "Calculate": "計算",
    "Sending": "送信中",
    "Reject": "拒否",
    "Confirmation": "確認",
    "Gas fees are paid to crypto miners who process transactions on the network. Gas fees are set by the network and fluctate based on network traffic and transaction complexity.": "ガス料金は、ネットワーク上でトランザクションを処理する暗号通貨マイナーに支払われます。ガス料金はネットワークによって設定され、ネットワークのトラフィックとトランザクションのコンプレクシティに基づいて変動します。",
    "Insufficient TOKEN balance": "{token}バランスは不足しています",
    "Confirm Swap": "Swapを確認",
    "Approve Allowance": "手当を承認",
    "Help Center": "ヘルプセンター",
    "Confirm the Allowance Transaction in order to be able to use Token through our services. Please note that this is a one-time action.": "当社のサービスを通じて<strong>{n}</strong>トークンを使用できるようにするには、手当トランザクションを確認してください。これは1回限りのアクションであることに注意してください。",
    "No Activities yet": "まだ活動はありません",
    "To": "へ",
    "Contract interaction": "コントラクトのやり取り",
    "Are you sure you want to delete this token": "このトークンを削除してもよろしいですか",
    "Send only using  {CHAIN}  network to receive funds on this deposit address.": "この入金アドレスで資金を受け取るには、{CHAIN} ネットワークのみを使用して送信してください。",
    "Leadership Incentives": "リーダーシップのインセンティブ",
    "Pool statistics": "プールの統計",
    "Hourly": "毎時",
    "Turnover rule": "ターンオーバールール",
    "Turnover rule percentage": "ターンオーバールール{n}%",
    "Miner sales contribution": "マイナーの売上貢献",
    "Pool": "プール",
    "percent of NFT sales": "NFTセールスの{n}%",
    "partner": "パートナー",
    "Main area": "メインエリア",
    "Turnover percentage rule": "ターンオーバーの{n}%のルール",
    "With dollar each": "各ドル付き",
    "Direct partners": "直接のパートナー",
    "Your next bonus will appear here": "次のボーナスがここに表示されます",
    "Your incentive": "あなたのインセンティブ",
    "Your cash bonus": "あなたのキャッシュボーナス",
    "Your bonus history": "あなたのボーナス履歴",
    "At the current moment you don't have any rewards": "現時点ではリワードはありません",
    "Partners with pin qualification": "{n}のピン資格を持つパートナー",
    "Total generated turnover": "合計ターンオーバー",
    "Active Miners": "アクティブなマイナー",
    "Increase your available miner slots from 4 â> 10": "利用可能なマイナー スロットを4 â> 10から増やしてください",
    "Total pool rewards": "合計プールリワード",
    "Your pool share": "あなたのプールシェア",
    "Total gGymnet": "合計gGymnet",
    "GYMNET Commissions Total": "GYMNETコミッションの合計",
    "Your attached wallet is {walletAddress}. You have tried to make request with wallet {currentWallet}. Please change connected wallet to {walletAddress}": "あなたの接続されたウォレットは<b>{walletAddress}</b>です。<b>{currentWallet}</b>ウォレットでリクエストを行おうとしました。接続されているウォレットを<b>{walletAddress}</b>に変更してください。",
    "Available for limited time": "期間限定で利用可能",
    "Bonus": "ボーナス",
    "Utility": "ユティリティ",
    "The URL must begin with 'https://bitopex.io/home/' and include your referral ID": "URL は「https://bitopex.io/home/」で始まらなければならなくて、紹介IDが含まれている必要があります",
    "Insufficient funds: to continue with payment use utility balance": "資金が不足しています:支払いを続けるにはユティリティバランスを使用してください",
    "My Team": "マイ・ティーム",
    "Team Statistics": "ティームの統計",
    "All partners": "全てパートナー",
    "Active partners": "アクティブなパートナー",
    "Inactive partners": "インアクティブなパートナー",
    "Binary Points": "バイナリポイント",
    "Points in left leg": "左のレッグでのポイント",
    "Points in right leg": "右のレッグでのポイント",
    "Left leg": "左足",
    "Right leg": "右足",
    "Holding Tank": "貯蔵タンク",
    "Enrollment Tree": "エンロールメントツリー",
    "Binary Tree": "バイナリツリー",
    "CV Points History": "CVポイント履歴",
    "Time Left To Activation": "<b>アクティベーションまでの</b>時間",
    "Search by email or wallet": "メールまたはウォレットで検索",
    "Refresh Data": "データを更新",
    "Joined at": "参加したのは",
    "Placement": "プレイスメント",
    "Select": "セレクト",
    "Own": "自分の",
    "Team": "チーム",
    "Position": "ポジション",
    "CV Points": "CVポイント",
    "CV = commission volume": "CV =コミッションボリューム",
    "Genealogy": "系図",
    "Unilevel": "ユニレベル",
    "Select view": "ビューの選択",
    "Team size": "チームサイズ",
    "Rank": "ランク",
    "Own purchases": "自分の購入",
    "Navigation": "ナビゲーション",
    "Search by wallet": "ウォレットで検索",
    "no user": "ユーザーがいません",
    "Wallet address is not valid": "ウォレットアドレスが無効です",
    "Free Spot": "フリースポット",
    "Search by wallet or username": "ウォレットまたはユーザー名で検索",
    "Info": "情報",
    "Benefits": "利点",
    "Objectives Completed": "完了した目標",
    "TO DO`s": "するべきこと",
    "Purchase NFTs In Total Value Of 100 USDT": "総額100USDTのNFTを購入",
    "Purchase Binary Pass - 19 USDT": "バイナリーパスを購入 - 19 USDT",
    "Team Turnover": "チームのターンオーバー",
    "BASED ON TEAM": "{n}% +に基づくチーム",
    "Qualification Turnover": "資格ターンオーバー",
    "Current Rank": "現在のランク",
    "Next Rank": "次のランク",
    "Binary Spot Reservation": "バイナリースポット予約",
    "Holding Tank Ends In": "貯蔵タンク<b>は</b>で終わります",
    "With every parcel purchase, you will receive two types of tokens—Gymnet and Bitcoin.": "小包を購入するたびに、Gymnet と Bitcoin の 2 種類のトークンを受け取ります。",
    "A minimum of USDT is required for Purchase": "購入には最低${n} USDTが必要です",
    "Become Affiliate Partner": "アフィリエイトパートナーになる",
    "Placement in Unilevel": "ユニレベルでのプレイスメント",
    "Matching bonuses": "マッチングボーナス",
    "Placement in Binary": "バイナリーでのプレイスメント",
    "Product Autoship Incentive": "商品オートシップインセンティブ",
    "For limited time only": "期間限定",
    "Free 30 days Product Autoship": "30日間の無料製品オートシップ",
    "Fast Start Incentive": "ファストスタートインセンティブ",
    "Receive Direct Sales Commissions": "直接セールスコミッションを受け取る",
    "Please select your role": "あなたの役割を選択してください",
    "Choose your role before diving in. Specify why user should choose each role and what opportunities are there.": "始める前に役割を選択してください。ユーザーが各役割を選択する理由と、どのような機会があるかを指定してください。",
    "is left to get": "<span>${n}</span>は、手に入れることに残っています",
    "Go to Products": "商品へ",
    "Rental Income": "賃貸料所得",
    "Left Leg Affiliate Link": "左のレッグのアフィリエイトリンク",
    "Right Leg Affiliate Link": "右のレッグのアフィリエイトリンク",
    "Qualification Conditions": "資格条件",
    "Buy Products": "商品を購入",
    "My Current Rank": "私の現在ランク",
    "To Reach Rank You Need:": "ランク{n}を達成するために必要なのは:",
    "NFT Turnover In Weak Leg": "ウィークレッグでのNFTターンオーバー",
    "NFT Own purchases": "NFTの自分の購入",
    "Required Qualified Partners": "必須の資格のあるパートナー",
    "Progress Until Rank": "{n}ランクまでの進行状況",
    "See Details": "詳細を見る",
    "Default Advantages": "デフォルトの利点",
    "Binary Commission": "バイナリーコミッション",
    "Upgrade Your Weekly Cap": "ウィークリーキャップをアップグレード",
    "Rank Benefits": "ランクの利点",
    "For 30 Days ONLY You Receive 10% Binary Commission Instead Of NUM": "30日間限定で、{n}%の代わりに10%のバイナリーコミッションを受け取ります!",
    "My Rank Progress": "私のランクプログレス",
    "Own Invest": "自己投資",
    "Qualified Partners": "適格なパートナー",
    "You need to buy products in value of": "${n}相当の商品を購入しなければなりません",
    "You need to generate turnover in value of in your weak leg.": "ウィークレッグで${n}の価値のターンオーバーを生み出さなければなりません。",
    "Remaining": "残り",
    "CV Points weak leg": "CVポイントのウィークレッグ",
    "Details": "詳細",
    "Partner": "パートナー",
    "leg": "レッグ",
    "Right": "右の",
    "Left": "左の",
    "You need qualified partner in left leg and qualified partner in right leg to complete this requirement.": "この要件を完了するには、左のレッグには{l}人の適格なパートナー、右のレッグには{r}人の適格なパートナーが必要です。",
    "partners in left leg and in right leg": "{l} {partner1}が左のレッグに、{r}人のパートナーが右のレッグに残っています",
    "partners in left leg remaining": "{l} {partner1}が左のレッグに残っています",
    "partners in right leg remaining": "{r}人のパートナーが右のレッグに残っています",
    "partner in right leg remaining": "{r}人のパートナーが右のレッグに残っています",
    "Holding Tank is over": "貯蔵タンクが終了しました",
    "Back To Rank Progress Details": "ランクのプログレスの詳細に戻る",
    "Super Bundle R10 or above": "スーパー バンドルR10\n以上",
    "Required Monthly Product Autoship": "必須の毎月の商品オートシップ",
    "All Customer Benefits": "すべての顧客のメリット",
    "Product Autoship": "商品のオートシェイプ",
    "Product Autoships": "商品の諸オートシェイプ",
    "Products are resellable": "商品は再販可能です",
    "Activate binary commission": "バイナリーコミッションを有効にする",
    "Activate direct sales commission": "ダイレクトセールスコミッションを有効にする",
    "Left before": "左の前",
    "Left after": "左の後",
    "Right before": "右の前",
    "Right after": "右の後",
    "Activate your referral link to be able to invite your referrals to the platform and earn additional commissions.": "紹介リンクを有効にすると、紹介をプラットフォームに招待したり追加のコミッションを獲得したりすることができるようになります。",
    "Activate referral link": "紹介リンクを有効にする",
    "Yes, I confirm": "はい、確認します",
    "Inactive": "インアクティブ",
    "Finance": "ファイナンス",
    "Total Income": "総収入",
    "Products": "商品",
    "GymStreet Map": "GymStreetマップ",
    "Kabutocho Map": "Kabutochoマップ",
    "Don't ask again": "二度と聞かないでください",
    "Filecoin Balance": "Filecoinバランス",
    "Filecoin": "Filecoin",
    "Total available": "利用可能な合計",
    "Total generated": "生成された合計",
    "Total used": "使用合計",
    "Transaction type": "トランザクションのタイプ",
    "To claim your commission, you must have a minimum of 1 USDT or (AMOUNT) GYMNET.": "コミッションを請求するには、少なくとも1USDTまたは{n}GYMNET が必要です。",
    "Loading balance...": "バランス読み込み中...",
    "Total Commissions": "合計コミッション",
    "Claimed Commissions": "請求されたコミッション",
    "Available Commissions": "利用可能なコミッション",
    "Total Commissions By Source": "ソース別の合計コミッション",
    "Filter by currency": "通貨によるフィルター",
    "No data to display": "表示するデータがありません",
    "Year": "年",
    "Career Status": "キャリアステータス",
    "Current Incentive": "現在インセンティブ",
    "Fast Start": "ファストスタート",
    "Product Autoship incentive": "商品オートシップインセンティブ",
    "Direct partner - left leg": "直接のパートナー - 左のレッグ",
    "Direct partner - right leg": "直接のパートナー - 右のレッグ",
    "Receive direct sales commission": "ダイレクトセールスコミッションを受け取る",
    "Direct sales commission": "ダイレクトセールスコミッション",
    "Receive binary commission": "バイナリーコミッションを受け取る",
    "Affiliate ranks": "アフィリエイトランク",
    "1 Left, 1 Right": "左1つ、右1つ",
    "See Benefits": "メリットを見る",
    "2 Silver (1 in different teams)": "2つのシルバー(異なるチームに1つ)",
    "2 Gold (1 in different teams)": "2つのゴルド(異なるチームに1つ)",
    "3 Platin (1 in different teams)": "3つのプラチナ(異なるチームに1つ)",
    "3 Double Platin (1 in different teams)": "3つのダブル プラチナ(異なるチームに1つ)",
    "4 Diamond (1 in different teams)": "4つのダイヤモンド(異なるチームに1つ)",
    "5 Double Diamond (1 in different teams)": "5つのダブル ダイヤモンド(異なるチームに1つ)",
    "5 Red Diamond (1 in different teams)": "5つのレッド ダイヤモンド(異なるチームに1つ)",
    "5 White Diamond (1 in different teams)": "5つのホワイト ダイヤモンド(異なるチームに1つ)",
    "Please note that $100 dollar will correspond to 100 points.": "100ドルは100ポイントに相当することをご注意ください。",
    "Home": "ホーム",
    "Last Left": "ラスト左の",
    "Last Right": "ラスト右の",
    "Binary leg": "バイナリーレッグ",
    "by admin": "管理者による",
    "missed commission": "逃されたコミッション",
    "payment": "支払い",
    "commission": "コミッション",
    "Payment Currency": "支払いの通貨",
    "BUSD Amount": "BUSDアマウント",
    "CV Ratio": "CV比率",
    "Hrs": "時間",
    "Mins": "分",
    "Secs": "秒",
    "Please enter a valid referral ID": "有効な紹介IDを入力してください",
    "Commission TXID": "TXIDコミッション",
    "Qualification turnover": "資格ターンオーバー",
    "Binary commission": "バイナリコミッション",
    "Additional Advantages": "追加の利点",
    "The referral ID you've entered is incorrect. Please copy and paste the correct referral link to proceed.": "入力した紹介IDが間違っています。続けるには、正しい紹介リンクをコピーして貼り付けてください。",
    "In order to receive binary commissions you need to have an active product autoship.": "バイナリーコミッションを受け取るには、アクティブな製品のオートシップが必要です。",
    "In order to receive binary commissions you need to have an active product autoship and a purchase for at least $100.": "バイナリーコミッションを受け取るためには、アクティブなオートシップと最低$100の購入が必要です。",
    "To qualify for binary commissions, a minimum product purchase of $100 is required.": "バイナリーコミッションを受け取るには、最低$100の商品購入が必要です。",
    "Transaction in Process": "処理中のトランザクション",
    "Claim your parcel": "荷物を受け取る",
    "You do not have any claimed slots to attach miners. To enable miner attachment, please claim parcels on the map.": "マイナーを接続するために要求されたスロットがありません。 マイナーのアタッチメントを有効にするには、マップ上で区画を要求してください。",
    "Active binary commission": "アクティブなバイナリー手数料",
    "Active direct sales commission": "積極的な直販手数料",
    "Own purchase min {AMOUNT}": "自身の購入最小額 {AMOUNT}",
    "Active product autoship": "アクティブな製品のオートシップ",
    "Right partner own purchase min {AMOUNT}": "適切なパートナー自身の最小購入額 {AMOUNT}",
    "Left partner own purchase min {AMOUNT}": "左パートナー自身の購入分 {AMOUNT}",
    "Unfortunately, you won't receive the bonus for this rank since you met the qualification conditions (more than 50%) before September 18, 2023": "残念ながら、2023 年 9 月 18 日までに資格条件 (50% 以上) を満たしているため、このランクのボーナスは受け取れません。",
    "You will be eligible for cash bonus based on your rank if you meet all qualification conditions between September 18 and November 8.Those who met the qualification conditions (more than 50%) before September 18, 2023 will not receive the Super Cash Bonus.": "9 月 18 日から 1 月 31 日までにすべての資格条件を満たしている場合、ランクに基づいて現金ボーナスを受け取る資格があります。2023 年 9 月 18 日より前に資格条件 (50% 以上) を満たした人は、スーパー キャッシュ ボーナスを受け取りません。",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / USDT": "新しいビジネスビルディングが実装されている場合、GYMNET / USDTで支払って土地を購入するオプションがあります。",
    "yearly": "毎年",
    "Unknown": "不明",
    "Claim processing may take up to 2 minutes. Thank you for your patience.": "請求の処理には2分までかかる場合があります。お待ちいただいてありがとうございます。",
    "1 Person": "一人",
    "2 Persons": "二人",
    "Extended Referral Link": "拡張参照リンク",
    "Enter a username": "ユーザー名を入力してください",
    "Possible only for usernames in your binary team!": "あなたのバイナリーチームのユーザー名にのみ可能!",
    "Select Team": "チームを選択",
    "Create Link": "リンクを作成",
    "New users will be placed under in the team": "新しいユーザーは{p}のチームに{n}の下に位置されます",
    "Invalid username!": "無効なユーザー名です!",
    "Left team": "左のチーム",
    "Right team": "右のチーム",
    "Distribution In Progress": "配布中",
    "Next Binary Bonus Distribution In": "次のバイナリーボーナス配布日まで",
    "Matching Bonus Commissions": "マッチング ボーナス コミッション",
    "Binary Commissions": "バイナリーコミッション",
    "Binary Weekly Cap": "バイナリー ウィークリー キャップ",
    "Binary Cycles": "バイナリーサイクル",
    "Matching Bonus": "バイナリーボーナス",
    "Incomes from Product Autoships": "製品オートシップからの収入",
    "Missed Commissions": "見逃したコミッション",
    "Plus Max Weekly Payout": "プラス最大毎週払い",
    "Import Token With Address": "アドレス付きトークンをインポート",
    "Import Supported Token": "サポートされているトークンをインポート",
    "Shop": "ショップ",
    "Filtrar por": "フィルター",
    "My Purchases": "私の購入",
    "Purchases for other members": "他のメンバーのための購入",
    "Product Autoship Purchases": "製品のオートシェイプ購入",
    "Sort By": "並べ替え基準",
    "Top up": "トップアップ",
    "Attach your miners": "あなたのマイナーを添付",
    "Buy parcels": "パーセルを購入",
    "Number of parcels you want to buy": "購入したいパーセルの数",
    "Use utility balance": "utilityの残高を使用する",
    "My Products": "私の製品",
    "USDT Amount": "USDTアマウント",
    "Next Matching Bonus Distribution In": "次回のマッチング ボーナスの配布まで",
    "Please top up your wallet with AMOUNT_CURRENCY USDT for upgrade.": "アップグレードするには、あなたのウォレットに{AMOUNT_CURRENCY} USDTをトップアップしてください。",
    "Please note that worth of USDT is required to complete the transaction.": "トランザクションを完了するには、{n}相当のUSDTが必要であることに注意してください。",
    "Incentive Ends In:": "インセンティブの終了まで:",
    "Bonus History": "ボーナス履歴",
    "Incentive has ended": "インセンティブが終了しました",
    "Direct Sales Turnover": "直接セールスターンオーバー",
    "Commissions are paid out following a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "手数料は70/30の比率で支払われ、70%がステーブルコイン、30%がGYMNETトークンで支払われます。",
    "(effective as of 28.02.2024)": "(2024年2月28日より有効）",
    "Free Product Autoship": "無料の製品オートシップ",
    "10% Binary Commission": "10% バイナリ手数料",
    "Enjoy More, Benefit More!": "もっと楽しんで、もっとメリットを！",
    "To qualify for Binary Commissions, you must have a new member with at least $100 on your right team and another on your left team.": "バイナリー コミッションの資格を得るには、右のチームに少なくとも 100 ドルを持つ新しいメンバーが必要で、左のチームにも別のメンバーが必要です。",
    "Buy products for at least $100 within 30 days to receive these benefits.": "これらの特典を受け取るには、<span>30 日</span>以内に <span>$100</span> 以上の商品を購入してください。",
    "Getting started": "はじめる",
    "Confirm your email to continue": "続行するにはメールを確認してください",
    "Resend confirmation email": "確認メールを再送信",
    "Super Bundle Special Discount Expires In": "スーパー バンドル特別割引の有効期限は です",
    "Done": "終わり",
    "Get your bundle": "バンドルを入手",
    "Gain Fresh Perspectives and Exclusive Benefits": "新鮮な視点と限定特典を獲得",
    "Super Bundle R8": "スーパーバンドル R8",
    "Super Bundle R17": "スーパーバンドル R17",
    "Starter Bundle": "スターターバンドル",
    "Travel": "トラベルプロモーション",
    "Cash Promo": "現金プロモーション",
    "Monthly Product Autoship is required to be an Affiliate Partner and earn commissions.": "アフィリエイト パートナーになってコミッションを獲得するには、Monthly Product Autoship が必要です。",
    "Affiliate": "アフィリエイト",
    "Recommended": "推奨",
    "Binary Commissions based on the rank": "ランクに基づくバイナリーコミッション",
    "Customer": "お客様",
    "NFT Turbo": "NFTターボ",
    "Purchase Digital Land": "デジタルランドを購入する",
    "Participate in Partnership Deals": "パートナーシップ取引に参加する",
    "Receive minting Rewards": "鋳造報酬を受け取る",
    "Up to": "最大 {n}%",
    "Weekly Distribution": "毎週の配信",
    "A parcel with minting power will generate rewards in Filecoin tokens, which will be distributed every Monday.": "鋳造力のある小包はファイルコイントークンで報酬を生成し、毎週月曜日に配布されます。",
    "It seems that a some request is already pending. Please check your wallet app or extension and try again.": "すでにいくつかのリクエストが保留されているようです。 ウォレット アプリまたは拡張機能を確認して、もう一度お試しください。",
    "Add miner NFT to wallet": "Miner NFT をウォレットに追加します",
    "Go to My NFTs": "私のNFTに移動",
    "Thank you for your participation": "ご参加ありがとうございます",
    "No owner": "所有者なし",
    "Ownership of this bundle is limited to one per user. Please purchase for yourself and for those who do not have it.": "このバンドルの所有権は、ユーザーごとに 1 つに制限されます。 ご自身用、お持ちでない方はぜひご購入ください。",
    "Buy parcels with minting power and generate income.": "鋳造の力で区画を購入し、収入を生み出します。",
    // Mystery box
    "Limited time offer": "期間限定キャンペーン",
    "See Options": "オプションを見る",
    "Please select one of the currencies": "通貨をお選びください",
    "Current balance": "現在の残高",
    "Try your luck": "運試し",
    "Offer ends in": "キャンペーン終了日",
    "Parcel and Dual Miner": "小包 と デュアルマイナー",
    "Mystery Box": "ミステリーボックス",
    "Try your luck and win incredible Bundles, Parcels and Dual Miners to boost your earnings!": "運を試して信じられないほどのバンドル、小包、デュアル マイナーを獲得して収益を増やしましょう!",
    "from MIN to MAX": "<b>{min}</b> から <b>{max}</b> まで",
    "Choose a Box and Discover Rewards": "ボックスを選択して特典を見つけてください",
    "Prizes you can win": "獲得賞品",
    "Item": "アイテム",
    "Chance": "チャンス",
    "Promos": "プロモーション",
    "Mystery Box results": "ミステリーボックスの結果",
    "Total reward value": "報酬総額",
    "My Rewards": "私の報酬",
    "Rewards Community Claimed": "コミュニティが受け取った報酬",
    "Hero image": "ヒーローイメージ",
    "No rewards yet": "まだ報酬はありません",
    "No winners yet": "まだ勝者はいません",
    "While no lucky participants have claimed their prizes yet, the excitement continues to build.": "まだ賞品を獲得した幸運な参加者はいませんが、興奮は高まり続けています。",
    "Mystery Box is Here!": "ミステリーボックスが登場！",
    "Be the First to Discover Mystery Rewards": "誰よりも早くミステリー報酬を発見しましょう",
    "Select Balance": "バランスの選択",
    "Current Balance": "経常収支",
    "Opening Mystery Box": "謎の箱を開ける",
    "Oops, not this time 😔": "おっと、今回は違います😔",
    "Luck is on your side. Enjoy your prize!": "運はあなたの味方です。賞品を楽しもう！",
    "The more you try, the better your chances of winning. Keep going!": "挑戦すればするほど、勝つ可能性は高くなります。 続けて！",
    "Try another box": "別のボックスを試してください",
    "Go to My Products": "私の製品に移動",
    "See promo results": "プロモ結果を見る",
    "Payment enabled": "支払いが可能",
    "Please select the wallet that is attached to your account.": "アカウントに関連付けられているウォレットを選択してください。",
    "Explore rewards": "リワードを見る",
    "View rewards claimed by you and the community": "あなたやコミュニティから請求されたリワードを見る",
    "Open a Mystery Box to get rewards starting from Parcels to Super bundles.": "ミステリーボックスを開けて、小包からスーパーバンドルまでのリワードを手に入れよう。",
    "Insufficient funds, please choose another box.": "資金不足のため、他のボックスを選択してください。",
    "Blockchain error": "ブロックチェーンエラー",
    "Rewards claimed": "報酬請求",
    "We have encountered difficulties while processing the result of the reward. Please contact our support team for prompt assistance.": "報酬の結果処理中に問題が発生しました。サポートチームまでご連絡ください。",
    "Boxes opened": "開封された箱",
    // Mystery box

    // Partnership details
    "About": "について",
    "Offer": "オファー",
    "Social Media": "ソーシャルメディア",
    "Additional Content": "追加コンテンツ",
    "Photo": "写真",
    "Video": "ビデオ",
    // Partnership details

    // Miner Rewards
    "Single Miners": "シングル・マイナー",
    "price today": "今日の価格",
    "Estimated daily rewards": "毎日の推定報酬",
    "day": "日",
    "day at current rate": "現在のレートでの日",
    "Estimated rewards until 2025": "2025 年までの推定報酬額",
    "at current rate": "現在のレートで",
    "Estimated rewards from today to December 1, 2025. It may change based on your shares in the pool.": "本日から2025年12月1日までの推定報酬。プールのシェアによって変動する可能性があります。",
    "Expected rewards calculator": "期待報酬計算ツール",
    "price": "価格",
    "My total GYMNET value in USD": "私のGYMNETの合計価値（USD）",
    "claimed": "主張した",
    "My Balances": "私の残高",
    "Tokens to be burned": "燃やされるトークン",
    "You dont have any Claimed or Pending GYMNET rewards.": "申請済みまたは保留中の GYMNET 報酬はありません。",
    "Claim now": "今すぐ請求してください",
    "No claimed GYMNET rewards are found.": "申請されたGYMNET特典は見つかりませんでした。",
    // Miner Rewards申請されたGYMNET特典は見つかりませんでした。

    "Please note that the leg with the lesser amount of points is called weak leg and the one with the higher amount of points is called strong or power leg, and it can change over time based on which leg the most turnover is generated.": "ポイントの少ないレッグは「ウィークレッグ」と呼ばれ、ポイントの多いレッグは「ストロングレッグ」または「パワーレッグ」と呼ばれ、どちらのレッグが最もターンオーバーしたかに基づいて時間の経過とともに変化することに注意してください。 が生成されます。",
    "Mystery Box price": "ミステリーボックス価格",

    "Registration Date": "登録日",
    "First Purchase Date": "最初の購入日",
    "Product Autoship Valid Until": "製品オートシップの有効期限",

    // Suspicous activity
    "You have one attempt left": "あと1回トライできます。その後、このアカウントでのトライは一時的に制限されます。",
    "You have no attempts left": "このアカウントの試行回数が残っていません。{minutesText} <b>分</b>後に再試行するか、パスワードをリセットしてください。",
    "Sorry, you’ve been blocked": "ブロックされました",
    "Your IP address has been temporarily blocked for security reasons.": "あなたのIPアドレスはセキュリティ上の理由で一時的にブロックされました。すぐに自動的にリダイレクトされますので、しばらくお待ちください。",
    "If you believe this was a mistake, please contact us through our Customer Support.": "間違いだと思われる場合は、<a href=\"mailto:support@gymstreet.io?subject=Suspicious Activity&body=I am blocked due to suspicious activity, but I think It's a mistake\" class=\"customer-support-button\">カスタマーサポート</a>までご連絡ください。</a>",
    // Instant commissions
    "Own Deposit": "私の預金",
    "years ago": "年前",
    "Rank Achievement Bonus": "ランク達成ボーナス",
    "Rank achievement bonus eligibility is based on rank achieved through qualifications.": "ランク達成ボーナスの資格は、資格によって達成されたランクに基づいて決まります。",
    "Bonuses": "ボーナス",
    "Bonuses are paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET tokens": "ボーナスは70/30の比率で支払われ、70%がUSDT、30%がGYMNETトークンで支払われます。",
    "Rank current": "現在のランク {n}",
    "You will receive the bonus after the incentive is over, and you can view it in the 'My Wallet' section.": "インセンティブ終了後にボーナスを受け取ることができます。\"マイウォレット\"セクションで確認できます。",
    "You must have at least RANK partners, with at least one in a different leg.": "最低でも{ランク}のパートナーを持っている必要があり、それらは左右それぞれのサイドに少なくとも1人ずついる必要があります。",
    "Additional Assets": "その他の資産",
    "Back to My wallet": "マイウォレットに戻る",
    "Bonus points": "ボーナスポイント",
    "Fee": "手数料",
    "Bonus Details": "ボーナスの詳細",
    "Details / weekly cap": "詳細 / １週間の上限",
    "User / amount": "ユーザー　/　量",
    "Extended Ref Link": "拡張された参照リンク",
    "Leadership": "リーダーシップ",
    "Travel Incentive": "インセンティブ旅行",
    "Unlock the full potential of your Filecoin rewards as they come to life following your purchase of any of our special bundles. Once your Filecoin Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "スペシャルバンドルの購入後、あなたの Filecoin 報酬の可能性を最大限に引き出しましょう。Filecoin の残高が表示されるようになると、報酬総額、受け取り済みの報酬、見込み報酬の概要にアクセスできるようになります。さらに、カウントダウンタイマーが次の報酬配布時間を表示するので、報酬を逃すことはありません。",
    "is left to qualify": "条件達成まであと${n}です",
    "Check transactions": "取引を確認",
    "Direct partner own purchases": "ダイレクトパートナーによる購入",
    "Each of your direct partners on the left and right legs needs to generate a turnover value of $100.00.": "左サイドと右サイドのダイレクトパートナーがそれぞれ100ドルのターンオーバーを生み出す必要があります。",
    "Participation to GYMSTREETs Elite Vote": " GYMSTREETsエリート投票への参加",
    "Prioritization ends in": "優先順位設定の終了まで",
    "Start Prioritizing": "優先順位設定を開始",
    "Your changes have been saved": "変更内容が保存されました",
    "features are still remaining": "まだ<b>{n}個の機能</b>が残っています",
    "feature is still remaining": "まだ<b>{n}個の機能</b>が残っています",
    "All prioritized": "すべての優先順位付けが完了しました",
    "GYMSTREETs Elite Vote Statistics": "GYMSTREET のエリート投票統計",
    "Just NUM features to go! Complete your prioritization to help us shape community's vision.": "あとNUM個の機能が残っています！コミュニティのビジョンを形成するために優先順位付けを完了してください。",
    "You've prioritized the features. Please review your choices and hit Submit to finalize.": "機能の優先順位付けを行いました。あなたの回答を確認し、<b「提出」</b>をクリックして最終確定してください。",
    "Back to All Features": "すべての機能に戻る",
    "Changes saved": "変更が保存されました",
    "No changes": "変更はありません",
    "Submit": "提出",
    "priority": "優先順位",
    "Set priority": "優先順位を設定",
    "Feature details": "機能の詳細",
    "Select a feature to see more info": "詳細情報を表示する機能を選択",
    "Prioritization Submitted": "優先順位付けが送信されました",
    "The results will be available after the voting period ends. You'll be able to change your priorities until then.": "結果は投票期間終了後に確認可能になります。それまでの間、優先順位を変更できます。",
    "Go to Career Status page": "キャリアステータスページに移動",
    "Keep Prioritizing": "優先順位付けを続ける",
    "Continue Prioritizing": "優先順位付けを続行",
    "Review and Submit": "内容を確認して送信",
    "Thinking of reprioritizing?": "優先順位を訂正したいですか？",
    "You've already submitted your priorities. If you want to reprioritize them, please make sure you hit Resubmit.": "すでに優先順位が提出されています。再度優先順位をつける場合は、必ず<b>「再提出」</b>をクリックしてください。",
    "Got it": "わかりました",
    "Resubmit": "再提出",
    "Priorities submitted": "優先順位が提出されました",
    "Submitted": "提出されました",
    "View Statistics": "統計を表示",
    "Continue Prioritization": "優先順位付けを続行",
    "Reprioritize": "優先順位を再設定",
    "Start Prioritization": "優先順位付けを開始",
    "features": "機能",
    "feature": "機能",
    "GYMSTREET Elite Vote": "GYMSTREエリートの投票",
    "Thanks for submitting! You can still reprioritize and resubmit your choices until the voting period ends.": "提出ありがとうございます！投票期間が終了するまで、優先順位を再設定して選択を再提出することができます。",
    "Youre not done yet. Continue prioritizing the upcoming features and submit your final choices.": "まだ完了していません。今後の機能の優先順位付けを続け、回答を提出してください。",
    "Exclusive for Platin and higher rank members. Prioritize the features you want to see next on GYMSTREET.": "プラチナおよびそれ以上のランクのメンバー限定。GYMSTREETにあって欲しい機能に優先順位を付けてください。",
    "View rules": "ルールを表示",
    "Prioritization has ended. See the community's vote results.": "優先順位付けが終了しました。コミュニティの投票結果をご覧ください。",
    "Stay tuned": "お楽しみに",
    "See current community prioritization statistics below. In 7 days, we'll also announce our decision right here, revealing the selected new features and their estimated release dates.": "現在のコミュニティの優先順位付け統計をご覧ください。7日後に、ここで決定が発表され、選択された新機能とそれらのリリース予定日が公開されます。",
    "Terms and Conditions for GYMSTREETs Elite Vote": "GYMSTREETのエリート投票の利用規約",
    "The period for earning mining rewards in BITOPEX tokens has officially concluded. Thanks for your participation. Enjoy your BPX earnings.": "BITOPEX トークンでマイニング報酬を獲得できる期間は正式に終了しました。 ご参加いただきありがとうございます。 BPX での収益をお楽しみください。",
    "We're enhancing our claim feature, so it will be offline, for the next {hours} hours. Thank you and stay tuned!": "クレーム機能を強化しているため、今後{hours}時間はオフラインとなります。ありがとうございます！",
    "Eligibility for a one-time 10% commission, based on the total turnover generated from direct sales, requires referring team members and achieving a turnover of $5,000 or more in direct sales.": "直接販売から得られた総売上高に基づいて 10% の 1 回限りの手数料を受け取る資格を得るには、チームメンバーを紹介し、直接販売で 5,000 ドル以上の売上高を達成する必要があります。",

    "Turnover Incentive": "離職奨励金",
    "Available For Limited time only": "<b>期間限定</b>でのみ利用可能",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving $20,000 in direct sales.": "直接販売で $20,000 を達成すると、無料の R8 バンドルを受け取る資格があることに注意してください。",

    "Minting Rewards": "報酬の鋳造",
    "Please check transactions if your {asset} balance is not visible": "{asset}残高が表示されない場合は取引を確認してください",
    "In order to send {asset} you need to select {chain} network and wallet that is connected to your account.": "{asset} を送信するには、アカウントに接続されている {chain} ネットワークとウォレットを選択する必要があります。",
    "Cash bonus eligibility is based on rank achieved through qualifications. Participants qualifying with over 50% before March 4th, 2024, will not receive the bonus.": "現金ボーナスの資格は、資格によって達成されたランクに基づいて決定されます。 2024 年 3 月 4 日までに 50% 以上の参加資格を獲得した参加者はボーナスを受け取りません。",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving 100k in team purchases.": "チーム購入が 100,000 に達すると、無料の R8 バンドルを受け取る資格があることに注意してください。",
    "Member": "Affiliate",
    "Trainee": "Trainee",
    "Rookie": "Rookie",
    "Silver": "Silver",
    "Gold": "Gold",
    "Platinum": "Platinum",
    "Double Platinum": "Double Platinum",
    "Diamond": "Diamond",
    "Double Diamond": "Double Diamond",
    "Red Diamond": "Red Diamond",
    "White Diamond": "White Diamond",
    "Crown Diamond": "Crown Diamond",
    "Platin": "Platinum",
    "Double Platin": "Double Platinum",
    "Single Pool": "Single Pool",
    "Explorer": "Explorer",
    "Mastery": "Mastery",
    "Convert": "変換する",
    "Convert your unused miners into hashpower to earn rewards in Gymnet and Filecoin": "未使用のマイナーをハッシュパワーに変換して、ジムネットとファイルコインで報酬を獲得しましょう。",
    "The 50% Turnover Rule is applied to this incentive": "このインセンティブには 50% の売上ルールが適用されます",
    "Features expected timeframes revealed": "機能の予想される期間が明らかに",
    "Find estimated timelines for features prioritized by our community and aligned with our strategy.": "コミュニティによって優先され、戦略に沿った機能の推定タイムラインを見つけます。",
    "Estimated timeframe": "推定期間",
    "Undefined": "未定義",
    "No Longer Valid": "もう有効ではない",
    "You need to upload a photo of your document Please upload a png or jpeg, not a pdf.": "ドキュメントの写真をアップロードする必要があります \n PDF ではなく、<span style='color: #1A6C99'>png または jpeg</span> をアップロードしてください。",
    "File size exceeds {n} MB.": "ファイルサイズが {n} MB を超えています。",
    "Please upload a legible document in png or jpeg format.": "判読可能なドキュメントを <span style='color: #1A6C99'>png または jpeg 形式でアップロードしてください。</span>",
    "Deposit Tokens": "デポジットトークン",
    "Statistics": "統計",
    "Super Bundle Rank Benefits": "スーパーバンドルランク特典",
    "Matching bonus up to NUM levels": "最大 {num} レベルのマッチングボーナス",
    "Up to NUM levels": "最大 {num} レベル",
    "Left leg partner": "左足のパートナー",
    "Right leg partner": "右足のパートナー",
    "Commission Activation Requirements": "コミッションのアクティベーション要件",
    "Up to 1 level": "1レベルまで",
    "Bonus is paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET": "ボーナスは 70/30 の比率で支払われます (70% が USDT、30% が GYMNET)",
    "Current Turnover": "現在の売上高",
    "You will receive the bonus once the incentive period is over and it will be deposited directly into your wallet.": "インセンティブ期間が終了するとボーナスを受け取り、ウォレットに直接入金されます。",
    "Purchase Amount": "購入金額",
    "Spring": "春",
    "Spring Incentive": "春のインセンティブ",
    "Commission Earned": "獲得したコミッション: <b>${n}</b>",
    "Bonus Earned": "ボーナス獲得: <b>${n}</b>",
    "Earn 1 PAP point for every dollar spent on purchases using a utility balance or when you purchase a product autoship.": "ユーティリティ残高を使用した購入、または製品の自動配送を購入すると、1 ドルごとに 1 PAP ポイントを獲得できます。",
    "Deposit to Single pool": "Single pool への入金",
    "Max": "最大",
    "Received": "受取",
    "About voting rights": "投票権について",
    "Your first deposit must be over $25": "最初の入金は25ドル以上である必要があります。",
    "Staking period ends": "ステーキング期間終了",
    "December": "12月",
    "Deposit & Stake": "入金とステーク",
    "Purchasing the card requires passing the KYC procedure.": "カード購入には KYC 手続きの完了が必要です。",
    "Continue to KYC": "KYC へ",
    "KYC Verification": "KYC 認証",
    "Step": "ステップ {n}",
    "Upload ID": "IDのアップロード",
    "Male": "男性",
    "Female": "女性",
    "Gender": "性別",
    "ID Type is required": "IDの種類は必須です。",
    "ID Document Language is required": "ID 書類言語は必須です。",
    "ID Expiration Date is required": "ID 有効期限 は必須です。",
    "ID Issue Date is required": "ID 発行年月日 は必須です。",
    "State must be at least 3 characters": "県は３文字以上である必要があります。",
    "ID Type": "IDの種類",
    "National Identity Card": "身分証明書",
    "Driver's License": "運転免許証",
    "Passport": "パスポート",
    "ID Document Language": "ID 書類言語",
    "ID Number be at least 3 characters": "ID ナンバーは３文字以上である必要があります。",
    "ID Issue Date": "ID 発行年月日",
    "ID Expiration Date": "ID 有効期限",
    "You need to upload a photo (up to mb) of your document Please upload an image not a pdf.": "書類の写真をアップロードしてください。 <span style='color: #1A6C99'>(最大 {n}mb)</span>  \n pdfではなく画像ファイル形式でアップロードしてください。", // DONT TRANSLATE <span style='color: #1A6C99'>, {n}mb)</span>
    "File size exceeds 3.3 MB.": " ファイル容量が3.3 MBを超えています。",
    "Front side of ID": "ID前面",
    "Back side of ID": "ID背面",
    "Upload proof of address document": "住所証明をアップロードしてください。",
    "Upload proof of address, which must not be older than 75 days.": "発行から75日以内の住所証明をアップロードしてください。",
    "Acceptable forms of proof of address include a credit card statement, utility bill, bank statement or bank letter.": "住所証明として認められるのは、クレジットカードの明細書、公共料金の請求書、銀行の明細書、銀行からの通知などです。",
    "Address Document Type is required": "住所書類の種類は必須です。",
    "Document Issued by is required": "書類の発行元は必須です。",
    "Document Issued Date is required": "書類の発行年月日は必須です。",
    "Address Document Type": "住所書類の種類",
    "Credit Card Statement": "クレジットカード明細書",
    "Utility Bill": "公共料金の請求書",
    "Bank Statement": "銀行残高証明書",
    "Bank Letter": "銀行からの通知",
    "Document Issued by": "書類発行元",
    "Document Issued Date": "書類発行年月日",
    "Please upload a legible document in image format not pdf, up to mb in size.": "読みやすい書類をpdf形式ではなく画像形式で、<span style='color: #1A6C99'>最大 {n} mb</span> でアップロードしてください。",
    "Submit for Verification": "提出",
    "Your documents have been submitted for verification. Once verification has been approved, you can continue your purchase.": "あなたの書類が認証のために提出されました。認証が承認されると、購入を続けることができます。",
    "You need GYMNET to make a deposit in Single Pool": "Single Poolに預金するには <b>{n} GYMNET</b> が必要です。",
    "Security Assurance": "セキュリティ\n保障",
    "Global Accessibility": "グローバル\nアクセシビリティ",
    "Affiliate Earnings": "アフィリエイト\n収益",
    "Effortless Integration": "楽に\n統合",
    "Cards": "カード",
    "Overview": "概要",
    "Information": "情報",
    "Invoices": "請求書",
    "KYC Status": "KYC ステータス",
    "Try again": "再試行",
    "Apply here": "お申し込みはこちら",
    "Not verified": "未確認",
    "Pending verification": "確認中",
    "Rejected": "確認失敗",
    "Verified": "確認済み",
    "Next service fee in": "次回の手数料は",
    "No active membership": "アクティブメンバーシップ無し",
    "Card": "カード",
    "Card number": "カードナンバー",
    "PIN Set Successfully": "暗証番号の設定完了",
    "Go to Dashboard": "ダッシュボードへ",
    "4-digit PIN": "4桁の暗証番号",
    "Re-enter 4-digit PIN": "4桁の暗証番号を再入力",
    "Set PIN": "暗証番号を設定",
    "Your account is now verified and you can continue your purchase of the bundle.": "アカウントが認証されました。バンドル購入に進むことができます。",
    "Continue purchase": "購入へ進む。",
    "Your documents have been rejected from KYC provider.": "提出された書類はKYCプロバイダに拒否されました。",
    "Please try again": "再試行してください。",
    "Your KYC is approved": "KYC認証が承認されました。",
    "Your KYC is rejected": "KYC認証が承認されませんでした。",
    "File is required": "ファイルが必要です",
    "Seamless Spending with GYMSTREET Cards": "GYMSTREETカードでシームレスに使う",
    "Our cards open a world of smooth transactions and rewarding opportunities for you.": "私たちのカードは、スムーズな取引と特典への扉を開きます。",
    "Deposit": "預金",
    "Connected": "接続済み",
    "No deposit fee for burn": "バーンのための預託金無し。",
    "Min amount": "最少額",
    "Total GYMNET locked": "ロックされた合計GYMNET",
    "APR": "APR（年率）",
    "Daily APR": "デイリー APR（日率APR）",
    "Total own claimed": "獲得総額",
    "Reward per block": "ブロックごとの報酬",
    "My Deposit": "預金額",
    "Locked period until Dec 2027": "ロック期間は2027年12月まで",
    "With Locking Period": "ロック期間あり",
    "Total Gymnet Deposit": "Gymnet 総預金額",
    "Gymnet Rewards": "Gymnet 報酬",
    "Total Gymnet earned": "Gymnet 獲得総額",
    "Active holdings": "アクティブな資産",
    "With locking period": "ロック期間あり",
    "Withdrawn holdings": "引き出した資産",
    "Locked": "ロックされた",
    "Unlocked": "ロック無し",
    "Deposit amount": "預金額",
    "Claimed rewards": "獲得報酬",
    "Start date": "開始日",
    "End date": "終了日",
    "Withdraw": "引き出す",
    "First name": "氏名",
    "Last name": "姓",
    "Address": "住所",
    "State": "県",
    "Phone number": "電話番号",
    "Birth Date": "生年月日",
    "Submit & Continue": "送信して続ける",
    "ID Number": "ID 番号",
    "ID Issued By": "ID 発行元",
    "Proof of address document": "住所証明書類",
    "Proof of address document (optional)": " 住所証明書類(任意)",
    "Card Types Tailored to Your Needs": "お客様のニーズに合わせたカードタイプ",
    "Card design": "カードデザイン",
    "Card information": "カード情報",
    "Your go-to choice for daily transactions and affiliate commissions, offering practical benefits and reliable service within GYMSTREET and beyond.": "日々の取引やアフィリエイト報酬の獲得にピッタリ、GYMSTREET内外で実用的なメリットと信頼性の高いサービスを提供しています。",
    "Elevate your financial experience with this premium option with expanded benefits, designed for those who seek luxury and exclusivity.": "贅沢で特別なものを求める方のために設計された、特典の充実したこのプレミアム・オプションで、金融体験をさらに高めてください。",
    "Cards come in Bundles": "カードはセットになっています。",
    "Explorer card and 2 parcels": "Explorerカードと2つの土地区画",
    "Mastery card and 3 parcels": "Masteryカードと3つの土地区画",
    "Getting Started with Your GYMSTREET Card Experience": "GYMSTREETカード体験を始める。",
    "Start securely with our simple KYC process, ensuring a safe and personalized Card experience.": "簡単なKYCプロセスで安全に始められ、安全でパーソナライズされたカード体験をお約束します。",
    "Stake the required GYMNET tokens - 500 for Explorer or 1500 for Mastery - to initiate your application.": "必要なGYMNETトークン（Explorerは500枚、Masteryは1500枚）をステークして申請を開始する。",
    "Order Your Card": "カードを注文する",
    "Make your final decision on card type and place your order buying one of the 2 card bundles.": "カードタイプを最終決定し、2枚のカードセットから1枚を選んで注文する。",
    "Get Your Card": "カード取得",
    "Anticipate the arrival of your new financial companion, securely shipped and ready to integrate into your life.": "安全に発送され、あなたの生活に溶け込む準備が整った、新しいファイナンシャル・コンパニオンの到着をご期待ください。",
    "Use Your Card": "カードを使用する",
    "Begin making purchases with your card and start earning affiliate commissions and more.": "あなたのカードで買い物を始め、アフィリエイト報酬を獲得しましょう。",
    "Card Operation Fees": "カード手数料",
    "Fee Type": "料金タイプ",
    "Both Cards (Explorer & Mastery)": "両カード (Explorer ＆ Mastery)",
    "POS Transactions": "POS 取引",
    "ATM Withdrawals": "ATM 引き出し",
    "Foreign Currency Conversion": "外貨換算",
    "Card Replacement": "カード交換",
    "Simple, Transparent Pricing": "シンプルで透明性のある価格設定",
    "Monthly Membership Fee": "月会費",
    "Monthly Membership": "月額会員",
    "Get Your Card Now": "今すぐカードを手に入れる",
    "Choose the card that best suits your lifestyle. With straightforward fees, a seamless onboarding process, and an 11-level deep affiliate commission reward system, these cards are your partner in financial empowerment.": "あなたのライフスタイルに最適なカードをお選びください。わかりやすい手数料、シームレスな入会手続き、11段階のアフィリエイト報酬システムなど、これらのカードはあなたの経済力を高めるパートナーです。",
    "How do I get a card?": "カードはどのように入手できますか？",
    "Start by filling out the KYC form on our site. After we verify you, you'll need to deposit tokens for the card you want in Single Pool: 500 for Explorer or 1500 for Mastery. Then, you can order your card.": "まずは当サイトのKYCフォームにご記入ください。認証完了の後、シングルプールに欲しいカードのトークンを入金する必要があります：Explorer は500ドル、Masteryは1500ドルです。その後、カードを注文することができます。", // DONT TRANSLATE (Explorer & Mastery, KYC )
    "How many tokens do I need to deposit for each card?": "各カードには何枚のトークンを入金する必要がありますか？",
    "You need 500 tokens for the Explorer card and 1500 tokens for the Mastery card.": "Explorer カードには500トークン、Mastery カードには1500トークンが必要です。", // NOT TRANSLATE (Explorer & Mastery)
    "When will my card arrive?": "カードはいつ届きますか？",
    "After you order, your card will be sent out and should arrive quickly, so you can start using it soon.": "ご注文後、カードは発送され、すぐに到着します。",
    "Can I earn affiliate commissions with my card?": "カードでアフィリエイト報酬を得ることはできますか？",
    "Yes, activate your card, and you can begin earning through our 11-level deep affiliate program. Check our website for how the commissions work.": "カードを有効にしていただくと、11段階のアフィリエイト・プログラムで報酬を得ることができます。コミッションの仕組みはウェブサイトをご覧ください。",
    "Total Value At Time of Deposit": "預入時の総額",
    "Current Value": "現在の価値",
    "My claimed GYMNET rewards": "獲得したGYMNET報酬",
    "My pending GYMNET rewards": "未獲得のGYMNET報酬",
    "Personal Information": "個人的情報",
    "First name is required": "名は必須です",
    "Last name is required": "姓は必須です",
    "Address is required": "住所は必須です",
    "Please enter your email address": "メールアドレスを入力してください",
    "Phone number is required": "電話番号は必須です",
    "Date of birth is required": "生年月日は必須です",
    "ID Number is required": "ID番号は必須です",
    "ID Issued by is required": "発行元のIDは必須です",
    "Select asset to auto swap and stake": "自動スワップしステークする資産を選択",
    "Giving swap allowance": "スワップを許可しています。",
    "Swap allowance given": "スワップが許可されました。",
    "Swapping tokens to GYMNET": "トークンをGYMNETに交換中",
    "Tokens swapped to GYMNET": "トークンをGYMNETに交換完了",
    "Giving stake allowance": "ステークを許可しています。",
    "Stake allowance given": "ステークが許可されました。",
    "Staking to single pool": "Single pool へのステーキング",
    "Staked to single pool": "Single pool へのステーキング完了",
    "1. Swap allowance (Only for USDT)": "1. スワップ許可 (USDTのみ)",
    "2. Swap to GYMNET": "2.GYMNET にスワップ",
    "3. Stake allowance": "3. ステーク許可",
    "4. Stake": "4. ステーク",
    "Actual balance:": "実際の残高:",
    "The transaction gas fee is reserved from the actual BNB balance.": "取引ガス料金は実際のBNB残高から差し引かれる。",
    "Insufficient balance to make a swap": "スワップを行うための残高が不足しています。",
    "Transactions gas fee is considered in BNB available balance": "取引ガス代はBNB残高から支払われます。",
    "We will ask for allowance and try to swap your tokens to GYMNET, and after, stake them in single pool.": "ユーザーのトークンをGYMNETにスワップする許可を求め、その後、シングルプールにステークします。",
    "Auto swap and stake": "自動スワップ＆ステーク",
    "Continue transactions": "取引を続ける",
    "Pause": "一時停止",
    "You need more GYMNET to make a deposit in Single Pool": "Single Pool に入金するにはあと <b>{n} GYMNET</b> が必要です。",
    "Swap & Stake": "スワップ＆ステーク",
    "You need more USDT to purchase a bundle": "セット購入にはあと <b>{n} USDT</b> が必要です。",
    "Topup": "チャージ",
    "Proceed anyways": "理解した上で次に進む",
    "Explorer Card": "Explorerカード",
    "Mastery Card": "Masteryカード",
    "Monthly spending max": "月々の支出上限",
    "Monthly Balance limit": "月間残高限度額",
    "Go to Cards": "カードに移動",
    "Contact Support": "サポート問い合わせ先",
    "Deposit Fee": "入金手数料",
    "Switch to 'Utility' to view the price for each product with USDT & Utility. To partially pay for a product using Utility, you need to have enough USDT and Utility in your balance.": "「Utility」に切り替えると、USDT と Utility を使用した各商品の価格が表示されます。 Utility を使用して商品の一部を支払うには、残高に十分な USDT と Utility が必要です。",
    "Deposit with locking period": "ロック期間付きの入金",
    "The state must be in the form of a two-letter abbreviation in uppercase.": "州は大文字の 2 文字の省略形である必要があります。",
    "You need to upload a photo in JPEG or PNG format, not a PDF. The document can be in either English or Spanish.": "写真は PDF ではなく、<span style='color: #1A6C99'>jpeg</span> または <span style='color: #1A6C99'>png</span> 形式でアップロードする必要があります。 \n ドキュメントは<span style='color: #1A6C99'>英語</span>または<span style='color: #1A6C99'>スペイン語</span>のいずれかで作成できます。",
    "You need to upload a legible document in jpeg or png format, not a pdf. The document can be in either English or Spanish.": "PDF ではなく、<span style='color: #1A6C99'>jpeg</span> または <span style='color: #1A6C99'>png</span> 形式で読みやすいドキュメントをアップロードする必要があります。 \n ドキュメントは<span style='color: #1A6C99'>英語</span>または<span style='color: #1A6C99'>スペイン語</span>のいずれかで作成できます。",
    "Commissions are paid out using a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "コミッションは 70/30 の比率で支払われ、70% がステーブルコイン、30% が GYMNET トークンで支払われます。",
    "This policy is effective as of September 25, 2023.": "このポリシーは、2023 年 9 月 25 日から発効します。",
    "Required turnover": "必要な売上高",
    "Switch to TOKEN to view the price for each product with USDT & TOKEN. To partially pay for a product using TOKEN, you need to have enough USDT and TOKEN in your balance.": "「{TOKEN}」に切り替えると、USDT と {TOKEN} を使用した各商品の価格が表示されます。 {TOKEN} を使用して商品の一部を支払うには、残高に十分な USDT と {TOKEN} が必要です。",
    "Alternatively, you can use your CURRENCY balance to cover the gas fee.": "または、{currency_yg} の残高を使用してガス料金を賄うこともできます。",
    "Insufficient funds: to continue with payment use Utility or Gymnet balance": "資金が不足しています: 支払いを続けるには、Utility または Gymnet 残高を使用してください",
    "Use": "使用",
    "Use Utility or Gymnet": "Utility または Gymnet を使用してください",
    "Achieved PRICE turnover": "${price} の売上高を達成しました",
    "Your current milestone": "現在のマイルストーン",
    "Reward History": "報酬履歴",
    "Leading Direct Partner": "主要な直接パートナー",
    "Remaining Direct Partners": "残りの直接パートナー",
    "Bonus Split": "ボーナス分割",
    "Monthly Countdown": "毎月のカウントダウン",
    "No direct partners": "直接のパートナーはいない",
    "To receive the reward, you must have an active product autoship.": "報酬を受け取るには、アクティブな製品オートシップが必要です。",
    "This incentive is subject to the 50% Turnover Rule.": "このインセンティブには 50% 離職率ルールが適用されます。",
    "Income From Product Authoship": "製品著作権からの収入",
    "Your documents have been submitted for verification. The bank may take up to 3 business days to review your application. Once approved, you can continue with your purchase.": "あなたの書類は確認のために提出されました。 銀行による申請の審査には最大 3 営業日かかる場合があります。 承認されたら、購入を続行できます。",
    "To purchase the card, you need to agree to the T&C and complete the KYC verification process.": "カードを購入するには、利用規約に同意し、KYC 検証プロセスを完了する必要があります。",
    "Only Latin letters are allowed": "ラテン文字のみ使用可能",
    "First name must be at least 3 characters": "名は 3 文字以上である必要があります",
    "First name should not exceed 10 characters": "名は 10 文字を超えてはなりません",
    "Should be only letters": "文字のみである必要があります",
    "Address must be at least 3 characters": "住所は 3 文字以上である必要があります",
    "Only latin letters, numbers and special characters are allowed": "ラテン文字、数字、特殊文字のみを使用できます",
    "Email is not valid": "メール アドレスが無効です",
    "Zip must contain only numbers with min length of 3": "郵便番号には 3 文字以上の数字のみを含めることができます",
    "The phone number must be at least 6 digits": "電話番号は 6 桁以上である必要があります",
    "The phone number must not exceed 12 digits": "電話番号は 12 桁を超えてはなりません",
    "Phone number should contain only numbers": "電話番号には数字のみを含めることができます",
    "ID Number must have at least 3 characters": "ID 番号は 3 文字以上である必要があります",
    "ID Issued by must have at least 3 characters": "ID 発行者は 3 文字以上である必要があります",
    "ID Issued by should not exceed 191 characters": "ID 発行者は 191 文字を超えてはなりません",
    "Last name must be at least 3 characters": "姓は 3 文字以上でなければなりません",
    "State is required": "州は必須です",
    "The incentive countdown starts at the beginning of each month and ends at the end of the month. If you do not qualify for the bonus within that month, you will need to start again in the next month.": "インセンティブのカウントダウンは毎月初めに始まり、月末に終了します。その月内にボーナスの資格を満たさなかった場合は、翌月から再度開始する必要があります。",
    "Activated": "有効化",
    "Deposit & fee commission": "預かり金および手数料",
    "Web3 wallet not connected. Please connect to proceed.": "Web3 ウォレットが接続されていません。続行するには接続してください。",
    "Total investors profit": "投資家の合計利益",
    "Commissions from vault investors": "ボールト投資家からの手数料",
    "Please Claim your rewards from to activate this section.": "このセクションを有効にするには、{n} から報酬を受け取ってください。",
    "Or use Button Below": "または下のボタンを使用してください",
    "Partner Activity": "パートナーアクティビティ",
    "Pending Commissions": "保留中のコミッション",
    "Binary Turnover": "バイナリー回転率",
    "Unilevel Turnover": "ユニレベルターンオーバー",
    "Right Direct Partners": "右ダイレクトパートナー",
    "Left Direct Partners": "左ダイレクトパートナー",
    "50% Compensation:": "50% 補償:",
    "When you reach the next rank, you will receive 50% of the lost bonus from the previous rank.": "次のランクに到達すると、前のランクで失われたボーナスの 50% を受け取ります。",
    "Please note that username can be changed only once in 6 months.": "ユーザー名は 6 か月に 1 回のみ変更可能です。",
    "Binary Turnover in weak leg": "弱い足でのバイナリー転換",
    "Unilevel turnover requirement": "ユニレベル回転率要件",
    "Connect a WEB3 wallet such as Metamask or Trustwallet and pay with {currency}. This process requires a small BNB fee ($0.30) to handle transaction costs!": "Metamask や Trustwallet などの WEB3 ウォレットを接続し、{currency} で支払います。このプロセスでは、取引コストを処理するために少額の BNB 手数料 ($0.30) がかかります。",
    "Use your account's wallet balance to purchase the product.": "アカウントのウォレット残高を使用して製品を購入します。",
    "Seems like your crypto hasn't arrived yet": "暗号通貨がまだ届いていないようです",
    "Wait more": "もう少しお待ちください",
    "Or topup with": "または、次の方法でチャージしてください",
    "Connected to web3": "Web3 に接続しました",
    "Reset": "リセット",
    "Top Up Successful": "チャージに成功しました",
    "Top Up Successful. Card balance will be updated soon": "チャージに成功しました。カード残高はすぐに更新されます",
    "Please activate your card and top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "カードを有効化し、月額料金をカバーするために残高を追加してください。残高が更新されない場合、カードは無効となり、新しいカードを購入する必要があります。",
    "PERCENTAGE allocation from Global Pool": "グローバル プールからの {perc}% の割り当て",
    "You need to reach rank RANK_NAME to participate in this pool.": "このプールに参加するには、ランク \"{rank_name}\" に到達する必要があります。",
    "Global Pool": "グローバル プール",
    "Next Distribution In:": "次の分配:",
    "50% Rule": "50% ルール",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%": "必要な売上高に対して、各直接パートナーが貢献できる最大シェアは50％である。",
    "Sub Pools": "サブ プール",
    "Congratulations! You have qualified for a share of the Global Pool.": "おめでとうございます！グローバル プールのシェアを獲得する資格を得ました。",
    "You will receive your share once the countdown is complete.": "カウントダウンが完了すると、シェアを受け取ることができます。",
    "Please top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "月会費をカバーするために残高を補充してください。残高が更新されない場合、カードは無効となり、新しいカードを購入する必要があります。",
    "You need to deposit VALUE worth of GYMNET to upgrade your weekly binary cap": "週間バイナリーキャップをアップグレードするには、{価値}相当のGYMNETを入金する必要があります。",
    "Binary Max Weekly Payout": "バイナリー最大週間ペイアウト",
    "Required Single Pool Deposit To Upgrade": "アップグレードに必要なシングルプール入金額",
    "Upgrade Binary Max Weekly Payout": "アップグレードバイナリ最大週ペイアウト",
    "Weekly Cap Upgraded": "アップグレードされたウィークリーキャップ",
    "Your 8 months period for earning token rewards in Filecoin has officially concluded. Thank you for your participation. Enjoy your FIL earnings.": "ファイルコインでトークン報酬を獲得できた8ヶ月の期間が正式に終了しました。ご参加ありがとうございました。FILの収益をお楽しみください。",
    "As previously announced Filecoin will be replaced by Moonberg token, which will be distributed for a period of 24 months. The Moonberg launch is expected for August 2024 and the distribution will start one week after that.": "以前発表されたように、FilecoinはMoonbergトークンに置き換えられ、24ヶ月間配布されます。Moonbergのローンチは2024年8月を予定、配布はその1週間後に開始されます。",
    "Username must not exceed 255 characters": "ユーザー名は255文字以内",
    "Upgrade": "アップグレード",
    "Product Autoship Monthly": "製品自動配送（月単位）",
    "Product Autoship Yearly": "製品自動配送（年単位）",
    "Product Owner": "製品所有者",
    "Myself": "自分",
    "Another user": "別のユーザー",
    "Payment": "支払い",
    "PAP Balance": "PAP 残高",
    "Purchased By": "購入者",
    "Purchased For": "購入先",
    "Single parcel": "単一区画",
    "Single miner": "単一マイナー",
    "completed": "完了しました",
    "pending": "保留中",
    "expired": "期限切れ",

    "Select {n} blockchain to send the transaction": "トランザクションを送信するには、{n} ブロックチェーンを選択してください",
    "We recommend waiting to claim your rewards when the gas fee is lower than the amount you are claiming.": "ガス料金が請求する金額よりも低い場合は、報酬を請求するまで待つことをお勧めします。",
    "Total Value Of Purchases": "購入の合計金額",
    "The total value of all purchases excluding purchases related to Card Bundles, granted products and product autoship subscriptions.": "カードバンドル、付与された製品、および製品の自動配送サブスクリプションに関連する購入を除く、すべての購入の合計金額。",
    "Total Receivable Tokens": "受取可能なトークンの合計",
    "The number of Moonberg tokens you will receive over the next 24 months.": "今後 24 か月間に受け取る Moonberg トークンの数。",
    "Realtime ROI": "リアルタイム ROI",
    "Current Return on Investment (ROI) based on the real-time price of the Moonberg token.": "Moonberg トークンのリアルタイム価格に基づく現在の投資収益率 (ROI)。",
    "Token Calculation Breakdown": "トークン計算の内訳",
    "{n}% of your total purchase value is allocated for purchasing {asset} tokens": "合計購入金額の {n}% が {asset} トークンの購入に割り当てられます",
    "Moonberg tokens will be distributed upon the official release of the Moonberg token.": "Moonberg トークンは、Moonberg トークンの公式リリース時に配布されます。",
    "Moonberg Allocation": "Moonberg割り当て",
    "BTC Balance": "BTC残高",
    "The funds will be transferred via the BEP-20 BNB network.": "資金は BEP-20 BNB ネットワーク経由で送金されます。",
    "A bundle with minting power will generate BTC token rewards, which will be distributed every Monday.": "ミントパワーを持つバンドルは BTC トークン報酬を生成し、毎週月曜日に配布されます。",
    "Weekly rewards expected": "毎週の報酬が期待される",
    "Direct Sales Incentive": "直接販売インセンティブ",
    "You are about to activate your affiliate referral link.": "アフィリエイト紹介リンクを有効化しようとしています。",
    "Upon activation, a period of free Product Autoship will be initiated starting from the date of your registration.": "有効化すると、登録日から無料の製品オートシップ期間が開始されます。",
    "If you confirm the activation, a mandatory monthly Product Autoship will be required to be eligible to receive commissions.": "有効化を確認すると、コミッションを受け取る資格を得るには、毎月の製品オートシップが必須となります。",
    "Keep your product autoship active to maintain your commission eligibility for commission ranks Platinum and above.": "プラチナ以上のコミッションランクの資格を維持するには、製品の自動配送をアクティブにしておいてください。",
    "Buy Product Autoship": "商品の自動配送を購入",
    "Your current rank is achieved through qualification.": "現在のランクは資格取得によって達成されます。",
    "Your commission rank is determined by your actual rank or a super bundle purchase—whichever is higher will be considered.": "コミッションランクは実際のランクまたはスーパーバンドルの購入によって決定され、どちらか高い方が考慮されます。",
    "Your card has been deactivated due to an unpaid monthly subscription. For assistance or any questions, please reach out to our customer support team.": "月額サブスクリプションの未払いのため、{card_name} カードは無効になりました。サポートやご質問がございましたら、カスタマー サポート チームまでお問い合わせください。",

    "Subject": "件名",
    "topup": "トップアップ",
    "withdraw": "引き出し",
    "failed": "失敗",
    "3rd Anniversary": "3 周年記念",
    "3rd Anniversary Promo": "3 周年記念プロモーション",
    "Your current bonus amount": "現在のボーナス額",
    "All products are eligible for the incentive, except for Product Autoship. Rewards will be distributed on November 1, 2024, and the bonus will be deposited directly into your internal wallet.": "製品オートシップを除くすべての製品がインセンティブの対象となります。報酬は 2024 年 11 月 1 日に配布され、ボーナスは内部ウォレットに直接入金されます。",
























    // Affility translations
    "close": "閉じる",
    "cancel": "キャンセル",
    "yes_i_confirm": "同意する",
    "something_went_wrong_try_again": "何か問題が発生しました。もう一度お試しください。",
    "or": "または",
    "success": "成功",
    "ok": "OK",
    "retry": "再試行",
    "home": "ホーム",
    "log_in": "ログイン",
    "join_now": "今すぐ参加",
    "toggle_notifications": "通知設定",
    "active": "有効",
    "inactive": "無効",
    "product_autoship": "製品オートシップ",
    "balances": "残高",
    "contract_addresses": "コントラクトアドレス",
    "address_add_to_wallet_success": "アドレスをウォレットに追加しました。",
    "language": "言語",
    "choose_language": "言語選択",
    "profile": "プロフィール",
    "settings": "設定",
    "learn": "学ぶ",
    "help_center": "ヘルプセンター",
    "log_out": "ログアウト",
    "theme": "テーマ",
    "back": "戻る",
    "forward": "進む",
    "previous": "前",
    "next": "次",
    "jump_to_top": "トップへ",
    "load_more": "さらに読み込む",
    "proceed": "次へ",
    "notifications": "通知",
    "notifications_loading_error_description": "デバイスがインターネットに接続されていることを確認するか、ルーターまたはモデムを再起動してください。",
    "notifications_empty_title": "通知リストがカラです。",
    "notifications_empty_description": "通知機能で、すべての更新とリマインダーを1つの便利な場所で管理できます。",
    "email_verification_sent_title": "メール認証",
    "email_verification_sent_description": "ご登録を確定するためのリンクを記載したメールを{email}宛に送信しました。",
    "email_verification_required": "メール認証が必要です",
    "email_verification_confirm": "次に進むにはメール認証が必要です",
    "email_verification_verify": "次に進むにはメールを確認してください",
    "new_email": "新しいメール",
    "enter_new_email": "新しいメールアドレスを入力",
    "change_email": "メールアドレスの変更",
    "resend_email": "メールの再送信",
    "upgrade_to_affiliate": "アフィリエイトにアップグレードする",
    "upgrade_to_affiliate_success": "アフィリエイトへのアップグレードが完了しました",
    "become_affiliate": "アフィリエイトになる",
    "upgrade_to_affiliate_description_free_autoship": "アフィリエイト紹介リンクを有効にしようとしています。有効化されると、登録された日からオートシップ無料期間が始まります。",
    "upgrade_to_affiliate_description_mandatory_autoship": "アフィリエイト紹介リンクを有効化しようとしています。有効化した場合、コミッションを受け取るために毎月の製品オートシップが必須となります。",
    "t&c": "利用規約",
    "accept_t&c": "承諾して閉じる",
    "affiliate_t&c": "アフィリエイト利用規約",
    "cards_t&c": "カード利用規約",
    "i_have_read_and_accept_the_{prop}": "私は{prop}を読んだ上で承諾します。",
    "switch_language": "言語変更",
    "switch_theme": "テーマ変更",
    "enjoy_more_benefit_more": "もっと楽しもう！もっと得しよう！",
    "buy_products_at_least_${n}": "製品の最低購入額 ${n}",
    "go_to_products": "製品へ",
    "free_autoship": "プラチナ以上のお客様には、無料の製品自動配送",
    "{n}%_binary_commission": "{n}% バイナリーコミッション",
    "rank": "ランク",
    "role": "役割",
    "bundle": "セット",
    "current_rank": "現在のランク",
    "current_role": "現在の役割",
    "customer": "お客様",
    "get_your_bundle": "セットを獲得",
    "nft_turbo_expires_in": "スーパーセット特別割引の有効期限までの残り時間",
    "left_leg_link": "左脚用リンク",
    "right_leg_link": "右脚用リンク",
    "sponsor_id": "スポンサーID",
    "total_income": "収入総額",
    "current_cv_balance": "現在のCV 残高",
    "progress_until_rank_{n}": "ランク{n}までの進捗状況",
    "to_reach_rank_{n}_you_need": "ランク{n}達成にはこちらが必要です",
    "nft_own_purchases": "NFT 自己購入額",
    "direct_partner_own_purchases": "直接パートナー自己購入額",
    "team_turnover": "チーム売上",
    "required_qualified_partners": "必要な有資格パートナー",
    "see_details": "詳細を見る",
    "soon": "近日",
    "new": "新",
    "promos": "セール",
    "coming_soon": "近日公開",
    "team_statistics": "チーム統計",
    "all_time": "全期間",
    "weekly": "週間",
    "monthly": "月間",
    "yearly": "年間",
    "left_leg": "左脚",
    "right_leg": "右脚",
    "all_partners": "すべてのパートナー",
    "direct_partners": "直接パートナー",
    "all_active_partners": "すべてのアクティブパートナー",
    "all_inactive_partners": "すべての非アクティブパートナー",
    "become_affiliate_partner": "アフィリエイトパートナーになる",
    "gain_fresh_perspectives": "新鮮な視点と限定特典を獲得しよう。",
    "autoship_required_to_be_affiliate": "アフィリエイトパートナーとして報酬を得るには、毎月の製品オートシップが必要です。",
    "for_limited_time_only": "期間限定",
    "placement_in_unilevel": "ユニレベルの配置",
    "placement_in_binary": "バイナリーの配置",
    "cv_points": "CV ポイント",
    "matching_bonuses": "マッチング　ボーナス",
    "leadership_incentives": "リーダーシップ　インセンティブ",
    "autoship_incentive": "製品オートシップ　インセンティブ",
    "binary_tree_statistics": "バイナリーツリーの統計",
    "unilevel_tree_statistics": "ユニレベルツリーの統計",
    "active_partners": "アクティブパートナー",
    "inactive_partners": "活動休止パートナー",
    "The minimum amount should be equal to or greater than NUM GYMNET": "最小金額は {num} GYMNET 以上である必要があります",
    "select_the_team": "チームを選択",
    "new_users_will_be_placed_under_username_in_the_left_team": "新規ユーザーは {LEG} チームの {USERNAME} の下に配置されます。",
    "done": "完了",
    "right": "右",
    "left": "左",
};
