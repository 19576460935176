export default {
    "Learn how it works": "Learn how it works",
    "Buy parcel": "Buy parcel",
    "Wallets": "Wallets",
    "To GymNetwork": "To GymNetwork",
    "Connect Wallet": "Connect Wallet",
    "Pending rewards": "Pending rewards",
    "Coming Soon": "Coming Soon",
    "Claim": "Claim",
    "Inventory": "Inventory",
    "Lands": "Lands",
    "Miners": "Miners",
    "Attach": "Attach",
    "Attach to parcel": "Attach to parcel",
    "Investment": "Investment",
    "Wallet": "Wallet",
    "Your address": "Your address",
    "History": "History",
    "Map": "Map",
    "Buy miner": "Buy miner",
    "Buy Miner": "Buy Miner",
    "Get ": "Get ",
    "Get Miner": "Get Miner",
    "Upgrade parcel": "Upgrade parcel",
    "Soon": "Soon",
    "Upgraded": "Upgraded",
    "Upgraded land has 10 slots for putting miner NFTs. Upgraded land does not have more physical space, it’s only that you can stake more miners.": "Upgraded land has 10 slots for putting miner NFTs. Upgraded land does not have more physical space, it’s only that you can stake more miners.",
    "Next": "Next",
    "Back": "Back",
    "Skip guide": "Skip guide",
    "Number of miners you want to buy": "Number of miners you want to buy",
    "Attach Miner": "Attach Miner",
    "Upgrade for": "Upgrade for",
    "On Sale": "On Sale",
    "Type": "Type",
    "Location": "Location",
    "Size": "Size",
    "Buy now": "Buy now",
    "Email": "Email",
    "Username": "Username",
    "Verify Email": "Verify Email",
    "Index": "Index",
    "Verification Code": "Verification Code",
    "Log out": "Log out",
    "Select all": "Select all",
    "Cart": "Cart",
    "Total": "Total",
    "Register": "Register",
    "Dashboard": "Dashboard",
    "Transaction success!": "Transaction success!",
    "I`ve read Terms & Conditions": "I`ve read Terms & Conditions",
    "Terms & conditions": "Terms & conditions",
    "Affiliate Terms & Conditions": "Affiliate Terms & Conditions",
    "Accept Terms & Conditions": "Accept Terms & Conditions",
    "Loading! Please Wait ...": "Loading! Please Wait ...",
    "Initializing Wallet! Please Wait ...": "Initializing Wallet! Please Wait ...",
    "Login In Progress! Please Wait ...": "Login In Progress! Please Wait ...",
    "Enter your email": "Enter your email",
    "Confirm your Email": "Confirm your Email",
    "Enter username": "Enter username",
    "To dashboard": "To dashboard",
    "You successfully verified your email": "You successfully verified your email",
    "View map": "View map",
    "Log in to buy": "Log in to buy",
    "Congratulations": "Congratulations",
    "Buy": "Buy",
    "Miner": "Miner",
    "Parcel": "Parcel",
    "Upgrade": "Upgrade",
    "Load more": "Load more",
    "Basic": "Basic",
    "Activate": "Activate",
    "Basic Miner": "Basic Miner",
    "In use": "In use",
    "Expired": "Expired",
    "Available": "Available",
    "Standard parcel": "Standard parcel",
    "Success": "Success",
    "Something Went Wrong": "Something Went Wrong",
    "Loading": "Loading",
    "Invalid Verification Code": "Invalid Verification Code",
    "Invalid Email Address": "Invalid Email Address",
    "Invalid Arguments Supplied": "Invalid Arguments Supplied",
    "Email Already Exists": "Email Already Exists",
    "UnAuthorized": "UnAuthorized",
    "User Update Failed": "User Update Failed",
    "Permission Denied": "Permission Denied",
    "Basic miner": "Basic miner",
    "Remove": "Remove",
    "Special offers": "Special offers",
    "Please Wait": "Please Wait",
    "Transaction Rejected": "Transaction Rejected",
    "sold": "sold",
    "The parcel price will be increased after certain percentage of parcels will be sold. Bundle prices will be increased accordingly.": "The parcel price will be increased after certain percentage of parcels will be sold. Bundle prices will be increased accordingly.",
    "Sold": "Sold",
    "Parcel price index": "Parcel price index",
    "Current parcel price": "Current parcel price",
    "Parcels sold": "Parcels sold",
    "Parcel stats": "Parcel stats",
    "Help center": "Help center",
    "Partner area": "Partner area",
    "Transaction Success": "Transaction Success",
    "parcels available": "parcels available",
    "Apply": "Apply",
    "Ocean": "Ocean",
    "Near Ocean": "Near Ocean",
    "Insufficient USDT amount": "Insufficient USDT amount",
    "Insufficient {asset} amount": "Insufficient {asset} amount",
    "Miner Rewards": "Miner Rewards",
    "No data found": "No data found",
    "Own Deposit": "Own Deposit",
    "Full": "Full",
    "Standard": "Standard",
    "Attach miners": "Attach miners",
    "Upgraded Parcel": "Upgraded Parcel",
    "Discount": "Discount",
    "save": "save",
    "Copied": "Copied",
    "Marketplace": "Marketplace",
    "Wallet Address": "Wallet Address",
    "Contract Address": "Contract Address",
    "Inland": "Inland",
    "Business": "Business",
    "parcels": "parcels",
    "parcel": "parcel",
    "Parcel name": "Parcel name",
    "Basic Bundle": "Basic Bundle",
    "4 Parcels Bundle": "4 Parcels Bundle",
    "10 Parcels Bundle": "10 Parcels Bundle",
    "40 Parcels Bundle": "40 Parcels Bundle",
    "80 Parcels Bundle": "80 Parcels Bundle",
    "140 Parcels Bundle": "140 Parcels Bundle",
    "240 Parcels Bundle": "240 Parcels Bundle",
    "4 Basic Miner Bundle": "4 Miner Bundle",
    "10 Basic Miner Bundle": "10 Miner Bundle",
    "40 Basic Miner Bundle": "40 Miner Bundle",
    "80 Basic Miner Bundle": "80 Miner Bundle",
    "140 Basic Miner Bundle": "140 Miner Bundle",
    "240 Basic Miner Bundle": "240 Miner Bundle",
    "Hashpower": "Hashpower",
    "Something went wrong, please refresh the page and try again": "Something went wrong, please refresh the page and try again",
    "Gallery": "Gallery",
    "Accepted": "Accepted",
    "Pending": "Pending",
    "Upload": "Upload",
    "Uploading image": "Uploading image",
    "Something went wrong, try again later": "Something went wrong, try again later",
    "You need to be authenticated to upload images to Gallery.": "You need to be authenticated to upload images to Gallery.",
    "Assign image": "Assign image",
    "Assign image to parcels": "Assign image to parcels",
    "Remove current image": "Remove current image",
    "Change image": "Change image",
    "Assign to parcels": "Assign to parcels",
    "Reselect image assignment area?": "Reselect image assignment area?",
    "You have not applied changes. Do you want to reselect image assignment area?": "You have not applied changes. Do you want to reselect image assignment area?",
    "Image has been successfully changed": "Image has been successfully changed",
    "Image has been successfully assigned to parcels": "Image has been successfully assigned to parcels",
    "Image has been successfully removed from the parcels": "Image has been successfully removed from the parcels",
    "Yes": "Yes",
    "No": "No",
    "Cancel": "Cancel",
    "You have no images": "You have no images",
    "Notifications": "Notifications",
    "No notifications": "No notifications",
    "years ago": "years ago",
    "year ago": "year ago",
    "months ago": "months ago",
    "month ago": "month ago",
    "weeks ago": "weeks ago",
    "week ago": "week ago",
    "days ago": "days ago",
    "day ago": "day ago",
    "hours ago": "hours ago",
    "hour ago": "hour ago",
    "minutes ago": "minutes ago",
    "minutes": "minutes",
    "minute": "minute",
    "seconds ago": "seconds ago",
    "seconds": "seconds",
    "second": "second",
    "Your image was approved": "Your image was approved",
    "Your image was rejected": "Your image was rejected",
    "New": "New",
    "Gallery guide" : "Gallery guide",
    "Go to gallery": "Go to gallery",
    "How it works": "How it works",
    "Removing image from parcels..." : "Removing image from parcels...",
    "Removing image" : "Removing image",
    "Image has been successfully removed" : "Image has been successfully removed",
    "Wrong file size" : "Wrong file size",
    "Please upload image files with size less than 10MB": "Please upload image files with size less than 10MB",
    "Drag to Reposition": "Drag to Reposition",
    "Pinch or use the mouse wheel to zoom in or out": "Pinch or use the mouse wheel to zoom in or out",
    "Drag and move the image with fingers or using your mouse to select area": "Drag and move the image with fingers or using your mouse to select area",
    "It can take up to 48 hours for the image to be approved. You can view your images awaiting approval in the Pending tab.": "It can take up to 48 hours for the image to be approved. You can view your images awaiting approval in the Pending tab.",
    "Delete Image": "Delete Image",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable. Land containing this image will also be cleared.": "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable. Land containing this image will also be cleared.",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable." : "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable.",
    "Wrong File Type": "Wrong File Type",
    "Please upload jpg/jpeg image files": "Please upload jpg/jpeg image files",
    "Upload Images to Your Gallery": "Upload Images to Your Gallery",
    "You can upload images to your gallery from your computer, mobile phone or tablet. Currently only jpg/jpeg image file types are supported. Press the upload button or simply drag and drop the image from computer to upload your image. Image size must be up to 10 MB." : "You can upload images to your gallery from your computer, mobile phone or tablet. Currently only jpg/jpeg image file types are supported. Press the upload button or simply drag and drop the image from computer to upload your image. Image size must be up to 10 MB.",
    "Upload image": "Upload image",
    "Image Approval": "Image Approval",
    "Uploaded images need to be approved before they appear in the approved section of your gallery. This procedure is necessary to filter out inappropriate, explicit content and copyrighted material. Approval can take up to 48 hours. You'll get notified about the verdict whenever you're online." : "Uploaded images need to be approved before they appear in the approved section of your gallery. This procedure is necessary to filter out inappropriate, explicit content and copyrighted material. Approval can take up to 48 hours. You'll get notified about the verdict whenever you're online.",
    "Approval": "Approval",
    "Select Your Land to Assign Image to": "Select Your Land to Assign Image to",
    "You can assign your uploaded images to your owned land. To do so, simply select the parcels by dragging mouse on the land part you want to assign an image to. Please note that image can only be assigned to a rectangular area that is owned by you only." : "You can assign your uploaded images to your owned land. To do so, simply select the parcels by dragging mouse on the land part you want to assign an image to. Please note that image can only be assigned to a rectangular area that is owned by you only.",
    "Land Selection": "Land Selection",
    "Crop Image": "Crop Image",
    "You can crop a custom part of your image that you want to assign to your land section. You can close in to any area of your image and crop it by dragging the image with mouse or your finger if you're on a mobile device. You can also zoom in and out by using mouse wheel or pinch gesture." : "You can crop a custom part of your image that you want to assign to your land section. You can close in to any area of your image and crop it by dragging the image with mouse or your finger if you're on a mobile device. You can also zoom in and out by using mouse wheel or pinch gesture.",
    "Activate multiple selection": "Activate multiple selection",
    "Exit multiple selection": "Exit multiple selection",
    "Owned": "Owned",
    "Owned by": "Owned by",
    "Multiple types": "Multiple types",
    "Super Bundle": "Super Bundle",
    "Balance": "Balance",
    "View your purchased Bundles and claim to have them from your balance": "View your purchased Bundles and claim to have them from your balance",
    "Minimum claimable amount is (AMOUNT)": "Minimum claimable amount is {amount}",
    "Feel free to choose lands on Map.": "Feel free to choose lands on Map.",
    "Earn money by using miners for generating rewards.": "Earn money by using miners for generating rewards.",
    "Upgrade your owned lands from 4 slots to 10.": "Upgrade your owned lands from 4 slots to 10.",
    "Confirm": "Confirm",
    "You don`t have any available parcels in your inventory": "You don`t have any available parcels in your inventory",
    "Months": "Months",
    "Super Bundle R6": "Super Bundle R6",
    "Super Bundle R10": "Super Bundle R10",
    "Super Bundle R15": "Super Bundle R15",
    "Super Bundle R20": "Super Bundle R20",
    "Super Bundle R22 S": "Super Bundle R22 S",
    "Super Bundle R22 M": "Super Bundle R22 M",
    "Super Bundle R22 L": "Super Bundle R22 L",
    "Super Bundle R22 XL": "Super Bundle R22 XL",
    "Super Bundle R22 XXL": "Super Bundle R22 XXL",
    "1 Claim your land": "1 Claim your land",
    "2 Claim your miner": "2 Claim your miner",
    "3 Upgrade your land": "3 Upgrade your land",
    "Your NFT balance": "Your NFT balance",
    "Next price changes in": "Next price changes in",
    "Parcels": "Parcels",
    "Get to know the platform usage": "Get to know the platform usage",
    "What is a Parcel?": "What is a Parcel?",
    "What is a Miner?": "What is a Miner?",
    "A miner is what generates income for you at GYM Street. You can attach up to 4 miners on each of your standard land parcels.": "A miner is what generates income for you at GYM Street. You can attach up to 4 miners on each of your standard land parcels.",
    "Miner rewards": "Miner rewards",
    "My Miners": "My Miners",
    "My Hashpower": "My Hashpower",
    "Total Claimed": "Total Claimed",
    "Total pending rewards": "Total pending rewards",
    "Claim all": "Claim all",
    "Daily rewards": "Daily rewards",
    "Weekly rewards": "Weekly rewards",
    "Monthly rewards": "Monthly rewards",
    "Yearly rewards": "Yearly rewards",
    "ROI": "ROI",
    "ROI Calculator": "ROI Calculator",
    "Global Statistics": "Global Statistics",
    "Global Hashpower": "Global Hashpower",
    "Your Shares": "Your Shares",
    "Daily Global Rewards": "Daily Global Rewards",
    "Price": "Price",
    "Total Minted": "Total Minted",
    "total": "total",
    "standard": "standard",
    "upgraded": "upgraded",
    "active": "active",
    "inactive": "inactive",
    "Attach miner": "Attach miner",
    "Total Miners": "Total Miners",
    "Total Minted Rewards": "Total Minted Rewards",
    "What is parcel and how to use it?": "A Parcel is an area unit on the {metaverse} map equipped with minting capabilities. Own Parcels and generate income.",
    "What is a miner and how to use it?": 'The Miner is your income generator on {metaverse}. Enhance your earnings by attaching Miners to your Parcel. <br/> <br/> The GYMNET Miner and Dual Miner both operate at a mining speed of 1,000 Mh/s.',
    "Your active/inactive hashpower, 1 miner = 1,000 Mh/s, your Miners 0 x 1,000Mh/s = 0.00": "Your active/inactive hashpower, 1 miner = 1,000 Mh/s, your Miners 0 x 1,000Mh/s = 0.00",
    "The amount of the cumulated claimed rewards": "The amount of the cumulated claimed rewards",
    "Your current available pending rewards.": "Your current available pending rewards.",
    "Congratulations!": "Congratulations!",
    "Go to Map": "Go to Map",
    "Purchase more miners to improve your results": "Purchase more miners to improve your results",
    "Buy more miners": "Buy more miners",
    "Thanks, I'll stay below average": "Thanks, I\'ll stay below average",
    "Do you want to buy more parcels now ?": "Do you want to buy more parcels now ?",
    "I definitely do": "I definitely do",
    "No, I\'ll let my miners to rust": "No, I\'ll let my miners to rust",
    "You have no miners": "You have no miners",
    "Buy miners": "Buy dual miners",
    "Close": "Close",
    "Read more": "Read more",
    "Drag and drop an image, or Browse": "Drag and drop an image, or <label class=\"browse-button\" for=\"upload-image\">Browse</label>",
    "Browse an image": "<label class=\"browse-button\" for=\"upload-image\">Browse</label> an image",
    "Drop to upload": "Drop to upload",
    "File not supported": "File not supported",
    "Upload your parcel image": "Upload your parcel image",
    "Up to 10MB, jpg/jpeg": "Up to 10MB, jpg/jpeg",
    "High resolution images, should align with your land shape (you can crop after upload)": "<span class=\"bold-info\">High resolution images,</span> should align with your land shape (you can crop after upload)",
    "Same image may be used on different lands owned by the user": "<span class=\"bold-info\">Same image may be used on</span> different lands owned by the user",
    "No violence, offensive, graphic or 18+ content": "No violence, offensive, graphic or <span class=\"bold-info\">18+ content</span>",
    "No third-party copyrighted content, no logos or screenshots from Gymstreet and Gymnetwork": "<span class=\"bold-info\">No third-party copyrighted content,</span> no logos or screenshots from <span class=\"bold-info\">Gymstreet and Gymnetwork</span>",
    "No images already used by another user, no images from DeFi listing website images": "<span class=\"bold-info\">No images already used</span> by another user, no images from DeFi listing website images",
    "No religious images": "<span class=\"bold-info\">No religious images</span>",
    "Unlock miners by purchasing at least 1 land parcel": "Unlock miners by purchasing at least 1 land parcel",
    "OK": "OK",
    "Your parcels": "Your parcels",
    "Your miners": "Your miners",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcels to start mining": "You are not mining at full speed, you have {miners} miner that need to be attached to your parcels to start mining",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcel to start mining": "You are not mining at full speed, you have {miners} miner that need to be attached to your parcel to start mining",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcels to start mining": "You are not mining at full speed, you have {miners} miners that need to be attached to your parcels to start mining",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcel to start mining": "You are not mining at full speed, you have {miners} miners that need to be attached to your parcel to start mining",
    "Upgrade your parcels to mine even more?": "Upgrade your parcels to mine even more?",
    "Info Center": "Info Center",
    "Standard Parcel": "Standard Parcel",
    "You have no parcels": "You have no parcels",
    "Single Products": "Single Products",
    "Parcels sold / Total parcels": "Parcels sold / Total parcels",
    "You can mine even more. Purchase more miners to improve your results": "You can mine even more. Purchase more miners to improve your results",
    "The Promotion starts in:": "The Promotion starts in:",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / USDT": "When a new business building is being implemented, you will have \n the option to buy land by paying with GYMNET / USDT",
    "Referral ID": "Referral ID",
    "In Progress": "In Progress",
    "Active": "Active",
    "Learn More": "Learn More",
    "Add to wallet": "Add to wallet",
    "Parcel NFT": "Parcel NFT",
    "Miner NFT": "Miner NFT",
    "Your Balance": "Your Balance",
    "GYMNET Balance": "GYMNET Balance",
    "Please go to your wallet, which you just connected, and verify your login by confirming the requested transaction. It's free of charge!": "Please go to your wallet, which you just connected, and verify your login by confirming the requested transaction. It\'s free of charge!",
    "Contract addresses": "Contract addresses",
    "Add TOKEN to wallet": "Add {token} to wallet",
    "I accept the terms and conditions": "I accept the terms and conditions",
    "Log In": "Log In",
    "Sign Up": "Sign Up",
    "Connect My Wallet": "Connect My Wallet",
    "Change Email": "Change Email",
    "Reset Password": "Reset Password",
    "Your password has been changed.": "Your password has been changed.",
    "Please log in to your account.": "Please log in to your account.",
    "Confirm Email": "Confirm Email",
    "Emails don't match": "Emails don't match",
    "Or": "Or",
    "Already have an account?": "Already have an account?",
    "Recover": "Recover",
    "Enter New Password": "Enter New Password",
    "Confirm New Password": "Confirm New Password",
    "Password": "Password",
    "Forgot password?": "Forgot password?",
    "Password is required": "Password is required",
    "Password confirmation is required": "Password confirmation is required",
    "Passwords do not match": "Passwords do not match",
    "Password must contain at least one uppercase letter, one lowercase letter and one number": "Password must contain at least one uppercase letter, one lowercase letter and one number",
    "Password must be at least 8 characters": "Password must be at least 8 characters",
    "Password must not exceed 24 characters": "Password must not exceed 24 characters",
    "Password must contain at least one special character": "Password must contain at least one special character",
    "Password cannot contain spaces": "Password cannot contain spaces",
    "Email is required": "Email is required",
    "Email confirmation is required": "Email confirmation is required",
    "Please enter a valid email": "Please enter a valid email",
    "Step NUM1 of NUM2": "Step {num1} of {num2}",
    "Create Password": "Create Password",
    "Get Started": "Get Started",
    "Once installed, you should see the below splash screen. Click the 'Get Started' button to begin creating your Ethereum wallet using MetaMask.": "Once installed, you should see the below splash screen. Click the “Get Started” button to begin creating your Ethereum wallet using MetaMask.",
    "Create a Wallet": "Create a Wallet",
    "On the next step, tap on 'Create a wallet'.": "On the next step, tap on \"Create a wallet\".",
    "On the next step, tap on 'Create a new wallet'.": "On the next step, tap on \n“Create a new wallet”.",
    "Confirm on this screen": "Confirm on this screen",
    "You will then be asked if you want to help improve MetaMask. Click 'No, Thanks' if this doesn't interest you, otherwise click 'I agree'.": "You will then be asked if you want to help improve MetaMask. Click “No, Thanks” if this doesn’t interest you, otherwise click “I agree”.",
    "Pick a password on the next step. This needs to be at least 8 symbols long.": "Pick a password on the next step. This needs to be at least 8 symbols long.",
    "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower case letters, symbols and numbers.": "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower  case letters, symbols and numbers.",
    "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.": "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.",
    "Wallet Ready": "Wallet Ready",
    "Add Binance Smart Chain": "Add Binance Smart Chain",
    "Click on the button below to add Binance Smart Chain Network to your MetaMask wallet.": "Click on the button below to add Binance Smart Chain Network to your MetaMask wallet.",
    "Confirm the connection request": "Confirm the connection request",
    "Get Started!": "Get Started!",
    "After Downloading and opening MetaMask, tap on 'Get Started'.": "After Downloading and opening MetaMask, tap on “Get Started”.",
    "Improve Metamask": "Improve Metamask",
    "Secure Wallet": "Secure Wallet",
    "On this step you can choose. We recommend you do this now as you might otherwise lose access to your wallet. Tap on 'Start' to continue.": "On this step you can choose. \nWe recommend you do this now as you might otherwise lose access to your wallet. \nTap on “Start” to continue.",
    "On this step tap on 'Start' to continue.": "On this step tap on “Start” \nto continue.",
    "Confirm your password to continue.": "Confirm your password to continue.",
    "You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.": "You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.",
    "Congratulations! You have set up your MetaMask wallet. Confirm the next few pop-ups on the screen to access your wallet.": "Congratulations! You have set up your MetaMask wallet. Confirm the next few pop-ups on the screen to access your wallet.",
    "Import Binance Smart Chain": "Import Binance Smart Chain",
    "Tap on the burger menu of the app in the top left corner of the screen.": "Tap on the burger menu of the app in the top left corner of the screen.",
    "Next, tap on Settings in the menu to the left.": "Next, tap on Settings in the menu to the left.",
    "Next, tap on Networks.": "Next, tap on Networks.",
    "Next, copy the NFT Smart Contract by tapping on the button below.": "Next, copy the NFT Smart Contract by tapping on the button below.",
    "Copy NFT Contract": "Copy NFT Contract",
    "Add More Tokens": "Add More Tokens",
    "In the search field, type in BNB Smart Chain and add it to your network.": "In the search field, type in BNB Smart Chain and add it to your network.",
    "Next, select Custom Token. Paste the address of the smart contract you copied into the token address field. In the Token Symbol field, type SP, and in the Token Decimal, put 0. Then click the 'Import' button to complete your setup.": "Next, select Custom Token. Paste the address of the smart contract you copied into the token address field. In the Token Symbol field, type SP, and in the Token Decimal, put 0. Then click the 'Import' button to complete your setup.",
    "You have successfully set up Your wallet.Welcome to the world of WEB3!": "You have successfully set up Your wallet.\nWelcome to the world of WEB3!",
    "Install MetaMask": "Install MetaMask",
    "Install MetaMask here.": "Install MetaMask here.",
    "Confirm Password": "Confirm Password",
    "Add Network": "Add Network",
    "Give permission to the platform to add a network.": "Give permission to the platform to add a network.",
    "Guide for MetaMask Wallet connection process.": "Guide for MetaMask Wallet connection process.",
    "Install here.": "Install here.",
    "MetaMask will then present you with your 12-word backup phrase.": "MetaMask will then present you with your 12-word backup phrase.",
    "Something went wrong, please try verifying your email again.": "Something went wrong, please try verifying your email again.",
    "Looks like your password reset link has expired. Please try again.": "Looks like your password reset link has expired. Please try again.",
    "Looks like your password reset link has already been used. Please request it again.": "Looks like your password reset link has already been used. Please request it again.",
    "Couldn't recover password": "Couldn't recover password",
    "Couldn't reset your password.": "Couldn't reset your password.",
    "Couldn't login.": "Couldn't login.",
    "Couldn't sign you up.": "Couldn't sign you up.",
    "Resend Email": "Resend Email",
    "Couldn't resend confirmation email.": "Couldn't resend confirmation email.",
    "Provided email is already in use.": "Provided email is already in use.",
    "You have already verified your email address": "You have already verified your email address",
    "Invalid or expired verification code": "Invalid or expired verification code",
    "Extra PERCENT% Discount": "Extra {PERCENT}% Discount",
    "Finalize Registration": "Finalize Registration",
    "Continue": "Continue",
    "Your payment has been received. It is currently being processed. You will receive a notification when done.": "Your payment has been received. It is currently being processed. You will receive a notification when done.",
    "Tap on the 'Import Tokens' link to add a Custom Token.": "Tap on the “Import Tokens” link to add a Custom Token.",
    "Click on the button 'Approve' to allow the connection request.": "Click on the button “Approve” to allow the connection request.",
    "MetaMask will then present you with your 12-word backup phrase. You will need to write this down in the same order displayed on your screen.": "MetaMask will then present you with your 12-word backup phrase. You will need to write this down in the same order displayed on your screen.",
    "MetaMask Secret Phrase": "MetaMask Secret Phrase",
    "Confirm Secret Phrase": "Confirm Secret Phrase",
    "Copy NFT Smart Contract": "Copy NFT Smart Contract",
    "You are almost finished with the MetaMask setup process. Just click 'All done' on the last page, and you will be automatically logged into MetaMask. If you ever log out, you can log back in by clicking on the icon added to your web browser (usually, it's next to the URL bar).": "You are almost finished with the MetaMask setup process. Just click \"All done\" on the last page, and you will be automatically logged into MetaMask. If you ever log out, you can log back in by clicking on the icon added to your web browser (usually, it's next to the URL bar).",
    "Click on the button 'Switch network' to allow the connection request.": "Click on the button “Switch network” to allow the connection request.",
    "Payment Method": "Payment Method",
    "Select cryptocurrency": "Select cryptocurrency",
    "Please use an Ethereum BEP-20 address.": "Please use an Ethereum BEP-20 address.",
    "An error occurred while logging in. Please try again.": "An error occurred while logging in. Please try again.",
    "We sent an email to {EMAIL} with a link to finalize your registration": "We sent an email to {EMAIL} with a link to finalize your registration",
    "We sent an email to {EMAIL} with a link to change your password": "We sent an email to {EMAIL} with a link to change your password",
    "Connect to Gymstreet": "Connect to Gym Street",
    "Now to access the Gym Street Metaverse, you need to open it with the MetaMask browser. Go to your MetaMask wallet app, choose 'Browser' from the side navigation menu, search for Gym Street in the browser tab.": "Now to access the Gym Street Metaverse, you need to open it with the MetaMask browser. Go to your MetaMask wallet app, choose \"Browser\" from the side navigation menu, search for Gym Street in the browser tab.",
    "First, let's set up your cryptocurrency wallet.": "First, let's set up your cryptocurrency wallet.",
    "Follow the next steps to continue the setup.": "Follow the next steps to continue the setup.",
    "Your payment processing is complete. Your NFT balance has been updated!": "Your payment processing is complete. Your NFT balance has been updated!",
    "Your payment is received but is incomplete. Please pay AMOUNT CURRENCY more to complete the payment.": "Your payment is received but is incomplete. Please pay {amount} {currency} more to complete the payment.",
    "Terms & Conditions": "Terms & Conditions",
    "Email Verification": "Email Verification",
    "Transaction history": "Transaction history",
    "Order history": "Order History",
    "Product": "Product",
    "Quantity": "Quantity",
    "Payment UID": "Payment UID",
    "Status": "Status",
    "Currency": "Currency",
    "Autoship":"Autoship",
    "Amount": "Amount",
    "Date": "Date",
    "Tx ID": "Tx ID",
    "Completed": "Completed",
    "MetaMask is not installed": "MetaMask is not installed",
    "If you have already installed MetaMask, please": "If you have already installed {MetaMask}, please",
    "refresh page": "refresh page",
    "to continue.": "to continue.",
    "You have successfully installed MetaMask. Now you can add a Network.": "You have successfully installed MetaMask. Now you can add a Network.",
    "You already have this network": "You already have this network",
    "Error": "Error",
    "Please enter valid email": "Please enter valid email",
    "Your sponsor is": "Your sponsor is",
    "Please confirm your email to continue": "Please confirm your email to continue",
    "New email must be different from current email": "New email must be different from current email",
    "Credentials": "Credentials",
    "Enter Wallet Address (i.e. 0xb794f...79268)": "Enter Wallet Address (i.e. 0xb794f...79268)",
    "Contact Customer Support": "Contact Customer Support",
    "Change Wallet": "Change Wallet",
    "Account": "Account",
    "Purchase for another user": "Purchase for another user",
    "Save": "Save",
    "Please leave an address here, in which we can also mint your NFTs afterwards.": "Please leave an address here, in which we can also mint your NFTs afterwards.",
    "Important!": "Important!",
    "It should be a BEP-20 address - i.e. a BNB Smart Chain wallet address. We recommend the wallet Metamask or Trustwallet.": "It should be a BEP-20 address - i.e. a BNB Smart Chain wallet address. We recommend the wallet Metamask or Trustwallet.",
    "NEVER USE AN EXCHANGE Wallet ADRESS !!!": "NEVER USE AN EXCHANGE Wallet ADRESS !!!",
    "Failed": "Failed",
    "Account changed": "Account changed",
    "Your Profile has been updated": "Your Profile has been updated",
    "You already have an attached wallet address to this account.": "You already have an attached wallet address to this account.",
    "Login": "Login",
    "Existing wallet": "Existing wallet",
    "Referral ID is required": "Referral ID is required",
    "Pending Transaction": "Pending Transaction",
    "Processing": "Processing",
    "Send this payment ID to a customer support when you are requested to do so.": "Send this payment ID to a customer support when you are requested to do so.",
    "To be able to claim your NFTs you need to connect the same wallet address attached to your account": "To be able to claim your NFTs you need to connect the same wallet address attached to your account",
    "Please confirm your email to continue.": "Please confirm your email to continue.",
    "Your payment was refunded. AMOUNT CURRENCY was returned to your wallet.": "Your payment was refunded. {amount} {currency} was returned to your wallet.",
    "Valid address": "Valid address",
    "This social account is used by another user. Verify your email first or contact Customer Support": "This social account is used by another user. Verify your email first or contact Customer Support",
    "Invalid wallet connection": "Invalid wallet connection",
    "Registration completed successfully": "Registration completed successfully",
    "Failed to complete registration of the user": "Failed to complete registration of the user",
    "Failed to register user":"Failed to register user",
    "Signature grant failed": "Signature grant failed",
    "Unsupported chain": "Unsupported chain",
    "This wallet address is already associated with another user account. Please use another one!": "This wallet address is already associated with another user account. Please use another one!",
    "Failed to add address to your wallet": "Failed to add address to your wallet",
    "Address has been successfully added to your wallet": "Address has been successfully added to your wallet",
    "Failed to accept Affiliate TMS": "Failed to accept Affiliate TMS",
    "Wallet address has been successfully applied": "Wallet address has been successfully applied",
    "Your email is verified.": "Your email is verified.",
    "Failed to add Token": "Failed to add Token",
    "Failed to upload image": "Failed to upload image",
    "Wrong file size. Please upload image files with size less than 10MB": "Wrong file size. Please upload image files with size less than 10MB",
    "We've verified your email address. You can now continue using the site.": "We've verified your email address. You can now continue using the site.",
    "Actions": "Actions",
    "Request to change the chain has been rejected": "Request to change the chain has been rejected",
    "Switch Chain": "Switch Chain",
    "You need to switch to Binance Smart Chain": "You need to switch to Binance Smart Chain",
    "Switch to Binance Smart Chain to continue using GymStreet": "Switch to Binance Smart Chain to continue using GymStreet",
    "To CashFT": "To CashFT",
    "Choose your Metaverse": "Choose your Metaverse",
    "You need to choose your Metaverse to continue. Maps, Dashboard and My Products information will vary depending on your selection. Currently {METAVERSE} Metaverse is selected.": "You need to choose your Metaverse to continue. Maps, Dashboard and My Products information will vary depending on your selection. Currently {METAVERSE} Metaverse is selected.",
    "Can't check your purchases": "Can't check your purchases",
    "Attach {COUNT} miners": "Attach {COUNT} miners",
    "Attach 1 miner": "Attach 1 miner",
    "View on map": "View on map",
    "Buy {COUNT} parcels": "Buy {COUNT} parcels",
    "Buy 1 parcel": "Buy 1 parcel",
    "Buy {COUNT} miners": "Buy {COUNT} dual miners",
    "Buy 1 miner": "Buy 1 dual miner",
    "Metaverses": "Metaverses",
    "My NFTs": "My NFTs",
    "Total Hashpower (Mh/s)": "Total Hashpower (Mh/s)",
    "Available Miner Slots": "Available Miner Slots",
    "Buy a Super Bundle in the next": "Buy a Super Bundle in the next",
    "and get an EXTRA discount!": "and get an EXTRA discount!",
    "See All Offers": "See All Offers",
    "I accept the affiliate terms & conditions": "I accept the affiliate terms & conditions",
    "Special offer!": "Special offer!",
    "Just one": "Just one",
    "All": "All",
    "1000 Mh/s": "1000 Mh/s",
    "View Claimed Coordinates": "View Claimed Coordinates",
    "Your Parcel Coordinates": "Your Parcel Coordinates",
    "Number of miners you want to attach": "Number of miners you want to attach",
    "Average ROI past 9 months": "Average ROI past 9 months",
    "Login Via Crypto Wallet": "Login Via Crypto Wallet",
    "Referral Link": "Referral Link",
    "Affiliate Link": "Affiliate Link",
    "Invalid Wallet": "Invalid Wallet",
    "Back to Log In": "Back to Log In",
    "You need a valid referral ID to finalize your registration": "You need a valid referral ID to finalize your registration",
    "I have read and accept the": "I have read and accept the",
    "Your Sponsor": "Your Sponsor",
    "Forgot Your Password": "Forgot Your Password",
    "Enter your email address and we will send you instructions to reset your password.": "Enter your email address and we will send you instructions to reset your password.",
    "We just sent you an email to confirm your e-mail address. Please click on the link in the e-mail to reset your password.": "We just sent you an email to confirm your e-mail address. Please click on the link in the e-mail to reset your password.",
    "Welcome To Metaverse": "Welcome To {n} Metaverse",
    "Commissions": "Commissions",
    "Refund of electricity vouchers": "Refund of electricity vouchers",
    "Import Custom Token": "Import Custom Token",
    "Add Token To Wallet": "Add Token To Wallet",
    "Token contract address": "Token contract address",
    "Token symbol": "Token symbol",
    "Provided address is not a supported token address": "Provided address is not a supported token address",
    "Are you sure you want to claim your rewards": "Are you sure you want to claim your rewards",
    "OTP Code": "OTP Code",
    "OTP Code is required": "OTP Code is required",
    "Enter OTP code you have received at email": "Enter OTP code you have received at {email}",
    "Asset": "Asset",
    "Gas": "Gas",
    "Send crypto": "Send crypto",
    "Enter wallet address": "Enter wallet address",
    "My wallet": "My wallet",
    "NFTs": "NFTs",
    "Activity": "Activity",
    "Assets": "Assets",
    "Import token": "Import token",
    "Don't see your token?": "Don't see your token?",
    "more": "more",
    "Swap": "Swap",
    "Send": "Send",
    "Sending success": "Sending success",
    "View account in explorer": "View account in explorer",
    "estimated": "estimated",
    "Copy your address": "Copy your address",
    "Export private key": "Export private key",
    "Receive": "Receive",
    "Account Details": "Account Details",
    "View on blockchain": "View on blockchain",
    "GymStreet Parcels": "GymStreet Parcels",
    "GymStreet Miners": "GymStreet Miners",
    "Kabutocho Parcels": "Kabutocho Parcels",
    "Kabutocho Miners": "Kabutocho Miners",
    "Invalid wallet address": "Invalid wallet address",
    "Calculate": "Calculate",
    "Sending": "Sending",
    "Reject": "Reject",
    "Confirmation": "Confirmation",
    "Gas fees are paid to crypto miners who process transactions on the network. Gas fees are set by the network and fluctate based on network traffic and transaction complexity.": "Gas fees are paid to crypto miners who process transactions on the network. Gas fees are set by the network and fluctate based on network traffic and transaction complexity.",
    "max": "max",
    "Insufficient TOKEN balance": "Insufficient {token} balance",
    "Confirm Swap": "Confirm Swap",
    "Approve Allowance": "Approve Allowance",
    "Help Center": "Help Center",
    "Confirm the Allowance Transaction in order to be able to use Token through our services. Please note that this is a one-time action.": "Confirm the Allowance Transaction in order to be able to use <strong>{n}</strong> Token through our services. Please note that this is a one-time action.",
    "No Activities yet": "No Activities yet",
    "To": "To",
    "Contract interaction": "Contract interaction",
    "Are you sure you want to delete this token": "Are you sure you want to delete this token",
    "Send only using  {CHAIN}  network to receive funds on this deposit address.": "Send only using  {CHAIN}  network to receive funds on this deposit address.",
    "Land reserved for PARTNER": "Land reserved for {PARTNER}",
    "About PARTNER": "About {PARTNER}",
    "swap": "swap",
    "transfer": "transfer",
    "approve": "approve",
    "Careers status": "Careers status",
    "Level pool": "Level pool",
    "Turnover pool": "Turnover pool",
    "Leadership Incentives": "Leadership Incentives",
    "Global Line Bonus": "Global Line Bonus",
    "Turnover pools": "Turnover pools",
    "Tax pools": "Tax pools ({n}mo)",
    "Total qualified turnover": "Total qualified turnover",
    "My claimed rewards": "My claimed rewards",
    "My pending rewards": "My pending rewards",
    "Pool statistics": "Pool statistics",
    "Hourly": "Hourly",
    "Week": "Week",
    "Month": "Month",
    "Turnover rule": "Turnover rule",
    "In order to get qualified for any pool, you’ll have to follow the 50% turnover rule: The maximum share each direct partner can contribute to the required turnover is up to 50% of the turnover coming from NFT sales.": "In order to get qualified for any pool, you’ll have to follow the 50% turnover rule: The maximum share each direct partner can contribute to the required turnover is up to 50% of the turnover coming from NFT sales.",
    "View more": "View more",
    "Turnover progress": "Turnover progress",
    "Global line before June 2023": "Global line before June 2023",
    "Global line before March 2023": "Global line before March 2023",
    "Thank you for your participation in Global Line Incentive!": "Thank you for your participation in Global Line Incentive!",
    "More info": "More info",
    "Your Position": "Your Position",
    "Last Position": "Last Position",
    "My line length": "My line length",
    "Global Line Turnover": "Global Line Turnover",
    "The turnover generated from all participants who joined the Global Line after you.": "The turnover generated from all participants who joined the Global Line after you.",
    "Your purchases": "Your purchases",
    "You need purchases of at least $100 to become a Global Line member.": "You need purchases of at least $100 to become a Global Line member.",
    "Your pending income": "Your pending income",
    "The minimum amount to claim is N": "The minimum amount to claim is {n}",
    "Buy more NFTs to increase your shares": "Buy more <strong><i>NFTs</i></strong> to increase your <strong>shares.</strong>",
    "New Registrations": "New Registrations",
    "Turnover": "Turnover",
    "Global level": "Global level",
    "Joining time": "Joining time",
    "Global Line Total Turnover": "Global Line Total Turnover",
    "Global Line end": "Global Line end",
    "Please confirm your claim": "Please confirm your claim",
    "After you confirm, you will receive your rewards on your balance.": "After you confirm, you will receive your rewards on your balance.",
    "Select Language": "Select Language",
    "Download": "Download",
    "Your Income": "Your Income",
    "Shares": "Shares",
    "Single Miner": "Single Miner",
    "Super Bundles": "Super Bundles",
    "Regular Bundles": "Regular Bundles",
    "paginationText": "Showing {from} - {to} of {count}",
    "Turnover rule percentage": "{n}% Turnover rule",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%.": "The maximum share each direct partner can contribute to the required turnover is up to 50%.",
    "Your partners": "Your partners",
    "Generated turnover": "Generated turnover",
    "Qualified turnover": "Qualified turnover",
    "Generated": "Generated",
    "Qualified": "Qualified",
    "Your partners turnover statistics will appear here.": "Your partners turnover statistics will appear here.",
    "Pools": "Pools",
    "Qualification": "Qualification",
    "Rewards": "Rewards",
    "Participants": "Participants",
    "Total rewards": "Total rewards",
    "Rewards per participant": "Rewards per participant",
    "Miner sales contribution": "Miner sales contribution",
    "Land sales contribution": "Land sales contribution",
    "Maintainance contribution": "Maintainance contribution",
    "Pool": "Pool",
    "Distributed": "Distributed",
    "Refresh data": "Refresh data",
    "You will receive your rewards directly on your wallet": "You will receive your rewards directly on your wallet",
    "Total claimed": "Total claimed",
    "Next distribution": "Next distribution",
    "percent of NFT sales": "{n}% of NFT sales",
    "Unclaimed BUSD rewards": "Unclaimed BUSD rewards",
    "Required level": "Required level",
    "NFT sales percent": "NFT sales {n}%",
    "Total qualified participants": "Total qualified participants",
    "TxID": "TxID",
    "Tx Type": "Tx Type",
    "Global line reward": "Global line reward",
    "Your share": "Your share",
    "Income before": "Income before",
    "Income after": "Income after",
    "turnovers": 'Turnovers',
    "Total partners": 'Total partners',
    "depth": 'Depth',
    "turnover": 'Turnover',
    "refreshData": "Refresh data",
    "NFT Turnover": "NFT Turnover",
    "Single Pool Turnover": "Single Pool Turnover",
    'weekly': "Weekly",
    'monthly': "Monthly",
    'yearly': "Yearly",
    'wallet': 'Wallet',
    'columns': 'Columns',
    'filters': 'Filters',
    'Unknown': 'Unknown',
    'level': 'Level',
    'search': 'Search',
    'partners': 'partners',
    'partner': 'partner',
    'Farming Turnover': "Farming Turnover",
    'Vault Turnover': "Vault Turnover",
    'Main area': 'Main area',
    "NFT Income": "NFT Income",
    "NFT Commissions Total": "NFT Commissions Total",
    "NFT Statistic": "NFT Statistic",
    "Level": "Level",
    "User": "User",
    "Your current pin": "Your current pin",
    "Turnover for the next pin": "Turnover for the next pin",
    "Turnover percentage rule": "Turnover {n}% rule",
    "With dollar each": "With dollar each",
    "Direct partners": "Direct partners",
    "Your next bonus will appear here": "Your next bonus will appear here",
    "Your next incentive": "Your next incentive",
    "Your next bonus": "Your next bonus",
    "Your incentive": "Your incentive",
    "Your cash bonus": "Your cash bonus",
    "Your bonus history": "Your bonus history",
    "At the current moment you don't have any rewards": "At the current moment you don't have any rewards",
    "Incentives": "Incentives",
    "Partners with pin qualification": "Partners with {n} pin qualification",
    "Team of": "Team of",
    "Partners": "Partners",
    "Cash Bonus": "Cash Bonus",
    "Your pin": "Your pin",
    "Incentive": "Incentive",
    "Pin": "Pin",
    "Total generated turnover": "Total generated turnover",
    "Unclaimed parcels": "Unclaimed parcels",
    "Upgraded parcels": "Upgraded parcels",
    "Inactive miners": "Inactive miners",
    "Active Miners": "Active Miners",
    "Go to PARTNER": "Go to {PARTNER}",
    "Total pool rewards": "Total pool rewards",
    "Your pool share": "Your pool share",
    "Total gGymnet": "Total gGymnet",
    "GYMNET Commissions Total": "GYMNET Commissions Total",
    "Your attached wallet is {walletAddress}. You have tried to make request with wallet {currentWallet}. Please change connected wallet to {walletAddress}": "Your attached wallet is <b>{walletAddress}</b>.\nYou have tried to make request with wallet <b>{currentWallet}</b>. Please change connected wallet to <b>{walletAddress}</b>",
    "Reach Rank": "Reach Rank <b>{n}</b>",
    "Available for limited time": "Available for limited time",
    "Value": "Value",
    "Bonus": "Bonus",
    "Partnerships": "Partnerships",
    "Cannot find EMAIL_OR_WALLET": "Cannot find {EMAIL_OR_WALLET}",
    "Incentive Ends In: ": "Incentive <strong>Ends In</strong>: ",
    "Your Rewards History": "Your Rewards History",
    "Dubai & Sofia Trip": "Dubai & Sofia Trip",
    "No Rewards": "No Rewards",
    "No Partners": "No Partners",
    "Reward": "Reward",
    "Granted": "Granted",
    "Transaction": "Transaction",
    "Free BUNDLE": "Free {BUNDLE}",
    "Team Purchases": "Team Purchases",
    "Direct Sales": "Direct Sales",
    "You have won this reward": "You have won this reward",
    "50% Turnover Rule is applied for all incentives!": "50% Turnover Rule is applied for all incentives!",
    "Please note that you have the chance to earn a complimentary R8 Bundle in two different ways: either by reaching 20k in direct sales or by achieving 100k in team purchases.": "Please note that you have the chance to earn a complimentary R8 Bundle in two different ways: either by reaching 20k in direct sales or by achieving 100k in team purchases.",
    "Insufficient funds for gas, please add BNB to your wallet": "Insufficient funds for gas, please add BNB to your wallet",
    "Please note that you will require up to AMOUNT worth of CURRENCY to complete the transaction.": "Please note that you will require up to {amount} worth of {currency} to complete the transaction.",
    "Please note that AMOUNT worth of CURRENCY is required to complete the transaction.": "Please note that {amount} worth of {currency} is required to complete the transaction.",
    "Please note that AMOUNT_CURRENCY is required for gas fee": "Please note that {amount_currency} is required for gas fee",
    "Select a payment method": "Select a payment method",
    "Use your account's wallet balances to purchase the product.": "Use your account's wallet balances to purchase the product.",
    "Connect a WEB3 wallet like Metamask or Trustwallet and pay with {currency}. This requires a small amount of BNB ($0.30) to cover the transaction fees!": "Connect a WEB3 wallet like Metamask or Trustwallet and pay with {currency}. This requires a small amount of BNB ($0.30) to cover the transaction fees!",
    "Select one of the available blockchains and cryptocurrencies, and proceed with the payment via an external wallet application.": "Select one of the available blockchains and cryptocurrencies, and proceed with the payment via an external wallet application.",
    "Internal wallet": "Internal wallet",
    "Web3 Wallet": "Web3 Wallet",
    "External wallet": "External wallet",
    "Select Currency": "Select Currency",
    "Purchase for myself": "Purchase for myself",
    "Select Blockchain": "Select Blockchain",
    "Select Cryptocurrency": "Select Cryptocurrency",
    "Please select another payment method": "Please select another payment method",
    "Connect my wallet": "Connect my wallet",
    "Select a product owner": "Select a product owner",
    "Please select who you are purchasing for:": "Please select who you are purchasing for:",
    "Product Owner Tooltip": "Product Owner Tooltip",
    "Owner e-mail": "Owner e-mail",
    "Purchase Summary": "Purchase Summary",
    "Network": "Network",
    "Fees": "Fees",
    "Rate": "Rate",
    "Send to wallet address": "Send to wallet address",
    "Or scan the code with your wallet app to fill in address automatically.": "Or scan the code with your wallet app to fill in address automatically.",
    "Send only CURRENCY to this wallet address!": "Send only {currency} to this wallet address!",
    "Please send your crypto within 30 minutes to ensure a fixed exchange rate": "Please send your crypto within 30 minutes to ensure a fixed exchange rate",
    "I sent the crypto": "I sent the crypto",
    "Insufficient funds": "Insufficient funds",
    "Purchase Successful": "Purchase Successful!",
    "You can monitor the transaction status in the Order History tab. If it is successful, the NFT balance will be updated in the My NFTs tab.": "You can monitor the transaction status in the \"Order History\" tab. If it is successful, the NFT balance will be updated in the \"My NFTs\" tab.",
    "The NFT balance was successfully updated and can be viewed in My NFTs tab.": "The NFT balance was successfully updated and can be viewed in \"My NFTs\" tab.",
    "Available balance": "Available balance",
    "OFF": "OFF",
    "This window will close automatically once we receive your crypto.": "This window will close automatically once we receive your crypto.",
    "We did receive your crypto, but it was less than required. Please transfer the missing amount to complete your transaction.": "We did receive your crypto, but it was less than required. Please transfer the missing amount to complete your transaction.",
    "Please do not attempt to do another transaction. Send your Payment UID to our support.": "Please do not attempt to do another transaction. Send your Payment UID to our support.",
    "Scanning for transaction": "Scanning for transaction",
    "Go to My Orders": "Go to My Orders",
    "remaining": "remaining",
    "Please send your crypto within 3 hours to ensure a fixed exchange rate": "Please send your crypto within 3 hours to ensure a fixed exchange rate",
    "Please send your crypto within n minutes to ensure a fixed exchange rate": "Please send your crypto within {N} minutes to ensure a fixed exchange rate",
    "Missing crypto amount": "Missing crypto amount",
    "Missing amount": "Missing amount",
    "Received Amount.": "Received Amount.",
    "Required amount": "Required amount",
    "Continue with payment": "Continue with payment",
    "Processing Fee": "Processing Fee",
    "Estimated gas fee": "Estimated gas fee",
    "Please indicate the intended recipient of the product. You have the option to purchase it for yourself or choose 'Another user' as the recipient.": "Please indicate the intended recipient of the product. You have the option to purchase it for yourself or choose \"Another user\" as the recipient.",
    "Your payment has expired. To resolve this issue, please contact customer support": "Your payment has expired. To resolve this issue, please contact customer support",
    "Please choose your preferred payment method to finalize your purchase.": "Please choose your preferred payment method to finalize your purchase.",
    "Your payment has expired. To resolve this issue, please contact customer support.": "Your payment has expired. To resolve this issue, please contact customer support.",
    "Your payment has been received and is currently being processed. We will notify you once the process is complete.": "Your payment has been received and is currently being processed. We will notify you once the process is complete.",
    "Your transaction has been declined. Please attempt the transaction again.": "Your transaction has been declined. Please attempt the transaction again.",
    "Monitor the status of your transaction by visiting the provided link:": "Monitor the status of your transaction by visiting the provided link:",
    "Apologies, an error occurred on the blockchain side. Please try again.": "Apologies, an error occurred on the blockchain side. Please try again.",
    "Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.": "Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.",
    "Transaction Expired": "Transaction Expired",
    "Enable Payment": "Enable Payment",
    "Email does not exist": "Email does not exist",
    "Request another quote": "Request another quote",
    "Purchase Success!": "Purchase Success!",
    "Pay": "Pay",
    "You purchased GIFT for RECEIVER!": "You purchased {gift} for {receiver}!",
    "You've purchased GIFT for RECEIVER!": "You purchased {gift} for \"{receiver}\"!",
    "You've purchased a GIFT for RECEIVER!": "You purchased a {gift} for \"{receiver}\"!",
    "You received GIFT from SENDER!": "You received {gift} from {sender}!",
    "You've received GIFT from SENDER!": "You've received {gift} from \"{sender}\"!",
    "You've received a GIFT from SENDER!": "You've received a {gift} from \"{sender}\"!",
    "Rewards claimed successfully": "Rewards claimed successfully",
    "Commissions claimed successfully.": "Commissions claimed successfully.",
    "Transaction Failed": "Transaction Failed",
    "The transaction has failed. Please try again.": "The transaction has failed. Please try again.",
    "Your transaction has been declined. Please add BNB to your wallet and attempt the transaction again.": "Your transaction has been declined. Please add BNB to your wallet and attempt the transaction again.",
    "Blockchain Notification: Apologies, an error occurred on the blockchain side. Please try again.": "Blockchain Notification: Apologies, an error occurred on the blockchain side. Please try again.",
    "Apologies, an unexpected error occurred. Please retry your request. If the issue persists, please contact support.": "Apologies, an unexpected error occurred. Please retry your request. If the issue persists, please contact support.",
    "Received Amount": "Received Amount",
    "No enough CURRENCY to cover gas fee.": "No enough {currency} to cover gas fee.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover gas fee.": "Please top up your wallet with {amount_currency} to cover gas fee.",
    "Scanning": "Scanning",
    "To continue, please enable payment.": "To continue, please enable payment.",
    "Kindly be aware that AMOUNT_CURRENCY may be necessary to cover the gas fee.": "Kindly be aware that {amount_currency} may be necessary to cover the gas fee.",
    "Insufficient funds in CURRENCY to cover the gas fee.": "Insufficient funds in {currency} to cover the gas fee.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover the gas fee.": "Please top up your wallet with {amount_currency} to cover the gas fee.",
    "We will cover the gas fee for this transaction on your behalf.": "We will cover the gas fee for this transaction on your behalf.",
    "Available Balance": "Available Balance",
    "Utility Balance": "Utility Balance",
    "Bitopex Balance": "Bitopex Balance",
    "Moonberg Balance": "Moonberg Balance",
    "Balance History": "Balance History",
    "Miner Hashpower": "Miner Hashpower",
    "Dual Miners": "Dual Miners",
    "Dual miners info": "The number of active and total dual miners.",
    "Dual Miner Hashpower": "Dual Miner Hashpower",
    "Dual miners hashpower info": "The hashpower you possess is determined by multiplying the number of your active dual miners with the unit miner hashpower, which is set at 1000Mh/s per unit.",
    "Unlock the full potential of your Bitopex rewards as they come to life following your purchase of any of our special bundles. Once your Bitopex Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Unlock the full potential of your Bitopex rewards as they come to life following your purchase of any of our special bundles. Once your Bitopex Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.",
    "Rewards in Gymnet": "Rewards in Gymnet",
    "Rewards in Bitcoin": "Rewards in Bitcoin",
    "Dual Miner": "Dual Miner",
    "Sec": "Sec",
    "Min": "Min",
    "Seconds": "Seconds",
    "Minutes": "Minutes",
    "Days": "Days",
    "Hours": "Hours",
    "Total Rewards Expected": "Total Rewards Expected",
    "Monthly Distribution": "Monthly Distribution",
    "Price Projection": "Price Projection",
    "Server error": "Server error",
    "Average Gymnet ROI for the last 9 months": "Average Gymnet ROI for the last 9 months",
    "Dual Miners will generate rewards in Bitopex tokens, which will be distributed every Friday.":"Dual Miners will generate rewards in Bitopex tokens, which will be distributed every Friday.",
    "Introducing the Utility Balance feature, a new and convenient payment method within our payment ecosystem. With this feature, you'll enjoy a separate balance that can be used alongside other payment methods. It provides you with the flexibility to partially utilize your utility balance, up to 25% of the total when purchasing single Land NFTs or making payments for Land NFT bundles.":"Introducing the Utility Balance feature, a new and convenient payment method within our payment ecosystem. With this feature, you'll enjoy a separate balance that can be used alongside other payment methods. It provides you with the flexibility to partially utilize your utility balance, up to 25% of the total when purchasing single Land NFTs or making payments for Land NFT bundles.",
    "Balance before": "Balance before",
    "Balance after": "Balance after",
    "TXID": "TXID",
    "The number of active and total miners you have in Gymstreet and Kabutochō, respectively.": "The number of active and total miners you have in Gymstreet and Kabutochō, respectively.",
    "The hashpower you possess is determined by multiplying the number of your active miners with the unit miner hashpower, which is set at 1000Mh/s per unit.": "The hashpower you possess is determined by multiplying the number of your active miners with the unit miner hashpower, which is set at 1000Mh/s per unit.",
    "For Limited Time Only": "For <strong>Limited Time</strong> Only",
    "Token": "Token",
    "Buy Bundles": "Buy Bundles",
    "Total Received": "Total Received",
    "Next Rewards Distribution In:": "Next Rewards Distribution In:",
    "Total Rewards": "Total Rewards",
    "Payout": "Payout",
    "Weekly": "Weekly",
    "Bitopex": "Bitopex",
    "Gymnet": "Gymnet",
    "User Profile": "User Profile",
    "Name": "Name",
    "Nationality": "Nationality",
    "Contact Information": "Contact Information",
    "Country": "Country",
    "City": "City",
    "Street": "Street",
    "Zip": "Zip",
    "E-Mail": "E-Mail",
    "Wallet address": "Wallet address",
    "Name is required": "Name is required",
    "Nationality is required": "Nationality is required",
    "Country is required": "Country is required",
    "City is required": "City is required",
    "Street is required": "Street is required",
    "Zip is required": "Zip is required",
    "Successfully updated": "Successfully updated",
    "Zip contain only numbers and min length is 3": "Zip contain only numbers and min length is 3",
    "Please enter a valid email address": "Please enter a valid email address",
    "Personal information": "Personal information",
    "T&C": "T&C",
    "Field is required": "Field is required",
    "Password successfully changed": "Password successfully changed",
    "Old password is incorrect": "Old password is incorrect",
    "Oops...Something went wrong. Please try again": "Oops...Something went wrong. Please try again",
    "Name must be at least 3 characters": "Name must be at least 3 characters",
    "City must be at least 3 characters": "City must be at least 3 characters",
    "Street must be at least 3 characters": "Street must be at least 3 characters",
    "Affiliate Terms and conditions": "Affiliate Terms and conditions",
    "Affiliate links": "Affiliate links",
    "Affiliate link successfully changed": "Affiliate link successfully changed",
    "Please enter the Bitopex affiliate link here. Kindly note that you can only save the link once.": "Please enter the Bitopex affiliate link here. Kindly note that you can only save the link once.",
    "Input cannot be only spaces": "Input cannot be only spaces",
    "To proceed with the registration, kindly accept the above 2 conditions by checking the corresponding boxes.": "To proceed with the registration, kindly accept the above 2 conditions by checking the corresponding boxes.",
    "I hereby confirm that I am not a US citizen": "I hereby confirm that I am not a US citizen",
    "I hereby confirm that I am not a UAE citizen": "I hereby confirm that I am not a UAE citizen",
    "Enter Old Password": "Enter Old Password",
    "Change username & password": "Change username & password",
    "Utility": "Utility",
    "Next Distribution Amount": "Next Distribution Amount",
    "Internal transaction": "Internal transaction",
    "The URL must begin with 'https://bitopex.io/home/' and include your referral ID": "The URL must begin with 'https://bitopex.io/home/' and include your referral ID",
    "Purchase": "Purchase",
    "Insufficient funds: to continue with payment use utility balance": "Insufficient funds: to continue with payment use utility balance",
    "Username required to proceed": "Username required to proceed",
    "Enter preferred username here": "Enter preferred username here",
    "Username must be at least 8 characters, starting with a letter. Use only lowercase letters, numbers, hyphens, dots, and underscores.": "Username must be at least 8 characters, starting with a letter. Use only lowercase letters, numbers, hyphens, dots, and underscores.",
    "Username is already taken. Please choose a different username.": "Username is already taken. Please choose a different username.",
    "Email or username": "Email or username",
    "Username is available": "Username is available",
    "Provided username is already taken.": "Provided username is already taken.",
    "Invalid credentials. Please try again.": "Invalid credentials.\n Please try again.",
    "Username successfully set": "Username successfully set",
    "Dear User, due to ongoing updates to our platform, you will need to enter a username to continue. The username needs to be unique and cannot be changed": "Dear User, due to ongoing updates to our platform, you will need to enter a username to continue. The username needs to be unique and cannot be changed",
    "You have successfully set your username": "You have successfully set your username",
    "Continue to dashboard": "Continue to dashboard",
    "Username is required": "Username is required",
    "My Team": "My Team",
    "Team Statistics": "Team Statistics",
    "All partners": "All partners",
    "Active partners": "Active partners",
    "Inactive partners": "Inactive partners",
    "Binary Points": "Binary Points",
    "Points in left leg": "Points in left leg",
    "Points in right leg": "Points in right leg",
    "Left leg": "Left leg",
    "Right leg": "Right leg",
    "Holding Tank": "Holding Tank",
    "Enrollment Tree": "Enrollment Tree",
    "Binary Tree": "Binary Tree",
    "CV Points History": "CV Points History",
    "Time Left To Activation": "Time Left <b>To Activation</b>",
    "Search by email or wallet": "Search by email or wallet",
    "Refresh Data": "Refresh Data",
    "Joined at": "Joined at",
    "Placement": "Placement",
    "Select": "Select",
    "Own": "Own",
    "Team": "Team",
    "Position": "Position",
    "CV Points": "CV Points",
    "CV = commission volume": "CV = commission volume",
    "Genealogy": "Genealogy",
    "Unilevel": "Unilevel",
    "Select view": "Select view",
    "Team size": "Team size",
    "Rank": "Rank",
    "Own purchases": "Own purchases",
    "Navigation": "Navigation",
    "Search by wallet": "Search by wallet",
    "no user": "no user",
    "Wallet address is not valid": "Wallet address is not valid",
    "Free Spot": "Free Spot",
    "Search by wallet or username": "Search by wallet or username",
    "Info": "Info",
    "Benefits": "Benefits",
    "Objectives Completed": "Objectives Completed",
    "TO DO`s": "TO DO`s",
    "Purchase NFTs In Total Value Of 100 USDT": "Purchase NFTs In Total Value Of 100 USDT",
    "Purchase Binary Pass - 19 USDT": "Purchase Binary Pass - 19 USDT",
    "Team Turnover": "Team Turnover",
    "BASED ON TEAM": "{n}% + BASED ON TEAM",
    "Qualification Turnover": "Qualification Turnover",
    "Current Rank": "Current Rank",
    "Next Rank": "Next Rank",
    "Binary Spot Reservation": "Binary Spot Reservation",
    "Rank Transfer": "Rank Transfer",
    "Review": "Review",
    "Holding Tank Ends In": "Holding Tank <b>Ends In</b>",
    "With every parcel purchase, you will receive two types of tokens—Gymnet and Bitcoin.": "With every parcel purchase, you will receive two types of tokens—Gymnet and Bitcoin.",
    "Top Up": "Top Up",
    "A minimum of USDT is required for Purchase": "A minimum of ${n} USDT is required for Purchase",
    "Become Affiliate Partner": "Become Affiliate Partner",
    "Placement in Unilevel": "Placement in Unilevel",
    "Matching bonuses": "Matching bonuses",
    "Placement in Binary": "Placement in Binary",
    "Product Autoship Incentive": "Product Autoship Incentive",
    "Product Autoship Monthly": "Product Autoship Monthly",
    "Product Autoship Yearly": "Product Autoship Yearly",
    "Gift": "Gift",
    "For limited time only": "For limited time only",
    "Free 30 days Product Autoship": "Free 30 days Product Autoship",
    "Fast Start Incentive": "Fast Start Incentive",
    "Receive Direct Sales Commissions": "Receive Direct Sales Commissions",
    "Please select your role": "Please select your role",
    "Choose your role before diving in. Specify why user should choose each role and what opportunities are there.": "Choose your role before diving in. Specify why user should choose each role and what opportunities are there.",
    "is left to get": "<span>${n}</span> is left to get",
    "Go to Products": "Go to Products",
    "Rental Income": "Rental Income",
    "Left Leg Affiliate Link": "Left Leg Affiliate Link",
    "Right Leg Affiliate Link": "Right Leg Affiliate Link",
    "Qualification Conditions": "Qualification Conditions",
    "Buy Products": "Buy Products",
    "Member": "Affiliate",
    "Trainee": "Trainee",
    "Rookie": "Rookie",
    "Silver": "Silver",
    "Gold": "Gold",
    "Platinum": "Platinum",
    "Double Platinum": "Double Platinum",
    "Diamond": "Diamond",
    "Double Diamond": "Double Diamond",
    "Red Diamond": "Red Diamond",
    "White Diamond": "White Diamond",
    "Crown Diamond": "Crown Diamond",
    "My Current Rank": "My Current Rank",
    "To Reach Rank You Need:": "To Reach Rank {n} You Need:",
    "NFT Turnover In Weak Leg": "NFT Turnover In Weak Leg",
    "NFT Own purchases": "NFT Own purchases",
    "Required Qualified Partners": "Required Qualified Partners",
    "Progress Until Rank": "Progress Until Rank {n}",
    "See Details": "See Details",
    "Default Advantages": "Default Advantages",
    "Binary Commission": "Binary Commission",
    "Upgrade Your Weekly Cap": "Upgrade Your Weekly Cap",
    "Rank Benefits": "Rank Benefits",
    "For 30 Days ONLY You Receive 10% Binary Commission Instead Of NUM": "For 30 Days ONLY You Receive 10% Binary Commission Instead Of {n}%!",
    "My Rank Progress": "My Rank Progress",
    "Own Invest": "Own Invest",
    "Qualified Partners": "Qualified Partners",
    "You need to buy products in value of": "You need to buy products in value of ${n}",
    "You need to generate turnover in value of in your weak leg.": "You need to generate turnover in value of ${n} in your weak leg.",
    "Remaining": "Remaining",
    "CV Points weak leg": "CV Points weak leg",
    "Details": "Details",
    "Partner": "Partner",
    "leg": "leg",
    "Right": "Right",
    "Left": "Left",
    "You need qualified partner in left leg and qualified partner in right leg to complete this requirement.": "You need {l} qualified partner in left leg and {r} qualified partner in right leg to complete this requirement.",
    "partners in left leg and in right leg": "{l} {partner1} in left leg and {r} partner in right leg remaining",
    "partners in left leg remaining": "{l} {partner1} in left leg remaining",
    "partners in right leg remaining": "{r} partners in right leg remaining",
    "partner in right leg remaining": "{r} partner in right leg remaining",
    "Holding Tank is over": "Holding Tank is over",
    "Back To Rank Progress Details": "Back To Rank Progress Details",
    "Super Bundle R10 or above": "Super Bundle R10\n or above",
    "Required Monthly Product Autoship": "Required Monthly Product Autoship",
    "All Customer Benefits": "All Customer Benefits",
    "Product Autoship": "Product Autoship",
    "Product Autoships": "Product Autoships",
    "Products are resellable": "Products are resellable",
    "Activate binary commission": "Activate binary commission",
    "Activate direct sales commission": "Activate direct sales commission",
    "Left before": "Left before",
    "Left after": "Left after",
    "Right before": "Right before",
    "Right after": "Right after",
    "Activate your referral link to be able to invite your referrals to the platform and earn additional commissions.": "Activate your referral link to be able to invite your referrals to the platform and earn additional commissions.",
    "Activate referral link": "Activate referral link",
    "Yes, I confirm": "Yes, I confirm",
    "Inactive": "Inactive",
    "Finance": "Finance",
    "Total Income": "Total Income",
    "Products": "Products",
    "GymStreet Map": "GymStreet Map",
    "Kabutocho Map": "Kabutocho Map",
    "Don't ask again": "Don\'t ask again",
    "Filecoin Balance": "Filecoin Balance",
    "Filecoin": "Filecoin",
    "Total available": "Total available",
    "Total generated": "Total generated",
    "Total used": "Total used",
    "Transaction type": "Transaction type",
    "To claim your commission, you must have a minimum of 1 USDT or (AMOUNT) GYMNET.": "To claim your commission, you must have a minimum of 1 USDT or {n} GYMNET.",
    "Loading balance...": "Loading balance...",
    "Total Commissions": "Total Commissions",
    "Claimed Commissions": "Claimed Commissions",
    "Available Commissions": "Available Commissions",
    "Total Commissions By Source": "Total Commissions By Source",
    "Filter by currency": "Filter by currency",
    "No data to display": "No data to display",
    "Year": "Year",
    "Active miners": "Active miners",
    "Career Status": "Career Status",
    "Current Incentive": "Current Incentive",
    "Fast Start": "Fast Start",
    "Product Autoship incentive": "Product Autoship incentive",
    "Direct partner - left leg": "Direct partner - left leg",
    "Direct partner - right leg": "Direct partner - right leg",
    "Receive direct sales commission": "Receive direct sales commission",
    "Direct sales commission": "Direct sales commission",
    "Receive binary commission": "Receive binary commission",
    "Affiliate ranks": "Affiliate ranks",
    "1 Left, 1 Right": "1 Left, 1 Right",
    "See Benefits": "See Benefits",
    "2 Silver (1 in different teams)": "2 Silver (1 in different teams)",
    "2 Gold (1 in different teams)": "2 Gold (1 in different teams)",
    "3 Platin (1 in different teams)": "3 Platinum (1 in different teams)",
    "3 Double Platin (1 in different teams)": "3 Double Platinum (1 in different teams)",
    "4 Diamond (1 in different teams)": "4 Diamond (1 in different teams)",
    "5 Double Diamond (1 in different teams)": "5 Double Diamond (1 in different teams)",
    "5 Red Diamond (1 in different teams)": "5 Red Diamond (1 in different teams)",
    "5 White Diamond (1 in different teams)": "5 White Diamond (1 in different teams)",
    "Platin": "Platinum",
    "Double Platin": "Double Platinum",
    "Please note that $100 dollar will correspond to 100 points.": "Please note that $100 dollar will correspond to 100 points.",
    "Home": "Home",
    "Last Left": "Last Left",
    "Last Right": "Last Right",
    "Binary leg": "Binary leg",
    "by admin": "by admin",
    "missed commission": "missed commission",
    "payment": "payment",
    "commission": "commission",
    "Payment Currency": "Payment Currency",
    "BUSD Amount": "BUSD Amount",
    "CV Ratio": "CV Ratio",
    "Hrs": "Hrs",
    "Mins": "Mins",
    "Secs": "Secs",
    "Please enter a valid referral ID": "Please enter a valid referral ID",
    "Commission TXID": "Commission TXID",
    "Qualification turnover": "Qualification turnover",
    "Binary commission": "Binary commission",
    "Additional Advantages": "Additional Advantages",
    "The referral ID you've entered is incorrect. Please copy and paste the correct referral link to proceed.": "The referral ID you've entered is incorrect. Please copy and paste the correct referral link to proceed.",
    "Claim processing may take up to 2 minutes. Thank you for your patience.": "Claim processing may take up to 2 minutes. Thank you for your patience.",
    "1 Person": "1 Person",
    "2 Persons": "2 Persons",
    "Extended Referral Link": "Extended Referral Link",
    "Enter a username": "Enter a username",
    "Possible only for usernames in your binary team!": "Possible only for usernames in your binary team!",
    "Select Team": "Select Team",
    "Create Link": "Create Link",
    "New users will be placed under in the team": "New users will be placed under {n} in the {p} team",
    "Invalid username!": "Invalid username!",
    "Left team": "Left team",
    "Right team": "Right team",
    "The claim button is currently disabled for maintenance. We apologize for the inconvenience. Please come back shortly.": "The claim button is currently disabled for maintenance. We apologize for the inconvenience. Please come back shortly.",
    "Distribution In Progress": "Distribution In Progress",
    "Next Binary Bonus Distribution In": "Next Binary Bonus Distribution In",
    "Matching Bonus Commissions": "Matching Bonus Commissions",
    "Binary Commissions": "Binary Commissions",
    "Binary Weekly Cap": "Binary Weekly Cap",
    "Binary Cycles": "Binary Cycles",
    "Matching Bonus": "Matching Bonus",
    "Incomes from Product Autoships": "Incomes from Product Autoships",
    "Missed Commissions": "Missed Commissions",
    "Plus Max Weekly Payout": "Plus Max Weekly Payout",
    "Import Token With Address": "Import Token With Address",
    "Import Supported Token": "Import Supported Token",
    "Shop": "Shop",
    "Filter by": "Filter by",
    "My Purchases": "My Purchases",
    "Purchases for other members": "Purchases for other members",
    "Product Autoship Purchases": "Product Autoship Purchases",
    "Sort By": "Sort By",
    "Top up": "Top up",
    "Attach your miners": "Attach your miners",
    "Buy parcels": "Buy parcels",
    "Number of parcels you want to buy": "Number of parcels you want to buy",
    "Use utility balance": "Use utility balance",
    "My Products": "My Products",
    "USDT Amount": "USDT Amount",
    "Next Matching Bonus Distribution In": "Next Matching Bonus Distribution In",
    "Please top up your wallet with AMOUNT_CURRENCY USDT for upgrade.": "Please top up your wallet with {AMOUNT_CURRENCY} USDT for upgrade.",
    "Please note that worth of USDT is required to complete the transaction.": "Please note that {n} worth of USDT is required to complete the transaction.",
    "In order to receive binary commissions you need to have an active product autoship.": "In order to receive binary commissions you need to have an active product autoship.",
    "In order to receive binary commissions you need to have an active product autoship and a purchase for at least $100.": "In order to receive binary commissions you need to have an active product autoship and a purchase for at least $100.",
    "To qualify for binary commissions, a minimum product purchase of $100 is required.": "To qualify for binary commissions, a minimum product purchase of $100 is required.",
    "Transaction in Process": "Transaction in Process",
    "Claim your parcel": "Claim your parcel",
    "You do not have any claimed slots to attach miners. To enable miner attachment, please claim parcels on the map.": "You do not have any claimed slots to attach miners. To enable miner attachment, please claim parcels on the map.",
    "Active binary commission": "Active binary commission",
    "Active direct sales commission": "Active direct sales commission",
    "Own purchase min {AMOUNT}": "Own purchase min {AMOUNT}",
    "Active product autoship": "Active product autoship",
    "Right partner own purchase min {AMOUNT}": "Right partner own purchase min {AMOUNT}",
    "Left partner own purchase min {AMOUNT}": "Left partner own purchase min {AMOUNT}",
    "Incentive Ends In:": "Incentive Ends In:",
    "Bonus History": "Bonus History",
    "Incentive has ended": "Incentive has ended",
    "Direct Sales Turnover": "Direct Sales Turnover",
    "Commissions are paid out following a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "Commissions are paid out following a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.",
    "(effective as of 28.02.2024)": "(effective as of 28.02.2024)",
    "Rank Achievement Bonus": "Rank Achievement Bonus",
    "Rank achievement bonus eligibility is based on rank achieved through qualifications.": "Rank achievement bonus eligibility is based on rank achieved through qualifications.",
    "Bonuses": "Bonuses",
    "Bonuses are paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET tokens": "Bonuses are paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET tokens",
    "Rank current": "Rank {n}",
    "Unfortunately, you won't receive the bonus for this rank since you met the qualification conditions (more than 50%) before September 18, 2023": "Unfortunately, you won't receive the bonus for this rank since you met the qualification conditions (more than 50%) before September 18, 2023",
    "You will be eligible for cash bonus based on your rank if you meet all qualification conditions between September 18 and November 8.Those who met the qualification conditions (more than 50%) before September 18, 2023 will not receive the Super Cash Bonus.": "You will be eligible for cash bonus based on your rank if you meet all qualification conditions between September 18 and January 31. Those who met the qualification conditions (more than 50%) before September 18, 2023 will not receive the Super Cash Bonus.",
    "You will receive the bonus after the incentive is over, and you can view it in the 'My Wallet' section.": "You will receive the bonus after the incentive is over, and you can view it in the 'My Wallet' section.",
    "You must have at least RANK partners, with at least one in a different leg.": "You must have at least {rank} partners, with at least one in a different leg.",
    "Additional Assets": "Additional Assets",
    "Back to My wallet": "Back to My wallet",
    "Bonus points": "Bonus points",
    "Fee": "Fee",
    "Bonus Details": "Bonus Details",
    "Details / weekly cap": "Details / weekly cap",
    "User / amount": "User / amount",
    "Extended Ref Link": "Extended Ref Link",
    "Leadership": "Leadership",
    "Travel": "Travel",
    "Cash Promo": "Cash Promo",
    "Travel Incentive": "Travel Incentive",
    "Super Bundle R8": "Super Bundle R8",
    "Super Bundle R17": "Super Bundle R17",
    "Starter Bundle": "Starter Bundle",
    "Monthly Product Autoship is required to be an Affiliate Partner and earn commissions.": "Monthly Product Autoship is required to be an Affiliate Partner and earn commissions.",
    "Gain Fresh Perspectives and Exclusive Benefits": "Gain Fresh Perspectives and Exclusive Benefits",
    "Buy products for at least $100 within 30 days to receive these benefits.": "Buy products for at least <span>$100</span> within <span>30 days</span> to receive these benefits.",
    "To qualify for Binary Commissions, you must have a new member with at least $100 on your right team and another on your left team.": "To qualify for Binary Commissions, you must have a new member with at least $100 on your right team and another on your left team.",
    "Free Product Autoship": "Free Product Autoship",
    "10% Binary Commission": "10% Binary Commission",
    "Enjoy More, Benefit More!": "Enjoy More, Benefit More!",
    "Getting started": "Getting started",
    "Confirm your email to continue": "Confirm your email to continue",
    "Resend confirmation email": "Resend confirmation email",
    "Super Bundle Special Discount Expires In": "Super Bundle Special Discount Expires In",
    "Done": "Done",
    "Get your bundle": "Get your bundle",
    "Affiliate": "Affiliate",
    "Binary Commissions based on the rank": "Binary Commissions based on the rank",
    "Customer": "Customer",
    "NFT Turbo": "NFT Turbo",
    "Purchase Digital Land": "Purchase Digital Land",
    "Participate in Partnership Deals": "Participate in Partnership Deals",
    "Receive minting Rewards": "Receive minting Rewards",
    "Unlock the full potential of your Filecoin rewards as they come to life following your purchase of any of our special bundles. Once your Filecoin Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Unlock the full potential of your Filecoin rewards as they come to life following your purchase of any of our special bundles. Once your Filecoin Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.",
    "A parcel with minting power will generate rewards in Filecoin tokens, which will be distributed every Monday.": "A parcel with minting power will generate rewards in Filecoin tokens, which will be distributed every Monday.",
    "Up to": "Up to {n}%",
    "Weekly Distribution": "Weekly Distribution",
    "is left to qualify": "${n} is left to qualify",
    "It seems that a some request is already pending. Please check your wallet app or extension and try again.": "It seems that a some request is already pending. Please check your wallet app or extension and try again.",
    "Check transactions": "Check transactions",
    "Add miner NFT to wallet": "Add Miner NFT to wallet",
    "Go to My NFTs": "Go to My NFTs",
    "Direct partner own purchases": "Direct partner own purchases",
    "Each of your direct partners on the left and right legs needs to generate a turnover value of $100.00.": "Each of your direct partners on the left and right legs needs to generate a turnover value of $100.00.",
    "Thank you for your participation": "Thank you for your participation",
    "No owner": "No owner",
    "Ownership of this bundle is limited to one per user. Please purchase for yourself and for those who do not have it.": "Ownership of this bundle is limited to one per user. Please purchase for yourself and for those who do not have it.",
    "Buy parcels with minting power and generate income.": "Buy parcels with minting power and generate income.",

    // Mystery box
    "Limited time offer": "Limited time offer",
    "See Options": "See Options",
    "Please select one of the currencies": "Please select one of the currencies",
    "Current balance": "Current balance",
    "Try your luck": "Try your luck",
    "Offer ends in": "Offer ends in",
    "Parcel and Dual Miner": "Parcel and Dual Miner",
    "Mystery Box": "Mystery Box",
    "Try your luck and win incredible Bundles, Parcels and Dual Miners to boost your earnings!": "Try your luck and win incredible Bundles, Parcels and Dual Miners to boost your earnings!",
    "from MIN to MAX": "from <b>{min}</b> to <b>{max}</b>",
    "Choose a Box and Discover Rewards": "Choose a Box and Discover Rewards",
    "Prizes you can win": "Prizes you can win",
    "Item": "Item",
    "Chance": "Chance",
    "Promos": "Promos",
    "Mystery Box results": "Mystery Box results",
    "Total reward value": "Total reward value",
    "My Rewards": "My Rewards",
    "Rewards Community Claimed": "Rewards Community Claimed",
    "Hero image": "Hero image",
    "No rewards yet": "No rewards yet",
    "No winners yet": "No winners yet",
    "While no lucky participants have claimed their prizes yet, the excitement continues to build.": "While no lucky participants have claimed their prizes yet, the excitement continues to build.",
    "Mystery Box is Here!": "Mystery Box is Here!",
    "Be the First to Discover Mystery Rewards": "Be the First to Discover Mystery Rewards",
    "Select Balance": "Select Balance",
    "Current Balance": "Current Balance",
    "Opening Mystery Box": "Opening Mystery Box",
    "Oops, not this time 😔": "Oops, not this time 😔",
    "Luck is on your side. Enjoy your prize!": "Luck is on your side. Enjoy your prize!",
    "The more you try, the better your chances of winning. Keep going!": "The more you try, the better your chances of winning. Keep going!",
    "Try another box": "Try another box",
    "Go to My Products": "Go to My Products",
    "See promo results": "See promo results",
    "Payment enabled": "Payment enabled",
    "Please select the wallet that is attached to your account.": "Please select the wallet that is attached to your account.",
    "Explore rewards": "Explore rewards",
    "View rewards claimed by you and the community": "View rewards claimed by you and the community",
    "Open a Mystery Box to get rewards starting from Parcels to Super bundles.": "Open a Mystery Box to get rewards starting from Parcels to Super bundles.",
    "Insufficient funds, please choose another box.": "Insufficient funds, please choose another box.",
    "Blockchain error": "Blockchain error",
    "Rewards claimed": "Rewards claimed",
    "We have encountered difficulties while processing the result of the reward. Please contact our support team for prompt assistance.": "We have encountered difficulties while processing the result of the reward. Please contact our support team for prompt assistance.",
    "Boxes opened": "Boxes opened",
    // Mystery box

    // Partnership details
    "About": "About",
    "Offer": "Offer",
    "Social Media": "Social Media",
    "Additional Content": "Additional Content",
    "Photo": "Photo",
    "Video": "Video",
    // Partnership details

    // Miner Rewards
    "Single Miners": "Single Miners",
    "price today": "price today",
    "Estimated daily rewards": "Estimated daily rewards",
    "day": "day",
    "day at current rate": "day at current rate",
    "Estimated rewards until 2025": "Estimated rewards until 2025",
    "at current rate": "at current rate",
    "Estimated rewards from today to December 1, 2025. It may change based on your shares in the pool.": "Estimated rewards from today to December 1, 2025. It may change based on your shares in the pool.",
    "Expected rewards calculator": "Expected rewards calculator",
    "price": "price",
    "My total GYMNET value in USD": "My total GYMNET value in USD",
    "claimed": "claimed",
    "My Balances": "My Balances",
    "Tokens to be burned": "Tokens to be burned",
    "You dont have any Claimed or Pending GYMNET rewards.": "You don’t have any Claimed or Pending GYMNET rewards.",
    "Claim now": "Claim now",
    "No claimed GYMNET rewards are found.": "No claimed GYMNET rewards are found.",
    // Miner Rewards

    "Please note that the leg with the lesser amount of points is called weak leg and the one with the higher amount of points is called strong or power leg, and it can change over time based on which leg the most turnover is generated.": "Please note that the leg with the lesser amount of points is called \"weak leg\" and the one with the higher amount of points is called \"strong\" or \"power leg\", and it can change over time based on which leg the most turnover is generated.",
    "Mystery Box price": "Mystery Box price",

    "Registration Date": "Registration Date",
    "First Purchase Date": "First Purchase Date",
    "Product Autoship Valid Until": "Product Autoship Valid Until",

    // Feature Prioritization
    "Participation to GYMSTREETs Elite Vote": "Participation to GYMSTREET’s Elite Vote",
    "Prioritization ends in": "Prioritization ends in",
    "Start Prioritizing": "Start Prioritizing",
    "Your changes have been saved": "Your changes have been saved",
    "features are still remaining": "<b>{n} features</b> are still remaining",
    "feature is still remaining": "<b>{n} feature</b> is still remaining",
    "All prioritized": "All prioritized",
    "GYMSTREETs Elite Vote Statistics": "GYMSTREET’s Elite Vote Statistics",
    "Just NUM features to go! Complete your prioritization to help us shape community's vision.": "Just <b>{countText}</b> to go! Complete your prioritization to help us shape community's vision.",
    "You've prioritized the features. Please review your choices and hit Submit to finalize.": "You've prioritized the features. Please review your choices and hit <b>“Submit”</b> to finalize.",
    "Back to All Features": "Back to All Features",
    "Changes saved": "Changes saved",
    "No changes": "No changes",
    "Submit": "Submit",
    "priority": "priority",
    "Set priority": "Set priority",
    "Feature details": "Feature details",
    "Select a feature to see more info": "Select a feature to see more info",
    "Prioritization Submitted": "Prioritization Submitted",
    "The results will be available after the voting period ends. You'll be able to change your priorities until then.": "The results will be available after the voting period ends. You'll be able to change your priorities until then.",
    "Go to Career Status page": "Go to Career Status page",
    "Keep Prioritizing": "Keep Prioritizing",
    "Continue Prioritizing": "Continue Prioritizing",
    "Review and Submit": "Review and Submit",
    "Thinking of reprioritizing?": "Thinking of reprioritizing?",
    "You've already submitted your priorities. If you want to reprioritize them, please make sure you hit Resubmit.": "You’ve already submitted your priorities. If you want to reprioritize them, please make sure you hit <b>“Resubmit”</b>.",
    "Got it": "Got it",
    "Resubmit": "Resubmit",
    "Priorities submitted": "Priorities submitted",
    "Submitted": "Submitted",
    "View Statistics": "View Statistics",
    "Continue Prioritization": "Continue Prioritization",
    "Reprioritize": "Reprioritize",
    "Start Prioritization": "Start Prioritization",
    "features": "features",
    "feature": "feature",
    "GYMSTREET Elite Vote": "GYMSTREET's Elite Vote",
    "Thanks for submitting! You can still reprioritize and resubmit your choices until the voting period ends.": "Thanks for submitting! You can still reprioritize and resubmit your choices until the voting period ends.",
    "Youre not done yet. Continue prioritizing the upcoming features and submit your final choices.": "You’re not done yet. Continue prioritizing the upcoming features and submit your final choices.",
    "Exclusive for Platin and higher rank members. Prioritize the features you want to see next on GYMSTREET.": "Exclusive for Platinum and higher rank members. Prioritize the features you want to see next on GYMSTREET.",
    "View rules": "View rules",
    "Prioritization has ended. See the community's vote results.": "Prioritization has ended. See the community's vote results.",
    "Stay tuned": "Stay tuned",
    "See current community prioritization statistics below. In 7 days, we'll also announce our decision right here, revealing the selected new features and their estimated release dates.": "See current community prioritization statistics below. In 7 days, we'll also announce our decision right here, revealing the selected new features and their estimated release dates.",
    "Terms and Conditions for GYMSTREETs Elite Vote": "Terms and Conditions for GYMSTREET's Elite Vote",
    // Suspicous activity
    "You have one attempt left": "You have one attempt left. Afterwards, your tries for this account will be temporarily restricted.",
    "You have no attempts left": "You have no attempts left for this account. Please try again in {minutesText} <b>minutes</b> or reset your password.",
    "Sorry, you’ve been blocked": "Sorry, you’ve been blocked",
    "Your IP address has been temporarily blocked for security reasons.": "Your IP address has been temporarily blocked for security reasons. Please wait, you will be automatically redirected soon.",
    "If you believe this was a mistake, please contact us through our Customer Support.": "If you believe this was a mistake, please contact us through our <a href=\"mailto:support@gymstreet.io?subject=Suspicious Activity&body=I am blocked due to suspicious activity, but I think It's a mistake\" class=\"customer-support-button\">Customer Support.</a>",
    "Features expected timeframes revealed": "Features expected timeframes revealed",
    "Find estimated timelines for features prioritized by our community and aligned with our strategy.": "Find estimated timelines for features prioritized by our community and aligned with our strategy.",
    "Estimated timeframe": "Estimated timeframe",
    "Undefined": "Undefined",
    // Instant commissions
    "The period for earning mining rewards in BITOPEX tokens has officially concluded. Thanks for your participation. Enjoy your BPX earnings.": "The period for earning mining rewards in BITOPEX tokens has officially concluded. Thanks for your participation. Enjoy your BPX earnings.",
    "No Longer Valid": "No Longer Valid",

    // Turnover Incentive
    "Turnover Incentive": "Turnover Incentive",
    "Available For Limited time only": "Available For <b>Limited time</b> only",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving $20,000 in direct sales.": "Please note that you have the opportunity to earn a complimentary R8 Bundle if you achieve $20,000 in direct sales.",
    "Deposit to Single pool": "Deposit to Single pool",
    "Max": "Max",
    "Received": "Received",
    "About voting rights": "About voting rights",
    "Your first deposit must be over $25": "Your first deposit must be over $25",
    "Staking period ends": "Staking period ends",
    "December": "December",
    "Deposit & Stake": "Deposit & Stake",

    // cashFT
    "Purchasing the card requires passing the KYC procedure.": "Purchasing the card requires passing the KYC procedure.",
    "Continue to KYC": "Continue to KYC",
    "KYC Verification": "KYC Verification",
    "Step": "Step {n}",
    "Upload ID": "Upload ID",
    "Male": "Male",
    "Female": "Female",
    "Gender": "Gender",
    "ID Type is required": "ID Type is required",
    "ID Document Language is required": "ID Document Language is required",
    "ID Expiration Date is required": "ID Expiration Date is required",
    "ID Issue Date is required": "ID Issue Date is required",
    "State must be at least 3 characters": "State must be at least 3 characters",
    "ID Type": "ID Type",
    "National Identity Card": "National Identity Card",
    "Driver's License": "Driver\'s License",
    "Passport": "Passport",
    "ID Document Language": "ID Document Language",
    "ID Number be at least 3 characters": "ID Number be at least 3 characters",
    "ID Issue Date": "ID Issue Date",
    "ID Expiration Date": "ID Expiration Date",
    "You need to upload a photo of your document Please upload a png or jpeg, not a pdf.": "You need to upload a photo of your document \n Please upload a <span style='color: #1A6C99'>png or jpeg, not a pdf</span>.",
    "File size exceeds {n} MB.": "File size exceeds {n} MB.",
    "Front side of ID": "Front side of ID",
    "Back side of ID": "Back side of ID",
    "Upload proof of address document": "Upload proof of address document",
    "Upload proof of address, which must not be older than 75 days.": "Upload proof of address, which must not be older than 75 days.",
    "Acceptable forms of proof of address include a credit card statement, utility bill, bank statement or bank letter.": "Acceptable forms of proof of address include a credit card statement, utility bill, bank statement or bank letter.",
    "Address Document Type is required": "Address Document Type is required",
    "Document Issued by is required": "Document Issued by is required",
    "Document Issued Date is required": "Document Issued Date is required",
    "Address Document Type": "Address Document Type",
    "Credit Card Statement": "Credit Card Statement",
    "Utility Bill": "Utility Bill",
    "Bank Statement": "Bank Statement",
    "Bank Letter": "Bank Letter",
    "Document Issued by": "Document Issued by",
    "Document Issued Date": "Document Issued Date",
    "Please upload a legible document in png or jpeg format.": "Please upload a legible document <span style='color: #1A6C99'>in png or jpeg format.</span>",
    "Submit for Verification": "Submit for Verification",
    "Your documents have been submitted for verification. Once verification has been approved, you can continue your purchase.": "Your documents have been submitted for verification. Once verification has been approved, you can continue your purchase.",
    "Single Pool": "Single Pool",
    "You need more GYMNET to make a deposit in Single Pool": "You need <b>{n} more GYMNET</b> to make a deposit in Single Pool",
    "You need more USDT to purchase a bundle": "You need <b>{n} more USDT</b> to purchase a bundle",
    "We're enhancing our claim feature, so it will be offline, for the next {hours} hours. Thank you and stay tuned!": "We're enhancing our claim feature, so it will be offline, for the next {hours} hours. Thank you and stay tuned!",
    "Eligibility for a one-time 10% commission, based on the total turnover generated from direct sales, requires referring team members and achieving a turnover of $5,000 or more in direct sales.": "Eligibility for a one-time 10% commission, based on the total turnover generated from direct sales, requires referring team members and achieving a turnover of $5,000 or more in direct sales.",

    "Security Assurance": "Security\nAssurance",
    "Global Accessibility": "Global\nAccessibility",
    "Affiliate Earnings": "Affiliate\nEarnings",
    "Effortless Integration": "Effortless\nIntegration",
    "Cards": "Cards",
    "Overview": "Overview",
    "Information": "Information",
    "Invoices": "Invoices",
    "KYC Status": "KYC Status",
    "Try again": "Try again",
    "Apply here": "Apply here",
    "Not verified": "Not verified",
    "Pending verification": "Pending verification",
    "Rejected": "Rejected",
    "Verified": "Verified",
    "Next service fee in": "Next service fee in",
    "No active membership": "No active membership",
    "Card": "Card",
    "Card number": "Card number",
    "Explorer": "Explorer",
    "Mastery": "Mastery",
    "PIN Set Successfully": "PIN Set Successfully",
    "Go to Dashboard": "Go to Dashboard",
    "4-digit PIN": "4-digit PIN",
    "Re-enter 4-digit PIN": "Re-enter 4-digit PIN",
    "Set PIN": "Set PIN",
    "Your account is now verified and you can continue your purchase of the bundle.": "Your account is now verified and you can continue your purchase of the bundle.",
    "Continue purchase": "Continue purchase",
    "Your documents have been rejected from KYC provider.": "Your documents have been rejected from KYC provider.",
    "Please try again": "Please try again",
    "Your KYC is approved": "Your KYC is approved",
    "Your KYC is rejected": "Your KYC is rejected",
    "Minting Rewards": "Minting Rewards",
    "Please check transactions if your {asset} balance is not visible": "Please check transactions if your {asset} balance is not visible",
    "In order to send {asset} you need to select {chain} network and wallet that is connected to your account.": "In order to send {asset} you need to select {chain} network and wallet that is connected to your account.",
    "File is required": "File is required",
    "Cash bonus eligibility is based on rank achieved through qualifications. Participants qualifying with over 50% before March 4th, 2024, will not receive the bonus.": "Cash bonus eligibility is based on rank achieved through qualifications. Participants qualifying with over 50% before March 4th, 2024, will not receive the bonus.",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving 100k in team purchases.": "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving 100k in team purchases.",

    "Convert": "Convert",
    "Convert your unused miners into hashpower to earn rewards in Gymnet and Filecoin": "Convert your unused miners into hashpower to earn rewards in Gymnet and Filecoin",
    "The 50% Turnover Rule is applied to this incentive": "The 50% Turnover Rule is applied to this incentive",

    "Super Bundle Rank Benefits": "Super Bundle Rank Benefits",
    "Matching bonus up to NUM levels": "Matching bonus up to {num} levels",
    "Up to NUM levels": "Up to {num} levels",
    "Left leg partner": "Left leg partner",
    "Right leg partner": "Right leg partner",
    "Commission Activation Requirements": "Commission Activation Requirements",
    "Up to 1 level": "Up to 1 level",
    "Bonus is paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET": "Bonus is paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET",
    "You will receive the bonus once the incentive period is over and it will be deposited directly into your wallet.": "You will receive the bonus once the incentive period is over and it will be deposited directly into your wallet.",
    "Current Turnover": "Current Turnover",
    "Purchase Amount": "Purchase Amount",
    "Spring": "Spring",
    "Spring Incentive": "Spring Incentive",
    "Commission Earned": "Commission Earned: <b>${n}</b>",
    "Bonus Earned": "Bonus Earned: <b>${n}</b>",
    "Earn 1 PAP point for every dollar spent on purchases using a utility balance or when you purchase a product autoship.": "Earn 1 PAP point for every dollar spent on purchases using a utility balance or when you purchase a product autoship.",
    "Seamless Spending with GYMSTREET Cards": "Seamless Spending with GYMSTREET Cards",
    "Our cards open a world of smooth transactions and rewarding opportunities for you.": "Our cards open a world of smooth transactions and rewarding opportunities for you.",
    "Card Types Tailored to Your Needs": "Card Types Tailored to Your Needs",
    "Card design": "Card design",
    "Card information": "Card information",
    "Your go-to choice for daily transactions and affiliate commissions, offering practical benefits and reliable service within GYMSTREET and beyond.": "Your go-to choice for daily transactions and affiliate commissions, offering practical benefits and reliable service within GYMSTREET and beyond.",
    "Elevate your financial experience with this premium option with expanded benefits, designed for those who seek luxury and exclusivity.": "Elevate your financial experience with this premium option with expanded benefits, designed for those who seek luxury and exclusivity.",
    "Cards come in Bundles": "Cards come in Bundles",
    "Explorer card and 2 parcels": "Explorer card and 2 parcels",
    "Mastery card and 3 parcels": "Mastery card and 3 parcels",
    "Getting Started with Your GYMSTREET Card Experience": "Getting Started with Your GYMSTREET Card Experience",
    "Start securely with our simple KYC process, ensuring a safe and personalized Card experience.": "Start securely with our simple KYC process, ensuring a safe and personalized Card experience.",
    "Deposit Tokens": "Deposit Tokens",
    "Stake the required GYMNET tokens - 500 for Explorer or 1500 for Mastery - to initiate your application.": "Stake the required GYMNET tokens - 500 for Explorer or 1500 for Mastery - to initiate your application.",
    "Order Your Card": "Order Your Card",
    "Make your final decision on card type and place your order buying one of the 2 card bundles.": "Make your final decision on card type and place your order buying one of the 2 card bundles.",
    "Get Your Card": "Get Your Card",
    "Anticipate the arrival of your new financial companion, securely shipped and ready to integrate into your life.": "Anticipate the arrival of your new financial companion, securely shipped and ready to integrate into your life.",
    "Use Your Card": "Use Your Card",
    "Begin making purchases with your card and start earning affiliate commissions and more.": "Begin making purchases with your card and start earning affiliate commissions and more.",
    "Card Operation Fees": "Card Operation Fees",
    "Fee Type": "Fee Type",
    "Both Cards (Explorer & Mastery)": "Both Cards (Explorer & Mastery)",
    "POS Transactions": "POS Transactions",
    "ATM Withdrawals": "ATM Withdrawals",
    "Foreign Currency Conversion": "Foreign Currency Conversion",
    "Card Replacement": "Card Replacement",
    "Simple, Transparent Pricing": "Simple, Transparent Pricing",
    "Monthly Membership Fee": "Monthly Membership Fee",
    "Monthly Membership": "Monthly Membership",
    "Get Your Card Now": "Get Your Card Now",
    "Choose the card that best suits your lifestyle. With straightforward fees, a seamless onboarding process, and an 11-level deep affiliate commission reward system, these cards are your partner in financial empowerment.": "Choose the card that best suits your lifestyle. With straightforward fees, a seamless onboarding process, and an 11-level deep affiliate commission reward system, these cards are your partner in financial empowerment.",
    "How do I get a card?": "How do I get a card?",
    "Start by filling out the KYC form on our site. After we verify you, you'll need to deposit tokens for the card you want in Single Pool: 500 for Explorer or 1500 for Mastery. Then, you can order your card.": "Start by filling out the KYC form on our site. After we verify you, you'll need to deposit tokens for the card you want in Single Pool: 500 for Explorer or 1500 for Mastery. Then, you can order your card.",
    "How many tokens do I need to deposit for each card?": "How many tokens do I need to deposit for each card?",
    "You need 500 tokens for the Explorer card and 1500 tokens for the Mastery card.": "You need 500 tokens for the Explorer card and 1500 tokens for the Mastery card.",
    "When will my card arrive?": "When will my card arrive?",
    "After you order, your card will be sent out and should arrive quickly, so you can start using it soon.": "After you order, your card will be sent out and should arrive quickly, so you can start using it soon.",
    "Can I earn affiliate commissions with my card?": "Can I earn affiliate commissions with my card?",
    "Yes, activate your card, and you can begin earning through our 11-level deep affiliate program. Check our website for how the commissions work.": "Yes, activate your card, and you can begin earning through our 11-level deep affiliate program. Check our website for how the commissions work.",

    // Single pool translation
    "Total Value At Time of Deposit": "Total Value At Time of Deposit",
    "Current Value": "Current Value",
    "My claimed GYMNET rewards": "My claimed GYMNET rewards",
    "My pending GYMNET rewards": "My pending GYMNET rewards",
    "Deposit": "Deposit",
    "Connected": "Connected",
    "No deposit fee for burn": "No deposit fee for burn",
    "Min amount": "Min amount",
    "Total GYMNET locked": "Total GYMNET locked",
    "APR": "APR",
    "Daily APR": "Daily APR",
    "Total own claimed": "Total own claimed",
    "Reward per block": "Reward per block",
    "My Deposit": "My Deposit",
    "Locked period until Dec 2027": "Locked period until Dec 2027",
    "With Locking Period": "With Locking Period",
    "Total Gymnet Deposit": "Total Gymnet Deposit",
    "Gymnet Rewards": "Gymnet Rewards",
    "Total Gymnet earned": "Total Gymnet earned",
    "Active holdings": "Active holdings",
    "With locking period": "With locking period",
    "Withdrawn holdings": "Withdrawn holdings",
    "Locked": "Locked",
    "Unlocked": "Unlocked",
    "Deposit amount": "Deposit amount",
    "Claimed rewards": "Claimed rewards",
    "Start date": "Start date",
    "End date": "End date",
    "Withdraw": "Withdraw",

    // KYC steps
    "First name": "First name",
    "Last name": "Last name",
    "Address": "Address",
    "State": "State",
    "Phone number": "Phone number",
    "Birth Date": "Birth Date",
    "Submit & Continue": "Submit & Continue",
    "ID Number": "ID Number",
    "ID Issued By": "ID Issued By",
    "Proof of address document": "Proof of address document",
    "Proof of address document (optional)": "Proof of address document (optional)",
    "Statistics": "Statistics",
    "Personal Information": "Personal Information",
    "First name is required": "First name is required",
    "Last name is required": "Last name is required",
    "Address is required": "Address is required",
    "Please enter your email address": "Please enter your email address",
    "Phone number is required": "Phone number is required",
    "Date of birth is required": "Date of birth is required",
    "ID Number is required": "ID Number is required",
    "ID Issued by is required": "ID Issued by is required",

    "Select asset to auto swap and stake": "Select asset to auto swap and stake",
    "Giving swap allowance": "Giving swap allowance",
    "Swap allowance given": "Swap allowance given",
    "Swapping tokens to GYMNET": "Swapping tokens to GYMNET",
    "Tokens swapped to GYMNET": "Tokens swapped to GYMNET",
    "Giving stake allowance": "Giving stake allowance",
    "Stake allowance given": "Stake allowance given",
    "Staking to single pool": "Staking to single pool",
    "Staked to single pool": "Staked to single pool",
    "1. Swap allowance (Only for USDT)": "1. Swap allowance (Only for USDT)",
    "2. Swap to GYMNET": "2. Swap to GYMNET",
    "3. Stake allowance": "3. Stake allowance",
    "4. Stake": "4. Stake",
    "Actual balance:": "Actual balance:",
    "The transaction gas fee is reserved from the actual BNB balance.": "The transaction gas fee is reserved from the actual BNB balance.",
    "Insufficient balance to make a swap": "Insufficient balance to make a swap",
    "Transactions gas fee is considered in BNB available balance": "Transactions gas fee is considered in BNB available balance",
    "We will ask for allowance and try to swap your tokens to GYMNET, and after, stake them in single pool.": "We will ask for allowance and try to swap your tokens to GYMNET, and after, stake them in single pool.",
    "Auto swap and stake": "Auto swap and stake",
    "Continue transactions": "Continue transactions",
    "Pause": "Pause",
    "Swap & Stake": "Swap & Stake",
    "Topup": "Topup",
    "Proceed anyways": "Proceed anyways",
    "Explorer Card": "Explorer Card",
    "Mastery Card": "Mastery Card",
    "Monthly spending max": "Monthly spending max",
    "Monthly Balance limit": "Monthly Balance limit",
    "Go to Cards": "Go to Cards",
    "The minimum amount should be equal to or greater than NUM GYMNET": "The minimum amount should be equal to or greater than {num} GYMNET",
    "Contact Support": "Contact Support",
    "Deposit Fee": "Deposit Fee",
    "Switch to 'Utility' to view the price for each product with USDT & Utility. To partially pay for a product using Utility, you need to have enough USDT and Utility in your balance.": "Switch to 'Utility' to view the price for each product with USDT & Utility. To partially pay for a product using Utility, you need to have enough USDT and utility in your balance.",
    "Deposit with locking period": "Deposit with locking period",
    "The state must be in the form of a two-letter abbreviation in uppercase.": "The state must be in the form of a two-letter abbreviation in uppercase.",
    "You need to upload a photo in JPEG or PNG format, not a PDF. The document can be in either English or Spanish.": "You need to upload a photo in <span style='color: #1A6C99'>jpeg</span> or <span style='color: #1A6C99'>png</span> format, not a pdf. \nThe document can be in either <span style='color: #1A6C99'>English</span> or <span style='color: #1A6C99'>Spanish</span>.",
    "You need to upload a legible document in jpeg or png format, not a pdf. The document can be in either English or Spanish.": "You need to upload a legible document in <span style='color: #1A6C99'>jpeg</span> or <span style='color: #1A6C99'>png</span> format, not a pdf. \nThe document can be in either <span style='color: #1A6C99'>English</span> or <span style='color: #1A6C99'>Spanish</span>.",
    "Commissions are paid out using a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "Commissions are paid out using a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.",
    "This policy is effective as of September 25, 2023.": "This policy is effective as of September 25, 2023.",
    "Required turnover": "Required turnover",
    "Switch to TOKEN to view the price for each product with USDT & TOKEN. To partially pay for a product using TOKEN, you need to have enough USDT and TOKEN in your balance.": "Switch to '{TOKEN}' to view the price for each product with USDT & {TOKEN}. To partially pay for a product using {TOKEN}, you need to have enough USDT and {TOKEN} in your balance.",
    "Alternatively, you can use your CURRENCY balance to cover the gas fee.": "Alternatively, you can use your {currency_yg} balance to cover the gas fee.",
    "Insufficient funds: to continue with payment use Utility or Gymnet balance": "Insufficient funds: to continue with payment use Utility or Gymnet balance",
    "Use": "Use",
    "Use Utility or Gymnet": "Use Utility or Gymnet",
    "Achieved PRICE turnover": "Achieved ${price} turnover",
    "Your current milestone": "Your current milestone",
    "Reward History": "Reward History",
    "Leading Direct Partner": "Leading Direct Partner",
    "Remaining Direct Partners": "Remaining Direct Partners",
    "Bonus Split": "Bonus Split",
    "Monthly Countdown": "Monthly Countdown",
    "No direct partners": "No direct partners",
    "To receive the reward, you must have an active product autoship.": "To receive the reward, you must have an active product autoship.",
    "This incentive is subject to the 50% Turnover Rule.": "This incentive is subject to the 50% Turnover Rule.",
    "Income From Product Authoship": "Income From Product Authoship",
    "Your documents have been submitted for verification. The bank may take up to 3 business days to review your application. Once approved, you can continue with your purchase.": "Your documents have been submitted for verification. The bank may take up to 3 business days to review your application. Once approved, you can continue with your purchase.",
    "To purchase the card, you need to agree to the T&C and complete the KYC verification process.": "To purchase the card, you need to agree to the T&C and complete the KYC verification process.",
    "Only Latin letters are allowed": "Only Latin letters are allowed",
    "First name must be at least 3 characters": "First name must be at least 3 characters",
    "First name should not exceed 10 characters": "First name should not exceed 10 characters",
    "Should be only letters": "Should be only letters",
    "Address must be at least 3 characters": "Address must be at least 3 characters",
    "Only latin letters, numbers and special characters are allowed": "Only latin letters, numbers and special characters are allowed",
    "Email is not valid": "Email is not valid",
    "Zip must contain only numbers with min length of 3": "Zip must contain only numbers with min length of 3",
    "The phone number must be at least 6 digits": "The phone number must be at least 6 digits",
    "The phone number must not exceed 12 digits": "The phone number must not exceed 12 digits",
    "Phone number should contain only numbers": "Phone number should contain only numbers",
    "ID Number must have at least 3 characters": "ID Number must have at least 3 characters",
    "ID Issued by must have at least 3 characters": "ID Issued by must have at least 3 characters",
    "ID Issued by should not exceed 191 characters": "ID Issued by should not exceed 191 characters",
    "Last name must be at least 3 characters": "Last name must be at least 3 characters",
    "State is required": "State is required",
    "The incentive countdown starts at the beginning of each month and ends at the end of the month. If you do not qualify for the bonus within that month, you will need to start again in the next month.": "The incentive countdown starts at the beginning of each month and ends at the end of the month. If you do not qualify for the bonus within that month, you will need to start again in the next month.",
    "Activated": "Activated",
    "Deposit & fee commission": "Deposit & fee commission",
    "Web3 wallet not connected. Please connect to proceed.": "Web3 wallet not connected. Please connect to proceed.",
    "Total investors profit": "Total investors profit",
    "Commissions from vault investors": "Commissions from vault investors",
    "Please Claim your rewards from to activate this section.": "Please Claim your rewards from {n} to activate this section.",
    "Or use Button Below": "Or use Button Below",
    "Partner Activity": "Partner Activity",
    "Pending Commissions": "Pending Commissions",
    "Binary Turnover": "Binary Turnover"    ,
    "Unilevel Turnover": "Unilevel Turnover",
    "Right Direct Partners": "Right Direct Partners",
    "Left Direct Partners": "Left Direct Partners",
    "50% Compensation:": "50% Compensation:",
    "When you reach the next rank, you will receive 50% of the lost bonus from the previous rank.": "When you reach the next rank, you will receive 50% of the lost bonus from the previous rank.",
    "Please note that username can be changed only once in 6 months.": "Please note that username can be changed only once in 6 months.",
    "Binary Turnover in weak leg": "Binary Turnover in weak leg",
    "Unilevel turnover requirement": "Unilevel turnover requirement",
    "Connect a WEB3 wallet such as Metamask or Trustwallet and pay with {currency}. This process requires a small BNB fee ($0.30) to handle transaction costs!": "Connect a WEB3 wallet such as Metamask or Trustwallet and pay with {currency}. This process requires a small BNB fee ($0.30) to handle transaction costs!",
    "Use your account's wallet balance to purchase the product.": "Use your account's wallet balance to purchase the product.",
    "Seems like your crypto hasn't arrived yet": "Seems like your crypto hasn't arrived yet",
    "Wait more": "Wait more",
    "Or topup with": "Or topup with",
    "Connected to web3": "Connected to web3",
    "Reset": "Reset",
    "Top Up Successful": "Top Up Successful",
    "Top Up Successful. Card balance will be updated soon": "Top Up Successful. Card balance will be updated soon",

    "Please activate your card and top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "Please activate your card and top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.",
    "PERCENTAGE allocation from Global Pool": "{perc}% allocation from Global Pool",
    "You need to reach rank RANK_NAME to participate in this pool.": "You need to reach rank \"{rank_name}\" to participate in this pool.",
    "Global Pool": "Global Pool",
    "Next Distribution In:": "Next Distribution In:",
    "50% Rule": "50% Rule",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%": "The maximum share each direct partner can contribute to the required turnover is up to 50%",
    "Sub Pools": "Sub Pools",
    "Congratulations! You have qualified for a share of the Global Pool.": "Congratulations! You have qualified for a share of the Global Pool.",
    "You will receive your share once the countdown is complete.": "You will receive your share once the countdown is complete.",
    "Please top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "Please top up the balance to cover the monthly fee. If the balance isn\'t updated, your card will be deactivated and you\'ll need to purchase a new one.",
    "You need to deposit VALUE worth of GYMNET to upgrade your weekly binary cap": "You need to deposit {dvalue} worth of GYMNET to upgrade your weekly binary cap",
    "Binary Max Weekly Payout": "Binary Max Weekly Payout",
    "Required Single Pool Deposit To Upgrade": "Required Single Pool Deposit To Upgrade",
    "Upgrade Binary Max Weekly Payout": "Upgrade Binary Max Weekly Payout",
    "Weekly Cap Upgraded": "Weekly Cap Upgraded",
    "Your 8 months period for earning token rewards in Filecoin has officially concluded. Thank you for your participation. Enjoy your FIL earnings.": "Your 8 months period for earning token rewards in Filecoin has officially concluded. Thank you for your participation. Enjoy your FIL earnings.",
    "As previously announced Filecoin will be replaced by Moonberg token, which will be distributed for a period of 24 months. The Moonberg launch is expected for August 2024 and the distribution will start one week after that.": "As previously announced Filecoin will be replaced by Moonberg token, which will be distributed for a period of 24 months. The Moonberg launch is expected for August 2024 and the distribution will start one week after that.",
    "Username must not exceed 255 characters": "Username must not exceed 255 characters",
    "Upgrade": "Upgrade",
    "Product Owner": "Product Owner",
    "Myself": "Myself",
    "Another user": "Another user",
    "Payment": "Payment",
    "PAP Balance": "PAP Balance",
    "Purchased By": "Purchased By",
    "Purchased For": "Purchased For",
    "Single parcel": "Single parcel",
    "Single miner": "Single miner",
    "completed": "Completed",
    "pending": "Pending",
    "expired": "Expired",

    "Select {n} blockchain to send the transaction": "Select {n} blockchain to send the transaction",
    "We recommend waiting to claim your rewards when the gas fee is lower than the amount you are claiming.": "We recommend waiting to claim your rewards when the gas fee is lower than the amount you are claiming.",
    "Total Value Of Purchases": "Total Value Of Purchases",
    "The total value of all purchases excluding purchases related to Card Bundles, granted products and product autoship subscriptions.": "The total value of all purchases excluding purchases related to Card Bundles, granted products and product autoship subscriptions.",
    "Total Receivable Tokens": "Total Receivable Tokens",
    "The number of Moonberg tokens you will receive over the next 24 months.": "The number of Moonberg tokens you will receive over the next 24 months.",
    "Realtime ROI": "Realtime ROI",
    "Current Return on Investment (ROI) based on the real-time price of the Moonberg token.": "Current Return on Investment (ROI) based on the real-time price of the Moonberg token.",
    "Token Calculation Breakdown": "Token Calculation Breakdown",
    "{n}% of your total purchase value is allocated for purchasing {asset} tokens": "{n}% of your total purchase value is allocated for purchasing {asset} tokens",
    "Moonberg tokens will be distributed upon the official release of the Moonberg token.": "Moonberg tokens will be distributed upon the official release of the Moonberg token.",
    "Moonberg Allocation": "Moonberg Allocation",
    "BTC Balance": "BTC Balance",
    "The funds will be transferred via the BEP-20 BNB network.": "The funds will be transferred via the BEP-20 BNB network.",
    "A bundle with minting power will generate BTC token rewards, which will be distributed every Monday.": "A bundle with minting power will generate BTC token rewards, which will be distributed every Monday.",
    "Weekly rewards expected": "Weekly rewards expected",
    "Direct Sales Incentive": "Direct Sales Incentive",
    "You are about to activate your affiliate referral link.": "You are about to activate your affiliate referral link.",
    "Upon activation, a period of free Product Autoship will be initiated starting from the date of your registration.": "Upon activation, a period of free Product Autoship will be initiated starting from the date of your registration.",
    "If you confirm the activation, a mandatory monthly Product Autoship will be required to be eligible to receive commissions.": "If you confirm the activation, a mandatory monthly Product Autoship will be required to be eligible to receive commissions.",
    "Keep your product autoship active to maintain your commission eligibility for ranks Platinum and above.": "Keep your product autoship active to maintain your commission eligibility for ranks Platinum and above.",
    "Buy Product Autoship": "Buy Product Autoship",
    "Your current rank is achieved through qualification.": "Your current rank is achieved through qualification.",
    "Your commission rank is determined by your actual rank or a super bundle purchase—whichever is higher will be considered.": "Your commission rank is determined by your actual rank or a super bundle purchase—whichever is higher will be considered.",
    "Your card has been deactivated due to an unpaid monthly subscription. For assistance or any questions, please reach out to our customer support team.": "Your {card_name} card has been deactivated due to an unpaid monthly subscription. For assistance or any questions, please reach out to our customer support team.",

    "Subject": "Subject",
    "topup": "Top Up",
    "withdraw": "Withdraw",
    "failed": "Failed",
    "Reach Total Unilevel Team Turnover of VALUE": "Reach Total Unilevel Team Turnover of ${teamTurnover}",
    "3rd Anniversary": "3rd Anniversary",
    "3rd Anniversary Promo": "3rd Anniversary Promo",
    "Your current bonus amount": "Your current bonus amount",
    "All products are eligible for the incentive, except for Product Autoship. Rewards will be distributed on November 1, 2024, and the bonus will be deposited directly into your internal wallet.": "All products are eligible for the incentive, except for Product Autoship. Rewards will be distributed on November 1, 2024, and the bonus will be deposited directly into your internal wallet.",
    


























    // Affility translations
    "close": "Close",
    "cancel": "Cancel",
    "yes_i_confirm": "Yes, I Confirm",
    "something_went_wrong_try_again": "Something went wrong. Please try again",
    "or": "Or",
    "success": "Success",
    "ok": "OK",
    "retry": "Retry",
    "home": "Home",
    "log_in": "Log in",
    "join_now": "Join Now",
    "toggle_notifications": "Toggle Notifications",
    "active": "Active",
    "inactive": "Inactive",
    "product_autoship": "Product Autoship",
    "balances": "Balances",
    "contract_addresses": "Contract Addresses",
    "address_add_to_wallet_success": "Address has been successfully added to your wallet",
    "language": "Language",
    "choose_language": "Choose Language",
    "profile": "Profile",
    "settings": "Settings",
    "learn": "Learn",
    "help_center": "Help Center",
    "log_out": "Log Out",
    "theme": "Theme",
    "back": "Back",
    "forward": "Forward",
    "previous": "Previous",
    "next": "Next",
    "jump_to_top": "Jump to Top",
    "load_more": "Load More",
    "proceed": "Proceed",
    "notifications": "Notifications",
    "notifications_loading_error_description": "Ensure your device is connected to the internet, or try restarting your router or modem.",
    "notifications_empty_title": "The notifications list is empty",
    "notifications_empty_description": "Keep track of all your updates and reminders in one convenient place with our notifications feature.",
    "email_verification_sent_title": "Email Verification",
    "email_verification_sent_description": "We sent an email to {email} with a link to finalize your registration",
    "email_verification_required": "Email verification is required",
    "email_verification_confirm": "Please confirm your email to continue",
    "email_verification_verify": "Verify your email to continue",
    "new_email": "New email",
    "enter_new_email": "Enter new email",
    "change_email": "Change email",
    "resend_email": "Resend email",
    "upgrade_to_affiliate": "Upgrade to Affiliate",
    "upgrade_to_affiliate_success": "You have successfully upgraded to Affiliate",
    "become_affiliate": "Become Affiliate",
    "upgrade_to_affiliate_description_free_autoship": "You are about to activate your affiliate referral link. Upon activation, a period of free Product Autoship will be initiated starting from the date of your registration.",
    "upgrade_to_affiliate_description_mandatory_autoship": "You are about to activate your affiliate referral link. If you confirm the activation, a mandatory monthly Product Autoship will be required to be eligible to receive commissions.",
    "t&c": "Terms & Conditions",
    "accept_t&c": "Accept and Close",
    "affiliate_t&c": "Affiliate Terms & Conditions",
    "cards_t&c": "Cards Terms & Conditions",
    "i_have_read_and_accept_the_{prop}": "I have read and accept the {prop}",
    "switch_language": "Switch Language",
    "switch_theme": "Switch Theme",
    "enjoy_more_benefit_more": "Enjoy more! Benefit more!",
    "buy_products_at_least_${n}": "Buy products for at least ${n}",
    "go_to_products": "Go To Products",
    "free_autoship": "Free Product Autoship for Platinum and Above",
    "{n}%_binary_commission": "{n}% Binary Commission",
    "rank": "Rank",
    "role": "Role",
    "bundle": "Bundle",
    "current_rank": "Current rank",
    "current_role": "Current role",
    "customer": "Customer",
    "get_your_bundle": "Get your bundle",
    "nft_turbo_expires_in": "Super Bundle Special Discount Expires In",
    "left_leg_link": "Left Leg Link",
    "right_leg_link": "Right Leg Link",
    "sponsor_id": "Sponsor ID",
    "total_income": "Total Income",
    "current_cv_balance": "Current CV Balance",
    "progress_until_rank_{n}": "Progress Until Rank {n}",
    "to_reach_rank_{n}_you_need": "To Reach Rank {n} You Need",
    "nft_own_purchases": "NFT Own Purchases",
    "direct_partner_own_purchases": "Direct Partner Own Purchases",
    "team_turnover": "Team Turnover",
    "required_qualified_partners": "Required Qualified Partners",
    "see_details": "See Details",
    "soon": "Soon",
    "new": "New",
    "promos": "Promos",
    "coming_soon": "Coming Soon",
    "team_statistics": "Team Statistics",
    "all_time": "All Time",
    "weekly": "Weekly",
    "monthly": "Monthly",
    "yearly": "Yearly",
    "left_leg": "Left Leg",
    "right_leg": "Right Leg",
    "all_partners": "All Partners",
    "direct_partners": "Direct Partners",
    "all_active_partners": "All Active Partners",
    "all_inactive_partners": "All Inactive Partners",
    "become_affiliate_partner": "Become Affiliate Partner",
    "gain_fresh_perspectives": "Gain Fresh Perspectives and Exclusive Benefits.",
    "autoship_required_to_be_affiliate": "Monthly Product Autoship is required to be an Affiliate Partner and earn commissions.",
    "for_limited_time_only": "For Limited Time Only",
    "placement_in_unilevel": "Placement in Unilevel",
    "placement_in_binary": "Placement in Binary",
    "cv_points": "CV Points",
    "matching_bonuses": "Matching bonuses",
    "leadership_incentives": "Leadership Incentives",
    "autoship_incentive": "Product Autoship Incentive",
    "binary_tree_statistics": "Binary Tree Statistics",
    "unilevel_tree_statistics": "Unilevel Tree Statistics",
    "active_partners": "Active Partners",
    "inactive_partners": "Inactive Partners",
    "select_the_team": "Select the team",
    "new_users_will_be_placed_under_username_in_the_left_team": "New users will be placed under {USERNAME} in the {LEG} team.",
    "done": "Done",
    "right": "right",
    "left": "left",
};
