var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseOverlaySimple',{staticClass:"sidebar-overlay z-3",attrs:{"enabled":_setup.isSidebarOpen},nativeOn:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _setup.closeSidebar.apply(null, arguments)}}},[_c('BaseSidebarSliding',{staticClass:"position-absolute d-flex flex-column",attrs:{"tag":"aside","open":_setup.isSidebarOpen}},[_c('section',{staticClass:"sidebar-content flex-fill flex-shrink-1 overflow-auto"},[_c('ComposedSelectMetaverse',{staticClass:"metaverse-switch d-block",attrs:{"width":null,"listenKeys":false}}),_vm._v(" "),_c('ul',[_vm._l((_setup.sidebarItems),function(item){return [(!item.invisible)?_c(item.component || 'ComposedMenuItemCollapsible',{tag:"component",attrs:{"to":item.to,"title":_vm.$t(item.title),"value":_setup.openedSubMenuObject === item,"active":_setup.activeItemPath.includes(item),"badgeProps":item.badgeProps ? {
                            size: item.badgeProps.size,
                            type: item.badgeProps.type,
                            text: item.badgeProps.text ? _vm.$t(item.badgeProps.text) : null,
                        } : null,"disabled":item.disabled || false,"prefixIcon":item.prefixIcon || null,"prefixIconActive":item.prefixIconActive || null,"suffixIcon":item.suffixIcon || null,"suffixIconActive":item.suffixIconActive || null},nativeOn:{"click":function($event){!item?.disabled && item?.children && _setup.openedSubMenuObject !== item ? _setup.openSubMenu(item) : _setup.closeSubMenu()}}}):_vm._e(),_vm._v(" "),(_setup.openedSubMenuObject === item && _setup.openedSubMenuObject?.children.length > 0)?_c('li',{staticClass:"sub-menu"},[_c('ul',[_vm._l((_setup.openedSubMenuObject?.children),function(item){return [(!item.invisible)?_c(item.component || 'ComposedMenuItemSubMenu',{tag:"component",attrs:{"to":item.to,"title":_vm.$t(item.title),"badgeProps":item.badgeProps ? {
                                        size: item.badgeProps.size,
                                        type: item.badgeProps.type,
                                        text: item.badgeProps.text ? _vm.$t(item.badgeProps.text) : null,
                                    } : null,"disabled":item.disabled || false,"active":_setup.activeItemPath.includes(item)}}):_vm._e()]})],2)]):_vm._e()]})],2)],1),_vm._v(" "),_c('BaseDividerSimple',{staticClass:"divider"}),_vm._v(" "),_c('section',{staticClass:"divided-section flex-fill flex-shrink-0 d-flex flex-column justify-content-end"},[(_setup.user)?_c('div',{staticClass:"auth-content"},[(_setup.user.walletAddress && _setup.user.isReferralLinkActive)?_c('ComposedCountdownAutoship',{attrs:{"user":_setup.user}}):_vm._e()],1):_c('div',{staticClass:"unauth-content flex-fill d-flex flex-column justify-content-between"},[_c('div',{staticClass:"additional-menu-items"},[_c('ComposedMenuItemContractAddressesOpener',{on:{"open":_setup.closeSidebarAndOpenContracts}}),_vm._v(" "),_c('ComposedMenuItemThemeSwitcher'),_vm._v(" "),_c('ComposedMenuItemLanguagesOpener',{on:{"open":_setup.closeSidebarAndOpenLanguages}})],1),_vm._v(" "),_c('ComposedActionAuthButtons',{staticClass:"auth-buttons",attrs:{"direction":"column","reverse":""}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }