import { render, staticRenderFns } from "./Collapsible.vue?vue&type=template&id=ca8becb6&scoped=true"
import script from "./Collapsible.vue?vue&type=script&setup=true&lang=ts"
export * from "./Collapsible.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Collapsible.vue?vue&type=style&index=0&id=ca8becb6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca8becb6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/dashboard/core/components/base/image/SvgThemedIcon.vue').default,BaseIconSwitch: require('/root/dashboard/core/components/base/icon/Switch.vue').default,BaseIndicatorBadge: require('/root/dashboard/core/components/base/indicator/Badge.vue').default,BaseMenuItemContent: require('/root/dashboard/core/components/base/menu-item/Content.vue').default,BaseLinkMenu: require('/root/dashboard/core/components/base/link/Menu.vue').default,BaseMenuItemContainer: require('/root/dashboard/core/components/base/menu-item/Container.vue').default})
