export default {
    "Learn how it works": "学习怎么操作",
    "Buy parcel": "购买包裹",
    "Wallets": "钱包",
    "To GymNetwork": "去 GymNetwork",
    "Connect Wallet": "链接钱包",
    "Pending rewards": "代办奖励",
    "Coming Soon": "即将来临",
    "Claim": "认领",
    "Inventory": "库存",
    "Lands": "土地",
    "Miners": "矿工",
    "Attach": "附带",
    "Attach to parcel": "附在包裹上",
    "Investment": "投资",
    "Wallet": "钱包",
    "Your address": "您的地址",
    "History": "历史",
    "Map": "地图",
    "Buy miner": "购买矿工",
    "Buy Miner": "购买",
    "Get ": "拥有 ",
    "Get Miner": "拥有矿工",
    "Upgrade parcel": "包裹升级",
    "Soon": "即将",
    "Upgraded":"升级",
    "Upgraded land has 10 slots for putting miner NFTs. Upgraded land does not have more physical space, it’s only that you can stake more miners.": "升级后的土地有10个槽位用于放置NFT矿工。升级后的土地没有更多的空间，只是你可以抵押更多的矿工。",
    "Next": "下一步",
    "Back": "倒回",
    "Skip guide": "跳过指南",
    "Number of miners you want to buy": "您要購買的雙礦機數量",
    "Attach Miner": "附带矿工",
    "Upgrade for": "升级给予",
    "On Sale": "特价",
    "Type": "输入",
    "Location": "地点",
    "Size": "尺寸",
    "Buy now": "即时购买",
    "Email": "邮箱",
    "Username": "用户名",
    "Verify Email": "验证邮箱",
    "Index": "指标",
    "Verification Code": "验证码",
    "Log out": "退出",
    "Select all": "全选",
    "Cart": "购物车",
    "Total": "总共",
    "Register": "注册",
    "Dashboard": "仪表板",
    "Transaction success!": "交易成功!",
    "I`ve read Terms & Conditions": "我已阅读条款和条件",
    "Terms & conditions": "条款和条件",
    "Affiliate Terms & Conditions": "附属条款和条件",
    "Accept Terms & Conditions": "接受条款和条件",
    "Loading! Please Wait ...": "加载中！请稍等。。。",
    "Initializing Wallet! Please Wait ...": "正在开启钱包！请稍候。。。",
    "Login In Progress! Please Wait ...": "登录中！请稍候。。。",
    "Enter your email": "输入您的邮箱地址",
    "Confirm your Email": "确认您的邮箱地址",
    "Enter username": "输入用户名",
    "To dashboard": "去仪表板",
    "You successfully verified your email": "您成功验证您的邮件",
    "View map": "查看地图",
    "Log in to buy": "登錄購買",
    "Congratulations": "恭喜",
    "Buy" : "购买",
    "Miner" : "矿工",
    "Parcel" : "包裹",
    "Upgrade": "升级",
    "Load more": "下载更多",
    "All": "全部",
    "Basic": "基本",
    "Activate": "激活",
    "Basic Miner": "基本矿工",
    "In use": "正在使用",
    "Expired": "无效",
    "Available": "可用",
    "Standard parcel": "标准包裹",
    "Success": "成功",
    "Something Went Wrong": "出错了",
    "Loading": "加载",
    "Invalid Verification Code": "验证号无效",
    "Invalid Email Address": "邮箱地址无效",
    "Invalid Arguments Supplied": "提供的参数无效",
    "Email Already Exists": "邮箱已经注册",
    "UnAuthorized": "未授权",
    "User Update Failed": "用户更新失败",
    "Permission Denied": "拒绝权限",
    "Basic miner": "基本矿工",
    "Remove": "删除",
    "Special offers": "特价",
    "Please Wait": "请稍等",
    "Transaction Rejected": "交易被拒绝",
    "sold": "已售出",
    "The parcel price will be increased after certain percentage of parcels will be sold. Bundle prices will be increased accordingly.": "售出一定比例的包裹后，包裹价格将上涨。捆绑价格也将相应上涨",
    "Sold": "已售出",
    "Parcel price index": "包裹价格指数",
    "Current parcel price": "当前包裹价格",
    "Parcels sold": "包裹已售出",
    "Parcel stats": "包裹统计",
    "Help center": "协助中心",
    "Partner area": "合伙区",
    "email_verification": '电子邮件验证码已发送至',
    "change_email": '更改邮箱',
    "you_successfully_verified_your_email": "您成功验证了您的电子邮件",
    "to_dashboard": "前往仪表板",
    "Transaction Success": "交易成功",
    "parcels available": "可用包裹",
    "Apply": "申请",
    "Ocean": "海洋",
    "Near Ocean": "靠近海洋",
    "Insufficient USDT amount": "USDT 数量不足",
    "Insufficient {asset} amount": "{asset} 数量不足",
    "Miner Rewards": "矿工奖励",
    "No data found": "未找到数据",
    "Full": "充分",
    "Standard": "标准",
    "Attach miners": "附加矿工",
    "Upgraded Parcel": "升级包裹",
    "Discount": "优惠",
    "save": "储存",
    "Copied": "复制",
    "Marketplace": "市场",
    "Contract Address": "合约地址",
    "Wallet Address": "钱包地址",
    "Inland": "内陆",
    "Business": "商业",
    "parcels": "包裹",
    "parcel": "包裹",
    "Parcel name": "包裹名称",
    "Basic Bundle": "基本包",
    "4 Parcels Bundle": "4 包裹捆绑",
    "10 Parcels Bundle": "10 包裹捆绑",
    "40 Parcels Bundle": "40 包裹捆绑",
    "80 Parcels Bundle": "80 包裹捆绑",
    "140 Parcels Bundle": "140 包裹捆绑",
    "240 Parcels Bundle": "240 包裹捆绑",
    "4 Basic Miner Bundle": "4 矿工包",
    "10 Basic Miner Bundle": "10 矿工包",
    "40 Basic Miner Bundle": "40 矿工包",
    "80 Basic Miner Bundle": "80 矿工包",
    "140 Basic Miner Bundle": "140 矿工包",
    "240 Basic Miner Bundle": "240 矿工包",
    "Hashpower": "Hashpower",
    "Something went wrong, please refresh the page and try again": "出了点问题，请刷新页面，然后重试",
    "Gallery": "画廊",
    "Accepted": "公认",
    "Pending": "待办的",
    "Upload": "上传",
    "Uploading image": "上传图片",
    "Something went wrong, try again later": "出了点问题，请稍后再试",
    "You need to be authenticated to upload images to Gallery.": "您需要经过身份验证才能将图像上传到画廊。",
    "Assign image": "分配图像",
    "Assign image to parcels": "将图像分配给宗地",
    "Remove current image": "刪除當前圖像",
    "Change image": "更改圖像",
    "Assign to parcels": "分配給包裹",
    "Reselect image assignment area?": "重新選擇圖像分配區域？",
    "You have not applied changes. Do you want to reselect image assignment area?": "您沒有應用更改。您要重新選擇圖像分配區域嗎？",
    "Image has been successfully changed": "圖像已成功更改",
    "Image has been successfully assigned to parcels": "圖像已成功分配給包裹",
    "Image has been successfully removed from the parcels": "圖像已成功從包裹中刪除",
    "Yes": "是的",
    "No": "不",
    "Cancel": "取消",
    "You have no images": "您沒有圖像",
    "Notifications": "Notifications",
    "No notifications": "No notifications",
    "year ago": "没有通知",
    "months ago": "几年前",
    "month ago": "一年前",
    "weeks ago": "几个月前",
    "week ago": "一个月前",
    "days ago": "几周前",
    "day ago": "一周前",
    "hours ago": "几天前",
    "hour ago": "前一天",
    "minutes ago": "几小时前",
    "minutes": "一个小时前",
    "minute": "几分钟前",
    "seconds": "分钟",
    "second": "分钟",
    "Your image was approved": "您的图片已获批准",
    "Your image was rejected": "您的图片被拒绝",
    "New": "新的",
    "Gallery guide" : "畫廊指南",
    "Go to gallery": "去畫廊",
    "How it works": "這個怎麼運作",
    "Removing image from parcels..." : "從包裹中刪除圖像...",
    "Removing image" : "刪除圖像",
    "Image has been successfully removed" : "圖像已成功刪除",
    "Wrong file size" : "文件大小錯誤",
    "Please upload image files with size less than 10MB": "請上傳尺寸小於10MB的圖像文件",
    "Drag to Reposition": "拖動重新定位",
    "Pinch or use the mouse wheel to zoom in or out": "捏或使用鼠標滾輪放大或縮放",
    "Drag and move the image with fingers or using your mouse to select area": "用手指拖動並移動圖像或使用鼠標選擇區域",
    "It can take up to 48 hours for the image to be approved. You can view your images awaiting approval in the Pending tab.": "批准圖像最多可能需要48小時。您可以在“待處理”選項卡中查看等待批准的圖像。",
    "Delete Image": "刪除圖像",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable. Land containing this image will also be cleared.": "您確定要永久從畫廊中刪除圖像嗎？此操作是不可回收的。包含此圖像的土地也將被清除。",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable." : "您確定要永久從畫廊中刪除圖像嗎？此操作是不可回收的。",
    "Wrong File Type": "錯誤的文件類型",
    "Please upload jpg/jpeg image files": "請上傳JPG/JPEG圖像文件",
    "Upload Images to Your Gallery": "將圖像上傳到畫廊",
    "You can upload images to your gallery from your computer, mobile phone or tablet. Currently only jpg/jpeg image file types are supported. Press the upload button or simply drag and drop the image from computer to upload your image. Image size must be up to 10 MB." : "您可以從計算機，手機或平板電腦上將圖像上傳到畫廊。當前僅支持JPG/JPEG圖像文件類型。按“上傳”按鈕或簡單地將圖像從計算機拖放以上傳圖像。圖像大小必須高達10 MB。",
    "Upload image": "上傳圖片",
    "Image Approval": "圖像批准",
    "Uploaded images need to be approved before they appear in the approved section of your gallery. This procedure is necessary to filter out inappropriate, explicit content and copyrighted material. Approval can take up to 48 hours. You'll get notified about the verdict whenever you're online." : "在您的畫廊的批准部分出現在批准的部分之前，需要批准上傳的圖像。此過程是必要的，以濾除不適當，明確的內容和受版權保護的材料。批准最多可能需要48小時。每當您在線時，您會收到有關判決的通知。",
    "Approval": "贊同",
    "Select Your Land to Assign Image to": "選擇您的土地將圖像分配給",
    "You can assign your uploaded images to your owned land. To do so, simply select the parcels by dragging mouse on the land part you want to assign an image to. Please note that image can only be assigned to a rectangular area that is owned by you only." : "您可以將上傳的圖像分配給擁有的土地。為此，只需通過將鼠標拖到要分配圖像的土地部分上的鼠標來選擇包裹即可。請注意，只能將圖像分配給您僅由您擁有的矩形區域。",
    "Land Selection": "土地選擇",
    "Crop Image": "作物圖像",
    "You can crop a custom part of your image that you want to assign to your land section. You can close in to any area of your image and crop it by dragging the image with mouse or your finger if you're on a mobile device. You can also zoom in and out by using mouse wheel or pinch gesture." : "您可以裁剪要分配給土地部分的圖像的自定義部分。您可以關閉圖像的任何區域，並在移動設備上使用鼠標或手指拖動圖像來裁剪它。您也可以使用鼠標輪或捏手勢來放大和縮放。",
    "Activate multiple selection": "激活多重選擇",
    "Exit multiple selection": "退出多重選擇",
    "Owned": "擁有",
    "Owned by": "由",
    "Multiple types": "多種類型",
    "seconds ago": "几秒钟前",
    "Super Bundle": "超级捆绑",
    "Balance": "平衡",
    "View your purchased Bundles and claim to have them from your balance": "查看您购买的捆绑包并声称从您的余额中获得它们",
    "Feel free to choose lands on Map.": "随意选择地图上的土地。",
    "Earn money by using miners for generating rewards.": "通过使用矿工产生奖励来赚钱。",
    "Upgrade your owned lands from 4 slots to 10.": "将您拥有的土地从 4 个插槽升级到 10 个。",
    "Confirm": "确认",
    "You don`t have any available parcels in your inventory": "您的库存中没有任何可用的包裹",
    "Months": "几个月",
    "Super Bundle R6": "超级捆绑 R6",
    "Super Bundle R10": "超级捆绑 R10",
    "Super Bundle R15": "超级捆绑 R15",
    "Super Bundle R20": "超级捆绑 R20",
    "Super Bundle R22 S": "超级捆绑 R22 S",
    "Super Bundle R22 M": "超级捆绑 R22 M",
    "Super Bundle R22 L": "超级捆绑 R22 L",
    "Super Bundle R22 XL": "超级捆绑 R22 XL",
    "Super Bundle R22 XXL": "超级捆绑 R22 XXL",
    "1 Claim your land": "1 认领你的土地",
    "2 Claim your miner": "2 认领你的矿工",
    "3 Upgrade your land": "3 升级你的土地",
    "Your NFT balance": "你的 NFT 余额",
    "Next price changes in": "下一次價格變動",
    "Parcels": "包裹",
    "Get to know the platform usage": "了解平台使用情況",
    "What is a Parcel?": "什麼是包裹？",
    "Land is a unit of area on the GYM Street map and it is also an NFT. You can own as much land on GYM Street as you want.": "Land是GYM Street地圖上的面積單位，也是NFT。您可以在 GYM Street 擁有任意多的土地。",
    "What is a Miner?": "什麼是礦工？",
    "A miner is what generates income for you at GYM Street. You can attach up to 4 miners on each of your standard land parcels.": "礦工是 GYM Street 為您創造收入的來源。您可以在每個標準地塊上附加最多 4 個礦工。",
    "Miner rewards": "礦工獎勵",
    "My Miners": "我的礦工",
    "My Hashpower": "我的 Hashpower",
    "Total Claimed": "索賠總額",
    "Total pending rewards": "待定獎勵總額",
    "Claim all": "認領所有",
    "Daily rewards": "每日獎勵",
    "Weekly rewards": "每週獎勵",
    "Monthly rewards": "每月獎勵",
    "Yearly rewards": "年度獎勵",
    "ROI": "ROI",
    "ROI Calculator": "ROI 计算器",
    "Global Statistics": "全球統計",
    "Global Hashpower": "全球 Hashpower",
    "Your Shares": "您的分享",
    "Daily Global Rewards": "每日全球獎勵",
    "Price": "價格",
    "Total Minted": "總鑄造量",
    "total": "全部的",
    "standard": "標準",
    "upgraded": "升級",
    "active": "積極的",
    "inactive": "不活躍",
    "Attach miner": "附上礦工",
    "Total Miners": "礦工總數",
    "Total Minted Rewards": "總獎勵",
    "What is parcel and how to use it?": "地塊是 {metaverse} 地圖上具有鑄造功能的區域單位。 擁有包裹並產生收入。",
    "What is a miner and how to use it?": "矿工是您在 {metaverse} 上的收入来源。 通过将矿工附加到您的包裹上来增加您的收入。 <br/> <br/> GYMNET 矿机和双矿机的挖矿速度均为 1,000 Mh/s。",
    "Your active/inactive hashpower, 1 miner = 1,000 Mh/s, your Miners 0 x 1,000Mh/s = 0.00": "您的活跃/不活跃 hashpower，1 名矿工 = 1,000 Mh/s，您的矿工 0 x 1,000Mh/s = 0.00",
    "The amount of the cumulated claimed rewards": "累計領取獎勵金額",
    "Your current available pending rewards.": "您當前可用的待定獎勵。",
    "Congratulations!": "恭喜！",
    "Upgrade your parcels to mine even more?": "升級你的包裹來開採更多？",
    "You have NUM parcels that are not upgraded.": "您有 {parcels} 個未升級的包裹。",
    "Go to Map": "前往地圖",
    "You're mining less than the average user.": "你挖的比普通用戶少。",
    "Purchase more miners to improve your results": "購買更多礦機以改善您的結果",
    "Buy more miners": "購買更多礦機",
    "Thanks, I'll stay below average": "謝謝，我會保持在平均水平以下",
    "You aren't mining at full speed.": "你沒有全速挖掘。",
    "Do you want to buy more parcels now ?": "您現在要購買更多包裹嗎？",
    "I definitely do": "我絕對會",
    "No, I'll let my miners to rust": "不，我會讓我的礦工生鏽",
    "You have no miners": "你沒有礦工",
    "Buy miners": "購買雙礦機",
    "You haven't upgradeable parcels": "您沒有可升級的包裹",
    "You haven't inactive miners": "你沒有不活躍的礦工",
    "Close": "關",
    "Read more": "閱讀更多",
    "Drag and drop an image, or Browse": "拖放圖像，或 <label class=\"browse-button\" for=\"upload-image\">Browse</label>",
    "Browse an image": "<label class=\"browse-button\" for=\"upload-image\">瀏覽</label> 圖片",
    "Drop to upload": "下載",
    "File not supported": "文件不支持",
    "Upload your parcel image": "上傳您的包裹圖像",
    "Up to 10MB, jpg/jpeg": "最多10MB，JPG/JPEG",
    "High resolution images, should align with your land shape (you can crop after upload)": "<span class=\"bold-info\">高分辨率圖片，</span>應與您的地形一致（上傳後可裁剪）",
    "Same image may be used on different lands owned by the user": "<span class=\"bold-info\">同一圖像可用於</span>用戶擁有的不同土地",
    "No violence, offensive, graphic or 18+ content": "沒有暴力、攻擊性、圖形或 <span class=\"bold-info\">18+ 內容</span>",
    "No third-party copyrighted content, no logos or screenshots from Gymstreet and Gymnetwork": "<span class=\"bold-info\">沒有第三方版權內容，</span>沒有來自 <span class=\"bold-info\">Gymstreet 和 Gymnetwork 的徽標或屏幕截圖</span>",
    "No images already used by another user, no images from DeFi listing website images": "<span class=\"bold-info\">沒有其他用戶使用過的圖片</span>，沒有來自 DeFi 的圖片列出網站圖片",
    "No religious images": "<span class=\"bold-info\">沒有宗教圖像</span>",
    "Unlock miners by purchasing at least 1 land parcel": "通过购买至少 1 个地块解锁矿工",
    "OK": "好的",
    "Your parcels": "您的包裹",
    "Your miners": "你的矿工",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcels to start mining": "你没有全速挖矿，你有 {miners} 矿工需要附加到你的包裹上才能开始挖矿",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcel to start mining": "你没有全速挖矿，你有 {miners} 矿机需要连接到你的包裹才能开始挖矿",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcels to start mining": "您没有全速挖矿，您有 {miners} 个矿工需要附加到您的包裹上才能开始挖矿",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcel to start mining": "你没有全速挖矿，你有{miners}个矿工需要附加到你的包裹上才能开始挖矿",
    "Info Center": "信息中心",
    "Standard Parcel": "標準包裹",
    "You have no parcels": "你没有包裹",
    "Single Products": "单品",
    "Parcels sold / Total parcels": "包裹已售出 / 總包裹",
    "You can mine even more. Purchase more miners to improve your results": "你可以开采更多。 购买更多矿机以改善您的结果",
    "In Progress": "進行中",
    "Active": "積極的",
    "Learn More": "學到更多",
    "Add to wallet": "添加到錢包",
    "Your Balance": "您的餘額",
    "The Promotion starts in:": "促銷開始於：",
    "Please go to your wallet, which you just connected, and verify your login by confirming the requested transaction. It's free of charge!": "请转到您刚刚连接的钱包，并通过确认请求的交易来验证您的登录。 它是免费的！",
    "Contract addresses": "合約地址",
    "Add TOKEN to wallet": "將 {token} 添加到錢包",
    "I accept the terms and conditions": "我接受条款及条件",
    "Log In": "登錄",
    "Sign Up": "報名",
    "Connect My Wallet": "連接我的錢包",
    "Change Email": "更改電子郵件",
    "Reset Password": "重設密碼",
    "Your password has been changed.": "您的密碼已被更改。",
    "Please log in to your account.": "請登錄您的帳戶。",
    "Confirm Email": "確認郵件",
    "Emails don't match": "電子郵件不匹配",
    "Or": "要么",
    "Sign Up With Email": "通過電子郵件註冊",
    "Already have an account?": "已經有一個帳戶？",
    "Recover": "恢復",
    "Enter New Password": "輸入新密碼",
    "Confirm New Password": "確認新密碼",
    "Password": "密碼",
    "Forgot password?": "忘記密碼？",
    "Password is required": "密碼是必需的",
    "Password confirmation is required": "需要密碼確認",
    "Passwords do not match": "密碼不匹配",
    "Password must contain at least one uppercase letter, one lowercase letter and one number": "密碼必須至少包含一個大寫字母，一個小寫字母和一個號碼",
    "Password must be at least 8 characters": "密碼必須至少8個字符",
    "Password must not exceed 24 characters": "密码不得超过 24 个字符",
    "Password must contain at least one special character": "密碼必須至少包含一個特殊字符",
    "Password cannot contain spaces": "密碼不能包含空格",
    "Email is required": "需要電子郵件",
    "Email confirmation is required": "需要電子郵件確認",
    "Please enter a valid email": "請輸入有效電子郵件",
    "Step NUM1 of NUM2": "第 {num1} 步，共 {num2} 步",
    "Create Password": "創建密碼",
    "Get Started": "開始",
    "Create a Wallet": "創建錢包",
    "Confirm on this screen": "在此屏幕上確認",
    "Pick a password on the next step. This needs to be at least 8 symbols long.": "在下一步中選擇一個密碼。 這需要至少 8 個符號長。",
    "Wallet Ready": "錢包就緒",
    "Add Binance Smart Chain": "添加 Binance Smart Chain",
    "Click on the button below to add Binance Smart Chain Network to your MetaMask wallet.": "單擊下面的按鈕將 Binance Smart Chain 網絡添加到您的 MetaMask 錢包。",
    "Confirm the connection request": "確認連接請求",
    "Get Started!": "開始吧！",
    "Secure Wallet": "安全錢包",
    "Confirm your password to continue.": "確認您的密碼以繼續。",
    "You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.": "如果您無法訪問計算機，您將需要它來恢復您的錢包，並且應將其保存在安全的地方。 任何有權訪問您的 12 字備份短語的人都可以收回您的資金，因此請保密。",
    "You have successfully set up Your wallet.Welcome to the world of WEB3!": "您已成功設置您的錢包。\n歡迎來到 WEB3 的世界！",
    "Confirm Password": "確認密碼",
    "Install here.": "在這里安裝。",
    "Something went wrong, please try verifying your email again.": "出了點問題，請嘗試再次驗證您的電子郵件。",
    "Looks like your password reset link has expired. Please try again.": "看起來您的密碼重置鏈接已過期。請再試一遍。",
    "Looks like your password reset link has already been used. Please request it again.": "看起來您的密碼重置鏈接已經使用了。請再次要求。",
    "Couldn't recover password": "無法恢復密碼",
    "Couldn't reset your password.": "無法重置您的密碼。",
    "Couldn't login.": "無法登錄。",
    "Couldn't sign you up.": "無法簽約。",
    "Resend Email": "重發電子郵件",
    "Couldn't resend confirmation email.": "無法重新發送確認電子郵件。",
    "Provided email is already in use.": "提供的電子郵件已經在使用。",
    "You have already verified your email address": "您已經驗證了您的電子郵件地址",
    "Invalid or expired verification code": "無效或過期的驗證代碼",
    "An error occurred while logging in. Please try again.": "登錄時發生錯誤。請重試。",
    "We sent an email to {EMAIL} with a link to finalize your registration": "我們將電子郵件發送給{EMAIL}帶有鏈接以最終確定您的註冊",
    "We sent an email to {EMAIL} with a link to change your password": "我們將電子郵件發送給{EMAIL}帶有鏈接以更改您的密碼",
    "Referral ID": "推薦ID",
    "On the next step, tap on 'Create a new wallet'.": "在下一步中，點擊\n“Create a new wallet”。",
    "On the next step, tap on 'Create a wallet'.": "在下一步中，點擊 \"Create a wallet\"。",
    "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower case letters, symbols and numbers.": "我們建議使用其他任何地方都沒有使用的完全唯一的密碼，並包含上下案例字母，符號和數字的混合物。",
    "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.": "將記憶的單詞按順序記住。完成备份后点击 \"Complete\" 按钮。",
    "On this step you can choose. We recommend you do this now as you might otherwise lose access to your wallet. Tap on 'Start' to continue.": "在這一步你可以選擇。 \n我們建議您現在就這樣做，否則您可能無法訪問您的錢包。 \n點擊\"Start\"繼續。",
    "On this step tap on 'Start' to continue.": "在此步驟中，點擊\"Start\"\n繼續。",
    "Tap on the burger menu of the app in the top left corner of the screen.": "點擊屏幕左上角的應用程序的漢堡菜單。",
    "Next, tap on Settings in the menu to the left.": "接下来，点击左侧菜单中的\"Settings\"。",
    "Next, tap on Networks.": "接下来，点击\"Networks\"。",
    "Add More Tokens": "添加更多令牌",
    "In the search field, type in BNB Smart Chain and add it to your network.": "在搜索字段中，輸入 \"BNB Smart Chain\" 並將其添加到您的網絡。",
    "Next, select Custom Token. Paste the address of the smart contract you copied into the token address field. In the Token Symbol field, type SP, and in the Token Decimal, put 0. Then click the 'Import' button to complete your setup.": "接下来，选择\"Custom Token\"。 将您复制的智能合约的地址粘贴到\"Token Address\"字段中。 在\"Token Symbol\"字段中，键入 SP，并在\"Token Decimal\"中输入 0。然后单击\"Import\"按钮以完成设置。",
    "Add Network": "添加網絡",
    "Give permission to the platform to add a network.": "授予平台添加網絡的權限。",
    "Extra PERCENT% Discount": "額外{PERCENT}％折扣",
    "Tap on the 'Import Tokens' link to add a Custom Token.": "點擊\"Import Tokens\"鏈接以添加自定義令牌。",
    "Click on the button 'Approve' to allow the connection request.": "單擊\"Approve\"按鈕以允許連接請求。",
    "Confirm Secret Phrase": "確認秘密短語",
    "Click on the button 'Switch network' to allow the connection request.": "單擊\"Switch network\"按鈕以允許連接請求。",
    "Payment Method": "付款方法",
    "Select cryptocurrency": "選擇加密貨幣",
    "Please use an Ethereum BEP-20 address.": "請使用以太坊 BEP-20 地址。",
    "Connect to Gymstreet": "連接到 Gym Street",
    "Now to access the Gym Street Metaverse, you need to open it with the MetaMask browser. Go to your MetaMask wallet app, choose 'Browser' from the side navigation menu, search for Gym Street in the browser tab.": "現在要訪問 Gym Street Metaverse，您需要使用 MetaMask 瀏覽器打開它。 轉到您的 MetaMask 錢包應用程序，從側面導航菜單中選擇 \"Browser\"，在瀏覽器選項卡中搜索 Gym Street。",
    "First, let's set up your cryptocurrency wallet.": "首先，讓我們設置您的加密貨幣錢包。",
    "Follow the next steps to continue the setup.": "請按照下一步繼續設置。",
    "Your balance was updated": "您的餘額已更新",
    "Once installed, you should see the below splash screen. Click the 'Get Started' button to begin creating your Ethereum wallet using MetaMask.": "安裝後，您應該會看到下面的啟動畫面。 点击 \"Get Started\" 按钮开始使用MetaMask创建您的 Ethereum 钱包。",
    "You will then be asked if you want to help improve MetaMask. Click 'No, Thanks' if this doesn't interest you, otherwise click 'I agree'.": "然后系统会询问您是否愿意帮助改进 MetaMask。 如果您对此不感兴趣，请点击\"No, Thanks\"，否则请点击\"I agree\"。",
    "MetaMask will then present you with your 12-word backup phrase. You will need to write this down in the same order displayed on your screen.": "然後，MetaMask將為您提供12個字的備份短語。您需要以與屏幕上顯示的相同順序寫下。",
    "After Downloading and opening MetaMask, tap on 'Get Started'.": "下载并打开 MetaMask 后，点击\"Get Started\"。",
    "Improve Metamask": "改进 MetaMask",
    "Congratulations! You have set up your MetaMask wallet. Confirm the next few pop-ups on the screen to access your wallet.": "恭喜！您已經設置了MetaMask錢包。確認屏幕上的接下來的幾個彈出窗口以訪問您的錢包。",
    "Import Binance Smart Chain": "導入 Binance Smart Chain",
    "Next, copy the NFT Smart Contract by tapping on the button below.": "接下来，通过点击下面的按钮复制 \"NFT Smart Contract\"。",
    "Copy NFT Contract": "复制 \"NFT Contract\"",
    "Install MetaMask": "安裝MetaMask",
    "Install MetaMask here.": "在這里安裝MetaMask。",
    "Guide for MetaMask Wallet connection process.": "MetaMask錢包連接過程的指南。",
    "MetaMask will then present you with your 12-word backup phrase.": "然後，MetaMask將為您提供12個字的備份短語。",
    "MetaMask Secret Phrase": "MetaMask 密語",
    "Copy NFT Smart Contract": "复制NFT Smart Contract",
    "You are almost finished with the MetaMask setup process. Just click 'All done' on the last page, and you will be automatically logged into MetaMask. If you ever log out, you can log back in by clicking on the icon added to your web browser (usually, it's next to the URL bar).": "您幾乎完成了MetaMask設置過程。只需單擊最後一頁上的\"All done\"，您將自動登錄到MetaMask。如果您登錄，則可以通過單擊添加到Web瀏覽器中的圖標（通常在URL欄旁邊）來重新登錄。",
    "Your payment processing is complete. Your NFT balance has been updated!": "您的付款处理已完成。 您的 NFT 余额已更新！",
    "Your payment is received but is incomplete. Please pay AMOUNT CURRENCY more to complete the payment.": "您的付款已收到但不完整。 请多支付 {amount} {currency} 以完成付款。",
    "Terms & Conditions": "条款和条件",
    "Email Verification": "电子邮件验证",
    "Transaction history": "交易歷史記錄",
    "Order history": "訂單歷史",
    "Product": "產品",
    "Quantity": "數量",
    "Payment UID": "付款UID",
    "Status": "地位",
    "Currency": "貨幣",
    "Autoship":"自动送货",
    "Amount": "數量",
    "Date": "日期",
    "Tx ID": "Tx ID",
    "Completed": "完全的",
    "MetaMask is not installed": "未安裝 MetaMask",
    "If you have already installed MetaMask, please": "如果您已經安裝了{MetaMask}，請",
    "refresh page": "刷新頁面",
    "to continue.": "接著說。",
    "You have successfully installed MetaMask. Now you can add a Network.": "您已成功安裝 MetaMask。 現在您可以添加一個網絡。",
    "You already have this network": "您已經擁有此網絡",
    "Continue": "继续",
    "Finalize Registration": "完成注册",
    "Please confirm your email to continue": "請確認您的電子郵件繼續",
    "New email must be different from current email": "新電子郵件必須與當前電子郵件不同",
    "Actions": "動作",
    "Request to change the chain has been rejected": "更改鏈的要求已被拒絕",
    "Switch Chain": "開關鏈",
    "Choose your Metaverse": "選擇你的元宇宙",
    "You need to choose your Metaverse to continue. Maps, Dashboard and My Products information will vary depending on your selection. Currently {METAVERSE} Metaverse is selected.": "您需要选择您的 Metaverse 才能继续。 地图、仪表板和我的产品信息将根据您的选择而有所不同。 目前已选择{METAVERSE} Metaverse。",
    "Can't check your purchases": "無法檢查您的購買",
    "Attach {COUNT} miners": "附上 {COUNT} 個礦工",
    "Attach 1 miner": "附上1名礦工",
    "View on map": "在地圖上查看",
    "Buy {COUNT} parcels": "購買 {COUNT} 個包裹",
    "Buy 1 parcel": "買1個包裹",
    "Buy {COUNT} miners": "購買 {COUNT} 雙礦機",
    "Buy 1 miner": "買1個雙礦機",
    "Metaverses": "元宇宙",
    "My NFTs": "我的 NFT",
    "Total Hashpower (Mh/s)": "總算力 (Mh/s)",
    "Available Miner Slots": "可用的礦工插槽",
    "Average ROI past 9 months": "過去9個月的平均投資回報率",
    "Login Via Crypto Wallet": "通过加密钱包登录",
    "Land reserved for PARTNER": "為{PARTNER}保留的土地",
    "About PARTNER": "關於{PARTNER}",
    "swap": "交換",
    "transfer": "轉移",
    "approve": "批准",
    "The minimum amount to claim is N": "最低索赔金额为 {n}",
    "paginationText": "显示 {from} - {to} of {count}",
    "gymstreet_turnover": "Gymstreet 营业额",
    "refreshData": "刷新数据",
    "NFT Turnover": "NFT 营业额",
    "Single Pool Turnover": "单池营业额",
    "max": "最大额度",
    "turnovers": "营业额",
    "Total partners": "总合作伙伴",
    "depth": "深度",
    "turnover": "周转",
    "weekly": "每周",
    "monthly": "月刊",
    "wallet": "钱包",
    "columns": "列",
    "filters": "过滤器",
    "level": "等级",
    "search": "搜索",
    "partners": "伙伴",
    "Farming Turnover": "农业营业额",
    "Vault Turnover": "保险库营业额",
    "Careers status": "职业明细",
    "GYMNET Balance": "GYMNET余额",
    "Save": "节省",
    "Swap": "交换",
    "Turnover pool": "池子流水l",
    "Global Line Bonus": "全球线路奖金",
    "Turnover pools": "池子流水",
    "Tax pools": "税池({n}mo)",
    "Total qualified turnover": "合格总营业额",
    "My claimed rewards": "我领取的奖励",
    "My pending rewards": "我的待定奖励",
    "Week": "周",
    "Month": "月",
    "In order to get qualified for any pool, you’ll have to follow the 50% turnover rule: The maximum share each direct partner can contribute to the required turnover is up to 50% of the turnover coming from NFT sales.": "为了获得任何资金池的 资格，您必须遵循 50% 的营业额规则：每个直接合作伙伴可以贡献的最大份额为所需营业额，最多为 NFT 销售营业额的 50%。",
    "View more": "查看更多",
    "Turnover progress": "流水进展",
    "Thank you for your participation in Global Line Incentive!": "感謝您參與全球線激勵！",
    "Global line before June 2023": "2023年6月前全球專線",
    "Global line before March 2023": "2023年3月前全球專線",
    "More info": "更多信息",
    "Your Position": "您现在的位置",
    "Last Position": "最后位置",
    "My line length": "我的线长",
    "Global Line Turnover": "全球线路营业额",
    "The turnover generated from all participants who joined the Global Line after you.": "在您之后加入全球线的所有参与者产生的营业额。",
    "Your purchases": "你的购买",
    "You need purchases of at least $100 to become a Global Line member.": "您需要购买至少 100 美元才能成为 Global Line 会员。",
    "Your pending income": "你的待定收入",
    "Buy more NFTs to increase your shares": "购买更多<strong><i>NFT</i></strong>以增加您的<strong>份额。</strong>",
    "New Registrations": "新注册",
    "Turnover": "周转",
    "Global level": "全球级别",
    "Joining time": "加盟时间",
    "Global Line Total Turnover": "全球线路总成交额",
    "Global Line end": "全球线路结束",
    "Please confirm your claim": "请确认您的索赔",
    "After you confirm, you will receive your rewards on your balance.": "确认后，您将在余额中收到奖励。",
    "Select Language": "选择语言",
    "Download": "下载",
    "Your Income": "你的收入",
    "Shares": "分享",
    "Single Miner": "单个矿工",
    "Super Bundles": "超级捆绑包",
    "Regular Bundles": "常规捆绑包",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%.": "每個直接合作夥伴可以為所需營業額貢獻的最大份額高達 50%。",
    "Your partners": "您的合作伙伴",
    "Generated turnover": "已生成的流水",
    "Qualified turnover": "合格的流水",
    "Generated": "生成",
    "Qualified": "合格的",
    "Your partners turnover statistics will appear here.": "您的合作伙伴营业额统计数据将显示在此处。",
    "Pools": "池子",
    "Qualification": "资质",
    "Rewards": "奖励",
    "Participants": "参与者",
    "Total rewards": "总奖励",
    "Rewards per participant": "每个参与者的奖励",
    "Land sales contribution": "土地销售贡献",
    "Maintainance contribution": "维护贡献",
    "Distributed": "分布式",
    "Refresh data": "刷新数据",
    "You will receive your rewards directly on your wallet": "您将直接在钱包中收到奖励",
    "Total claimed": "索赔总额",
    "Next distribution": "下一次分发",
    "Unclaimed BUSD rewards": "还没取现的BUSD奖励",
    "Required level": "要求等级",
    "NFT sales percent": "NFT 销售额 {n}%",
    "Total qualified participants": "合格参与者总数",
    "TxID": "区块链交易哈希",
    "Tx Type": "交易类型",
    "Global line reward": "全球线路奖励",
    "Your share": "你的份额",
    "Income before": "之前的收入",
    "Income after": "之后的收入",
    "NFT Income": "NFT收入",
    "NFT Commissions Total": "NFT总返佣",
    "NFT Statistic": "NFT统计",
    "Level": "等级",
    "User": "用户",
    "Your current pin": "您当前的密码",
    "Turnover for the next pin": "下一针的营业额",
    "Your next incentive": "你的下一个激励",
    "Your next bonus": "您的下一个奖金",
    "Incentives": "激励措施",
    "Partners with pin qualification": "具有 {n} 个 pin 资格的合作伙伴",
    "Partners": "伙伴",
    "Cash Bonus": "现金红利",
    "Your pin": "你的密码",
    "Incentive": "激励",
    "Pin": "别针",
    "Level pool": "水平池",
    "Go to PARTNER": "轉到{PARTNER}",
    "Partnerships": "夥伴關係",
    "Cannot find EMAIL_OR_WALLET": "找不到 {EMAIL_OR_WALLET}",
    "Incentive Ends In: ": "激勵<strong>結束</strong>：",
    "Your Rewards History": "您的獎勵歷史",
    "Dubai & Sofia Trip": "迪拜和索非亞之旅",
    "No Rewards": "沒有獎勵",
    "No Partners": "沒有伴侶",
    "Reward": "報酬",
    "Granted": "的確",
    "Transaction": "交易",
    "Free BUNDLE": "免費{BUNDLE}",
    "Team Purchases": "團隊採購",
    "Direct Sales": "直銷",
    "You have won this reward": "您已獲得此獎勵",
    "50% Turnover Rule is applied for all incentives!": "所有獎勵均適用 50% 的營業額規則！",
    "Please note that you have the chance to earn a complimentary R8 Bundle in two different ways: either by reaching 20k in direct sales or by achieving 100k in team purchases.": "請注意，您有機會通過兩種不同的方式獲得免費的 R8 捆綁包：直銷達到 20k 或團隊購買達到 100k。",
    "Insufficient funds for gas, please add BNB to your wallet": "汽油資金不足，請在錢包中添加BNB",
    "Please note that you will require up to AMOUNT worth of CURRENCY to complete the transaction.": "请注意，您将需要高达 {amount} 价值的 {currency} 来完成交易。",
    "Please note that AMOUNT worth of CURRENCY is required to complete the transaction.": "請注意，完成交易需要價值 {amount} 的 {currency}。",
    "Please note that AMOUNT_CURRENCY is required for gas fee": "請注意，汽油費需要 {amount_currency}",
    "Select a payment method": "选择付款方式",
    "Use your account's wallet balances to purchase the product.": "使用您的帐户钱包余额来购买产品。",
    "Connect a WEB3 wallet like Metamask or Trustwallet and pay with {currency}. This requires a small amount of BNB ($0.30) to cover the transaction fees!": "连接一个WEB3钱包，如Metamask或Trustwallet，并使用{currency}支付。这需要少量BNB(0.30美元)来支付交易费用!",
    "Select one of the available blockchains and cryptocurrencies, and proceed with the payment via an external wallet application.": "选择一个可用的区块链和加密货币，然后通过外部钱包应用程序进行支付。",
    "Internal wallet": "内部钱包",
    "Web3 Wallet": "Web3钱包",
    "External wallet": "外部钱包",
    "Select Currency": "选择货币",
    "Select from the available balances": "从可用余额中进行选择",
    "Purchase for myself": "为自己购买",
    "Select Blockchain": "选择区块链",
    "Select Cryptocurrency": "选择加密货币",
    "Please select another payment method": "请选择其他付款方式",
    "Connect my wallet": "连接我的钱包",
    "Select a product owner": "选择产品负责人",
    "Please select who you are purchasing for:": "请选择您为谁购买：",
    "Product Owner Tooltip": "产品负责人提示框",
    "Owner e-mail": "负责人邮箱",
    "Purchase Summary": "购买汇总",
    "Network": "网络",
    "Fees": "费用",
    "Rate": "汇率",
    "Send to wallet address": "发送到钱包地址",
    "Or scan the code with your wallet app to fill in address automatically.": "或者用钱包应用程序进行扫码，自动填写地址。",
    "Send only CURRENCY to this wallet address!": "只发送 {currency} 到这个钱包地址!",
    "Please send your crypto within 30 minutes to ensure a fixed exchange rate": "请在30分钟内发送您的加密货币，以确保固定汇率",
    "I sent the crypto": "我已发送加密货币",
    "Insufficient funds": "资金不足",
    "Purchase Successful": "购买成功！",
    "The NFT balance was successfully updated and can be viewed in My NFTs tab.": "要监视交易状态您可以在 \"我的订单\" - 如果成功，您的NFT余额将更新在 \"我的NFTs\" 标签。",
    "Available balance": "可用余额",
    "scanning_for_transaction_text": "要监视交易状态您可以在 \"我的订单\" - 如果成功，您的NFT余额将更新在 \"我的NFTs\" 标签。",
    "This window will close automatically once we receive your crypto.": "一旦我们收到您的加密货币，此窗口将自动关闭。",
    "We did receive your crypto, but it was less than required. Please transfer the missing amount to complete your transaction.": "我们确实收到了您的加密货币，但金额不足。请转帐遗漏的金额以完成您的交易。",
    "Please do not attempt to do another transaction. Send your Payment UID to our support.": "交易已过期：请不要尝试进行其他交易。发送您的交易ID到我们的技术支持。",
    "Scanning for transaction": "扫描交易",
    "Go to My Orders": "转到我的订单",
    "remaining": "尚未处理",
    "Please send your crypto within 3 hours to ensure a fixed exchange rate": "請在 3 小時內發送您的加密貨幣以確保固定匯率",
    "Please send your crypto within n minutes to ensure a fixed exchange rate": "請在 {N} 分鐘內發送您的加密貨幣，以確保固定匯率",
    "Missing crypto amount": "丢失的加密货币金额",
    "Missing amount": "丢失金额",
    "Recived amount": "收到金额",
    "Required amount": "要求金额",
    "Continue with payment": "继续付款",
    "Your payment has expired. To resolve this issue, please contact customer support": "您的付款已過期。 要解決此問題，請聯繫客戶支持",
    "Your payment was refunded. AMOUNT CURRENCY was returned to your wallet.": "您的付款已退還。 {amount} {currency} 已退回您的錢包。",
    "You can monitor the transaction status in the Order History tab. If it is successful, the NFT balance will be updated in the My NFTs tab.": "您可以在“訂單歷史”選項卡中監控交易狀態。 如果成功，NFT 餘額將在“我的 NFTs”選項卡中更新。",
    "OFF": "離開",
    "Received Amount.": "收到金額。",
    "Processing Fee": "加工費",
    "Estimated gas fee": "預估汽油費",
    "Please indicate the intended recipient of the product. You have the option to purchase it for yourself or choose 'Another user' as the recipient.": "请注明产品的预期收件人。您可以选择为自己购买或选择“其他用户”作为收件人。",
    "Please choose your preferred payment method to finalize your purchase.": "请选择您喜欢的付款方式，以完成您的购买。",
    "Your payment has expired. To resolve this issue, please contact customer support.": "您的付款已过期。要解决此问题，请联系客户支持。",
    "Your payment has been received and is currently being processed. We will notify you once the process is complete.": "您的付款已收到，目前正在处理中。一旦流程结束，我们将通知您。",
    "Your transaction has been declined. Please attempt the transaction again.": "您的交易已被拒绝。请再次尝试进行交易。",
    "Monitor the status of your transaction by visiting the provided link:": "通过访问提供的链接来监视您的交易状态:",
    "Apologies, an error occurred on the blockchain side. Please try again.": "抱歉，区块链端出现错误。请再试一次。",
    "Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.": "请等待区块链上正在进行的交易完成后再发起新的交易。",
    "Transaction Expired": "交易過期",
    "Enable Payment": "啟用支付",
    "Email does not exist": "電子郵件不存在",
    "Request another quote": "請求另一個報價",
    "Purchase Success!": "購買成功！",
    "Purchase for another user": "為其他用戶購買",
    "Pay": "支付",
    "You purchased GIFT for RECEIVER!": "您為 {receiver} 購買了 {gift}！",
    "You've purchased GIFT for RECEIVER!": "您為 \"{receiver}\" 購買了 {gift}！",
    "You've purchased a GIFT for RECEIVER!": "您为\"{receiver}\"购买了一份{gift}！",
    "Rewards claimed successfully": "獎勵領取成功",
    "Commissions claimed successfully.": "佣金領取成功。",
    "You received GIFT from SENDER!": "您收到了來自{sender} 的{gift}！",
    "You've received GIFT from SENDER!": "您已收到来自 \"{sender}\" 的 {gift}！",
    "You've received a GIFT from SENDER!": "您已收到来自 \"{sender}\" 的 {gift}！",
    "Transaction Failed": "交易失敗",
    "The transaction has failed. Please try again.": "交易失敗。 請再試一次。",
    "Your transaction has been declined. Please add BNB to your wallet and attempt the transaction again.": "您的交易已被拒絕。 請將 BNB 添加到您的錢包並再次嘗試交易。",
    "Blockchain Notification: Apologies, an error occurred on the blockchain side. Please try again.": "區塊鏈通知：抱歉，區塊鏈端發生錯誤。 請再試一次。",
    "Apologies, an unexpected error occurred. Please retry your request. If the issue persists, please contact support.": "抱歉，發生意外錯誤。 請重試您的請求。 如果問題仍然存在，請聯繫支持人員。",
    "Received Amount": "收到金額",
    "Dual Miner": "雙礦機",
    "Bitopex": "Bitopex",
    "Gymnet": "Gymnet",
    "No enough CURRENCY to cover gas fee.": "沒有足夠的 {currency} 來支付汽油費。",
    "Please top up your wallet with AMOUNT_CURRENCY to cover gas fee.": "請用 {amount_currency} 充值您的錢包以支付汽油費。",
    "Scanning": "掃描",
    "To continue, please enable payment.": "要繼續，請啟用付款。",
    "Kindly be aware that AMOUNT_CURRENCY may be necessary to cover the gas fee.": "請注意，可能需要 {amount_currency} 來支付汽油費。",
    "Insufficient funds in CURRENCY to cover the gas fee.": "{currency} 的資金不足以支付汽油費。",
    "Please top up your wallet with AMOUNT_CURRENCY to cover the gas fee.": "請用 {amount_currency} 充值您的錢包以支付汽油費。",
    "We will cover the gas fee for this transaction on your behalf.": "我們將代表您支付此交易的汽油費。",
    "Value": "价值",
    "Reach Rank": "获得等级 <b>{n}</b>",
    "Rewards in Gymnet": "Gymnet中的奖励",
    "Rewards in Bitcoin": "Bitcoin中的奖励",
    "For Limited Time Only": "仅限于 <strong>限时</strong> ",
    "Seconds": "秒",
    "Minutes": "分",
    "Days": "天",
    "Hours": "时",
    "Unclaimed parcels": "未认领包",
    "Upgraded parcels": "升级包",
    "Inactive miners": "不活跃矿工",
    "Active miners": "活跃矿工",
    "Available Balance": "可用余额",
    "Utility Balance": "Utility余额",
    "Bitopex Balance": "Bitopex余额",
    "Moonberg Balance": "Moonberg余额",
    "Balance History": "余额历史记录",
    "Miner Hashpower": "礦工 Hashpower",
    "Dual Miners": "双币矿工",
    "Dual miners info": "活跃矿工和总双币矿工数量。",
    "Dual Miner Hashpower": "雙礦工 Hashpower",
    "Dual miners hashpower info": "您擁有的 hashpower 是通過將您的活躍雙礦工數量乘以單位礦工 hashpower 來確定的，它被設置為每單位 1000Mh/s。",
    "Unlock the full potential of your Bitopex rewards as they come to life following your purchase of any of our special bundles. Once your Bitopex Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "在您购买我们的任何特别捆绑包后，您的 Bitopex 奖励将变为现实并释放全部的潜力。 一旦您的 Bitopex 余额可用，您就有权访问总奖励、已收到奖励和预期奖励的概览。 此外，倒数计时器会显示下一次奖励分配情况，确保您不会错过奖励。",
    "Sec": "秒",
    "Min": "分",
    "Total Rewards Expected": "总预计奖励",
    "Monthly Distribution": "按月分配",
    "Price Projection": "价格预测",
    "Server error": "服务器错误",
    "Average Gymnet ROI for the last 9 months": "过去9个月的平均Gymnet ROI",
    "Dual Miners will generate rewards in Bitopex tokens, which will be distributed every Friday.":"双币矿工将产生 Bitopex 代币奖励，将于每周五分发。",
    "Introducing the Utility Balance feature, a new and convenient payment method within our payment ecosystem. With this feature, you'll enjoy a separate balance that can be used alongside other payment methods. It provides you with the flexibility to partially utilize your utility balance, up to 25% of the total when purchasing single Land NFTs or making payments for Land NFT bundles.":"隆重推出 Utility 餘額功能，這是我們支付生態系統中一種全新且便捷的支付方式。 通過此功能，您將享受單獨的餘額，可與其他付款方式一起使用。 它使您可以靈活地部分利用您的公用事業餘額，在購買單個 Land NFT 或支付 Land NFT 捆綁包時最多可使用總量的 25%。",
    "Balance before": "之前的余额",
    "Balance after": "之后的余额",
    "TXID": "TXID（交易编号）",
    "The number of active and total miners you have in Gymstreet and Kabutochō, respectively.": "您分别在 Gymstreet 和 Kabutochō 拥有的活跃矿工数量和总矿工数量。",
    "The hashpower you possess is determined by multiplying the number of your active miners with the unit miner hashpower, which is set at 1000Mh/s per unit.": "你擁有的 hashpower 是通過將你的活躍礦工數量乘以單位礦工 hashpower 來確定的，它被設置為每單位 1000Mh/s。",
    "Token": "代币",
    "Buy Bundles": "购买捆绑包",
    "Total Received": "收到总数",
    "Next Rewards Distribution In:": "下次奖励分配在:",
    "Total Rewards": "总奖励",
    "Payout": "支付",
    "Weekly": "每周",
    "Next Distribution Amount": "下一次分配金額",
    "Internal transaction": "內部交易",
    "Minimum claimable amount is (AMOUNT)": "最低索賠金額為 {amount}",
    "User Profile": "用户配置文件",
    "Name": "姓名",
    "Nationality": "国籍",
    "Contact Information": "联系方式",
    "Country": "国家",
    "City": "城市",
    "Street": "街道",
    "Zip": "邮政编码",
    "E-Mail": "电子邮箱",
    "Wallet address": "钱包地址",
    "Name is required": "请填写姓名",
    "Nationality is required": "请填写国籍",
    "Country is required": "请填写国家",
    "City is required": "请填写城市",
    "Street is required": "请填写街道",
    "Zip is required": "请填写邮政编码",
    "Successfully updated": "更新成功",
    "Zip contain only numbers and min length is 3": "邮政编码只包含数字，最小长度为3",
    "Please enter a valid email address": "请输入有效的电子邮件地址",
    "Personal information": "个人信息",
    "T&C": "条款与条件",
    "Field is required": "必填字段",
    "Password successfully changed": "密码更改成功",
    "Old password is incorrect": "旧密码不正确",
    "Oops...Something went wrong. Please try again": "哎呀……出了什么问题。请再试一次",
    "Name must be at least 3 characters": "名称至少为3个字符",
    "City must be at least 3 characters": "城市至少为3个字符",
    "Street must be at least 3 characters": "街道至少为3个字符",
    "Affiliate Terms and conditions": "附属条款与条件",
    "Affiliate links": "联盟链接",
    "Affiliate link successfully changed": "联盟链接更改成功",
    "Please enter the Bitopex affiliate link here. Kindly note that you can only save the link once.": "请在这里输入Bitopex联盟链接。请注意，您只能保存一次链接。",
    "Input cannot be only spaces": "輸入不能只有空格",
    "To proceed with the registration, kindly accept the above 2 conditions by checking the corresponding boxes.": "要繼續註冊，請勾選相應的框以接受上述 2 個條件。",
    "I hereby confirm that I am not a US citizen": "我特此確認我不是美國公民",
    "I hereby confirm that I am not a UAE citizen": "我特此確認我不是阿聯酋公民",
    "Enter Old Password": "輸入舊密碼",
    "Change username & password": "更改使用者名稱和密碼",
    "My wallet": "我的錢包",
    "Purchase": "購買",
    "Username required to proceed": "需要用户名才能继续",
    "Enter preferred username here": "在此输入首选用户名",
    "Username must be at least 8 characters, starting with a letter. Use only lowercase letters, numbers, hyphens, dots, and underscores.": "用户名必须至少包含 8 个字符，以字母开头。 仅使用小写字母、数字、连字符、点和下划线。",
    "Username is already taken. Please choose a different username.": "用户名已存在。 请选择一个不同的用户名。",
    "Email or username": "电子邮件或用户名",
    "Username is available": "用户名可用",
    "Provided username is already taken.": "提供的用户名已被占用。",
    "Invalid credentials. Please try again.": "无效证件。\n 请再试一次。",
    "Username successfully set": "用户名设置成功",
    "Dear User, due to ongoing updates to our platform, you will need to enter a username to continue. The username needs to be unique and cannot be changed": "尊敬的用户，由于我们的平台不断更新，您需要输入用户名才能继续。 用户名需要唯一且不能更改",
    "You have successfully set your username": "您已成功设置用户名",
    "Continue to dashboard": "继续仪表板",
    "Username is required": "用户名是必需的",
    "Rank Transfer": "排名转移",
    "Review": "审查",
    "Top Up": "充值",
    "The claim button is currently disabled for maintenance. We apologize for the inconvenience. Please come back shortly.": "声明按钮目前因维护而被禁用。 我们对不便表示抱歉。 请尽快回来。",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / BUSD": "当新的商业大楼正在实施时，您可以\n选择通过支付GYMNET / BUSD购买土地",
    "Parcel NFT": "NFT 地块",
    "Miner NFT": "NFT 矿工",
    "BUSD Balance": "BUSD 余额",
    "Your payment has been received. It is currently being processed. You will receive a notification when done.": "已收到您的付款。目前正在处理中。完成后您将收到通知",
    "Error": "错误",
    "Please enter valid email": "请输入有效的电子邮箱",
    "Your sponsor is": "您的担保人是",
    "Credentials": "资格证书",
    "Enter Wallet Address (i.e. 0xb794f...79268)": "输入钱包地址 (即 0xb794f...79268)",
    "Contact Customer Support": "请联系客户支持",
    "Change Wallet": "更换钱包",
    "Account": "账户",
    "Please leave an address here, in which we can also mint your NFTs afterwards.": "请在这里留下一个地址，之后我们也可以在其中铸造您的NFTs。",
    "Important!": "重要!",
    "It should be a BEP-20 address - i.e. a BNB Smart Chain wallet address. We recommend the wallet Metamask or Trustwallet.": "它应该是BEP-20地址，即BNB Smart Chain钱包地址。我们推荐Metamask或Trustwallet钱包。",
    "NEVER USE AN EXCHANGE Wallet ADRESS !!!": "永远不要使用交易所钱包地址!!!",
    "Failed": "失败",
    "Account changed": "已更改账户",
    "Your Profile has been updated": "您的个人资料已更新",
    "You already have an attached wallet address to this account.": "您已将钱包地址附加到此帐户。",
    "Login": "注册",
    "Existing wallet": "已存在钱包",
    "Referral ID is required": "需要提供推荐ID",
    "Pending Transaction": "未决交易",
    "Processing": "处理中",
    "Send this payment ID to a customer support when you are requested to do so.": "当您被要求时，将此付款ID发送给客户支持。",
    "To be able to claim your NFTs you need to connect the same wallet address attached to your account": "为了能够申领您的NFTs，您需要连接同一个附加在您帐户上的钱包地址",
    "Please confirm your email to continue.": "请确认您的电子邮件以继续。",
    "Valid address": "有效地址",
    "This social account is used by another user. Verify your email first or contact Customer Support": "其他用户正在使用此社交帐户。请先验证您的电子邮件或联系客户支持",
    "Invalid wallet connection": "钱包连接无效",
    "Registration completed successfully": "成功完成注册",
    "Failed to complete registration of the user": "未能完成用户注册",
    "Failed to register user": "注册用户失败",
    "Signature grant failed": "授予签名失败",
    "Unsupported chain": "不受支持的链",
    "This wallet address is already associated with another user account. Please use another one!": "此钱包地址已与另一个用户帐户关联。请使用另一个！",
    "Failed to add address to your wallet": "无法将地址添加到您的钱包",
    "Address has been successfully added to your wallet": "地址已成功添加到您的钱包",
    "Failed to accept Affiliate TMS": "接受联盟TMS失败",
    "Wallet address has been successfully applied": "已成功申请钱包地址",
    "Your email is verified.": "已验证您的电子邮件。",
    "Failed to add Token": "添加代币失败",
    "Failed to upload image": "上传图像失败",
    "Wrong file size. Please upload image files with size less than 10MB": "文件大小错误。请上传大小小于10MB的图像文件",
    "We've verified your email address. You can now continue using the site.": "我们已验证您的电子邮件地址。您现在可以继续使用该网站。",
    "You need to switch to Binance Smart Chain": "您需要切换到币安智能链",
    "Switch to Binance Smart Chain to continue using GymStreet": "切换到币安智能链以继续使用GymStreet",
    "To CashFT": "到 CashFT",
    "Buy a Super Bundle in the next": "接下来购买Super Bundle",
    "and get an EXTRA discount!": "并获得额外折扣！",
    "See All Offers": "查看所有优惠",
    "I accept the affiliate terms & conditions": "我接受联盟条款和条件",
    "Special offer!": "特别优惠!",
    "Just one": "只有一个",
    "1000 Mh/s": "1000 Mh/s",
    "View Claimed Coordinates": "查看已申领坐标",
    "Your Parcel Coordinates": "您的地块坐标",
    "Number of miners you want to attach": "想要附加的矿工数量",
    "Referral Link": "推荐链接",
    "Affiliate Link": "联盟链接",
    "Invalid Wallet": "无效钱包",
    "Back to Log In": "返回登录",
    "You need a valid referral ID to finalize your registration": "您需要一个有效的推荐ID来完成您的注册",
    "I have read and accept the": "我已阅读并接受",
    "Your Sponsor": "你的担保人",
    "Forgot Your Password": "忘记密码",
    "Enter your email address and we will send you instructions to reset your password.": "输入您的电子邮件地址，我们将向您发送重置密码的说明。",
    "We just sent you an email to confirm your e-mail address. Please click on the link in the e-mail to reset your password.": "我们刚刚给您发了一封电子邮件，确认您的电子邮件地址。请单击电子邮件中的链接重置您的密码。",
    "Welcome To Metaverse": "欢迎来到 {n} Metaverse",
    "Commissions": "电费券退款",
    "Refund of electricity vouchers": "Refund of electricity vouchers",
    "Import Custom Token": "导入自定义代币",
    "Add Token To Wallet": "将代币添加到钱包",
    "Token contract address": "代币合约地址",
    "Token symbol": "代币符号",
    "Provided address is not a supported token address": "提供的地址不是支持的代币地址",
    "Are you sure you want to claim your rewards": "您确定要领取奖励吗",
    "OTP Code": "OTP验证码（一次性验证码）",
    "OTP Code is required": "需要OTP验证码",
    "Enter OTP code you have received at email": "输入您在{电子邮件}中收到的OTP代码",
    "Asset": "资产",
    "Gas": "Gas",
    "Send crypto": "发送加密货币",
    "Enter wallet address": "输入钱包地址",
    "NFTs": "NFTs",
    "Activity": "活动",
    "Assets": "资产们",
    "Import token": "导入代币",
    "Don't see your token?": "没看到您的代币？",
    "more": "更多",
    "Send": "发送",
    "Sending success": "发送成功",
    "View account in explorer": "在浏览器中查看帐户",
    "estimated": "已评估",
    "Copy your address": "复制您的地址",
    "Export private key": "导出私钥",
    "Receive": "接受",
    "Account Details": "帐户详情",
    "View on blockchain": "在区块链查看",
    "GymStreet Parcels": "GymStreet 地块",
    "GymStreet Miners": "GymStreet 矿工",
    "Kabutocho Parcels": "Kabutocho 地块",
    "Kabutocho Miners": "Kabutocho 矿工",
    "Invalid wallet address": "无效的钱包地址",
    "Calculate": "计算",
    "Sending": "发送中",
    "Reject": "拒绝",
    "Confirmation": "确认",
    "Gas fees are paid to crypto miners who process transactions on the network. Gas fees are set by the network and fluctate based on network traffic and transaction complexity.": "Gas费用支付给在网络上处理交易的加密矿工。Gas费用由网络设定，并根据网络流量和交易复杂性波动。",
    "Insufficient TOKEN balance": "{token}余额不足",
    "Confirm Swap": "确认Swap",
    "Approve Allowance": "批准津贴",
    "Help Center": "帮助中心",
    "Confirm the Allowance Transaction in order to be able to use Token through our services. Please note that this is a one-time action.": "确认津贴交易，以便能够通过我们的服务使用<strong>{n}</strong>代币。请注意，这是一次性操作。",
    "No Activities yet": "还没有活动",
    "To": "到",
    "Contract interaction": "合约交互",
    "Are you sure you want to delete this token": "是否确实要删除此代币",
    "Send only using  {CHAIN}  network to receive funds on this deposit address.": "僅使用 {CHAIN} 網路發送以接收此存款地址上的資金。",
    "Leadership Incentives": "领导力激励",
    "Pool statistics": "资金池统计",
    "Hourly": "每小时",
    "Turnover rule": "营业额规则",
    "Turnover rule percentage": "{n}% 营业额规则",
    "Miner sales contribution": "矿工销售贡献",
    "Pool": "资金池",
    "percent of NFT sales": "NFT销售量的{n}% ",
    "partner": "合作伙伴",
    "Main area": "主要区域",
    "Turnover percentage rule": "营业额 {n}% 规则",
    "With dollar each": "每个用美元",
    "Direct partners": "直接合作伙伴",
    "Your next bonus will appear here": "您的下一笔奖金将出现在此处",
    "Your incentive": "您的激励",
    "Your cash bonus": "您的现金奖金",
    "Your bonus history": "您的奖金历史记录",
    "At the current moment you don't have any rewards": "目前您没有任何奖励",
    "Team of": "团队",
    "Total generated turnover": "生成的总营业额",
    "Active Miners": "活跃矿工",
    "Total pool rewards": "总资金池奖励",
    "Your pool share": "您的资金池股份",
    "Total gGymnet": "总gGymnet",
    "GYMNET Commissions Total": "GYMNET 总佣金",
    "Your attached wallet is {walletAddress}. You have tried to make request with wallet {currentWallet}. Please change connected wallet to {walletAddress}": "您附加的钱包是<b>{walletAddress}</b>。您尝试使用钱包<b>{currentWallet}</b>进行请求。请将连接的钱包更改为<b>{walletAddress}</b>",
    "Available for limited time": "限时可用",
    "Bonus": "奖金",
    "Utility": "公共设施",
    "The URL must begin with 'https://bitopex.io/home/' and include your referral ID": "URL必须以'https://bitopex.io/home/'开头并且包含您的推荐ID",
    "Insufficient funds: to continue with payment use utility balance": "资金不足:继续用公用设施余额付款",
    "My Team": "我的团队",
    "Team Statistics": "团队统计",
    "All partners": "所有合作伙伴",
    "Active partners": "活跃的合作伙伴",
    "Inactive partners": "不活跃合作伙伴",
    "Binary Points": "二进制点",
    "Points in left leg": "左腿上的点",
    "Points in right leg": "右腿上的点",
    "Left leg": "左腿",
    "Right leg": "右腿",
    "Holding Tank": "贮槽",
    "Enrollment Tree": "登记树",
    "Binary Tree": "二进制树",
    "CV Points History": "CV点历史记录",
    "Time Left To Activation": "<b>激活</b>剩余时间",
    "Search by email or wallet": "通过邮件或者钱包搜索",
    "Refresh Data": "刷新数据",
    "Joined at": "加入在",
    "Placement": "放置",
    "Select": "选择",
    "Own": "个人",
    "Team": "团队",
    "Position": "位置",
    "CV Points": "CV点",
    "CV = commission volume": "CV = 佣金数额",
    "Genealogy": "宗谱",
    "Unilevel": "Unilevel",
    "Select view": "选择视图",
    "Team size": "团队大小",
    "Rank": "等级",
    "Own purchases": "个人购买",
    "Navigation": "导航",
    "Search by wallet": "通过钱包搜索",
    "no user": "没有用户",
    "Wallet address is not valid": "钱包地址无效",
    "Free Spot": "自由点",
    "Search by wallet or username": "通过钱包或者用户名搜索",
    "Info": "信息",
    "Benefits": "奖金",
    "Objectives Completed": "已完成目标",
    "TO DO`s": "待办事项",
    "Purchase NFTs In Total Value Of 100 USDT": "购买总价值100 USDT的NFTs",
    "Purchase Binary Pass - 19 USDT": "购买二进制通行证- 19USDT",
    "Team Turnover": "团队营业额",
    "BASED ON TEAM": "{n}% + 基于团队",
    "Qualification Turnover": "资格营业额",
    "Current Rank": "当前等级",
    "Next Rank": "下一个等级",
    "Binary Spot Reservation": "二进制点预定",
    "Holding Tank Ends In": "贮槽 <b>结果为</b>",
    "With every parcel purchase, you will receive two types of tokens—Gymnet and Bitcoin.": "每次購買包裹，您都會收到兩種類型的代幣——Gymnet 和 Bitcoin。",
    "A minimum of USDT is required for Purchase": "购买至少需要${n}USDT",
    "Become Affiliate Partner": "成为联盟合作伙伴",
    "Placement in Unilevel": "放置在Unilevel",
    "Matching bonuses": "匹配奖金",
    "Placement in Binary": "二进制放置",
    "Product Autoship Incentive": "产品自动订货激励",
    "For limited time only": "仅限限时使用",
    "Free 30 days Product Autoship": "30天免费产品自动订购",
    "Fast Start Incentive": "快速起步激励",
    "Receive Direct Sales Commissions": "获得直销佣金",
    "Please select your role": "请选择您的角色",
    "Choose your role before diving in. Specify why user should choose each role and what opportunities are there.": "在投入之前选择好你的角色。说明为什么用户应该选择每个角色以及该角色有什么机会。",
    "is left to get": "<span>${n}</span> 还有待获得",
    "Go to Products": "转到产品",
    "Rental Income": "租金收入",
    "Left Leg Affiliate Link": "左腿联盟链接",
    "Right Leg Affiliate Link": "右腿联盟链接",
    "Qualification Conditions": "资格条件",
    "Buy Products": "购买产品",
    "My Current Rank": "我的当前等级",
    "To Reach Rank You Need:": "要达到等级 {n} 您需要:",
    "NFT Turnover In Weak Leg": "弱腿的NFT营业额",
    "NFT Own purchases": "NFT个人购买",
    "Required Qualified Partners": "要求的合格合作伙伴",
    "Progress Until Rank": "晋升至等级 {n}",
    "See Details": "查看详情",
    "Default Advantages": "默认优势",
    "Binary Commission": "二进制佣金",
    "Upgrade Your Weekly Cap": "升级您的每周上限",
    "Rank Benefits": "等级福利",
    "For 30 Days ONLY You Receive 10% Binary Commission Instead Of NUM": "在30天里您只收到10%的二进制佣金，而不是{n}%!",
    "My Rank Progress": "我的排名进度",
    "Own Invest": "自有投资",
    "Qualified Partners": "合格的合作伙伴",
    "You need to buy products in value of": "你需要购买产品的价值为${n}",
    "You need to generate turnover in value of in your weak leg.": "你需要在您弱腿上创造价值为${n}的营业额。",
    "Remaining": "剩余",
    "CV Points weak leg": "CV点弱腿",
    "Details": "详情",
    "Partner": "合作伙伴",
    "leg": "腿",
    "Right": "右",
    "Left": "左",
    "You need qualified partner in left leg and qualified partner in right leg to complete this requirement.": "你需要左腿有 {l} 个合格的合作伙伴，右腿有 {r} 个合格的合作伙伴才能完成这项要求。",
    "partners in left leg and in right leg": "{l} {合作伙伴1} 在左腿并且 {r} 个合作伙伴在右腿剩余",
    "partners in left leg remaining": "{l} {合作伙伴1} 在左腿剩余",
    "partners in right leg remaining": "{r} 合作伙伴在右腿剩余",
    "partner in right leg remaining": "{r} 合作伙伴在右腿剩余",
    "Holding Tank is over": "贮槽剩余",
    "Back To Rank Progress Details": "返回等级进度详情",
    "Super Bundle R10 or above": "Super Bundle R10\n 或者超过",
    "Required Monthly Product Autoship": "需要每月产品自动订购",
    "All Customer Benefits": "所有客户福利",
    "Product Autoship": "产品自动订购",
    "Product Autoships": "多个产品自动订购",
    "Products are resellable": "产品可转售",
    "Activate binary commission": "激活二进制佣金",
    "Activate direct sales commission": "激活直销佣金",
    "Left before": "之前的左",
    "Left after": "之后的左",
    "Right before": "之前的右",
    "Right after": "之后的右",
    "Activate your referral link to be able to invite your referrals to the platform and earn additional commissions.": "激活您的推荐链接，可以邀请您的推荐人到平台并赚取额外佣金。",
    "Activate referral link": "激活推荐链接",
    "Yes, I confirm": "是的，我确认",
    "Inactive": "未激活",
    "Finance": "资金",
    "Total Income": "总收入",
    "Products": "产品",
    "GymStreet Map": "GymStreet 地图",
    "Kabutocho Map": "Kabutocho 地图",
    "Don't ask again": "别再问了",
    "Filecoin Balance": "Filecoin 余额",
    "Filecoin": "Filecoin",
    "Total available": "总可用量",
    "Total generated": "总生成量",
    "Total used": "总使用量",
    "Transaction type": "交易类型",
    "To claim your commission, you must have a minimum of 1 USDT or (AMOUNT) GYMNET.": "要申请佣金，您必须至少有1个USDT或{N}个GYMNET。",
    "Loading balance...": "正在加载余额...",
    "Total Commissions": "总佣金",
    "Claimed Commissions": "申请佣金",
    "Available Commissions": "可得佣金",
    "Total Commissions By Source": "按来源列出的佣金总额",
    "Filter by currency": "按货币筛选",
    "No data to display": "没有要显示的数据",
    "Year": "年",
    "Career Status": "职业状况",
    "Current Incentive": "当前激励",
    "Fast Start": "快速启动",
    "Product Autoship incentive": "产品自动订购激励",
    "Direct partner - left leg": "直接合作伙伴 - 左腿",
    "Direct partner - right leg": "直接合作伙伴 - 右腿",
    "Receive direct sales commission": "获得直销佣金",
    "Direct sales commission": "直销佣金",
    "Receive binary commission": "获得二进制佣金",
    "Affiliate ranks": "联盟等级",
    "1 Left, 1 Right": "1 个左, 1 个右",
    "See Benefits": "查看福利",
    "2 Silver (1 in different teams)": "2 个白银 (1 个在不同的团队中)",
    "2 Gold (1 in different teams)": "2 个黄金 (1 个在不同的团队中)",
    "3 Platin (1 in different teams)": "3 个铂金 (1 个在不同的团队中)",
    "3 Double Platin (1 in different teams)": "3 个双铂金 (1 个在不同的团队中)",
    "4 Diamond (1 in different teams)": "4 个钻石 (1 个在不同的团队中)",
    "5 Double Diamond (1 in different teams)": "5 个双钻 (1 个在不同的团队中)",
    "5 Red Diamond (1 in different teams)": "5 个红钻 (1 个在不同的团队中)",
    "5 White Diamond (1 in different teams)": "5 个白钻 (1 个在不同的团队中)",
    "Please note that $100 dollar will correspond to 100 points.": "请注意，100美元相当于100分。",
    "Home": "主页",
    "Last Left": "最后的左",
    "Last Right": "最后的右",
    "Binary leg": "二进制腿",
    "by admin": "通过管理",
    "missed commission": "未得佣金",
    "payment": "支付",
    "commission": "佣金",
    "Payment Currency": "付款货币",
    "BUSD Amount": "BUSD 金额",
    "CV Ratio": "CV 比率",
    "Hrs": "小时",
    "Mins": "分",
    "Secs": "秒",
    "Please enter a valid referral ID": "请输入有效的推荐人ID",
    "Commission TXID": "佣金TXID",
    "Qualification turnover": "资格营业额",
    "Binary commission": "二进制佣金",
    "Additional Advantages": "附加优势",
    "The referral ID you've entered is incorrect. Please copy and paste the correct referral link to proceed.": "您输入的推荐ID不正确。请复制并粘贴正确的推荐链接以继续。",
    "In order to receive binary commissions you need to have an active product autoship.": "要获得二进制佣金，您需要拥有一个活跃的产品自动订单。",
    "In order to receive binary commissions you need to have an active product autoship and a purchase for at least $100.": "為了獲得二元佣金，您需要擁有有效的產品自動發貨並且購買金額至少為 100 美元。",
    "To qualify for binary commissions, a minimum product purchase of $100 is required.": "要獲得二元佣金資格，至少需要購買 100 美元的產品。",
    "Transaction in Process": "交易進行中",
    "Claim your parcel": "領取您的包裹",
    "You do not have any claimed slots to attach miners. To enable miner attachment, please claim parcels on the map.": "您沒有任何已聲明的​​插槽來連接礦工。 若要啟用礦工附件，請在地圖上領取包裹。",
    "Active binary commission": "活躍的二元佣金",
    "Active direct sales commission": "積極的直銷佣金",
    "Own purchase min {AMOUNT}": "自己購買至少 {AMOUNT}",
    "Active product autoship": "活躍產品自動出貨",
    "Right partner own purchase min {AMOUNT}": "合適的合作夥伴自己購買至少 {AMOUNT}",
    "Left partner own purchase min {AMOUNT}": "剩下的合作夥伴自己購買的商品至少為 {AMOUNT}",
    "Unfortunately, you won't receive the bonus for this rank since you met the qualification conditions (more than 50%) before September 18, 2023": "遗憾的是，由于您在 2023 年 9 月 18 日之前满足资格条件（超过 50%），因此您将无法获得该级别的奖金",
    "You will be eligible for cash bonus based on your rank if you meet all qualification conditions between September 18 and November 8.Those who met the qualification conditions (more than 50%) before September 18, 2023 will not receive the Super Cash Bonus.": "如果您在9月18日至1月31日期间满足所有资格条件，您将有资格根据您的排名获得现金奖励。2023年9月18日之前满足资格条件（50％以上）的人将不会获得超级现金奖励。",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / USDT": "当一栋新的商业大楼正在实施时，您可以选择用GYMNET/USDT付款购买土地",
    "yearly": "每年",
    "Unknown": "未知",
    "Claim processing may take up to 2 minutes. Thank you for your patience.": "申领处理可能需要长达2分钟的时间。感谢您的耐心。",
    "1 Person": "1个人",
    "2 Persons": "2个人",
    "Extended Referral Link": "扩展推荐链接",
    "Enter a username": "输入用户名",
    "Possible only for usernames in your binary team!": "仅适用于二进制团队中的用户名！",
    "Select Team": "选择团队",
    "Create Link": "创建链接",
    "New users will be placed under in the team": "新用户将被置于｛p｝团队中的｛n｝下",
    "Invalid username!": "用户名无效!",
    "Left team": "左队",
    "Right team": "右队",
    "Distribution In Progress": "正在分配",
    "Next Binary Bonus Distribution In": "下一个二进制奖金分布",
    "Matching Bonus Commissions": "匹配奖金佣金",
    "Binary Commissions": "二进制佣金",
    "Binary Weekly Cap": "二进制每周最高限额",
    "Binary Cycles": "二进制周期",
    "Matching Bonus": "匹配奖金",
    "Incomes from Product Autoships": "收入来自产品自动订货",
    "Missed Commissions": "错过的佣金",
    "Plus Max Weekly Payout": "加上最高每周支出",
    "Import Token With Address": "导入带地址的代币",
    "Import Supported Token": "导入支持的代币",
    "Shop": "购物",
    "Filter by": "筛选方式",
    "My Purchases": "我的购买",
    "Purchases for other members": "为其他成员购买",
    "Product Autoship Purchases": "产品自动购买",
    "Sort By": "排序方式",
    "Top up": "充值",
    "Attach your miners": "连接您的矿工",
    "Buy parcels": "购买地块",
    "Number of parcels you want to buy": "要购买的地块数量",
    "Use utility balance": "使用utility餘額",
    "My Products": "我的产品",
    "USDT Amount": "USDT 数额",
    "Next Matching Bonus Distribution In": "下一个匹配奖金分配",
    "Please top up your wallet with AMOUNT_CURRENCY USDT for upgrade.": "请用｛AMOUNT_CURRENCY｝USDT充值升级。",
    "Please note that worth of USDT is required to complete the transaction.": "请注意，完成交易需要价值｛n｝美元的USDT。",
    "Incentive Ends In:": "激励的结果是:",
    "Bonus History": "奖金历史记录",
    "Incentive has ended": "激励已结束",
    "Direct Sales Turnover": "直销营业额",
    "Commissions are paid out following a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "佣金按 70/30 的比例支付，其中 70% 为稳定币，30% 为 GYMNET 代币。",
    "(effective as of 28.02.2024)": "(自 2024 年 2 月 28 日起生效）",
    "Free Product Autoship": "免费产品自动发货",
    "10% Binary Commission": "10% 二元佣金",
    "Enjoy More, Benefit More!": "享受更多，受益更多！",
    "To qualify for Binary Commissions, you must have a new member with at least $100 on your right team and another on your left team.": "要获得二元佣金资格，您的右队必须有一名新成员，且左队必须有一名至少 100 美元的新成员。",
    "Buy products for at least $100 within 30 days to receive these benefits.": "在 <span>30 天内</span>购买至少 <span>100 美元</span>的产品即可获得这些福利。",
    "Getting started": "入门",
    "Confirm your email to continue": "确认您的电子邮件以继续",
    "Resend confirmation email": "重发确认邮件",
    "Super Bundle Special Discount Expires In": "超级捆绑特别折扣到期日",
    "Done": "完毕",
    "Get your bundle": "获取您的捆绑包",
    "Gain Fresh Perspectives and Exclusive Benefits": "获得新的视角和独特的好处",
    "Super Bundle R8": "超级捆绑包 R8",
    "Super Bundle R17": "超级捆绑包 R17",
    "Starter Bundle": "入门套件",
    "Travel": "旅游促销",
    "Cash Promo": "现金促销",
    "Monthly Product Autoship is required to be an Affiliate Partner and earn commissions.": "每月产品自动发货需要成为联属合作伙伴并赚取佣金。",
    "Affiliate": "附属机构",
    "Recommended": "受到推崇的",
    "Binary Commissions based on the rank": "基于排名的二元佣金",
    "Customer": "顾客",
    "NFT Turbo": "NFT 涡轮增压",
    "Purchase Digital Land": "购买数字土地",
    "Participate in Partnership Deals": "参与合作伙伴交易",
    "Receive minting Rewards": "获得铸币奖励",
    "Up to": "高达 {n}%",
    "Weekly Distribution": "每周分配",
    "A parcel with minting power will generate rewards in Filecoin tokens, which will be distributed every Monday.": "具有鑄幣能力的包裹將產生 Filecoin 代幣獎勵，每週一發放。",
    "It seems that a some request is already pending. Please check your wallet app or extension and try again.": "似乎某些請求已經等待處理。 請檢查您的錢包應用程式或擴充程序，然後重試。",
    "Add miner NFT to wallet": "將 Miner NFT 加入錢包",
    "Go to My NFTs": "前往我的 NFT",
    "Thank you for your participation": "感谢您的参与",
    "No owner": "沒有主人",
    "Ownership of this bundle is limited to one per user. Please purchase for yourself and for those who do not have it.": "此捆綁包的所有權僅限於每個使用者一個。 請為自己和沒有的人購買。",
    "Buy parcels with minting power and generate income.": "購買具有鑄幣能力的包裹並產生收入。",
    // Mystery box
    "Limited time offer": "限时优惠",
    "See Options": "查看选项",
    "Please select one of the currencies": "请选择其中一种货币",
    "Current balance": "当前余额",
    "Try your luck": "试试运气",
    "Offer ends in": "优惠截止日期",
    "Parcel and Dual Miner": "地块和双未成年人",
    "Mystery Box": "神秘盒子",
    "Try your luck and win incredible Bundles, Parcels and Dual Miners to boost your earnings!": "試試你的運氣，贏得令人難以置信的捆綁包、包裹和雙礦工，以增加你的收入！",
    "from MIN to MAX": "從 <b>{min}</b> 到 <b>{max}</b>",
    "Choose a Box and Discover Rewards": "選擇一個盒子並發現獎勵",
    "Prizes you can win": "您可以赢得的奖品",
    "Item": "物品",
    "Chance": "機會",
    "Promos": "促銷",
    "Mystery Box results": "神秘盒結果",
    "Total reward value": "總獎勵值",
    "My Rewards": "我的獎勵",
    "Rewards Community Claimed": "社區領取的獎勵",
    "Hero image": "英雄形象",
    "No rewards yet": "還沒有獎勵",
    "No winners yet": "還沒有獲獎者",
    "While no lucky participants have claimed their prizes yet, the excitement continues to build.": "雖然尚未有幸運參與者領取獎品，但興奮之情仍在持續。",
    "Mystery Box is Here!": "神秘盒子來了！",
    "Be the First to Discover Mystery Rewards": "成為第一個發現神秘獎勵的人",
    "Select Balance": "選擇餘額",
    "Current Balance": "當前餘額",
    "Opening Mystery Box": "打开神秘盒",
    "Oops, not this time 😔": "哎呀，這次不行了😔",
    "Luck is on your side. Enjoy your prize!": "幸运就在你身边。尽情享受你的奖品吧",
    "The more you try, the better your chances of winning. Keep going!": "您嘗試的越多，獲勝的機會就越大。 繼續前進！",
    "Try another box": "嘗試另一個盒子",
    "Go to My Products": "前往我的產品",
    "See promo results": "查看促销结果",
    "Payment enabled": "已啟用付款",
    "Please select the wallet that is attached to your account.": "請選擇附加到您帳號的錢包。",
    "Explore rewards": "探索奖励",
    "View rewards claimed by you and the community": "查看您和社区领取的奖励",
    "Open a Mystery Box to get rewards starting from Parcels to Super bundles.": "打开神秘箱，获得从包裹到超级礼包的各种奖励。",
    "Insufficient funds, please choose another box.": "资金不足，请选择其他方框。",
    "Blockchain error": "区块链错误",
    "Rewards claimed": "申请的奖励",
    "We have encountered difficulties while processing the result of the reward. Please contact our support team for prompt assistance.": "我们在处理奖励结果时遇到困难。请联系我们的支持团队，以便及时获得帮助。",
    "Boxes opened": "打开的箱子",
    // Mystery box

    // Partnership details
    "About": "关于",
    "Offer": "提供服务",
    "Social Media": "社交媒体",
    "Additional Content": "其他内容",
    "Photo": "照片",
    "Video": "视频",
    // Partnership details

    // Miner Rewards
    "Single Miners": "单身矿工",
    "price today": "今天的價格",
    "Estimated daily rewards": "預計每日獎勵",
    "day": "天",
    "day at current rate": "以目前匯率計算的天數",
    "Estimated rewards until 2025": "預計獎勵到 2025 年",
    "at current rate": "按目前的速度",
    "Estimated rewards from today to December 1, 2025. It may change based on your shares in the pool.": "从今天到 2025 年 12 月 1 日的估计奖励。可能会根据您在奖池中的份额而有所变化。",
    "Expected rewards calculator": "預期獎勵計算器",
    "price": "價格",
    "My total GYMNET value in USD": "我的 GYMNET 總價值（美元）",
    "claimed": "聲稱",
    "My Balances": "我的余额",
    "Tokens to be burned": "焚烧的代币",
    "You dont have any Claimed or Pending GYMNET rewards.": "您没有任何已领取或待处理的 GYMNET 奖励。",
    "Claim now": "现在宣称",
    "No claimed GYMNET rewards are found.": "未找到已领取的 GYMNET 奖励。",
    // Miner Rewards

    "Please note that the leg with the lesser amount of points is called weak leg and the one with the higher amount of points is called strong or power leg, and it can change over time based on which leg the most turnover is generated.": "请注意，积分较少的腿被称为“弱腿”，而点数较高的腿被称为“强腿”或“强腿”，并且它会随着时间的推移而变化，具体取决于哪条腿的成交量最大 被生成。",
    "Mystery Box price": "神秘盒价格",

    "Registration Date": "注册日期",
    "First Purchase Date": "首次购买日期",
    "Product Autoship Valid Until": "产品自动发货有效期至",

    "Own Deposit": "自有存款",
    "years ago": "年前",
    "Rank Achievement Bonus": "排名成就獎金",
    "Rank achievement bonus eligibility is based on rank achieved through qualifications.": "排名成就獎金資格取決於通過資格獲得的排名。",
    "Bonuses": "奖金",
    "Bonuses are paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET tokens": "奖金按照 70/30 的比例支付，其中 70% 为 USDT，30% 为 GYMNET 代币",
    "Rank current": "等级 {n}",
    "You will receive the bonus after the incentive is over, and you can view it in the 'My Wallet' section.": "奖金结束后，您将收到奖金，您可以在 \"我的钱包 \"部分查看奖金。",
    "You must have at least RANK partners, with at least one in a different leg.": "您必须至少有 {rank} 个合作伙伴，其中至少有一个在不同的赛段。",
    "Additional Assets": "其他资产",
    "Back to My wallet": "返回我的钱包",
    "Bonus points": "奖励积分",
    "Fee": "费用",
    "Bonus Details": "奖励详情",
    "Details / weekly cap": "详情/每周上限",
    "User / amount": "用户/金额",
    "Extended Ref Link": "扩展参考链接",
    "Leadership": "领导层",
    "Travel Incentive": "旅行奖励",
    "Unlock the full potential of your Filecoin rewards as they come to life following your purchase of any of our special bundles. Once your Filecoin Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "当您购买我们的任何特殊捆绑包后，您的 Filecoin 奖励就会生效，充分释放您的潜力。一旦您的 Filecoin 余额可用，您就可以获得总奖励、已获得奖励和预期奖励的概览。此外，一个倒计时器会显示下一次奖励分配，确保您不会错过奖励。",
    "is left to qualify": "${n} 尚待鉴定",
    "Check transactions": "支票交易",
    "Direct partner own purchases": "直接合作伙伴自己购买",
    "Each of your direct partners on the left and right legs needs to generate a turnover value of $100.00.": "您左右两边的每个直接合作伙伴都需要产生 100.00 美元的营业额。",
    // Feature Prioritization
    "Participation to GYMSTREETs Elite Vote": "参与 GYMSTREET 的精英投票",
    "Prioritization ends in": "优先排序结束于",
    "Start Prioritizing": "开始排序",
    "Your changes have been saved": "您的更改已保存",
    "features are still remaining": "<b>{n}功能</b>仍然存在",
    "feature is still remaining": "<b>{n}功能</b>仍然存在",
    "All prioritized": "所有优先级",
    "GYMSTREETs Elite Vote Statistics": "GYMSTREET 精英投票統計",
    "Just NUM features to go! Complete your prioritization to help us shape community's vision.": "只剩 <b>{countText}</b>！完成您的优先排序，帮助我们塑造社区愿景。",
    "You've prioritized the features. Please review your choices and hit Submit to finalize.": "您已經確定了功能的優先順序。 請檢查您的選擇並點擊<b>“提交”</b>以完成。",
    "Back to All Features": "返回所有功能",
    "Changes saved": "已保存更改",
    "No changes": "无更改",
    "Submit": "提交",
    "priority": "优先级",
    "Set priority": "设置优先级",
    "Feature details": "功能详情",
    "Select a feature to see more info": "选择功能查看更多信息",
    "Prioritization Submitted": "提交优先级",
    "The results will be available after the voting period ends. You'll be able to change your priorities until then.": "投票结果将在投票期结束后公布。在此之前，您可以更改您的优先级。",
    "Go to Career Status page": "转到职业状况页面",
    "Keep Prioritizing": "继续确定优先次序",
    "Continue Prioritizing": "继续排定优先顺序",
    "Review and Submit": "审核并提交",
    "Thinking of reprioritizing?": "考虑重新确定优先次序？",
    "You've already submitted your priorities. If you want to reprioritize them, please make sure you hit Resubmit.": "您已經提交了您的優先事項。 如果您想重新調整它們的優先級，請確保點擊<b>「重新提交」</b>。",
    "Got it": "收到",
    "Resubmit": "重新提交",
    "Priorities submitted": "已提交优先事项",
    "Submitted": "已提交",
    "View Statistics": "查看统计数据",
    "Continue Prioritization": "继续确定优先次序",
    "Reprioritize": "重新确定优先次序",
    "Start Prioritization": "开始优先排序",
    "features": "特征",
    "feature": "功能",
    "GYMSTREET Elite Vote": "GYMSTREET 的精英投票",
    "Thanks for submitting! You can still reprioritize and resubmit your choices until the voting period ends.": "感谢您的提交！在投票结束前，您仍可重新排定优先顺序并重新提交您的选择。",
    "Youre not done yet. Continue prioritizing the upcoming features and submit your final choices.": "您还没有完成。继续对即将推出的功能进行优先排序，并提交您的最终选择。",
    "Exclusive for Platin and higher rank members. Prioritize the features you want to see next on GYMSTREET.": "铂金及以上级别会员专享。优先选择您希望在 GYMSTREET 上看到的下一个功能。",
    "View rules": "查看规则",
    "Prioritization has ended. See the community's vote results.": "优先排序已结束。查看社区投票结果。",
    "Stay tuned": "敬请期待",
    "See current community prioritization statistics below. In 7 days, we'll also announce our decision right here, revealing the selected new features and their estimated release dates.": "请参阅下面的当前社区优先级统计。7 天后，我们还将在此公布我们的决定，揭示选定的新功能及其预计发布日期。",
    "Terms and Conditions for GYMSTREETs Elite Vote": "GYMSTREETs 精英投票的条款和条件",
    // Suspicous activity
    "You have one attempt left": "您还有一次尝试机会。之后，您对该账户的尝试将被暂时限制。",
    "You have no attempts left": "您的帐户已没有尝试次数。请在 {minutesText} <b>分钟</b>内重试或重设密码。",
    "Sorry, you’ve been blocked": "죄송합니다, 차단되었습니다.",
    "Your IP address has been temporarily blocked for security reasons.": "보안상의 이유로 IP 주소가 일시적으로 차단되었습니다. 잠시만 기다려 주세요. 곧 자동으로 리디렉션됩니다.",
    "If you believe this was a mistake, please contact us through our Customer Support.": "如果您认为这是一个错误，请通过我们的 <a href=\"mailto:support@gymstreet.io?subject=Suspicious Activity&body=I am blocked due to suspicious activity, but I think It's a mistake\" class=\"客户支持按钮\">客户支持与我们联系。</a>",
    // Instant commissions
    "The period for earning mining rewards in BITOPEX tokens has officially concluded. Thanks for your participation. Enjoy your BPX earnings.": "赚取BITOPEX代币挖矿奖励的时期已正式结束。 感谢您的参与。 享受您的 BPX 收入。",
    "We're enhancing our claim feature, so it will be offline, for the next {hours} hours. Thank you and stay tuned!": "我们正在增强索赔功能，因此在接下来的 {hours} 个小时内，索赔功能将处于离线状态。感谢您的关注！",
    "Eligibility for a one-time 10% commission, based on the total turnover generated from direct sales, requires referring team members and achieving a turnover of $5,000 or more in direct sales.": "根据直销产生的总营业额计算，获得一次性 10% 佣金的资格要求推荐团队成员并在直销中实现 5,000 美元或以上的营业额。",

    "Turnover Incentive": "营业额激励",
    "Available For Limited time only": "仅限<b>限时</b>使用",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving $20,000 in direct sales.": "请注意，直接销售达到 20,000 美元后，您就有资格获得免费的 R8 捆绑包。",

    "Minting Rewards": "铸造奖励",
    "Please check transactions if your {asset} balance is not visible": "如果您的{asset}余额不可见，请检查交易",
    "In order to send {asset} you need to select {chain} network and wallet that is connected to your account.": "为了发送{asset}，您需要选择与您的帐户连接的{chain}网络和钱包。",
    "Cash bonus eligibility is based on rank achieved through qualifications. Participants qualifying with over 50% before March 4th, 2024, will not receive the bonus.": "現金獎金資格取決於通過資格獲得的排名。 2024 年 3 月 4 日之前合格率超過 50% 的參與者將不會獲得獎金。",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving 100k in team purchases.": "请注意，团队购买达到 100k 时，您就有资格获得免费的 R8 捆绑包。",

    "Convert": "转换",
    "Convert your unused miners into hashpower to earn rewards in Gymnet and Filecoin": "将您未使用的矿工转换为哈希值，赚取 Gymnet 和 Filecoin 奖励",
    "The 50% Turnover Rule is applied to this incentive": "本次激励适用 50% 换手率规则",

    "Super Bundle Rank Benefits": "超級捆綁排名福利",
    "Matching bonus up to NUM levels": "匹配獎金最高可達 {num} 級",
    "Up to NUM levels": "最多 {num} 級",
    "Left leg partner": "左腿搭檔",
    "Right leg partner": "右腿搭檔",
    "Commission Activation Requirements": "佣金啟動要求",
    "Up to 1 level": "最多 1 級",
    "Bonus is paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET": "奖金按照70/30的比例发放，其中70%为USDT，30%为GYMNET",
    "Current Turnover": "当前成交额",
    "You will receive the bonus once the incentive period is over and it will be deposited directly into your wallet.": "奖励期结束后您将收到奖金，并将其直接存入您的钱包",
    "Purchase Amount": "采购量",
    "Spring": "春天",
    "Spring Incentive": "春季奖励",
    "Commission Earned": "赚取的佣金：<b>${n}</b>",
    "Bonus Earned": "獲得的獎金：<b>${n}</b>",
    "Earn 1 PAP point for every dollar spent on purchases using a utility balance or when you purchase a product autoship.": "使用公用事業餘額進行購買或購買產品自動出貨時，每花費 1 美元即可賺取 1 PAP 點數。",
    "Member": "Affiliate",
    "Trainee": "Trainee",
    "Rookie": "Rookie",
    "Silver": "Silver",
    "Gold": "Gold",
    "Platinum": "Platinum",
    "Double Platinum": "Double Platinum",
    "Diamond": "Diamond",
    "Double Diamond": "Double Diamond",
    "Red Diamond": "Red Diamond",
    "White Diamond": "White Diamond",
    "Crown Diamond": "Crown Diamond",
    "Platin": "Platinum",
    "Double Platin": "Double Platinum",
    "Single Pool": "Single Pool",
    "Explorer": "Explorer",
    "Mastery": "Mastery",
    "Features expected timeframes revealed": "預計時間範圍已揭曉",
    "Find estimated timelines for features prioritized by our community and aligned with our strategy.": "尋找我們社區優先考慮並與我們的策略保持一致的功能的預計時間表。",
    "Estimated timeframe": "預計時間",
    "Undefined": "不明確的",
    "No Longer Valid": "不再有效",
    "Deposit to Single pool": "存入單池",
    "Max": "最大限度",
    "Received": "已收到",
    "About voting rights": "關於投票權",
    "Your first deposit must be over $25": "您的第一筆存款必須超過 25 美元",
    "Staking period ends": "質押期結束",
    "December": "十二月",
    "Deposit & Stake": "存款和質押",
    "Purchasing the card requires passing the KYC procedure.": "購買卡需要通過KYC程序。",
    "Continue to KYC": "繼續了解您的客戶",
    "KYC Verification": "KYC 驗證",
    "Step": "步驟{n}",
    "Upload ID": "上傳身分證件",
    "Male": "男性",
    "Female": "女性",
    "Gender": "性別",
    "ID Type is required": "ID 類型為必填項",
    "ID Document Language is required": "身分證件語言為必填項",
    "ID Expiration Date is required": "身分證件效期為必填項",
    "ID Issue Date is required": "身分證件簽發日期為必填項",
    "State must be at least 3 characters": "狀態必須至少為 3 個字符",
    "ID Type": "身分證類型",
    "National Identity Card": "國民身分證",
    "Driver's License": "駕照",
    "Passport": "護照",
    "ID Document Language": "身分證件語言",
    "ID Number be at least 3 characters": "ID 號碼至少為 3 個字符",
    "ID Issue Date": "身分證件簽發日期",
    "ID Expiration Date": "身分證件有效期限",
    "You need to upload a photo of your document Please upload a png or jpeg, not a pdf.": "您需要上傳文件的照片\n請上傳 <span style='color: #1A6C99'>png 或 jpeg，而不是 pdf</span>。",
    "File size exceeds {n} MB.": "檔案大小超過 {n} MB。",
    "Front side of ID": "身分證正面",
    "Back side of ID": "身分證背面",
    "Upload proof of address document": "上傳地址證明文件",
    "Upload proof of address, which must not be older than 75 days.": "上傳地址證明，該地址不得超過 75 天。",
    "Acceptable forms of proof of address include a credit card statement, utility bill, bank statement or bank letter.": "可接受的地址證明形式包括信用卡對帳單、公用事業帳單、銀行對帳單或銀行信函。",
    "Address Document Type is required": "地址 文件類型 為必填項",
    "Document Issued by is required": "需要出具的文件",
    "Document Issued Date is required": "文件簽發日期為必填項",
    "Address Document Type": "地址文件類型",
    "Credit Card Statement": "信用卡帳單",
    "Utility Bill": "水電費帳單",
    "Bank Statement": "銀行對帳單",
    "Bank Letter": "銀行信函",
    "Document Issued by": "文件簽發者",
    "Document Issued Date": "文件簽發日期",
    "Please upload a legible document in png or jpeg format.": "請上傳清晰的檔案 <span style='color: #1A6C99'>png 或 jpeg 格式。",
    "Submit for Verification": "提交驗證",
    "Your documents have been submitted for verification. Once verification has been approved, you can continue your purchase.": "您的文件已提交以供驗證。 驗證通過後，您可以繼續購買。",
    "You need more GYMNET to make a deposit in Single Pool": "您還需要 <b>{n} 個 GYMNET</b> 才能在 Single Pool 中存款",
    "You need more USDT to purchase a bundle": "您還需要 <b>{n} 個 USDT</b> 才能購買捆綁包",
    "Security Assurance": "安全\n保證",
    "Global Accessibility": "全域\n輔助功能",
    "Affiliate Earnings": "聯營公司\n收入",
    "Effortless Integration": "輕鬆\n集成",
    "Cards": "牌",
    "Overview": "概述",
    "Information": "資訊",
    "Invoices": "發票",
    "KYC Status": "KYC 狀態",
    "Try again": "再試一次",
    "Apply here": "在此申請",
    "Not verified": "未經審核的",
    "Pending verification": "待驗證",
    "Rejected": "拒絕",
    "Verified": "已驗證",
    "Next service fee in": "下次服務費在",
    "No active membership": "無活躍會員",
    "Card": "卡片",
    "Card number": "卡號",
    "PIN Set Successfully": "PIN 設定成功",
    "Go to Dashboard": "轉到儀表板",
    "4-digit PIN": "4 位數 PIN 碼",
    "Re-enter 4-digit PIN": "重新輸入 4 位數 PIN 碼",
    "Set PIN": "設定密碼",
    "Your account is now verified and you can continue your purchase of the bundle.": "您的帳戶現已驗證，您可以繼續購買捆綁包。",
    "Continue purchase": "繼續購買",
    "Your documents have been rejected from KYC provider.": "您的文件已被 KYC 提供者拒絕。",
    "Please try again": "請再試一次",
    "Your KYC is approved": "您的 KYC 已獲批准",
    "Your KYC is rejected": "您的 KYC 被拒絕",
    "File is required": "需要文件",
    "Seamless Spending with GYMSTREET Cards": "使用 GYMSTREET 卡無縫消費",
    "Our cards open a world of smooth transactions and rewarding opportunities for you.": "我們的卡片為您打開了一個順暢交易和獎勵機會的世界。",
    "Card Types Tailored to Your Needs": "根據您的需求量身訂做的卡片類型",
    "Card design": "卡片設計",
    "Card information": "卡片資訊",
    "Your go-to choice for daily transactions and affiliate commissions, offering practical benefits and reliable service within GYMSTREET and beyond.": "您日常交易和聯盟佣金的首選，在 GYMSTREET 內外提供實際的好處和可靠的服務。",
    "Elevate your financial experience with this premium option with expanded benefits, designed for those who seek luxury and exclusivity.": "此高級選項專為追求奢華和獨特的人士而設計，提供更多福利，提升您的財務體驗。",
    "Cards come in Bundles": "卡片以捆綁形式提供",
    "Explorer card and 2 parcels": "Explorer卡片和2個包裹",
    "Mastery card and 3 parcels": "Mastery卡和3個包裹",
    "Getting Started with Your GYMSTREET Card Experience": "開始您的 GYMSTREET 卡體驗",
    "Start securely with our simple KYC process, ensuring a safe and personalized Card experience.": "透過我們簡單的 KYC 流程安全地開始，確保安全和個人化的卡片體驗。",
    "Deposit Tokens": "存款代幣",
    "Stake the required GYMNET tokens - 500 for Explorer or 1500 for Mastery - to initiate your application.": "質押所需的 GYMNET 代幣 - Explorer 為 500 代幣，Mastery 為 1500 代幣 - 來啟動您的應用程式。",
    "Order Your Card": "訂購您的卡",
    "Make your final decision on card type and place your order buying one of the 2 card bundles.": "對卡片類型做出最終決定，然後下訂單購買 2 個卡包之一。",
    "Get Your Card": "取得您的卡",
    "Anticipate the arrival of your new financial companion, securely shipped and ready to integrate into your life.": "期待您的新財務伴侶的到來，安全運輸並準備好融入您的生活。",
    "Use Your Card": "使用您的卡",
    "Begin making purchases with your card and start earning affiliate commissions and more.": "開始使用您的卡片進行購物並開始賺取聯盟佣金等。",
    "Card Operation Fees": "卡操作費",
    "Fee Type": "費用類型",
    "Both Cards (Explorer & Mastery)": "兩張卡（Explorer 和 Mastery）",
    "POS Transactions": "POS 交易",
    "ATM Withdrawals": "自動櫃員機提款",
    "Foreign Currency Conversion": "外幣兌換",
    "Card Replacement": "補卡",
    "Simple, Transparent Pricing": "簡單、透明的定價",
    "Monthly Membership Fee": "每月會員費",
    "Monthly Membership": "每月會員資格",
    "Get Your Card Now": "立即取得您的卡",
    "Choose the card that best suits your lifestyle. With straightforward fees, a seamless onboarding process, and an 11-level deep affiliate commission reward system, these cards are your partner in financial empowerment.": "選擇最適合您生活方式的卡片。 這些卡具有簡單的費用、無縫的入職流程和 11 級深度會員佣金獎勵系統，是您的財務授權合作夥伴。",
    "How do I get a card?": "我如何獲得卡片？",
    "Start by filling out the KYC form on our site. After we verify you, you'll need to deposit tokens for the card you want in Single Pool: 500 for Explorer or 1500 for Mastery. Then, you can order your card.": "首先在我們的網站上填寫 KYC 表格。 在我們驗證您的身分後，您需要在 Single Pool 中存入您想要的卡片的代幣：Explorer 為 500，Mastery 為 1500。 然後，您可以訂購您的卡。",
    "How many tokens do I need to deposit for each card?": "每張卡片需要存入多少代幣？",
    "You need 500 tokens for the Explorer card and 1500 tokens for the Mastery card.": "Explorer 卡需要 500 個代幣，Mastery 卡需要 1500 個代幣。",
    "When will my card arrive?": "我的卡什麼時候會到達？",
    "After you order, your card will be sent out and should arrive quickly, so you can start using it soon.": "訂購後，您的卡將被寄出，並且應該很快就會到達，以便您可以盡快開始使用。",
    "Can I earn affiliate commissions with my card?": "我可以用我的卡片賺取聯屬佣金嗎？",
    "Yes, activate your card, and you can begin earning through our 11-level deep affiliate program. Check our website for how the commissions work.": "是的，啟動您的卡，您就可以開始透過我們的 11 級深度聯盟計畫賺取收入。 請查看我們的網站以了解佣金的運作方式。",
    "Total Value At Time of Deposit": "存款時總價值",
    "Current Value": "目前值",
    "My claimed GYMNET rewards": "我領取的 GYMNET 獎勵",
    "My pending GYMNET rewards": "我待處理的 GYMNET 獎勵",
    "Deposit": "訂金",
    "Connected": "連接的",
    "No deposit fee for burn": "銷毀無押金",
    "Min amount": "最低金額",
    "Total GYMNET locked": "GYMNET 鎖定總數",
    "APR": "年利率",
    "Daily APR": "每日年利率",
    "Total own claimed": "自有索賠總額",
    "Reward per block": "每塊獎勵",
    "My Deposit": "我的存款",
    "Locked period until Dec 2027": "鎖定期至 2027 年 12 月",
    "With Locking Period": "有鎖定期",
    "Total Gymnet Deposit": "體操網存款總額",
    "Gymnet Rewards": "體操網獎勵",
    "Total Gymnet earned": "體操網總收入",
    "Active holdings": "活躍持股",
    "With locking period": "有鎖定期",
    "Withdrawn holdings": "撤回持股",
    "Locked": "鎖定",
    "Unlocked": "解鎖",
    "Deposit amount": "存款金額",
    "Claimed rewards": "領取獎勵",
    "Start date": "開始日期",
    "End date": "結束日期",
    "Withdraw": "提取",
    "First name": "名",
    "Last name": "姓",
    "Address": "地址",
    "State": "狀態",
    "Phone number": "電話號碼",
    "Birth Date": "出生日期",
    "Submit & Continue": "提交並繼續",
    "ID Number": "身分證號",
    "ID Issued By": "身分證件簽發者",
    "Proof of address document": "地址證明文件",
    "Proof of address document (optional)": "地址證明文件（可選）",
    "Statistics": "統計數據",
    "Personal Information": "個人資訊",
    "First name is required": "名字為必填項",
    "Last name is required": "姓氏為必填項",
    "Address is required": "地址為必填項",
    "Please enter your email address": "請輸入您的電子郵件地址",
    "Phone number is required": "電話號碼為必填項",
    "Date of birth is required": "出生日期為必填項",
    "ID Number is required": "身分證號碼為必填項",
    "ID Issued by is required": "需要出具的身份證件",
    "Select asset to auto swap and stake": "選擇要自動交換和質押的資產",
    "Giving swap allowance": "給予掉期津貼",
    "Swap allowance given": "給予掉期津貼",
    "Swapping tokens to GYMNET": "將代幣交換到 GYMNET",
    "Tokens swapped to GYMNET": "代幣交換至 GYMNET",
    "Giving stake allowance": "給予股份津貼",
    "Stake allowance given": "給予股權津貼",
    "Staking to single pool": "質押到單一礦池",
    "Staked to single pool": "質押到單一礦池",
    "1. Swap allowance (Only for USDT)": "1. 隔夜利息（僅限USDT）",
    "2. Swap to GYMNET": "2. 兌換至GYMNET",
    "3. Stake allowance": "3. 股權津貼",
    "4. Stake": "4. 股份",
    "Actual balance:": "實質餘額：",
    "The transaction gas fee is reserved from the actual BNB balance.": "交易气体费从 BNB 实际余额中扣除。",
    "Insufficient balance to make a swap": "餘額不足，無法進行兌換",
    "Transactions gas fee is considered in BNB available balance": "交易gas費用計入BNB可用餘額",
    "We will ask for allowance and try to swap your tokens to GYMNET, and after, stake them in single pool.": "我們將請求津貼並嘗試將您的代幣交換到 GYMNET，然後將它們放入單一池中。",
    "Auto swap and stake": "自動交換和質押",
    "Continue transactions": "繼續交易",
    "Pause": "暫停",
    "Swap & Stake": "交換和質押",
    "Topup": "儲值",
    "Proceed anyways": "無論如何都要繼續",
    "Explorer Card": "Explorer卡",
    "Mastery Card": "Mastery卡",
    "Monthly spending max": "每月最高支出",
    "Monthly Balance limit": "每月余额限额",
    "Go to Cards": "前往卡片",
    "Contact Support": "联系支持人员",
    "Deposit Fee": "存款费",
    "Switch to 'Utility' to view the price for each product with USDT & Utility. To partially pay for a product using Utility, you need to have enough USDT and Utility in your balance.": "切換到「Utility」以查看每種產品的 USDT 和 Utility 價格。 要使用 Utility 支付產品的部分費用，您的餘額中需要有足夠的 USDT 和 Utility。",
    "Deposit with locking period": "存款有锁定期",
    "The state must be in the form of a two-letter abbreviation in uppercase.": "状态必须采用大写的两个字母缩写形式。",
    "You need to upload a photo in JPEG or PNG format, not a PDF. The document can be in either English or Spanish.": "您需要上传 <span style='color: #1A6C99'>jpeg</span> 或 <span style='color: #1A6C99'>png</span> 格式的照片，而不是 pdf。 \n文档可以是<span style='color: #1A6C99'>英语</span>或<span style='color: #1A6C99'>西班牙语</span>。",
    "You need to upload a legible document in jpeg or png format, not a pdf. The document can be in either English or Spanish.": "您需要上传 <span style='color: #1A6C99'>jpeg</span> 或 <span style='color: #1A6C99'>png</span> 格式的清晰文档，而不是 pdf。 \n文档可以是<span style='color: #1A6C99'>英语</span>或<span style='color: #1A6C99'>西班牙语</span>。",
    "Commissions are paid out using a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "佣金按 70/30 的比例支付，其中 70% 为稳定币，30% 为 GYMNET 代币。",
    "This policy is effective as of September 25, 2023.": "本政策自2023年9月25日起施行。",
    "Required turnover": "所需营业额",
    "Switch to TOKEN to view the price for each product with USDT & TOKEN. To partially pay for a product using TOKEN, you need to have enough USDT and TOKEN in your balance.": "切換到「{TOKEN}」以查看每種產品的 USDT 和 {TOKEN} 價格。 要使用 {TOKEN} 支付產品的部分費用，您的餘額中需要有足夠的 USDT 和 {TOKEN}。",
    "Alternatively, you can use your CURRENCY balance to cover the gas fee.": "或者，您可以使用您的 {currency_yg} 餘額來支付汽油費。",
    "Insufficient funds: to continue with payment use Utility or Gymnet balance": "資金不足：使用Utility或Gymnet餘額繼續付款",
    "Use": "使用",
    "Use Utility or Gymnet": "使用 Utility 或 Gymnet",
    "Achieved PRICE turnover": "實現 ${price} 營業額",
    "Your current milestone": "您目前的里程碑",
    "Reward History": "獎勵歷史",
    "Leading Direct Partner": "領先的直接合作夥伴",
    "Remaining Direct Partners": "剩餘直接合作夥伴",
    "Bonus Split": "獎金分割",
    "Monthly Countdown": "每月倒數",
    "No direct partners": "無直接合作夥伴",
    "To receive the reward, you must have an active product autoship.": "要獲得獎勵，您必須擁有有效的產品自動發貨。",
    "This incentive is subject to the 50% Turnover Rule.": "此激勵措施須遵守 50% 營業額規則。",
    "Income From Product Authoship": "產品授權收入",
    "Your documents have been submitted for verification. The bank may take up to 3 business days to review your application. Once approved, you can continue with your purchase.": "您的文件已提交以供验证。 银行最多可能需要 3 个工作日来审核您的申请。 一旦获得批准，您就可以继续购买。",
    "To purchase the card, you need to agree to the T&C and complete the KYC verification process.": "要購買該卡，您需要同意條款和條件並完成 KYC 驗證流程。",
    "Only Latin letters are allowed": "只允許使用拉丁字母",
    "First name must be at least 3 characters": "名字必須至少包含 3 個字符",
    "First name should not exceed 10 characters": "名字不得超過 10 個字符",
    "Should be only letters": "應該只是字母",
    "Address must be at least 3 characters": "地址必須至少為 3 個字符",
    "Only latin letters, numbers and special characters are allowed": "僅允許使用拉丁字母、數字和特殊字符",
    "Email is not valid": "電子郵件無效",
    "Zip must contain only numbers with min length of 3": "Zip 只能包含最小長度為 3 的數字",
    "The phone number must be at least 6 digits": "電話號碼必須至少 6 位數字",
    "The phone number must not exceed 12 digits": "電話號碼不得超過 12 位數字",
    "Phone number should contain only numbers": "電話號碼只能包含數字",
    "ID Number must have at least 3 characters": "ID 號碼必須至少包含 3 個字符",
    "ID Issued by must have at least 3 characters": "ID 頒發者必須至少包含 3 個字符",
    "ID Issued by should not exceed 191 characters": "ID 頒發者不得超過 191 個字符",
    "Last name must be at least 3 characters": "姓氏必須至少包含 3 個字符",
    "State is required": "狀態為必填項",
    "The incentive countdown starts at the beginning of each month and ends at the end of the month. If you do not qualify for the bonus within that month, you will need to start again in the next month.": "獎勵倒數從每個月初開始，到月底結束。如果您在該月內沒有資格獲得獎金，則需要在下個月重新開始。",
    "Activated": "活性",
    "Deposit & fee commission": "押金及手續費佣金",
    "Web3 wallet not connected. Please connect to proceed.": "Web3 錢包未連線。請連接以繼續。",
    "Total investors profit": "投资者总利润",
    "Commissions from vault investors": "金库投资者的佣金",
    "Please Claim your rewards from to activate this section.": "请从 {n} 领取您的奖励以激活此部分。",
    "Or use Button Below": "或者使用下面的按钮",
    "Partner Activity": "合作伙伴活动",
    "Pending Commissions": "待定佣金",
    "Binary Turnover": "二进制周转率",
    "Unilevel Turnover": "单层翻转",
    "Right Direct Partners": "右直接搭档",
    "Left Direct Partners": "左侧直接合作伙伴",
    "50% Compensation:": "50% 補償：",
    "When you reach the next rank, you will receive 50% of the lost bonus from the previous rank.": "當您達到下一個等級時，您將獲得上一個等級所失去的獎金的50%。",
    "Please note that username can be changed only once in 6 months.": "請注意，使用者名稱每 6 個月只能更改一次。",
    "Binary Turnover in weak leg": "弱腿二元翻转",
    "Unilevel turnover requirement": "单级周转率要求",
    "Connect a WEB3 wallet such as Metamask or Trustwallet and pay with {currency}. This process requires a small BNB fee ($0.30) to handle transaction costs!": "連接 WEB3 錢包（例如 Metamask 或 Trustwallet）並使用 {currency} 付款。此過程需要少量 BNB 費用（0.30 美元）來處理交易費用！",
    "Use your account's wallet balance to purchase the product.": "使用您帳戶的錢包餘額購買產品。",
    "Seems like your crypto hasn't arrived yet": "您的加密貨幣似乎尚未到達",
    "Wait more": "等等",
    "Or topup with": "或者儲值",
    "Connected to web3": "連接到 web3",
    "Reset": "重置",
    "Top Up Successful": "儲值成功",
    "Top Up Successful. Card balance will be updated soon": "儲值成功。卡內餘額即將更新",
    "Please activate your card and top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "请激活您的卡并充值，以支付月租费。如果余额没有更新，您的卡将被停用，您需要购买一张新卡。",
    "PERCENTAGE allocation from Global Pool": "來自全域池的 {perc}% 分配",
    "You need to reach rank RANK_NAME to participate in this pool.": "您需要達到排名“{rank_name}”才能參加此池。",
    "Global Pool": "全球池",
    "Next Distribution In:": "下一個發行版：",
    "50% Rule": "50% 規則",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%": "ส่วนแบ่งสูงสุดที่หุ้นส่วนโดยตรงแต่ละรายสามารถมีส่วนในการหมุนเวียนที่ต้องการคือสูงถึง 50%",
    "Sub Pools": "子池",
    "Congratulations! You have qualified for a share of the Global Pool.": "恭喜！您有資格分享全球礦池。",
    "You will receive your share once the countdown is complete.": "倒數計時完成後，您將收到您的份額。",
    "Please top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "请为余额充值，以支付月费。如果余额没有更新，您的卡将被停用，您需要购买一张新卡。",
    "You need to deposit VALUE worth of GYMNET to upgrade your weekly binary cap": "您需要存入价值 {dvalue} 的 GYMNET 来升级您的二进制每周上限。",
    "Binary Max Weekly Payout": "二进制每周最高赔率",
    "Required Single Pool Deposit To Upgrade": "升级所需的单池存款",
    "Upgrade Binary Max Weekly Payout": "升级二进制每周最高赔率",
    "Weekly Cap Upgraded": "已升级的每周上限",
    "Your 8 months period for earning token rewards in Filecoin has officially concluded. Thank you for your participation. Enjoy your FIL earnings.": "您在 Filecoin 赚取代币奖励的 8 个月期限已正式结束。感谢您的参与。请尽情享受您的 FIL 收入。",
    "As previously announced Filecoin will be replaced by Moonberg token, which will be distributed for a period of 24 months. The Moonberg launch is expected for August 2024 and the distribution will start one week after that.": "正如之前宣布的那样，Filecoin 将被 Moonberg 代币取代，后者的发行期为 24 个月。Moonberg 的发布时间预计为 2024 年 8 月，之后一周将开始分发。",
    "Username must not exceed 255 characters": "用户名不得超过 255 个字符",
    "Upgrade": "升級",
    "Product Autoship Monthly": "產品每月自動出貨",
    "Product Autoship Yearly": "每年產品自動出貨",
    "Product Owner": "產品負責人",
    "Myself": "我",
    "Another user": "另一位用戶",
    "Payment": "支付",
    "PAP Balance": "PAP餘額",
    "Purchased By": "購買者",
    "Purchased For": "購買目的",
    "Single parcel": "單件包裹",
    "Single miner": "單礦工",
    "completed": "完全的",
    "pending": "待办的",
    "expired": "无效",

    "Select {n} blockchain to send the transaction": "选择 {n} 区块链发送交易",
    "We recommend waiting to claim your rewards when the gas fee is lower than the amount you are claiming.": "我们建议等到 gas 费用低于您申领的金额时再申领奖励。",
    "Total Value Of Purchases": "购买总价值",
    "The total value of all purchases excluding purchases related to Card Bundles, granted products and product autoship subscriptions.": "所有购买的总价值，不包括与卡包、授予的产品和产品自动发货订阅相关的购买。",
    "Total Receivable Tokens": "可收代币总数",
    "The number of Moonberg tokens you will receive over the next 24 months.": "您将在未来 24 个月内收到的 Moonberg 代币数量。",
    "Realtime ROI": "实时投资回报率",
    "Current Return on Investment (ROI) based on the real-time price of the Moonberg token.": "根据 Moonberg 代币的实时价格计算的当前投资回报率 (ROI)。",
    "Token Calculation Breakdown": "代币计算明细",
    "{n}% of your total purchase value is allocated for purchasing {asset} tokens": "您总购买价值的 {n}% 用于购买 {asset} 代币",
    "Moonberg tokens will be distributed upon the official release of the Moonberg token.": "Moonberg 代幣將在 Moonberg 代幣正式發佈時進行分發。",
    "Moonberg Allocation": "Moonberg 分配",
    "BTC Balance": "BTC 餘額",
    "The funds will be transferred via the BEP-20 BNB network.": "資金將透過 BEP-20 BNB 網路進行轉移。",
    "A bundle with minting power will generate BTC token rewards, which will be distributed every Monday.": "具有鑄幣能力的捆綁包將產生 BTC 代幣獎勵，該獎勵將於每週一分發。",
    "Weekly rewards expected": "預計每週獎勵",
    "Direct Sales Incentive": "直銷激勵",
    "You are about to activate your affiliate referral link.": "您即將啟動您的聯盟推薦連結。",
    "Upon activation, a period of free Product Autoship will be initiated starting from the date of your registration.": "啟用後，將從您註冊之日起開始一段免費產品自動出貨期限。",
    "If you confirm the activation, a mandatory monthly Product Autoship will be required to be eligible to receive commissions.": "如果您確認激活，則需要每月強制執行產品自動發貨才有資格獲得佣金。",
    "Keep your product autoship active to maintain your commission eligibility for commission ranks Platinum and above.": "保持您的產品自動出貨處於活動狀態，以維持您獲得白金級及以上佣金等級的佣金資格。",
    "Buy Product Autoship": "購買產品自動出貨",
    "Your current rank is achieved through qualification.": "您目前的等級是透過資格獲得的。",
    "Your commission rank is determined by your actual rank or a super bundle purchase—whichever is higher will be considered.": "您的佣金排名由您的實際排名或超級捆綁購買決定—以較高者為準。",
    "Your card has been deactivated due to an unpaid monthly subscription. For assistance or any questions, please reach out to our customer support team.": "由於未支付每月訂閱費，您的 {card_name} 卡已停用。如需協助或有任何疑問，請聯絡我們的客戶支援團隊。",
    
    "Subject": "主题",
    "topup": "充值",
    "withdraw": "提现",
    "failed": "失败",
    "3rd Anniversary": "三週年紀念日",
    "3rd Anniversary Promo": "三週年紀念促銷",
    "Your current bonus amount": "您目前的獎金金額",
    "All products are eligible for the incentive, except for Product Autoship. Rewards will be distributed on November 1, 2024, and the bonus will be deposited directly into your internal wallet.": "除產品自動出貨外，所有產品都有資格獲得獎勵。獎勵將於2024年11月1日發放，獎金將直接存入您的內部錢包。",
























    // Affility translations
    "close": "关闭",
    "cancel": "取消",
    "yes_i_confirm": "是的，我确认",
    "something_went_wrong_try_again": "出现错误。请重试",
    "or": "或",
    "success": "成功",
    "ok": "确定",
    "retry": "重試",
    "home": "主页",
    "log_in": "登录",
    "join_now": "立即加入",
    "toggle_notifications": "切换通知",
    "active": "活动",
    "inactive": "不活动",
    "product_autoship": "产品自动发货",
    "balances": "余额",
    "contract_addresses": "合同地址",
    "address_add_to_wallet_success": "地址已成功添加到您的钱包",
    "language": "语言",
    "choose_language": "选择语言",
    "profile": "个人资料",
    "settings": "设置",
    "learn": "学习",
    "help_center": "帮助中心",
    "log_out": "注销",
    "theme": "主题",
    "back": "后退",
    "forward": "前进",
    "previous": "上一个",
    "next": "下一个",
    "jump_to_top": "跳至顶部",
    "load_more": "加载更多",
    "proceed": "继续",
    "notifications": "通知",
    "notifications_loading_error_description": "确保设备已连接到互联网，或尝试重新启动路由器或调制解调器",
    "notifications_empty_title": "通知列表为空",
    "notifications_empty_description": "使用我们的通知功能，在一个方便的地方跟踪您的所有更新和提醒。",
    "email_verification_sent_title": "电子邮件验证",
    "email_verification_sent_description": "我们向{email}发送了一封电子邮件，其中包含一个链接以完成您的注册",
    "email_verification_required": "需要电子邮件验证",
    "email_verification_confirm": "请确认您的电子邮件以继续",
    "email_verification_verify": "验证您的电子邮件以继续",
    "new_email": "新电子邮件",
    "enter_new_email": "输入新电子邮件",
    "change_email": "更改电子邮件",
    "resend_email": "重新发送电子邮件",
    "upgrade_to_affiliate": "升级为会员",
    "upgrade_to_affiliate_success": "您已成功升级为联盟会员",
    "become_affiliate": "成为会员",
    "upgrade_to_affiliate_description_free_autoship": "您即将激活您的会员推荐链接。激活后，从您注册之日起将开始一段免费产品自动发货期。",
    "upgrade_to_affiliate_description_mandatory_autoship": "您即将激活您的联盟推荐链接。如果您确认激活，则必须每月进行一次产品自动发货才能有资格获得佣金。",
    "t&c": "条款和条件",
    "accept_t&c": "接受并关闭",
    "affiliate_t&c": "联盟条款和条件",
    "cards_t&c": "信用卡条款和条件",
    "i_have_read_and_accept_the_{prop}": "我已阅读并接受{prop}",
    "switch_language": "切换语言",
    "switch_theme": "切换主题",
    "enjoy_more_benefit_more": "享受更多！受益更多！",
    "buy_products_at_least_${n}": "购买至少价值 ${n} 的产品",
    "go_to_products": "转到产品",
    "free_autoship": "白金及以上级别用户可享受免费产品自动发货",
    "{n}%_binary_commission": "{n}% 二元佣金",
    "rank": "排名",
    "role": "角色",
    "bundle": "捆绑包",
    "current_rank": "当前排名",
    "current_role": "当前角色",
    "customer": "客户",
    "get_your_bundle": "获取您的捆绑包",
    "nft_turbo_expires_in": "超级捆绑包特别折扣将于以下时间到期",
    "left_leg_link": "左腿链接",
    "right_leg_link": "右腿链接",
    "sponsor_id": "赞助商 ID",
    "total_income": "总收入",
    "current_cv_balance": "当前 CV 余额",
    "progress_until_rank_{n}": "进度直至排名 {n}",
    "to_reach_rank_{n}_you_need": "要达到排名 {n}，您需要",
    "nft_own_purchases": "NFT 自有购买",
    "direct_partner_own_purchases": "直接合作伙伴自有购买",
    "team_turnover": "团队营业额",
    "required_qualified_partners": "所需合格合作伙伴",
    "see_details": "查看详细信息",
    "soon": "即将推出",
    "new": "新的",
    "promos": "促销",
    "coming_soon": "即将推出",
    "team_statistics": "团队统计",
    "all_time": "所有时间",
    "weekly": "每周",
    "monthly": "每月",
    "yearly": "每年",
    "left_leg": "左腿",
    "right_leg": "右腿",
    "all_partners": "所有合作伙伴",
    "direct_partners": "直接合作伙伴",
    "all_active_partners": "所有活跃合作伙伴",
    "all_inactive_partners": "所有不活跃合作伙伴",
    "become_affiliate_partner": "成为联盟合作伙伴",
    "gain_fresh_perspectives": "获得新视角和独家福利。",
    "autoship_required_to_be_affiliate": "成为联盟合作伙伴并赚取佣金需要每月产品自动发货。",
    "for_limited_time_only": "仅限时",
    "placement_in_unilevel": "单层安置",
    "placement_in_binary": "二元安置",
    "cv_points": "CV 点数",
    "matching_bonuses": "匹配奖金",
    "leadership_incentives": "领导力激励",
    "autoship_incentive": "产品自动发货激励",
    "binary_tree_statistics": "二叉树统计",
    "unilevel_tree_statistics": "单级树统计",
    "active_partners": "活跃合作伙伴",
    "inactive_partners": "非活跃合作伙伴",
    "The minimum amount should be equal to or greater than NUM GYMNET": "最低金額應等於或大於{num} GYMNET",
    "select_the_team": "選擇團隊",
    "new_users_will_be_placed_under_username_in_the_left_team": "新用戶將被放置在 {LEG} 團隊的 {USERNAME} 下。",
    "done": "完畢",
    "right": "正確的",
    "left": "左邊",
};
