var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"d-flex flex-wrap",class:[
        `flex-${_setup.direction}`,
        {
            'align-items-center': _setup.direction === 'row',
            'align-items-stretch': _setup.direction === 'column',
        }
    ]},_vm._l((_setup.balances),function(balance){return _c('ComposedCardAssetAmount',{key:balance.symbol,class:{
            'loading-container': balance.loading,
        },attrs:{"tag":"li","amount":_setup.toCurrencyFormat(balance.amount || 0, {
            minDigits: 0,
            maxDigits: 3,
        }),"skeleton":balance.skeleton,"iconProps":balance.icon}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }