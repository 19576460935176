export default {
    "Learn how it works": "Узнайте, как это работает",
    "Buy parcel": "Купить посылку",
    "Wallets": "Кошельки",
    "To GymNetwork": "В GymNetwork",
    "Connect Wallet": "Подключить Кошелек",
    "Pending rewards": "Ожидающие награды",
    "Coming Soon": "Скоро",
    "Claim": "Востребовать",
    "Inventory": "Инвентарь",
    "Lands": "Земли",
    "Miners": "Майнеры",
    "Attach": "Прикрепить",
    "Attach to parcel": "Прикрепить к посылке",
    "Investment": "Инвестиция",
    "Wallet": "Кошелек",
    "Your address": "Ваш адрес",
    "History": "История",
    "Map": "Карта",
    "Buy miner": "Купить майнер",
    "Buy Miner": "Купить Майнер",
    "Get ": "Получить ",
    "Get Miner": "Получить Майнер",
    "Upgrade parcel": "Обновить посылку",
    "Soon": "Скоро",
    "Upgraded":"Обновлено",
    "Upgraded land has 10 slots for putting miner NFTs. Upgraded land does not have more physical space, it’s only that you can stake more miners.": "Обновленная земля имеет 10 слотов для размещения NFT майнеров. Обновленная земля не имеет большего физического пространства, но вы можете поставить больше майнеров.",
    "Next": "Следующий",
    "Back": "Назад",
    "Skip guide": "Пропустить руководство",
    "Number of miners you want to buy": "Количество двойных майнеров, которые вы хотите купить",
    "Attach Miner": "Прикрепить Майнер",
    "Upgrade for": "Обновить для",
    "On Sale": "В Продаже",
    "Type": "Тип",
    "Location": "Местоположение",
    "Size": "Размер",
    "Buy now": "Купить сейчас",
    "Email": "Электронная почта",
    "Username": "Имя пользователя",
    "Verify Email": "Подтвердить электронную почту",
    "Index": "Индекс",
    "Verification Code": "Код Подтверждения",
    "Log out": "Выйти",
    "Select all": "Выбрать все",
    "Cart": "Корзина",
    "Total": "Всего",
    "Register": "Зарегистрироваться",
    "Dashboard": "Панель управления",
    "Transaction success!": "Транзакция успешна!",
    "I`ve read Terms & Conditions": "Я прочитал Условия и Положения",
    "Terms & conditions": "Условия и положения",
    "Affiliate Terms & Conditions": "Партнерские Условия и Положения",
    "Accept Terms & Conditions": "Принять Условия и Положения",
    "Loading! Please Wait ...": "Загрузка! Пожалуйста, подождите ...",
    "Initializing Wallet! Please Wait ...": "Инициализация Кошелька! Пожалуйста, подождите ...",
    "Login In Progress! Please Wait ...": "Выполняется вход! Пожалуйста, подождите ...",
    "Enter your email": "Введите ваш адрес электронной почты",
    "Confirm your Email": "Подтвердите ваш адрес электронной почты",
    "Enter username": "Введите имя пользователя",
    "You successfully verified your email": "Вы успешно подтвердили свой адрес электронной почты",
    "View map": "Просмотреть карту",
    "Log in to buy": "Войдите, чтобы купить",
    "Congratulations": "Поздравляем",
    "Buy" : "Купить",
    "Miner" : "Майнер",
    "Parcel" : "Посылка",
    "Upgrade": "Обновление",
    "Load more": "Загрузить больше",
    "All": "Все",
    "Basic": "Базовый",
    "Activate": "Активировать",
    "In use": "Используется",
    "Expired": "Истек",
    "Available": "Доступно",
    "Standard parcel": "Стандартная посылка",
    "Something Went Wrong": "Что-то пошло не так",
    "Loading": "Загрузка",
    "Invalid Verification Code": "Недействительный Код Подтверждения",
    "Invalid Email Address": "Недействительный Адрес Электронной Почты",
    "Invalid Arguments Supplied": "Указаны Недействительные Аргументы",
    "Email Already Exists": "Электронная Почта Уже Существует",
    "UnAuthorized": "Неавторизованный",
    "User Update Failed": "Ошибка Обновления Пользователя",
    "Permission Denied": "В Доступе Отказано",
    "Remove": "Удалить",
    "Special offers": "Специальные предложения",
    "Please Wait": "Пожалуйста, Подождите",
    "Transaction Rejected": "Транзакция Отклонена",
    "sold": "продано",
    "The parcel price will be increased after certain percentage of parcels will be sold. Bundle prices will be increased accordingly.": "Цена посылки будет увеличена после того, как будет продан определенный процент посылок. Цены на пакеты будут увеличены соответственно.",
    "Sold": "Продано",
    "Parcel price index": "Индекс цены на посылку",
    "Current parcel price": "Текущая цена посылки",
    "Parcels sold": "Проданные посылки",
    "Parcel stats": "Статистика посылки",
    "Help center": "Справочный центр",
    "Partner area": "Партнерская зона",
    "to_dashboard": "На панель управления",
    "Transaction Success": "Транзакция Успешна",
    "parcels available": "доступные посылки",
    "Apply": "Применить",
    "Ocean": "Океан",
    "Near Ocean": "Возле океана",
    "Insufficient USDT amount": "Недостаточное количество USDT",
    "Insufficient {asset} amount": "Недостаточное количество {asset}",
    "Miner Rewards": "Награды Майнеров",
    "No data found": "Данные не найдены",
    "Contract Address": "Адрес Контракта",
    "Wallet Address": "Адрес кошелька",
    "Inland": "Внутренний",
    "Business": "Бизнес",
    "parcels": "посылки",
    "Standard": "Стандарт",
    "Full": "Полный",
    "Attach miners": "Прикрепить майнеров",
    "Upgraded Parcel": "Модернизированная посылка",
    "Discount": "Скидка",
    "save": "экономит",
    "Copied": "Скопированный",
    "Marketplace": "Рыночная площадь",
    "Parcel name": "Название посылки",
    "Basic Bundle": "Базовый комплект",
    "4 Parcels Bundle": "4 Свертка посылок",
    "10 Parcels Bundle": "10 Свертка посылок",
    "40 Parcels Bundle": "40 Свертка посылок",
    "80 Parcels Bundle": "80 Свертка посылок",
    "140 Parcels Bundle": "140 Свертка посылок",
    "240 Parcels Bundle": "240 Свертка посылок",
    "4 Basic Miner Bundle": "Набор 4 майнеров",
    "10 Basic Miner Bundle": "Набор 10 майнеров",
    "40 Basic Miner Bundle": "Набор 40 майнеров",
    "80 Basic Miner Bundle": "Набор 80 майнеров",
    "140 Basic Miner Bundle": "Набор 140 майнеров",
    "240 Basic Miner Bundle": "Набор 240 майнеров",
    "Hashpower": "Hashpower",
    "Something went wrong, please refresh the page and try again": "Что-то пошло не так, обновите страницу и повторите попытку",
    "Gallery": "Галерея",
    "Accepted": "Принято",
    "Pending": "В ожидании",
    "Upload": "Загрузить",
    "Uploading image": "Загрузка изображения",
    "Something went wrong, try again later": "Что-то пошло не так, повторите попытку позже",
    "You need to be authenticated to upload images to Gallery.": "Вы должны быть аутентифицированы, чтобы загружать изображения в Галерею.",
    "Assign image": "Применить изображение",
    "Assign image to parcels": "Применить изображение к посылкам",
    "Remove current image": "Удалить текущее изображение",
    "Change image": "Изменить изображение",
    "Assign to parcels": "Применить к посылкам",
    "Reselect image assignment area?": "Повторно назначить зону приминения?",
    "You have not applied changes. Do you want to reselect image assignment area?": "Вы не применили изменения.Вы хотите заменить зону назначения изображений?",
    "Image has been successfully changed": "Изображение было успешно изменено",
    "Image has been successfully assigned to parcels": "Изображение было успешно применено к посылкам",
    "Image has been successfully removed from the parcels": "Изображение было успешно удалено с посылок",
    "Yes": "Да",
    "No": "Нет",
    "Cancel": "Отмена",
    "You have no images": "У вас нет изображений",
    "Notifications": "Уведомления",
    "No notifications": "Нет уведомлений",
    "year ago": "год назад",
    "months ago": "месяцев назад",
    "month ago": "месяц назад",
    "weeks ago": "недели назад",
    "week ago": "неделю назад",
    "days ago": "дней назад",
    "day ago": "день назад",
    "hours ago": "часов назад",
    "hour ago": "час назад",
    "minutes ago": "минут назад",
    "minutes": "минуты",
    "minute": "минута",
    "seconds": "секунды",
    "second": "секунд",
    "Your image was approved": "Ваше изображение одобрено",
    "Your image was rejected": "Ваше изображение было отклонено",
    "New": "Новый",
    "Gallery guide" : "Гид галереи",
    "Go to gallery": "Перейти в галерею",
    "How it works": "Как это работает",
    "Removing image from parcels..." : "Удаление изображения с посылок ...",
    "Removing image" : "Удаление изображения",
    "Image has been successfully removed" : "Изображение было успешно удалено",
    "Wrong file size" : "Неправильный размер файла",
    "Please upload image files with size less than 10MB": "Пожалуйста, загрузите файлы изображений с размером менее 10 МБ",
    "Drag to Reposition": "Перетащите, чтобы изменить положение",
    "Pinch or use the mouse wheel to zoom in or out": "Сожмите или используйте колесо мыши, чтобы увеличить или уменьшить масштаб",
    "Drag and move the image with fingers or using your mouse to select area": "Перетащите изображение пальцами или с помощью мыши, чтобы выбрать область",
    "It can take up to 48 hours for the image to be approved. You can view your images awaiting approval in the Pending tab.": "Проверка изображения может занять до 48 часов. Вы можете просмотреть изображения, ожидающие утверждения, на вкладке «Ожидание».",
    "Delete Image": "Удалить изображение",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable. Land containing this image will also be cleared.": "Вы уверены, что хотите навсегда удалить изображение из галереи? Эта операция не подлежит восстановлению. Земля, содержащая это изображение, также будет очищена.",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable." : "Вы уверены, что хотите навсегда удалить изображение из своей галереи? Эта операция не подлежит восстановлению.",
    "Wrong File Type": "Неправильный тип файла",
    "Please upload jpg/jpeg image files": "Пожалуйста, загрузите файлы изображений типа JPG/JPEG",
    "Upload Images to Your Gallery": "Загрузите изображения в свою галерею",
    "You can upload images to your gallery from your computer, mobile phone or tablet. Currently only jpg/jpeg image file types are supported. Press the upload button or simply drag and drop the image from computer to upload your image. Image size must be up to 10 MB." : "Вы можете загружать изображения в свою галерею со своего компьютера, мобильного телефона или планшета. В настоящее время поддерживаются только типы файлов изображений jpg/jpeg. Нажмите кнопку загрузки или просто перетащите изображение с компьютера, чтобы загрузить свое изображение. Размер изображения должен быть до 10 МБ.",
    "Upload image": "Загрузить изображение",
    "Image Approval": "Одобрение изображения",
    "Uploaded images need to be approved before they appear in the approved section of your gallery. This procedure is necessary to filter out inappropriate, explicit content and copyrighted material. Approval can take up to 48 hours. You'll get notified about the verdict whenever you're online." : "Загруженные изображения должны быть одобрены, прежде чем они появятся в одобренном разделе вашей галереи. Эта процедура необходима для фильтрации неприемлемого, явного контента и материалов, защищенных авторским правом. Одобрение может занять до 48 часов. Вы получите уведомление о приговоре всякий раз, когда вы в сети.",
    "Approval": "Одобрение",
    "Select Your Land to Assign Image to": "Выберите свою землю, чтобы назначить изображение",
    "You can assign your uploaded images to your owned land. To do so, simply select the parcels by dragging mouse on the land part you want to assign an image to. Please note that image can only be assigned to a rectangular area that is owned by you only." : "Вы можете присвоить загруженные изображения своей земле. Для этого просто выберите участки, перетащив мышь на ту часть земли, которой вы хотите присвоить изображение. Обратите внимание, что изображение может быть назначено только прямоугольной области, принадлежащей только вам.",
    "Land Selection": "Выбор земли",
    "Crop Image": "Обрезать изображение",
    "You can crop a custom part of your image that you want to assign to your land section. You can close in to any area of your image and crop it by dragging the image with mouse or your finger if you're on a mobile device. You can also zoom in and out by using mouse wheel or pinch gesture." : "Вы можете обрезать пользовательскую часть изображения, которую хотите присвоить своему земельному участку. Вы можете закрыть любую область изображения и обрезать ее, перетащив изображение мышью или пальцем, если вы используете мобильное устройство. Вы также можете увеличивать и уменьшать масштаб с помощью колесика мыши или жеста щипка.",
    "Activate multiple selection": "Активировать множественный выбор",
    "Exit multiple selection": "Выйти из множественного выбора",
    "Owned": "Принадлежащий",
    "Owned by": "Принадлежит",
    "Multiple types": "Несколько типов",
    "seconds ago": "секунд назад",
    "Super Bundle": "Супер комплект",
    "View your purchased Bundles and claim to have them from your balance": "Просматривайте купленные пакеты и требуйте, чтобы они были зачислены на ваш баланс.",
    "Feel free to choose lands on Map.": "Не стесняйтесь выбирать земли на карте.",
    "Earn money by using miners for generating rewards.": "Зарабатывайте деньги, используя майнеры для получения вознаграждения.",
    "Upgrade your owned lands from 4 slots to 10.": "Обновите свои земли с 4 слотов до 10.",
    "You don`t have any available parcels in your inventory": "У вас нет доступных посылок в вашем инвентаре",
    "Months": "Месяцы",
    "Super Bundle R6": "Супер Комплект R6",
    "Super Bundle R10": "Супер Комплект R10",
    "Super Bundle R15": "Супер Комплект R15",
    "Super Bundle R20": "Супер Комплект R20",
    "Super Bundle R22 S": "Супер Комплект R22 S",
    "Super Bundle R22 M": "Супер Комплект R22 M",
    "Super Bundle R22 L": "Супер Комплект R22 L",
    "Super Bundle R22 XL": "Супер Комплект R22 XL",
    "Super Bundle R22 XXL": "Супер Комплект R22 XXL",
    "1 Claim your land": "1 Претендуй на свою землю",
    "2 Claim your miner": "2 Заявите свой майнер",
    "3 Upgrade your land": "3 Обновите свою землю",
    "Your NFT balance": "Ваш баланс NFT",
    "Next price changes in": "Следующее изменение цены через",
    "Parcels": "Посылки",
    "Get to know the platform usage": "Ознакомьтесь с использованием платформы",
    "What is a Parcel?": "Что такое посылка?",
    "Land is a unit of area on the GYM Street map and it is also an NFT. You can own as much land on GYM Street as you want.": "Земля — это единица площади на карте GYM Street, а также NFT. Вы можете владеть сколько угодно земли на GYM Street.",
    "What is a Miner?": "Что такое майнер?",
    "A miner is what generates income for you at GYM Street. You can attach up to 4 miners on each of your standard land parcels.": "Майнер — это то, что приносит вам доход в GYM Street. Вы можете прикрепить до 4-х майнеров на каждый из ваших стандартных земельных участков.",
    "My Miners": "Мои майнеры",
    "My Hashpower": "Мой hashpower",
    "Total Claimed": "Всего заявлено",
    "Total pending rewards": "Всего ожидающих вознаграждений",
    "Daily rewards": "Ежедневные награды",
    "Weekly rewards": "Еженедельные награды",
    "Monthly rewards": "Ежемесячные награды",
    "Yearly rewards": "Годовые награды",
    "ROI": "ROI",
    "ROI Calculator": "ROI Калькулятор",
    "Global Statistics": "Глобальная статистика",
    "Global Hashpower": "Глобальный hashpower",
    "Your Shares": "Ваши акции",
    "Daily Global Rewards": "Ежедневные глобальные награды",
    "Price": "Цена",
    "Total Minted": "Всего отчеканено",
    "total": "общий",
    "standard": "стандартный",
    "upgraded": "улучшенный",
    "inactive": "неактивный",
    "Attach miner": "Прикрепить майнер",
    "Total Miners": "Всего майнеров",
    "Total Minted Rewards": "Всего отчеканенных вознаграждений",
    "What is parcel and how to use it?": "Посылка — это единица площади на карте {metaverse}, оснащенная возможностями чеканки. Имейте посылки и получайте доход.",
    "What is a miner and how to use it?": "Майнер — ваш генератор дохода в {metaverse}. Увеличьте свой заработок, прикрепив майнеры к своей посылке. <br/> <br/> GYMNET Miner и Dual Miner работают со скоростью добычи 1000 Mh/s.",
    "Your active/inactive hashpower, 1 miner = 1,000 Mh/s, your Miners 0 x 1,000Mh/s = 0.00": "Ваш активный /неактивный hashpower, 1 майнер = 1000 Mh/s, ваши майнеры 0 x 1000Mh/s = 0,00",
    "The amount of the cumulated claimed rewards": "Сумма накопленных заявленных вознаграждений",
    "Your current available pending rewards.": "Ваши текущие доступные ожидающие награды.",
    "Congratulations!": "Поздравляем!",
    "Upgrade your parcels to mine even more?": "Обновите свои посылки, чтобы добывать еще больше?",
    "You have NUM parcels that are not upgraded.": "У вас есть {parcels} участков, которые\n не обновлены.",
    "Go to Map": "Перейти к карте",
    "You're mining less than the average user.": "Вы майните меньше, чем средний пользователь.",
    "Purchase more miners to improve your results": "Купите больше майнеров, чтобы улучшить свои результаты",
    "Buy more miners": "Покупайте больше майнеров",
    "Thanks, I'll stay below average": "Спасибо, я останусь ниже среднего",
    "You aren't mining at full speed.": "Вы не работаете на полной скорости.",
    "Do you want to buy more parcels now ?": "Вы хотите купить больше посылок сейчас?",
    "I definitely do": "я определенно знаю",
    "No, I'll let my miners to rust": "Нет, я позволю своим шахтерам ржаветь",
    "You have no miners": "У вас нет майнеров",
    "Buy miners": "Купить двойные майнеры",
    "You haven't upgradeable parcels": "У вас нет обновляемых посылок",
    "You haven't inactive miners": "У вас нет неактивных майнеров",
    "Close": "Закрывать",
    "Read more": "Читать далее",
    "Drag and drop an image, or Browse": "Перетащите изображение или <label class=\"browse-button\" for=\"upload-image\">Обзор</label>",
    "Browse an image": "<label class=\"browse-button\" for=\"upload-image\">Просмотреть</label> изображение",
    "Drop to upload": "Бросить на загрузку",
    "File not supported": "Файл не поддерживается",
    "Upload your parcel image": "Загрузите изображение посылки",
    "Up to 10MB, jpg/jpeg": "До 10 МБ, JPG/JPEG",
    "High resolution images, should align with your land shape (you can crop after upload)": "<span class=\"bold-info\">Изображения с высоким разрешением</span> должны совпадать с формой вашей земли (вы можете обрезать после загрузки)",
    "Same image may be used on different lands owned by the user": "<span class=\"bold-info\">Одно и то же изображение может использоваться</span> на разных землях, принадлежащих пользователю",
    "No violence, offensive, graphic or 18+ content": "Никакого насилия, оскорбительного, графического или <span class=\"bold-info\">контента старше 18 лет</span>",
    "No third-party copyrighted content, no logos or screenshots from Gymstreet and Gymnetwork": "<span class=\"bold-info\">Нет стороннего контента, защищенного авторским правом</span>, нет логотипов или скриншотов из <span class=\"bold-info\">Gymtree и Gymnetwork</span>",
    "No images already used by another user, no images from DeFi listing website images": "<span class=\"bold-info\">Нет изображений, которые уже использовались</span> другим пользователем, нет изображений из DeFi со списком изображений веб-сайтов",
    "No religious images": "<span class=\"bold-info\">Никаких религиозных изображений</span>",
    "Unlock miners by purchasing at least 1 land parcel": "Разблокируйте майнеров, купив хотя бы 1 земельный участок",
    "OK": "ХОРОШО",
    "Your parcels": "Ваши посылки",
    "Your miners": "Ваши майнеры",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcels to start mining": "Вы не занимаетесь майнингом на полной скорости, у вас есть майнер {miners}, который необходимо прикрепить к вашим участкам, чтобы начать майнинг",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcel to start mining": "Вы не занимаетесь майнингом на полной скорости, у вас есть майнер {miners}, который необходимо прикрепить к вашей посылке, чтобы начать майнинг",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcels to start mining": "Вы не занимаетесь майнингом на полной скорости, у вас есть майнеры {miners}, которые необходимо прикрепить к вашим участкам, чтобы начать майнинг",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcel to start mining": "Вы не занимаетесь майнингом на полной скорости, у вас есть майнеры {miners}, которые необходимо прикрепить к вашей посылке, чтобы начать майнинг",
    "Info Center": "Инфо центр",
    "Standard Parcel": "Стандартная посылка",
    "You have no parcels": "У вас нет посылок",
    "Single Products": "Отдельные продукты",
    "Parcels sold / Total parcels": "Проданные посылки / Общие посылки",
    "You can mine even more. Purchase more miners to improve your results": "Вы можете добывать еще больше. Купите больше майнеров, чтобы улучшить свои результаты",
    "In Progress": "В ходе выполнения",
    "Learn More": "Учить больше",
    "Add to wallet": "Добавить в кошелек",
    "Your Balance": "Ваш баланс",
    "The Promotion starts in:": "Акция начинается через:",
    "Please go to your wallet, which you just connected, and verify your login by confirming the requested transaction. It's free of charge!": "Пожалуйста, перейдите в свой кошелек, который вы только что подключили, и подтвердите свой логин, подтвердив запрошенную транзакцию. Это бесплатно!",
    "Contract addresses": "Адреса контрактов",
    "Add TOKEN to wallet": "Добавьте {token} в кошелек",
    "I accept the terms and conditions": "Я принимаю правила и условия",
    "Log In": "Войти",
    "Sign Up": "Зарегистрироваться",
    "Connect My Wallet": "Подключить мой кошелек",
    "Change Email": "Изменить e-mail",
    "Reset Password": "Сброс пароля",
    "Your password has been changed.": "Ваш пароль был изменен.",
    "Please log in to your account.": "Пожалуйста, войдите в свою учетную запись.",
    "Confirm Email": "Подтвердите эл. почту",
    "Emails don't match": "Почты не совпадают",
    "Or": "Или",
    "Sign Up With Email": "Зарегистрируйтесь с электронной почтой",
    "Already have an account?": "Уже есть учетная запись?",
    "Recover": "Восстанавить",
    "Enter New Password": "Введите новый пароль",
    "Confirm New Password": "Подтвердите новый пароль",
    "Password": "Пароль",
    "Forgot password?": "Забыли пароль?",
    "Password is required": "Необходим пароль",
    "Password confirmation is required": "Необходимо подтверждение пароля",
    "Passwords do not match": "Пароли не совпадают",
    "Password must contain at least one uppercase letter, one lowercase letter and one number": "Пароль должен содержать как минимум одну верхнюю букву, одну строчную букву и один номер",
    "Password must be at least 8 characters": "Пароль должен быть не менее 8 символов",
    "Password must not exceed 24 characters": "Пароль не должен превышать 24 символов",
    "Password must contain at least one special character": "Пароль должен содержать хотя бы один специальный символ",
    "Password cannot contain spaces": "Пароль не может содержать пробелов",
    "Email is required": "Электронная почта необходима",
    "Email confirmation is required": "Требуется подтверждение электронной почты",
    "Please enter a valid email": "Пожалуйста, введите действительный адрес электронной почты",
    "Step NUM1 of NUM2": "Шаг {num1} из {num2}",
    "Create Password": "Придумать пароль",
    "Get Started": "Начать",
    "Create a Wallet": "Создать кошелек",
    "Confirm on this screen": "Подтвердите на этом экране",
    "Pick a password on the next step. This needs to be at least 8 symbols long.": "Выберите пароль на следующем шаге. Это должно быть не менее 8 символов.",
    "Wallet Ready": "Кошелек готов",
    "Add Binance Smart Chain": "Добавьте Binance Smart Chain",
    "Click on the button below to add Binance Smart Chain Network to your MetaMask wallet.": "Нажмите кнопку ниже, чтобы добавить сеть Binance Smart Chain в свой кошелек MetaMask.",
    "Confirm the connection request": "Подтвердить запрос на подключение",
    "Get Started!": "Начать!",
    "Secure Wallet": "Безопасный кошелек",
    "Confirm your password to continue.": "Подтвердите свой пароль, чтобы продолжить.",
    "You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.": "Он понадобится вам для восстановления вашего кошелька, если вы потеряете доступ к своему компьютеру, и его следует хранить в надежном месте. Любой, у кого есть доступ к вашей резервной фразе из 12 слов, сможет восстановить ваши средства, поэтому держите это в секрете.",
    "You have successfully set up Your wallet.Welcome to the world of WEB3!": "Вы успешно настроили свой кошелек.\nДобро пожаловать в мир WEB3!",
    "Confirm Password": "Подтвердите пароль",
    "Install here.": "Установить здесь.",
    "Something went wrong, please try verifying your email again.": "Что-то пошло не так, пожалуйста, попробуйте еще раз подтвердить свою электронную почту.",
    "Looks like your password reset link has expired. Please try again.": "Похоже, ваша ссылка на сброс пароля истекла. Пожалуйста, попробуйте еще раз.",
    "Looks like your password reset link has already been used. Please request it again.": "Похоже, ваша ссылка сброса пароля уже использовалась. Пожалуйста, запросите его еще раз.",
    "Couldn't recover password": "Не получилось восстановить пароль",
    "Couldn't reset your password.": "Не получилось сбросить ваш пароль.",
    "Couldn't login.": "Не получилось войти в систему.",
    "Couldn't sign you up.": "Не получилось зарегистрироваться.",
    "Resend Email": "Отправить электронное письмо cнова",
    "Couldn't resend confirmation email.": "Не удалось снова отправить письмо для подтверждения.",
    "Provided email is already in use.": "Электронная почта уже используется.",
    "You have already verified your email address": "Вы уже подтвсердили свой адрес электронной почты",
    "Invalid or expired verification code": "Неверный или истекший код проверки",
    "An error occurred while logging in. Please try again.": "Во время входа в систему произошла ошибка. Попробуйте еще раз.",
    "We sent an email to {EMAIL} with a link to finalize your registration": "Мы отправили электронное письмо в {EMAIL} с ссылкой, чтобы завершить регистрацию",
    "We sent an email to {EMAIL} with a link to change your password": "Мы отправили электронное письмо в {EMAIL} с ссылкой для изменения вашего пароля",
    "Referral ID": "Реферала ID",
    "On the next step, tap on 'Create a new wallet'.": "На следующем шаге нажмите \n «Create a new wallet».",
    "On the next step, tap on 'Create a wallet'.": "На следующем шаге нажмите \"Create a wallet\".",
    "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower case letters, symbols and numbers.": "Мы рекомендуем использовать совершенно уникальный пароль, который больше нигде не использовался, и содержит смесь букв верхнего и нижнего регистра, символов и чисел.",
    "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.": "Поместите слова, запоминающиеся на предыдущем шаге в порядке. Нажмите кнопку «Complete», когда вы закончите резервную копию.",
    "On this step you can choose. We recommend you do this now as you might otherwise lose access to your wallet. Tap on 'Start' to continue.": "На этом шаге вы можете выбрать.\n Мы рекомендуем сделать это сейчас, так как вы можете потерять доступ к своему кошельку.\n Нажмите на «Start», чтобы продолжить.",
    "On this step tap on 'Start' to continue.": "На этом шаге нажмите «Start» \n чтобы продолжить.",
    "Tap on the burger menu of the app in the top left corner of the screen.": "Нажмите на меню со значком в виде бургера приложения в верхнем левом углу экрана.",
    "Next, tap on Settings in the menu to the left.": "Затем нажмите на «Settings» в меню слева.",
    "Next, tap on Networks.": "Далее нажмите на «Networks».",
    "Add More Tokens": "Добавьте больше токенов",
    "In the search field, type in BNB Smart Chain and add it to your network.": "В поле поиска введите \"BNB Smart Chain\" и добавьте его в свою сеть.",
    "Next, select Custom Token. Paste the address of the smart contract you copied into the token address field. In the Token Symbol field, type SP, and in the Token Decimal, put 0. Then click the 'Import' button to complete your setup.": "Далее выберите «Custom Token». Вставьте адрес смарт-контракта, который вы скопировали в поле «Token address». В поле «Token Symbol», тип SP и в «Token Decimal», вставьте 0. Затем нажмите кнопку «Import», чтобы завершить настройку.",
    "Add Network": "Добавить сеть",
    "Give permission to the platform to add a network.": "Дайте разрешение для платформы на добавление сети.",
    "Extra PERCENT% Discount": "Дополнительная {PERCENT}% скидка",
    "Tap on the 'Import Tokens' link to add a Custom Token.": "Нажмите на ссылку «Import Tokens», чтобы добавить пользовательский токен.",
    "Click on the button 'Approve' to allow the connection request.": "Нажмите кнопку «Approve», чтобы разрешить запрос подключения.",
    "Confirm Secret Phrase": "Подтвердите секретную фразу",
    "Click on the button 'Switch network' to allow the connection request.": "Нажмите на кнопку «Switch network», чтобы разрешить запрос подключения.",
    "Payment Method": "Метод оплаты",
    "Select cryptocurrency": "Выберите криптовалюту",
    "Please use an Ethereum BEP-20 address.": "Пожалуйста, используйте адрес Ethereum BEP-20.",
    "Connect to Gymstreet": "Подключиться к Gym Street",
    "Now to access the Gym Street Metaverse, you need to open it with the MetaMask browser. Go to your MetaMask wallet app, choose 'Browser' from the side navigation menu, search for Gym Street in the browser tab.": "Теперь, чтобы получить доступ к Gym Street Metaverse, вам нужно открыть ее в браузере MetaMask. Перейдите в приложение кошелька MetaMask, выберите \"Browser\" в боковом навигационном меню, найдите Gym Street на вкладке браузера.",
    "First, let's set up your cryptocurrency wallet.": "Сначала, давайте настроим ваш криптовалютный кошелек.",
    "Follow the next steps to continue the setup.": "Следуйте инструкции, чтобы продолжить настройку.",
    "Your balance was updated": "Ваш баланс был обновлен",
    "Once installed, you should see the below splash screen. Click the 'Get Started' button to begin creating your Ethereum wallet using MetaMask.": "После установки вы должны увидеть экран ниже.Нажмите кнопку «Get Started», чтобы начать создавать свой кошелек Ethereum с помощью MetaMask.",
    "You will then be asked if you want to help improve MetaMask. Click 'No, Thanks' if this doesn't interest you, otherwise click 'I agree'.": "Затем вас спросят, хотите ли вы помочь улучшить MetaMask. Нажмите «No, Thanks», если это вас не интересует, иначе нажмите «I agree».",
    "MetaMask will then present you with your 12-word backup phrase. You will need to write this down in the same order displayed on your screen.": "Затем MetaMask представит вас с вашей резервной фразой на 12 слов. Вам нужно будет записать это в том же порядке, отображаемом на вашем экране.",
    "After Downloading and opening MetaMask, tap on 'Get Started'.": "После загрузки и открытия Metamask нажмите «Get Started».",
    "Improve Metamask": "Улучшить MetaMask",
    "Congratulations! You have set up your MetaMask wallet. Confirm the next few pop-ups on the screen to access your wallet.": "Поздравляю! Вы настроили свой кошелек MetaMask. Подтвердите следующие несколько всплывающих окон на экране, чтобы получить доступ к вашим кошельку.",
    "Import Binance Smart Chain": "Импорт Binance Smart Chain",
    "Next, copy the NFT Smart Contract by tapping on the button below.": "Затем скопируйте NFT Smart Contract , нажав на кнопку ниже.",
    "Copy NFT Contract": "Скопируйте NFT Contract",
    "Install MetaMask": "Установите MetaMask",
    "Install MetaMask here.": "Установите MetaMask здесь.",
    "Guide for MetaMask Wallet connection process.": "Руководство для процесса соединения кошелька Metamask.",
    "MetaMask will then present you with your 12-word backup phrase.": "Затем MetaMask представит вас с вашей резервной фразой на 12 слов.",
    "MetaMask Secret Phrase": "Секретная фраза MetaMask",
    "Copy NFT Smart Contract": "Скопировать NFT Smart Contract",
    "You are almost finished with the MetaMask setup process. Just click 'All done' on the last page, and you will be automatically logged into MetaMask. If you ever log out, you can log back in by clicking on the icon added to your web browser (usually, it's next to the URL bar).": "Вы почти закончили процесс настройки MetaMask. Просто нажмите «All done» на последней странице, и вы будете автоматически войти в MetaMask. Если вы когда-нибудь выходите из аккаунта, вы можете войти в систему, нажав на значок, в вашем веб-браузер (обычно он находится рядом с панелью URL).",
    "Your payment processing is complete. Your NFT balance has been updated!": "Обработка вашего платежа завершена. Ваш баланс NFT был обновлен!",
    "Your payment is received but is incomplete. Please pay AMOUNT CURRENCY more to complete the payment.": "Ваш платеж получен, но неполный. Пожалуйста, заплатите еще {amount} {currency}, чтобы завершить платеж.",
    "Email Verification": "подтверждение адреса электронной почты",
    "Transaction history": "История транзакций",
    "Order History": "История Заказов",
    "Product": "Продукт",
    "Quantity": "Количество",
    "Payment UID": "UID платежа",
    "Status": "Состояние",
    "Currency": "Валюта",
    "Autoship":"Автошип",
    "Date": "Дата",
    "Tx ID": "Tx ID",
    "Completed": "Завершенный",
    "MetaMask is not installed": "MetaMask не установлен",
    "If you have already installed MetaMask, please": "Если вы уже установили {MetaMask}, пожалуйста,",
    "refresh page": "обновить страницу,",
    "to continue.": "чтобы продолжить.",
    "You have successfully installed MetaMask. Now you can add a Network.": "Вы успешно установили MetaMask. Теперь вы можете добавить сеть.",
    "You already have this network": "У вас уже есть эта сеть",
    "Continue": "Продолжать",
    "Finalize Registration": "Завершить регистрацию",
    "Please confirm your email to continue": "Пожалуйста, подтвердите свою почту, чтобы продолжить",
    "New email must be different from current email": "Новая почта должна отличаться от текущей",
    "Actions": "Действия",
    "Request to change the chain has been rejected": "Запрос на изменение цепочки была отклонена",
    "Switch Chain": "Переключатель цепь",
    "Choose your Metaverse": "Выберите свою Метавселенную",
    "You need to choose your Metaverse to continue. Maps, Dashboard and My Products information will vary depending on your selection. Currently {METAVERSE} Metaverse is selected.": "Чтобы продолжить, вам нужно выбрать свою Метавселенную. Информация о Картах, Панели управления и Моих продуктах будет зависеть от вашего выбора. В настоящее время выбрана метавселенная {METAVERSE}.",
    "Can't check your purchases": "Не получилось проверить ваши покупки",
    "Attach {COUNT} miners": "Прикрепите {COUNT} майнеров",
    "Attach 1 miner": "Прикрепить 1 майнер",
    "View on map": "Посмотреть на карте",
    "Buy {COUNT} parcels": "Купите {COUNT} посылок",
    "Buy 1 parcel": "Купить 1 посылку",
    "Buy {COUNT} miners": "Купите {COUNT} двойных майнера",
    "Buy 1 miner": "Купить 1 двойной майнер",
    "Metaverses": "Метавселенные",
    "My NFTs": "Мои NFT",
    "Total Hashpower (Mh/s)": "Общая мощность (Mh/s)",
    "Available Miner Slots": "Доступные слоты для майнеров",
    "Login Via Crypto Wallet": "Вход в систему через криптокошелек",
    "Land reserved for PARTNER": "Земля, зарезервированная для {PARTNER}",
    "About PARTNER": "О {PARTNER}",
    "swap": "обмен",
    "transfer": "перевод",
    "approve": "одобрение",
    "max": "Максимум",
    "The minimum amount to claim is N": "Минимальная сумма претензии: {n}",
    "paginationText": "Показано {from} - {to} из {count}",
    "turnovers": "Обороты",
    "Total partners": "Всего партнеров",
    "refreshData": "Обновить данные",
    "NFT Turnover": "NFT оборот",
    "Single Pool Turnover": "Оборот единого пула",
    "depth": "Глубина",
    "turnover": "Оборот",
    "weekly": "За неделю",
    "monthly": "За месяц",
    "wallet": "Кошелек",
    "columns": "Столбцы",
    "filters": "Фильтры",
    "level": "Уровень",
    "search": "Поиск",
    "partners": "Партнеры",
    "Farming Turnover": "Оборот фарминга",
    "Vault Turnover": "Оброт хранилища",
    "Careers status": "Карьерный статус",
    "To dashboard": "На панель управления",
    "Success": "Успех",
    "Basic miner": "Базовый майнер",
    "Balance": "Баланс",
    "Confirm": "Подтверждать",
    "Miner rewards": "Награды майнера",
    "Claim all": "Затребовать все",
    "Active": "Активные",
    "GYMNET Balance": "Баланс GYMNET",
    "Amount": "Сумма",
    "Save": "Сохранять",
    "Average ROI past 9 months": "ROI за последние 9 месяцев",
    "Swap": "Замена",
    "Turnover pool": "Пул оборотов",
    "Leadership Incentives": "Награды за лидерство",
    "Global Line Bonus": "Бонус глобальной линии",
    "Turnover pools": "Пулы оборотов",
    "Tax pools": "Налоговые пулы ({n}mo)",
    "Total qualified turnover": "Общий квалифицированный оборот",
    "My claimed rewards": "Мои заявленные награды",
    "My pending rewards": "Мои ожидаемые награды",
    "Week": "Неделя",
    "Month": "Месяц",
    "In order to get qualified for any pool, you’ll have to follow the 50% turnover rule: The maximum share each direct partner can contribute to the required turnover is up to 50% of the turnover coming from NFT sales.": "Чтобы пройти квалификацию для любого пула, вам нужно будет следовать правилу 50% оборота: максимальная доля, которую каждый прямой партнер может внести в требуемый оборот, составляет до 50% от оборота, поступающего от продаж NFT.",
    "View more": "Посмотреть больше",
    "Turnover progress": "Прогресс оборота",
    "Thank you for your participation in Global Line Incentive!": "Спасибо за участие в программе Global Line Incentives!",
    "Global line before June 2023": "Глобальная линия до июня 2023 г.",
    "Global line before March 2023": "Глобальная линия до марта 2023 г.",
    "More info": "Подробнее",
    "Your Position": "Твоя позиция",
    "Last Position": "Последняя позиция",
    "My line length": "Длина моей линии",
    "Global Line Turnover": "Глобальный оборот линии",
    "The turnover generated from all participants who joined the Global Line after you.": "Оборот, полученный от всех участников, присоединившихся к Global Line после вас.",
    "Your purchases": "Ваши покупки",
    "You need purchases of at least $100 to become a Global Line member.": "Чтобы стать участником программы Global Line, вам необходимо совершить покупку на сумму не менее 100 долларов.",
    "Your pending income": "Ваш ожидаемый доход",
    "Buy more NFTs to increase your shares": "Купите больше <strong><i>NFT</i></strong>, чтобы увеличить свои <strong>доли.</strong>",
    "New Registrations": "Новые регистрации",
    "Turnover": "Оборот",
    "Global level": "Глобальный уровень",
    "Joining time": "Время присоединения",
    "Global Line Total Turnover": "Глобальный общий оборот линии",
    "Global Line end": "Конец глобальной линии",
    "Please confirm your claim": "Пожалуйста, подтвердите свою претензию",
    "After you confirm, you will receive your rewards on your balance.": "После подтверждения вы получите вознаграждение на свой баланс.",
    "Select Language": "Выберите язык",
    "Download": "Скачать",
    "Your Income": "Ваш доход",
    "Shares": "Акции",
    "Single Miner": "Один майнер",
    "Super Bundles": "Супернаборы",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%.": "Максимальная доля, которую каждый прямой партнер может внести в требуемый оборот, составляет до 50%.",
    "Your partners": "Ваши партнеры",
    "Generated turnover": "Сгенерированный оборот",
    "Qualified turnover": "Квалифицированный оборот",
    "Generated": "Сгенерировано",
    "Qualified": "Квалифицированный",
    "Your partners turnover statistics will appear here.": "Здесь появится статистика оборотов ваших партнеров.",
    "Pools": "Пулы",
    "Qualification": "Квалификация",
    "Rewards": "Награды",
    "Participants": "Участники",
    "Total rewards": "Всего наград",
    "Rewards per participant": "Награды за участника",
    "Land sales contribution": "Вклад от продажи земли",
    "Maintainance contribution": "Вклад в обслуживание",
    "Distributed": "Распределено",
    "Refresh data": "Обновить данные",
    "You will receive your rewards directly on your wallet": "Вы получите вознаграждение прямо на свой кошелек",
    "Total claimed": "Всего востребовано",
    "Next distribution": "Следуещее распределение",
    "Unclaimed BUSD rewards": "Незатребованные награды BUSD",
    "Required level": "Требуемый уровень",
    "NFT sales percent": "Продажи NFT {n}%",
    "Total qualified participants": "Всего квалифицированных участников",
    "TxID": "TxID",
    "Tx Type": "Тип транзакций",
    "Global line reward": "Награда за глобальную линию",
    "Your share": "Ваша доля",
    "Income before": "Доход до",
    "Income after": "Доход после",
    "NFT Income": "Доход NFT",
    "NFT Commissions Total": "Всего Комиссий NFT",
    "NFT Statistic": "Статистика NFT",
    "Level": "Уровень",
    "User": "Пользователь",
    "Your current pin": "Ваш текущий пин",
    "Turnover for the next pin": "Оборот для следующего пина",
    "Your next incentive": "Ваше следующее поощрение",
    "Your next bonus": "Ваш следующий бонус",
    "Incentives": "Стимулы",
    "Team of": "Команда",
    "Partners": "Партнеры",
    "Cash Bonus": "Денежный бонус",
    "Your pin": "Ваш пин",
    "Incentive": "Поощрение",
    "Pin": "Пин",
    "Level pool": "уровень пула",
    "Go to PARTNER": "Перейти к {PARTNER}",
    "Partnerships": "Партнеры",
    "Cannot find EMAIL_OR_WALLET": "Не можем найти {EMAIL_OR_WALLET}",
    "Incentive Ends In: ": "Стимул <strong>заканчивается</strong>:",
    "Your Rewards History": "Ваша история наград",
    "Dubai & Sofia Trip": "Поездка в Дубай и Софию",
    "No Rewards": "Нет наград",
    "No Partners": "Нет партнеров",
    "Reward": "Награда",
    "Granted": "Успешный",
    "Transaction": "Транзакция",
    "Free BUNDLE": "Бесплатно {BUNDLE}",
    "Team Purchases": "Командные покупки",
    "Direct Sales": "Прямые продажи",
    "You have won this reward": "Вы выиграли эту награду",
    "50% Turnover Rule is applied for all incentives!": "Правило оборота 50% применяется для всех стимулов!",
    "Please note that you have the chance to earn a complimentary R8 Bundle in two different ways: either by reaching 20k in direct sales or by achieving 100k in team purchases.": "Обратите внимание, что у вас есть шанс получить бесплатный набор R8 двумя разными способами: либо набрав 20 000 в прямых продажах, либо набрав 100 000 на командных покупках.",
    "Insufficient funds for gas, please add BNB to your wallet": "Недостаточно средств для газа, добавьте BNB в свой кошелек",
    "Please note that you will require up to AMOUNT worth of CURRENCY to complete the transaction.": "Пожалуйста, обратите внимание, что для завершения транзакции вам потребуется {amount} в {currency}.",
    "Please note that AMOUNT worth of CURRENCY is required to complete the transaction.": "Обратите внимание, что для завершения транзакции требуется {amount} в {currency}.",
    "Please note that AMOUNT_CURRENCY is required for gas fee": "Обратите внимание, что для оплаты газа требуется {amount_currency}",
    "Select a payment method": "Выберите способ оплаты",
    "Use your account's wallet balances to purchase the product.": "Используйте баланс кошелька вашего аккаунта для покупки товара.",
    "Connect a WEB3 wallet like Metamask or Trustwallet and pay with {currency}. This requires a small amount of BNB ($0.30) to cover the transaction fees!": "Подключите WEB3-кошелек, например Metamask или Trustwallet, и оплатите с помощью {currency}. Для этого потребуется небольшое количество BNB ($0.30) для покрытия комиссии за транзакцию!",
    "Select one of the available blockchains and cryptocurrencies, and proceed with the payment via an external wallet application.": "Выберите один из доступных блокчейнов и криптовалют и проведите платеж через внешнее приложение кошелька.",
    "Internal wallet": "Внутренний кошелек",
    "Web3 Wallet": "Кошелек Web3",
    "External wallet": "Внешний кошелек",
    "Select Currency": "Выбрать Валюту",
    "Select from the available balances": "Выбрать из доступных балансов",
    "Purchase for myself": "Купить для себя",
    "Select Blockchain": "Выбрать Блокчейн",
    "Select Cryptocurrency": "Выбрать Криптовалюту",
    "Please select another payment method": "Пожалуйста, выберите другой способ оплаты",
    "Connect my wallet": "Подключить мой кошелек",
    "Select a product owner": "Выберите владельца продукта",
    "Please select who you are purchasing for:": "Пожалуйста, выберите, для кого вы покупаете:",
    "Product Owner Tooltip": "Всплывающая Подсказка о Владельце Продукта",
    "Owner e-mail": "Электронная почта владельца",
    "Purchase Summary": "Сводка о покупке",
    "Network": "Сеть",
    "Fees": "Сборы",
    "Rate": "Тариф",
    "Send to wallet address": "Отправить на адрес кошелька",
    "Or scan the code with your wallet app to fill in address automatically.": "Или отсканируйте код с помощью вашего приложения для кошелька, чтобы автоматически заполнить адрес.",
    "Send only CURRENCY to this wallet address!": "Отправьте только {currency} на этот адрес кошелька!",
    "Please send your crypto within 30 minutes to ensure a fixed exchange rate": "Пожалуйста, отправьте вашу криптовалюту в течение 30 минут, чтобы обеспечить фиксированный курс обмена",
    "I sent the crypto": "Я отправил криптовалюту",
    "Insufficient funds": "Недостаточно средств",
    "Purchase Successful": "Покупка Успешна!",
    "The NFT balance was successfully updated and can be viewed in My NFTs tab.": "Вы можете отслеживать статус вашей транзакции в \"Мои заказы\" - Если она прошла успешно, ваш баланс NFT будет обновлен во вкладке \"Мои NFT\".",
    "Available balance": "Доступный баланс",
    "scanning_for_transaction_text": "Вы можете отслеживать статус вашей транзакции в \"Мои заказы\" - Если она прошла успешно, ваш баланс NFT будет обновлен во вкладке \"Мои NFT\".",
    "This window will close automatically once we receive your crypto.": "Это окно закроется автоматически, как только мы получим вашу криптовалюту.",
    "We did receive your crypto, but it was less than required. Please transfer the missing amount to complete your transaction.": "Мы получили вашу криптовалюту, но она была меньше, чем требуется. Пожалуйста, переведите недостающую сумму для завершения транзакции.",
    "Please do not attempt to do another transaction. Send your Payment UID to our support.": "Срок действия транзакции истек: пожалуйста, не пытайтесь провести еще одну транзакцию. Отправьте ID Транзакции в нашу службу поддержки.",
    "Scanning for transaction": "Сканирование транзакции",
    "Go to My Orders": "Перейдите в Мои Заказы",
    "remaining": "остаток",
    "Please send your crypto within 3 hours to ensure a fixed exchange rate": "Пожалуйста, отправьте свою криптовалюту в течение 3 часов, чтобы обеспечить фиксированный обменный курс.",
    "Please send your crypto within n minutes to ensure a fixed exchange rate": "Пожалуйста, отправьте свою криптовалюту в течение {N} минут, чтобы обеспечить фиксированный обменный курс.",
    "Missing crypto amount": "Недостающая сумма криптовалюты",
    "Missing amount": "Недостающая сумма",
    "Recived amount": "Полученная сумма",
    "Required amount": "Требуемая сумма",
    "Continue with payment": "Продолжить платеж",
    "Your payment has expired. To resolve this issue, please contact customer support": "Срок действия вашего платежа истек. Для решения этой проблемы обратитесь в службу поддержки",
    "Your payment was refunded. AMOUNT CURRENCY was returned to your wallet.": "Ваш платеж был возвращен. {amount} {currency} было возвращено на ваш кошелек.",
    "You can monitor the transaction status in the Order History tab. If it is successful, the NFT balance will be updated in the My NFTs tab.": "Вы можете следить за статусом транзакции во вкладке «История заказов». В случае успеха баланс NFT будет обновлен во вкладке «Мои NFT».",
    "OFF": "ВЫКЛЮЧЕННЫЙ",
    "Received Amount.": "Полученная сумма.",
    "Processing Fee": "Разовая комиссия",
    "Estimated gas fee": "Расчетная плата за газ",
    "Please indicate the intended recipient of the product. You have the option to purchase it for yourself or choose 'Another user' as the recipient.": "Пожалуйста, укажите адресата продукта. У вас есть возможность приобрести его для себя или выбрать в качестве получателя \"Другой пользователь\".",
    "Please choose your preferred payment method to finalize your purchase.": "Пожалуйста, выберите предпочтительный способ оплаты для завершения покупки.",
    "Your payment has expired. To resolve this issue, please contact customer support.": "Ваш платеж истек. Чтобы решить эту проблему, пожалуйста, свяжитесь со службой поддержки.",
    "Your payment has been received and is currently being processed. We will notify you once the process is complete.": "Ваш платеж был получен и в настоящее время обрабатывается. Мы уведомим вас о завершении процесса.",
    "Your transaction has been declined. Please attempt the transaction again.": "Ваша транзакция была отклонена. Пожалуйста, повторите транзакцию.",
    "Monitor the status of your transaction by visiting the provided link:": "Отслеживайте статус вашей транзакции, перейдя по указанной ссылке:",
    "Apologies, an error occurred on the blockchain side. Please try again.": "Извините, произошла ошибка на стороне блокчейна. Пожалуйста, попробуйте еще раз.",
    "Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.": "Пожалуйста, дождитесь завершения текущей транзакции на блокчейне, прежде чем инициировать новую.",
    "Transaction Expired": "Срок действия транзакции истек",
    "Enable Payment": "Включить оплату",
    "Email does not exist": "Электронная почта не существует",
    "Request another quote": "Запросить другую цитату",
    "Purchase Success!": "Удачной покупки!",
    "Purchase for another user": "Покупка для другого пользователя",
    "Pay": "Платить",
    "You purchased GIFT for RECEIVER!": "Вы приобрели {gift} для {receiver}!",
    "You've purchased GIFT for RECEIVER!": "Вы приобрели {gift} для \"{receiver}\"!",
    "You've purchased a GIFT for RECEIVER!": "Вы приобрели {gift} для \"{receiver}\"!",
    "Rewards claimed successfully": "Награды получены успешно",
    "Commissions claimed successfully.": "Комиссии заявили успешно.",
    "You received GIFT from SENDER!": "Вы получили {gift} от {sender}!",
    "You've received GIFT from SENDER!": "Вы получили {gift} от \"{sender}\"!",
    "You've received a GIFT from SENDER!": "Вы получили {gift} от пользователя \"{sender}\"!",
    "Transaction Failed": "Falha na transação",
    "The transaction has failed. Please try again.": "Транзакция не удалась. Пожалуйста, попробуйте еще раз.",
    "Your transaction has been declined. Please add BNB to your wallet and attempt the transaction again.": "Sua transação foi recusada. Por favor, adicione BNB à sua carteira e tente a transação novamente.",
    "Blockchain Notification: Apologies, an error occurred on the blockchain side. Please try again.": "Notificação Blockchain: Desculpas, ocorreu um erro no lado blockchain. Por favor, tente novamente.",
    "Apologies, an unexpected error occurred. Please retry your request. If the issue persists, please contact support.": "Desculpas, ocorreu um erro inesperado. Tente novamente sua solicitação. Se o problema persistir, entre em contato com o suporte.",
    "Received Amount": "Полученная сумма",
    "Dual Miner": "Двойной майнер",
    "Bitopex": "Bitopex",
    "Gymnet": "Gymnet",
    "No enough CURRENCY to cover gas fee.": "Недостаточно {currency} для оплаты платы за газ.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover gas fee.": "Пополните свой кошелек на {amount_currency}, чтобы покрыть плату за газ.",
    "Scanning": "Сканирование",
    "To continue, please enable payment.": "Чтобы продолжить, включите оплату.",
    "Kindly be aware that AMOUNT_CURRENCY may be necessary to cover the gas fee.": "Обратите внимание, что {amount_currency} может потребоваться для покрытия платы за газ.",
    "Insufficient funds in CURRENCY to cover the gas fee.": "Недостаточно средств в {currency} для покрытия платы за газ.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover the gas fee.": "Пополните свой кошелек на {amount_currency}, чтобы покрыть плату за газ.",
    "We will cover the gas fee for this transaction on your behalf.": "Мы будем покрывать газовую плату за эту транзакцию за нас счет.",
    "Value": "Значение",
    "Reach Rank": "Ранг Охвата <b>{n}</b>",
    "Rewards in Gymnet": "Вознаграждения в Gymnet",
    "Rewards in Bitcoin": "Вознаграждения в Bitcoin",
    "For Limited Time Only": "Только <strong>в течение Ограниченного Времени</strong> ",
    "Days": "Дни",
    "Hours": "Часы",
    "Unclaimed parcels": "Невостребованные участки",
    "Upgraded parcels": "Обновленные участки",
    "Inactive Miners": "Неактивные майнеры",
    "Active miners": "Активные майнеры",
    "Available Balance": "Доступный Баланс",
    "Utility Balance": "Utility Баланс",
    "Bitopex Balance": "Bitopex Баланс",
    "Moonberg Balance": "Moonberg Баланс",
    "Balance History": "История Баланса",
    "Miner Hashpower": "Майнера hashpower",
    "Dual Miners": "Двойные Майнеры",
    "Dual miners info": "Количество активных и общее количество двойных майнеров.",
    "Dual Miner Hashpower": "Двойных Майнеров Hashpower",
    "Dual miners hashpower info": "Ваша hashpower определяется умножением количества активных двойных майнеров на hashpower единицы майнера, которая установлена на уровне 1000Mh/s на единицу.",
    "Unlock the full potential of your Bitopex rewards as they come to life following your purchase of any of our special bundles. Once your Bitopex Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Раскройте весь потенциал ваших вознаграждений Bitopex, поскольку они становятся реальностью после покупки любого из наших специальных пакетов. Как только ваш Баланс Bitopex становится доступным, вы получаете доступ к обзору ваших общих вознаграждений, полученных вознаграждений и ожидаемых вознаграждений. Кроме того, таймер обратного отсчета показывает время следующей раздачи вознаграждений, гарантируя, что вы никогда не пропустите их получение.",
    "Sec": "Сек",
    "Min": "Мин",
    "Seconds": "Секунды",
    "Minutes": "Минуты",
    "Total Rewards Expected": "Ожидаемое Общее Вознаграждение",
    "Monthly Distribution": "Ежемесячная Раздача",
    "Price Projection": "Ценовой Прогноз",
    "Server error": "Ошибка сервера",
    "Average Gymnet ROI for the last 9 months": "Средний ROI Gymnet за последние 9 месяцев",
    "Dual Miners will generate rewards in Bitopex tokens, which will be distributed every Friday.":"Двойные Майнеры будут генерировать вознаграждения в токенах Bitopex, которые будут раздаваться каждую пятницу.",
    "Introducing the Utility Balance feature, a new and convenient payment method within our payment ecosystem. With this feature, you'll enjoy a separate balance that can be used alongside other payment methods. It provides you with the flexibility to partially utilize your utility balance, up to 25% of the total when purchasing single Land NFTs or making payments for Land NFT bundles.":"Представляем функцию Utility Balance, новый и удобный способ оплаты в нашей платежной экосистеме. Благодаря этой функции вы получите отдельный баланс, который можно использовать наряду с другими способами оплаты. Это дает вам возможность частично использовать свой баланс, до 25% от общей суммы при покупке отдельных Land NFT или пакетов с Land NFT.",
    "Balance before": "Баланс до",
    "Balance after": "Баланс после",
    "TXID": "TXID",
    "The number of active and total miners you have in Gymstreet and Kabutochō, respectively.": "Количество активных и общего числа майнеров, которыми вы располагаете в Gymstreet и Kabutochō, соответственно.",
    "The hashpower you possess is determined by multiplying the number of your active miners with the unit miner hashpower, which is set at 1000Mh/s per unit.": "Ваша hashpower определяется умножением количества ваших активных майнеров на hashpower единицы майнера, которая установлена на 1000Mh/s на единицу.",
    "Token": "Token",
    "Buy Bundles": "Купить Пакеты",
    "Total Received": "Всего Получено",
    "Next Rewards Distribution In:": "Следующая Раздача Вознаграждений Через:",
    "Total Rewards": "Всего Вознаграждений",
    "Payout": "Выплата",
    "Weekly": "Еженедельно",
    "Next Distribution Amount": "Следующая сумма распределения",
    "Internal transaction": "Внутренняя транзакция",
    "Minimum claimable amount is (AMOUNT)": "Минимальная сумма требования: {amount}",
    "User Profile": "Профиль Пользователя",
    "Name": "Имя",
    "Nationality": "Гражданство",
    "Contact Information": "Контактная Информация",
    "Country": "Страна",
    "City": "Город",
    "Street": "Улица",
    "Zip": "Почтовый индекс",
    "E-Mail": "Электронная почта",
    "Wallet address": "Адрес кошелька",
    "Name is required": "Имя обязательно",
    "Nationality is required": "Гражданство обязательно",
    "Country is required": "Страна обязательна",
    "City is required": "Город обязательно",
    "Street is required": "Улица обязательна",
    "Zip is required": "Почтовый индекс обязателен",
    "Successfully updated": "Успешно обновлено",
    "Zip contain only numbers and min length is 3": "Почтовый индекс содержит только цифры и минимальная длина равна 3",
    "Please enter a valid email address": "Пожалуйста, введите действительный адрес электронной почты",
    "Personal information": "Личная Информация",
    "T&C": "T&C",
    "Field is required": "Поле обязательно",
    "Password successfully changed": "Пароль успешно изменен",
    "Old password is incorrect": "Старый пароль неверен",
    "Oops...Something went wrong. Please try again": "Упс... Что-то пошло не так. Пожалуйста, попробуйте еще раз",
    "Name must be at least 3 characters": "Имя должно быть не менее 3 символов",
    "City must be at least 3 characters": "Город должен быть не менее 3 символов",
    "Street must be at least 3 characters": "Улица должна быть не менее 3 символов",
    "Affiliate Terms and conditions": "Партнерские положения и условия",
    "Affiliate links": "Партнерские ссылки",
    "Affiliate link successfully changed": "Партнерская ссылка успешно изменена",
    "Please enter the Bitopex affiliate link here. Kindly note that you can only save the link once.": "Пожалуйста, введите здесь партнерскую ссылку Bitopex. Обратите внимание, что сохранить ссылку можно только один раз.",
    "Input cannot be only spaces": "Ввод не может быть только пробелами",
    "To proceed with the registration, kindly accept the above 2 conditions by checking the corresponding boxes.": "Чтобы продолжить регистрацию, примите 2 вышеуказанных условия, установив соответствующие флажки.",
    "I hereby confirm that I am not a US citizen": "Настоящим подтверждаю, что я не гражданин США",
    "I hereby confirm that I am not a UAE citizen": "Настоящим подтверждаю, что я не гражданин ОАЭ",
    "Enter Old Password": "Введите старый пароль",
    "Change username & password": "Изменить имя пользователя и пароль",
    "My wallet": "Мой кошелек",
    "Purchase": "Покупка",
    "Username required to proceed": "Имя пользователя требуется для продолжения",
    "Enter preferred username here": "Введите предпочтительное имя пользователя здесь",
    "Username must be at least 8 characters, starting with a letter. Use only lowercase letters, numbers, hyphens, dots, and underscores.": "Имя пользователя должно быть не менее 8 символов, начиная с буквы. Используйте только строчные буквы, цифры, дефисы, точки и символы подчеркивания.",
    "Username is already taken. Please choose a different username.": "Данное имя пользователя уже занято. Выберите другое имя пользователя.",
    "Email or username": "Электронная почта или имя пользователя",
    "Username is available": "Имя пользователя доступно",
    "Provided username is already taken.": "Предоставленное имя пользователя уже занято.",
    "Invalid credentials. Please try again.": "Недействительные учетные данные.\n Пожалуйста, попробуйте еще раз.",
    "Username successfully set": "Имя пользователя успешно установлено",
    "Dear User, due to ongoing updates to our platform, you will need to enter a username to continue. The username needs to be unique and cannot be changed": "Уважаемый пользователь, в связи с постоянными обновлениями нашей платформы вам потребуется ввести имя пользователя, чтобы продолжить. Имя пользователя должно быть уникальным и не может быть изменено",
    "You have successfully set your username": "Вы успешно установили свое имя пользователя",
    "Continue to dashboard": "Перейти к приборной панели",
    "Username is required": "Имя пользователя требуется",
    "Rank Transfer": "Перенос ранга",
    "Review": "Обзор",
    "Top Up": "Пополнить",
    "The claim button is currently disabled for maintenance. We apologize for the inconvenience. Please come back shortly.": "Кнопка претензии в настоящее время отключена на техническое обслуживание. Приносим свои извинения за доставленные неудобства. Пожалуйста, вернитесь в ближайшее время.",
    "Basic Miner": "Базовый Майнер",
    "parcel": "участок",
    "active": "активный",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / BUSD": "При реализации нового бизнес-здания у вас будет возможность приобрести землю, оплатив ее с помощью GYMNET / BUSD",
    "Parcel NFT": "Участок NFT",
    "Miner NFT": "Майнер NFT",
    "BUSD Balance": "Баланс BUSD",
    "Your payment has been received. It is currently being processed. You will receive a notification when done.": "Ваш платеж получен. В настоящее время он обрабатывается. Вы получите уведомление, когда все будет готово.",
    "Terms & Conditions": "Условия и Положения",
    "Error": "Ошибка",
    "Please enter valid email": "Пожалуйста, введите действительный адрес электронной почты",
    "Your sponsor is": "Ваш спонсор является",
    "Credentials": "Учетные данные",
    "Enter Wallet Address (i.e. 0xb794f...79268)": "Введите Адрес Кошелька (например, 0xb794f...79268)",
    "Contact Customer Support": "Свяжитесь со Службой Поддержки",
    "Change Wallet": "Изменить Кошелек",
    "Account": "Аккаунт",
    "Please leave an address here, in which we can also mint your NFTs afterwards.": "Пожалуйста, оставьте здесь адрес, на который мы сможем впоследствии майнить ваши NFT.",
    "Important!": "Важно!",
    "It should be a BEP-20 address - i.e. a BNB Smart Chain wallet address. We recommend the wallet Metamask or Trustwallet.": "Это должен быть адрес BEP-20 - т.е. адрес кошелька BNB Smart Chain. Мы рекомендуем кошелек Metamask или Trustwallet.",
    "NEVER USE AN EXCHANGE Wallet ADRESS !!!": "НИКОГДА НЕ ИСПОЛЬЗУЙТЕ АДРЕС ОБМЕННОГО Кошелька!!!",
    "Failed": "Не удалось",
    "Account changed": "Аккаунт изменен",
    "Your Profile has been updated": "Ваш Профиль был обновлен",
    "You already have an attached wallet address to this account.": "У вас уже есть привязанный адрес кошелька к этой учетной записи.",
    "Login": "Логин",
    "Existing wallet": "Существующий кошелек",
    "Referral ID is required": "Реферальный ID обязателен",
    "Pending Transaction": "Ожидаемая Транзакция",
    "Processing": "Обработка",
    "Send this payment ID to a customer support when you are requested to do so.": "Отправьте этот ID платежа в службу поддержки, когда вас об этом попросят.",
    "To be able to claim your NFTs you need to connect the same wallet address attached to your account": "Чтобы иметь возможность получить свои NFT, необходимо подключить тот же адрес кошелька, который привязан к вашему аккаунту",
    "Please confirm your email to continue.": "Пожалуйста, подтвердите свой электронный адрес для продолжения.",
    "Valid address": "Действительный адрес",
    "This social account is used by another user. Verify your email first or contact Customer Support": "Этот социальный аккаунт используется другим пользователем. Сначала проверьте свою электронную почту или обратитесь в Службу поддержки",
    "Invalid wallet connection": "Недействительное подключение кошелька",
    "Registration completed successfully": "Регистрация завершена успешно",
    "Failed to complete registration of the user": "Не удалось завершить регистрацию пользователя",
    "Failed to register user": "Не удалось зарегистрировать пользователя",
    "Signature grant failed": "Не удалось получить право подписи",
    "Unsupported chain": "Неподдерживаемая цепочка",
    "This wallet address is already associated with another user account. Please use another one!": "Этот адрес кошелька уже связан с другой учетной записью пользователя. Пожалуйста, используйте другой!",
    "Failed to add address to your wallet": "Не удалось добавить адрес к вашему кошельку",
    "Address has been successfully added to your wallet": "Адрес успешно добавлен к вашему кошельку",
    "Failed to accept Affiliate TMS": "Не удалось принять Партнерский TMS",
    "Wallet address has been successfully applied": "Адрес кошелька был успешно применен",
    "Your email is verified.": "Ваша электронная почта верифицирована.",
    "Failed to add Token": "Не удалось добавить Токен",
    "Failed to upload image": "Не удалось загрузить изображение",
    "Wrong file size. Please upload image files with size less than 10MB": "Неверный размер файла. Пожалуйста, загрузите файлы изображений размером менее 10 МБ",
    "We've verified your email address. You can now continue using the site.": "Мы верифицировали ваш адрес электронной почты. Теперь вы можете продолжить работу с сайтом.",
    "You need to switch to Binance Smart Chain": "Вам необходимо перейти на Binance Smart Chain",
    "Switch to Binance Smart Chain to continue using GymStreet": "Перейдите на Binance Smart Chain для продолжения использования GymStreet",
    "To CashFT": "К CashFT",
    "Buy a Super Bundle in the next": "Купите Супер Пакет в следующем",
    "and get an EXTRA discount!": "и получите ЭКСТРА-скидку!",
    "See All Offers": "Посмотреть Все Предложения",
    "I accept the affiliate terms & conditions": "Я принимаю партнерские положения и условия",
    "Special offer!": "Специальное предложение!",
    "Just one": "Только одно",
    "1000 Mh/s": "1000 Mh/s",
    "View Claimed Coordinates": "Посмотреть Заявленные Координаты",
    "Your Parcel Coordinates": "Ваши Координаты Участка",
    "Number of miners you want to attach": "Количество майнеров, которые вы хотите прикрепить",
    "Referral Link": "Реферальная Ссылка",
    "Affiliate Link": "Партнерская Ссылка",
    "Invalid Wallet": "Недействительный Кошелек",
    "Back to Log In": "Вернуться к Входу",
    "You need a valid referral ID to finalize your registration": "Вам необходим действительный реферальный ID для завершения регистрации",
    "I have read and accept the": "Я прочитал и принимаю",
    "Your Sponsor": "Ваш Спонсор",
    "Forgot Your Password": "Забыли Пароль",
    "Enter your email address and we will send you instructions to reset your password.": "Введите свой адрес электронной почты, и мы вышлем вам инструкции по сбросу пароля.",
    "We just sent you an email to confirm your e-mail address. Please click on the link in the e-mail to reset your password.": "Мы только что отправили вам письмо для подтверждения вашего адреса электронной почты. Пожалуйста, щелкните на ссылке в письме, чтобы сбросить пароль.",
    "Welcome To Metaverse": "Добро пожаловать в {n} Метавселенную",
    "Commissions": "Комиссионные",
    "Refund of electricity vouchers": "Возврат ваучеров электроэнергии",
    "Import Custom Token": "Импортировать Пользовательский Токен",
    "Add Token To Wallet": "Добавить Токен в Кошелек",
    "Token contract address": "Адрес контракта на Токен",
    "Token symbol": "Символ Токена",
    "Provided address is not a supported token address": "Указанный адрес не является поддерживаемым адресом токена",
    "Are you sure you want to claim your rewards": "Вы уверены, что хотите получить свое вознаграждение",
    "OTP Code": "Код OTP",
    "OTP Code is required": "Код OTP необходим",
    "Enter OTP code you have received at email": "Введите код OTP, полученный на {email}",
    "Asset": "Актив",
    "Gas": "Газ",
    "Send crypto": "Отправить криптовалюту",
    "Enter wallet address": "Введите адрес кошелька",
    "NFTs": "NFT",
    "Activity": "Активность",
    "Assets": "Активы",
    "Import token": "Импортировать токен",
    "Don't see your token?": "Не видите свой токен?",
    "more": "больше",
    "Send": "Отправить",
    "Sending success": "Отправка успешна",
    "View account in explorer": "Посмотреть аккаунт в эксплорере",
    "estimated": "предполагаемый",
    "Copy your address": "Скопировать свой адрес",
    "Export private key": "Экспортировать личный ключ",
    "Receive": "Получить",
    "Account Details": "Детали Аккаунта",
    "View on blockchain": "Просмотр на блокчейне",
    "GymStreet Parcels": "Участки GymStreet",
    "GymStreet Miners": "Майнеры GymStreet",
    "Kabutocho Parcels": "Участки Kabutocho",
    "Kabutocho Miners": "Майнеры Kabutocho",
    "Invalid wallet address": "Недействительный адрес кошелька",
    "Calculate": "Рассчитать",
    "Sending": "Отправка",
    "Reject": "Отклонить",
    "Confirmation": "Подтверждение",
    "Gas fees are paid to crypto miners who process transactions on the network. Gas fees are set by the network and fluctate based on network traffic and transaction complexity.": "Газовые комиссионные выплачиваются майнерам криптовалют, которые обрабатывают транзакции в сети. Газовые комиссионные устанавливаются сетью и колеблются в зависимости от трафика сети и сложности транзакции.",
    "Insufficient TOKEN balance": "Недостаточный баланс {token}",
    "Confirm Swap": "Подтвердите обмен",
    "Approve Allowance": "Утвердить разрешение",
    "Help Center": "Справочный центр",
    "Confirm the Allowance Transaction in order to be able to use Token through our services. Please note that this is a one-time action.": "Подтвердите разрешающую транзакцию для того, чтобы иметь возможность использовать <strong>{n}</strong> Токен через наши сервисы. Пожалуйста, обратите внимание, что это одноразовое действие.",
    "No Activities yet": "Никаких действий пока нет",
    "To": "До",
    "Contract interaction": "Взаимодействие по договору",
    "Are you sure you want to delete this token": "Вы уверены, что хотите удалить этот токен",
    "Send only using  {CHAIN}  network to receive funds on this deposit address.": "Для получения средств на этот адрес депозита отправляйте только через сеть {CHAIN}.",
    "Pool statistics": "Статистика пула",
    "Hourly": "Почасовая",
    "Turnover rule": "Правило оборота",
    "Regular Bundles": "Регулярные пакеты",
    "Turnover rule percentage": "{n}% Правило оборота",
    "Miner sales contribution": "Вклад майнера в продажи",
    "Pool": "Пул",
    "percent of NFT sales": "{n}% от продаж NFT",
    "partner": "партнер",
    "Main area": "Основная область",
    "Turnover percentage rule": "Правило {n}% оборота",
    "With dollar each": "С каждого доллара",
    "Direct partners": "Прямые партнеры",
    "Your next bonus will appear here": "Ваш следующий бонус появится здесь",
    "Your incentive": "Ваше вознаграждение",
    "Your cash bonus": "Ваш денежный бонус",
    "Your bonus history": "Ваша история бонусов",
    "At the current moment you don't have any rewards": "В данный момент у вас нет вознаграждений",
    "Partners with pin qualification": "Партнеры с {n} пин-квалификацией",
    "Total generated turnover": "Общий сгенерированный оборот",
    "Inactive miners": "Неактивные майнеры",
    "Active Miners": "Активные майнеры",
    "Total pool rewards": "Общее вознаграждение пула",
    "Your pool share": "Ваша доля в пуле",
    "Total gGymnet": "Всего gGymnet",
    "GYMNET Commissions Total": "Общая сумма комиссионных GYMNET",
    "Your attached wallet is {walletAddress}. You have tried to make request with wallet {currentWallet}. Please change connected wallet to {walletAddress}": "Ваш привязанный кошелек - <b>{walletAddress}</b>. Вы пытались сделать запрос с кошелька <b>{currentWallet}</b>. Пожалуйста, измените привязанный кошелек на <b>{walletAddress}</b>",
    "Available for limited time": "Доступно в течение ограниченного времени",
    "Bonus": "Бонус",
    "Utility": "Утилита",
    "The URL must begin with 'https://bitopex.io/home/' and include your referral ID": "URL-адрес должен начинаться с 'https://bitopex.io/home/' и включать ваш реферальный ID",
    "Insufficient funds: to continue with payment use utility balance": "Недостаточно средств: для продолжения оплаты используйте баланс утилиты",
    "My Team": "Моя команда",
    "Team Statistics": "Статистика команды",
    "All partners": "Все партнеры",
    "Active partners": "Активные партнеры",
    "Inactive partners": "Неактивные партнеры",
    "Binary Points": "Бинарные очки",
    "Points in left leg": "Очки в левой ноге",
    "Points in right leg": "Очки в правой ноге",
    "Left leg": "Левая нога",
    "Right leg": "Правая нога",
    "Holding Tank": "Холдинговый резервуар",
    "Enrollment Tree": "Дерево зачислений",
    "Binary Tree": "Бинарное дерево",
    "CV Points History": "История очков CV",
    "Time Left To Activation": "Время <b>до активации</b>",
    "Search by email or wallet": "Поиск по электронной почте или кошельку",
    "Refresh Data": "Обновить данные",
    "Joined at": "Присоединился к",
    "Placement": "Место",
    "Select": "Выбрать",
    "Own": "Собственный",
    "Team": "Команда",
    "Position": "Позиция",
    "CV Points": "Очки CV",
    "CV = commission volume": "CV = объем комиссионных",
    "Genealogy": "Генеалогия",
    "Unilevel": "Одноуровневый",
    "Select view": "Выбрать вид",
    "Team size": "Размер команды",
    "Rank": "Ранг",
    "Own purchases": "Собственные покупки",
    "Navigation": "Навигация",
    "Search by wallet": "Поиск по кошельку",
    "no user": "нет пользователя",
    "Wallet address is not valid": "Адрес кошелька не действителен",
    "Free Spot": "Свободное место",
    "Search by wallet or username": "Поиск по кошельку или имени пользователя",
    "Info": "Инфо",
    "Benefits": "Преимущества",
    "Objectives Completed": "Цели выполнены",
    "TO DO`s": "Список задач",
    "Purchase NFTs In Total Value Of 100 USDT": "Купите NFT на общую сумму 100 USDT",
    "Purchase Binary Pass - 19 USDT": "Купите Бинарный Пропуск - 19 USDT",
    "Team Turnover": "Оборот команды",
    "BASED ON TEAM": "{n}% + ОСНОВАН НА КОМАНДЕ",
    "Qualification Turnover": "Оборот по квалификации",
    "Current Rank": "Текущий ранг",
    "Next Rank": "Следующий ранг",
    "Binary Spot Reservation": "Бронирование бинарного места",
    "Holding Tank Ends In": "Холдинговый резервуар <b>заканчивается через</b>",
    "With every parcel purchase, you will receive two types of tokens—Gymnet and Bitcoin.": "При каждой покупке посылки вы получите токены двух типов — Gymnet и Bitcoin.",
    "A minimum of USDT is required for Purchase": "Для покупки требуется минимум ${n} USDT",
    "Become Affiliate Partner": "Стать аффилированным партнером",
    "Placement in Unilevel": "Размещение на уровне Unilevel",
    "Matching bonuses": "Матчевые бонусы",
    "Placement in Binary": "Размещение в Binary",
    "Product Autoship Incentive": "Стимулирование автоотправки продукта",
    "For limited time only": "Только в течение ограниченного времени",
    "Free 30 days Product Autoship": "Бесплатная 30-дневная автоотправка продуктов",
    "Fast Start Incentive": "Стимулирование быстрого старта",
    "Receive Direct Sales Commissions": "Получать комиссионные за прямые продажи",
    "Please select your role": "Пожалуйста, выберите свою роль",
    "Choose your role before diving in. Specify why user should choose each role and what opportunities are there.": "Выберите свою роль, прежде чем погружаться. Укажите, почему пользователь должен выбрать каждую роль и какие возможности существуют.",
    "is left to get": "<span>${n}</span> осталось получить",
    "Go to Products": "Перейти к продуктам",
    "Rental Income": "Доход от аренды",
    "Left Leg Affiliate Link": "Партнерская ссылка для левой ноги",
    "Right Leg Affiliate Link": "Партнерская ссылка для правой ноги",
    "Qualification Conditions": "Условия квалификации",
    "Buy Products": "Купить продукты",
    "My Current Rank": "Мой текущий ранг",
    "To Reach Rank You Need:": "Для достижения ранга {n} вам нужно:",
    "NFT Turnover In Weak Leg": "Оборот NFT в слабой ноге",
    "NFT Own purchases": "Собственные покупки NFT",
    "Required Qualified Partners": "Требуемые квалифицированные партнеры",
    "Progress Until Rank": "Прогресс до ранга {n}",
    "See Details": "См. детали",
    "Default Advantages": "Преимущества по умолчанию",
    "Binary Commission": "Бинарные комиссионные",
    "Upgrade Your Weekly Cap": "Повысьте свой еженедельный лимит",
    "Rank Benefits": "Преимущества ранга",
    "For 30 Days ONLY You Receive 10% Binary Commission Instead Of NUM": "ТОЛЬКО в течение 30 дней вы получаете 10% бинарных комиссионных вместо {n}%!",
    "My Rank Progress": "Мой прогресс в ранге",
    "Own Invest": "Собственные инвестиции",
    "Qualified Partners": "Квалифицированные партнеры",
    "You need to buy products in value of": "Вам необходимо приобрести продукцию на сумму ${n}",
    "You need to generate turnover in value of in your weak leg.": "Вам необходимо создать оборот в размере ${n} на вашей слабой ноге.",
    "Remaining": "Остаток",
    "CV Points weak leg": "В слабой ноге Очки CV",
    "Details": "Детали",
    "Partner": "Партнер",
    "leg": "нога",
    "Right": "правая",
    "Left": "левая",
    "You need qualified partner in left leg and qualified partner in right leg to complete this requirement.": "Вам нужен {l} квалифицированный партнер в левой ноге и {r} квалифицированный партнер в правой ноге, чтобы выполнить это требование.",
    "partners in left leg and in right leg": "Осталось {l} {partner1} в левой ноге и {r} партнер в правой ноге",
    "partners in left leg remaining": "{l} {partner1} в левой ноге остался",
    "partners in right leg remaining": "{r} партнеров в правой ноге осталось",
    "partner in right leg remaining": "{r} партнер в правой ноге остался",
    "Holding Tank is over": "Холдинговый резервуар закончился",
    "Back To Rank Progress Details": "Вернуться к деталям прогресса ранга",
    "Super Bundle R10 or above": "Супер Пакет R10\n или выше",
    "Required Monthly Product Autoship": "Требуемая ежемесячная автоотправка продукта",
    "All Customer Benefits": "Все преимущества для клиентов",
    "Product Autoship": "Автоотправка продуктов",
    "Product Autoships": "Автоотправки продуктов",
    "Products are resellable": "Продукты можно перепродавать",
    "Activate binary commission": "Активировать бинарную комиссию",
    "Activate direct sales commission": "Активировать комиссию за прямые продажи",
    "Left before": "Левая до",
    "Left after": "Левая после",
    "Right before": "Правая до",
    "Right after": "Правая после",
    "Activate your referral link to be able to invite your referrals to the platform and earn additional commissions.": "Активируйте свою реферальную ссылку, чтобы иметь возможность приглашать своих рефералов на платформу и получать дополнительные комиссионные.",
    "Activate referral link": "Активировать реферальную ссылку",
    "Yes, I confirm": "Да, я подтверждаю",
    "Inactive": "Неактивный",
    "Finance": "Финансы",
    "Total Income": "Общий доход",
    "Products": "Продукты",
    "GymStreet Map": "Карта GymStreet",
    "Kabutocho Map": "Карта Kabutocho",
    "Don't ask again": "Больше не спрашивать",
    "Filecoin Balance": "Баланс Filecoin",
    "Filecoin": "Filecoin",
    "Total available": "Всего доступно",
    "Total generated": "Всего сгенерировано",
    "Total used": "Всего использовано",
    "Transaction type": "Тип транзакции",
    "To claim your commission, you must have a minimum of 1 USDT or (AMOUNT) GYMNET.": "Для получения комиссионного вознаграждения необходимо иметь минимум 1 USDT или {n} GYMNET.",
    "Loading balance...": "Загрузка баланса...",
    "Total Commissions": "Всего комиссионных",
    "Claimed Commissions": "Запрошенные комиссионные",
    "Available Commissions": "Доступные комиссионные",
    "Total Commissions By Source": "Всего комиссионных по источникам",
    "Filter by currency": "Фильтр по валюте",
    "No data to display": "Нет данных для отображения",
    "Year": "Год",
    "Career Status": "Карьерный Статус",
    "Current Incentive": "Текущее стимулирование",
    "Fast Start": "Быстрый старт",
    "Product Autoship incentive": "Стимулирование автоотправки продукта",
    "Direct partner - left leg": "Прямой партнер - левая нога",
    "Direct partner - right leg": "Прямой партнер - правая нога",
    "Receive direct sales commission": "Получить комиссионные за прямые продажи",
    "Direct sales commission": "Комиссионные за прямые продажи",
    "Receive binary commission": "Получить бинарные комиссионные",
    "Affiliate ranks": "Партнерские ранги",
    "1 Left, 1 Right": "1 Левая, 1 Правая",
    "See Benefits": "Посмотреть преимущества",
    "2 Silver (1 in different teams)": "2 Серебро (1 в разных командах)",
    "2 Gold (1 in different teams)": "2 Золото (1 в разных командах)",
    "3 Platin (1 in different teams)": "3 Платина (1 в разных командах)",
    "3 Double Platin (1 in different teams)": "3 Двойная Платина (1 в разных командах)",
    "4 Diamond (1 in different teams)": "4 Бриллиант (1 в разных командах)",
    "5 Double Diamond (1 in different teams)": "5 Двойной Бриллиант (1 в разных командах)",
    "5 Red Diamond (1 in different teams)": "5 Красный Бриллиант (1 в разных командах)",
    "5 White Diamond (1 in different teams)": "5 Белый Бриллиант (1 в разных командах)",
    "Please note that $100 dollar will correspond to 100 points.": "Пожалуйста, обратите внимание, что $100 долларов соответствует 100 очкам.",
    "Home": "Главная",
    "Last Left": "Последняя левая",
    "Last Right": "Последняя правая",
    "Binary leg": "Бинарная нога",
    "by admin": "админом",
    "missed commission": "пропущенные комиссионные",
    "payment": "оплата",
    "commission": "комиссионные",
    "Payment Currency": "Валюта платежа",
    "BUSD Amount": "Сумма BUSD",
    "CV Ratio": "Коэффициент CV",
    "Hrs": "Часы",
    "Mins": "Мин",
    "Secs": "Сек",
    "Please enter a valid referral ID": "Пожалуйста, введите действительный реферальный ID",
    "Commission TXID": "Комиссионные TXID",
    "Qualification turnover": "Оборот квалификации",
    "Binary commission": "Бинарные комиссионные",
    "Additional Advantages": "Дополнительные преимущества",
    "The referral ID you've entered is incorrect. Please copy and paste the correct referral link to proceed.": "Введенный вами реферальный ID некорректен. Пожалуйста, скопируйте и вставьте правильную реферальную ссылку, чтобы продолжить.",
    "In order to receive binary commissions you need to have an active product autoship.": "Для получения бинарных комиссионных необходимо иметь активный продукт автошип.",
    "In order to receive binary commissions you need to have an active product autoship and a purchase for at least $100.": "Для получения бинарных комиссионных необходимо иметь активный автошип и покупку продукта на сумму не менее $100.",
    "To qualify for binary commissions, a minimum product purchase of $100 is required.": "Для получения бинарных комиссионных необходимо, чтобы минимальная покупка продукта составляла 100 долларов.",
    "Transaction in Process": "Транзакция в процессе",
    "Claim your parcel": "Получите свою посылку",
    "You do not have any claimed slots to attach miners. To enable miner attachment, please claim parcels on the map.": "У вас нет заявленных слотов для подключения майнеров. Чтобы включить привязку майнера, запросите посылки на карте.",
    "Active binary commission": "Активная бинарная комиссия",
    "Active direct sales commission": "Активная комиссия прямых продаж",
    "Own purchase min {AMOUNT}": "Собственная покупка мин. {AMOUNT}",
    "Active product autoship": "Активная автодоставка товара",
    "Right partner own purchase min {AMOUNT}": "Собственная покупка правого партнера мин. {AMOUNT}",
    "Left partner own purchase min {AMOUNT}": "Собственная покупка правого партнера мин. {AMOUNT}",
    "Unfortunately, you won't receive the bonus for this rank since you met the qualification conditions (more than 50%) before September 18, 2023": "К сожалению, бонус за этот ранг вы не получите, так как выполнили условия квалификации (более 50%) до 18 сентября 2023 года.",
    "You will be eligible for cash bonus based on your rank if you meet all qualification conditions between September 18 and November 8.Those who met the qualification conditions (more than 50%) before September 18, 2023 will not receive the Super Cash Bonus.": "Вы будете иметь право на получение денежного бонуса в зависимости от вашего ранга, если вы выполните все квалификационные условия в период с 18 сентября по 31 января. Те, кто выполнил квалификационные условия (более 50%) до 18 сентября 2023 г., не получат Суперденежный бонус.",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / USDT": "При реализации нового бизнес-здания у вас будет \n возможность приобрести землю, оплатив ее GYMNET / USDT",
    "yearly": "Ежегодно",
    "Unknown": "Неизвестно",
    "Claim processing may take up to 2 minutes. Thank you for your patience.": "Обработка заявки может занять до 2 минут. Благодарим Вас за терпение.",
    "1 Person": "1 человек",
    "2 Persons": "2 человека",
    "Extended Referral Link": "Расширенная реферальная ссылка",
    "Enter a username": "Введите имя пользователя",
    "Possible only for usernames in your binary team!": "Возможно только для имен пользователей в вашей бинарной команде!",
    "Select Team": "Выберите команду",
    "Create Link": "Создать ссылку",
    "New users will be placed under in the team": "Новые пользователи будут помещены под {n} в команду {p}",
    "Invalid username!": "Неверное имя пользователя!",
    "Left team": "Левая команда",
    "Right team": "Правая команда",
    "Distribution In Progress": "Распределение в процессе",
    "Next Binary Bonus Distribution In": "Следующее распределение бинарных бонусов через",
    "Matching Bonus Commissions": "Комиссионные по соответствующим бонусам",
    "Binary Commissions": "Бинарные комиссионные",
    "Binary Weekly Cap": "Бинарный недельный лимит",
    "Binary Cycles": "Бинарные циклы",
    "Matching Bonus": "Соответствующий бонус",
    "Incomes from Product Autoships": "Доходы от автопоставок товаров",
    "Missed Commissions": "Пропущенные комиссии",
    "Plus Max Weekly Payout": "Плюс максимальная недельная выплата",
    "Import Token With Address": "Импорт токена с адресом",
    "Import Supported Token": "Импорт поддерживаемого токена",
    "Shop": "Магазин",
    "Filter by": "Фильтр по",
    "My Purchases": "Мои покупки",
    "Purchases for other members": "Покупки для других пользователей",
    "Product Autoship Purchases": "Покупки товаров по автопоставке",
    "Sort By": "Сортировать по",
    "Top up": "Пополнить запасы",
    "Attach your miners": "Присоединить ваших майнеров",
    "Buy parcels": "Купить участки",
    "Number of parcels you want to buy": "Количество участков, которые вы хотите купить",
    "Use utility balance": "Используйте баланс utility",
    "My Products": "Мои товары",
    "USDT Amount": "Сумма USDT",
    "Next Matching Bonus Distribution In": "Следующее распределение соответствующих бонусов через",
    "Please top up your wallet with AMOUNT_CURRENCY USDT for upgrade.": "Пожалуйста, пополните свой кошелек на {AMOUNT_CURRENCY} USDT для обновления.",
    "Please note that worth of USDT is required to complete the transaction.": "Пожалуйста, обратите внимание, что для завершения транзакции необходимо пополнить кошелек на сумму {n} USDT.",
    "Incentive Ends In:": "Поощрение заканчивается через:",
    "Bonus History": "История бонусов",
    "Incentive has ended": "Поощрение закончилось",
    "Direct Sales Turnover": "Оборот прямых продаж",
    "Commissions are paid out following a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "Комиссионные выплачиваются в соотношении 70/30, 70% в стабильных монетах и 30% в токенах GYMNET.",
    "(effective as of 28.02.2024)": "(вступает в силу с 28.02.2024)",
    "Free Product Autoship": "Бесплатная автодоставка продукта",
    "10% Binary Commission": "10% Бинарная комиссия",
    "Enjoy More, Benefit More!": "Наслаждайтесь больше, получайте больше пользы!",
    "To qualify for Binary Commissions, you must have a new member with at least $100 on your right team and another on your left team.": "Чтобы претендовать на получение бинарных комиссий, у вас должен быть новый участник с суммой не менее 100 долларов США в правой команде и еще один в левой команде.",
    "Buy products for at least $100 within 30 days to receive these benefits.": "Купите товары на сумму не менее <span>100 долларов США</span> в течение <span>30 дней</span>, чтобы получить эти преимущества.",
    "Getting started": "Начиная",
    "Confirm your email to continue": "Подтвердите свой адрес электронной почты, чтобы продолжить",
    "Resend confirmation email": "Переслать письмо",
    "Super Bundle Special Discount Expires In": "Срок действия специальной скидки Super Bundle истекает",
    "Done": "Выполнено",
    "Get your bundle": "Получите свой пакет",
    "Gain Fresh Perspectives and Exclusive Benefits": "Получите новые перспективы и эксклюзивные преимущества",
    "Super Bundle R8": "Супер Комплект R8",
    "Super Bundle R17": "Супер Комплект R17",
    "Starter Bundle": "Стартовый пакет",
    "Travel": "Путешествие Промо",
    "Cash Promo": "Денежная акция",
    "Monthly Product Autoship is required to be an Affiliate Partner and earn commissions.": "Ежемесячная автодоставка продуктов необходима для того, чтобы быть партнером-партнером и получать комиссионные.",
    "Affiliate": "Партнер",
    "Recommended": "Рекомендовано",
    "Binary Commissions based on the rank": "Бинарные комиссии в зависимости от ранга",
    "Customer": "Клиент",
    "NFT Turbo": "NFT Турбо",
    "Purchase Digital Land": "Купить цифровую землю",
    "Participate in Partnership Deals": "Участвуйте в партнерских сделках",
    "Receive minting Rewards": "Получайте награды за чеканку",
    "Up to": "До {n}%",
    "Weekly Distribution": "Еженедельное распространение",
    "A parcel with minting power will generate rewards in Filecoin tokens, which will be distributed every Monday.": "Посылка с мощностью майнинга будет генерировать вознаграждение в токенах Filecoin, которые будут распределяться каждый понедельник.",
    "It seems that a some request is already pending. Please check your wallet app or extension and try again.": "Кажется, какой-то запрос уже находится на рассмотрении. Пожалуйста, проверьте приложение или расширение кошелька и повторите попытку.",
    "Add miner NFT to wallet": "Добавьте Miner NFT в кошелек",
    "Go to My NFTs": "Зайдите в Мои NFT",
    "Thank you for your participation": "Благодарим за Ваше участие",
    "No owner": "Нет владельца",
    "Ownership of this bundle is limited to one per user. Please purchase for yourself and for those who do not have it.": "Право собственности на этот пакет ограничено одним на пользователя. Приобретайте, пожалуйста, для себя и для тех, у кого его нет.",
    "Buy parcels with minting power and generate income.": "Покупайте посылки с монетной мощностью и получайте доход.",
    // Mystery box
    "Limited time offer": "Ограниченное предложение",
    "See Options": "Посмотреть варианты",
    "Please select one of the currencies": "Пожалуйста, выберите одну из валют",
    "Current balance": "Текущий баланс",
    "Try your luck": "Испытайте свою удачу",
    "Offer ends in": "Предложение заканчивается в",
    "Parcel and Dual Miner": "Посылка и Двойной майнер",
    "Mystery Box": "Загадочная коробка",
    "Try your luck and win incredible Bundles, Parcels and Dual Miners to boost your earnings!": "Испытайте удачу и выиграйте невероятные пакеты, посылки и двойные майнеры, чтобы увеличить свой заработок!",
    "from MIN to MAX": "от <b>{min}</b> до <b>{max}</b>",
    "Choose a Box and Discover Rewards": "Выберите коробку и получите награды",
    "Prizes you can win": "Призы, которые вы можете выиграть",
    "Item": "Элемент",
    "Chance": "Шанс",
    "Promos": "Промоакции",
    "Mystery Box results": "Результаты загадочной коробки",
    "Total reward value": "Общая стоимость вознаграждения",
    "My Rewards": "Мои награды",
    "Rewards Community Claimed": "Сообщество наград получено",
    "Hero image": "Изображение героя",
    "No rewards yet": "Наград пока нет",
    "No winners yet": "Победителей пока нет",
    "While no lucky participants have claimed their prizes yet, the excitement continues to build.": "Хотя ни один счастливчик еще не получил свои призы, ажиотаж продолжает нарастать.",
    "Mystery Box is Here!": "Загадочная коробка уже здесь!",
    "Be the First to Discover Mystery Rewards": "Будьте первым, кто откроет тайные награды",
    "Select Balance": "Выберите Баланс",
    "Current Balance": "Текущий баланс",
    "Opening Mystery Box": "Открытие таинственной коробки",
    "Oops, not this time 😔": "Упс, не в этот раз 😔",
    "Luck is on your side. Enjoy your prize!": "Удача на вашей стороне. Наслаждайтесь своим призом!",
    "The more you try, the better your chances of winning. Keep going!": "Чем больше вы будете стараться, тем выше ваши шансы на победу. Продолжать идти!",
    "Try another box": "Попробуйте другую коробку",
    "Go to My Products": "Перейти в Мои продукты",
    "See promo results": "Посмотреть результаты промо-акций",
    "Payment enabled": "Оплата включена",
    "Please select the wallet that is attached to your account.": "Пожалуйста, выберите кошелек, который привязан к вашей учетной записи.",
    "Explore rewards": "Изучите награды",
    "View rewards claimed by you and the community": "Просмотрите награды, полученные вами и сообществом.",
    "Open a Mystery Box to get rewards starting from Parcels to Super bundles.": "Откройте Загадочная коробка и получите призы, начиная от посылок и заканчивая суперпакетами.",
    "Insufficient funds, please choose another box.": "Недостаточно средств, пожалуйста, выберите другое поле.",
    "Blockchain error": "Ошибка блокчейна",
    "Rewards claimed": "Заявленные вознаграждения",
    "We have encountered difficulties while processing the result of the reward. Please contact our support team for prompt assistance.": "Мы столкнулись с трудностями при обработке результатов вознаграждения. Пожалуйста, свяжитесь с нашей службой поддержки для получения оперативной помощи.",
    "Boxes opened": "Открытые ящики",
    // Mystery box

    // Partnership details
    "About": "О сайте",
    "Offer": "Предложение",
    "Social Media": "Социальные медиа",
    "Additional Content": "Дополнительный контент",
    "Photo": "Фото",
    "Video": "Видео",
    // Partnership details

    // Miner Rewards
    "Single Miners": "Одинокие Майнеры",
    "price today": "цена сегодня",
    "Estimated daily rewards": "Предполагаемые ежедневные награды",
    "day": "день",
    "day at current rate": "день по текущему курсу",
    "Estimated rewards until 2025": "Предполагаемые награды до 2025 года",
    "at current rate": "по текущему курсу",
    "Estimated rewards from today to December 1, 2025. It may change based on your shares in the pool.": "Предполагаемое вознаграждение с сегодняшнего дня по 1 декабря 2025 года. Он может измениться в зависимости от вашей доли в пуле.",
    "Expected rewards calculator": "Калькулятор ожидаемого вознаграждения",
    "price": "цена",
    "My total GYMNET value in USD": "Моя общая стоимость GYMNET в долларах США",
    "claimed": "заявлено",
    "My Balances": "Мои остатки",
    "Tokens to be burned": "Токены для сожжения",
    "You dont have any Claimed or Pending GYMNET rewards.": "У вас нет заявленных или ожидающих наград GYMNET.",
    "Claim now": "Получить сейчас",
    "No claimed GYMNET rewards are found.": "Заявленные награды GYMNET не найдены.",
    // Miner Rewards

    "Please note that the leg with the lesser amount of points is called weak leg and the one with the higher amount of points is called strong or power leg, and it can change over time based on which leg the most turnover is generated.": "Обратите внимание, что этап с меньшим количеством очков называется «слабым», а этап с большим количеством очков называется «сильным» или «сильным», и со временем он может меняться в зависимости от того, на каком этапе больше всего оборотов. генерируется.",
    "Mystery Box price": "Цена Загадочной коробки",

    "Registration Date": "Дата регистрации",
    "First Purchase Date": "Дата первой покупки",
    "Product Autoship Valid Until": "Автодоставка продукта действительна до",

    "Own Deposit": "Собственный депозит",
    "years ago": "лет назад",
    "Rank Achievement Bonus": "Бонус за достижение ранга",
    "Rank achievement bonus eligibility is based on rank achieved through qualifications.": "Право на получение бонуса за достижение ранга зависит от ранга, достигнутого в результате квалификации.",
    "Bonuses": "Бонусы",
    "Bonuses are paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET tokens": "Бонусы выплачиваются в соотношении 70/30, 70% в USDT и 30% в токенах GYMNET.",
    "Rank current": "Ранг {n}",
    "You will receive the bonus after the incentive is over, and you can view it in the 'My Wallet' section.": "Вы получите бонус после окончания стимула и сможете посмотреть его в разделе \"Мой кошелек\".",
    "You must have at least RANK partners, with at least one in a different leg.": "У вас должно быть не менее {rank} партнеров, причем хотя бы один из них должен быть в другой ноге.",
    "Additional Assets": "Дополнительные активы",
    "Back to My wallet": "Назад к моему кошельку",
    "Bonus points": "Бонусные баллы",
    "Fee": "Комиссия",
    "Bonus Details": "Детали бонуса",
    "Details / weekly cap": "Детали / еженедельный лимит",
    "User / amount": "Пользователь / сумма",
    "Extended Ref Link": "Расширенная ссылка",
    "Leadership": "Лидерство",
    "Travel Incentive": "Стимул для путешествий",
    "Unlock the full potential of your Filecoin rewards as they come to life following your purchase of any of our special bundles. Once your Filecoin Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Раскройте весь потенциал ваших вознаграждений в файлкоинах, поскольку они становятся реальностью после покупки любого из наших специальных пакетов. Как только ваш Filecoin-баланс станет доступен, вы получите доступ к обзору общего количества вознаграждений, полученных и ожидаемых вознаграждений. Кроме того, таймер обратного отсчета показывает следующую раздачу вознаграждений, чтобы вы никогда не пропустили ее.",
    "is left to qualify": "Осталось ${n} для квалификации",
    "Check transactions": "Контрольные транзакции",
    "Direct partner own purchases": "Прямые собственные покупки партнеров",
    "Each of your direct partners on the left and right legs needs to generate a turnover value of $100.00.": "Каждый из ваших прямых партнеров на левой и правой ногах должен генерировать оборот стоимостью $100,00.",
    // Feature Prioritization
    "Participation to GYMSTREETs Elite Vote": "Участие в элитном голосовании GYMSTREET",
    "Prioritization ends in": "Расстановка приоритетов заканчивается через",
    "Start Prioritizing": "Начните расставлять приоритеты",
    "Your changes have been saved": "Ваши изменения сохранены",
    "features are still remaining": "<b>{n} функций</b> все еще осталось.",
    "feature is still remaining": "<b>{n} функция</b> все еще доступна",
    "All prioritized": "Все в приоритете",
    "GYMSTREETs Elite Vote Statistics": "Статистика элитного голосования GYMSTREET",
    "Just NUM features to go! Complete your prioritization to help us shape community's vision.": "Осталось всего <b>{countText}</b>! Расставьте приоритеты, чтобы помочь нам сформировать видение сообщества.",
    "You've prioritized the features. Please review your choices and hit Submit to finalize.": "Вы расставили приоритеты по функциям. Пожалуйста, проверьте свой выбор и нажмите <b>Отправить</b> для завершения.",
    "Back to All Features": "Назад ко всем функциям",
    "Changes saved": "Изменения сохранены",
    "No changes": "Без изменений",
    "Submit": "Отправить",
    "priority": "приоритет",
    "Set priority": "Установить приоритет",
    "Feature details": "Детали функции",
    "Select a feature to see more info": "Выберите функцию, чтобы увидеть дополнительную информацию",
    "Prioritization Submitted": "Расстановка приоритетов Представлено",
    "The results will be available after the voting period ends. You'll be able to change your priorities until then.": "Результаты будут доступны после окончания периода голосования. До этого момента вы сможете изменить свои приоритеты.",
    "Go to Career Status page": "Перейти на страницу карьерного статуса",
    "Keep Prioritizing": "Продолжайте расставлять приоритеты",
    "Continue Prioritizing": "Продолжить расставлять приоритеты",
    "Review and Submit": "Пересмотреть и отправить",
    "Thinking of reprioritizing?": "Думаете о пересмотре приоритетов?",
    "You've already submitted your priorities. If you want to reprioritize them, please make sure you hit Resubmit.": "Вы уже представили свои приоритеты. Если вы хотите изменить их приоритетность, обязательно нажмите <b>«Повторно отправить»</b>.",
    "Got it": "Понятно",
    "Resubmit": "Повторно отправить",
    "Priorities submitted": "Приоритеты представлены",
    "Submitted": "Отправлено",
    "View Statistics": "Посмотреть статистику",
    "Continue Prioritization": "Продолжить расстановку приоритетов",
    "Reprioritize": "Изменить приоритеты",
    "Start Prioritization": "Начать приоритизацию",
    "features": "особенности",
    "feature": "функция",
    "GYMSTREET Elite Vote": "Голосование элиты GYMSTREET",
    "Thanks for submitting! You can still reprioritize and resubmit your choices until the voting period ends.": "Спасибо за отправку! Вы по-прежнему можете изменить приоритеты и повторно отправить свой выбор до окончания периода голосования.",
    "Youre not done yet. Continue prioritizing the upcoming features and submit your final choices.": "Вы еще не закончили. Продолжайте расставлять приоритеты предстоящих функций и отправьте свой окончательный выбор.",
    "Exclusive for Platin and higher rank members. Prioritize the features you want to see next on GYMSTREET.": "Эксклюзивно для участников уровня Platin и выше. Расставьте приоритеты в отношении функций, которые вы хотите видеть в GYMSTREET в будущем.",
    "View rules": "Посмотреть правила",
    "Prioritization has ended. See the community's vote results.": "Расстановка приоритетов закончилась. Посмотрите результаты голосования сообщества.",
    "Stay tuned": "Следите за обновлениями",
    "See current community prioritization statistics below. In 7 days, we'll also announce our decision right here, revealing the selected new features and their estimated release dates.": "См. текущую статистику приоритетов сообщества ниже. Через 7 дней мы также объявим о своем решении прямо здесь, указав выбранные новые функции и предполагаемые даты их выпуска.",
    "Terms and Conditions for GYMSTREETs Elite Vote": "Положения и условия элитного голосования GYMSTREET",
    // Suspicous activity
    "You have one attempt left": "У вас осталась одна попытка. После этого ваши попытки для этой учетной записи будут временно ограничены.",
    "You have no attempts left": "У вас не осталось попыток для этой учетной записи. Пожалуйста, повторите попытку через {minutesText} <b>минут</b> или сбросьте пароль.",
    "Sorry, you’ve been blocked": "Извините, вы были заблокированы",
    "Your IP address has been temporarily blocked for security reasons.": "Ваш IP-адрес был временно заблокирован по соображениям безопасности. Пожалуйста, подождите, скоро вы будете автоматически перенаправлены.",
    "If you believe this was a mistake, please contact us through our Customer Support.": "Если вы считаете, что это ошибка, свяжитесь с нами через <a href=\"mailto:support@gymstreet.io?subject=Suspicious Activity&body=I am blocked due to suspicious activity, but I think It's a mistake\" class=\"customer-support-button\">Службу поддержки клиентов</a>.",
    // Instant commissions
    "The period for earning mining rewards in BITOPEX tokens has officially concluded. Thanks for your participation. Enjoy your BPX earnings.": "Период получения вознаграждений за майнинг в токенах BITOPEX официально завершился. Спасибо за ваше участие. Наслаждайтесь доходом от BPX.",
    "We're enhancing our claim feature, so it will be offline, for the next {hours} hours. Thank you and stay tuned!": "Мы улучшаем функцию подачи претензий, поэтому в течение следующих {hours} часов она будет работать в автономном режиме. Спасибо и оставайтесь на связи!",
    "Eligibility for a one-time 10% commission, based on the total turnover generated from direct sales, requires referring team members and achieving a turnover of $5,000 or more in direct sales.": "Право на единовременную комиссию в размере 10%, основанную на общем обороте прямых продаж, требует от привлеченных членов команды и достижения оборота в размере 5000 долларов США или более в прямых продажах.",

    "Turnover Incentive": "Стимулирование оборота",
    "Available For Limited time only": "Доступно только в течение <b>ограниченного времени</b>.",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving $20,000 in direct sales.": "Обратите внимание, что вы имеете право на получение бесплатного пакета R8 при достижении прямых продаж на сумму 20 000 долларов США.",

    "Minting Rewards": "Майнинг награды",
    "Please check transactions if your {asset} balance is not visible": "Проверьте транзакции, если ваш баланс {asset} не отображается",
    "In order to send {asset} you need to select {chain} network and wallet that is connected to your account.": "Чтобы отправить {asset}, вам необходимо выбрать сеть {chain} и кошелек, подключенный к вашему аккаунту.",
    "Cash bonus eligibility is based on rank achieved through qualifications. Participants qualifying with over 50% before March 4th, 2024, will not receive the bonus.": "Право на получение денежного бонуса зависит от ранга, достигнутого благодаря квалификации. Участники, прошедшие квалификацию с более чем 50% до 4 марта 2024 г., не получат бонус.",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving 100k in team purchases.": "Обратите внимание, что вы имеете право на получение бесплатного пакета R8 при достижении 100 000 командных покупок.",

    "Convert": "Конвертировать",
    "Convert your unused miners into hashpower to earn rewards in Gymnet and Filecoin": "Конвертируйте неиспользуемые майнеры в хэшпауэр, чтобы получать вознаграждение в Gymnet и Filecoin.",
    "The 50% Turnover Rule is applied to this incentive": "К этому поощрению применяется правило 50% оборота",

    "Super Bundle Rank Benefits": "Преимущества ранга Super Bundle",
    "Matching bonus up to NUM levels": "Соответствующий бонус до {num} уровней",
    "Up to NUM levels": "До {num} уровней",
    "Left leg partner": "Партнер левой ноги",
    "Right leg partner": "Партнер правой ноги",
    "Commission Activation Requirements": "Требования активации комиссии",
    "Up to 1 level": "До 1 уровня",
    "Bonus is paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET": "Бонус выплачивается в соотношении 70/30, 70% в USDT и 30% в GYMNET.",
    "Current Turnover": "Текущий оборот",
    "You will receive the bonus once the incentive period is over and it will be deposited directly into your wallet.": "Вы получите бонус после окончания периода поощрения, и он будет зачислен прямо на ваш кошелек.",
    "Purchase Amount": "Сумма покупки",
    "Spring": "Весна",
    "Spring Incentive": "Весенний стимул",
    "Commission Earned": "Заработанная комиссия: <b>${n}</b>",
    "Bonus Earned": "Заработанные бонусы: <b>${n}</b>",
    "Earn 1 PAP point for every dollar spent on purchases using a utility balance or when you purchase a product autoship.": "Зарабатывайте 1 балл PAP за каждый доллар, потраченный на покупки с использованием баланса коммунальных услуг или при покупке продукта с автоматической доставкой.",
    "Member": "Affiliate",
    "Trainee": "Trainee",
    "Rookie": "Rookie",
    "Silver": "Silver",
    "Gold": "Gold",
    "Platinum": "Platinum",
    "Double Platinum": "Double Platinum",
    "Diamond": "Diamond",
    "Double Diamond": "Double Diamond",
    "Red Diamond": "Red Diamond",
    "White Diamond": "White Diamond",
    "Crown Diamond": "Crown Diamond",
    "Platin": "Platinum",
    "Double Platin": "Double Platinum",
    "Single Pool": "Single Pool",
    "Explorer": "Explorer",
    "Mastery": "Mastery",
    "Features expected timeframes revealed": "Раскрыты ожидаемые сроки реализации",
    "Find estimated timelines for features prioritized by our community and aligned with our strategy.": "Узнайте ориентировочные сроки реализации функций, приоритетных для нашего сообщества и соответствующих нашей стратегии.",
    "Estimated timeframe": "Ориентировочные сроки",
    "Undefined": "Неопределенный",
    "No Longer Valid": "Более не действителен",
    "Deposit to Single pool": "Депозит в единый пул",
    "Max": "Макс",
    "Received": "Полученный",
    "About voting rights": "Об избирательных правах",
    "Your first deposit must be over $25": "Ваш первый депозит должен превышать 25 долларов США.",
    "Staking period ends": "Период ставок заканчивается",
    "December": "Декабрь",
    "Deposit & Stake": "Депозит и ставка",
    "Purchasing the card requires passing the KYC procedure.": "Для приобретения карты необходимо пройти процедуру KYC.",
    "Continue to KYC": "Продолжить процедуру KYC",
    "KYC Verification": "KYC-проверка",
    "Step": "Шаг {n}",
    "Upload ID": "Загрузить идентификатор",
    "Male": "Мужской",
    "Female": "Женский",
    "Gender": "Пол",
    "ID Type is required": "Укажите тип идентификатора.",
    "ID Document Language is required": "Укажите язык документа, удостоверяющего личность.",
    "ID Expiration Date is required": "Укажите дату истечения срока действия удостоверения личности.",
    "ID Issue Date is required": "Укажите дату выдачи удостоверения личности.",
    "State must be at least 3 characters": "Государство должно содержать не менее 3 символов.",
    "ID Type": "Тип идентификатора",
    "National Identity Card": "Национальное удостоверение личности",
    "Driver's License": "Водительское удостоверение",
    "Passport": "Заграничный пасспорт",
    "ID Document Language": "Язык документа, удостоверяющего личность",
    "ID Number be at least 3 characters": "Идентификатор должен содержать не менее 3 символов.",
    "ID Issue Date": "Дата выдачи удостоверения личности",
    "ID Expiration Date": "Дата истечения срока действия удостоверения личности",
    "You need to upload a photo of your document Please upload a png or jpeg, not a pdf.": "Вам необходимо загрузить фотографию вашего документа \n Загрузите <span style='color: #1A6C99'>png или jpeg, а не PDF</span>.",
    "File size exceeds {n} MB.": "Размер файла превышает {n} МБ.",
    "Front side of ID": "Лицевая сторона удостоверения личности",
    "Back side of ID": "Обратная сторона удостоверения личности",
    "Upload proof of address document": "Загрузите документ, подтверждающий адрес",
    "Upload proof of address, which must not be older than 75 days.": "Загрузите подтверждение адреса, которое не должно быть старше 75 дней.",
    "Acceptable forms of proof of address include a credit card statement, utility bill, bank statement or bank letter.": "Приемлемые формы подтверждения адреса включают выписку по кредитной карте, счет за коммунальные услуги, выписку из банка или банковское письмо.",
    "Address Document Type is required": "Адрес. Укажите тип документа.",
    "Document Issued by is required": "Требуется документ, выданный кем",
    "Document Issued Date is required": "Укажите дату выдачи документа.",
    "Address Document Type": "Адрес Тип документа",
    "Credit Card Statement": "Заявление о кредитных картах",
    "Utility Bill": "Счет за коммунальные услуги",
    "Bank Statement": "Выписка из банка",
    "Bank Letter": "Банковское письмо",
    "Document Issued by": "Документ выдан",
    "Document Issued Date": "Дата выдачи документа",
    "Please upload a legible document in png or jpeg format.": "Загрузите разборчивый документ <span style='color: #1A6C99'>в формате PNG или JPEG.</span>",
    "Submit for Verification": "Отправить на проверку",
    "Your documents have been submitted for verification. Once verification has been approved, you can continue your purchase.": "Ваши документы отправлены на проверку. После подтверждения подтверждения вы можете продолжить покупку.",
    "You need more GYMNET to make a deposit in Single Pool": "Вам нужно еще <b>{n} GYMNET</b>, чтобы внести депозит в Single Pool.",
    "You need more USDT to purchase a bundle": "Вам нужно еще <b>{n} долларов США</b>, чтобы приобрести пакет.",
    "Security Assurance": "Безопасность\nГарантия",
    "Global Accessibility": "Глобальная\nДоступность",
    "Affiliate Earnings": "Партнерская\nЗаработок",
    "Effortless Integration": "Легкая\nинтеграция",
    "Cards": "Карты",
    "Overview": "Обзор",
    "Information": "Информация",
    "Invoices": "Счета-фактуры",
    "KYC Status": "Статус KYC",
    "Try again": "Попробуйте еще раз",
    "Apply here": "Подать заявку здесь",
    "Not verified": "не подтверждено",
    "Pending verification": "Ожидает подтвержения",
    "Rejected": "Отклоненный",
    "Verified": "Проверено",
    "Next service fee in": "Следующий сервисный сбор через",
    "No active membership": "Нет активного членства",
    "Card": "Карта",
    "Card number": "Номер карты",
    "PIN Set Successfully": "PIN-код установлен успешно",
    "Go to Dashboard": "Перейти к приборной панели",
    "4-digit PIN": "4-значный PIN-код",
    "Re-enter 4-digit PIN": "Повторно введите 4-значный PIN-код.",
    "Set PIN": "Установить PIN-код",
    "Your account is now verified and you can continue your purchase of the bundle.": "Теперь ваша учетная запись подтверждена, и вы можете продолжить покупку пакета.",
    "Continue purchase": "Продолжить покупку",
    "Your documents have been rejected from KYC provider.": "Ваши документы были отклонены провайдером KYC.",
    "Please try again": "Пожалуйста, попробуйте еще раз",
    "Your KYC is approved": "Ваш KYC одобрен",
    "Your KYC is rejected": "Ваш KYC отклонен",
    "File is required": "Требуется файл",
    "Seamless Spending with GYMSTREET Cards": "Бесперебойные траты с картами GYMSTREET",
    "Our cards open a world of smooth transactions and rewarding opportunities for you.": "Наши карты открывают для вас мир плавных транзакций и выгодных возможностей.",
    "Card Types Tailored to Your Needs": "Типы карт, соответствующие вашим потребностям",
    "Card design": "Дизайн карты",
    "Card information": "Информация о карте",
    "Your go-to choice for daily transactions and affiliate commissions, offering practical benefits and reliable service within GYMSTREET and beyond.": "Ваш идеальный выбор для ежедневных транзакций и партнерских комиссий, предлагающий практические преимущества и надежный сервис в GYMSTREET и за его пределами.",
    "Elevate your financial experience with this premium option with expanded benefits, designed for those who seek luxury and exclusivity.": "Повысьте свой финансовый опыт с помощью этого варианта премиум-класса с расширенными преимуществами, предназначенного для тех, кто ищет роскошь и эксклюзивность.",
    "Cards come in Bundles": "Карты идут в связках",
    "Explorer card and 2 parcels": "Карта Explorer и 2 посылки",
    "Mastery card and 3 parcels": "Карта Mastery и 3 посылки",
    "Getting Started with Your GYMSTREET Card Experience": "Начало работы с картой GYMSTREET Card",
    "Start securely with our simple KYC process, ensuring a safe and personalized Card experience.": "Начните безопасно с нашей простой процедуры KYC, гарантирующей безопасное и персонализированное использование карты.",
    "Deposit Tokens": "Депозит жетонов",
    "Stake the required GYMNET tokens - 500 for Explorer or 1500 for Mastery - to initiate your application.": "Сделайте ставку необходимых токенов GYMNET — 500 для Explorer или 1500 для Mastery — чтобы инициировать вашу заявку.",
    "Order Your Card": "Закажите свою карту",
    "Make your final decision on card type and place your order buying one of the 2 card bundles.": "Примите окончательное решение о типе карты и разместите заказ, купив один из двух комплектов карт.",
    "Get Your Card": "Получите свою карту",
    "Anticipate the arrival of your new financial companion, securely shipped and ready to integrate into your life.": "Ожидайте прибытия вашего нового финансового компаньона, который будет надежно доставлен и готов интегрироваться в вашу жизнь.",
    "Use Your Card": "Используйте свою карту",
    "Begin making purchases with your card and start earning affiliate commissions and more.": "Начните совершать покупки с помощью своей карты и начните зарабатывать партнерские комиссии и многое другое.",
    "Card Operation Fees": "Комиссия за операции с картой",
    "Fee Type": "Тип комиссии",
    "Both Cards (Explorer & Mastery)": "Обе карты (Explorer и Mastery)",
    "POS Transactions": "POS-транзакции",
    "ATM Withdrawals": "Снятие в банкоматах",
    "Foreign Currency Conversion": "Конвертация иностранной валюты",
    "Card Replacement": "Замена карты",
    "Simple, Transparent Pricing": "Простое и прозрачное ценообразование",
    "Monthly Membership Fee": "Ежемесячный членский взнос",
    "Monthly Membership": "Ежемесячное членство",
    "Get Your Card Now": "Получите свою карту сейчас",
    "Choose the card that best suits your lifestyle. With straightforward fees, a seamless onboarding process, and an 11-level deep affiliate commission reward system, these cards are your partner in financial empowerment.": "Выберите карту, которая лучше всего соответствует вашему образу жизни. Благодаря простым комиссиям, четкому процессу регистрации и 11-уровневой системе вознаграждения партнерских комиссий эти карты станут вашим партнером в расширении финансовых возможностей.",
    "How do I get a card?": "Как мне получить карту?",
    "Start by filling out the KYC form on our site. After we verify you, you'll need to deposit tokens for the card you want in Single Pool: 500 for Explorer or 1500 for Mastery. Then, you can order your card.": "Начните с заполнения формы KYC на нашем сайте. После того, как мы проверим вас, вам нужно будет внести жетоны на нужную карту в единый пул: 500 для Explorer или 1500 для Mastery. После этого вы можете заказать карту.",
    "How many tokens do I need to deposit for each card?": "Сколько токенов мне нужно внести на каждую карту?",
    "You need 500 tokens for the Explorer card and 1500 tokens for the Mastery card.": "Вам понадобится 500 жетонов для карты Explorer и 1500 жетонов для карты Mastery.",
    "When will my card arrive?": "Когда придет моя карта?",
    "After you order, your card will be sent out and should arrive quickly, so you can start using it soon.": "После того, как вы сделаете заказ, ваша карта будет отправлена и должна прибыть быстро, чтобы вы могли начать ее использовать в ближайшее время.",
    "Can I earn affiliate commissions with my card?": "Могу ли я получать партнерские комиссии с помощью своей карты?",
    "Yes, activate your card, and you can begin earning through our 11-level deep affiliate program. Check our website for how the commissions work.": "Да, активируйте свою карту, и вы сможете начать зарабатывать через нашу 11-уровневую партнерскую программу. Посетите наш сайт, чтобы узнать, как работают комиссии.",
    "Total Value At Time of Deposit": "Общая стоимость на момент внесения депозита",
    "Current Value": "Текущая стоимость",
    "My claimed GYMNET rewards": "Мои заявленные награды GYMNET",
    "My pending GYMNET rewards": "Мои ожидающие награды GYMNET",
    "Deposit": "Депозит",
    "Connected": "Связанный",
    "No deposit fee for burn": "Без залога за сжигание",
    "Min amount": "Минимальная сумма",
    "Total GYMNET locked": "Всего GYMNET заблокирован",
    "APR": "АПРЕЛЬ",
    "Daily APR": "Ежедневная годовая процентная ставка",
    "Total own claimed": "Всего собственных заявленных",
    "Reward per block": "Награда за блок",
    "My Deposit": "Мой депозит",
    "Locked period until Dec 2027": "Период блокировки до декабря 2027 г.",
    "With Locking Period": "С периодом блокировки",
    "Total Gymnet Deposit": "Общий депозит Gymnet",
    "Gymnet Rewards": "Награды Gymnet",
    "Total Gymnet earned": "Всего заработано Gymnet",
    "Active holdings": "Активные холдинги",
    "With locking period": "С периодом блокировки",
    "Withdrawn holdings": "Выведенные активы",
    "Locked": "Заблокировано",
    "Unlocked": "Разблокировано",
    "Deposit amount": "Сумма вклада",
    "Claimed rewards": "Заявленные награды",
    "Start date": "Дата начала",
    "End date": "Дата окончания",
    "Withdraw": "Отзывать",
    "First name": "Имя",
    "Last name": "Фамилия",
    "Address": "Адрес",
    "State": "Состояние",
    "Phone number": "Номер телефона",
    "Birth Date": "Дата рождения",
    "Submit & Continue": "Отправить и продолжить",
    "ID Number": "Идентификационный номер",
    "ID Issued By": "Идентификатор выдан",
    "Proof of address document": "Документ, подтверждающий адрес",
    "Proof of address document (optional)": "Документ, подтверждающий адрес (необязательно)",
    "Statistics": "Статистика",
    "Personal Information": "Персональная информация",
    "First name is required": "Требуется имя",
    "Last name is required": "Требуется фамилия",
    "Address is required": "Требуется адрес",
    "Please enter your email address": "Пожалуйста, введите Ваш адрес электронной почты",
    "Phone number is required": "Требуется номер телефона",
    "Date of birth is required": "Требуется дата рождения",
    "ID Number is required": "Требуется идентификационный номер",
    "ID Issued by is required": "Требуется идентификатор, выданный",
    "Select asset to auto swap and stake": "Выберите актив для автоматического обмена и ставки",
    "Giving swap allowance": "Предоставление скидки на обмен",
    "Swap allowance given": "Предоставлено пособие по обмену",
    "Swapping tokens to GYMNET": "Обмен токенов на GYMNET",
    "Tokens swapped to GYMNET": "Токены заменены на GYMNET",
    "Giving stake allowance": "Выдача пособия по ставке",
    "Stake allowance given": "Доплата по ставке предоставлена",
    "Staking to single pool": "Стейкинг в единый пул",
    "Staked to single pool": "Ставка в один пул",
    "1. Swap allowance (Only for USDT)": "1. Допуск свопа (только для USDT)",
    "2. Swap to GYMNET": "2. Переключитесь на GYMNET.",
    "3. Stake allowance": "3. Размер ставки",
    "4. Stake": "4. Ставка",
    "Actual balance:": "Фактический баланс:",
    "The transaction gas fee is reserved from the actual BNB balance.": "Комиссия за транзакционный газ удерживается с фактического баланса BNB.",
    "Insufficient balance to make a swap": "Недостаточно баланса для обмена",
    "Transactions gas fee is considered in BNB available balance": "Комиссия за газ за транзакцию учитывается в доступном балансе BNB.",
    "We will ask for allowance and try to swap your tokens to GYMNET, and after, stake them in single pool.": "Мы запросим разрешение и попытаемся обменять ваши токены на GYMNET, а затем разместить их в одном пуле.",
    "Auto swap and stake": "Автоматический своп и ставка",
    "Continue transactions": "Продолжить транзакции",
    "Pause": "Пауза",
    "Swap & Stake": "Своп и ставка",
    "Topup": "Пополнение",
    "Proceed anyways": "Продолжить в любом случае",
    "Explorer Card": "Карта Explorer",
    "Mastery Card": "Карта Mastery",
    "Monthly spending max": "Максимальный ежемесячный расход",
    "Monthly Balance limit": "Ежемесячный лимит баланса",
    "Go to Cards": "Перейти в Карты",
    "Contact Support": "Контактная поддержка",
    "Deposit Fee": "Комиссия за депозит",
    "Switch to 'Utility' to view the price for each product with USDT & Utility. To partially pay for a product using Utility, you need to have enough USDT and Utility in your balance.": "Переключитесь на «Utility», чтобы просмотреть цену на каждый продукт в долларах США и Utility. Чтобы частично оплатить товар с помощью Utility, вам необходимо иметь на балансе достаточное количество USDT и Utility.",
    "Deposit with locking period": "Депозит с периодом блокировки",
    "The state must be in the form of a two-letter abbreviation in uppercase.": "Штат должен быть указан в виде двухбуквенной аббревиатуры в верхнем регистре.",
    "You need to upload a photo in JPEG or PNG format, not a PDF. The document can be in either English or Spanish.": "Вам необходимо загрузить фотографию в формате <span style='color: #1A6C99'>jpeg</span> или <span style='color: #1A6C99'>png</span>, а не в формате pdf. \n Документ может быть либо на <span style='color: #1A6C99'>английском</span>, либо на <span style='color: #1A6C99'>испанском</span>.",
    "You need to upload a legible document in jpeg or png format, not a pdf. The document can be in either English or Spanish.": "Вам необходимо загрузить разборчивый документ в формате <span style='color: #1A6C99'>jpeg</span> или <span style='color: #1A6C99'>png</span>, а не pdf файл. \n Документ может быть либо на <span style='color: #1A6C99'>английском</span>, либо на <span style='color: #1A6C99'>испанском</span>.",
    "Commissions are paid out using a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "Комиссионные выплачиваются в соотношении 70/30: 70% в стейблкоинах и 30% в токенах GYMNET.",
    "This policy is effective as of September 25, 2023.": "Эта политика вступает в силу с 25 сентября 2023 года.",
    "Required turnover": "Требуемый оборот",
    "Switch to TOKEN to view the price for each product with USDT & TOKEN. To partially pay for a product using TOKEN, you need to have enough USDT and TOKEN in your balance.": "Переключитесь на «{TOKEN}», чтобы просмотреть цену на каждый продукт в долларах США и {TOKEN}. Чтобы частично оплатить товар с помощью {TOKEN}, вам необходимо иметь на балансе достаточное количество USDT и {TOKEN}.",
    "Alternatively, you can use your CURRENCY balance to cover the gas fee.": "Кроме того, вы можете использовать свой баланс {currency_yg} для покрытия комиссии за бензин.",
    "Insufficient funds: to continue with payment use Utility or Gymnet balance": "Недостаточно средств: для продолжения оплаты используйте баланс Utility или Gymnet.",
    "Use": "Использовать",
    "Use Utility or Gymnet": "Используйте Utility или Gymnet.",
    "Achieved PRICE turnover": "Достигнут оборот ${price}",
    "Your current milestone": "Ваша текущая веха",
    "Reward History": "История наград",
    "Leading Direct Partner": "Ведущий прямой партнер",
    "Remaining Direct Partners": "Остальные прямые партнеры",
    "Bonus Split": "Бонусный сплит",
    "Monthly Countdown": "Ежемесячный обратный отсчет",
    "No direct partners": "Нет прямых партнеров",
    "To receive the reward, you must have an active product autoship.": "Чтобы получить вознаграждение, у вас должна быть активная автодоставка продукта.",
    "This incentive is subject to the 50% Turnover Rule.": "На этот стимул распространяется правило 50% оборота.",
    "Income From Product Authoship": "Доход от авторства продукта",
    "Your documents have been submitted for verification. The bank may take up to 3 business days to review your application. Once approved, you can continue with your purchase.": "Ваши документы отправлены на проверку. Банку может потребоваться до 3 рабочих дней для рассмотрения вашего заявления. После одобрения вы можете продолжить покупку.",
    "To purchase the card, you need to agree to the T&C and complete the KYC verification process.": "Чтобы приобрести карту, вам необходимо согласиться с Условиями и пройти процедуру проверки KYC.",
    "Only Latin letters are allowed": "Разрешены только латинские буквы",
    "First name must be at least 3 characters": "Имя должно содержать не менее 3 символов.",
    "First name should not exceed 10 characters": "Имя не должно превышать 10 символов.",
    "Should be only letters": "Должны быть только буквы",
    "Address must be at least 3 characters": "Адрес должен содержать не менее 3 символов.",
    "Only latin letters, numbers and special characters are allowed": "Разрешены только латинские буквы, цифры и специальные символы.",
    "Email is not valid": "Email не является допустимым",
    "Zip must contain only numbers with min length of 3": "Zip должен содержать только цифры длиной не менее 3.",
    "The phone number must be at least 6 digits": "Номер телефона должен состоять не менее чем из 6 цифр",
    "The phone number must not exceed 12 digits": "Номер телефона не должен превышать 12 цифр",
    "Phone number should contain only numbers": "Номер телефона должен содержать только цифры",
    "ID Number must have at least 3 characters": "Идентификационный номер должен содержать не менее 3 символов.",
    "ID Issued by must have at least 3 characters": "Идентификатор, выданный, должен содержать не менее 3 символов.",
    "ID Issued by should not exceed 191 characters": "Идентификатор выдан не должен превышать 191 символ.",
    "Last name must be at least 3 characters": "Фамилия должна содержать не менее 3 символов.",
    "State is required": "Требуется состояние",
    "The incentive countdown starts at the beginning of each month and ends at the end of the month. If you do not qualify for the bonus within that month, you will need to start again in the next month.": "Обратный отсчет поощрений начинается в начале каждого месяца и заканчивается в конце месяца. Если вы не имеете права на получение бонуса в течение этого месяца, вам придется начать заново в следующем месяце.",
    "Activated": "Активировано",
    "Deposit & fee commission": "Комиссия за депозит и комиссию",
    "Web3 wallet not connected. Please connect to proceed.": "Кошелек Web3 не подключен. Пожалуйста, подключите, чтобы продолжить.",
    "Total investors profit": "Общая прибыль инвесторов",
    "Commissions from vault investors": "Комиссионные от инвесторов хранилищ",
    "Please Claim your rewards from to activate this section.": "Пожалуйста, запросите свои награды у {n}, чтобы активировать этот раздел.",
    "Or use Button Below": "Или используйте кнопку ниже",
    "Partner Activity": "Партнерская активность",
    "Pending Commissions": "Ожидаемые комиссии",
    "Binary Turnover": "Бинарный оборот",
    "Unilevel Turnover": "Одноуровневый оборот",
    "Right Direct Partners": "Правые прямые партнеры",
    "Left Direct Partners": "Левые прямые партнеры",
    "50% Compensation:": "50% Компенсация:",
    "When you reach the next rank, you will receive 50% of the lost bonus from the previous rank.": "При достижении следующего ранга вы получите 50% утраченного бонуса от предыдущего ранга.",
    "Please note that username can be changed only once in 6 months.": "Обратите внимание, что имя пользователя можно изменить только один раз в 6 месяцев.",
    "Binary Turnover in weak leg": "Бинарный оборот на слабой ноге",
    "Unilevel turnover requirement": "Требование одноуровневого оборота",
    "Connect a WEB3 wallet such as Metamask or Trustwallet and pay with {currency}. This process requires a small BNB fee ($0.30) to handle transaction costs!": "Подключите кошелек WEB3, например Metamask или Trustwallet, и оплатите с помощью {currency}. Этот процесс требует небольшой комиссии BNB (0,30 доллара США) для покрытия транзакционных издержек!",
    "Use your account's wallet balance to purchase the product.": "Используйте баланс кошелька вашего аккаунта для покупки продукта.",
    "Seems like your crypto hasn't arrived yet": "Кажется, ваша криптовалюта еще не пришла",
    "Wait more": "Подожди еще",
    "Or topup with": "Или пополнить с помощью",
    "Connected to web3": "Подключено к веб3",
    "Reset": "Перезагрузить",
    "Top Up Successful": "Пополнение прошло успешно",
    "Top Up Successful. Card balance will be updated soon": "Пополнение прошло успешно. Баланс карты будет обновлен в ближайшее время",
    "Please activate your card and top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "Пожалуйста, активируйте вашу карту и пополните баланс, чтобы покрыть ежемесячную плату. Если баланс не будет обновлен, ваша карта будет деактивирована, и вам придется приобрести новую.",
    "PERCENTAGE allocation from Global Pool": "Распределение {perc} % из глобального пула",
    "You need to reach rank RANK_NAME to participate in this pool.": "Чтобы участвовать в этом пуле, вам необходимо достичь ранга \"{rank_name}\".",
    "Global Pool": "Глобальный пул",
    "Next Distribution In:": "Следующая раздача через:",
    "50% Rule": "Правило 50%",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%": "Максимальная доля, которую каждый прямой партнер может внести в необходимый оборот, составляет до 50%",
    "Sub Pools": "Под-пулы",
    "Congratulations! You have qualified for a share of the Global Pool.": "Поздравляем! Вы имеете право на долю в Глобальном пуле.",
    "You will receive your share once the countdown is complete.": "Вы получите свою долю, как только обратный отсчет завершится.",
    "Please top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "Пожалуйста, пополните баланс, чтобы покрыть ежемесячную плату. Если баланс не будет обновлен, ваша карта будет деактивирована, и вам придется приобрести новую.",
    "You need to deposit VALUE worth of GYMNET to upgrade your weekly binary cap": "Вам необходимо пополнить счет на сумму {dvalue} от GYMNET, чтобы повысить свой еженедельный бинарный лимит",
    "Binary Max Weekly Payout": "Максимальная еженедельная выплата в бинарах",
    "Required Single Pool Deposit To Upgrade": "Требуемый депозит на один пул для обновления",
    "Upgrade Binary Max Weekly Payout": "Обновление бинарных максимальных еженедельных выплат",
    "Weekly Cap Upgraded": "Обновление еженедельного капитала",
    "Your 8 months period for earning token rewards in Filecoin has officially concluded. Thank you for your participation. Enjoy your FIL earnings.": "Ваш 8-месячный период получения вознаграждения за токены в Filecoin официально завершился. Благодарим вас за участие. Наслаждайтесь заработком в FIL.",
    "As previously announced Filecoin will be replaced by Moonberg token, which will be distributed for a period of 24 months. The Moonberg launch is expected for August 2024 and the distribution will start one week after that.": "Как было объявлено ранее, Filecoin будет заменен токеном Moonberg, который будет распространяться в течение 24 месяцев. Запуск Moonberg ожидается в августе 2024 года, а раздача начнется через неделю после этого.",
    "Username must not exceed 255 characters": "Имя пользователя не должно превышать 255 символов",
    "Upgrade": "Обновление",
    "Product Autoship Monthly": "Автодоставка продукта ежемесячно",
    "Product Autoship Yearly": "Автодоставка продукта ежегодно",
    "Product Owner": "Владелец продукта",
    "Myself": "Я сам",
    "Another user": "Другой пользователь",
    "Payment": "Оплата",
    "PAP Balance": "Баланс PAP",
    "Purchased By": "Куплено",
    "Purchased For": "Куплено для",
    "Single parcel": "Одна посылка",
    "Single miner": "Один майнер",
    "completed": "Завершенный",
    "pending": "В ожидании",
    "expired": "Истек",

    "Select {n} blockchain to send the transaction": "Выберите блокчейн {n} для отправки транзакции",
    "We recommend waiting to claim your rewards when the gas fee is lower than the amount you are claiming.": "Мы рекомендуем подождать, чтобы получить вознаграждение, когда плата за газ станет ниже суммы, которую вы запрашиваете.",
    "Total Value Of Purchases": "Общая стоимость покупок",
    "The total value of all purchases excluding purchases related to Card Bundles, granted products and product autoship subscriptions.": "Общая стоимость всех покупок, за исключением покупок, связанных с наборами карт, предоставленными продуктами и подписками на автодоставку продуктов.",
    "Total Receivable Tokens": "Общая сумма токенов к получению",
    "The number of Moonberg tokens you will receive over the next 24 months.": "Количество токенов Moonberg, которые вы получите в течение следующих 24 месяцев.",
    "Realtime ROI": "Окупаемость инвестиций в реальном времени",
    "Current Return on Investment (ROI) based on the real-time price of the Moonberg token.": "Текущая прибыль на инвестиции (ROI) на основе цены токена Moonberg в реальном времени.",
    "Token Calculation Breakdown": "Разбивка расчета токенов",
    "{n}% of your total purchase value is allocated for purchasing {asset} tokens": "{n}% от общей стоимости ваших покупок выделяется на покупку токенов {asset}",
    "Moonberg tokens will be distributed upon the official release of the Moonberg token.": "Токены Moonberg будут распространены после официального выпуска токена Moonberg.",
    "Moonberg Allocation": "Распределение Moonberg",
    "BTC Balance": "Баланс BTC",
    "The funds will be transferred via the BEP-20 BNB network.": "Средства будут переведены через сеть BEP-20 BNB.",
    "A bundle with minting power will generate BTC token rewards, which will be distributed every Monday.": "Пакет с мощностью чеканки будет генерировать вознаграждения в виде токенов BTC, которые будут распределяться каждый понедельник.",
    "Weekly rewards expected": "Ожидаются еженедельные вознаграждения",
    "Direct Sales Incentive": "Стимул прямых продаж",
    "You are about to activate your affiliate referral link.": "Вы собираетесь активировать свою партнерскую реферальную ссылку.",
    "Upon activation, a period of free Product Autoship will be initiated starting from the date of your registration.": "После активации начнется период бесплатной автодоставки продукта, начиная с даты вашей регистрации.",
    "If you confirm the activation, a mandatory monthly Product Autoship will be required to be eligible to receive commissions.": "Если вы подтвердите активацию, для получения комиссионных потребуется обязательная ежемесячная автодоставка продукта.",
    "Keep your product autoship active to maintain your commission eligibility for commission ranks Platinum and above.": "Поддерживайте активную функцию автодоставки вашей продукции, чтобы сохранить право на получение комиссионных для уровней Платина и выше.",
    "Buy Product Autoship": "Купить продукт Автодоставка",
    "Your current rank is achieved through qualification.": "Ваш текущий ранг достигается путем квалификации.",
    "Your commission rank is determined by your actual rank or a super bundle purchase—whichever is higher will be considered.": "Ваш ранг комиссии определяется вашим фактическим рангом или покупкой супер-набора — будет учитываться то, что выше.",
    "Your card has been deactivated due to an unpaid monthly subscription. For assistance or any questions, please reach out to our customer support team.": "Ваша карта {card_name} была деактивирована из-за неоплаченной ежемесячной подписки. Для получения помощи или по любым вопросам обращайтесь в нашу службу поддержки клиентов.",
    
    "Subject": "Тема",
    "topup": "Пополнение",
    "withdraw": "Вывод",
    "failed": "Неуспешно",
    "3rd Anniversary": "3-я годовщина",
    "3rd Anniversary Promo": "Промо-акция 3-й годовщины",
    "Your current bonus amount": "Ваша текущая сумма бонуса",
    "All products are eligible for the incentive, except for Product Autoship. Rewards will be distributed on November 1, 2024, and the bonus will be deposited directly into your internal wallet.": "Все продукты имеют право на поощрение, за исключением Product Autoship. Вознаграждения будут распределены 1 ноября 2024 года, а бонус будет зачислен непосредственно на ваш внутренний кошелек.",
























    // Affility translations
    "close": "Закрыть",
    "cancel": "Отмена",
    "yes_i_confirm": "Да, я подтверждаю",
    "something_went_wrong_try_again": "Что-то пошло не так. Пожалуйста, попробуйте еще раз",
    "or": "Или",
    "ok": "OK",
    "retry": "Повторить попытку",
    "success": "Успех",
    "home": "Главная",
    "log_in": "Авторизоваться",
    "join_now": "Присоединится сейчас",
    "toggle_notifications": "Открыть уведомления",
    "active": "Активный",
    "inactive": "Неактивный",
    "product_autoship": "Автодоставка продукта",
    "balances": "Балансы",
    "contract_addresses": "Адреса контрактов",
    "address_add_to_wallet_success": "Адрес был успешно добавлен в ваш кошелек",
    "language": "Язык",
    "choose_language": "Выберите язык",
    "profile": "Профиль",
    "settings": "Настройки",
    "learn": "Учиться",
    "help_center": "Центр помощи",
    "log_out": "Выйти",
    "theme": "Тема",
    "back": "Назад",
    "forward": "Вперед",
    "previous": "Предыдущий",
    "next": "Следующий",
    "jump_to_top": "Перейти наверх",
    "load_more": "Загрузи больше",
    "proceed": "Продолжить",
    "notifications": "Уведомления",
    "notifications_loading_error_description": "Убедитесь, что ваше устройство подключено к Интернету, или попробуйте перезапустить маршрутизатор или модем.",
    "notifications_empty_title": "Список уведомлений пуст",
    "notifications_empty_description": "Следите за всеми обновлениями и напоминаниями в одном удобном месте с помощью нашей функции уведомлений.",
    "email_verification_sent_title": "Подтверждение по элетронной почте",
    "email_verification_sent_description": "Мы отправили электронное письмо на адрес {email} со ссылкой для завершения вашей регистрации.",
    "email_verification_required": "Требуется подтверждение электронной почты",
    "email_verification_confirm": "Пожалуйста, подтвердите свой адрес электронной почты, чтобы продолжить",
    "email_verification_verify": "Подтвердите свой адрес электронной почты, чтобы продолжить",
    "new_email": "Новый E-mail",
    "enter_new_email": "Введите новый адрес электронной почты",
    "change_email": "Изменить e-mail",
    "resend_email": "Отправить письмо повторно",
    "upgrade_to_affiliate": "Перейти на партнерскую версию",
    "upgrade_to_affiliate_success": "Вы успешно перешли на статус Партнера",
    "become_affiliate": "Стать партнером",
    "upgrade_to_affiliate_description_free_autoship": "Вы собираетесь активировать свою партнерскую реферальную ссылку. После активации будет инициирован период бесплатной автодоставки продукта, начиная с даты вашей регистрации.",
    "upgrade_to_affiliate_description_mandatory_autoship": "Вы собираетесь активировать свою партнерскую реферальную ссылку. Если вы подтвердите активацию, для получения комиссионных потребуется обязательная ежемесячная автодоставка продукта.",
    "t&c": "Условия и положения",
    "accept_t&c": "Принять и закрыть",
    "affiliate_t&c": "Партнерские положения и условия",
    "cards_t&c": "Условия и положения карт",
    "i_have_read_and_accept_the_{prop}": "Я прочитал и принимаю {prop}",
    "switch_language": "Переключить язык",
    "switch_theme": "Переключить тему",
    "enjoy_more_benefit_more": "Наслаждайтесь больше! Получите больше пользы!",
    "buy_products_at_least_${n}": "Купите товары на сумму не менее ${n}",
    "go_to_products": "Перейти к продуктам",
    "free_autoship": "Бесплатная автодоставка продукции для уровня Platinum и выше",
    "{n}%_binary_commission": "{n}% бинарной комиссии",
    "rank": "Классифицировать",
    "role": "Роль",
    "bundle": "Пучок",
    "current_rank": "Текущее место в рейтинге",
    "current_role": "Текущая роль",
    "customer": "Клиент",
    "get_your_bundle": "Получите свой пакет",
    "nft_turbo_expires_in": "Срок действия специальной скидки Super Bundle истекает",
    "left_leg_link": "Ссылка на левую ногу",
    "right_leg_link": "Ссылка на правую ногу",
    "sponsor_id": "Идентификатор спонсора",
    "total_income": "Общая прибыль",
    "current_cv_balance": "Текущий баланс CV",
    "progress_until_rank_{n}": "Прогресс до ранга {n}",
    "to_reach_rank_{n}_you_need": "Чтобы достичь ранга {n}, вам нужно",
    "nft_own_purchases": "Собственные покупки NFT",
    "direct_partner_own_purchases": "Собственные закупки прямых партнеров",
    "team_turnover": "Текучесть команды",
    "required_qualified_partners": "Требуемые квалифицированные партнеры",
    "see_details": "Смотрите подробности",
    "soon": "Скоро",
    "new": "Новый",
    "promos": "Промоакции",
    "coming_soon": "Вскоре",
    "team_statistics": "Статистика команды",
    "all_time": "Все время",
    "weekly": "Еженедельно",
    "monthly": "Ежемесячно",
    "yearly": "Ежегодно",
    "left_leg": "Левая нога",
    "right_leg": "Правая нога",
    "all_partners": "Все партнеры",
    "direct_partners": "Прямые партнеры",
    "all_active_partners": "Все активные партнеры",
    "all_inactive_partners": "Все неактивные партнеры",
    "become_affiliate_partner": "Стать партнером",
    "gain_fresh_perspectives": "Получите новые перспективы и эксклюзивные преимущества.",
    "autoship_required_to_be_affiliate": "Ежемесячная автодоставка продуктов необходима для того, чтобы быть партнером-партнером и получать комиссионные.",
    "for_limited_time_only": "Только на ограниченное время",
    "placement_in_unilevel": "Размещение в Unilevel",
    "placement_in_binary": "Размещение в Binary",
    "cv_points": "CV баллы",
    "matching_bonuses": "Matching бонусы",
    "leadership_incentives": "Стимулы лидерства",
    "autoship_incentive": "Стимул к автоотгрузке продукта",
    "binary_tree_statistics": "Статистика бинарного дерева",
    "unilevel_tree_statistics": "Статистика одноуровневого дерева",
    "active_partners": "Активные партнеры",
    "inactive_partners": "Неактивные партнеры",
    "The minimum amount should be equal to or greater than NUM GYMNET": "Минимальная сумма должна быть равна или больше {num} GYMNET",
    "select_the_team": "Выберите команду",
    "new_users_will_be_placed_under_username_in_the_left_team": "Новые пользователи будут помещены под {USERNAME} в команду {LEG}.",
    "done": "Готово",
    "right": "вправо",
    "left": "влево",
};
