export default {
    "Learn how it works": "เรียนรู้วิธีการทำงาน",
    "Buy parcel": "ซื้อพัสดุ",
    "Wallets": "กระเป๋าเงิน",
    "To GymNetwork": "ไปยัง GymNetwork",
    "Connect Wallet": "เชื่อมต่อกระเป๋าเงิน",
    "Pending rewards": "รางวัลที่รอดำเนินการ",
    "Coming Soon": "เร็วๆ นี้",
    "Claim": "รับ",
    "Inventory": "รายการสิ่งของ",
    "Lands": "ที่ดิน",
    "Miners": "เครื่องขุด",
    "Attach": "แนบ",
    "Attach to parcel": "แนบไปกับพัสดุ",
    "Investment": "การลงทุน",
    "Wallet": "กระเป๋าเงิน",
    "Your address": "ที่อยู่ของคุณ",
    "History": "ประวัติ",
    "Map": "แผนที่",
    "Buy miner": "ซื้อเครื่องขุด",
    "Buy Miner": "ซื้อเครื่องขุด",
    "Get ": "รับ",
    "Get Miner": "รับเครื่องขุด",
    "Upgrade parcel": "อัปเกรดพัสดุ",
    "Soon": "เร็ว ๆ นี้",
    "Upgraded":"อัปเกรดแล้ว",
    "Upgraded land has 10 slots for putting miner NFTs. Upgraded land does not have more physical space, it’s only that you can stake more miners.": "ที่ดินที่ได้รับการอัปเกรดมี 10 ช่องสำหรับวาง NFT ของเครื่องขุด ที่ดินที่ได้รับการอัปเกรดไม่มีพื้นที่ทางกายภาพมากขึ้น มีเพียงคุณเท่านั้นที่สามารถเดิมพันเครื่องขุดได้มากขึ้น",
    "Next": "ถัดไป",
    "Back": "กลับ",
    "Skip guide": "ข้ามคำแนะนำ",
    "Number of miners you want to buy": "จำนวนเครื่องขุดแบบคู่ที่คุณต้องการซื้อ",
    "Attach Miner": "แนบเครื่องขุด",
    "Upgrade for": "อัปเกรดสำหรับ",
    "On Sale": "ลดราคา",
    "Type": "ประเภท",
    "Location": "ที่ตั้ง",
    "Size": "ขนาด",
    "Buy now": "ซื้อตอนนี้",
    "Email": "อีเมล",
    "Username": "ชื่อผู้ใช้",
    "Verify Email": "ตรวจสอบอีเมล",
    "Index": "ดัชนี",
    "Verification Code": "รหัสยืนยัน",
    "Log out": "ออกจากระบบ",
    "Select all": "เลือกทั้งหมด",
    "Cart": "รถเข็น",
    "Total": "ยอดรวม",
    "Register": "ลงทะเบียน",
    "Dashboard": "แดชบอร์ด",
    "Transaction success!": "ธุรกรรมประสบความสำเร็จ!",
    "I`ve read Terms & Conditions": "ฉันได้อ่านข้อกำหนดและเงื่อนไขแล้ว",
    "Terms & conditions": "ข้อตกลงและเงื่อนไข",
    "Affiliate Terms & Conditions": "ข้อกำหนดและเงื่อนไขของพันธมิตร",
    "Accept Terms & Conditions": "ยอมรับข้อกำหนดและเงื่อนไข",
    "Loading! Please Wait ...": "กำลังโหลด! โปรดรอ ...",
    "Initializing Wallet! Please Wait ...": "กำลังเริ่มต้นกระเป๋าเงิน! โปรดรอ ...",
    "Login In Progress! Please Wait ...": "เข้าสู่ระบบ กำลังดำเนินการ! โปรดรอ...",
    "Enter your email": "กรอกอีเมลของคุณ",
    "Confirm your Email": "ยืนยันอีเมลของคุณ",
    "Enter username": "ใส่ชื่อผู้ใช้",
    "To dashboard": "ไปที่แดชบอร์ด",
    "You successfully verified your email": "คุณยืนยันอีเมลของคุณสำเร็จแล้ว",
    "View map": "ดูแผนที่",
    "Log in to buy": "เข้าสู่ระบบเพื่อซื้อ",
    "Congratulations": "ขอแสดงความยินดี",
    "Buy" : "ซื้อ",
    "Miner" : "เครื่องขุด",
    "Parcel" : "พัสดุ",
    "Upgrade": "อัพเกรด",
    "Load more": "โหลดเพิ่ม",
    "All": "ทั้งหมด",
    "Basic": "พื้นฐาน",
    "Activate": "เปิดใช้งาน",
    "Basic Miner": "เครื่องขุดพื้นฐาน",
    "In use": "ใช้งานอยู่",
    "Expired": "หมดอายุ",
    "Available": "มีอยู่",
    "Standard parcel": "พัสดุมาตรฐาน",
    "Success": "สำเร็จ",
    "Something Went Wrong": "บางอย่างผิดพลาด",
    "Loading": "กำลังโหลด",
    "Invalid Verification Code": "รหัสยืนยันไม่ถูกต้อง",
    "Invalid Email Address": "ที่อยู่อีเมลที่ไม่ถูกต้อง",
    "Invalid Arguments Supplied": "อาร์กิวเมนต์ที่ให้มาไม่ถูกต้อง",
    "Email Already Exists": "อีเมลมีอยู่แล้ว",
    "UnAuthorized": "UnAuthorized",
    "User Update Failed": "การอัปเดตผู้ใช้ล้มเหลว",
    "Permission Denied": "การอนุญาติถูกปฏิเสธ",
    "Basic miner": "เครื่องขุดพื้นฐาน",
    "Remove": "ลบ",
    "Special offers": "ข้อเสนอพิเศษ",
    "Please Wait": "โปรดรอ",
    "Transaction Rejected": "ธุรกรรมถูกปฏิเสธ",
    "sold": "ขายแล้ว",
    "The parcel price will be increased after certain percentage of parcels will be sold. Bundle prices will be increased accordingly.": "ราคาพัสดุจะเพิ่มขึ้นหลังจากขายได้เป็นเปอร์เซ็นต์แล้ว ราคาชุดรวมจะเพิ่มขึ้นตามไปด้วย",
    "Sold": "ขายแล้ว",
    "Parcel price index": "ดัชนีราคาพัสดุ",
    "Current parcel price": "ราคาพัสดุปัจจุบัน",
    "Parcels sold": "พัสดุขายแล้ว",
    "Parcel stats": "สถิติพัสดุ",
    "Help center": "ศูนย์ช่วยเหลือ",
    "Partner area": "พื้นที่พันธมิตร",
    "email_verification": 'ส่งรหัสยืนยันอีเมลไปที่',
    "change_email": 'เปลี่ยนอีเมล',
    "you_successfully_verified_your_email": "คุณยืนยันอีเมลของคุณสำเร็จแล้ว",
    "to_dashboard": "ไปที่แดชบอร์ด",
    "Transaction Success": "การทำธุรกรรมสำเร็จ",
    "parcels available": "พัสดุที่มีอยู่",
    "Apply": "ใช้",
    "Ocean": "มหาสมุทร",
    "Near Ocean": "ใกล้มหาสมุทร",
    "Insufficient USDT amount": "จำนวน USDT ไม่เพียงพอ",
    "Insufficient {asset} amount": "จำนวน {asset} ไม่เพียงพอ",
    "Miner Rewards": "รางวัลเครื่องขุด",
    "No data found": "ไม่พบข้อมูล",
    "Contract Address": "ที่อยู่ตามสัญญา",
    "Wallet Address": "ที่อยู่กระเป๋าเงิน",
    "Inland": "ภายในประเทศ",
    "Business": "ธุรกิจ",
    "parcels": "พัสดุ",
    "parcel": "parcel",
    "Standard": "มาตรฐาน",
    "Full": "เต็ม",
    "Attach miners": "แนบคนงานเหมือง",
    "Upgraded Parcel": "อัพเกรดพัสดุ",
    "Discount": "การลดราคา",
    "save": "บันทึก",
    "Copied": "คัดลอกแล้ว",
    "Marketplace": "ตลาดกลาง",
    "Parcel name": "ชื่อพัสดุ",
    "Basic Bundle": "ชุดพื้นฐาน",
    "4 Parcels Bundle": "4 พัสดุ Bundle",
    "10 Parcels Bundle": "10 พัสดุ Bundle",
    "40 Parcels Bundle": "40 พัสดุ Bundle",
    "80 Parcels Bundle": "80 พัสดุ Bundle",
    "140 Parcels Bundle": "140 พัสดุ Bundle",
    "240 Parcels Bundle": "240 พัสดุ Bundle",
    "4 Basic Miner Bundle": "4 กลุ่มคนงานเหมือง",
    "10 Basic Miner Bundle": "10 กลุ่มคนงานเหมือง",
    "40 Basic Miner Bundle": "40 กลุ่มคนงานเหมือง",
    "80 Basic Miner Bundle": "80 กลุ่มคนงานเหมือง",
    "140 Basic Miner Bundle": "140 กลุ่มคนงานเหมือง",
    "240 Basic Miner Bundle": "240 กลุ่มคนงานเหมือง",
    "Hashpower": "Hashpower",
    "Something went wrong, please refresh the page and try again": "เกิดข้อผิดพลาด โปรดรีเฟรชหน้านี้แล้วลองอีกครั้ง",
    "Gallery": "แกลลอรี่",
    "Accepted": "ได้รับการยอมรับ",
    "Pending": "รอดำเนินการ",
    "Upload": "ที่อัพโหลด",
    "Uploading image": "กำลังอัพโหลดภาพ",
    "Something went wrong, try again later": "เกิดข้อผิดพลาด โปรดลองอีกครั้งในภายหลัง",
    "You need to be authenticated to upload images to Gallery.": "คุณต้องเข้าสู่ระบบเพื่ออัพโหลดภาพไปยังแกลลอรี่",
    "Assign image": "กำหนดภาพ",
    "Assign image to parcels": "กำหนดรูปภาพให้กับพัสดุ",
    "Remove current image": "ลบภาพปัจจุบัน",
    "Change image": "เปลี่ยนภาพ",
    "Assign to parcels": "มอบหมายให้พัสดุ",
    "Reselect image assignment area?": "เลือกพื้นที่กำหนดรูปภาพอีกครั้งหรือไม่",
    "You have not applied changes. Do you want to reselect image assignment area?": "คุณยังไม่ได้ใช้การเปลี่ยนแปลงคุณต้องการที่จะเลือกพื้นที่การกำหนดภาพหรือไม่?",
    "Image has been successfully changed": "ภาพได้รับการเปลี่ยนแปลงสำเร็จ",
    "Image has been successfully assigned to parcels": "ภาพได้รับการกำหนดให้สำเร็จในพัสดุ",
    "Image has been successfully removed from the parcels": "ภาพได้รับการลบออกจากพัสดุ",
    "Yes": "ใช่",
    "No": "ไม่",
    "Cancel": "ยกเลิก",
    "You have no images": "คุณไม่มีภาพ",
    "Notifications": "Notifications",
    "No notifications": "No notifications",
    "year ago": "ไม่มีการแจ้งเตือน",
    "months ago": "ปีที่แล้ว",
    "month ago": "ปีที่แล้ว",
    "weeks ago": "หลายเดือนก่อน",
    "week ago": "เดือนที่แล้ว",
    "days ago": "หลายสัปดาห์ก่อน",
    "day ago": "อาทิตย์ที่แล้ว",
    "hours ago": "วันที่ผ่านมา",
    "hour ago": "วันก่อน",
    "minutes ago": "ชั่วโมงที่แล้ว",
    "minutes": "ชั่วโมงที่แล้ว",
    "minute": "นาทีที่แล้ว",
    "seconds": "นาที",
    "second": "นาที",
    "Your image was approved": "ภาพของคุณได้รับการอนุมัติ",
    "Your image was rejected": "ภาพของคุณถูกปฏิเสธ",
    "New": "ใหม่",
    "Gallery guide" : "คู่มือแกลเลอรี่",
    "Go to gallery": "ไปที่แกลเลอรี่",
    "How it works": "มันทำงานอย่างไร",
    "Removing image from parcels..." : "ลบภาพออกจากพัสดุ ...",
    "Removing image" : "ลบภาพ",
    "Image has been successfully removed" : "รูปภาพถูกลบออกเรียบร้อยแล้ว",
    "Wrong file size" : "ขนาดไฟล์ผิด",
    "Please upload image files with size less than 10MB": "โปรดอัปโหลดไฟล์ภาพที่มีขนาดน้อยกว่า 10MB",
    "Drag to Reposition": "ลากเพื่อเปลี่ยนตำแหน่ง",
    "Pinch or use the mouse wheel to zoom in or out": "หยิกหรือใช้ล้อเมาส์เพื่อซูมเข้าหรือออก",
    "Drag and move the image with fingers or using your mouse to select area": "ลากและเลื่อนภาพด้วยนิ้วหรือใช้เมาส์เพื่อเลือกพื้นที่",
    "It can take up to 48 hours for the image to be approved. You can view your images awaiting approval in the Pending tab.": "อาจใช้เวลานานถึง 48 ชั่วโมงเพื่อให้ภาพได้รับการอนุมัติคุณสามารถดูภาพของคุณที่รอการอนุมัติในแท็บที่รอดำเนินการ",
    "Delete Image": "ลบภาพ",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable. Land containing this image will also be cleared.": "แน่ใจหรือว่าต้องการลบภาพออกจากแกลเลอรี่ของคุณอย่างถาวร?การดำเนินการนี้ไม่สามารถกู้คืนได้ที่ดินที่มีภาพนี้จะถูกล้างออก",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable." : "แน่ใจหรือว่าต้องการลบภาพออกจากแกลเลอรี่ของคุณอย่างถาวร?การดำเนินการนี้ไม่สามารถกู้คืนได้",
    "Wrong File Type": "ประเภทไฟล์ผิด",
    "Please upload jpg/jpeg image files": "โปรดอัปโหลดไฟล์รูปภาพ JPG/JPEG",
    "Upload Images to Your Gallery": "อัปโหลดรูปภาพไปยังแกลเลอรี่ของคุณ",
    "You can upload images to your gallery from your computer, mobile phone or tablet. Currently only jpg/jpeg image file types are supported. Press the upload button or simply drag and drop the image from computer to upload your image. Image size must be up to 10 MB." : "คุณสามารถอัปโหลดรูปภาพไปยังแกลเลอรี่จากคอมพิวเตอร์โทรศัพท์มือถือหรือแท็บเล็ตปัจจุบันรองรับประเภทไฟล์รูปภาพ JPG/JPEG เท่านั้นกดปุ่มอัปโหลดหรือเพียงแค่ลากและวางภาพจากคอมพิวเตอร์เพื่ออัปโหลดรูปภาพของคุณขนาดภาพต้องสูงถึง 10 MB",
    "Upload image": "อัพโหลดรูปภาพ",
    "Image Approval": "การอนุมัติภาพ",
    "Uploaded images need to be approved before they appear in the approved section of your gallery. This procedure is necessary to filter out inappropriate, explicit content and copyrighted material. Approval can take up to 48 hours. You'll get notified about the verdict whenever you're online." : "ภาพที่อัปโหลดจะต้องได้รับการอนุมัติก่อนที่จะปรากฏในส่วนที่ได้รับอนุมัติของแกลเลอรี่ของคุณขั้นตอนนี้จำเป็นต้องกรองเนื้อหาที่ไม่เหมาะสมเนื้อหาที่ชัดเจนและเนื้อหาที่มีลิขสิทธิ์การอนุมัติอาจใช้เวลาสูงสุด 48 ชั่วโมงคุณจะได้รับการแจ้งเตือนเกี่ยวกับคำตัดสินเมื่อใดก็ตามที่คุณออนไลน์",
    "Approval": "การอนุมัติ",
    "Select Your Land to Assign Image to": "เลือกที่ดินของคุณเพื่อกำหนดภาพให้กับ",
    "You can assign your uploaded images to your owned land. To do so, simply select the parcels by dragging mouse on the land part you want to assign an image to. Please note that image can only be assigned to a rectangular area that is owned by you only." : "คุณสามารถกำหนดรูปภาพที่อัปโหลดไปยังที่ดินของคุณได้ในการทำเช่นนั้นเพียงเลือกพัสดุโดยการลากเมาส์ในส่วนของที่ดินที่คุณต้องการกำหนดภาพให้โปรดทราบว่าสามารถกำหนดรูปภาพให้กับพื้นที่สี่เหลี่ยมที่คุณเป็นเจ้าของเท่านั้น",
    "Land Selection": "การเลือกที่ดิน",
    "Crop Image": "ภาพพืชผล",
    "You can crop a custom part of your image that you want to assign to your land section. You can close in to any area of your image and crop it by dragging the image with mouse or your finger if you're on a mobile device. You can also zoom in and out by using mouse wheel or pinch gesture." : "คุณสามารถครอบตัดส่วนที่กำหนดเองของภาพที่คุณต้องการกำหนดให้กับส่วนที่ดินของคุณคุณสามารถปิดในทุกพื้นที่ของภาพและครอบตัดโดยการลากภาพด้วยเมาส์หรือนิ้วของคุณหากคุณอยู่บนอุปกรณ์มือถือนอกจากนี้คุณยังสามารถซูมเข้าและออกได้โดยใช้ล้อเมาส์หรือท่าทางหยิก",
    "Activate multiple selection": "เปิดใช้งานการเลือกหลายรายการ",
    "Exit multiple selection": "ออกจากการเลือกหลายครั้ง",
    "Owned": "เป็นเจ้าของ",
    "Owned by": "ที่เป็นเจ้าของโดย",
    "Multiple types": "หลายประเภท",
    "seconds ago": "วินาทีที่แล้ว",
    "Super Bundle": "ซุปเปอร์บันเดิล",
    "Balance": "สมดุล",
    "View your purchased Bundles and claim to have them from your balance": "ดู Bundles ที่คุณซื้อและอ้างว่ามีจากยอดคงเหลือของคุณ",
    "Feel free to choose lands on Map.": "รู้สึกอิสระที่จะเลือกที่ดินบนแผนที่",
    "Earn money by using miners for generating rewards.": "หารายได้โดยใช้นักขุดเพื่อสร้างรางวัล",
    "Upgrade your owned lands from 4 slots to 10.": "อัปเกรดดินแดนที่คุณเป็นเจ้าของจาก 4 ช่องเป็น 10 ช่อง",
    "Confirm": "ยืนยัน",
    "You don`t have any available parcels in your inventory": "คุณไม่มีพัสดุในสินค้าคงคลังของคุณ",
    "Months": "เดือน",
    "Super Bundle R6": "ซุปเปอร์บันเดิล R6",
    "Super Bundle R10": "ซุปเปอร์บันเดิล R10",
    "Super Bundle R15": "ชุดสุดคุ้ม R15",
    "Super Bundle R20": "ซุปเปอร์บันเดิล R20",
    "Super Bundle R22 S": "ซุปเปอร์บันเดิล R22 S",
    "Super Bundle R22 M": "ซุปเปอร์บันเดิล R22 M",
    "Super Bundle R22 L": "ซุปเปอร์บันเดิล R22 L",
    "Super Bundle R22 XL": "ซุปเปอร์บันเดิล R22 XL",
    "Super Bundle R22 XXL": "ซุปเปอร์บันเดิล R22 XXL",
    "1 Claim your land": "1 อ้างสิทธิ์ในที่ดินของคุณ",
    "2 Claim your miner": "2 อ้างสิทธิ์คนขุดแร่ของคุณ",
    "3 Upgrade your land": "3 อัพเกรดที่ดินของคุณ",
    "Your NFT balance": "ยอดคงเหลือ NFT ของคุณ",
    "Next price changes in": "การเปลี่ยนแปลงราคาครั้งต่อไปใน",
    "Parcels": "พัสดุ",
    "Get to know the platform usage": "ทำความรู้จักกับการใช้งานแพลตฟอร์ม",
    "What is a Parcel?": "พัสดุคืออะไร?",
    "Land is a unit of area on the GYM Street map and it is also an NFT. You can own as much land on GYM Street as you want.": "ที่ดินเป็นหน่วยพื้นที่บนแผนที่ GYM Street และเป็น NFT คุณสามารถเป็นเจ้าของที่ดินบน GYM Street ได้มากเท่าที่คุณต้องการ",
    "What is a Miner?": "คนขุดแร่คืออะไร?",
    "A miner is what generates income for you at GYM Street. You can attach up to 4 miners on each of your standard land parcels.": "คนขุดแร่คือสิ่งที่สร้างรายได้ให้คุณที่ GYM Street คุณสามารถติดคนงานเหมืองได้สูงสุด 4 คนในแต่ละที่ดินมาตรฐานของคุณ",
    "Miner rewards": "รางวัลคนขุดแร่",
    "My Miners": "คนงานเหมืองของฉัน",
    "My Hashpower": "ของฉัน Hashpower",
    "Total Claimed": "อ้างสิทธิ์ทั้งหมด",
    "Total pending rewards": "รางวัลที่รอดำเนินการทั้งหมด",
    "Claim all": "เรียกร้องทั้งหมด",
    "Daily rewards": "รางวัลรายวัน",
    "Weekly rewards": "รางวัลรายสัปดาห์",
    "Monthly rewards": "รางวัลประจำเดือน",
    "Yearly rewards": "รางวัลประจำปี",
    "ROI": "ROI",
    "ROI Calculator": "เครื่องคิดเลข ROI",
    "Global Statistics": "สถิติโลก",
    "Global Hashpower": "ระดับโลก Hashpower",
    "Your Shares": "หุ้นของคุณ",
    "Daily Global Rewards": "รางวัลระดับโลกรายวัน",
    "Price": "ราคา",
    "Total Minted": "มิ้นต์ทั้งหมด",
    "total": "ทั้งหมด",
    "standard": "มาตรฐาน",
    "upgraded": "อัพเกรด",
    "active": "คล่องแคล่ว",
    "inactive": "ไม่ได้ใช้งาน",
    "Attach miner": "แนบคนขุดแร่",
    "Total Miners": "รวมคนงานเหมือง",
    "Total Minted Rewards": "รางวัลที่สร้างเสร็จทั้งหมด",
    "What is parcel and how to use it?": "Parcel คือหน่วยพื้นที่บนแผนที่ {metaverse} ที่มาพร้อมกับความสามารถในการสร้างเหรียญ เป็นเจ้าของพัสดุและสร้างรายได้",
    "What is a miner and how to use it?": "The Miner เป็นตัวสร้างรายได้ของคุณบน {metaverse} เพิ่มรายได้ของคุณโดยแนบ Miners เข้ากับพัสดุของคุณ <br/> <br/> GYMNET Miner และ Dual Miner ทำงานที่ความเร็วการขุด 1,000 Mh/s",
    "Your active/inactive hashpower, 1 miner = 1,000 Mh/s, your Miners 0 x 1,000Mh/s = 0.00": "ใช้งาน/ไม่ได้ใช้งาน hashpower 1 เครื่องขุด = 1,000 Mh/s เครื่องขุดของคุณ 0 x 1,000Mh/s = 0.00",
    "The amount of the cumulated claimed rewards": "จำนวนของรางวัลที่อ้างสิทธิ์สะสม",
    "Your current available pending rewards.": "รางวัลที่รอดำเนินการในปัจจุบันของคุณ",
    "Congratulations!": "ยินดีด้วย!",
    "Upgrade your parcels to mine even more?": "อัปเกรดพัสดุของคุณให้เป็นของฉันมากยิ่งขึ้น?",
    "You have NUM parcels that are not upgraded.": "คุณมี {parcels} พัสดุที่\nไม่ได้รับการอัปเกรด",
    "Go to Map": "ไปที่แผนที่",
    "You're mining less than the average user.": "คุณกำลังขุดน้อยกว่าผู้ใช้ทั่วไป",
    "Purchase more miners to improve your results": "ซื้อเครื่องขุดเพิ่มเพื่อปรับปรุงผลลัพธ์ของคุณ",
    "Buy more miners": "ซื้อเครื่องขุดเพิ่มเติม",
    "Thanks, I'll stay below average": "ขอบคุณ ฉันจะอยู่ต่ำกว่าค่าเฉลี่ย",
    "You aren't mining at full speed.": "คุณไม่ได้ขุดด้วยความเร็วสูงสุด",
    "Do you want to buy more parcels now ?": "คุณต้องการซื้อพัสดุเพิ่มเติมตอนนี้หรือไม่ ?",
    "I definitely do": "แน่นอนฉันทำ",
    "No, I'll let my miners to rust": "ไม่ ฉันจะปล่อยให้คนงานเหมืองของฉันเป็นสนิม",
    "You have no miners": "คุณไม่มีคนงานเหมือง",
    "Buy miners": "ซื้อเครื่องขุดแบบคู่",
    "You haven't upgradeable parcels": "คุณยังไม่มีพัสดุที่สามารถอัพเกรดได้",
    "You haven't inactive miners": "คุณไม่มีนักขุดที่ไม่ได้ใช้งาน",
    "Close": "ปิด I",
    "Read more": "อ่านเพิ่มเติม",
    "Drag and drop an image, or Browse": "ลากและวางรูปภาพ หรือ <label class=\"browse-button\" for=\"upload-image\">เรียกดู</label>",
    "Browse an image": "<label class=\"browse-button\" for=\"upload-image\">เรียกดู</label> รูปภาพ",
    "Drop to upload": "ลดลงเพื่ออัปโหลด",
    "File not supported": "ไม่รองรับไฟล์",
    "Upload your parcel image": "อัปโหลดภาพพัสดุของคุณ",
    "Up to 10MB, jpg/jpeg": "สูงถึง 10MB JPG/JPEG",
    "High resolution images, should align with your land shape (you can crop after upload)": "<span class=\"bold-info\">รูปภาพความละเอียดสูง</span> ควรสอดคล้องกับรูปร่างที่ดินของคุณ (คุณสามารถครอบตัดหลังจากอัปโหลดได้)",
    "Same image may be used on different lands owned by the user": "<span class=\"bold-info\">รูปภาพเดียวกันอาจถูกใช้</span> ดินแดนอื่นที่ผู้ใช้เป็นเจ้าของ",
    "No violence, offensive, graphic or 18+ content": "ไม่มีความรุนแรง ไม่เหมาะสม ภาพกราฟิกหรือ<span class=\"bold-info\">เนื้อหาอายุ 18 ปีขึ้นไป</span>",
    "No third-party copyrighted content, no logos or screenshots from Gymstreet and Gymnetwork": "<span class=\"bold-info\">ไม่มีเนื้อหาที่มีลิขสิทธิ์ของบุคคลที่สาม</span> ไม่มีโลโก้หรือภาพหน้าจอจาก <span class=\"bold-info\">Gymstreet และ Gymnetwork</span>",
    "No images already used by another user, no images from DeFi listing website images": "<span class=\"bold-info\">ไม่มีรูปภาพที่ใช้แล้ว</span> โดยผู้ใช้รายอื่น ไม่มีรูปภาพจาก DeFi ที่แสดงรูปภาพเว็บไซต์",
    "No religious images": "<span class=\"bold-info\">ไม่มีรูปศาสนา</span>",
    "Unlock miners by purchasing at least 1 land parcel": "ปลดล็อกนักขุดโดยการซื้อที่ดินอย่างน้อย 1 ผืน",
    "OK": "ตกลง",
    "Your parcels": "พัสดุของคุณ",
    "Your miners": "คนงานเหมืองของคุณ",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcels to start mining": "คุณไม่ได้ขุดด้วยความเร็วเต็มที่ คุณมี {miners} คนที่ต้องแนบไปกับพัสดุของคุณเพื่อเริ่มการขุด",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcel to start mining": "คุณไม่ได้ขุดด้วยความเร็วสูงสุด คุณมี {miners} คนที่ต้องแนบไปกับพัสดุของคุณเพื่อเริ่มการขุด",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcels to start mining": "คุณไม่ได้ขุดด้วยความเร็วสูงสุด คุณมี {miners} คนที่ต้องแนบไปกับพัสดุของคุณเพื่อเริ่มการขุด",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcel to start mining": "คุณไม่ได้ขุดด้วยความเร็วสูงสุด คุณมี {miners} คนที่ต้องแนบไปกับพัสดุของคุณเพื่อเริ่มการขุด",
    "Info Center": "ศูนย์ข้อมูล",
    "Standard Parcel": "พัสดุมาตรฐาน",
    "You have no parcels": "คุณไม่มีพัสดุ",
    "Single Products": "ผลิตภัณฑ์เดียว",
    "Parcels sold / Total parcels": "พัสดุขายแล้ว / พัสดุทั้งหมด",
    "You can mine even more. Purchase more miners to improve your results": "คุณสามารถขุดได้มากขึ้น ซื้อเครื่องขุดเพิ่มเพื่อปรับปรุงผลลัพธ์ของคุณ",
    "In Progress": "กำลังดำเนินการ",
    "Active": "คล่องแคล่ว",
    "Learn More": "เรียนรู้เพิ่มเติม",
    "Add to wallet": "เพิ่มลงในกระเป๋าเงิน",
    "Your Balance": "ยอดคงเหลือของคุณ",
    "The Promotion starts in:": "โปรโมชั่นเริ่มใน:",
    "Please go to your wallet, which you just connected, and verify your login by confirming the requested transaction. It's free of charge!": "โปรดไปที่กระเป๋าเงินของคุณที่คุณเพิ่งเชื่อมต่อ และยืนยันการเข้าสู่ระบบของคุณโดยยืนยันธุรกรรมที่ร้องขอ ไม่มีค่าใช้จ่าย!",
    "Contract addresses": "ที่อยู่ตามสัญญา",
    "Add TOKEN to wallet": "เพิ่ม {token} ในกระเป๋าเงิน",
    "I accept the terms and conditions": "ฉันยอมรับข้อกำหนดและเงื่อนไขต่างๆ",
    "Log In": "เข้าสู่ระบบ",
    "Sign Up": "ลงชื่อ",
    "Connect My Wallet": "เชื่อมต่อกระเป๋าเงินของฉัน",
    "Change Email": "เปลี่ยนอีเมล",
    "Reset Password": "รีเซ็ตรหัสผ่าน",
    "Your password has been changed.": "รหัสผ่านของคุณเปลี่ยนไปแล้ว",
    "Please log into your account.": "กรุณาเข้าสู่ระบบบัญชีของคุณ",
    "Confirm Email": "ยืนยันอีเมล์",
    "Emails don't match": "อีเมลไม่ตรงกัน",
    "Or": "หรือ",
    "Sign Up With Email": "ลงทะเบียนด้วยอีเมล",
    "Already have an account?": "มีบัญชีอยู่แล้ว?",
    "Recover": "ฟื้นตัว",
    "Enter New Password": "ใส่รหัสผ่านใหม่",
    "Confirm New Password": "ยืนยันรหัสผ่านใหม่",
    "Password": "รหัสผ่าน",
    "Forgot password?": "ลืมรหัสผ่าน?",
    "Password is required": "ต้องการรหัสผ่าน",
    "Password confirmation is required": "จำเป็นต้องมีการยืนยันรหัสผ่าน",
    "Passwords do not match": "รหัสผ่านไม่ตรงกัน",
    "Password must contain at least one uppercase letter, one lowercase letter and one number": "รหัสผ่านต้องมีตัวอักษรตัวพิมพ์ใหญ่อย่างน้อยหนึ่งตัวอักษรตัวพิมพ์เล็กหนึ่งตัวและหมายเลขหนึ่ง",
    "Password must be at least 8 characters": "รหัสผ่านต้องมีอย่างน้อย 8 อักขระ",
    "Password must not exceed 24 characters": "รหัสผ่านต้องไม่เกิน 24 ตัวอักษร",
    "Password must contain at least one special character": "รหัสผ่านต้องมีอักขระพิเศษอย่างน้อยหนึ่งตัว",
    "Password cannot contain spaces": "รหัสผ่านไม่สามารถมีช่องว่าง",
    "Email is required": "จำเป็นต้องมีอีเมล",
    "Email confirmation is required": "จำเป็นต้องมีการยืนยันอีเมล",
    "Please enter a valid email": "กรุณาใส่อีเมลที่ถูกต้อง",
    "Step NUM1 of NUM2": "ขั้นตอนที่ {num1} จาก {num2}",
    "Create Password": "สร้างรหัสผ่าน",
    "Get Started": "เริ่ม",
    "Create a Wallet": "สร้างกระเป๋าเงิน",
    "Confirm on this screen": "ยืนยันบนหน้าจอนี้",
    "Pick a password on the next step. This needs to be at least 8 symbols long.": "เลือกรหัสผ่านในขั้นตอนถัดไป ต้องมีความยาวอย่างน้อย 8 สัญลักษณ์",
    "Wallet Ready": "กระเป๋าเงินพร้อม",
    "Add Binance Smart Chain": "เพิ่ม Binance Smart Chain",
    "Click on the button below to add Binance Smart Chain Network to your MetaMask wallet.": "คลิกที่ปุ่มด้านล่างเพื่อเพิ่มเครือข่าย Binance Smart Chain ไปยังกระเป๋าเงิน MetaMask ของคุณ",
    "Confirm the connection request": "ยืนยันคำขอเชื่อมต่อ",
    "Get Started!": "เริ่ม!",
    "Secure Wallet": "กระเป๋าเงินที่ปลอดภัย",
    "Confirm your password to continue.": "ยืนยันรหัสผ่านของคุณเพื่อดำเนินการต่อ",
    "You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.": "คุณจะต้องใช้มันเพื่อกู้คืนกระเป๋าเงินของคุณหากคุณไม่สามารถเข้าถึงคอมพิวเตอร์ได้ และควรเก็บไว้ในที่ปลอดภัย ใครก็ตามที่สามารถเข้าถึงวลีสำรอง 12 คำของคุณจะสามารถกู้คืนเงินของคุณได้ ดังนั้นจงปิดเป็นความลับ",
    "You have successfully set up Your wallet.Welcome to the world of WEB3!": "คุณตั้งค่ากระเป๋าเงินของคุณสำเร็จแล้ว\nยินดีต้อนรับสู่โลกของ WEB3!",
    "Confirm Password": "ยืนยันรหัสผ่าน",
    "Install here.": "ติดตั้งที่นี่",
    "Something went wrong, please try verifying your email again.": "มีบางอย่างผิดปกติโปรดลองยืนยันอีเมลของคุณอีกครั้ง",
    "Looks like your password reset link has expired. Please try again.": "ดูเหมือนว่าลิงค์รีเซ็ตรหัสผ่านของคุณหมดอายุแล้วกรุณาลองอีกครั้ง.",
    "Looks like your password reset link has already been used. Please request it again.": "ดูเหมือนว่าลิงก์รีเซ็ตรหัสผ่านของคุณได้ถูกใช้ไปแล้วโปรดขออีกครั้ง",
    "Couldn't recover password": "ไม่สามารถกู้คืนรหัสผ่านได้",
    "Couldn't reset your password.": "ไม่สามารถรีเซ็ตรหัสผ่านของคุณได้",
    "Couldn't login.": "เข้าสู่ระบบไม่ได้",
    "Couldn't sign you up.": "ไม่สามารถลงทะเบียนคุณได้",
    "Resend Email": "ส่งอีเมลกลับ",
    "Couldn't resend confirmation email.": "ไม่สามารถส่งอีเมลยืนยันอีกครั้ง",
    "Provided email is already in use.": "มีการใช้อีเมลแล้ว",
    "You have already verified your email address": "คุณได้ยืนยันที่อยู่อีเมลของคุณแล้ว",
    "Invalid or expired verification code": "รหัสตรวจสอบที่ไม่ถูกต้องหรือหมดอายุ",
    "An error occurred while logging in. Please try again.": "เกิดข้อผิดพลาดขณะเข้าสู่ระบบโปรดลองอีกครั้ง",
    "We sent an email to {EMAIL} with a link to finalize your registration": "เราส่งอีเมลไปที่ {EMAIL} พร้อมลิงค์เพื่อจบการลงทะเบียนของคุณ",
    "We sent an email to {EMAIL} with a link to change your password": "เราส่งอีเมลไปที่ {email} พร้อมลิงค์เพื่อเปลี่ยนรหัสผ่านของคุณ",
    "Referral ID": "รหัสอ้างอิง",
    "On the next step, tap on 'Create a new wallet'.": "ในขั้นตอนต่อไปให้แตะที่ \"Create a new wallet\"",
    "On the next step, tap on 'Create a wallet'.": "ในขั้นตอนถัดไป แตะที่ \"Create a wallet\"",
    "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower case letters, symbols and numbers.": "เราขอแนะนำให้ใช้รหัสผ่านที่ไม่ซ้ำกันอย่างสมบูรณ์ซึ่งไม่ได้ใช้ที่อื่นและมีส่วนผสมของตัวอักษรตัวพิมพ์ใหญ่และตัวพิมพ์เล็ก ๆ",
    "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.": "ใส่คำที่จดจำในขั้นตอนก่อนหน้าตามลำดับแตะปุ่ม \"Complete\" เมื่อคุณทำสำรองเสร็จแล้ว",
    "On this step you can choose. We recommend you do this now as you might otherwise lose access to your wallet. Tap on 'Start' to continue.": "ในขั้นตอนนี้คุณสามารถเลือก \nเราขอแนะนำให้คุณทำตอนนี้ เพราะไม่เช่นนั้นคุณอาจสูญเสียการเข้าถึงกระเป๋าเงินของคุณ \nแตะที่ \"Start\" เพื่อดำเนินการต่อ",
    "On this step tap on 'Start' to continue.": "ในขั้นตอนนี้ ให้แตะที่ \"Start\" \nเพื่อดำเนินการต่อ",
    "Tap on the burger menu of the app in the top left corner of the screen.": "แตะที่เมนูเบอร์เกอร์ของแอพที่มุมซ้ายบนของหน้าจอ",
    "Next, tap on Settings in the menu to the left.": "จากนั้น แตะที่ \"Settings\" ในเมนูทางด้านซ้าย",
    "Next, tap on Networks.": "ถัดไป แตะที่ \"Networks\"",
    "Add More Tokens": "เพิ่มโทเค็นเพิ่มเติม",
    "In the search field, type in BNB Smart Chain and add it to your network.": "ในช่องค้นหา พิมพ์ \"BNB Smart Chain\" และเพิ่มลงในเครือข่ายของคุณ",
    "Next, select Custom Token. Paste the address of the smart contract you copied into the token address field. In the Token Symbol field, type SP, and in the Token Decimal, put 0. Then click the 'Import' button to complete your setup.": "จากนั้น เลือก \"Custom Token\" วางที่อยู่ของสัญญาอัจฉริยะที่คุณคัดลอกลงในช่อง \"Token Address\" ในช่อง \"Token Symbol\" ให้พิมพ์ SP และในช่อง \"Token Decimal\" ให้ใส่ 0 จากนั้นคลิกปุ่ม \"Import\" เพื่อให้การตั้งค่าของคุณเสร็จสมบูรณ์",
    "Add Network": "เพิ่มเครือข่าย",
    "Give permission to the platform to add a network.": "อนุญาตให้แพลตฟอร์มเพิ่มเครือข่าย",
    "Extra PERCENT% Discount": "ส่วนลดพิเศษ {PERCENT}%",
    "Tap on the 'Import Tokens' link to add a Custom Token.": "แตะที่ลิงค์ \"Import Tokens\" เพื่อเพิ่มโทเค็นที่กำหนดเอง",
    "Click on the button 'Approve' to allow the connection request.": "คลิกที่ปุ่ม \"Approve\" เพื่ออนุญาตคำขอการเชื่อมต่อ",
    "Confirm Secret Phrase": "ยืนยันวลีลับ",
    "Click on the button 'Switch network' to allow the connection request.": "คลิกที่ปุ่ม \"Switch network\" เพื่ออนุญาตคำขอการเชื่อมต่อ",
    "Payment Method": "วิธีการชำระเงิน",
    "Select cryptocurrency": "เลือกสกุลเงินดิจิทัล",
    "Please use an Ethereum BEP-20 address.": "โปรดใช้ที่อยู่ Ethereum BEP-20",
    "Connect to Gymstreet": "เชื่อมต่อกับ Gym Street",
    "Now to access the Gym Street Metaverse, you need to open it with the MetaMask browser. Go to your MetaMask wallet app, choose 'Browser' from the side navigation menu, search for Gym Street in the browser tab.": "ในการเข้าถึงGym Street Metaverse คุณต้องเปิดด้วยเบราว์เซอร์MetaMask ไปที่แอปกระเป๋าเงินMetaMask เลือก\"Browser\" จากเมนูการนำทางด้านข้าง ค้นหาGym Street ในแท็บเบราว์เซอร์",
    "First, let's set up your cryptocurrency wallet.": "ก่อนอื่นมาตั้งค่ากระเป๋าเงินดิจิตอลของคุณ",
    "Follow the next steps to continue the setup.": "ทำตามขั้นตอนถัดไปเพื่อดำเนินการตั้งค่าต่อไป",
    "Your balance was updated": "ยอดคงเหลือของคุณได้รับการอัปเดต",
    "Once installed, you should see the below splash screen. Click the 'Get Started' button to begin creating your Ethereum wallet using MetaMask.": "เมื่อติดตั้งแล้ว คุณจะเห็นหน้าจอเริ่มต้นด้านล่าง คลิกปุ่ม \"Get Started\" เพื่อเริ่มสร้างกระเป๋าเงิน Ethereum ของคุณโดยใช้ MetaMask",
    "You will then be asked if you want to help improve MetaMask. Click 'No, Thanks' if this doesn't interest you, otherwise click 'I agree'.": "จากนั้นคุณจะถูกถามว่าคุณต้องการช่วยปรับปรุง MetaMask หรือไม่คลิก \"No, Thanks\" หากสิ่งนี้ไม่สนใจคุณมิฉะนั้นคลิก \"I agree\"",
    "MetaMask will then present you with your 12-word backup phrase. You will need to write this down in the same order displayed on your screen.": "MetaMask จะนำเสนอวลีสำรอง 12 คำของคุณคุณจะต้องเขียนสิ่งนี้ลงในลำดับเดียวกันที่แสดงบนหน้าจอของคุณ",
    "After Downloading and opening MetaMask, tap on 'Get Started'.": "หลังจากดาวน์โหลดและเปิด Metamask ให้แตะที่ \"Get Started\"",
    "Improve Metamask": "ปรับปรุง MetaMask",
    "Congratulations! You have set up your MetaMask wallet. Confirm the next few pop-ups on the screen to access your wallet.": "ยินดีด้วย!คุณได้ตั้งค่ากระเป๋าเงิน MetaMask แล้วยืนยันป๊อปอัปสองสามตัวถัดไปบนหน้าจอเพื่อเข้าถึงกระเป๋าเงินของคุณ",
    "Import Binance Smart Chain": "นำเข้า Binance Smart Chain",
    "Next, copy the NFT Smart Contract by tapping on the button below.": "จากนั้น คัดลอก NFT Smart Contract โดยแตะที่ปุ่มด้านล่าง",
    "Copy NFT Contract": "คัดลอก NFT Contract",
    "Install MetaMask": "ติดตั้ง MetaMask",
    "Install MetaMask here.": "ติดตั้ง MetaMask ที่นี่",
    "Guide for MetaMask Wallet connection process.": "คู่มือสำหรับกระบวนการเชื่อมต่อกระเป๋าเงิน Metamask",
    "MetaMask will then present you with your 12-word backup phrase.": "MetaMask จะนำเสนอวลีสำรอง 12 คำของคุณ",
    "MetaMask Secret Phrase": "วลีลับ MetaMask",
    "Copy NFT Smart Contract": "คัดลอก NFT Smart Contract",
    "You are almost finished with the MetaMask setup process. Just click 'All done' on the last page, and you will be automatically logged into MetaMask. If you ever log out, you can log back in by clicking on the icon added to your web browser (usually, it's next to the URL bar).": "คุณเกือบจะเสร็จสิ้นขั้นตอนการตั้งค่า MetaMask แล้ว เพียงคลิก 'All done' ในหน้าสุดท้าย แล้วคุณจะเข้าสู่ MetaMask โดยอัตโนมัติ หากคุณเคยออกจากระบบ คุณสามารถเข้าสู่ระบบอีกครั้งโดยคลิกที่ไอคอนที่เพิ่มไปยังเว็บเบราว์เซอร์ของคุณ (โดยปกติจะอยู่ถัดจากแถบ URL)",
    "Your payment processing is complete. Your NFT balance has been updated!": "การประมวลผลการชำระเงินของคุณเสร็จสมบูรณ์ ยอดคงเหลือ NFT ของคุณได้รับการอัปเดตแล้ว!",
    "Your payment is received but is incomplete. Please pay AMOUNT CURRENCY more to complete the payment.": "ได้รับการชำระเงินของคุณแต่ไม่สมบูรณ์ โปรดชำระเงินอีก {amount} {currency} เพื่อดำเนินการชำระเงินให้เสร็จสิ้น",
    "Terms & Conditions": "ข้อตกลงและเงื่อนไข",
    "Email Verification": "การยืนยันอีเมล",
    "Transaction history": "ประวัติการทำรายการ",
    "Order history": "ประวัติความเป็นมา",
    "Product": "ผลิตภัณฑ์",
    "Quantity": "ปริมาณ",
    "Payment UID": "UID การชำระเงิน",
    "Status": "สถานะ",
    "Currency": "สกุลเงิน",
    "Autoship":"ออโต้ชิป",
    "Amount": "จำนวน",
    "Date": "วันที่",
    "Tx ID": "Tx ID",
    "Completed": "สมบูรณ์",
    "MetaMask is not installed": "ไม่ได้ติดตั้ง MetaMask",
    "If you have already installed MetaMask, please": "หากคุณได้ติดตั้ง {MetaMask} แล้ว โปรด",
    "refresh page": "รีเฟรชหน้า",
    "to continue.": "เพื่อจะดำเนินการต่อ.",
    "You have successfully installed MetaMask. Now you can add a Network.": "คุณติดตั้ง MetaMask สำเร็จแล้ว ตอนนี้คุณสามารถเพิ่มเครือข่าย",
    "You already have this network": "คุณมีเครือข่ายนี้แล้ว",
    "Continue": "ดำเนินการต่อ",
    "Finalize Registration": "จบการลงทะเบียน",
    "Please confirm your email to continue": "โปรดยืนยันอีเมลของคุณเพื่อดำเนินการต่อ",
    "New email must be different from current email": "อีเมลใหม่ต้องแตกต่างจากอีเมลปัจจุบัน",
    "Actions": "การกระทำ",
    "Request to change the chain has been rejected": "คำขอเปลี่ยนห่วงโซ่ถูกปฏิเสธ",
    "Switch Chain": "สวิตช์โซ่",
    "Choose your Metaverse": "เลือก Metaverse ของคุณ",
    "You need to choose your Metaverse to continue. Maps, Dashboard and My Products information will vary depending on your selection. Currently {METAVERSE} Metaverse is selected.": "คุณต้องเลือก Metaverse ของคุณเพื่อดำเนินการต่อ ข้อมูลแผนที่ แดชบอร์ด และผลิตภัณฑ์ของฉันจะแตกต่างกันไปขึ้นอยู่กับการเลือกของคุณ ปัจจุบัน {METAVERSE} Metaverse ถูกเลือกไว้",
    "Can't check your purchases": "ไม่สามารถตรวจสอบการซื้อของคุณได้",
    "Attach {COUNT} miners": "แนบคนงานเหมือง {COUNT} คน",
    "Attach 1 miner": "แนบ 1 คนขุดแร่",
    "View on map": "ดูบนแผนที่",
    "Buy {COUNT} parcels": "ซื้อ {COUNT} พัสดุ",
    "Buy 1 parcel": "ซื้อ 1 ห่อ",
    "Buy {COUNT} miners": "ซื้อเครื่องขุดแบบคู่ {COUNT} เครื่อง",
    "Buy 1 miner": "ซื้อ 1 เครื่องขุดแบบคู่",
    "Metaverses": "เมตาเวิร์ส",
    "My NFTs": "NFT ของฉัน",
    "Total Hashpower (Mh/s)": "แฮชพาวเวอร์ รวม (Mh/s)",
    "Available Miner Slots": "ช่องขุดที่มีอยู่",
    "Average ROI past 9 months": "ROI เฉลี่ย 9 เดือนที่ผ่านมา",
    "Login Via Crypto Wallet": "เข้าสู่ระบบผ่าน Crypto Wallet",
    "Land reserved for PARTNER": "ที่ดินที่สงวนไว้สำหรับ {PARTNER}",
    "About PARTNER": "เกี่ยวกับ{PARTNER}",
    "swap": "แลกเปลี่ยน",
    "transfer": "โอนย้าย",
    "approve": "อนุมัติ",
    "max": "สูงสุด",
    "The minimum amount to claim is N": "จำนวนเงินขั้นต่ำในการเรียกร้องคือ {n}",
    "paginationText": "กำลังแสดง {from} - {to} ของ {count}",
    "turnovers": "ผลประกอบการ",
    "Total partners": "ยอดรวมพันธมิตร",
    "refreshData": "รีเฟรชข้อมูล",
    "NFT Turnover": "NFT มูลค่าการซื้อขาย",
    "Single Pool Turnover": "มูลค่าการซื้อขายพูลเดี่ยว",
    "depth": "ความลึก",
    "turnover": "มูลค่าการซื้อขาย",
    "weekly": "รายสัปดาห์",
    "monthly": "รายเดือน",
    "wallet": "กระเป๋าเงิน",
    "columns": "คอลัมน์",
    "filters": "ตัวกรอง",
    "level": "ระดับ",
    "search": "ค้นหา",
    "partners": "พันธมิตร",
    "Farming Turnover": "มูลค่าการซื้อขายทางการเกษตร",
    "Vault Turnover": "มูลค่าการซื้อขายห้องนิรภัย",
    "Careers status": "สถานะอาชีพ",
    "GYMNET Balance": "ยอดคงเหลือ GYMNET",
    "Save": "บันทึก",
    "Swap": "แลกเปลี่ยน",
    "Turnover pool": "ยอดมูลค่าการซื้อขาย",
    "Global Line Bonus": "โบนัสโกลบอลไลน์",
    "Turnover pools": "ยอดมูลค่าการซื้อขาย",
    "Tax pools": "กองทุนภาษี ({n}เดือน)",
    "Total qualified turnover": "มูลค่าการซื้อขายรวมทั้งหมด",
    "My claimed rewards": "รางวัลที่อ้างสิทธิ์ของฉัน",
    "My pending rewards": "รางวัลที่รอดำเนินการของฉัน",
    "Week": "สัปดาห์",
    "Month": "เดือน",
    "In order to get qualified for any pool, you’ll have to follow the 50% turnover rule: The maximum share each direct partner can contribute to the required turnover is up to 50% of the turnover coming from NFT sales.": "เพื่อให้มีคุณสมบัติสำหรับพูลใด ๆ คุณจะต้องปฏิบัติตามกฎการหมุนเวียน 50%: ส่วนแบ่งสูงสุดที่พันธมิตรโดยตรงแต่ละรายสามารถมีส่วนร่วมในมูลค่าการซื้อขายที่ต้องการนั้นสูงถึง 50% ของมูลค่าการซื้อขายที่มาจากการขาย NFT",
    "View more": "ดูเพิ่มเติม",
    "Turnover progress": "ความคืบหน้ามูลค่าการซื้อขาย",
    "Thank you for your participation in Global Line Incentive!": "ขอขอบคุณที่เข้าร่วม Global Line Incentive!",
    "Global line before June 2023": "สายทั่วโลกก่อนเดือนมิถุนายน 2023",
    "Global line before March 2023": "สายทั่วโลกก่อนมีนาคม 2023",
    "More info": "ข้อมูลเพิ่มเติม",
    "Your Position": "ตำแหน่งของคุณ",
    "Last Position": "ตำแหน่งสุดท้าย",
    "My line length": "ความยาวบรรทัดของฉัน",
    "Global Line Turnover": "มูลค่าการซื้อขายทั่วโลก",
    "The turnover generated from all participants who joined the Global Line after you.": "มูลค่าการซื้อขายที่เกิดจากผู้เข้าร่วมทั้งหมดที่เข้าร่วม Global Line หลังจากคุณ",
    "Your purchases": "การซื้อของคุณ",
    "You need purchases of at least $100 to become a Global Line member.": "คุณต้องซื้ออย่างน้อย $100 เพื่อเป็นสมาชิก Global Line",
    "Your pending income": "รายได้ที่รอดำเนินการ",
    "Buy more NFTs to increase your shares": "ซื้อ <strong><i>NFTs</i></strong> เพิ่มเพื่อเพิ่ม<strong>หุ้น</strong>ของคุณ",
    "New Registrations": "การลงทะเบียนใหม่",
    "Turnover": "มูลค่าการซื้อขาย",
    "Global level": "ระดับโลก",
    "Joining time": "เวลาเข้าร่วม",
    "Global Line Total Turnover": "มูลค่าการซื้อขายรวมของ Global Line",
    "Global Line end": "สิ้นสุดบรรทัดทั่วโลก",
    "Please confirm your claim": "โปรดยืนยันการอ้างสิทธิ์ของคุณ",
    "After you confirm, you will receive your rewards on your balance.": "หลังจากที่คุณยืนยัน คุณจะได้รับรางวัลตามยอดคงเหลือของคุณ",
    "Select Language": "เลือกภาษา",
    "Download": "ดาวน์โหลด",
    "Your Income": "รายได้ของคุณ",
    "Shares": "หุ้น",
    "Single Miner": "คนขุดคนเดียว",
    "Super Bundles": "ซุปเปอร์บันเดิ้ล",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%.": "ส่วนแบ่งสูงสุดที่หุ้นส่วนทางตรงแต่ละรายสามารถนำไปสู่มูลค่าการซื้อขายที่ต้องการได้นั้นสูงถึง 50%",
    "Your partners": "พันธมิตรของคุณ",
    "Generated turnover": "มูลค่าการซื้อขายที่เกิดขึ้น",
    "Qualified turnover": "มูลค่าการซื้อขายที่ผ่านการรับรอง",
    "Generated": "สร้างขึ้น",
    "Qualified": "ผ่านการรับรอง",
    "Your partners turnover statistics will appear here.": "สถิติการหมุนเวียนของพันธมิตรของคุณจะปรากฏที่นี่",
    "Pools": "กองทุน",
    "Qualification": "คุณสมบัติ",
    "Rewards": "รางวัล",
    "Participants": "ผู้เข้าร่วม",
    "Total rewards": "รางวัลรวม",
    "Rewards per participant": "รางวัลต่อผู้เข้าร่วม",
    "Land sales contribution": "เงินสมทบการขายที่ดิน",
    "Maintainance contribution": "ค่าบำรุงรักษา",
    "Distributed": "แจกจ่ายแล้ว",
    "Refresh data": "รีเฟรชข้อมูล",
    "You will receive your rewards directly on your wallet": "คุณจะได้รับรางวัลโดยตรงจากกระเป๋าเงินของคุณ",
    "Total claimed": "ยอดรวมที่รับทั้งหมด",
    "Next distribution": "การกระจายครั้งต่อไป",
    "Unclaimed BUSD rewards": "รางวัล BUSD ที่ไม่มีการรับ",
    "Required level": "ระดับที่ต้องการ",
    "NFT sales percent": "ยอดขาย NFT {n}%",
    "Total qualified participants": "ผู้เข้าร่วมที่มีคุณสมบัติทั้งหมด",
    "TxID": "ID Tx ",
    "Tx Type": "ประเภท Tx",
    "Global line reward": "รางวัลระดับโลก",
    "Your share": "แบ่งปันของคุณ",
    "Income before": "รายได้มาก่อน",
    "Income after": "รายได้หลัง",
    "NFT Income": "รายได้ NFT",
    "NFT Commissions Total": "ค่าคอมมิชชั่น NFT รวม",
    "NFT Statistic": "สถิติ NFT",
    "Level": "ระดับ",
    "User": "ผู้ใช้",
    "Your current pin": "พินปัจจุบันของคุณ",
    "Turnover for the next pin": "มูลค่าการซื้อขายสำหรับพินถัดไป",
    "Your next incentive": "สิ่งจูงใจต่อไปของคุณ",
    "Your next bonus": "โบนัสครั้งต่อไปของคุณ",
    "Incentives": "สิ่งจูงใจ",
    "Team of": "ทีมของ",
    "Partners": "พันธมิตร",
    "Cash Bonus": "โบนัสเงินสด",
    "Your pin": "พินของคุณ",
    "Incentive": "แรงจูงใจ",
    "Pin": "เข็มหมุด",
    "Level pool": "ระดับกองทุน",
    "Go to PARTNER": "ไปที่ {PARTNER}",
    "Partnerships": "ห้างหุ้นส่วน",
    "Cannot find EMAIL_OR_WALLET": "ไม่พบ {EMAIL_OR_WALLET}",
    "Incentive Ends In: ": "สิ่งจูงใจ <strong>สิ้นสุดใน</strong>:",
    "Your Rewards History": "ประวัติรางวัลของคุณ",
    "Dubai & Sofia Trip": "เที่ยวดูไบ & โซเฟีย",
    "No Rewards": "ไม่มีรางวัล",
    "No Partners": "ไม่มีพันธมิตร",
    "Reward": "รางวัล",
    "Granted": "ได้รับ",
    "Transaction": "ธุรกรรม",
    "Free BUNDLE": "ฟรี {BUNDLE}",
    "Team Purchases": "การซื้อทีม",
    "Direct Sales": "ขายตรง",
    "You have won this reward": "คุณได้รับรางวัลนี้",
    "50% Turnover Rule is applied for all incentives!": "กฎการหมุนเวียน 50% ใช้สำหรับสิ่งจูงใจทั้งหมด!",
    "Please note that you have the chance to earn a complimentary R8 Bundle in two different ways: either by reaching 20k in direct sales or by achieving 100k in team purchases.": "โปรดทราบว่าคุณมีโอกาสที่จะได้รับชุด R8 ฟรีในสองวิธีที่แตกต่างกัน: โดยการเข้าถึง 20,000 ในการขายตรงหรือโดยการบรรลุ 100,000 ในการซื้อแบบทีม",
    "Insufficient funds for gas, please add BNB to your wallet": "เงินทุนไม่เพียงพอสำหรับก๊าซโปรดเพิ่ม BNB ลงในกระเป๋าเงินของคุณ",
    "Please note that you will require up to AMOUNT worth of CURRENCY to complete the transaction.": "โปรดแน่ใจว่าคุณมีอย่างน้อย {amount} ในสกุลเงิน {currency} เพื่อทำธุรกรรมให้เสร็จสิ้น",
    "Please note that AMOUNT worth of CURRENCY is required to complete the transaction.": "โปรดทราบว่าต้องใช้ {amount} มูลค่าของ {currency} ในการทำธุรกรรมให้เสร็จสมบูรณ์",
    "Please note that AMOUNT_CURRENCY is required for gas fee": "โปรดทราบว่าต้องใช้ {amount_currency} สำหรับค่าน้ำมัน",
    "Select a payment method": "โปรดเลือกวิธีการชำระเงิน",
    "Use your account's wallet balances to purchase the product.": "ใช้ยอดคงเหลือในกระเป๋าเงินในบัญชีของคุณเพื่อซื้อผลิตภัณฑ์",
    "Connect a WEB3 wallet like Metamask or Trustwallet and pay with {currency}. This requires a small amount of BNB ($0.30) to cover the transaction fees!": "เชื่อมต่อกระเป๋าเงิน WEB3 เช่น Metamask หรือ Trustwallet และชำระเงินด้วย {currency} ซึ่งต้องใช้ BNB จำนวนเล็กน้อย ($0.30) เพื่อให้เพียงพอกับค่าธรรมเนียมการทำธุรกรรม!",
    "Select one of the available blockchains and cryptocurrencies, and proceed with the payment via an external wallet application.": "เลือกหนึ่งใน blockchains และ cryptocurrencies จากนั้นดำเนินการชำระเงินผ่านแอปพลิเคชันกระเป๋าเงินภายนอก",
    "Internal wallet": "กระเป๋าเงินภายใน",
    "Web3 Wallet": "กระเป๋าเงิน Web3",
    "External wallet": "กระเป๋าเงินภายนอก",
    "Select Currency": "เลือกสกุลเงิน",
    "Select from the available balances": "เลือกจากยอดคงเหลือที่มีอยู่",
    "Purchase for myself": "ซื้อใช้เอง",
    "Select Blockchain": "เลือก Blockchain",
    "Select Cryptocurrency": "เลือก Cryptocurrency",
    "Please select another payment method": "กรุณาเลือกวิธีการชำระเงินแบบอื่น",
    "Connect my wallet": "เชื่อมต่อกระเป๋าเงินของฉัน",
    "Select a product owner": "เลือกเจ้าของผลิตภัณฑ์",
    "Please select who you are purchasing for:": "โปรดเลือกว่าคุณกำลังซื้อให้กับใคร:",
    "Product Owner Tooltip": "เคล็ดลับเครื่องมือสำหรับเจ้าของผลิตภัณฑ์",
    "Owner e-mail": "e-mail เจ้าของ",
    "Purchase Summary": "สรุปการซื้อ",
    "Network": "เครือข่าย",
    "Fees": "ค่าธรรมเนียม",
    "Rate": "อัตรา",
    "Send to wallet address": "ส่งไปยังที่อยู่กระเป๋าเงิน",
    "Or scan the code with your wallet app to fill in address automatically.": "หรือสแกนโค้ดด้วยแอพกระเป๋าเงินของคุณเพื่อกรอกที่อยู่โดยอัตโนมัติ",
    "Send only CURRENCY to this wallet address!": "ส่งเฉพาะ {currency} ไปยังที่อยู่กระเป๋าเงินนี้!",
    "Please send your crypto within 30 minutes to ensure a fixed exchange rate": "โปรดส่ง crypto ของคุณภายใน 30 นาทีเพื่อให้แน่ใจว่าคุณจะได้อัตราแลกเปลี่ยนเดิม",
    "I sent the crypto": "ฉันส่ง crypto แล้ว",
    "Insufficient funds": "เงินทุนไม่เพียงพอ",
    "Purchase Successful": "ซื้อสำเร็จ!",
    "The NFT balance was successfully updated and can be viewed in My NFTs tab.": "คุณสามารถตรวจสอบสถานะการทำธุรกรรมของคุณได้ใน \"My Orders\" - หากดำเนินการสำเร็จ ยอดคงเหลือ NFT ของคุณจะได้รับการอัปเดตในแท็บ \"My NFTs\"",
    "Available balance": "ยอดเงินคงเหลือที่ใช้ได้",
    "scanning_for_transaction_text": "คุณสามารถตรวจสอบสถานะการทำธุรกรรมของคุณได้ใน \"My Orders\" - หากดำเนินการสำเร็จ ยอดคงเหลือ NFT ของคุณจะได้รับการอัปเดตในแท็บ \"My NFTs\"",
    "This window will close automatically once we receive your crypto.": "หน้าต่างนี้จะปิดโดยอัตโนมัติเมื่อเราได้รับ crypto ของคุณแล้ว",
    "We did receive your crypto, but it was less than required. Please transfer the missing amount to complete your transaction.": "เราได้รับ crypto ของคุณแล้ว แต่น้อยกว่าจำนวนที่กำหนด กรุณาโอนจำนวนที่ขาดหายไปเพื่อทำธุรกรรมให้เสร็จสมบูรณ์",
    "Please do not attempt to do another transaction. Send your Payment UID to our support.": "ธุรกรรมหมดอายุ: โปรดอย่าพยายามทำธุรกรรมอีก โปรดส่งรหัสธุรกรรมของคุณไปยังฝ่ายสนับสนุนของเรา",
    "Scanning for transaction": "กำลังสแกนเพื่อทำธุรกรรม",
    "Go to My Orders": "ไปที่ My Orders",
    "remaining": "เหลืออยู่",
    "Please send your crypto within 3 hours to ensure a fixed exchange rate": "กรุณาส่ง crypto ของคุณภายใน 3 ชั่วโมงเพื่อให้แน่ใจว่าอัตราแลกเปลี่ยนคงที่",
    "Please send your crypto within n minutes to ensure a fixed exchange rate": "โปรดส่ง crypto ของคุณภายใน {N} นาทีเพื่อให้แน่ใจว่าอัตราแลกเปลี่ยนคงที่",
    "Missing crypto amount": "ไม่มีจำนวน crypto",
    "Missing amount": "จำนวนที่ขาดหายไป",
    "Received amount": "จำนวนที่ได้รับ",
    "Required amount": "จำนวนที่จำเป็น",
    "Continue with payment": "ดำเนินการชำระเงินต่อไป",
    "No enough CURRENCY to cover gas fee.": "ไม่มีเงิน {currency} เพียงพอที่จะครอบคลุมค่าน้ำมัน",
    "Please top up your wallet with AMOUNT_CURRENCY to cover gas fee.": "โปรดเติมเงินในกระเป๋าเงินของคุณด้วย {amount_currency} เพื่อให้ครอบคลุมค่าน้ำมัน",
    "Scanning": "กำลังสแกน",
    "To continue, please enable payment.": "หากต้องการดำเนินการต่อ โปรดเปิดใช้งานการชำระเงิน",
    "Kindly be aware that AMOUNT_CURRENCY may be necessary to cover the gas fee.": "โปรดทราบว่าอาจต้องใช้ {amount_currency} ในการชำระค่าน้ำมัน",
    "Insufficient funds in CURRENCY to cover the gas fee.": "เงินใน {currency} ไม่เพียงพอสำหรับค่าน้ำมัน",
    "Please top up your wallet with AMOUNT_CURRENCY to cover the gas fee.": "โปรดเติมเงินในกระเป๋าเงินของคุณด้วย {amount_currency} เพื่อให้ครอบคลุมค่าน้ำมัน",
    "We will cover the gas fee for this transaction on your behalf.": "เราจะครอบคลุมค่าธรรมเนียมก๊าซสำหรับการทำธุรกรรมนี้ในนามของคุณ",
    "Please choose your preferred payment method to finalize your purchase.": "โปรดเลือกวิธีการชำระเงินที่คุณต้องการเพื่อดำเนินการซื้อให้เสร็จสิ้น",
    "Your payment has expired. To resolve this issue, please contact customer support.": "การชำระเงินของคุณหมดอายุแล้ว หากต้องการแก้ไขปัญหานี้ โปรดติดต่อฝ่ายสนับสนุนลูกค้า",
    "Your payment has been received and is currently being processed. We will notify you once the process is complete.": "ได้รับการชำระเงินของคุณแล้วและกำลังดำเนินการอยู่ เราจะแจ้งให้คุณทราบเมื่อดำเนินการเสร็จสิ้น",
    "Please indicate the intended recipient of the product. You have the option to purchase it for yourself or choose 'Another user' as the recipient.": "โปรดระบุผู้รับสินค้าที่ต้องการ คุณมีตัวเลือกที่จะซื้อให้ ตัวเอง หรือเลือก \"ผู้ใช้อื่น\" ให้เป็นผู้รับ",
    "Your transaction has been declined. Please attempt the transaction again.": "ธุรกรรมของคุณถูกปฏิเสธ โปรดลองทำธุรกรรมอีกครั้ง",
    "Monitor the status of your transaction by visiting the provided link:": "สามารถตรวจสอบสถานการณ์ทำธุรกรรมของคุณได้ โดยไปที่ลิงค์ที่นี้:",
    "Apologies, an error occurred on the blockchain side. Please try again.": "ขออภัย มีข้อผิดพลาดเกิดขึ้นที่บล็อกเชน กรุณาลองอีกครั้ง",
    "Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.": "โปรดรอให้ธุรกรรมที่กำลังดำเนินการอยู่บนบล็อกเชนเสร็จสิ้นก่อนที่จะเริ่มธุรกรรมใหม่",
    "Your payment has expired. To resolve this issue, please contact customer support": "การชำระเงินของคุณหมดอายุแล้ว หากต้องการแก้ไขปัญหานี้ โปรดติดต่อฝ่ายสนับสนุนลูกค้า",
    "Your payment was refunded. AMOUNT CURRENCY was returned to your wallet.": "การชำระเงินของคุณได้รับเงินคืนแล้ว {amount} {currency} ถูกส่งคืนไปยังกระเป๋าเงินของคุณ",
    "You can monitor the transaction status in the Order History tab. If it is successful, the NFT balance will be updated in the My NFTs tab.": "คุณสามารถตรวจสอบสถานะการทำธุรกรรมได้ในแท็บ \"ประวัติการสั่งซื้อ\" หากสำเร็จ ยอดคงเหลือ NFT จะได้รับการอัปเดตในแท็บ \"My NFTs\"",
    "OFF": "ปิด",
    "Received Amount.": "จำนวนเงินที่ได้รับ",
    "Processing Fee": "ค่าดำเนินการ",
    "Estimated gas fee": "ค่าน้ำมันโดยประมาณ",
    "Transaction Expired": "ธุรกรรมหมดอายุ",
    "Enable Payment": "เปิดใช้งานการชำระเงิน",
    "Email does not exist": "ไม่มีอีเมล",
    "Request another quote": "ขอใบเสนอราคาอื่น",
    "Purchase Success!": "ซื้อสำเร็จ!",
    "Purchase for another user": "ซื้อสำหรับผู้ใช้รายอื่น",
    "Pay": "จ่าย",
    "You purchased GIFT for RECEIVER!": "คุณซื้อ {gift} ในราคา {receiver}!",
    "You've purchased GIFT for RECEIVER!": "คุณซื้อ {gift} ในราคา \"{receiver}\"!",
    "You've purchased a GIFT for RECEIVER!": "คุณซื้อ {gift} ให้กับ \"{receiver}\"!",
    "Rewards claimed successfully": "รับรางวัลสำเร็จ",
    "Commissions claimed successfully.": "อ้างสิทธิ์ค่าคอมมิชชันสำเร็จแล้ว",
    "You received GIFT from SENDER!": "คุณได้รับ {gift} จาก {sender}!",
    "You've received GIFT from SENDER!": "คุณได้รับ {gift} จาก \"{sender}\"!",
    "You've received a GIFT from SENDER!": "คุณได้รับ {gift} จาก \"{sender}\"!",
    "Transaction Failed": "การทำธุรกรรมล้มเหลว",
    "The transaction has failed. Please try again.": "การทำธุรกรรมล้มเหลว กรุณาลองอีกครั้ง.",
    "Your transaction has been declined. Please add BNB to your wallet and attempt the transaction again.": "ธุรกรรมของคุณถูกปฏิเสธ โปรดเพิ่ม BNB ลงในกระเป๋าเงินของคุณและพยายามทำธุรกรรมอีกครั้ง",
    "Blockchain Notification: Apologies, an error occurred on the blockchain side. Please try again.": "การแจ้งเตือนบล็อคเชน: ขออภัย เกิดข้อผิดพลาดที่ฝั่งบล็อคเชน กรุณาลองอีกครั้ง.",
    "Apologies, an unexpected error occurred. Please retry your request. If the issue persists, please contact support.": "ขออภัย เกิดข้อผิดพลาดที่ไม่คาดคิด โปรดลองคำขอของคุณอีกครั้ง หากปัญหายังคงอยู่ โปรดติดต่อฝ่ายสนับสนุน",
    "Received Amount": "จำนวนเงินที่ได้รับ",
    "Dual Miner": "คนขุดแร่คู่",
    "Bitopex": "Bitopex",
    "Gymnet": "Gymnet",
    "Value": "ค่า",
    "Reach Rank": "เข้าถึงอันดับ <b>{n}</b>",
    "Rewards in Gymnet": "รางวัลใน Gymnet",
    "Rewards in Bitcoin": "รางวัลใน Bitcoin",
    "For Limited Time Only": "สำหรับ <strong>เวลา จำกัด</strong> เท่านั้น",
    "Seconds": "วินาที",
    "Minutes": "นาที",
    "Days": "วัน",
    "Hours": "ชั่วโมง",
    "Unclaimed parcels": "พัสดุที่ไม่มีการอ้างสิทธิ์",
    "Upgraded parcels": "อัพเกรดพัสดุ",
    "Inactive miners": "นักขุดที่ไม่ได้ใช้งาน",
    "Active miners": "นักขุดที่ใช้งานอยู่",
    "Available Balance": "ยอดเงินคงเหลือ",
    "Utility Balance": "ยอดคงเหลือ Utility",
    "Bitopex Balance": "ยอดคงเหลือ Bitopex",
    "Moonberg Balance": "ยอดคงเหลือ Moonberg",
    "Balance History": "ประวัติยอดคงเหลือ",
    "Miner Hashpower": "ขุดแร่ Hashpower",
    "Sec": "วินาที",
    "Dual Miners": "ขุดแร่คู่",
    "Dual miners info": "จำนวนนักขุดคู่ที่ใช้งานอยู่และทั้งหมด",
    "Dual Miner Hashpower": "ขุดแร่คู่ Hashpower",
    "Dual miners hashpower info": "hashpower ที่คุณมีอยู่นั้นกำหนดโดยการคูณจำนวนตัวขุดคู่ที่ใช้งานอยู่ของคุณด้วยตัวขุดหน่วย hashpower ซึ่งตั้งไว้ที่ 1000Mh/s ต่อหน่วย",
    "Unlock the full potential of your Bitopex rewards as they come to life following your purchase of any of our special bundles. Once your Bitopex Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Uปลดล็อกศักยภาพของรางวัล Bitopex ของคุณอย่างเต็มที่เมื่อรางวัลเหล่านั้นกลับมามีชีวิตอีกครั้งหลังจากที่คุณซื้อชุดรวมพิเศษใดๆ ของเรา เมื่อยอดคงเหลือ Bitopex ของคุณพร้อมใช้งาน คุณจะสามารถเข้าถึงภาพรวมของรางวัลทั้งหมด รางวัลที่ได้รับ และรางวัลที่คาดว่าจะได้รับ นอกจากนี้ ตัวจับเวลานับถอยหลังจะแสดงการแจกรางวัลครั้งต่อไป ทำให้คุณมั่นใจได้ว่าจะไม่พลาดรางวัลอย่างแน่นอน",
    "Min": "นาที",
    "Total Rewards Expected": "รางวัลทั้งหมดที่คาดหวัง",
    "Monthly Distribution": "การกระจายรายเดือน",
    "Price Projection": "ราคาที่คาดไว้",
    "Server error": "เซิร์ฟเวอร์ผิดพลาด",
    "Average Gymnet ROI for the last 9 months": "ROI ของ Gymnet เฉลี่ยในช่วง 9 เดือนที่ผ่านมา",
    "Dual Miners will generate rewards in Bitopex tokens, which will be distributed every Friday.":"Dual Miners จะสร้างรางวัลเป็นโทเค็น Bitopex ซึ่งจะแจกจ่ายทุกวันศุกร์",
    "Introducing the Utility Balance feature, a new and convenient payment method within our payment ecosystem. With this feature, you'll enjoy a separate balance that can be used alongside other payment methods. It provides you with the flexibility to partially utilize your utility balance, up to 25% of the total when purchasing single Land NFTs or making payments for Land NFT bundles.":"ขอแนะนำฟีเจอร์ Utility Balance ซึ่งเป็นวิธีการชำระเงินแบบใหม่ที่สะดวกภายในระบบนิเวศการชำระเงินของเรา ด้วยคุณสมบัตินี้ คุณจะได้รับยอดคงเหลือแยกต่างหากที่สามารถใช้ร่วมกับวิธีการชำระเงินอื่นๆ ได้ ให้ความยืดหยุ่นแก่คุณในการใช้ยอดเงินคงเหลือบางส่วนของคุณ สูงสุด 25% ของยอดทั้งหมดเมื่อซื้อ Land NFT เดี่ยวหรือชำระเงินสำหรับชุด Land NFT",
    "Balance before": "ยอดคงเหลือก่อนหน้า",
    "Balance after": "ยอดคงเหลือภายหลัง",
    "TXID": "TXID",
    "The number of active and total miners you have in Gymstreet and Kabutochō, respectively.": "จำนวนนักขุดที่ใช้งานและทั้งหมดที่คุณมีใน Gymstreet และ Kabutochō ตามลำดับ",
    "The hashpower you possess is determined by multiplying the number of your active miners with the unit miner hashpower, which is set at 1000Mh/s per unit.": "hashpower ที่คุณมีอยู่นั้นกำหนดโดยการคูณจำนวนนักขุดที่ใช้งานอยู่ของคุณกับหน่วยขุด hashpower ซึ่งตั้งไว้ที่ 1,000Mh/s ต่อหน่วย",
    "Token": "โทเค็น",
    "Buy Bundles": "ซื้อบันเดิล",
    "Total Received": "รวมที่ได้รับทั้งหมด",
    "Next Rewards Distribution In:": "การออกรางวัลครั้งต่อไปใน:",
    "Total Rewards": "รางวัลรวม",
    "Payout": "การจ่ายเงิน",
    "Weekly": "รายสัปดาห์",
    "Next Distribution Amount": "จำนวนการแจกจ่ายถัดไป",
    "Internal transaction": "การทำธุรกรรมภายใน",
    "Minimum claimable amount is (AMOUNT)": "จำนวนเงินขั้นต่ำที่สามารถเรียกร้องได้คือ {amount}",
    "User Profile": "โปรไฟล์ผู้ใช้",
    "Name": "ชื่อ",
    "Nationality": "สัญชาติ",
    "Contact Information": "ข้อมูลติดต่อ",
    "Country": "ประเทศ",
    "City": "เมือง",
    "Street": "ถนน",
    "Zip": "รหัสไปรษณีย์",
    "E-Mail": "อีเมล",
    "Wallet address": "ที่อยู่กระเป๋าเงิน",
    "Name is required": "ต้องระบุชื่อ",
    "Nationality is required": "ต้องระบุสัญชาติ",
    "Country is required": "ต้องระบุประเทศ",
    "City is required": "ต้องระบุเมือง",
    "Street is required": "ต้องระบุถนน",
    "Zip is required": "ต้องระบุรหัสไปรษณีย์",
    "Successfully updated": "อัปเดทเสร็จสิ้น",
    "Zip contain only numbers and min length is 3": "รหัสไปรษณีย์ต้องมีเฉพาะตัวเลขและความยาวขั้นต่ำคือ 3 ตัวเลข",
    "Please enter a valid email address": "โปรดใส่ที่อยู่อีเมลที่ถูกต้อง",
    "Personal information": "ข้อมูลส่วนตัว",
    "T&C": "ข้อกำหนดและเงื่อนไข",
    "Field is required": "ต้องระบุฟิลด์นี้",
    "Password successfully changed": "รหัสผ่านถูกเปลี่ยนเสร็จสิ้น",
    "Old password is incorrect": "รหัสผ่านเดิมไม่ถูกต้อง",
    "Oops...Something went wrong. Please try again": "อุ้บส์...บางอย่างผิดพลาด โปรดลองอีกครั้ง",
    "Name must be at least 3 characters": "ชื่อต้องมีอย่างน้อย 3 ตัวอักษร",
    "City must be at least 3 characters": "เมืองต้องมีอย่างน้อย 3 ตัวอักษร",
    "Street must be at least 3 characters": "ถนนต้องมีอย่างน้อย 3 ตัวอักษร",
    "Affiliate Terms and conditions": "ข้อกำหนดและเงื่อนไขของพันธมิตร",
    "Affiliate links": "ลิงค์พันธมิตร",
    "Affiliate link successfully changed": "เปลี่ยนลิงค์พันธมิตรเสร็จเรียบร้อยแล้ว",
    "Please enter the Bitopex affiliate link here. Kindly note that you can only save the link once.": "โปรดป้อนลิงค์พันธมิตร Bitopex ที่นี่ โปรดทราบว่าคุณสามารถบันทึกลิงก์ได้เพียงครั้งเดียวเท่านั้น",
    "Input cannot be only spaces": "อินพุตต้องไม่เว้นวรรคเท่านั้น",
    "To proceed with the registration, kindly accept the above 2 conditions by checking the corresponding boxes.": "หากต้องการดำเนินการลงทะเบียน โปรดยอมรับเงื่อนไข 2 ข้อข้างต้นโดยทำเครื่องหมายในช่องที่เกี่ยวข้อง",
    "I hereby confirm that I am not a US citizen": "ฉันขอยืนยันว่าฉันไม่ใช่พลเมืองของสหรัฐอเมริกา",
    "I hereby confirm that I am not a UAE citizen": "ฉันขอยืนยันว่าฉันไม่ใช่พลเมืองสหรัฐอาหรับเอมิเรตส์",
    "Enter Old Password": "ป้อนรหัสผ่านเก่า",
    "Change username & password": "เปลี่ยนชื่อผู้ใช้และรหัสผ่าน",
    "My wallet": "กระเป๋าเงินของฉัน",
    "Purchase": "ซื้อ",
    "Username required to proceed": "ต้องระบุชื่อผู้ใช้เพื่อดำเนินการต่อ",
    "Enter preferred username here": "ป้อนชื่อผู้ใช้ที่ต้องการที่นี่",
    "Username must be at least 8 characters, starting with a letter. Use only lowercase letters, numbers, hyphens, dots, and underscores.": "ชื่อผู้ใช้ต้องมีอักขระอย่างน้อย 8 ตัว โดยขึ้นต้นด้วยตัวอักษร ใช้เฉพาะตัวอักษรพิมพ์เล็ก ตัวเลข ยัติภังค์ จุด และขีดล่างเท่านั้น",
    "Username is already taken. Please choose a different username.": "ชื่อผู้ใช้ถูกใช้ไปแล้ว โปรดเลือกชื่อผู้ใช้อื่น",
    "Email or username": "อีเมล์หรือชื่อผู้ใช้",
    "Username is available": "ชื่อผู้ใช้สามารถใช้ได้",
    "Provided username is already taken.": "ชื่อผู้ใช้ที่ระบุถูกใช้ไปแล้ว",
    "Invalid credentials. Please try again.": "ข้อมูลประจำตัวที่ไม่ถูกต้อง.\n กรุณาลองอีกครั้ง.",
    "Username successfully set": "ตั้งชื่อผู้ใช้สำเร็จแล้ว",
    "Dear User, due to ongoing updates to our platform, you will need to enter a username to continue. The username needs to be unique and cannot be changed": "เรียนผู้ใช้ เนื่องจากมีการอัปเดตแพลตฟอร์มของเราอย่างต่อเนื่อง คุณจะต้องป้อนชื่อผู้ใช้เพื่อดำเนินการต่อ ชื่อผู้ใช้ต้องไม่ซ้ำกันและไม่สามารถเปลี่ยนแปลงได้",
    "You have successfully set your username": "คุณตั้งชื่อผู้ใช้ของคุณสำเร็จแล้ว",
    "Continue to dashboard": "ไปที่แดชบอร์ด",
    "Username is required": "ต้องระบุชื่อผู้ใช้",
    "Rank Transfer": "การโอนอันดับ",
    "Review": "ทบทวน",
    "Top Up": "เติม",
    "The claim button is currently disabled for maintenance. We apologize for the inconvenience. Please come back shortly.": "ขณะนี้ปุ่มรับสิทธิ์ถูกปิดใช้งานเพื่อการบำรุงรักษา เราต้องขออภัยในความไม่สะดวก. โปรดกลับมาเร็วๆ นี้",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / BUSD": "เมื่อมีการดำเนินการสร้างอาคารธุรกิจใหม่ คุณจะมี \n ตัวเลือกในการซื้อที่ดิน โดยการชำระเงินด้วย GYMNET / BUSD",
    "Parcel NFT": "พัสดุ NFT",
    "Miner NFT": "นักขุด NFT",
    "BUSD Balance": "ยอดคงเหลือ BUSD",
    "Please log in to your account.": "กรุณาเข้าสู่ระบบบัญชีของคุณ",
    "Your payment has been received. It is currently being processed. You will receive a notification when done.": "ได้รับการชำระเงินของคุณแล้ว ซึ่งขณะนี้อยู่ระหว่างการดำเนินการ คุณจะได้รับการแจ้งเตือนเมื่อการชำระเงินเสร็จสิ้น",
    "Error": "ผิดพลาด",
    "Please enter valid email": "โปรดระบุอีเมลที่ถูกต้อง",
    "Your sponsor is": "ผู้สนับสนุนของคุณคือ",
    "Credentials": "ข้อมูลรับรอง",
    "Enter Wallet Address (i.e. 0xb794f...79268)": "ป้อนที่อยู่กระเป๋าสตางค์ (เช่น 0xb794f...79268)",
    "Contact Customer Support": "ติดต่อฝ่ายสนับสนุน",
    "Change Wallet": "เปลี่ยนแปลงกระเป๋าเงิน",
    "Account": "บัญชี",
    "Please leave an address here, in which we can also mint your NFTs afterwards.": "โปรดทิ้งที่อยู่ไว้ที่นี่ เพื่อที่เราจะได้สร้าง NFT ของคุณในภายหลังได้",
    "Important!": "สำคัญอย่างยิ่ง!",
    "It should be a BEP-20 address - i.e. a BNB Smart Chain wallet address. We recommend the wallet Metamask or Trustwallet.": "ควรเป็นที่อยู่ BEP-20 - เช่น ที่อยู่กระเป๋าสตางค์ BNB Smart Chain ซึ่งทางเราขอแนะนำให้เป็นกระเป๋าเงิน Metamask หรือ Trustwallet",
    "NEVER USE AN EXCHANGE Wallet ADRESS !!!": "อย่าใช้ที่อยู่กระเป๋าสตางค์แบบแลกเปลี่ยนเด็ดขาด !!!",
    "Failed": "ล้มเหลว",
    "Account changed": "บัญชีได้รับการเปลี่ยนแปลง",
    "Your Profile has been updated": "โปรไฟล์ของคุณได้มีการอัปเดต",
    "You already have an attached wallet address to this account.": "คุณมีที่อยู่กระเป๋าเงินที่ผูกมากับบัญชีนี้แล้ว",
    "Login": "เข้าสู่ระบบ",
    "Existing wallet": "กระเป๋าเงินที่มีอยู่",
    "Referral ID is required": "ต้องมีรหัสผู้อ้างอิง",
    "Pending Transaction": "การทำธุรกรรมที่รอดำเนินการ",
    "Processing": "กำลังดำเนินการ",
    "Send this payment ID to a customer support when you are requested to do so.": "ส่งรหัสการชำระเงินนี้ไปยังฝ่ายสนับสนุนลูกค้าเมื่อคุณได้รับการร้องขอให้มีการดำเนินการดังกล่าว",
    "To be able to claim your NFTs you need to connect the same wallet address attached to your account": "คุณจะต้องเชื่อมต่อที่อยู่กระเป๋าเงินเดียวกันกับบัญชีของคุณเพื่อให้สามารถอ้างสิทธิ์ NFT ของคุณได้",
    "Please confirm your email to continue.": "กรุณายืนยันอีเมลของคุณเพื่อดำเนินการต่อ",
    "Valid address": "ที่อยู่ที่ถูกต้อง",
    "This social account is used by another user. Verify your email first or contact Customer Support": "บัญชีโซเชียลนี้ถูกใช้โดยผู้ใช้รายอื่นแล้ว โปรดยืนยันอีเมลของคุณก่อน หรือติดต่อฝ่ายสนับสนุนลูกค้า",
    "Invalid wallet connection": "การเชื่อมต่อกระเป๋าเงินไม่ถูกต้อง",
    "Registration completed successfully": "การลงทะเบียนเสร็จสมบูรณ์แล้ว",
    "Failed to complete registration of the user": "การลงทะเบียนผู้ใช้ไม่สำเร็จ",
    "Failed to register user": "ลงทะเบียนผู้ใช้ไม่สำเร็จ",
    "Signature grant failed": "การใส่ลายเซ็นล้มเหลว",
    "Unsupported chain": "ไม่รองรับ chain นี้",
    "This wallet address is already associated with another user account. Please use another one!": "ที่อยู่กระเป๋าเงินนี้ได้ผูกกับบัญชีผู้ใช้อื่นแล้ว กรุณาใช้กระเป่าเงินอื่น!",
    "Failed to add address to your wallet": "ไม่สามารถเพิ่มที่อยู่กระเป๋าเงินของคุณได้",
    "Address has been successfully added to your wallet": "เพิ่มที่อยู่กระเป๋าเงินของคุณเรียบร้อยแล้ว",
    "Failed to accept Affiliate TMS": "ไม่สามารถยอมรับ Affiliate TMS ได้",
    "Wallet address has been successfully applied": "ใช้ที่อยู่กระเป๋าเงินเรียบร้อยแล้ว",
    "Your email is verified.": "อีเมลของคุณได้รับการยืนยันแล้ว",
    "Failed to add Token": "ไม่สามารถเพิ่มโทเค็นได้",
    "Failed to upload image": "ไม่สามารถอัปโหลดภาพได้",
    "Wrong file size. Please upload image files with size less than 10MB": "ขนาดไฟล์ไม่ถูกต้อง กรุณาอัพโหลดไฟล์ภาพที่มีขนาดไม่เกิน 10MB",
    "We've verified your email address. You can now continue using the site.": "เราได้ยืนยันที่อยู่อีเมลของคุณแล้ว ตอนนี้คุณสามารถใช้เว็บไซต์ต่อไปได้",
    "You need to switch to Binance Smart Chain": "คุณต้องเปลี่ยนไปใช้ Binance Smart Chain",
    "Switch to Binance Smart Chain to continue using GymStreet": "เปลี่ยนไปใช้ Binance Smart Chain เพื่อใช้ GymStreet ต่อไป",
    "To CashFT": "สู่ CashFT",
    "Buy a Super Bundle in the next": "ซื้อ Super Bundle ในครั้งต่อไป",
    "and get an EXTRA discount!": "เพื่อรับส่วนลดพิเศษ!",
    "See All Offers": "ดูข้อเสนอทั้งหมด",
    "I accept the affiliate terms & conditions": "ฉันยอมรับข้อกำหนดและเงื่อนไขของพันธมิตร",
    "Special offer!": "ข้อเสนอพิเศษ!",
    "Just one": "แค่หนึ่งเท่านั้น",
    "1000 Mh/s": "1,000 เมกะเฮิร์ตซ์/วินาที",
    "View Claimed Coordinates": "ดูพิกัดที่อ้างสิทธิ์",
    "Your Parcel Coordinates": "พิกัดพัสดุของคุณ",
    "Number of miners you want to attach": "จำนวนนักขุดที่คุณต้องการแนบ",
    "Referral Link": "ลิงค์ผู้อ้างอิง",
    "Affiliate Link": "ลิงค์พันธมิตร",
    "Invalid Wallet": "กระเป๋าเงินไม่ถูกต้อง",
    "Back to Log In": "กลับไปที่เข้าสู่ระบบ",
    "You need a valid referral ID to finalize your registration": "คุณต้องมีรหัสผู้อ้างอิงที่ถูกต้องเพื่อดำเนินการลงทะเบียนให้เสร็จสิ้น",
    "I have read and accept the": "ฉันได้อ่านและยอมรับ",
    "Your Sponsor": "ผู้สนับสนุนของคุณ",
    "Forgot Your Password": "ลืมรหัสผ่านหรือไม่?",
    "Enter your email address and we will send you instructions to reset your password.": "โปรดป้อนที่อยู่อีเมลของคุณแล้วเราจะส่งคำแนะนำในการรีเซ็ตรหัสผ่านไปให้คุณ",
    "We just sent you an email to confirm your e-mail address. Please click on the link in the e-mail to reset your password.": "เราเพิ่งส่งอีเมลไปให้คุณเพื่อยืนยันที่อยู่อีเมลของคุณ โปรดคลิกที่ลิงก์ในอีเมลที่เราส่งไปให้เพื่อรีเซ็ตรหัสผ่านของคุณ",
    "Welcome To Metaverse": "ยินดีต้อนรับสู่ {n} Metaverse",
    "Commissions": "ค่าคอมมิชชั่น",
    "Refund of electricity vouchers": "การขอคืนบัตรกำนัลการไฟฟ้า",
    "Import Custom Token": "นำเข้าโทเค็นที่กำหนดเอง",
    "Add Token To Wallet": "เพิ่มโทเค็นในกระเป๋าเงิน",
    "Token contract address": "ที่อยู่สัญญาโทเค็น",
    "Token symbol": "สัญลักษณ์โทเค็น",
    "Provided address is not a supported token address": "ที่อยู่ที่ระบุไม่ใช่ที่อยู่ของโทเค็นที่รองรับ",
    "Are you sure you want to claim your rewards": "คุณแน่ใจหรือไม่ว่าต้องการรับรางวัลของคุณ",
    "OTP Code": "รหัส OTP",
    "OTP Code is required": "ต้องใช้รหัส OTP",
    "Enter OTP code you have received at email": "ป้อนรหัส OTP ที่คุณได้รับที่ {email}",
    "Asset": "สินทรัพย์",
    "Gas": "ก๊าซ",
    "Send crypto": "ส่ง crypto",
    "Enter wallet address": "ป้อนที่อยู่กระเป๋าเงิน",
    "NFTs": "NFTs",
    "Activity": "กิจกรรม",
    "Assets": "สินทรัพย์",
    "Import token": "นำเข้าโทเค็น",
    "Don't see your token?": "ไม่เห็นโทเค็นของคุณใช่ไหม?",
    "more": "เพิ่มเติม",
    "Send": "ส่ง",
    "Sending success": "การส่งสำเร็จ",
    "View account in explorer": "ดูบัญชีใน explorer",
    "estimated": "โดยประมาณ",
    "Copy your address": "คัดลอกที่อยู่ของคุณ",
    "Export private key": "ส่งออกคีย์ส่วนตัว",
    "Receive": "รับ",
    "Account Details": "รายละเอียดบัญชี",
    "View on blockchain": "ดูบนบล็อกเชน",
    "GymStreet Parcels": "พัสดุ GymStreet",
    "GymStreet Miners": "นักขุด GymStreet",
    "Kabutocho Parcels": "พัสดุ Kabutocho",
    "Kabutocho Miners": "นักขุด Kabutocho",
    "Invalid wallet address": "ที่อยู่กระเป๋าเงินไม่ถูกต้อง",
    "Calculate": "คำนวณ",
    "Sending": "กำลังส่ง",
    "Reject": "ปฏิเสธ",
    "Confirmation": "การยืนยัน",
    "Gas fees are paid to crypto miners who process transactions on the network. Gas fees are set by the network and fluctate based on network traffic and transaction complexity.": "ค่าธรรมเนียมก๊าซจะจ่ายให้กับนักขุด crypto ที่ประมวลผลธุรกรรมบนเครือข่าย ค่าธรรมเนียมก๊าซจะกำหนดโดยเครือข่ายและผันผวนตามการรับส่งข้อมูลเครือข่ายและความซับซ้อนของธุรกรรม",
    "Insufficient TOKEN balance": "ยอดคงเหลือ {token} ไม่เพียงพอ",
    "Confirm Swap": "ยืนยันการแลกเปลี่ยน",
    "Approve Allowance": "อนุมัติเบี้ยเลี้ยง",
    "Help Center": "ศูนย์ช่วยเหลือ",
    "Confirm the Allowance Transaction in order to be able to use Token through our services. Please note that this is a one-time action.": "ยืนยันธุรกรรมค่าเบี้ยเลี้ยงเพื่อให้สามารถใช้โทเค็น <strong>{n}</strong> ผ่านบริการของเราได้ ทั้งนี้โปรดทราบว่านี่เป็นการดำเนินการเพียงครั้งเดียวเท่านั้น",
    "No Activities yet": "ยังไม่มีกิจกรรมใดๆ",
    "To": "ถึง",
    "Contract interaction": "การโต้ตอบตามสัญญา",
    "Are you sure you want to delete this token": "คุณแน่ใจหรือไม่ว่าต้องการลบโทเค็นนี้",
    "Send only using  {CHAIN}  network to receive funds on this deposit address.": "ส่งโดยใช้เครือข่าย {CHAIN} เท่านั้นเพื่อรับเงินในที่อยู่การฝากเงินนี้",
    "Leadership Incentives": "แรงจูงใจในการเป็นผู้นำ",
    "Pool statistics": "สถิติพูล",
    "Hourly": "รายชั่วโมง",
    "Turnover rule": "กฎการหมุนเวียน",
    "Regular Bundles": "การรวมกลุ่มปกติ",
    "Turnover rule percentage": "กฎการหมุนเวียน {n}%",
    "Miner sales contribution": "ผลงานการขายของนักขุด",
    "Pool": "พูล",
    "percent of NFT sales": "{n}% ของยอดขาย NFT",
    "partner": "พันธมิตร",
    "Main area": "พื้นที่หลัก",
    "Turnover percentage rule": "กฎการหมุนเวียน {n}%",
    "With dollar each": "ด้วยแต่ละเงินดอลลาร์",
    "Direct partners": "พันธมิตรโดยตรง",
    "Your next bonus will appear here": "โบนัสถัดไปของคุณจะปรากฏที่นี่",
    "Your incentive": "แรงจูงใจของคุณ",
    "Your cash bonus": "โบนัสเงินสดของคุณ",
    "Your bonus history": "ประวัติโบนัสของคุณ",
    "At the current moment you don't have any rewards": "ในขณะนี้คุณยังไม่มีรางวัลใดๆ",
    "Partners with pin qualification": "พันธมิตรที่มีคุณสมบัติ pin {n}",
    "Total generated turnover": "มูลค่าการซื้อขายที่เกิดขึ้นทั้งหมด",
    "Active Miners": "นักขุดที่ใช้งานอยู่",
    "Total pool rewards": "รางวัลพูลทั้งหมด",
    "Your pool share": "ส่วนแบ่งพูลของคุณ",
    "Total gGymnet": "gGymnet ทั้งหมด",
    "GYMNET Commissions Total": "ค่าคอมมิชชั่น GYMNET ทั้งหมด",
    "Your attached wallet is {walletAddress}. You have tried to make request with wallet {currentWallet}. Please change connected wallet to {walletAddress}": "กระเป๋าเงินที่ผูกไว้ของคุณคือ <b>{walletAddress}</b> คุณได้พยายามส่งคำขอด้วย กระเป๋าเงิน <b>{currentWallet}</b> กรุณาเปลี่ยนกระเป๋าเงินที่ผูกไว้เป็น <b>{walletAddress}</b>",
    "Available for limited time": "มีเฉพาะในช่วงเวลาจำกัดเท่านั้น",
    "Bonus": "โบนัส",
    "Utility": "คุณประโยชน์",
    "The URL must begin with 'https://bitopex.io/home/' and include your referral ID": "URL ต้องขึ้นต้นด้วย 'https://bitopex.io/home/' และรวมรหัสผู้อ้างอิงของคุณไว้ด้วย",
    "Insufficient funds: to continue with payment use utility balance": "เงินไม่เพียงพอ: เพื่อดำเนินการชำระเงินต่อ ต้องใช้ยอดคงเหลือจากค่าสาธารณูปโภค",
    "My Team": "ทีมของฉัน",
    "Team Statistics": "สถิติทีม",
    "All partners": "พันธมิตรทังหมด",
    "Active partners": "พันธมิตรที่ใช้งานอยู่",
    "Inactive partners": "พันธมิตรที่ไม่ได้ใช้งาน",
    "Binary Points": "คะแนนไบนารี",
    "Points in left leg": "แต้มขาซ้าย",
    "Points in right leg": "แต้มขาขวา",
    "Left leg": "ขาซ้าย",
    "Right leg": "ขาขวา",
    "Holding Tank": "Tank ที่ถือไว้",
    "Enrollment Tree": "Tree ของการลงทะเบียน",
    "Binary Tree": "Tree ไบนารี",
    "CV Points History": "ประวัติคะแนน CV",
    "Time Left To Activation": "เวลาที่เหลือ <b>ในการเปิดใช้งาน</b>",
    "Search by email or wallet": "ค้นหาด้วยอีเมลหรือกระเป๋าเงิน",
    "Refresh Data": "รีเฟรชข้อมูล",
    "Joined at": "เข้าร่วมที่",
    "Placement": "ตำแหน่ง",
    "Select": "เลือก",
    "Own": "ตนเอง",
    "Team": "ทีม",
    "Position": "ตำแหน่ง",
    "CV Points": "คะแนน CV",
    "CV = commission volume": "CV = ปริมาณคอมมิชชั่น",
    "Genealogy": "ลำดับวงศ์ตระกูล",
    "Unilevel": "ยูนิเลเวล",
    "Select view": "เลือกมุมมอง",
    "Team size": "ขนาดทีม",
    "Rank": "ระดับ",
    "Own purchases": "การซื้อของตนเอง",
    "Navigation": "การนำทาง",
    "Search by wallet": "ค้นหาด้วยกระเป๋าเงิน",
    "no user": "ไม่มีผู้ใช้",
    "Wallet address is not valid": "ที่อยู่กระเป๋าเงินไม่ถูกต้อง",
    "Free Spot": "จุดที่ว่างอยู่",
    "Search by wallet or username": "ค้นหาด้วยกระเป๋าเงินหรือชื่อผู้ใช้",
    "Info": "ข้อมูล",
    "Benefits": "สิทธิประโยชน์",
    "Objectives Completed": "วัตถุประสงค์เสร็จสมบูรณ์",
    "TO DO`s": "สิ่งที่ต้องทำ",
    "Purchase NFTs In Total Value Of 100 USDT": "ซื้อ NFT มูลค่ารวม 100 USDT",
    "Purchase Binary Pass - 19 USDT": "ซื้อ Binary Pass - 19 USDT",
    "Team Turnover": "การหมุนเวียนของทีม",
    "BASED ON TEAM": "{n}% + ขึ้นอยู่กับทีม",
    "Qualification Turnover": "คุณสมบัติการหมุนเวียน",
    "Current Rank": "อันดับปัจจุบัน",
    "Next Rank": "อันดับถัดไป",
    "Binary Spot Reservation": "การจองจุดไบนารี",
    "Holding Tank Ends In": "การถือครอง Tank <b>สิ้นสุดใน</b>",
    "With every parcel purchase, you will receive two types of tokens—Gymnet and Bitcoin.": "ทุกการซื้อพัสดุ คุณจะได้รับโทเค็นสองประเภท—Gymnet และ Bitcoin",
    "โดยต้องมี BUSD ขั้นต่ำสำหรับการซื้อคือ": "ต้องมีขั้นต่ำ ${n} BUSD ต่อการซื้อ",
    "Become Affiliate Partner": "มาเป็นพันธมิตรในเครือ",
    "Placement in Unilevel": "ตำแหน่งใน Unilevel (ยูนิเลเวล)",
    "Matching bonuses": "โบนัสที่ตรงกัน",
    "Placement in Binary": "ตำแหน่งในไบนารี",
    "Product Autoship Incentive": "Product Autoship Incentive สิ่งจูงใจในการส่งผลิตภัณฑ์อัตโนมัติ",
    "For limited time only": "ในช่วงเวลาจำกัดเท่านั้น",
    "Free 30 days Product Autoship": "ฟรี 30 วัน ในการส่งผลิตภัณฑ์อัตโนมัติ",
    "Fast Start Incentive": "Fast Start Incentive แรงจูงใจเริ่มต้นอย่างรวดเร็ว",
    "Receive Direct Sales Commissions": "รับค่าคอมมิชชั่นการขายตรง",
    "Please select your role": "โปรดเลือกบทบาทของคุณ",
    "Choose your role before diving in. Specify why user should choose each role and what opportunities are there.": "เลือกบทบาทของคุณก่อนที่จะเริ่มดำเนินการ ระบุว่าเหตุท่านจึงเลือกบทบาทนั้นๆและมีโอกาสใดที่จะเกิดขึ้นบ้าง",
    "is left to get": "อีกเพียงแค่ <span>${n}</span> ก็จะได้รับ",
    "Go to Products": "ไปที่ผลิตภัณฑ์",
    "Rental Income": "รายได้จากค่าเช่า",
    "Left Leg Affiliate Link": "ลิงค์พันธมิตรขาซ้าย",
    "Right Leg Affiliate Link": "ลิงค์พันธมิตรขาขวา",
    "Qualification Conditions": "เงื่อนไขคุณสมบัติ",
    "Buy Products": "ซื้อผลิตภัณฑ์",
    "My Current Rank": "อันดับปัจจุบันของฉัน",
    "To Reach Rank You Need:": "เพื่อไปถึงอันดับ {n} คุณต้องการ:",
    "NFT Turnover In Weak Leg": "การหมุนเวียน NFT ในขาที่อ่อนแอ",
    "NFT Own purchases": "NFT การซื้อของตนเอง",
    "Required Qualified Partners": "พันธมิตรที่ผ่านการรับรองที่จำเป็น",
    "Progress Until Rank": "ความก้าวหน้าจนถึงอันดับ {n}",
    "See Details": "ดูรายละเอียด",
    "Default Advantages": "ข้อดีตั้งต้น",
    "Binary Commission": "คอมมิชชันไบนารี",
    "Upgrade Your Weekly Cap": "เพิ่มขีดจำกัดรายสัปดาห์ของคุณ",
    "Rank Benefits": "สิทธิประโยชน์จากระดับของอันดับ",
    "For 30 Days ONLY You Receive 10% Binary Commission Instead Of NUM": "เพียง 30 วันเท่านั้น ที่คุณจะได้รับคอมมิชชั่นไบนารี 10% แทนที่จะเป็น {n}%!",
    "My Rank Progress": "ความก้าวหน้าอันดับของฉัน",
    "Own Invest": "การลงทุนของตนเอง",
    "Qualified Partners": "พันธมิตรที่ผ่านการรับรอง",
    "You need to buy products in value of": "คุณต้องซื้อผลิตภัณฑ์มูลค่า ${n}",
    "You need to generate turnover in value of in your weak leg.": "คุณต้องสร้างมูลค่าการซื้อขายเป็นมูลค่า ${n} ในขาที่อ่อนแอของคุณ",
    "Remaining": "คงเหลือ",
    "CV Points weak leg": "CV Point ขาอ่อนแรง",
    "Details": "รายละเอียด",
    "Partner": "พันธมิตร",
    "leg": "ขา",
    "Right": "ขวา",
    "Left": "ซ้าย",
    "You need qualified partner in left leg and qualified partner in right leg to complete this requirement.": "คุณต้องมีพันธมิตรที่ผ่านการรับรอง {l} คน ที่ขาซ้ายและพันธมิตรที่ผ่านการรับรอง {r} คน ที่ขาขวาเพื่อปฏิบัติตามข้อกำหนดนี้",
    "partners in left leg and in right leg": "{l} {partner1} ในขาซ้ายและ {r} ในขาขวาที่เหลืออยู่",
    "partners in left leg remaining": "{l} {partner1} ในขาซ้ายที่เหลืออยู่",
    "partners in right leg remaining": "{l} {partner1} ในขาขวาที่เหลืออยู่",
    "partner in right leg remaining": "{r} พันธมิตรในขาขวาที่เหลืออยู่",
    "Holding Tank is over": "การถ่วง Tank ไว้สิ้นสุดแล้ว",
    "Back To Rank Progress Details": "กลับสู่รายละเอียดความคืบหน้าของอันดับ",
    "Super Bundle R10 or above": "Super Bundle R10\n หรือสูงกว่า",
    "Required Monthly Product Autoship": "การส่งผลิตภัณฑ์อัตโนมัติรายเดือนที่จำเป็น",
    "All Customer Benefits": "สิทธิประโยชน์ของลูกค้าทั้งหมด",
    "Product Autoship": "ผลิตภัณฑ์ส่งอัตโนมัติ",
    "Product Autoships": "ผลิตภัณฑ์ส่งอัตโนมัติ",
    "Products are resellable": "ผลิตภัณฑ์ที่สามารถจำหน่ายต่อได้",
    "Activate binary commission": "เปิดใช้งานคอมมิชชันไบนารี",
    "Activate direct sales commission": "เปิดใช้งานค่าคอมมิชชันการขายตรง",
    "Left before": "ซ้ายก่อน",
    "Left after": "ซ้ายหลัง",
    "Right before": "ขวาก่อน",
    "Right after": "ขวาหลัง",
    "Activate your referral link to be able to invite your referrals to the platform and earn additional commissions.": "เปิดใช้งานลิงก์ผู้อ้างอิงของคุณเพื่อให้สามารถเชิญผู้อ้างอิงของคุณมาที่แพลตฟอร์มและรับค่าคอมมิชชั่นเพิ่มเติมได้",
    "Activate referral link": "เปิดใช้งานลิงก์ผู้อ้างอิง",
    "Yes, I confirm": "ใช่ ฉันยืนยัน",
    "Inactive": "ไม่ใช้งาน",
    "Finance": "การเงิน",
    "Total Income": "รายได้ทั้งหมด",
    "Products": "ผลิตภัณฑ์",
    "GymStreet Map": "แผนที่ GymStreet",
    "Kabutocho Map": "แผนที่ Kabutocho",
    "Don't ask again": "อย่าถามอีก",
    "Filecoin Balance": "ยอดคงเหลือ Filecoin",
    "Filecoin": "Filecoin",
    "Total available": "ที่มีอยู่ทั้งหมด",
    "Total generated": "ที่สร้างขึ้นทั้งหมด",
    "Total used": "ที่ใช้แล้วทั้งหมด",
    "Transaction type": "ประเภทธุรกรรม",
    "To claim your commission, you must have a minimum of 1 USDT or (AMOUNT) GYMNET.": "หากต้องการรับค่าคอมมิชชั่น คุณต้องมีขั้นต่ำ 1 USDT หรือ {n} GYMNET",
    "Loading balance...": "กำลังโหลดยอดคงเหลือ...",
    "Total Commissions": "ค่าคอมมิชชั่นทั้งหมด",
    "Claimed Commissions": "ค่าคอมมิชชั่นที่อ้างสิทธิ์",
    "Available Commissions": "ค่าคอมมิชชั่นที่มีอยู่",
    "Total Commissions By Source": "ค่าคอมมิชชั่นทั้งหมดตามแหล่งที่มา",
    "Filter by currency": "กรองตามสกุลเงิน",
    "No data to display": "ไม่มีข้อมูลที่จะแสดง",
    "Year": "ปี",
    "Career Status": "สถานะอาชีพ",
    "Current Incentive": "แรงจูงใจในปัจจุบัน",
    "Fast Start": "เริ่มต้นอย่างรวดเร็ว",
    "Product Autoship incentive": "สิ่งจูงใจเกี่ยวกับผลิตภัณฑ์ส่งอัตโนมัติ",
    "Direct partner - left leg": "พันธมิตรโดยตรง - ขาซ้าย",
    "Direct partner - right leg": "พันธมิตรโดยตรง - ขาขวา",
    "Receive direct sales commission": "รับค่าคอมมิชชั่นการขายตรง",
    "Direct sales commission": "ค่าคอมมิชชั่นการขายตรง",
    "Receive binary commission": "รับคอมมิชชั่นไบนารี่",
    "Affiliate ranks": "อันดับพันธมิตร",
    "1 Left, 1 Right": "1 ซ้าย 1 ขวา",
    "See Benefits": "ดูสิทธิประโยชน์",
    "2 Silver (1 in different teams)": "2 ซิลเวอร์ (1 ซิลเวอร์ในทีมต่างกัน)",
    "2 Gold (1 in different teams)": "2 โกลด์ (1 โกลด์ในทีมต่างกัน)",
    "3 Platin (1 in different teams)": "3 แพลทินัม (1 แพลทินัมในทีมต่างกัน)",
    "3 Double Platin (1 in different teams)": "3 ดับเบิลแพลทินัม (1 ดับเบิลแพลทินัมในทีมต่างกัน)",
    "4 Diamond (1 in different teams)": "4 ไดมอนด์ (1 ไดมอนด์ในทีมต่างกัน)",
    "5 Double Diamond (1 in different teams)": "5 ดับเบิลไดมอนด์ (1 ดับเบิลไดมอนด์ในทีมต่างกัน)",
    "5 Red Diamond (1 in different teams)": "5 เรดไดมอนด์ (1 เรดไดมอนด์ในทีมต่างกัน)",
    "5 White Diamond (1 in different teams)": "5 ไวท์ไดมอนด์ (1 ไวท์ไดมอนด์ในทีมต่างกัน)",
    "Please note that $100 dollar will correspond to 100 points.": "โปรดทราบว่า $100 ดอลลาร์จะเท่ากับ 100 คะแนน",
    "Home": "หน้าหลัก",
    "Last Left": "ซ้ายสุดท้าย",
    "Last Right": "ขวาสุดท้าย",
    "Binary leg": "ขาไบนารี",
    "by admin": "โดยแอดมิน",
    "missed commission": "พลาดค่าคอมมิชชั่น",
    "payment": "การจ่ายเงิน",
    "commission": "คอมมิชชั่น",
    "Payment Currency": "สกุลเงินการจ่ายเงิน",
    "BUSD Amount": "จำนวน BUSD",
    "CV Ratio": "อัตราส่วน CV",
    "Hrs": "ชั่วโมง",
    "Mins": "นาที",
    "Secs": "วินาที",
    "Please enter a valid referral ID": "กรุณากรอกรหัสอ้างอิงที่ถูกต้อง",
    "Commission TXID": "ค่าคอมมิชชัน TXID",
    "Qualification turnover": "การหมุนเวียนคุณสมบัติ",
    "Binary commission": "ค่าคอมมิชชันไบนารี",
    "Additional Advantages": "สิทธิประโยชน์เพิ่มเติม",
    "The referral ID you've entered is incorrect. Please copy and paste the correct referral link to proceed.": "ID ผู้อ้างอิงที่คุณป้อนไม่ถูกต้อง โปรดคัดลอกและวางลิงก์อ้างอิงที่ถูกต้องเพื่อดำเนินการต่อ",
    "In order to receive binary commissions you need to have an active product autoship.": "เพื่อที่จะรับค่าคอมมิชชั่นไบนารี่ คุณจะต้องมีการส่งสินค้าอัตโนมัติที่ใช้งานอยู่",
    "In order to receive binary commissions you need to have an active product autoship and a purchase for at least $100.": "เพื่อที่จะได้รับค่าคอมมิชชั่นไบนารี่ คุณจะต้องมีการจัดส่งสินค้าอัตโนมัติและทำการซื้ออย่างน้อย $100",
    "To qualify for binary commissions, a minimum product purchase of $100 is required.": "เพื่อให้มีคุณสมบัติรับค่าคอมมิชชั่นไบนารี ต้องมีการซื้อผลิตภัณฑ์ขั้นต่ำ $100",
    "Transaction in Process": "ธุรกรรมอยู่ระหว่างดำเนินการ",
    "Claim your parcel": "เรียกร้องพัสดุของคุณ",
    "You do not have any claimed slots to attach miners. To enable miner attachment, please claim parcels on the map.": "คุณไม่มีช่องที่อ้างสิทธิ์เพื่อแนบคนงานเหมือง หากต้องการเปิดใช้งานไฟล์แนบของนักขุด โปรดรับพัสดุบนแผนที่",

    "Active binary commission": "คอมมิชชั่นไบนารี่ที่ใช้งานอยู่",
    "Active direct sales commission": "ค่าคอมมิชชันการขายตรงที่ใช้งานอยู่",
    "Own purchase min {AMOUNT}": "ซื้อเองขั้นต่ำ {AMOUNT}",
    "Active product autoship": "การส่งสินค้าอัตโนมัติที่ใช้งานอยู่",
    "Right partner own purchase min {AMOUNT}": "การซื้อของพันธมิตรที่ถูกต้องขั้นต่ำ {AMOUNT}",
    "Left partner own purchase min {AMOUNT}": "เหลือการซื้อของพันธมิตรขั้นต่ำ {AMOUNT}",
    "Unfortunately, you won't receive the bonus for this rank since you met the qualification conditions (more than 50%) before September 18, 2023": "ขออภัย คุณจะไม่ได้รับโบนัสสำหรับอันดับนี้เนื่องจากคุณมีคุณสมบัติตรงตามเงื่อนไข (มากกว่า 50%) ก่อนวันที่ 18 กันยายน 2023",
    "You will be eligible for cash bonus based on your rank if you meet all qualification conditions between September 18 and November 8.Those who met the qualification conditions (more than 50%) before September 18, 2023 will not receive the Super Cash Bonus.": "คุณจะมีสิทธิ์ได้รับโบนัสเงินสดตามอันดับของคุณ หากคุณมีคุณสมบัติตรงตามเงื่อนไขทั้งหมดระหว่างวันที่ 18 กันยายน ถึง 31 มกราคม ผู้ที่มีคุณสมบัติตรงตามเงื่อนไข (มากกว่า 50%) ก่อนวันที่ 18 กันยายน 2023 จะไม่ได้รับ Super Cash Bonus",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / USDT": "เมื่อมีการดำเนินการสร้างอาคารธุรกิจใหม่ คุณจะ \n มีทางเลือกในการซื้อที่ดินโดยการชำระเงินด้วย GYMNET / USDT",
    "yearly": "รายปี",
    "Unknown": "ไม่ทราบ",
    "A minimum of USDT is required for Purchase": "หากต้องการซื้อ คุณจะต้องมีขั้นต่ำอย่างน้อย ${n} USDT",
    "Claim processing may take up to 2 minutes. Thank you for your patience.": "การประมวลผลการเคลมอาจใช้เวลานานถึง 2 นาที ขอขอบพระคุณที่อดทนรอ",
    "1 Person": "1 คน",
    "2 Persons": "2 คน",
    "Extended Referral Link": "ลิงก์ผู้อ้างอิงเพิ่มเติม",
    "Enter a username": "ใส่ชื่อผู้ใช้",
    "Possible only for usernames in your binary team!": "ใช้ได้กับชื่อผู้ใช้ในทีมไบนารีของคุณเท่านั้น!",
    "Select Team": "เลือกทีม",
    "Create Link": "สร้างลิงค์",
    "New users will be placed under in the team": "ผู้ใช้ใหม่จะอยู่ภายใต้ {n} ในทีม {p}",
    "Invalid username!": "ชื่อผู้ใช้ที่ไม่ถูกต้อง!",
    "Left team": "ทีมซ้าย",
    "Right team": "ทีมขวา",
    "Distribution In Progress": "อยู่ระหว่างการการกระจาย",
    "Next Binary Bonus Distribution In": "การกระจายโบนัสไบนารีถัดไปใน",
    "Matching Bonus Commissions": "ค่าคอมมิชชั่นโบนัสที่ตรงกัน",
    "Binary Commissions": "ค่าคอมมิชชั่นไบนารี",
    "Binary Weekly Cap": "ไบนารีรายสัปดาห์สูงสุด",
    "Binary Cycles": "วงจรไบนารี่s",
    "Matching Bonus": "โบนัสการจับคู่",
    "Incomes from Product Autoships": "รายได้จากการส่งสินค้าอัตโนมัติ",
    "Missed Commissions": "พลาดค่าคอมมิชชั่น",
    "Plus Max Weekly Payout": "บวกการจ่ายเงินสูงสุดรายสัปดาห์",
    "Import Token With Address": "นำเข้าโทเค็นพร้อมที่อยู่",
    "Import Supported Token": "นำเข้าโทเค็นที่รองรับ",
    "Shop": "ร้านค้า",
    "Filter by": "กรองตาม",
    "My Purchases": "การซื้อของฉัน",
    "Purchases for other members": "การซื้อของสมาชิกท่านอื่น",
    "Product Autoship Purchases": "การซื้อผลิตภัณฑ์ออโต้ชิป",
    "Sort By": "เรียงตาม",
    "Top up": "เติมเงิน",
    "Attach your miners": "แนบคนงานเหมืองของคุณ",
    "Buy parcels": "ซื้อพัสดุ",
    "Number of parcels you want to buy": "จำนวนพัสดุที่คุณต้องการซื้อ",
    "Use utility balance": "ใช้ยอดคงเหลือ utility",
    "My Products": "สินค้าของฉัน",
    "USDT Amount": "จำนวน USDT",
    "Next Matching Bonus Distribution In": "การกระจายโบนัสการจับคู่ถัดไปใน",
    "Please top up your wallet with AMOUNT_CURRENCY USDT for upgrade.": "โปรดเติมเงินกระเป๋าเงินของคุณด้วย {AMOUNT_CURRENCY} USDT เพื่ออัปเกรด",
    "Please note that worth of USDT is required to complete the transaction.": "โปรดทราบว่าต้องใช้มูลค่า USDT จำนวน {n} เพื่อทำธุรกรรมให้เสร็จสมบูรณ์",
    "Incentive Ends In:": "แรงจูงใจสิ้นสุดใน:",
    "Bonus History": "ประวัติโบนัส",
    "Incentive has ended": "แรงจูงใจสิ้นสุดลงแล้ว",
    "Direct Sales Turnover": "ยอดขายตรง",
    "Commissions are paid out following a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "ค่าคอมมิชชั่นจะจ่ายตามอัตราส่วน 70/30 โดย 70% เป็นเหรียญเสถียรและ 30% เป็นโทเค็น GYMNET",
    "(effective as of 28.02.2024)": "(มีผลตั้งแต่วันที่ 28.02.2024)",
    "Free Product Autoship": "ฟรีสินค้าออโต้ชิป",
    "10% Binary Commission": "10% คอมมิชชั่นไบนารี่",
    "Enjoy More, Benefit More!": "สนุกมากขึ้น ได้รับประโยชน์มากขึ้น!",
    "To qualify for Binary Commissions, you must have a new member with at least $100 on your right team and another on your left team.": "เพื่อให้มีคุณสมบัติได้รับค่าคอมมิชชันไบนารี คุณต้องมีสมาชิกใหม่ที่มีเงินอย่างน้อย $100 ในทีมด้านขวาของคุณและอีกคนหนึ่งอยู่ในทีมด้านซ้ายของคุณ",
    "Buy products for at least $100 within 30 days to receive these benefits.": "ซื้อผลิตภัณฑ์อย่างน้อย <span>$100</span> ภายใน <span>30 วัน</span> เพื่อรับสิทธิประโยชน์เหล่านี้",
    "Getting started": "เริ่มต้นใช้งาน",
    "Confirm your email to continue": "ยืนยันอีเมลของคุณเพื่อดำเนินการต่อ",
    "Resend confirmation email": "ส่งอีเมลยืนยันอีกครั้ง",
    "Super Bundle Special Discount Expires In": "ส่วนลดพิเศษ Super Bundle จะหมดอายุภายใน",
    "Done": "เสร็จแล้ว",
    "Get your bundle": "รับชุดของคุณ",
    "Gain Fresh Perspectives and Exclusive Benefits": "รับมุมมองใหม่และสิทธิประโยชน์พิเศษ",
    "Super Bundle R8": "ซุปเปอร์บันเดิล R8",
    "Super Bundle R17": "ซุปเปอร์บันเดิล R17",
    "Starter Bundle": "ชุดเริ่มต้น",
    "Travel": "โปรโมชั่นท่องเที่ยว",
    "Cash Promo": "โปรโมชั่นเงินสด",
    "Monthly Product Autoship is required to be an Affiliate Partner and earn commissions.": "Autoship ผลิตภัณฑ์รายเดือนจะต้องเป็นพันธมิตร Affiliate และรับค่าคอมมิชชั่น",
    "Affiliate": "พันธมิตร",
    "Recommended": "ที่แนะนำ",
    "Binary Commissions based on the rank": "ค่าคอมมิชชั่นไบนารีขึ้นอยู่กับอันดับ",
    "Customer": "ลูกค้า",
    "NFT Turbo": "NFT เทอร์โบ",
    "Purchase Digital Land": "ซื้อที่ดินดิจิทัล",
    "Participate in Partnership Deals": "เข้าร่วมในข้อตกลงความร่วมมือ",
    "Receive minting Rewards": "รับรางวัลเหรียญกษาปณ์",
    "Up to": "มากถึง {n}%",
    "Weekly Distribution": "การกระจายรายสัปดาห์",
    "A parcel with minting power will generate rewards in Filecoin tokens, which will be distributed every Monday.": "พัสดุที่มีพลังการขุดจะสร้างรางวัลเป็นโทเค็น Filecoin ซึ่งจะแจกจ่ายทุกวันจันทร์",
    "It seems that a some request is already pending. Please check your wallet app or extension and try again.": "ดูเหมือนว่าคำขอบางรายการกำลังรอดำเนินการอยู่ โปรดตรวจสอบแอปกระเป๋าสตางค์หรือส่วนขยายของคุณแล้วลองอีกครั้ง",
    "Add miner NFT to wallet": "เพิ่ม Miner NFT ลงในกระเป๋าเงิน",
    "Go to My NFTs": "ไปที่ NFT ของฉัน",
    "Thank you for your participation": "ขอบคุณสำหรับการมีส่วนร่วมของคุณ",
    "No owner": "ไม่มีเจ้าของ",
    "Ownership of this bundle is limited to one per user. Please purchase for yourself and for those who do not have it.": "การเป็นเจ้าของชุดข้อมูลนี้จำกัดไว้เพียงหนึ่งรายการต่อผู้ใช้หนึ่งราย โปรดซื้อเพื่อตัวคุณเองและสำหรับผู้ที่ไม่มี",
    "Buy parcels with minting power and generate income.": "ซื้อพัสดุด้วยพลังการขุดและสร้างรายได้",
    // Mystery box
    "Limited time offer": "ข้อเสนอมีเวลาจำกัด",
    "See Options": "ดูตัวเลือก",
    "Please select one of the currencies": "โปรดเลือกหนึ่งในสกุลเงิน",
    "Current balance": "ยอดเงินปัจจุบัน",
    "Try your luck": "ลองเสี่ยงโชคของคุณ",
    "Offer ends in": "ข้อเสนอสิ้นสุดใน",
    "Parcel and Dual Miner": "พัสดุและคนขุดแร่คู่",
    "Mystery Box": "กล่องลึกลับ",
    "Try your luck and win incredible Bundles, Parcels and Dual Miners to boost your earnings!": "ลองเสี่ยงโชคและรับชุดบันเดิล พัสดุ และ Dual Miners ที่น่าทึ่งเพื่อเพิ่มรายได้ของคุณ!",
    "from MIN to MAX": "จาก <b>{min}</b> ถึง <b>{max}</b>",
    "Choose a Box and Discover Rewards": "เลือกกล่องและค้นพบรางวัล",
    "Prizes you can win": "รางวัลที่คุณสามารถชนะได้",
    "Item": "รายการ",
    "Chance": "โอกาส",
    "Promos": "โปรโมชั่น",
    "Mystery Box results": "ผลลัพธ์กล่องปริศนา",
    "Total reward value": "มูลค่ารางวัลรวม",
    "My Rewards": "รางวัลของฉัน",
    "Rewards Community Claimed": "รางวัลชุมชนอ้างสิทธิ์",
    "Hero image": "ภาพฮีโร่",
    "No rewards yet": "ยังไม่มีรางวัล",
    "No winners yet": "ยังไม่มีผู้ชนะ",
    "While no lucky participants have claimed their prizes yet, the excitement continues to build.": "แม้ว่ายังไม่มีผู้โชคดีคนใดที่ได้รับรางวัล แต่ความตื่นเต้นก็ยังคงเพิ่มขึ้นอย่างต่อเนื่อง",
    "Mystery Box is Here!": "กล่องปริศนามาแล้ว!",
    "Be the First to Discover Mystery Rewards": "เป็นคนแรกที่ค้นพบรางวัลลึกลับ",
    "Select Balance": "เลือกยอดคงเหลือ",
    "Current Balance": "ยอดเงินปัจจุบัน",
    "Opening Mystery Box": "การเปิดกล่องปริศนา",
    "Oops, not this time 😔": "อุ๊ย ไม่ใช่ครั้งนี้ 😔",
    "Luck is on your side. Enjoy your prize!": "โชคเข้าข้างคุณ เพลิดเพลินไปกับรางวัลของคุณ!",
    "The more you try, the better your chances of winning. Keep going!": "ยิ่งคุณพยายามมากเท่าไรก็ยิ่งมีโอกาสชนะมากขึ้นเท่านั้น ทำต่อไป!",
    "Try another box": "ลองกล่องอื่น",
    "Go to My Products": "ไปที่ผลิตภัณฑ์ของฉัน",
    "See promo results": "ดูผลโปรโมชั่น",
    "Payment enabled": "เปิดใช้งานการชำระเงินแล้ว",
    "Please select the wallet that is attached to your account.": "โปรดเลือกกระเป๋าเงินที่แนบมากับบัญชีของคุณ",
    "Explore rewards": "สำรวจรางวัล",
    "View rewards claimed by you and the community": "ดูรางวัลที่คุณและชุมชนอ้างสิทธิ์",
    "Open a Mystery Box to get rewards starting from Parcels to Super bundles.": "เปิดกล่องปริศนาเพื่อรับรางวัลตั้งแต่พัสดุไปจนถึงชุดซุปเปอร์",
    "Insufficient funds, please choose another box.": "เงินไม่เพียงพอ โปรดเลือกช่องอื่น",
    "Blockchain error": "ข้อผิดพลาดบล็อคเชน",
    "Rewards claimed": "รับรางวัลแล้ว",
    "We have encountered difficulties while processing the result of the reward. Please contact our support team for prompt assistance.": "เราประสบปัญหาขณะประมวลผลผลลัพธ์ของรางวัล โปรดติดต่อทีมสนับสนุนของเราเพื่อขอความช่วยเหลือทันที",
    "Boxes opened": "เปิดกล่องแล้ว",
    // Mystery box

    // Partnership details
    "About": "เกี่ยวกับ",
    "Offer": "เสนอ",
    "Social Media": "สื่อสังคม",
    "Additional Content": "เนื้อหาเพิ่มเติม",
    "Photo": "รูปถ่าย",
    "Video": "วีดีโอ",
    // Partnership details

    // Miner Rewards
    "Single Miners": "คนงานเหมืองคนเดียว",
    "price today": "ราคาวันนี้",
    "Estimated daily rewards": "ผลตอบแทนรายวันโดยประมาณ",
    "day": "วัน",
    "day at current rate": "วันตามอัตราปัจจุบัน",
    "Estimated rewards until 2025": "ผลตอบแทนโดยประมาณจนถึงปี 2025",
    "at current rate": "ในอัตราปัจจุบัน",
    "Estimated rewards from today to December 1, 2025. It may change based on your shares in the pool.": "รางวัลโดยประมาณตั้งแต่วันนี้ถึง 1 ธันวาคม 2568 อาจเปลี่ยนแปลงได้ตามจำนวนการแชร์ของคุณในพูล",
    "Expected rewards calculator": "เครื่องคำนวณรางวัลที่คาดหวัง",
    "price": "ราคา",
    "My total GYMNET value in USD": "มูลค่า GYMNET ทั้งหมดของฉันเป็น USD",
    "claimed": "อ้างสิทธิ์",
    "My Balances": "ยอดคงเหลือของฉัน",
    "Tokens to be burned": "โทเค็นที่จะเผา",
    "You dont have any Claimed or Pending GYMNET rewards.": "คุณไม่มีรางวัล GYMNET ที่อ้างสิทธิ์หรือรอดำเนินการ",
    "Claim now": "เรียกร้องตอนนี้",
    "No claimed GYMNET rewards are found.": "ไม่พบรางวัล GYMNET ที่อ้างสิทธิ์",
    // Miner Rewards

    "Please note that the leg with the lesser amount of points is called weak leg and the one with the higher amount of points is called strong or power leg, and it can change over time based on which leg the most turnover is generated.": "โปรดทราบว่าขาที่มีแต้มน้อยกว่าเรียกว่า \"ขาอ่อนแอ\" และขาที่มีแต้มมากกว่าเรียกว่า \"แข็งแกร่ง\" หรือ \"ขาทรงพลัง\" และสามารถเปลี่ยนไปตามกาลเวลาโดยขึ้นอยู่กับว่าขาใดมีการหมุนเวียนมากที่สุด ถูกสร้างขึ้น",
    "Mystery Box price": "ราคา กล่องปริศนา",

    "Registration Date": "วันที่ลงทะเบียน",
    "First Purchase Date": "วันที่ซื้อครั้งแรก",
    "Product Autoship Valid Until": "สินค้าออโต้ชิปใช้ได้จนถึง",

    "Own Deposit": "เงินฝากของตัวเอง",
    "years ago": "หลายปีก่อน",
    "Rank Achievement Bonus": "โบนัสความสำเร็จอันดับ",
    "Rank achievement bonus eligibility is based on rank achieved through qualifications.": "สิทธิ์โบนัสความสำเร็จของอันดับขึ้นอยู่กับอันดับที่ได้รับผ่านคุณสมบัติ",
    "Bonuses": "โบนัส",
    "Bonuses are paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET tokens": "โบนัสจะจ่ายตามอัตราส่วน 70/30 โดยมี 70% เป็น USDT และ 30% เป็นโทเค็น GYMNET",
    "Rank current": "อันดับ {n}",
    "You will receive the bonus after the incentive is over, and you can view it in the 'My Wallet' section.": "คุณจะได้รับโบนัสหลังจากสิ่งจูงใจสิ้นสุดลง และคุณสามารถดูได้ในส่วน 'กระเป๋าเงินของฉัน'",
    "You must have at least RANK partners, with at least one in a different leg.": "คุณต้องมีพันธมิตรอย่างน้อย {rank} โดยมีอย่างน้อยหนึ่งคนที่อยู่ในขาที่แตกต่างกัน",
    "Additional Assets": "ทรัพย์สินเพิ่มเติม",
    "Back to My wallet": "กลับไปที่กระเป๋าสตางค์ของฉัน",
    "Bonus points": "คะแนนโบนัส",
    "Fee": "ค่าธรรมเนียม",
    "Bonus Details": "รายละเอียดโบนัส",
    "Details / weekly cap": "รายละเอียด/รายสัปดาห์",
    "User / amount": "ผู้ใช้/จำนวนเงิน",
    "Extended Ref Link": "ลิงก์อ้างอิงแบบขยาย",
    "Leadership": "ภาวะผู้นำ",
    "Travel Incentive": "แรงจูงใจด้านการท่องเที่ยว",
    "Unlock the full potential of your Filecoin rewards as they come to life following your purchase of any of our special bundles. Once your Filecoin Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "ปลดล็อกศักยภาพของรางวัล Filecoin ของคุณอย่างเต็มที่ ทันทีที่คุณซื้อชุดรวมพิเศษของเรา เมื่อยอดคงเหลือ Filecoin ของคุณพร้อมใช้งาน คุณจะสามารถเข้าถึงภาพรวมของรางวัลทั้งหมด รางวัลที่ได้รับ และรางวัลที่คาดหวัง นอกจากนี้ ตัวจับเวลาถอยหลังจะแสดงการแจกรางวัลครั้งถัดไป ทำให้คุณมั่นใจได้ว่าจะไม่พลาดรางวัล",
    "is left to qualify": "เหลือ ${n} ที่จะผ่านเข้ารอบ",
    "Check transactions": "ตรวจสอบธุรกรรม",
    "Direct partner own purchases": "การซื้อของพันธมิตรโดยตรง",
    "Each of your direct partners on the left and right legs needs to generate a turnover value of $100.00.": "หุ้นส่วนโดยตรงของคุณทั้งทางซ้ายและขวาต้องสร้างมูลค่าการหมุนเวียนที่ $100.00",
    // Feature Prioritization
    "Participation to GYMSTREETs Elite Vote": "การเข้าร่วมการโหวต Elite ของ GYMSTREET",
    "Prioritization ends in": "การจัดลำดับความสำคัญจะสิ้นสุดใน",
    "Start Prioritizing": "เริ่มจัดลำดับความสำคัญ",
    "Your changes have been saved": "การเปลี่ยนแปลงของคุณได้รับการบันทึก",
    "features are still remaining": "<b>{n} คุณลักษณะ</b>ยังคงอยู่",
    "feature is still remaining": "<b>{n} คุณลักษณะ</b>ยังคงอยู่",
    "All prioritized": "จัดลำดับความสำคัญทั้งหมด",
    "GYMSTREETs Elite Vote Statistics": "สถิติการโหวต Elite ของ GYMSTREET",
    "Just NUM features to go! Complete your prioritization to help us shape community's vision.": "เพียง <b>{countText}</b> ไปได้เลย! ดำเนินการจัดลำดับความสำคัญของคุณให้เสร็จสิ้นเพื่อช่วยเรากำหนดวิสัยทัศน์ของชุมชน",
    "You've prioritized the features. Please review your choices and hit Submit to finalize.": "คุณได้จัดลำดับความสำคัญของคุณสมบัติต่างๆ โปรดตรวจสอบตัวเลือกของคุณแล้วกด <b>“ส่ง”</b> เพื่อเสร็จสิ้น",
    "Back to All Features": "กลับไปที่คุณสมบัติทั้งหมด",
    "Changes saved": "บันทึกการเปลี่ยนแปลงแล้ว",
    "No changes": "ไม่มีการเปลี่ยนแปลง",
    "Submit": "ส่ง",
    "priority": "ลำดับความสำคัญ",
    "Set priority": "กำหนดลำดับความสำคัญ",
    "Feature details": "รายละเอียดคุณสมบัติ",
    "Select a feature to see more info": "เลือกคุณลักษณะเพื่อดูข้อมูลเพิ่มเติม",
    "Prioritization Submitted": "ส่งการจัดลำดับความสำคัญแล้ว",
    "The results will be available after the voting period ends. You'll be able to change your priorities until then.": "ผลลัพธ์จะสามารถใช้ได้หลังจากสิ้นสุดระยะเวลาการโหวต คุณจะสามารถเปลี่ยนลำดับความสำคัญได้จนกว่าจะถึงตอนนั้น",
    "Go to Career Status page": "ไปที่หน้าสถานะอาชีพ",
    "Keep Prioritizing": "จัดลำดับความสำคัญต่อไป",
    "Continue Prioritizing": "จัดลำดับความสำคัญต่อไป",
    "Review and Submit": "ตรวจสอบและส่ง",
    "Thinking of reprioritizing?": "กำลังคิดที่จะจัดลำดับความสำคัญใหม่ใช่ไหม?",
    "You've already submitted your priorities. If you want to reprioritize them, please make sure you hit Resubmit.": "คุณได้ส่งลำดับความสำคัญของคุณแล้ว หากคุณต้องการจัดลำดับความสำคัญใหม่ โปรดอย่าลืมกด <b>“ส่งอีกครั้ง”</b>",
    "Got it": "เข้าใจแล้ว",
    "Resubmit": "ส่งอีกครั้ง",
    "Priorities submitted": "ส่งลำดับความสำคัญแล้ว",
    "Submitted": "ส่ง",
    "View Statistics": "ดูสถิติ",
    "Continue Prioritization": "ดำเนินการจัดลำดับความสำคัญต่อไป",
    "Reprioritize": "จัดลำดับความสำคัญใหม่",
    "Start Prioritization": "เริ่มการจัดลำดับความสำคัญ",
    "features": "คุณสมบัติ",
    "feature": "คุณสมบัติ",
    "GYMSTREET Elite Vote": "การโหวตแบบ Elite ของ GYMSTREET",
    "Thanks for submitting! You can still reprioritize and resubmit your choices until the voting period ends.": "ขอบคุณสำหรับการส่ง! คุณยังคงจัดลำดับความสำคัญใหม่และส่งตัวเลือกของคุณใหม่ได้จนกว่าช่วงการโหวตจะสิ้นสุดลง",
    "Youre not done yet. Continue prioritizing the upcoming features and submit your final choices.": "คุณยังไม่เสร็จ จัดลำดับความสำคัญของคุณสมบัติที่กำลังจะมาถึงต่อไปและส่งตัวเลือกสุดท้ายของคุณ",
    "Exclusive for Platin and higher rank members. Prioritize the features you want to see next on GYMSTREET.": "พิเศษสำหรับสมาชิก Platin และสมาชิกระดับสูงกว่า จัดลำดับความสำคัญคุณสมบัติที่คุณต้องการเห็นต่อไปบน GYMSTREET",
    "View rules": "ดูกฎเกณฑ์",
    "Prioritization has ended. See the community's vote results.": "การจัดลำดับความสำคัญสิ้นสุดลงแล้ว ดูผลการโหวตของชุมชน",
    "Stay tuned": "คอยติดตาม",
    "See current community prioritization statistics below. In 7 days, we'll also announce our decision right here, revealing the selected new features and their estimated release dates.": "ดูสถิติการจัดลำดับความสำคัญของชุมชนปัจจุบันด้านล่าง ภายใน 7 วัน เราจะประกาศการตัดสินใจของเราที่นี่ โดยเปิดเผยคุณสมบัติใหม่ที่เลือกไว้และวันที่เผยแพร่โดยประมาณ",
    "Terms and Conditions for GYMSTREETs Elite Vote": "ข้อกำหนดและเงื่อนไขสำหรับการโหวตแบบ Elite ของ GYMSTREET",

    // Suspicous activity
    "You have one attempt left": "คุณมีความพยายามเหลืออยู่หนึ่งครั้ง หลังจากนั้น ความพยายามของคุณสำหรับบัญชีนี้จะถูกจำกัดชั่วคราว",
    "You have no attempts left": "คุณไม่มีความพยายามเหลือสำหรับบัญชีนี้ โปรดลองอีกครั้งใน {minutesText} <b>นาที</b> หรือรีเซ็ตรหัสผ่านของคุณ",
    "Sorry, you’ve been blocked": "ขออภัย คุณถูกบล็อก",
    "Your IP address has been temporarily blocked for security reasons.": "ที่อยู่ IP ของคุณถูกบล็อกชั่วคราวด้วยเหตุผลด้านความปลอดภัย กรุณารอสักครู่ คุณจะถูกเปลี่ยนเส้นทางโดยอัตโนมัติในไม่ช้า",
    "If you believe this was a mistake, please contact us through our Customer Support.": "หากคุณเชื่อว่านี่เป็นข้อผิดพลาด โปรดติดต่อเราผ่านทาง <a href=\"mailto:support@gymstreet.io?subject=Suspicious Activity&body=I am blocked due to suspicious activity, but I think It's a mistake\" class=\"customer-support-button\">ฝ่ายสนับสนุนลูกค้า</a>",
    // Instant commissions
    "The period for earning mining rewards in BITOPEX tokens has officially concluded. Thanks for your participation. Enjoy your BPX earnings.": "ระยะเวลาในการรับรางวัลการขุดในโทเค็น BITOPEX ได้สิ้นสุดลงอย่างเป็นทางการแล้ว ขอบคุณสำหรับการมีส่วนร่วมของคุณ เพลิดเพลินไปกับรายได้ BPX ของคุณ",
    "We're enhancing our claim feature, so it will be offline, for the next {hours} hours. Thank you and stay tuned!": "เรากำลังปรับปรุงคุณสมบัติการอ้างสิทธิ์ของเรา ดังนั้นจึงจะออฟไลน์ในอีก {hours} ชั่วโมงข้างหน้า ขอขอบคุณและคอยติดตาม!",
    "Eligibility for a one-time 10% commission, based on the total turnover generated from direct sales, requires referring team members and achieving a turnover of $5,000 or more in direct sales.": "การมีสิทธิ์รับค่าคอมมิชชัน 10% แบบครั้งเดียว โดยอิงตามมูลค่าการซื้อขายรวมที่เกิดจากการขายตรง ต้องมีการแนะนำสมาชิกในทีมและต้องมียอดขายตั้งแต่ 5,000 ดอลลาร์ขึ้นไปในการขายตรง",

    "Turnover Incentive": "แรงจูงใจในการหมุนเวียน",
    "Available For Limited time only": "ใช้ได้เฉพาะ <b>ระยะเวลาจำกัด</b> เท่านั้น",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving $20,000 in direct sales.": "โปรดทราบว่าคุณมีสิทธิ์ได้รับชุด R8 ฟรีเมื่อทำยอดขายตรงได้ครบ 20,000 ดอลลาร์",

    "Minting Rewards": "รางวัลมิ้นท์",
    "Please check transactions if your {asset} balance is not visible": "โปรดตรวจสอบธุรกรรมหากยอดเงิน {asset} ของคุณไม่ปรากฏให้เห็น",
    "In order to send {asset} you need to select {chain} network and wallet that is connected to your account.": "ในการส่ง {asset} คุณต้องเลือกเครือข่าย {chain} และกระเป๋าเงินที่เชื่อมต่อกับบัญชีของคุณ",
    "Cash bonus eligibility is based on rank achieved through qualifications. Participants qualifying with over 50% before March 4th, 2024, will not receive the bonus.": "สิทธิ์โบนัสเงินสดขึ้นอยู่กับอันดับที่ได้รับผ่านคุณสมบัติ ผู้เข้าร่วมที่มีคุณสมบัติมากกว่า 50% ก่อนวันที่ 4 มีนาคม 2024 จะไม่ได้รับโบนัส",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving 100k in team purchases.": "โปรดทราบว่าคุณมีสิทธิ์รับบันเดิล R8 ฟรีเมื่อซื้อทีมครบ 100,000",

    "Convert": "แปลง",
    "Convert your unused miners into hashpower to earn rewards in Gymnet and Filecoin": "แปลงนักขุดที่ไม่ได้ใช้ของคุณให้เป็นพลังแฮชเพื่อรับรางวัลเป็น Gymnet และ Filecoin",
    "The 50% Turnover Rule is applied to this incentive": "กฎการหมุนเวียน 50% ใช้กับสิ่งจูงใจนี้",

    "Super Bundle Rank Benefits": "สิทธิประโยชน์อันดับ Super Bundle",
    "Matching bonus up to NUM levels": "จับคู่โบนัสสูงสุด {num} ระดับ",
    "Up to NUM levels": "มากถึง {num} ระดับ",
    "Left leg partner": "คู่ขาซ้าย",
    "Right leg partner": "คู่ขาขวา",
    "Commission Activation Requirements": "ข้อกำหนดในการเปิดใช้งานค่าคอมมิชชั่น",
    "Up to 1 level": "ได้ถึง 1 ระดับ",
    "Bonus is paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET": "โบนัสจะจ่ายตามอัตราส่วน 70/30 โดยมี 70% เป็น USDT และ 30% ใน GYMNET",
    "Current Turnover": "มูลค่าการซื้อขายปัจจุบัน",
    "You will receive the bonus once the incentive period is over and it will be deposited directly into your wallet.": "คุณจะได้รับโบนัสเมื่อระยะเวลาจูงใจสิ้นสุดลง และโบนัสจะฝากเข้ากระเป๋าเงินของคุณโดยตรง",
    "Purchase Amount": "จำนวนการซื้อ",
    "Spring": "ฤดูใบไม้ผลิ",
    "Spring Incentive": "แรงจูงใจฤดูใบไม้ผลิ",
    "Commission Earned": "ค่าคอมมิชชันที่ได้รับ: <b>${n}</b>",
    "Bonus Earned": "โบนัสที่ได้รับ: <b>${n}</b>",
    "Earn 1 PAP point for every dollar spent on purchases using a utility balance or when you purchase a product autoship.": "รับ 1 คะแนน PAP สำหรับทุก ๆ ดอลลาร์ที่ใช้จ่ายในการซื้อสินค้าโดยใช้ยอดคงเหลือยูทิลิตี้หรือเมื่อคุณซื้อการจัดส่งผลิตภัณฑ์อัตโนมัติ",
    "Member": "Affiliate",
    "Trainee": "Trainee",
    "Rookie": "Rookie",
    "Silver": "Silver",
    "Gold": "Gold",
    "Platinum": "Platinum",
    "Double Platinum": "Double Platinum",
    "Diamond": "Diamond",
    "Double Diamond": "Double Diamond",
    "Red Diamond": "Red Diamond",
    "White Diamond": "White Diamond",
    "Crown Diamond": "Crown Diamond",
    "Platin": "Platinum",
    "Double Platin": "Double Platinum",
    "Single Pool": "Single Pool",
    "Explorer": "Explorer",
    "Mastery": "Mastery",
    "Features expected timeframes revealed": "มีการเปิดเผยกรอบเวลาที่คาดหวัง",
    "Find estimated timelines for features prioritized by our community and aligned with our strategy.": "ค้นหาไทม์ไลน์โดยประมาณสำหรับฟีเจอร์ที่ชุมชนของเราจัดลำดับความสำคัญและสอดคล้องกับกลยุทธ์ของเรา",
    "Estimated timeframe": "ระยะเวลาโดยประมาณ",
    "Undefined": "ไม่ได้กำหนด",
    "No Longer Valid": "ไม่ถูกต้องอีกต่อไป",
    "Deposit to Single pool": "ฝากไปที่สระเดี่ยว",
    "Max": "สูงสุด",
    "Received": "ได้รับ",
    "About voting rights": "เกี่ยวกับสิทธิในการออกเสียง",
    "Your first deposit must be over $25": "เงินฝากครั้งแรกของคุณต้องมากกว่า $25",
    "Staking period ends": "ระยะเวลาการปักหลักสิ้นสุดลง",
    "December": "ธันวาคม",
    "Deposit & Stake": "เงินฝากและเดิมพัน",
    "Purchasing the card requires passing the KYC procedure.": "การซื้อบัตรต้องผ่านขั้นตอน KYC",
    "Continue to KYC": "ดำเนินการต่อไปยัง KYC",
    "KYC Verification": "การตรวจสอบ KYC",
    "Step": "ขั้นตอนที่ {n}",
    "Upload ID": "รหัสการอัปโหลด",
    "Male": "ชาย",
    "Female": "หญิง",
    "Gender": "เพศ",
    "ID Type is required": "ต้องระบุประเภท ID",
    "ID Document Language is required": "ต้องระบุภาษาของเอกสารประจำตัว",
    "ID Expiration Date is required": "ต้องระบุวันหมดอายุของ ID",
    "ID Issue Date is required": "ต้องระบุวันที่ออกบัตรประจำตัว",
    "State must be at least 3 characters": "รัฐต้องมีอักขระอย่างน้อย 3 ตัว",
    "ID Type": "ประเภทบัตรประจำตัว",
    "National Identity Card": "บัตรประจำตัวประชาชน",
    "Driver's License": "ใบขับขี่",
    "Passport": "หนังสือเดินทาง",
    "ID Document Language": "ภาษาเอกสารประจำตัว",
    "ID Number be at least 3 characters": "หมายเลขประจำตัวต้องมีอย่างน้อย 3 ตัวอักษร",
    "ID Issue Date": "วันที่ออกบัตรประจำตัว",
    "ID Expiration Date": "วันหมดอายุของไอดี",
    "You need to upload a photo of your document Please upload a png or jpeg, not a pdf.": "คุณต้องอัปโหลดรูปภาพเอกสารของคุณ \n โปรดอัปโหลด <span style='color: #1A6C99'>png หรือ jpeg ไม่ใช่ pdf</span>",
    "File size exceeds {n} MB.": "ขนาดไฟล์เกิน {n} MB",
    "Front side of ID": "ด้านหน้าไอดี",
    "Back side of ID": "ด้านหลังของบัตรประจำตัว",
    "Upload proof of address document": "อัพโหลดเอกสารหลักฐานที่อยู่",
    "Upload proof of address, which must not be older than 75 days.": "อัปโหลดหลักฐานที่อยู่ซึ่งต้องมีอายุไม่เกิน 75 วัน",
    "Acceptable forms of proof of address include a credit card statement, utility bill, bank statement or bank letter.": "แบบฟอร์มหลักฐานแสดงที่อยู่ที่เรายอมรับ ได้แก่ ใบแจ้งยอดบัตรเครดิต ใบเรียกเก็บเงินค่าสาธารณูปโภค ใบแจ้งยอดธนาคาร หรือจดหมายธนาคาร",
    "Address Document Type is required": "ต้องระบุประเภทเอกสารที่อยู่",
    "Document Issued by is required": "จำเป็นต้องมีเอกสารที่ออกโดย",
    "Document Issued Date is required": "ต้องระบุวันที่ออกเอกสาร",
    "Address Document Type": "ประเภทเอกสารที่อยู่",
    "Credit Card Statement": "ใบแจ้งยอดบัตรเครดิต",
    "Utility Bill": "ค่าสาธารณูปโภค",
    "Bank Statement": "รายการเงินฝากถอนในบัญชีเงินฝาก",
    "Bank Letter": "จดหมายธนาคาร",
    "Document Issued by": "เอกสารที่ออกโดย",
    "Document Issued Date": "วันที่ออกเอกสาร",
    "Please upload a legible document in png or jpeg format.": "โปรดอัปโหลดเอกสารที่อ่านง่าย <span style='color: #1A6C99'>ในรูปแบบ PNG หรือ JPEG</span>",
    "Submit for Verification": "ส่งเพื่อตรวจสอบ",
    "Your documents have been submitted for verification. Once verification has been approved, you can continue your purchase.": "เอกสารของคุณถูกส่งเพื่อการตรวจสอบแล้ว เมื่อการตรวจสอบได้รับการอนุมัติแล้ว คุณจะดำเนินการซื้อต่อได้",
    "You need more GYMNET to make a deposit in Single Pool": "คุณต้อง <b>{n} GYMNET เพิ่ม</b> เพื่อฝากเงินใน Single Pool",
    "You need more USDT to purchase a bundle": "คุณต้องมี <b>{n} USDT เพิ่ม</b>เพื่อซื้อชุดรวม",
    "Security Assurance": "ความปลอดภัย\nการรับประกัน",
    "Global Accessibility": "การเข้าถึง\nทั่วโลก",
    "Affiliate Earnings": "พันธมิตร\nรายได้",
    "Effortless Integration": "บูรณาการ\nได้อย่างง่ายดาย",
    "Cards": "การ์ด",
    "Overview": "ภาพรวม",
    "Information": "ข้อมูล",
    "Invoices": "ใบแจ้งหนี้",
    "KYC Status": "สถานะ KYC",
    "Try again": "ลองอีกครั้ง",
    "Apply here": "สมัครที่นี่",
    "Not verified": "ไม่ได้รับการยืนยัน",
    "Pending verification": "รอการตรวจสอบ",
    "Rejected": "ถูกปฏิเสธ",
    "Verified": "ตรวจสอบแล้ว",
    "Next service fee in": "ค่าบริการครั้งต่อไปใน",
    "No active membership": "ไม่มีการเป็นสมาชิกที่ใช้งานอยู่",
    "Card": "การ์ด",
    "Card number": "หมายเลขบัตร",
    "PIN Set Successfully": "ตั้งค่า PIN เรียบร้อยแล้ว",
    "Go to Dashboard": "ไปที่แผงควบคุม",
    "4-digit PIN": "รหัส PIN 4 หลัก",
    "Re-enter 4-digit PIN": "ป้อน PIN 4 หลักอีกครั้ง",
    "Set PIN": "ตั้งค่า PIN",
    "Your account is now verified and you can continue your purchase of the bundle.": "บัญชีของคุณได้รับการยืนยันแล้ว และคุณสามารถซื้อชุดรวมต่อไปได้",
    "Continue purchase": "ซื้อต่อ",
    "Your documents have been rejected from KYC provider.": "เอกสารของคุณถูกปฏิเสธจากผู้ให้บริการ KYC",
    "Please try again": "กรุณาลองอีกครั้ง",
    "Your KYC is approved": "KYC ของคุณได้รับการอนุมัติแล้ว",
    "Your KYC is rejected": "KYC ของคุณถูกปฏิเสธ",
    "File is required": "จำเป็นต้องมีไฟล์",
    "Seamless Spending with GYMSTREET Cards": "ใช้จ่ายอย่างราบรื่นด้วยบัตร GYMSTREET",
    "Our cards open a world of smooth transactions and rewarding opportunities for you.": "บัตรของเราเปิดโลกแห่งการทำธุรกรรมที่ราบรื่นและโอกาสที่คุ้มค่าสำหรับคุณ",
    "Card Types Tailored to Your Needs": "ประเภทบัตรที่ปรับแต่งตามความต้องการของคุณ",
    "Card design": "การออกแบบการ์ด",
    "Card information": "ข้อมูลบัตร",
    "Your go-to choice for daily transactions and affiliate commissions, offering practical benefits and reliable service within GYMSTREET and beyond.": "ทางเลือกของคุณสำหรับการทำธุรกรรมรายวันและค่าคอมมิชชั่นพันธมิตร นำเสนอผลประโยชน์เชิงปฏิบัติและบริการที่เชื่อถือได้ภายใน GYMSTREET และที่อื่น ๆ",
    "Elevate your financial experience with this premium option with expanded benefits, designed for those who seek luxury and exclusivity.": "ยกระดับประสบการณ์ทางการเงินของคุณด้วยตัวเลือกระดับพรีเมียมพร้อมสิทธิประโยชน์เพิ่มเติม ออกแบบมาสำหรับผู้ที่แสวงหาความหรูหราและความพิเศษเฉพาะตัว",
    "Cards come in Bundles": "การ์ดมาในชุด",
    "Explorer card and 2 parcels": "การ์ด Explorer และพัสดุ 2 ชิ้น",
    "Mastery card and 3 parcels": "การ์ด Mastery และพัสดุ 3 ชิ้น",
    "Getting Started with Your GYMSTREET Card Experience": "เริ่มต้นใช้งานบัตร GYMSTREET ของคุณ",
    "Start securely with our simple KYC process, ensuring a safe and personalized Card experience.": "เริ่มต้นอย่างปลอดภัยด้วยกระบวนการ KYC ที่เรียบง่ายของเรา เพื่อให้มั่นใจถึงประสบการณ์การใช้บัตรที่ปลอดภัยและเป็นส่วนตัว",
    "Deposit Tokens": "โทเค็นการฝาก",
    "Stake the required GYMNET tokens - 500 for Explorer or 1500 for Mastery - to initiate your application.": "วางเดิมพันโทเค็น GYMNET ที่จำเป็น - 500 สำหรับ Explorer หรือ 1,500 สำหรับ Mastery - เพื่อเริ่มต้นการสมัครของคุณ",
    "Order Your Card": "สั่งซื้อบัตรของคุณ",
    "Make your final decision on card type and place your order buying one of the 2 card bundles.": "ตัดสินใจขั้นสุดท้ายเกี่ยวกับประเภทการ์ดและสั่งซื้อชุดการ์ดชุดใดชุดหนึ่งจาก 2 ชุด",
    "Get Your Card": "รับบัตรของคุณ",
    "Anticipate the arrival of your new financial companion, securely shipped and ready to integrate into your life.": "คาดการณ์การมาถึงของเพื่อนทางการเงินคนใหม่ของคุณ จัดส่งอย่างปลอดภัยและพร้อมที่จะรวมเข้ากับชีวิตของคุณ",
    "Use Your Card": "ใช้บัตรของคุณ",
    "Begin making purchases with your card and start earning affiliate commissions and more.": "เริ่มซื้อสินค้าด้วยบัตรของคุณ และเริ่มรับค่าคอมมิชชั่นจากพันธมิตรและอื่นๆ อีกมากมาย",
    "Card Operation Fees": "ค่าธรรมเนียมการดำเนินการบัตร",
    "Fee Type": "ประเภทค่าธรรมเนียม",
    "Both Cards (Explorer & Mastery)": "การ์ดทั้งสองใบ (Explorer & Mastery)",
    "POS Transactions": "ธุรกรรม POS",
    "ATM Withdrawals": "การถอนเงินผ่านตู้เอทีเอ็ม",
    "Foreign Currency Conversion": "การแปลงสกุลเงินต่างประเทศ",
    "Card Replacement": "การเปลี่ยนบัตร",
    "Simple, Transparent Pricing": "ราคาที่เรียบง่ายและโปร่งใส",
    "Monthly Membership Fee": "ค่าสมาชิกรายเดือน",
    "Monthly Membership": "สมาชิกรายเดือน",
    "Get Your Card Now": "รับบัตรของคุณตอนนี้",
    "Choose the card that best suits your lifestyle. With straightforward fees, a seamless onboarding process, and an 11-level deep affiliate commission reward system, these cards are your partner in financial empowerment.": "เลือกบัตรที่เหมาะกับไลฟ์สไตล์ของคุณมากที่สุด ด้วยค่าธรรมเนียมที่ตรงไปตรงมา กระบวนการเริ่มต้นใช้งานที่ราบรื่น และระบบรางวัลคอมมิชชั่นพันธมิตรเชิงลึก 11 ระดับ การ์ดเหล่านี้คือพันธมิตรของคุณในการเพิ่มขีดความสามารถทางการเงิน",
    "How do I get a card?": "ฉันจะรับบัตรได้อย่างไร?",
    "Start by filling out the KYC form on our site. After we verify you, you'll need to deposit tokens for the card you want in Single Pool: 500 for Explorer or 1500 for Mastery. Then, you can order your card.": "เริ่มต้นด้วยการกรอกแบบฟอร์ม KYC บนเว็บไซต์ของเรา หลังจากที่เรายืนยันคุณแล้ว คุณจะต้องฝากโทเค็นสำหรับการ์ดที่คุณต้องการในรูปแบบ Single Pool: 500 สำหรับ Explorer หรือ 1500 สำหรับ Mastery จากนั้นคุณสามารถสั่งซื้อบัตรของคุณได้",
    "How many tokens do I need to deposit for each card?": "ฉันจำเป็นต้องฝากโทเค็นจำนวนเท่าใดสำหรับบัตรแต่ละใบ?",
    "You need 500 tokens for the Explorer card and 1500 tokens for the Mastery card.": "คุณต้องมี 500 โทเค็นสำหรับการ์ด Explorer และ 1,500 โทเค็นสำหรับการ์ด Mastery",
    "When will my card arrive?": "บัตรของฉันจะมาถึงเมื่อไร?",
    "After you order, your card will be sent out and should arrive quickly, so you can start using it soon.": "หลังจากที่คุณสั่งซื้อ บัตรของคุณจะถูกส่งออกไปและควรจะมาถึงอย่างรวดเร็ว เพื่อให้คุณสามารถเริ่มใช้งานได้เร็วๆ นี้",
    "Can I earn affiliate commissions with my card?": "ฉันสามารถรับค่าคอมมิชชั่นพันธมิตรด้วยบัตรของฉันได้หรือไม่?",
    "Yes, activate your card, and you can begin earning through our 11-level deep affiliate program. Check our website for how the commissions work.": "ใช่ เปิดใช้งานบัตรของคุณ แล้วคุณสามารถเริ่มสร้างรายได้ผ่านโปรแกรมพันธมิตรเชิงลึก 11 ระดับของเรา ตรวจสอบเว็บไซต์ของเราว่าค่าคอมมิชชั่นทำงานอย่างไร",
    "Total Value At Time of Deposit": "มูลค่ารวม ณ เวลาที่ฝาก",
    "Current Value": "มูลค่าปัจจุบัน",
    "My claimed GYMNET rewards": "รางวัล GYMNET ที่ฉันอ้างสิทธิ์",
    "My pending GYMNET rewards": "รางวัล GYMNET ที่รอดำเนินการของฉัน",
    "Deposit": "เงินฝาก",
    "Connected": "เชื่อมต่อแล้ว",
    "No deposit fee for burn": "ไม่มีค่าธรรมเนียมการฝากสำหรับการเผา",
    "Min amount": "จำนวนเงินขั้นต่ำ",
    "Total GYMNET locked": "ล็อค GYMNET ทั้งหมด",
    "APR": "เมษายน",
    "Daily APR": "เมษายนรายวัน",
    "Total own claimed": "อ้างสิทธิ์เองทั้งหมด",
    "Reward per block": "รางวัลต่อบล็อก",
    "My Deposit": "เงินฝากของฉัน",
    "Locked period until Dec 2027": "ล็อคระยะเวลาจนถึงธันวาคม 2027",
    "With Locking Period": "ด้วยระยะเวลาล็อค",
    "Total Gymnet Deposit": "ยอดเงินฝาก Gymnet ทั้งหมด",
    "Gymnet Rewards": "รางวัลยิมเน็ต",
    "Total Gymnet earned": "รวม Gymnet ที่ได้รับ",
    "Active holdings": "การถือครองที่ใช้งานอยู่",
    "With locking period": "โดยมีช่วงล็อค",
    "Withdrawn holdings": "ถอนการถือครอง",
    "Locked": "ล็อคแล้ว",
    "Unlocked": "ปลดล็อคแล้ว",
    "Deposit amount": "จำนวนเงินฝาก",
    "Claimed rewards": "รับรางวัลแล้ว",
    "Start date": "วันที่เริ่มต้น",
    "End date": "วันที่สิ้นสุด",
    "Withdraw": "ถอน",
    "First name": "ชื่อจริง",
    "Last name": "นามสกุล",
    "Address": "ที่อยู่",
    "State": "สถานะ",
    "Phone number": "หมายเลขโทรศัพท์",
    "Birth Date": "วันที่เกิด",
    "Submit & Continue": "ส่งและดำเนินการต่อ",
    "ID Number": "หมายเลขประจำตัวประชาชน",
    "ID Issued By": "บัตรประจำตัวที่ออกโดย",
    "Proof of address document": "เอกสารหลักฐานที่อยู่",
    "Proof of address document (optional)": "เอกสารหลักฐานที่อยู่ (ไม่บังคับ)",
    "Statistics": "สถิติ",
    "Personal Information": "ข้อมูลส่วนบุคคล",
    "First name is required": "จำเป็นต้องระบุชื่อ",
    "Last name is required": "ต้องระบุนามสกุล",
    "Address is required": "ต้องระบุที่อยู่",
    "Please enter your email address": "กรุณากรอกอีเมลของคุณ",
    "Phone number is required": "ต้องระบุหมายเลขโทรศัพท์",
    "Date of birth is required": "ต้องระบุวันเกิด",
    "ID Number is required": "ต้องระบุหมายเลขประจำตัว",
    "ID Issued by is required": "จำเป็นต้องมีบัตรประจำตัวที่ออกโดย",
    "Select asset to auto swap and stake": "เลือกสินทรัพย์เพื่อสลับอัตโนมัติและเดิมพัน",
    "Giving swap allowance": "ให้เบี้ยเลี้ยงการแลกเปลี่ยน",
    "Swap allowance given": "เบี้ยเลี้ยงการแลกเปลี่ยนที่ได้รับ",
    "Swapping tokens to GYMNET": "การสลับโทเค็นเป็น GYMNET",
    "Tokens swapped to GYMNET": "โทเค็นเปลี่ยนเป็น GYMNET",
    "Giving stake allowance": "มอบเงินส่วนได้ส่วนเสีย",
    "Stake allowance given": "เงินสงเคราะห์ที่ได้รับ",
    "Staking to single pool": "ปักหลักอยู่ในสระเดียว",
    "Staked to single pool": "ปักหลักอยู่ที่สระเดียว",
    "1. Swap allowance (Only for USDT)": "1. ค่าเผื่อการแลกเปลี่ยน (สำหรับ USDT เท่านั้น)",
    "2. Swap to GYMNET": "2. สลับไปที่ GYMNET",
    "3. Stake allowance": "3. เงินสงเคราะห์",
    "4. Stake": "4. เดิมพัน",
    "Actual balance:": "ยอดคงเหลือตามจริง:",
    "The transaction gas fee is reserved from the actual BNB balance.": "ค่าธรรมเนียมการทำธุรกรรมจะถูกสงวนไว้จากยอดคงเหลือ BNB จริง",
    "Insufficient balance to make a swap": "ยอดคงเหลือไม่เพียงพอที่จะทำการแลกเปลี่ยน",
    "Transactions gas fee is considered in BNB available balance": "ค่าธรรมเนียมการทำธุรกรรมจะคิดเป็นยอดคงเหลือของ BNB",
    "We will ask for allowance and try to swap your tokens to GYMNET, and after, stake them in single pool.": "เราจะขอเบี้ยเลี้ยงและพยายามสลับโทเค็นของคุณเป็น GYMNET จากนั้นจึงเดิมพันลงในพูลเดียว",
    "Auto swap and stake": "สลับอัตโนมัติและเดิมพัน",
    "Continue transactions": "ทำธุรกรรมต่อ",
    "Pause": "หยุดชั่วคราว",
    "Swap & Stake": "สลับและเดิมพัน",
    "Topup": "เติม",
    "Proceed anyways": "ดำเนินการต่อเลย",
    "Explorer Card": "การ์ดExplorer",
    "Mastery Card": "การ์ดMastery",
    "Monthly spending max": "การใช้จ่ายรายเดือนสูงสุด",
    "Monthly Balance limit": "วงเงินยอดคงเหลือรายเดือน",
    "Go to Cards": "ไปที่การ์ด",
    "Contact Support": "ติดต่อฝ่ายสนับสนุน",
    "Deposit Fee": "ค่าธรรมเนียมการฝากเงิน",
    "Switch to 'Utility' to view the price for each product with USDT & Utility. To partially pay for a product using Utility, you need to have enough USDT and Utility in your balance.": "สลับไปที่ 'Utility' เพื่อดูราคาของแต่ละผลิตภัณฑ์ด้วย USDT และ Utility หากต้องการชำระค่าสินค้าบางส่วนโดยใช้ Utility คุณจำเป็นต้องมี USDT และ Utility ในยอดคงเหลือของคุณเพียงพอ",
    "Deposit with locking period": "ฝากแบบมีระยะเวลาล็อค",
    "The state must be in the form of a two-letter abbreviation in uppercase.": "รัฐจะต้องอยู่ในรูปแบบของตัวย่อสองตัวเป็นตัวพิมพ์ใหญ่",
    "You need to upload a photo in JPEG or PNG format, not a PDF. The document can be in either English or Spanish.": "คุณต้องอัปโหลดภาพถ่ายในรูปแบบ <span style='color: #1A6C99'>jpeg</span> หรือ <span style='color: #1A6C99'>png</span> ไม่ใช่ pdf \n เอกสารอาจเป็น <span style='color: #1A6C99'>อังกฤษ</span> หรือ <span style='color: #1A6C99'>สเปน</span>",
    "You need to upload a legible document in jpeg or png format, not a pdf. The document can be in either English or Spanish.": "คุณต้องอัปโหลดเอกสารที่อ่านได้ชัดเจนในรูปแบบ <span style='color: #1A6C99'>jpeg</span> หรือ <span style='color: #1A6C99'>png</span> ไม่ใช่ pdf \n เอกสารอาจเป็น <span style='color: #1A6C99'>อังกฤษ</span> หรือ <span style='color: #1A6C99'>สเปน</span>",
    "Commissions are paid out using a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "คอมมิชชั่นจะได้รับการชำระโดยใช้อัตราส่วน 70/30 โดย 70% จะเป็น stablecoin และ 30% จะเป็นโทเค็น GYMNET",
    "This policy is effective as of September 25, 2023.": "นโยบายนี้มีผลตั้งแต่วันที่ 25 กันยายน 2023",
    "Required turnover": "มูลค่าการซื้อขายที่จำเป็น",
    "Switch to TOKEN to view the price for each product with USDT & TOKEN. To partially pay for a product using TOKEN, you need to have enough USDT and TOKEN in your balance.": "สลับไปที่ '{TOKEN}' เพื่อดูราคาของแต่ละผลิตภัณฑ์ด้วย USDT และ {TOKEN} หากต้องการชำระค่าสินค้าบางส่วนโดยใช้ {TOKEN} คุณจำเป็นต้องมี USDT และ {TOKEN} ในยอดคงเหลือของคุณเพียงพอ",
    "Alternatively, you can use your CURRENCY balance to cover the gas fee.": "หรือคุณสามารถใช้ยอดคงเหลือ {currency_yg} ของคุณเพื่อชำระค่าน้ำมันได้",
    "Insufficient funds: to continue with payment use Utility or Gymnet balance": "เงินไม่เพียงพอ: เพื่อดำเนินการชำระเงินต่อไป ให้ใช้ยอดคงเหลือ Utility หรือ Gymnet",
    "Use": "ใช้",
    "Use Utility or Gymnet": "ใช้ Utility หรือ Gymnet",
    "Achieved PRICE turnover": "บรรลุมูลค่าการซื้อขาย ${price}",
    "Your current milestone": "เหตุการณ์สำคัญในปัจจุบันของคุณ",
    "Reward History": "ประวัติรางวัล",
    "Leading Direct Partner": "พันธมิตรโดยตรงชั้นนำ",
    "Remaining Direct Partners": "พันธมิตรโดยตรงที่เหลืออยู่",
    "Bonus Split": "การแบ่งโบนัส",
    "Monthly Countdown": "นับถอยหลังรายเดือน",
    "No direct partners": "ไม่มีพันธมิตรโดยตรง",
    "To receive the reward, you must have an active product autoship.": "หากต้องการรับรางวัล คุณต้องมีการจัดส่งสินค้าอัตโนมัติที่ใช้งานอยู่",
    "This incentive is subject to the 50% Turnover Rule.": "แรงจูงใจดังกล่าวอยู่ภายใต้กฎเกณฑ์ยอดขาย 50%",
    "Income From Product Authoship": "รายได้จากการอนุญาตผลิตภัณฑ์",
    "Your documents have been submitted for verification. The bank may take up to 3 business days to review your application. Once approved, you can continue with your purchase.": "เอกสารของคุณถูกส่งเพื่อการตรวจสอบแล้ว ธนาคารอาจใช้เวลาสูงสุด 3 วันทำการในการตรวจสอบใบสมัครของคุณ เมื่อได้รับการอนุมัติแล้ว คุณจะดำเนินการซื้อต่อได้",
    "To purchase the card, you need to agree to the T&C and complete the KYC verification process.": "หากต้องการซื้อบัตร คุณต้องยอมรับข้อกำหนดและเงื่อนไขและดำเนินการตามกระบวนการตรวจสอบ KYC ให้เสร็จสิ้น",
    "Only Latin letters are allowed": "อนุญาตให้ใช้เฉพาะตัวอักษรละตินเท่านั้น",
    "First name must be at least 3 characters": "ชื่อต้องมีอย่างน้อย 3 ตัวอักษร",
    "First name should not exceed 10 characters": "ชื่อไม่ควรเกิน 10 ตัวอักษร",
    "Should be only letters": "ควรเป็นเพียงตัวอักษรเท่านั้น",
    "Address must be at least 3 characters": "ที่อยู่ต้องมีอย่างน้อย 3 ตัวอักษร",
    "Only latin letters, numbers and special characters are allowed": "อนุญาตให้ใช้เฉพาะตัวอักษรละติน ตัวเลข และอักขระพิเศษเท่านั้น",
    "Email is not valid": "อีเมลไม่ถูกต้อง",
    "Zip must contain only numbers with min length of 3": "รหัสไปรษณีย์ต้องมีเฉพาะตัวเลขที่มีความยาวขั้นต่ำคือ 3",
    "The phone number must be at least 6 digits": "หมายเลขโทรศัพท์ต้องมีอย่างน้อย 6 หลัก",
    "The phone number must not exceed 12 digits": "หมายเลขโทรศัพท์ต้องไม่เกิน 12 หลัก",
    "Phone number should contain only numbers": "หมายเลขโทรศัพท์ควรมีเฉพาะตัวเลขเท่านั้น",
    "ID Number must have at least 3 characters": "หมายเลขประจำตัวต้องมีอย่างน้อย 3 ตัวอักษร",
    "ID Issued by must have at least 3 characters": "ID ที่ออกโดยต้องมีอักขระอย่างน้อย 3 ตัว",
    "ID Issued by should not exceed 191 characters": "ID ที่ออกโดยไม่ควรเกิน 191 ตัวอักษร",
    "Last name must be at least 3 characters": "นามสกุลต้องมีอย่างน้อย 3 ตัวอักษร",
    "State is required": "ต้องระบุรัฐ",
    "The incentive countdown starts at the beginning of each month and ends at the end of the month. If you do not qualify for the bonus within that month, you will need to start again in the next month.": "การนับถอยหลังสิ่งจูงใจเริ่มตั้งแต่ต้นเดือนและสิ้นสุดตอนสิ้นเดือน หากคุณไม่มีคุณสมบัติรับโบนัสภายในเดือนนั้น คุณจะต้องเริ่มใหม่อีกครั้งในเดือนถัดไป",
    "Activated": "เปิดใช้งานแล้ว",
    "Deposit & fee commission": "ค่าคอมมิชชั่นการฝากและค่าธรรมเนียม",
    "Web3 wallet not connected. Please connect to proceed.": "ไม่ได้เชื่อมต่อกระเป๋าเงิน Web3 กรุณาเชื่อมต่อเพื่อดำเนินการต่อ",
    "Total investors profit": "กำไรของนักลงทุนทั้งหมด",
    "Commissions from vault investors": "ค่าคอมมิชชั่นจากนักลงทุนในห้องนิรภัย",
    "Please Claim your rewards from to activate this section.": "โปรดรับรางวัลของคุณจาก {n} เพื่อเปิดใช้งานส่วนนี้",
    "Or use Button Below": "หรือใช้ปุ่มด้านล่าง",
    "Partner Activity": "กิจกรรมพันธมิตร",
    "Pending Commissions": "ค่าคอมมิชชั่นที่รอดำเนินการ",
    "Binary Turnover": "มูลค่าการซื้อขายไบนารี่",
    "Unilevel Turnover": "มูลค่าการซื้อขายระดับเดียว",
    "Right Direct Partners": "พันธมิตรโดยตรงที่ถูกต้อง",
    "Left Direct Partners": "พันธมิตรทางซ้ายโดยตรง",
    "50% Compensation:": "ค่าตอบแทน 50%:",
    "When you reach the next rank, you will receive 50% of the lost bonus from the previous rank.": "เมื่อคุณไปถึงอันดับถัดไป คุณจะได้รับ 50% ของโบนัสที่เสียไปจากอันดับก่อนหน้า",
    "Please note that username can be changed only once in 6 months.": "โปรดทราบว่าชื่อผู้ใช้สามารถเปลี่ยนแปลงได้เพียงครั้งเดียวใน 6 เดือน",
    "Binary Turnover in weak leg": "การหมุนเวียนไบนารีในขาที่อ่อนแอ",
    "Unilevel turnover requirement": "ข้อกำหนดการหมุนเวียนระดับเดียว",
    "Connect a WEB3 wallet such as Metamask or Trustwallet and pay with {currency}. This process requires a small BNB fee ($0.30) to handle transaction costs!": "เชื่อมต่อกระเป๋าเงิน WEB3 เช่น Metamask หรือ Trustwallet แล้วชำระเงินด้วย {currency} กระบวนการนี้ต้องใช้ค่าธรรมเนียม BNB เล็กน้อย ($0.30) เพื่อจัดการต้นทุนธุรกรรม!",
    "Use your account's wallet balance to purchase the product.": "ใช้ยอดคงเหลือกระเป๋าสตางค์ในบัญชีของคุณเพื่อซื้อผลิตภัณฑ์",
    "Seems like your crypto hasn't arrived yet": "ดูเหมือนว่า crypto ของคุณยังมาไม่ถึง",
    "Wait more": "รออีก",
    "Or topup with": "หรือเติมเงินด้วย",
    "Connected to web3": "เชื่อมต่อกับเว็บ3",
    "Reset": "รีเซ็ต",
    "Top Up Successful": "เติมเงินสำเร็จ",
    "Top Up Successful. Card balance will be updated soon": "เติมเงินสำเร็จ ยอดคงเหลือของบัตรจะได้รับการอัปเดตเร็วๆ นี้",
    "Please activate your card and top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "กรุณาเปิดใช้งานบัตรของคุณและเติมเงินให้ครอบคลุมค่าธรรมเนียมรายเดือน หากไม่มีการอัปเดตยอดคงเหลือ บัตรของคุณจะถูกปิดใช้งานและคุณจะต้องซื้อบัตรใหม่",
    "PERCENTAGE allocation from Global Pool": "การจัดสรร {perc}% จาก Global Pool",
    "You need to reach rank RANK_NAME to participate in this pool.": "คุณต้องไปถึงอันดับ \"{rank_name}\" เพื่อเข้าร่วมในกลุ่มนี้",
    "Global Pool": "สระน้ำระดับโลก",
    "Next Distribution In:": "จัดจำหน่ายครั้งต่อไปใน:",
    "50% Rule": "กฎ 50%",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%": "ส่วนแบ่งสูงสุดที่แต่ละโดยตรงสามารถนำไปสู่มูลค่าการซื้อขายที่ต้องการได้สูงถึง 50%",
    "Sub Pools": "พูลย่อย",
    "Congratulations! You have qualified for a share of the Global Pool.": "ยินดีด้วย! คุณมีคุณสมบัติสำหรับส่วนแบ่งของ Global Pool",
    "You will receive your share once the countdown is complete.": "คุณจะได้รับส่วนแบ่งของคุณเมื่อการนับถอยหลังเสร็จสิ้น",
    "Please top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "กรุณาเติมเงินให้ครอบคลุมค่าบริการรายเดือน หากยอดคงเหลือไม่อัปเดต บัตรของคุณจะถูกปิดใช้งานและคุณจะต้องซื้อบัตรใหม่",
    "You need to deposit VALUE worth of GYMNET to upgrade your weekly binary cap": "คุณต้องฝากเงิน GYMNET มูลค่า {dvalue} เพื่ออัปเกรดฐานไบนารีรายสัปดาห์ของคุณ",
    "Binary Max Weekly Payout": "การจ่ายเงินไบนารีสูงสุดรายสัปดาห์",
    "Required Single Pool Deposit To Upgrade": "ต้องมีเงินฝากพูลเดี่ยวเพื่ออัปเกรด",
    "Upgrade Binary Max Weekly Payout": "อัปเกรดการจ่ายเงินไบนารีสูงสุดรายสัปดาห์",
    "Weekly Cap Upgraded": "อัปเกรดขีดจำกัดรายสัปดาห์แล้ว",
    "Your 8 months period for earning token rewards in Filecoin has officially concluded. Thank you for your participation. Enjoy your FIL earnings.": "ระยะเวลา 8 เดือนของคุณในการรับรางวัลโทเค็นใน Filecoin ได้สิ้นสุดลงอย่างเป็นทางการแล้ว ขอบคุณสำหรับการมีส่วนร่วมของคุณ เพลิดเพลินกับรายได้ FIL ของคุณ",
    "As previously announced Filecoin will be replaced by Moonberg token, which will be distributed for a period of 24 months. The Moonberg launch is expected for August 2024 and the distribution will start one week after that.": "ตามที่ประกาศไว้ก่อนหน้านี้ Filecoin จะถูกแทนที่ด้วยโทเค็น Moonberg ซึ่งจะแจกจ่ายเป็นระยะเวลา 24 เดือน คาดว่าการเปิดตัว Moonberg ในเดือนสิงหาคม 2567 และการจำหน่ายจะเริ่มหนึ่งสัปดาห์หลังจากนั้น",
    "Username must not exceed 255 characters": "ชื่อผู้ใช้ต้องไม่เกิน 255 ตัวอักษร",
    "Upgrade": "อัปเกรด",
    "Product Autoship Monthly": "สินค้าจัดส่งอัตโนมัติรายเดือน",
    "Product Autoship Yearly": "สินค้าจัดส่งอัตโนมัติรายปี",
    "Product Owner": "เจ้าของสินค้า",
    "Myself": "ตัวฉันเอง",
    "Another user": "ผู้ใช้รายอื่น",
    "Payment": "การชำระเงิน",
    "PAP Balance": "ยอดคงเหลือ PAP",
    "Purchased By": "ซื้อโดย",
    "Purchased For": "ซื้อสำหรับ",
    "Single parcel": "พัสดุชิ้นเดียว",
    "Single miner": "นักขุดรายเดียว",
    "completed": "สมบูรณ์",
    "pending": "รอดำเนินการ",
    "expired": "หมดอายุ",

    "Select {n} blockchain to send the transaction": "เลือก {n} บล็อคเชนเพื่อส่งธุรกรรม",
    "We recommend waiting to claim your rewards when the gas fee is lower than the amount you are claiming.": "เราขอแนะนำให้รอรับรางวัลเมื่อค่าธรรมเนียมก๊าซต่ำกว่าจำนวนเงินที่คุณเรียกร้อง",
    "Total Value Of Purchases": "มูลค่ารวมของการซื้อ",
    "The total value of all purchases excluding purchases related to Card Bundles, granted products and product autoship subscriptions.": "มูลค่ารวมของการซื้อทั้งหมด ไม่รวมการซื้อที่เกี่ยวข้องกับชุดการ์ด ผลิตภัณฑ์ที่ได้รับ และการสมัครรับสินค้าอัตโนมัติ",
    "Total Receivable Tokens": "จำนวนโทเค็นที่รับได้ทั้งหมด",
    "The number of Moonberg tokens you will receive over the next 24 months.": "จำนวนโทเค็น Moonberg ที่คุณจะได้รับในอีก 24 เดือนข้างหน้า",
    "Realtime ROI": "ผลตอบแทนจากการลงทุนแบบเรียลไทม์",
    "Current Return on Investment (ROI) based on the real-time price of the Moonberg token.": "ผลตอบแทนจากการลงทุน (ROI) ในปัจจุบันขึ้นอยู่กับราคาแบบเรียลไทม์ของโทเค็น Moonberg",
    "Token Calculation Breakdown": "การแยกย่อยการคำนวณโทเค็น",
    "{n}% of your total purchase value is allocated for purchasing {asset} tokens": "{n}% ของมูลค่าการซื้อทั้งหมดของคุณจะถูกจัดสรรสำหรับการซื้อโทเค็น {asset}",
    "Moonberg tokens will be distributed upon the official release of the Moonberg token.": "โทเค็น Moonberg จะถูกแจกจ่ายเมื่อมีการเปิดตัวโทเค็น Moonberg อย่างเป็นทางการ",
    "Moonberg Allocation": "การจัดสรร Moonberg",
    "BTC Balance": "ยอดคงเหลือ BTC",
    "The funds will be transferred via the BEP-20 BNB network.": "เงินจะถูกโอนผ่านเครือข่าย BNB BEP-20",
    "A bundle with minting power will generate BTC token rewards, which will be distributed every Monday.": "แพ็คเกจที่มาพร้อมพลังการผลิตจะสร้างรางวัลโทเค็น BTC ซึ่งจะแจกจ่ายทุกวันจันทร์",
    "Weekly rewards expected": "คาดว่าจะได้รับรางวัลรายสัปดาห์",
    "Direct Sales Incentive": "แรงจูงใจในการขายตรง",
    "You are about to activate your affiliate referral link.": "คุณกำลังจะเปิดใช้งานลิงก์อ้างอิงพันธมิตรของคุณ",
    "Upon activation, a period of free Product Autoship will be initiated starting from the date of your registration.": "เมื่อเปิดใช้งานแล้ว ช่วงเวลาของการส่งสินค้าอัตโนมัติฟรีจะเริ่มต้นตั้งแต่วันที่คุณลงทะเบียน",
    "If you confirm the activation, a mandatory monthly Product Autoship will be required to be eligible to receive commissions.": "หากคุณยืนยันการเปิดใช้งาน จะต้องส่งสินค้าอัตโนมัติรายเดือนตามข้อกำหนดเพื่อให้มีสิทธิ์ได้รับคอมมิชชัน",
    "Keep your product autoship active to maintain your commission eligibility for commission ranks Platinum and above.": "รักษาให้ผลิตภัณฑ์ของคุณเปิดใช้งานอัตโนมัติเพื่อรักษาสิทธิ์ในการรับคอมมิชชันในระดับ Platinum ขึ้นไป",
    "Buy Product Autoship": "ซื้อสินค้าอัตโนมัติ",
    "Your current rank is achieved through qualification.": "ตำแหน่งปัจจุบันของคุณได้รับมาจากการผ่านคุณสมบัติ",
    "Your commission rank is determined by your actual rank or a super bundle purchase—whichever is higher will be considered.": "ตำแหน่งคอมมิชชันของคุณจะถูกกำหนดโดยตำแหน่งจริงของคุณหรือการซื้อซูเปอร์บันเดิล—อันไหนก็ตามที่สูงกว่าจะได้รับการพิจารณา",
    "Your card has been deactivated due to an unpaid monthly subscription. For assistance or any questions, please reach out to our customer support team.": "บัตร {card_name} ของคุณถูกปิดใช้งานเนื่องจากไม่ได้ชำระค่าสมัครสมาชิกรายเดือน หากต้องการความช่วยเหลือหรือมีคำถามใดๆ โปรดติดต่อทีมสนับสนุนลูกค้าของเรา",

    "Subject": "เรื่อง",
    "topup": "เติมเงิน",
    "withdraw": "ถอนเงิน",
    "failed": "ล้มเหลว",
    "3rd Anniversary": "ครบรอบ 3 ปี",
    "3rd Anniversary Promo": "โปรโมชั่นครบรอบ 3 ปี",
    "Your current bonus amount": "จำนวนโบนัสปัจจุบันของคุณ",
    "All products are eligible for the incentive, except for Product Autoship. Rewards will be distributed on November 1, 2024, and the bonus will be deposited directly into your internal wallet.": "ผลิตภัณฑ์ทั้งหมดมีสิทธิ์ได้รับสิทธิพิเศษ ยกเว้นการส่งสินค้าอัตโนมัติ รางวัลจะแจกในวันที่ 1 พฤศจิกายน 2024 และโบนัสจะฝากเข้าในกระเป๋าเงินภายในของคุณโดยตรง",























    // Affility translations
    "close": "ปิด",
    "cancel": "ยกเลิก",
    "yes_i_confirm": "ใช่ ฉันยืนยัน",
    "something_went_wrong_try_again": "บางอย่างผิดพลาด. กรุณาลองอีกครั้ง",
    "or": "หรือ",
    "success": "สำเร็จ",
    "ok": "ตกลง",
    "retry": "ลองอีกครั้ง",
    "home": "บ้าน",
    "log_in": "เข้าสู่ระบบ",
    "join_now": "เข้าร่วมเดี๋ยวนี้",
    "toggle_notifications": "สลับการแจ้งเตือน",
    "active": "คล่องแคล่ว",
    "inactive": "ไม่ได้ใช้งาน",
    "product_autoship": "สินค้าออโต้ชิป",
    "balances": "ยอดคงเหลือ",
    "contract_addresses": "ที่อยู่ตามสัญญา",
    "address_add_to_wallet_success": "เพิ่มที่อยู่ในกระเป๋าเงินของคุณเรียบร้อยแล้ว",
    "language": "ภาษา",
    "choose_language": "เลือกภาษา",
    "profile": "ประวัติโดยย่อ",
    "settings": "การตั้งค่า",
    "learn": "เรียนรู้",
    "help_center": "ศูนย์ช่วยเหลือ",
    "log_out": "ออกจากระบบ",
    "theme": "ธีม",
    "back": "กลับ",
    "forward": "ซึ่งไปข้างหน้า",
    "previous": "ก่อนหน้า",
    "next": "ต่อไป",
    "jump_to_top": "ข้ามไปด้านบน",
    "load_more": "โหลดเพิ่มเติม",
    "proceed": "ดำเนินการ",
    "notifications": "การแจ้งเตือน",
    "notifications_loading_error_description": "ตรวจสอบให้แน่ใจว่าอุปกรณ์ของคุณเชื่อมต่อกับอินเทอร์เน็ตหรือลองรีสตาร์ทเราเตอร์หรือโมเด็มของคุณ",
    "notifications_empty_title": "รายการการแจ้งเตือนว่างเปล่า",
    "notifications_empty_description": "ติดตามการอัปเดตและการเตือนความจำทั้งหมดของคุณไว้ในที่เดียวที่สะดวกสบายด้วยคุณสมบัติการแจ้งเตือนของเรา",
    "email_verification_sent_title": "การยืนยันอีเมล",
    "email_verification_sent_description": "เราได้ส่งอีเมลไปที่ {email} พร้อมลิงก์เพื่อดำเนินการลงทะเบียนให้เสร็จสิ้น",
    "email_verification_required": "จำเป็นต้องมีการยืนยันอีเมล",
    "email_verification_confirm": "โปรดยืนยันอีเมลของคุณเพื่อดำเนินการต่อ",
    "email_verification_verify": "ยืนยันอีเมลของคุณเพื่อดำเนินการต่อ",
    "new_email": "อีเมลใหม่",
    "enter_new_email": "ป้อนอีเมลใหม่",
    "change_email": "เปลี่ยนอีเมล",
    "resend_email": "ส่งอีเมลอีกครั้ง",
    "upgrade_to_affiliate": "อัปเกรดเป็นพันธมิตร",
    "upgrade_to_affiliate_success": "คุณได้อัปเกรดเป็น Affiliate สำเร็จแล้ว",
    "become_affiliate": "มาเป็นพันธมิตร",
    "upgrade_to_affiliate_description_free_autoship": "คุณกำลังจะเปิดใช้งานลิงค์อ้างอิงพันธมิตรของคุณ เมื่อเปิดใช้งาน ระยะเวลาของ Product Autoship ฟรีจะเริ่มนับจากวันที่คุณลงทะเบียน",
    "upgrade_to_affiliate_description_mandatory_autoship": "คุณกำลังจะเปิดใช้งานลิงค์อ้างอิงพันธมิตรของคุณ หากคุณยืนยันการเปิดใช้งาน จะต้องมีการบังคับส่งสินค้าอัตโนมัติทุกเดือนจึงจะมีสิทธิ์รับค่าคอมมิชชั่น",
    "t&c": "ข้อกำหนดและเงื่อนไข",
    "accept_t&c": "ยอมรับและปิด",
    "affiliate_t&c": "ข้อกำหนดและเงื่อนไขของพันธมิตร",
    "cards_t&c": "ข้อกำหนดและเงื่อนไขการใช้บัตร",
    "i_have_read_and_accept_the_{prop}": "ฉันได้อ่านและยอมรับ {prop}",
    "switch_language": "สลับภาษา",
    "switch_theme": "สลับธีม",
    "enjoy_more_benefit_more": "สนุกมากขึ้น! ได้รับประโยชน์มากขึ้น!",
    "buy_products_at_least_${n}": "ซื้อผลิตภัณฑ์อย่างน้อย ${n}",
    "go_to_products": "ไปที่ผลิตภัณฑ์",
    "free_autoship": "ฟรีค่าจัดส่งผลิตภัณฑ์อัตโนมัติสำหรับ Platinum ขึ้นไป",
    "{n}%_binary_commission": "{n}% ค่าคอมมิชชันไบนารี",
    "rank": "อันดับ",
    "role": "บทบาท",
    "bundle": "มัด",
    "current_rank": "อันดับปัจจุบัน",
    "current_role": "บทบาทปัจจุบัน",
    "customer": "ลูกค้า",
    "get_your_bundle": "รับชุดของคุณ",
    "nft_turbo_expires_in": "ส่วนลดพิเศษ Super Bundle จะหมดอายุภายใน",
    "left_leg_link": "ลิงค์ขาซ้าย",
    "right_leg_link": "ลิงค์ขาขวา",
    "sponsor_id": "ID ผู้สนับสนุน",
    "total_income": "รายได้ทั้งหมด",
    "current_cv_balance": "ยอดคงเหลือ CV ปัจจุบัน",
    "progress_until_rank_{n}": "ความก้าวหน้าจนถึงอันดับ {n}",
    "to_reach_rank_{n}_you_need": "เพื่อไปให้ถึงอันดับ {n} ที่คุณต้องการ",
    "nft_own_purchases": "การซื้อ NFT ของตัวเอง",
    "direct_partner_own_purchases": "การซื้อของพันธมิตรโดยตรง",
    "team_turnover": "การหมุนเวียนของทีม",
    "required_qualified_partners": "พันธมิตรที่ผ่านการรับรองที่จำเป็น",
    "see_details": "ดูรายละเอียด",
    "soon": "เร็วๆ นี้",
    "new": "ใหม่",
    "promos": "โปรโมชั่น",
    "coming_soon": "เร็วๆ นี้",
    "team_statistics": "สถิติทีม",
    "all_time": "ตลอดเวลา",
    "weekly": "รายสัปดาห์",
    "monthly": "รายเดือน",
    "yearly": "เป็นประจำทุกปี",
    "left_leg": "ขาซ้าย",
    "right_leg": "ขาขวา",
    "all_partners": "พันธมิตรทั้งหมด",
    "direct_partners": "พันธมิตรโดยตรง",
    "all_active_partners": "พันธมิตรที่ใช้งานอยู่ทั้งหมด",
    "all_inactive_partners": "พันธมิตรที่ไม่ใช้งานทั้งหมด",
    "become_affiliate_partner": "มาเป็นพันธมิตรพันธมิตร",
    "gain_fresh_perspectives": "รับมุมมองใหม่และสิทธิประโยชน์พิเศษ",
    "autoship_required_to_be_affiliate": "Autoship ผลิตภัณฑ์รายเดือนจะต้องเป็นพันธมิตร Affiliate และรับค่าคอมมิชชั่น",
    "for_limited_time_only": "สำหรับระยะเวลาที่จำกัดเท่านั้น",
    "placement_in_unilevel": "ตำแหน่งใน Unilevel",
    "placement_in_binary": "ตำแหน่งในไบนารี่",
    "cv_points": "คะแนน CV",
    "matching_bonuses": "โบนัสที่ตรงกัน",
    "leadership_incentives": "แรงจูงใจในการเป็นผู้นำ",
    "autoship_incentive": "แรงจูงใจในการจัดส่งผลิตภัณฑ์อัตโนมัติ",
    "binary_tree_statistics": "สถิติต้นไม้ไบนารี่",
    "unilevel_tree_statistics": "สถิติต้นไม้ระดับเดียว",
    "active_partners": "คู่ค้าที่ใช้งานอยู่",
    "inactive_partners": "คู่ค้าที่ไม่ได้ใช้งาน",
    "The minimum amount should be equal to or greater than NUM GYMNET": "จำนวนขั้นต่ำควรเท่ากับหรือมากกว่า {num} GYMNET",
    "select_the_team": "เลือกทีม",
    "new_users_will_be_placed_under_username_in_the_left_team": "ผู้ใช้รายใหม่จะถูกวางไว้ภายใต้ {USERNAME} ในทีม {LEG}",
    "done": "เสร็จเรียบร้อย",
    "right": "ขวา",
    "left": "ซ้าย",
};
