export default {
    "Learn how it works": "Học cách sử dụng",
    "Buy parcel": "Mua lô",
    "Wallets": "Ví",
    "To GymNetwork": "Đến GymNetwork",
    "Connect Wallet": "Kết nối ví",
    "Pending rewards": "Phần thưởng chờ nhận",
    "Coming Soon": "Sắp diễn ra",
    "Claim": "Nhận",
    "Inventory": "Kho",
    "Lands": "Lô đất",
    "Miners": "Thợ đào",
    "Attach": "Gán",
    "Attach to parcel": "Gán vào lô",
    "Investment": "Khoản đầu tư",
    "Wallet": "Ví",
    "Your address": "Địa chỉ của bạn",
    "History": "Lịch sử",
    "Map": "Bản đồ",
    "Buy miner": "Mua thợ đào",
    "Buy Miner": "Mua Thợ đào",
    "Get ": "Nhận",
    "Get Miner": "Nhận thợ đào",
    "Upgrade parcel": "Nâng cấp lô",
    "Soon": "Sẽ có sớm",
    "Upgraded":"Đã nâng cấp",
    "Upgraded land has 10 slots for putting miner NFTs. Upgraded land does not have more physical space, it’s only that you can stake more miners.": "Mảnh đất đã được nâng cấp sẽ có 10 vị trí để gán NFT thợ đào. Đất được nâng cấp không có thêm không gian vật lý, bạn chỉ có thể gán thêm thợ đào vào.",
    "Next": "Tiếp",
    "Back": "Quay lại",
    "Skip guide": "Bỏ qua hướng dẫn",
    "Number of miners you want to buy": "Số lượng công cụ khai thác kép bạn muốn mua",
    "Attach Miner": "Gán thợ đào",
    "Upgrade for": "Nâng cấp cho",
    "On Sale": "Đang Sale",
    "Type": "Loại",
    "Location": "Địa điểm",
    "Size": "Kích cỡ",
    "Buy now": "Mua ngay",
    "Email": "Email",
    "Username": "Tên sử dụng",
    "Verify Email": "Xác nhận Email",
    "Index": "Chỉ số",
    "Verification Code": "Mã xác nhận",
    "Log out": "Đăng xuất",
    "Select all": "Chọn tất cả",
    "Cart": "Giỏ",
    "Total": "Tổng cộng",
    "Register": "Đăng kí",
    "Dashboard": "Bảng điều khiển",
    "Transaction success!": "Giao dịch thành công!",
    "I`ve read Terms & Conditions": "Tôi đã đọc điều khoản và điều kiện sử dụng",
    "Terms & conditions": "Điều khoản và điều kiện",
    "Affiliate Terms & Conditions": "Điều khoản và điều kiện mô hình liên kết",
    "Accept Terms & Conditions": "Chấp nhận điều kiện và điều khoản",
    "Loading! Please Wait ...": "Đang tải! Vui lòng chờ ...",
    "Initializing Wallet! Please Wait ...": "Đang khởi động ví! Vui lòng chờ...",
    "Login In Progress! Please Wait ...": "Đang đang nhập! Vui lòng chờ ...",
    "Enter your email": "Điền Email",
    "Confirm your Email": "Xác nhận Email",
    "Enter username": "Điền tên tài khoản",
    "To dashboard": "Đến bảng điều khiển",
    "You successfully verified your email": "Bạn đã xác nhận Email thành công",
    "View map": "Hiển thị bản đồ",
    "Log in to buy": "Đăng nhập để muamua",
    "Congratulations": "Chúc mừng",
    "Buy" : "Mua",
    "Miner" : "Thợ đào",
    "Parcel" : "Lô",
    "Upgrade": "Nâng cấp",
    "Load more": "Tải thêm",
    "All": "Tất cả",
    "Basic": "Cơ bản",
    "Activate": "Kích hoạt",
    "Basic Miner": "Thợ đào cơ bản",
    "In use": "Đang dùng",
    "Expired": "Hết hạn",
    "Available": "Có sẵn",
    "Standard parcel": "Lô cơ bản",
    "Success": "Thành công",
    "Something Went Wrong": "Có gì đó không đúng",
    "Loading": "Đang tải",
    "Invalid Verification Code": "Mã xác nhận sai",
    "Invalid Email Address": "Địa chỉ Email sai",
    "Invalid Arguments Supplied": "Lập luận đưa ra không có hiệu lực",
    "Email Already Exists": "Email đã được sử dụng",
    "UnAuthorized": "Không được cấp phép",
    "User Update Failed": "Cập nhập người dùng không thành công",
    "Permission Denied": "Bị từ chối cấp phép",
    "Basic miner": "Thợ đào cơ bản",
    "Remove": "Loại bỏ",
    "Special offers": "Ưu đãi đặc biệt",
    "Please Wait": "Vui lòng chờ",
    "Transaction Rejected": "Giao dịch bị từ chối",
    "sold": "Đã bán",
    "The parcel price will be increased after certain percentage of parcels will be sold. Bundle prices will be increased accordingly.": "Giá mỗi lô sẽ được tăng lên sau khi một lượng lô nhất định được bán ra. Giá nguyên gói (Bundle) sẽ được tăng lên tương ứng.",
    "Sold": "Đã bán",
    "Parcel price index": "Chỉ số giá lô",
    "Current parcel price": "Giá lô hiện tại",
    "Parcels sold": "Số lô đã được bán",
    "Parcel stats": "Các số liệu về lô",
    "Help center": "Trung tâm hỗ trợ",
    "Partner area": "Khu vực đối tác",
    "email_verification": 'Một mã xác minh email đã được gửi đến',
    "change_email": 'đổi email',
    "you_successfully_verified_your_email": "Bạn đã xác minh thành công email của mình",
    "to_dashboard": "Đi đến bảng điều khiển",
    "Transaction Success": "Giao dịch thành công",
    "parcels available": "lô hiện có",
    "Apply": "Áp dụng",
    "Ocean": "Đại dương",
    "Near Ocean": "Gần đại dương",
    "Insufficient USDT amount": "Lượng USDT không đủ",
    "Insufficient {asset} amount": "Lượng {asset} không đủ",
    "Miner Rewards": "Phần thưởng thợ đào",
    "No data found": "Dữ liệu không tìm thấy",
    "Contract Address": "Địa chỉ hợp đồng",
    "Wallet Address": "Địa chỉ Ví",
    "Inland": "Nội địa",
    "Business": "Việc kinh doanh",
    "parcels": "bưu kiện",
    "parcel": "Bưu kiện",
    "Standard": "Tiêu chuẩn",
    "Full": "Đầy",
    "Attach miners": "Đính kèm công cụ khai thác",
    "Upgraded Parcel": "Bưu kiện được nâng cấp",
    "Discount": "Miễn giảm",
    "save": "tiết kiệm",
    "Copied": "Đã sao chép",
    "Marketplace": "Thương trường",
    "Parcel name": "Tên bưu kiện",
    "Basic Bundle": "Gói cơ bản",
    "4 Parcels Bundle": "Gói 4 bưu kiện",
    "10 Parcels Bundle": "10 Gói 4 bưu kiện",
    "40 Parcels Bundle": "40 Gói 4 bưu kiện",
    "80 Parcels Bundle": "80 Gói 4 bưu kiện",
    "140 Parcels Bundle": "140 Gói 4 bưu kiện",
    "240 Parcels Bundle": "240 Gói 4 bưu kiện",
    "4 Basic Miner Bundle": "4 Gói khai thác",
    "10 Basic Miner Bundle": "10 Gói khai thác",
    "40 Basic Miner Bundle": "40 Gói khai thác",
    "80 Basic Miner Bundle": "80 Gói khai thác",
    "140 Basic Miner Bundle": "140 Gói khai thác",
    "240 Basic Miner Bundle": "240 Gói khai thác",
    "Hashpower": "Hashpower",
    "Something went wrong, please refresh the page and try again": "Đã xảy ra sự cố, vui lòng làm mới trang và thử lại",
    "Gallery": "Phòng trưng bày",
    "Accepted": "Đã chấp nhận",
    "Pending": "Đang chờ",
    "Upload": "Tải lên",
    "Uploading image": "Đang tải lên hình ảnh",
    "Something went wrong, try again later": "Đã xảy ra sự cố, vui lòng thử lại sau",
    "You need to be authenticated to upload images to Gallery.": "Bạn cần được xác thực để tải lên hình ảnh vào Phòng trưng bày.",
    "Assign image": "Gán hình ảnh",
    "Assign image to parcels": "Gán hình ảnh cho các bưu kiện",
    "Remove current image": "Xóa hình ảnh hiện tại",
    "Change image": "Đổi hình ảnh",
    "Assign to parcels": "Gán cho bưu kiện",
    "Reselect image assignment area?": "Phép chọn lại khu vực gán hình ảnh?",
    "You have not applied changes. Do you want to reselect image assignment area?": "Bạn đã không áp dụng thay đổi.Bạn có muốn chọn lại khu vực gán hình ảnh?",
    "Image has been successfully changed": "Hình ảnh đã được thay đổi thành công",
    "Image has been successfully assigned to parcels": "Hình ảnh đã được gán thành công cho bưu kiện",
    "Image has been successfully removed from the parcels": "Hình ảnh đã được xóa thành công khỏi bưu kiện",
    "Yes": "Đúng",
    "No": "Không",
    "Cancel": "Hủy bỏ",
    "You have no images": "Bạn không có hình ảnh",
    "Notifications": "Notifications",
    "No notifications": "No notifications",
    "year ago": "Không có thông báo",
    "months ago": "năm trước",
    "month ago": "năm trước",
    "weeks ago": "tháng trước",
    "week ago": "tháng trước",
    "days ago": "vài tuần trước",
    "day ago": "tuần trước",
    "hours ago": "ngày trước",
    "hour ago": "ngày trước đó",
    "minutes ago": "nhiều giờ trước",
    "minutes": "giờ trước",
    "minute": "một vài phút trước",
    "seconds": "phút",
    "second": "phút",
    "Your image was approved": "Hình ảnh của bạn đã được phê duyệt",
    "Your image was rejected": "Hình ảnh của bạn đã bị từ chối",
    "New": "Mới",
    "Gallery guide" : "Hướng dẫn thư viện",
    "Go to gallery": "Đi đến phòng trưng bày",
    "How it works": "Làm thế nào nó hoạt động",
    "Removing image from parcels..." : "Xóa hình ảnh khỏi bưu kiện ...",
    "Removing image" : "Loại bỏ hình ảnh",
    "Image has been successfully removed" : "Hình ảnh đã được xóa thành công",
    "Wrong file size" : "Sai kích thước tệp",
    "Please upload image files with size less than 10MB": "Vui lòng tải lên các tệp hình ảnh với kích thước nhỏ hơn 10MB",
    "Drag to Reposition": "Kéo để định vị lại",
    "Pinch or use the mouse wheel to zoom in or out": "Nhúm hoặc sử dụng bánh xe chuột để phóng to hoặc thu nhỏ",
    "Drag and move the image with fingers or using your mouse to select area": "Kéo và di chuyển hình ảnh bằng ngón tay hoặc sử dụng chuột của bạn để chọn khu vực",
    "It can take up to 48 hours for the image to be approved. You can view your images awaiting approval in the Pending tab.": "Có thể mất tới 48 giờ để hình ảnh được phê duyệt.Bạn có thể xem hình ảnh của bạn đang chờ phê duyệt trong tab đang chờ xử lý.",
    "Delete Image": "xóa ảnh",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable. Land containing this image will also be cleared.": "Bạn có chắc là bạn muốn xóa vĩnh viễn hình ảnh khỏi bộ sưu tập của bạn?Hoạt động này không thể phục hồi.Đất chứa hình ảnh này cũng sẽ được xóa.",
    "Are you sure you want to permanently delete the image from your gallery? This operation is not recoverable." : "Bạn có chắc là bạn muốn xóa vĩnh viễn hình ảnh khỏi bộ sưu tập của bạn?Hoạt động này không thể phục hồi.",
    "Wrong File Type": "Loại tệp sai",
    "Please upload jpg/jpeg image files": "Vui lòng tải lên các tệp hình ảnh JPG/JPEG",
    "Upload Images to Your Gallery": "Tải hình ảnh lên bộ sưu tập của bạn",
    "You can upload images to your gallery from your computer, mobile phone or tablet. Currently only jpg/jpeg image file types are supported. Press the upload button or simply drag and drop the image from computer to upload your image. Image size must be up to 10 MB." : "Bạn có thể tải hình ảnh lên bộ sưu tập của bạn từ máy tính, điện thoại di động hoặc máy tính bảng của bạn.Hiện tại chỉ có các loại tệp hình ảnh JPG/JPEG được hỗ trợ.Nhấn nút tải lên hoặc đơn giản là kéo và thả hình ảnh từ máy tính để tải lên hình ảnh của bạn.Kích thước hình ảnh phải lên đến 10 MB.",
    "Upload image": "Tải lên hình ảnh",
    "Image Approval": "Phê duyệt hình ảnh",
    "Uploaded images need to be approved before they appear in the approved section of your gallery. This procedure is necessary to filter out inappropriate, explicit content and copyrighted material. Approval can take up to 48 hours. You'll get notified about the verdict whenever you're online." : "Hình ảnh được tải lên cần được phê duyệt trước khi chúng xuất hiện trong phần được phê duyệt trong bộ sưu tập của bạn.Quy trình này là cần thiết để lọc ra nội dung không phù hợp, rõ ràng và tài liệu có bản quyền.Phê duyệt có thể mất tới 48 giờ.Bạn sẽ được thông báo về bản án bất cứ khi nào bạn trực tuyến.",
    "Approval": "Phê duyệt",
    "Select Your Land to Assign Image to": "Chọn đất của bạn để gán hình ảnh cho",
    "You can assign your uploaded images to your owned land. To do so, simply select the parcels by dragging mouse on the land part you want to assign an image to. Please note that image can only be assigned to a rectangular area that is owned by you only." : "Bạn có thể gán hình ảnh đã tải lên của bạn cho vùng đất riêng của bạn.Để làm như vậy, chỉ cần chọn các bưu kiện bằng cách kéo chuột trên phần đất bạn muốn gán một hình ảnh cho.Xin lưu ý rằng hình ảnh chỉ có thể được gán cho một khu vực hình chữ nhật chỉ thuộc sở hữu của bạn.",
    "Land Selection": "Lựa chọn đất",
    "Crop Image": "Hình ảnh cây trồng",
    "You can crop a custom part of your image that you want to assign to your land section. You can close in to any area of your image and crop it by dragging the image with mouse or your finger if you're on a mobile device. You can also zoom in and out by using mouse wheel or pinch gesture." : "Bạn có thể cắt một phần tùy chỉnh của hình ảnh mà bạn muốn gán cho phần đất của mình.Bạn có thể đóng vào bất kỳ khu vực nào trong hình ảnh của mình và cắt nó bằng cách kéo hình ảnh bằng chuột hoặc ngón tay của bạn nếu bạn đang ở trên thiết bị di động.Bạn cũng có thể phóng to và ra ngoài bằng cách sử dụng bánh xe chuột hoặc cử chỉ",
    "Activate multiple selection": "Kích hoạt nhiều lựa chọn",
    "Exit multiple selection": "Thoát nhiều lựa chọn",
    "Owned": "Sở hữu",
    "Owned by": "Được sở hữu bởi",
    "Multiple types": "Nhiều loại",
    "seconds ago": "Cách đây vài giây",
    "Super Bundle": "Gói siêu cấp",
    "Balance": "THĂNG BẰNG",
    "View your purchased Bundles and claim to have them from your balance": "Xem các Gói bạn đã mua và yêu cầu nhận chúng từ số dư của bạn",
    "Feel free to choose lands on Map.": "Hãy thoải mái lựa chọn các vùng đất trên Bản đồ.",
    "Earn money by using miners for generating rewards.": "Kiếm tiền bằng cách sử dụng các công cụ khai thác để tạo ra phần thưởng.",
    "Upgrade your owned lands from 4 slots to 10.": "Nâng cấp vùng đất thuộc sở hữu của bạn từ 4 vị trí lên 10 vị trí.",
    "Confirm": "Xác nhận",
    "You don`t have any available parcels in your inventory": "Bạn không có bất kỳ bưu kiện nào có sẵn trong kho của mình",
    "Months": "Tháng",
    "Super Bundle R6": "Siêu gói R6",
    "Super Bundle R10": "Siêu gói R10",
    "Super Bundle R15": "Siêu gói R15",
    "Super Bundle R20": "Siêu gói R20",
    "Super Bundle R22 S": "Siêu gói R22 S",
    "Super Bundle R22 M": "Siêu gói R22 M",
    "Super Bundle R22 L": "Siêu gói R22 L",
    "Super Bundle R22 XL": "Siêu gói R22 XL",
    "Super Bundle R22 XXL": "Siêu gói R22 XXL",
    "1 Claim your land": "1 Yêu cầu đất đai của bạn",
    "2 Claim your miner": "2 Yêu cầu người khai thác của bạn",
    "3 Upgrade your land": "3 Nâng cấp đất của bạn",
    "Your NFT balance": "Số dư NFT của bạn",
    "Next price changes in": "Thay đổi giá tiếp theo trong",
    "Parcels": "bưu kiện",
    "Get to know the platform usage": "Nhận biết việc sử dụng nền tảng",
    "What is a Parcel?": "Bưu kiện là gì?",
    "Land is a unit of area on the GYM Street map and it is also an NFT. You can own as much land on GYM Street as you want.": "Land là một đơn vị diện tích trên bản đồ GYM Street và nó cũng là một NFT. Bạn có thể sở hữu bao nhiêu đất trên Phố GYM tùy thích.",
    "What is a Miner?": "Thợ mỏ là gì?",
    "A miner is what generates income for you at GYM Street. You can attach up to 4 miners on each of your standard land parcels.": "Công cụ khai thác là thứ tạo ra thu nhập cho bạn tại GYM Street. Bạn có thể đính kèm tối đa 4 công cụ khai thác trên mỗi thửa đất tiêu chuẩn của mình.",
    "Miner rewards": "phần thưởng thợ mỏ",
    "My Miners": "Thợ mỏ của tôi",
    "My Hashpower": "Của tôi Hashpower",
    "Total Claimed": "Tổng số tiền được yêu cầu",
    "Total pending rewards": "Tổng số phần thưởng đang chờ xử lý",
    "Claim all": "Yêu cầu tất cả",
    "Daily rewards": "Phần thưởng hàng ngày",
    "Weekly rewards": "Phần thưởng hàng tuần",
    "Monthly rewards": "Phần thưởng hàng tháng",
    "Yearly rewards": "Phần thưởng hàng năm",
    "ROI": "ROI",
    "ROI Calculator": "Máy tính ROI",
    "Global Statistics": "Thống kê toàn cầu",
    "Global Hashpower": "Toàn cầu Hashpower",
    "Your Shares": "Chia sẻ của bạn",
    "Daily Global Rewards": "Phần thưởng toàn cầu hàng ngày",
    "Price": "Giá bán",
    "Total Minted": "tổng số đúc",
    "total": "toàn bộ",
    "standard": "Tiêu chuẩn",
    "upgraded": "nâng cấp",
    "active": "tích cực",
    "inactive": "không hoạt động",
    "Attach miner": "Đính kèm công cụ khai thác",
    "Total Miners": "Tổng số người khai thác",
    "Total Minted Rewards": "Tổng số phần thưởng đúc",
    "What is parcel and how to use it?": "Bưu kiện là một đơn vị diện tích trên bản đồ {metaverse} được trang bị khả năng đúc tiền. Sở hữu bưu kiện và tạo thu nhập.",
    "What is a miner and how to use it?": "Công cụ khai thác là công cụ tạo thu nhập của bạn trên {metaverse}. Nâng cao thu nhập của bạn bằng cách gắn Công cụ khai thác vào Bưu kiện của bạn. <br/> <br/> Cả Công cụ khai thác GYMNET và Công cụ khai thác kép đều hoạt động ở tốc độ khai thác 1.000 Mh/s.",
    "Your active/inactive hashpower, 1 miner = 1,000 Mh/s, your Miners 0 x 1,000Mh/s = 0.00": "Hoạt động/không hoạt động của bạn hashpower, 1 công cụ khai thác = 1.000 Mh/s, Công cụ khai thác của bạn 0 x 1.000Mh/s = 0,00",
    "The amount of the cumulated claimed rewards": "Số lượng phần thưởng được yêu cầu tích lũy",
    "Your current available pending rewards.": "Phần thưởng đang chờ xử lý hiện có của bạn.",
    "Congratulations!": "Xin chúc mừng!",
    "Upgrade your parcels to mine even more?": "Nâng cấp bưu kiện của bạn để khai thác nhiều hơn?",
    "You have NUM parcels that are not upgraded.": "Bạn có {parcels} bưu kiện\n chưa được nâng cấp.",
    "Go to Map": "Chuyển đến Bản đồ",
    "You're mining less than the average user.": "Bạn đang khai thác ít hơn người dùng trung bình.",
    "Purchase more miners to improve your results": "Mua thêm công cụ khai thác để cải thiện kết quả của bạn",
    "Buy more miners": "Mua thêm thợ mỏ",
    "Thanks, I'll stay below average": "Cảm ơn, tôi sẽ ở dưới mức trung bình",
    "You aren't mining at full speed.": "Bạn không khai thác ở tốc độ tối đa.",
    "Do you want to buy more parcels now ?": "Bạn có muốn mua thêm bưu kiện bây giờ?",
    "I definitely do": "tôi chắc chắn làm",
    "No, I'll let my miners to rust": "Không, tôi sẽ để những người thợ mỏ của mình rỉ sét",
    "You have no miners": "Bạn không có thợ mỏ",
    "Buy miners": "Mua công cụ khai thác kép",
    "You haven't upgradeable parcels": "Bạn chưa nâng cấp bưu kiện",
    "You haven't inactive miners": "Bạn không có công cụ khai thác không hoạt động",
    "Close": "Đóng",
    "Read more": "Đọc thêm",
    "Drag and drop an image, or Browse": "Kéo và thả hình ảnh hoặc <label class=\"browse-button\" for=\"upload-image\"> Duyệt qua </label>",
    "Browse an image": "<label class=\"Browse-button\" for=\"upload-image\"> Duyệt qua </label> một hình ảnh",
    "Drop to upload": "Rơi để tải lên",
    "File not supported": "Tệp không được hỗ trợ",
    "Upload your parcel image": "Tải lên hình ảnh bưu kiện của bạn",
    "Up to 10MB, jpg/jpeg": "Lên đến 10MB, jpg/jpeg",
    "High resolution images, should align with your land shape (you can crop after upload)": "<span class=\"bold-info\"> Hình ảnh có độ phân giải cao, </span> phải phù hợp với hình dạng đất của bạn (bạn có thể cắt sau khi tải lên)",
    "Same image may be used on different lands owned by the user": "<span class=\"bold-info\"> Có thể sử dụng cùng một hình ảnh trên </span> các vùng đất khác nhau do người dùng sở hữu",
    "No violence, offensive, graphic or 18+ content": "Không có nội dung bạo lực, xúc phạm, đồ họa hoặc <span class=\"bold-info\"> 18+ </span>",
    "No third-party copyrighted content, no logos or screenshots from Gymstreet and Gymnetwork": "<span class=\"bold-info\"> Không có nội dung có bản quyền của bên thứ ba, </span> không có biểu trưng hoặc ảnh chụp màn hình từ <span class=\"bold-info\"> Gymstreet và Gymnetwork </span>",
    "No images already used by another user, no images from DeFi listing website images": "<span class=\"bold-info\"> Không có hình ảnh nào đã được sử dụng </span> bởi người dùng khác, không có hình ảnh nào từ hình ảnh trang web danh sách DeFi",
    "No religious images": "<span class=\"bold-info\"> Không có hình ảnh tôn giáo </span>",
    "Unlock miners by purchasing at least 1 land parcel": "Mở khóa công cụ khai thác bằng cách mua ít nhất 1 thửa đất",
    "OK": "ĐƯỢC RỒI",
    "Your parcels": "Bưu kiện của bạn",
    "Your miners": "Thợ mỏ của bạn",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcels to start mining": "Bạn không khai thác ở tốc độ tối đa, bạn có công cụ khai thác {miners} cần được gắn vào bưu kiện của bạn để bắt đầu khai thác",
    "You are not mining at full speed, you have NUM miner that need to be attached to your parcel to start mining": "Bạn không khai thác ở tốc độ tối đa, bạn có công cụ khai thác {miners} cần được gắn vào bưu kiện của bạn để bắt đầu khai thác",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcels to start mining": "Bạn không khai thác ở tốc độ tối đa, bạn có {miners} công cụ khai thác cần được gắn vào bưu kiện của bạn để bắt đầu khai thác",
    "You are not mining at full speed, you have NUM miners that need to be attached to your parcel to start mining": "Bạn không khai thác ở tốc độ tối đa, bạn có {miners} công cụ khai thác cần được gắn vào bưu kiện của bạn để bắt đầu khai thác",
    "Info Center": "Trung tâm thông tin",
    "Standard Parcel": "Bưu kiện tiêu chuẩn",
    "You have no parcels": "Bạn không có bưu kiện",
    "Single Products": "Sản phẩm đơn lẻ",
    "Parcels sold / Total parcels": "Số lô đã được bán / Tổng số bưu kiện",
    "You can mine even more. Purchase more miners to improve your results": "Bạn có thể khai thác nhiều hơn nữa. Mua thêm công cụ khai thác để cải thiện kết quả của bạn",
    "In Progress": "Trong tiến trình",
    "Active": "Tích cực",
    "Learn More": "Tìm hiểu thêm",
    "Add to wallet": "Thêm vào ví",
    "Your Balance": "Cân bằng của bạn",
    "The Promotion starts in:": "Chương trình khuyến mãi bắt đầu trong:",
    "Please go to your wallet, which you just connected, and verify your login by confirming the requested transaction. It's free of charge!": "Vui lòng truy cập ví của bạn mà bạn vừa kết nối và xác minh thông tin đăng nhập của bạn bằng cách xác nhận giao dịch được yêu cầu. Nó hoàn toàn miễn phí!",
    "Contract addresses": "địa chỉ hợp đồng",
    "Add TOKEN to wallet": "Thêm {token} vào ví",
    "I accept the terms and conditions": "Tôi chấp nhận các điều khoản và điều kiện",
    "Log In": "Đăng nhập",
    "Sign Up": "Đăng ký",
    "Connect My Wallet": "Kết nối ví của tôi",
    "Change Email": "Thay đổi e-mail",
    "Reset Password": "Đặt lại mật khẩu",
    "Your password has been changed.": "Mật khẩu của bạn đã được thay đổi.",
    "Please log in to your account.": "Xin hãy đăng nhập vào tài khoản của bạn.",
    "Confirm Email": "Xác nhận e-mail",
    "Emails don't match": "Email không phù hợp",
    "Or": "Hoặc",
    "Sign Up With Email": "Đăng ký với e-mail",
    "Already have an account?": "Bạn co săn san để tạo một tai khoản?",
    "Recover": "Hồi phục",
    "Enter New Password": "Nhập mật khẩu mới",
    "Confirm New Password": "Xác nhận mật khẩu mới",
    "Password": "Mật khẩu",
    "Forgot password?": "Quên mật khẩu?",
    "Password is required": "Mật khẩu là bắt buộc",
    "Password confirmation is required": "Yêu cầu xác nhận mật khẩu",
    "Passwords do not match": "mất khẩu không hợp lệ",
    "Password must contain at least one uppercase letter, one lowercase letter and one number": "Mật khẩu phải chứa ít nhất một chữ cái chữ hoa, một chữ cái viết thường và một số",
    "Password must be at least 8 characters": "Mật khẩu cần dài ít nhất 8 ký tự",
    "Password must not exceed 24 characters": "Mật khẩu không được vượt quá 24 ký tự",
    "Password must contain at least one special character": "Mật khẩu phải chứa ít nhất một ký tự đặc biệt",
    "Password cannot contain spaces": "Mật khẩu không thể chứa khoảng trắng",
    "Email is required": "Email thì cần thiết",
    "Email confirmation is required": "Yêu cầu xác nhận e-mail",
    "Please enter a valid email": "Vui lòng nhập email hợp lệ",
    "Step NUM1 of NUM2": "Bước {num1} của {num2}",
    "Create Password": "Tạo mật khẩu",
    "Get Started": "Bắt đầu",
    "Create a Wallet": "Tạo ví",
    "Confirm on this screen": "Xác nhận trên màn hình này",
    "Pick a password on the next step. This needs to be at least 8 symbols long.": "Chọn một mật khẩu ở bước tiếp theo. Điều này cần phải dài ít nhất 8 ký hiệu.",
    "Wallet Ready": "Ví đã sẵn sàng",
    "Add Binance Smart Chain": "Thêm Binance Smart Chain",
    "Click on the button below to add Binance Smart Chain Network to your MetaMask wallet.": "Nhấp vào nút bên dưới để thêm Mạng Binance Smart Chain vào ví MetaMask của bạn.",
    "Confirm the connection request": "Xác nhận yêu cầu kết nối",
    "Get Started!": "Bắt đầu!",
    "Secure Wallet": "Ví an toàn",
    "Confirm your password to continue.": "Xác nhận mật khẩu của bạn để tiếp tục.",
    "You will need it to recover your wallet if you lose access to your computer, and it should be kept in a safe place. Anyone who has access to your 12-word backup phrase will be able to recover your funds, so keep it a secret.": "Bạn sẽ cần nó để khôi phục ví của mình nếu bạn mất quyền truy cập vào máy tính của mình và nó phải được giữ ở nơi an toàn. Bất kỳ ai có quyền truy cập vào cụm từ sao lưu 12 từ của bạn đều có thể lấy lại tiền của bạn, vì vậy hãy giữ bí mật.",
    "You have successfully set up Your wallet.Welcome to the world of WEB3!": "Bạn đã thiết lập thành công ví của mình.\nChào mừng đến với thế giới WEB3!",
    "Confirm Password": "Xác nhận mật khẩu",
    "Install here.": "Cài đặt tại đây.",
    "Something went wrong, please try verifying your email again.": "Có gì đó không ổn, vui lòng thử xác minh lại email của bạn.",
    "Looks like your password reset link has expired. Please try again.": "Có vẻ như liên kết đặt lại mật khẩu của bạn đã hết hạn. Vui lòng thử lại.",
    "Looks like your password reset link has already been used. Please request it again.": "Có vẻ như liên kết đặt lại mật khẩu của bạn đã được sử dụng.Vui lòng yêu cầu lại.",
    "Couldn't recover password": "Không thể khôi phục mật khẩu",
    "Couldn't reset your password.": "Không thể đặt lại mật khẩu của bạn.",
    "Couldn't login.": "Không thể đăng nhập.",
    "Couldn't sign you up.": "Không thể đăng ký bạn.",
    "Resend Email": "Gửi lại e-mail",
    "Couldn't resend confirmation email.": "Không thể gửi lại e-mail xác nhận.",
    "Provided email is already in use.": "Email được cung cấp đã được sử dụng.",
    "You have already verified your email address": "Bạn đã xác minh địa chỉ email của mình",
    "Invalid or expired verification code": "Mã xác minh không hợp lệ hoặc hết hạn",
    "An error occurred while logging in. Please try again.": "Đã xảy ra lỗi trong khi đăng nhập. Vui lòng thử lại.",
    "We sent an email to {EMAIL} with a link to finalize your registration": "Chúng tôi đã gửi email đến {EMAIL} với một liên kết để hoàn thiện việc đăng ký của bạn",
    "We sent an email to {EMAIL} with a link to change your password": "Chúng tôi đã gửi email đến {EMAIL} với liên kết để thay đổi mật khẩu của bạn",
    "Referral ID": "ID giới thiệu",
    "On the next step, tap on 'Create a new wallet'.": "Ở bước tiếp theo, hãy nhấn vào \"Create a new wallet\".",
    "On the next step, tap on 'Create a wallet'.": "Ở bước tiếp theo, hãy nhấn vào \"Create a wallet\".",
    "We recommend using a completely unique password that hasn't been used anywhere else, and contains a mixture of upper and lower case letters, symbols and numbers.": "Chúng tôi khuyên bạn nên sử dụng một mật khẩu hoàn toàn độc đáo chưa được sử dụng ở bất kỳ nơi nào khác và chứa hỗn hợp các chữ cái, biểu tượng và số chữ thường.",
    "Put the words memorized in the previous step in order. Tap on 'Complete' button when you are finished with the backup.": "Đặt các từ ghi nhớ trong bước trước theo thứ tự.Nhấn vào nút \"Complete\" khi bạn kết thúc với bản sao lưu.",
    "On this step you can choose. We recommend you do this now as you might otherwise lose access to your wallet. Tap on 'Start' to continue.": "Ở bước này bạn có thể chọn. \nChúng tôi khuyên bạn nên làm điều này ngay bây giờ vì nếu không, bạn có thể mất quyền truy cập vào ví của mình. \nNhấn vào \"Start\" để tiếp tục.",
    "On this step tap on 'Start' to continue.": "Ở bước này, hãy nhấn vào \"Start\" \nđể tiếp tục.",
    "Tap on the burger menu of the app in the top left corner of the screen.": "Nhấn vào menu burger của ứng dụng ở góc trên cùng bên trái của màn hình.",
    "Next, tap on Settings in the menu to the left.": "Tiếp theo, nhấn vào \"Settings\" trong menu bên trái.",
    "Next, tap on Networks.": "Tiếp theo, nhấn vào \"Networks\".",
    "Add More Tokens": "Thêm nhiều mã thông báo",
    "In the search field, type in BNB Smart Chain and add it to your network.": "Trong trường tìm kiếm, hãy nhập \"BNB Smart Chain\" và thêm nó vào mạng của bạn.",
    "Next, select Custom Token. Paste the address of the smart contract you copied into the token address field. In the Token Symbol field, type SP, and in the Token Decimal, put 0. Then click the 'Import' button to complete your setup.": "Tiếp theo, chọn \"Custom Token\". Dán địa chỉ của hợp đồng thông minh bạn đã sao chép vào trường \"Token Address\".Trong trường \"Token Symbol\" , nhập SP, và trong \"Token Decimal\", đặt 0. Sau đó nhấp vào nút \"Import\" để hoàn tất thiết lập của bạn.",
    "Add Network": "Thêm mạng",
    "Give permission to the platform to add a network.": "Cho phép nền tảng để thêm một mạng.",
    "Extra PERCENT% Discount": "Thêm {PERCENT}% giảm giá",
    "Tap on the 'Import Tokens' link to add a Custom Token.": "Nhấn vào liên kết \"Import Tokens\" để thêm Mã thông báo Tùy chỉnh.",
    "Click on the button 'Approve' to allow the connection request.": "Nhấp vào nút \"Approve\" để cho phép yêu cầu kết nối.",
    "Confirm Secret Phrase": "Xác nhận cụm từ bí mật",
    "Click on the button 'Switch network' to allow the connection request.": "Nhấp vào nút \"Switch network\" để cho phép yêu cầu kết nối.",
    "Payment Method": "Phương thức thanh toán",
    "Select cryptocurrency": "Chọn tiền điện tử",
    "Please use an Ethereum BEP-20 address.": "Vui lòng sử dụng địa chỉ Ethereum BEP-20.",
    "Connect to Gymstreet": "Kết nối với Gym Street",
    "Now to access the Gym Street Metaverse, you need to open it with the MetaMask browser. Go to your MetaMask wallet app, choose 'Browser' from the side navigation menu, search for Gym Street in the browser tab.": "Bây giờ để truy cập Gym Street Metaverse, bạn cần mở nó bằng trình duyệt MetaMask. Truy cập ứng dụng ví MetaMask của bạn, chọn \"Browser\" từ menu điều hướng bên, tìm kiếm Gym Street trong tab trình duyệt.",
    "First, let's set up your cryptocurrency wallet.": "Đầu tiên, chúng ta hãy thiết lập ví tiền điện tử của bạn.",
    "Follow the next steps to continue the setup.": "Thực hiện theo các bước tiếp theo để tiếp tục thiết lập.",
    "Your balance was updated": "Số dư của bạn đã được cập nhật",
    "Once installed, you should see the below splash screen. Click the 'Get Started' button to begin creating your Ethereum wallet using MetaMask.": "Sau khi cài đặt, bạn sẽ thấy màn hình giật gân bên dưới. Nhấp vào nút \"Get Started\" để bắt đầu tạo ví Ethereum của bạn bằng MetaMask.",
    "You will then be asked if you want to help improve MetaMask. Click 'No, Thanks' if this doesn't interest you, otherwise click 'I agree'.": "Sau đó, bạn sẽ được hỏi liệu bạn có muốn giúp cải thiện MetaMask hay không. Nhấp vào \"No, Thanks\" nếu điều này không làm bạn quan tâm, nếu không, hãy nhấp vào \"I agree\".",
    "MetaMask will then present you with your 12-word backup phrase. You will need to write this down in the same order displayed on your screen.": "MetaMask sau đó sẽ trình bày cho bạn cụm từ sao lưu 12 từ của bạn.Bạn sẽ cần phải viết nó xuống theo cùng một thứ tự được hiển thị trên màn hình của bạn.",
    "After Downloading and opening MetaMask, tap on 'Get Started'.": "Sau khi Tải xuống và mở MetaMask, hãy nhấn vào \"Get Started\".",
    "Improve Metamask": "Cải thiện MetaMask",
    "Congratulations! You have set up your MetaMask wallet. Confirm the next few pop-ups on the screen to access your wallet.": "Chúc mừng!Bạn đã thiết lập ví MetaMask của bạn.Xác nhận một vài cửa sổ bật lên tiếp theo trên màn hình để truy cập ví của bạn.",
    "Import Binance Smart Chain": "Nhập Binance Smart Chain",
    "Next, copy the NFT Smart Contract by tapping on the button below.": "Tiếp theo, sao chép NFT Smart Contract bằng cách nhấn vào nút bên dưới.",
    "Copy NFT Contract": "Sao chép NFT Contract",
    "Install MetaMask": "Cài đặt MetaMask",
    "Install MetaMask here.": "Cài đặt MetaMask ở đây.",
    "Guide for MetaMask Wallet connection process.": "Hướng dẫn cho quá trình kết nối ví Metamask.",
    "MetaMask will then present you with your 12-word backup phrase.": "MetaMask sau đó sẽ trình bày cho bạn cụm từ sao lưu 12 từ của bạn.",
    "MetaMask Secret Phrase": "Cụm từ bí mật MetaMask",
    "Copy NFT Smart Contract": "Sao chép NFT Smart Contract",
    "You are almost finished with the MetaMask setup process. Just click 'All done' on the last page, and you will be automatically logged into MetaMask. If you ever log out, you can log back in by clicking on the icon added to your web browser (usually, it's next to the URL bar).": "Bạn gần như đã hoàn tất quá trình thiết lập MetaMask. Chỉ cần nhấp vào \"All done\" trên trang cuối cùng và bạn sẽ tự động đăng nhập vào MetaMask. Nếu đã từng đăng xuất, bạn có thể đăng nhập lại bằng cách nhấp vào biểu tượng được thêm vào trình duyệt web của mình (thông thường, biểu tượng này nằm bên cạnh thanh URL).",
    "Your payment processing is complete. Your NFT balance has been updated!": "Quá trình xử lý thanh toán của bạn đã hoàn tất. Số dư NFT của bạn đã được cập nhật!",
    "Your payment is received but is incomplete. Please pay AMOUNT CURRENCY more to complete the payment.": "Thanh toán của bạn đã được nhận nhưng chưa đầy đủ. Vui lòng trả thêm {amount} {currency} để hoàn tất thanh toán.",
    "Terms & Conditions": "Điều khoản và điều kiện",
    "Email Verification": "Email xác thực",
    "Transaction history": "Lịch sử giao dịch",
    "Order history": "Lịch sử đơn Hàng",
    "Product": "Sản phẩm",
    "Quantity": "Định lượng",
    "Payment UID": "UID thanh toán",
    "Status": "Tình trạng",
    "Currency": "Tiền tệ",
    "Autoship":"Tự động vận chuyển",
    "Amount": "Số lượng",
    "Date": "Ngày",
    "Tx ID": "Tx ID",
    "Completed": "Hoàn thành",
    "MetaMask is not installed": "MetaMask chưa được cài đặt",
    "If you have already installed MetaMask, please": "Nếu bạn đã cài đặt {MetaMask}, vui lòng",
    "refresh page": "làm mới trang",
    "to continue.": "để tiếp tục.",
    "You have successfully installed MetaMask. Now you can add a Network.": "Bạn đã cài đặt thành công MetaMask. Bây giờ bạn có thể thêm một Mạng.",
    "You already have this network": "Bạn đã có mạng này",
    "Continue": "Tiếp tục",
    "Finalize Registration": "Hoàn tất đăng ký",
    "Please confirm your email to continue": "Vui lòng xác nhận email của bạn để tiếp tục",
    "New email must be different from current email": "Email mới phải khác với email hiện tại",
    "Actions": "Hành động",
    "Request to change the chain has been rejected": "Yêu cầu thay đổi chuỗi đã bị từ chối",
    "Switch Chain": "Chuỗi chuyển đổi",
    "Choose your Metaverse": "Chọn Metaverse của bạn",
    "You need to choose your Metaverse to continue. Maps, Dashboard and My Products information will vary depending on your selection. Currently {METAVERSE} Metaverse is selected.": "Bạn cần chọn Metaverse của mình để tiếp tục. Thông tin về Bản đồ, Trang tổng quan và Sản phẩm của tôi sẽ khác nhau tùy thuộc vào lựa chọn của bạn. Hiện tại {METAVERSE} Metaverse đã được chọn.",
    "Can't check your purchases": "Không thể kiểm tra mua hàng của bạn",
    "Attach {COUNT} miners": "Đính kèm {COUNT} công cụ khai thác",
    "Attach 1 miner": "Đính kèm 1 thợ mỏ",
    "View on map": "Xem trên bản đồ",
    "Buy {COUNT} parcels": "Mua {COUNT} bưu kiện",
    "Buy 1 parcel": "Mua 1 bưu kiện",
    "Buy {COUNT} miners": "Mua {COUNT} công cụ khai thác kép",
    "Buy 1 miner": "Mua 1 công cụ khai thác kép",
    "Metaverses": "Metaverses",
    "My NFTs": "NFT của tôi",
    "Total Hashpower (Mh/s)": "Tổng sức mạnh băm (Mh/s)",
    "Available Miner Slots": "Khe khai thác có sẵn",
    "Average ROI past 9 months": "ROI trung bình 9 tháng qua",
    "Login Via Crypto Wallet": "Đăng nhập qua Ví tiền điện tử",
    "Land reserved for PARTNER": "Đất dành cho {PARTNER}",
    "About PARTNER": "Giới thiệu về {PARTNER}",
    "swap": "tráo đổi",
    "transfer": "chuyển khoản",
    "approve": "chấp thuận",
    "max": "TỐI ĐA",
    "The minimum amount to claim is N": "Số tiền tối thiểu để yêu cầu là {n}",
    "paginationText": "Hiển thị {from} - {to} của {count}",
    "turnovers": "Doanh thu",
    "Total partners": "Tổng đối tác",
    "refreshData": "Làm mới dữ liệu",
    "NFT Turnover": "NFT Doanh số",
    "Single Pool Turnover": "Doanh thu hồ bơi đơn",
    "depth": "Chiều sâu",
    "turnover": "Doanh số",
    "weekly": "Hàng tuần",
    "monthly": "Hàng tháng",
    "wallet": "Ví",
    "columns": "Cột",
    "filters": "Bộ lọc",
    "level": "Cấp độ",
    "search": "Tìm kiếm",
    "partners": "Đối tác",
    "Farming Turnover": "Doanh thu từ trang trại",
    "Vault Turnover": "Doanh thu của Vault",
    "Careers status": "Thứ hạng nghề nghiệp",
    "GYMNET Balance": "Số dư tài khoản GYMNET",
    "Save": "Tiết kiệm",
    "Swap": "Hoán đổi",
    "Turnover pool": "Pool doanh thu",
    "Global Line Bonus": "Tiền thưởng dòng toàn cầu",
    "Turnover pools": "Pools doanh thu",
    "Tax pools": "Pool thuế ({n}mo)",
    "Total qualified turnover": "Tổng doanh thu đủ điều kiện",
    "My claimed rewards": "Phần thưởng đã yêu cầu của tôi",
    "My pending rewards": "Phần thưởng đang chờ xử lý của tôi",
    "Week": "Tuần",
    "Month": "Tháng",
    "In order to get qualified for any pool, you’ll have to follow the 50% turnover rule: The maximum share each direct partner can contribute to the required turnover is up to 50% of the turnover coming from NFT sales.": "Để đủ điều kiện cho bất kỳ nhóm nào, bạn sẽ phải tuân theo quy tắc doanh thu 50%: Tỷ lệ tối đa mà mỗi đối tác trực tiếp có thể đóng góp vào doanh thu yêu cầu lên tới 50% doanh thu đến từ việc bán NFT.",
    "View more": "Xem thêm",
    "Turnover progress": "Tiến triển doanh thu",
    "Thank you for your participation in Global Line Incentive!": "Cảm ơn bạn đã tham gia Ưu đãi dòng toàn cầu!",
    "Global line before June 2023": "Dòng toàn cầu trước tháng 6 năm 2023",
    "Global line before March 2023": "Dòng toàn cầu trước tháng 3 năm 2023",
    "More info": "Thông tin thêm",
    "Your Position": "Vị trí của bạn",
    "Last Position": "Vị trí cuối cùng",
    "My line length": "Độ dài dòng của tôi",
    "Global Line Turnover": "Doanh thu dòng toàn cầu",
    "The turnover generated from all participants who joined the Global Line after you.": "Doanh thu được tạo ra từ tất cả những người tham gia đã tham gia Dòng toàn cầu sau bạn.",
    "You need purchases of at least $100 to become a Global Line member.": "Bạn cần mua ít nhất $100 để trở thành thành viên Global Line.",
    "Your pending income": "Thu nhập chờ xử lý của bạn",
    "Buy more NFTs to increase your shares": "Mua thêm <strong><i>NFT</i></strong> để tăng <strong>cổ phiếu của bạn.</strong>",
    "New Registrations": "Đăng ký mới",
    "Turnover": "Doanh số",
    "Global level": "Cấp toàn cầu",
    "Joining time": "Thời gian tham gia",
    "Global Line Total Turnover": "Tổng doanh thu dòng toàn cầu",
    "Global Line end": "Kết thúc dòng chung",
    "Please confirm your claim": "Vui lòng xác nhận khiếu nại của bạn",
    "After you confirm, you will receive your rewards on your balance.": "Sau khi bạn xác nhận, bạn sẽ nhận được phần thưởng trên số dư của mình.",
    "Select Language": "Chọn ngôn ngữ",
    "Download": "Tải xuống",
    "Your Income": "Thu nhập của bạn",
    "Shares": "Cổ phiếu",
    "Single Miner": "Công cụ khai thác đơn lẻ",
    "Super Bundles": "Gói siêu cấp",
    "Regular Bundles": "Gói thông thường",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%.": "Tỷ lệ tối đa mà mỗi đối tác trực tiếp có thể đóng góp vào doanh thu yêu cầu lên tới 50%.",
    "Your partners": "Đối tác của bạn",
    "Generated turnover": "Doanh thu được tạo ra",
    "Qualified turnover": "Doanh thu đủ điều kiện",
    "Generated": "Đã tạo",
    "Qualified": "Đạt tiêu chuẩn",
    "Your partners turnover statistics will appear here.": "Thống kê doanh thu đối tác của bạn sẽ xuất hiện ở đây.",
    "Pools": "Pools",
    "Qualification": "Điều kiện",
    "Rewards": "Thưởng",
    "Participants": "Người tham gia",
    "Total rewards": "Tổng số phần thưởng",
    "Rewards per participant": "Phần thưởng cho mỗi người tham gia",
    "Land sales contribution": "Phần đóng góp sale đất",
    "Maintainance contribution": "Phần đóng góp bảo dưỡng",
    "Distributed": "Đã được phân phối",
    "Refresh data": "Làm mới dữ liệu",
    "You will receive your rewards directly on your wallet": "Bạn sẽ nhận phần thưởng trực tiếp trên ví của mình",
    "Total claimed": "Tổng lượng đã được nhận",
    "Next distribution": "Đóng góp tiếp theo",
    "Unclaimed BUSD rewards": "Thưởng BUSD chưa được bạn nhận",
    "Required level": "Mức yêu cầu",
    "NFT sales percent": "Doanh số NFT {n}%",
    "Total qualified participants": "Tổng số người tham gia đủ điều kiện",
    "TxID": "TxID",
    "Tx Type": "Loại Tx",
    "Global line reward": "Phần thưởng dòng toàn cầu",
    "Your share": "Chia sẻ của bạn",
    "Income before": "Thu nhập trước",
    "Income after": "Thu nhập sau",
    "NFT Income": "Thu nhập NFT",
    "NFT Commissions Total": "Tổng hoa hồng NFT",
    "NFT Statistic": "Thống kê NFT",
    "Level": "Cấp độ",
    "User": "Người dùng",
    "Your current pin": "Mã pin hiện tại của bạn",
    "Turnover for the next pin": "Doanh thu cho pin tiếp theo",
    "Your next incentive": "Ưu đãi tiếp theo của bạn",
    "Your next bonus": "Your next bonus",
    "Incentives": "Ưu đãi",
    "Partners": "Đối tác",
    "Cash Bonus": "Tiền thưởng",
    "Your pin": "Ghim của bạn",
    "Incentive": "Khuyến khích",
    "Pin": "Ghim",
    "Level pool": "Pool cấp độ",
    "Go to PARTNER": "Truy cập {PARTNER}",
    "Partnerships": "Quan hệ đối tác",
    "Cannot find EMAIL_OR_WALLET": "Không thể tìm thấy {EMAIL_OR_WALLET}",
    "Incentive Ends In: ": "Khuyến khích <strong>Kết thúc</strong>: ",
    "Your Rewards History": "Lịch sử phần thưởng của bạn",
    "Dubai & Sofia Trip": "Chuyến đi Dubai & Sofia",
    "No Rewards": "Không có phần thưởng",
    "No Partners": "Không có đối tác",
    "Reward": "Phần thưởng",
    "Granted": "Giả dụ như vậy",
    "Transaction": "Giao dịch",
    "Free BUNDLE": "Miễn phí {BUNDLE}",
    "Team Purchases": "Mua nhóm",
    "Direct Sales": "Bán trực tiếp",
    "You have won this reward": "Bạn đã giành được phần thưởng này",
    "50% Turnover Rule is applied for all incentives!": "Quy tắc doanh thu 50% được áp dụng cho tất cả các ưu đãi!",
    "Please note that you have the chance to earn a complimentary R8 Bundle in two different ways: either by reaching 20k in direct sales or by achieving 100k in team purchases.": "Xin lưu ý rằng bạn có cơ hội kiếm được Gói R8 miễn phí theo hai cách khác nhau: bằng cách đạt được 20.000 lượt bán hàng trực tiếp hoặc bằng cách đạt được 100.000 lượt mua hàng theo nhóm.",
    "Insufficient funds for gas, please add BNB to your wallet": "Không đủ tiền cho gas, vui lòng thêm BNB vào ví của bạn",
    "Please note that you will require up to AMOUNT worth of CURRENCY to complete the transaction.": "Xin lưu ý rằng bạn sẽ cần có {amount} {currency} để hoàn tất giao dịch.",
    "Please note that AMOUNT worth of CURRENCY is required to complete the transaction.": "Xin lưu ý rằng cần có {amount} trị giá {currency} để hoàn tất giao dịch.",
    "Please note that AMOUNT_CURRENCY is required for gas fee": "Xin lưu ý rằng cần có {amount_currency} cho phí xăng",
    "Select a payment method": "Chọn một Phương thức thanh toán",
    "Use your account's wallet balances to purchase the product.": "Sử dụng số dư ví trong tài khoản của bạn để mua sản phẩm.",
    "Connect a WEB3 wallet like Metamask or Trustwallet and pay with {currency}. This requires a small amount of BNB ($0.30) to cover the transaction fees!": "Kết nối ví WEB3 như Metamask hoặc Trustwallet và thanh toán bằng {currency}. Bạn sẽ cần bỏ ra một khoản BNB nhỏ ($0,30) để trả phí giao dịch!",
    "Select one of the available blockchains and cryptocurrencies, and proceed with the payment via an external wallet application.": "Chọn một trong các blockchain và tiền mã hóa khả dụng và tiến hành thanh toán qua ứng dụng ví ngoài.",
    "Internal wallet": "Ví nội bộ",
    "Web3 Wallet": "Ví Web3",
    "External wallet": "Ví ngoài",
    "Select Currency": "Chọn loại tiền",
    "Select from the available balances": "Chọn trong các số dư khả dụng",
    "Purchase for myself": "Mua cho mình",
    "Select Blockchain": "Chọn Blockchain",
    "Select Cryptocurrency": "Chọn Tiền mã hóa",
    "Please select another payment method": "Vui lòng chọn phương thức thanh toán khác",
    "Connect my wallet": "Kết nối với ví của tôi",
    "Select a product owner": "Chọn chủ sở hữu sản phẩm",
    "Please select who you are purchasing for:": "Vui lòng chọn người mà bạn đang mua cho:",
    "Product Owner Tooltip": "Chú giải về Chủ sở hữu SP",
    "Owner e-mail": "Email của Chủ sở hữu",
    "Purchase Summary": "Tóm tắt mua hàng",
    "Network": "Mạng",
    "Fees": "Phí",
    "Rate": "Tỷ giá",
    "Send to wallet address": "Gửi đến địa chỉ ví",
    "Or scan the code with your wallet app to fill in address automatically.": "Hoặc quét mã này bằng ứng dụng ví của bạn để tự động điền địa chỉ.",
    "Send only CURRENCY to this wallet address!": "Đừng gửi gì khác ngoài {currency} đến địa chỉ ví này!",
    "Please send your crypto within 30 minutes to ensure a fixed exchange rate": "Vui lòng gửi số tiền mã hóa của bạn trong vòng 30 phút để đảm bảo tỷ giá cố định",
    "I sent the crypto": "Tôi đã gửi tiền mã hóa đi",
    "Insufficient funds": "Không đủ tiền",
    "Purchase Successful": "Giao dịch thành công!",
    "The NFT balance was successfully updated and can be viewed in My NFTs tab.": "Bạn có thể theo dõi trạng thái giao dịch của mình trong \"Đơn hàng của tôi\" - Nếu giao dịch thành công, số dư NFT của bạn sẽ được cập nhật trong tab \"NFT của tôi\".",
    "Available balance": "Số dư khả dụng",
    "scanning_for_transaction_text": "Bạn có thể theo dõi trạng thái giao dịch của mình trong \"Đơn hàng của tôi\" - Nếu giao dịch thành công, số dư NFT của bạn sẽ được cập nhật trong tab \"NFT của tôi\".",
    "This window will close automatically once we receive your crypto.": "Cửa sổ này sẽ tự động đóng sau khi chúng tôi nhận được số tiền mã hóa bạn gửi.",
    "We did receive your crypto, but it was less than required. Please transfer the missing amount to complete your transaction.": "Chúng tôi đã nhận được số tiền mã hóa bạn gửi nhưng nó ít hơn con số yêu cầu. Vui lòng chuyển số tiền còn thiếu để hoàn tất giao dịch của bạn.",
    "Please do not attempt to do another transaction. Send your Payment UID to our support.": "Giao dịch hết hạn: vui lòng không cố thực hiện giao dịch khác. Hãy gửi ID giao dịch của bạn cho bộ phận hỗ trợ của chúng tôi.",
    "Scanning for transaction": "Quét mã để giao dịch",
    "Go to My Orders": "Tới trang Đơn hàng của tôi",
    "remaining": "còn lại",
    "Please send your crypto within 3 hours to ensure a fixed exchange rate": "Vui lòng gửi tiền điện tử của bạn trong vòng 3 giờ để đảm bảo tỷ giá hối đoái cố định",
    "Please send your crypto within n minutes to ensure a fixed exchange rate": "Vui lòng gửi tiền điện tử của bạn trong vòng {N} phút để đảm bảo tỷ giá hối đoái cố định",
    "Missing crypto amount": "Số tiền mã hóa bị thiếu",
    "Missing amount": "Số tiền còn thiếu",
    "Recived amount": "Số tiền đã nhận",
    "Required amount": "Số tiền yêu cầu",
    "Continue with payment": "Tiếp tục thanh toán",
    "No enough CURRENCY to cover gas fee.": "Không đủ {currency} để trả phí xăng.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover gas fee.": "Vui lòng nạp {amount_currency} vào ví của bạn để trả phí xăng.",
    "Scanning": "Quét",
    "To continue, please enable payment.": "Để tiếp tục, vui lòng kích hoạt thanh toán.",
    "Kindly be aware that AMOUNT_CURRENCY may be necessary to cover the gas fee.": "Vui lòng lưu ý rằng có thể cần {amount_currency} để trả phí xăng.",
    "Insufficient funds in CURRENCY to cover the gas fee.": "Không đủ tiền bằng {currency} để trả phí gas.",
    "Please top up your wallet with AMOUNT_CURRENCY to cover the gas fee.": "Vui lòng nạp {amount_currency} vào ví của bạn để trả phí xăng.",
    "We will cover the gas fee for this transaction on your behalf.": "Chúng tôi sẽ thay mặt bạn chi trả phí gas cho giao dịch này.",
    "Please choose your preferred payment method to finalize your purchase.": "Vui lòng chọn phương thức thanh toán ưa thích của bạn để hoàn tất giao dịch mua hàng.",
    "Your payment has expired. To resolve this issue, please contact customer support.": "Thanh toán của bạn đã hết hạn. Để giải quyết vấn đề này, vui lòng liên hệ bộ phận hỗ trợ khách hàng.",
    "Your payment has been received and is currently being processed. We will notify you once the process is complete.": "Thanh toán của bạn đã được nhận và đang được xử lý. Chúng tôi sẽ thông báo cho bạn sau khi quá trình hoàn tất.",
    "Please indicate the intended recipient of the product. You have the option to purchase it for yourself or choose 'Another user' as the recipient.": "Thanh toán của bạn đã được nhận và đang được xử lý. Chúng tôi sẽ thông báo cho bạn sau khi quá trình hoàn tất.",
    "Your transaction has been declined. Please attempt the transaction again.": "Giao dịch của bạn đã bị từ chối. Vui lòng thử thực hiện lại giao dịch.",
    "Monitor the status of your transaction by visiting the provided link:": "Theo dõi trạng thái giao dịch của bạn bằng cách truy cập liên kết được cung cấp:",
    "Apologies, an error occurred on the blockchain side. Please try again.": "Xin lỗi, đã xảy ra lỗi ở phía blockchain. Vui lòng thử lại.",
    "Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.": "Vui lòng đợi giao dịch đang diễn ra trên blockchain hoàn tất trước khi bắt đầu giao dịch mới.",
    "Your payment has expired. To resolve this issue, please contact customer support": "Thanh toán của bạn đã hết hạn. Để giải quyết vấn đề này, vui lòng liên hệ với bộ phận hỗ trợ khách hàng",
    "Your payment was refunded. AMOUNT CURRENCY was returned to your wallet.": "Thanh toán của bạn đã được hoàn trả. {amount} {currency} đã được trả lại ví của bạn.",
    "You can monitor the transaction status in the Order History tab. If it is successful, the NFT balance will be updated in the My NFTs tab.": "Bạn có thể theo dõi trạng thái giao dịch trong tab \"Lịch sử đặt hàng\". Nếu thành công, số dư NFT sẽ được cập nhật trong tab \"NFT của tôi\".",
    "OFF": "TẮT",
    "Received Amount.": "Số tiền đã nhận.",
    "Processing Fee": "Phí xử lý",
    "Estimated gas fee": "Phí gas ước tính",
    "Transaction Expired": "Giao dịch đã hết hạn",
    "Enable Payment": "Kích hoạt thanh toán",
    "Email does not exist": "Email không tồn tại",
    "Request another quote": "Yêu cầu báo giá khác",
    "Purchase Success!": "Mua hàng thành công!",
    "Purchase for another user": "Mua cho người dùng khác",
    "Pay": "Chi trả",
    "You purchased GIFT for RECEIVER!": "Bạn đã mua {gift} cho {receiver}!",
    "You've purchased GIFT for RECEIVER!": "Bạn đã mua {gift} cho \"{receiver}\"!",
    "You've purchased a GIFT for RECEIVER!": "Bạn đã mua một {gift} cho \"{receiver}\"!",
    "Rewards claimed successfully": "Nhận thưởng thành công",
    "Commissions claimed successfully.": "Hoa hồng đã được yêu cầu thành công.",
    "You received GIFT from SENDER!": "Bạn đã nhận được {gift} từ {sender}!",
    "You've received GIFT from SENDER!": "Bạn đã nhận được {gift} từ \"{sender}\"!",
    "You've received a GIFT from SENDER!": "Bạn đã nhận được {gift} từ \"{sender}\"!",
    "Transaction Failed": "Giao dịch không thành công",
    "The transaction has failed. Please try again.": "Giao dịch đã thất bại. Vui lòng thử lại.",
    "Your transaction has been declined. Please add BNB to your wallet and attempt the transaction again.": "Giao dịch của bạn đã bị từ chối. Vui lòng thêm BNB vào ví của bạn và thử thực hiện lại giao dịch.",
    "Blockchain Notification: Apologies, an error occurred on the blockchain side. Please try again.": "Thông báo chuỗi khối: Xin lỗi, đã xảy ra lỗi ở phía chuỗi khối. Vui lòng thử lại.",
    "Apologies, an unexpected error occurred. Please retry your request. If the issue persists, please contact support.": "Xin lỗi, đã xảy ra lỗi không mong muốn. Vui lòng thử lại yêu cầu của bạn. Nếu sự cố vẫn tiếp diễn, vui lòng liên hệ với bộ phận hỗ trợ.",
    "Received Amount": "Số tiền đã nhận",
    "Dual Miner": "Công cụ khai thác kép",
    "Bitopex": "Bitopex",
    "Gymnet": "Gymnet",
    "Value": "Giá trị",
    "Reach Rank": "Đạt hạng <b>{n}</b>",
    "Rewards in Gymnet": "Phần thưởng tại Gymnet",
    "Rewards in Bitcoin": "Phần thưởng tại Bitcoin",
    "For Limited Time Only": "Thời gian <strong>có hạn</strong>",
    "Seconds": "Giây",
    "Minutes": "Phút",
    "Days": "Ngày",
    "Hours": "Giờ",
    "Unclaimed parcels": "Lô chưa nhận",
    "Upgraded parcels": "Lô đã nâng cấp",
    "Inactive miners": "Máy khai thác không hoạt động",
    "Active miners": "Máy khai thác có hoạt động",
    "Available Balance": "Số dư khả dụng",
    "Utility Balance": "Số dư Utility",
    "Bitopex Balance": "Số dư Bitopex",
    "Moonberg Balance": "Số dư Moonberg",
    "Balance History": "Lịch sử số dư",
    "Miner Hashpower": "Hashpower của Máy",
    "Dual Miners": "Máy khai thác kép",
    "Dual miners info": "Số máy đang hoạt động và tổng Máy khai thác kép.",
    "Dual Miner Hashpower": "Hashpower của Máy",
    "Dual miners hashpower info": "Tổng Hashpower mà bạn sở hữu được xác định bằng cách nhân số lượng Máy khai thác kép đang hoạt động của bạn với đơn vị hashpower của 1 chiếc máy khai thác kép - tức 1000Mh/s trên mỗi chiếc.",
    "Unlock the full potential of your Bitopex rewards as they come to life following your purchase of any of our special bundles. Once your Bitopex Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Mở khóa toàn bộ tiềm năng của phần thưởng Bitopex ngay khi bạn mua bất kỳ gói đặc biệt nào của chúng tôi. Khi Số dư Bitopex của bạn đã có, bạn có thể xem xét tổng quan về tổng phần thưởng bạn có, phần thưởng đã nhận và phần thưởng dự kiến. Bên cạnh đó, chiếc đồng hồ đếm ngược cho tới lần phân phối phần thưởng tiếp theo sẽ giúp bạn không lo bỏ lỡ phần thưởng.",
    "Sec": "Giây",
    "Min": "Phút",
    "Total Rewards Expected": "Tổng phần thưởng dự kiến",
    "Monthly Distribution": "Phân phát hàng thàng",
    "Price Projection": "Dự báo giá",
    "Server error": "Lỗi server",
    "Average Gymnet ROI for the last 9 months": "ROI trung bình của Gymnet trong 9 tháng qua",
    "Dual Miners will generate rewards in Bitopex tokens, which will be distributed every Friday.":"Máy khai thác kép sẽ tạo ra phần thưởng dưới dạng token Bitopex và được phân phát vào thứ Sáu hàng tuần.",
    "Introducing the Utility Balance feature, a new and convenient payment method within our payment ecosystem. With this feature, you'll enjoy a separate balance that can be used alongside other payment methods. It provides you with the flexibility to partially utilize your utility balance, up to 25% of the total when purchasing single Land NFTs or making payments for Land NFT bundles.":"Giới thiệu tính năng Số dư Utility, một phương thức thanh toán mới và thuận tiện trong hệ sinh thái thanh toán của chúng tôi. Với tính năng này, bạn sẽ có một số dư riêng có thể được sử dụng cùng với các phương thức thanh toán khác. Nó cung cấp cho bạn sự linh hoạt để sử dụng một phần số dư tiện ích của bạn, tối đa 25% tổng số khi mua Land NFT đơn lẻ hoặc thanh toán cho các gói Land NFT.",
    "Balance before": "Số dư lúc trước",
    "Balance after": "Số dư lúc sau",
    "TXID": "TXID",
    "The number of active and total miners you have in Gymstreet and Kabutochō, respectively.": "Số lượng máy khai thác đang hoạt động và tổng số máy bạn có tại Gymstreet và Kabutochō",
    "The hashpower you possess is determined by multiplying the number of your active miners with the unit miner hashpower, which is set at 1000Mh/s per unit.": "Tổng Hashpower mà bạn sở hữu được xác định bằng cách nhân số lượng Máy khai thác đang hoạt động bạn có với đơn vị hashpower của 1 chiếc máy khai thác - tức 1000Mh/s trên mỗi chiếc.",
    "Token": "Token",
    "Buy Bundles": "Mua Gói",
    "Total Received": "Tổng đã nhận",
    "Next Rewards Distribution In:": "Đợt phát thưởng kế tiếp sẽ bắt đầu sau:",
    "Total Rewards": "Tổng Phần thưởng",
    "Payout": "Thanh toán",
    "Weekly": "Hàng tuần",
    "Next Distribution Amount": "Số tiền phân phối tiếp theo",
    "Internal transaction": "Giao dịch nội bộ",
    "Minimum claimable amount is (AMOUNT)": "Số tiền tối thiểu có thể yêu cầu là {amount}",
    "User Profile": "Hồ sơ người dùng",
    "Name": "Tên",
    "Nationality": "Quốc tịch",
    "Contact Information": "Thông tin liên hệ",
    "Country": "Quốc gia",
    "City": "Thành phố",
    "Street": "Đường",
    "Zip": "Mã Zipcode",
    "E-Mail": "Email",
    "Wallet address": "Địa chỉ Ví",
    "Name is required": "Bắt buộc phải điền ô Tên",
    "Nationality is required": "Bắt buộc phải điền ô Quốc tịch",
    "Country is required": "Bắt buộc phải điền ô Quốc gia",
    "City is required": "Bắt buộc phải điền ô Thành phố",
    "Street is required": "Bắt buộc phải điền ô Đường",
    "Zip is required": "Bắt buộc phải điền ô Mã Zipcode",
    "Successfully updated": "Đã cập nhật thành công",
    "Zip contain only numbers and min length is 3": "Mã Zipcode chỉ được chứa các chữ số, độ dài tối thiểu là 3 số",
    "Please enter a valid email address": "Vui lòng nhập địa chỉ email hợp lệ",
    "Personal information": "Thông tin cá nhân",
    "T&C": "Điều khoản & Điều kiện",
    "Field is required": "Đây là mục bắt buộc",
    "Password successfully changed": "Đã thay đổi mật khẩu thành công",
    "Old password is incorrect": "Mật khẩu cũ không đúng",
    "Oops...Something went wrong. Please try again": "Có lỗi xảy ra, vui lòng thử lại",
    "Name must be at least 3 characters": "Tên phải chứa ít nhất 3 ký tự",
    "City must be at least 3 characters": "Ô Thành phố phải chứa ít nhất 3 ký tự",
    "Street must be at least 3 characters": "Ô Đường phải chứa ít nhất 3 ký tự",
    "Affiliate Terms and conditions": "Điều khoản & Điều kiện Giới thiệu",
    "Affiliate links": "Link Giới thiệu",
    "Affiliate link successfully changed": "Đã thay đổi Link Giới thiệu thành công",
    "Please enter the Bitopex affiliate link here. Kindly note that you can only save the link once.": "Vui lòng nhập Link Giới thiệu Bitopex tại đây. Xin lưu ý: bạn chỉ có thể bấm lưu Link này một lần duy nhất.",
    "Input cannot be only spaces": "Đầu vào không thể chỉ có khoảng trắng",
    "To proceed with the registration, kindly accept the above 2 conditions by checking the corresponding boxes.": "Để tiến hành đăng ký, vui lòng chấp nhận 2 điều kiện trên bằng cách đánh dấu vào các ô tương ứng.",
    "I hereby confirm that I am not a US citizen": "Tôi xin xác nhận rằng tôi không phải là công dân Hoa Kỳ",
    "I hereby confirm that I am not a UAE citizen": "Tôi xin xác nhận rằng tôi không phải là công dân UAE",
    "Enter Old Password": "Nhập mật khẩu cũ",
    "Change username & password": "Thay đổi tên người dùng và mật khẩu",
    "My wallet": "Ví của tôi",
    "Purchase": "Mua",
    "Username required to proceed": "Yêu cầu tên người dùng để tiếp tục",
    "Enter preferred username here": "Nhập tên người dùng ưa thích ở đây",
    "Username must be at least 8 characters, starting with a letter. Use only lowercase letters, numbers, hyphens, dots, and underscores.": "Tên đăng nhập phải có ít nhất 8 ký tự, bắt đầu bằng một chữ cái. Chỉ sử dụng chữ thường, số, dấu gạch ngang, dấu chấm và dấu gạch dưới.",
    "Username is already taken. Please choose a different username.": "Tên người dùng đã được sử dụng. Xin hãy chọn tên người dùng khác.",
    "Email or username": "Thư điện tử hoặc tên người dùng",
    "Username is available": "Tên người dùng có sẵn",
    "Provided username is already taken.": "Tên người dùng được cung cấp đã được sử dụng.",
    "Invalid credentials. Please try again.": "Thông tin không hợp lệ.\n Vui lòng thử lại.",
    "Username successfully set": "Tên người dùng được đặt thành công",
    "Dear User, due to ongoing updates to our platform, you will need to enter a username to continue. The username needs to be unique and cannot be changed": "Kính gửi người dùng, do các bản cập nhật liên tục cho nền tảng của chúng tôi, bạn sẽ cần nhập tên người dùng để tiếp tục. Tên người dùng phải là duy nhất và không thể thay đổi",
    "You have successfully set your username": "Bạn đã đặt thành công tên người dùng của mình",
    "Continue to dashboard": "Tiếp tục đến trang tổng quan",
    "Username is required": "tên người dùng là bắt buộc",
    "Rank Transfer": "Chuyển hạng",
    "Review": "Ôn tập",
    "Top Up": "nạp tiền",
    "The claim button is currently disabled for maintenance. We apologize for the inconvenience. Please come back shortly.": "Nút yêu cầu hiện đang bị vô hiệu hóa để bảo trì. Chúng tôi xin lỗi vì sự bất tiện này. Xin vui lòng quay lại trong thời gian ngắn.",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / BUSD": "Khi một tòa nhà kinh doanh mới được dựng lên, bạn sẽ có \n lựa chọn mua đất bằng cách thanh toán bằng GYMNET / BUSD",
    "Parcel NFT": "Parcel NFT",
    "Miner NFT": "Miner NFT",
    "BUSD Balance": "Số dư BUSD",
    "Your payment has been received. It is currently being processed. You will receive a notification when done.": "Khoản thanh toán của bạn đã được nhận. Nó hiện đang được xử lý. Bạn sẽ nhận được thông báo khi hoàn tất.",
    "Error": "Lỗi",
    "Please enter valid email": "Vui lòng nhập email hợp lệ",
    "Your sponsor is": "Người giới thiệu của bạn là",
    "Credentials": "Thông tin xác thực",
    "Enter Wallet Address (i.e. 0xb794f...79268)": "Nhập Địa chỉ Ví (VD: 0xb794f...79268)",
    "Contact Customer Support": "Liên hệ bộ phận CSKH",
    "Change Wallet": "Thay đổi Ví",
    "Account": "Tài khoản",
    "Please leave an address here, in which we can also mint your NFTs afterwards.": "Vui lòng để lại địa chỉ ở đây để về sau chúng tôi cũng có thể đúc NFT của bạn tại đó.",
    "Important!": "Quan trọng!",
    "It should be a BEP-20 address - i.e. a BNB Smart Chain wallet address. We recommend the wallet Metamask or Trustwallet.": "Đó phải là địa chỉ BEP-20 - tức là địa chỉ ví BNB Smart Chain. Chúng tôi khuyên dùng ví Metamask hoặc Trustwallet.",
    "NEVER USE AN EXCHANGE Wallet ADRESS !!!": "ĐỪNG BAO GIỜ DÙNG ĐỊA CHỈ VÍ CỦA SÀN GIAO DỊCH!!!",
    "Failed": "Thất bại",
    "Account changed": "Tài khoản đã thay đổi",
    "Your Profile has been updated": "Hồ sơ của bạn đã được cập nhật",
    "You already have an attached wallet address to this account.": "Bạn đã có một địa chỉ ví liên kết với tài khoản này.",
    "Login": "Đăng nhập",
    "Existing wallet": "Ví hiện có",
    "Referral ID is required": "Bắt buộc phải điền ID giới thiệu",
    "Pending Transaction": "Giao dịch đang chờ xử lý",
    "Processing": "Đang xử lý",
    "Send this payment ID to a customer support when you are requested to do so.": "Hãy gửi ID thanh toán này tới bộ phận CSKH khi bạn được yêu cầu làm như vậy.",
    "To be able to claim your NFTs you need to connect the same wallet address attached to your account": "Để có thể nhận NFT của mình, bạn cần kết nối với cùng một địa chỉ ví đã được liên kết với tài khoản của mình",
    "Please confirm your email to continue.": "Vui lòng xác nhận email của bạn để tiếp tục.",
    "Valid address": "Địa chỉ hợp lệ",
    "This social account is used by another user. Verify your email first or contact Customer Support": "Tài khoản mạng xã hội này đã được sử dụng bởi người dùng khác. Hãy xác minh email của bạn trước hoặc liên hệ với bộ phận CSKH",
    "Invalid wallet connection": "Kết nối ví không hợp lệ",
    "Registration completed successfully": "Đã hoàn tất đăng ký",
    "Failed to complete registration of the user": "Không thể hoàn tất đăng ký người dùng",
    "Failed to register user": "Không thể đăng ký người dùng",
    "Signature grant failed": "Cấp chữ ký không thành công",
    "Unsupported chain": "Chain này không được hỗ trợ",
    "This wallet address is already associated with another user account. Please use another one!": "Địa chỉ ví này đã được liên kết với một tài khoản người dùng khác. Vui lòng dùng địa chỉ khác!",
    "Failed to add address to your wallet": "Không thể thêm địa chỉ vào ví của bạn",
    "Address has been successfully added to your wallet": "Địa chỉ đã được thêm thành công vào ví của bạn",
    "Failed to accept Affiliate TMS": "Thất bại khi chấp nhận Affiliate TMS",
    "Wallet address has been successfully applied": "Địa chỉ ví đã được áp dụng thành công",
    "Your email is verified.": "Email của bạn đã được xác minh.",
    "Failed to add Token": "Không thể thêm Token",
    "Failed to upload image": "Không thể tải hình ảnh lên",
    "Wrong file size. Please upload image files with size less than 10MB": "Sai kích thước tập tin. Vui lòng tải lên file hình ảnh có kích thước nhỏ hơn 10MB",
    "We've verified your email address. You can now continue using the site.": "Chúng tôi đã xác minh địa chỉ email của bạn. Bây giờ bạn có thể tiếp tục sử dụng website.",
    "You need to switch to Binance Smart Chain": "Bạn cần chuyển sang Binance Smart Chain",
    "Switch to Binance Smart Chain to continue using GymStreet": "Chuyển sang Binance Smart Chain để tiếp tục sử dụng GymStreet",
    "To CashFT": "Đến CashFT",
    "Buy a Super Bundle in the next": "Mua Super Bundle trong lần tiếp theo",
    "and get an EXTRA discount!": "để được giảm giá THÊM!",
    "See All Offers": "Xem tất cả Offer",
    "I accept the affiliate terms & conditions": "Tôi chấp nhận các điều khoản và điều kiện về affiliate",
    "Special offer!": "Offer đặc biệt!",
    "Just one": "Chỉ một",
    "1000 Mh/s": "1000 MHz/giây",
    "View Claimed Coordinates": "Xem tọa độ được nhận",
    "Your Parcel Coordinates": "Tọa độ Lô đất của bạn",
    "Number of miners you want to attach": "Số lượng Máy đào bạn muốn lắp",
    "Referral Link": "Link Giới thiệu",
    "Affiliate Link": "Link Giới thiệu",
    "Invalid Wallet": "Ví không hợp lệ",
    "Back to Log In": "Quay lại trang Đăng nhập",
    "You need a valid referral ID to finalize your registration": "Bạn cần có ID giới thiệu hợp lệ để hoàn tất quy trình đăng ký",
    "I have read and accept the": "Tôi đã đọc và đồng ý với",
    "Your Sponsor": "Người giới thiệu của bạn",
    "Forgot Your Password": "Quên mật khẩu",
    "Enter your email address and we will send you instructions to reset your password.": "Hãy nhập địa chỉ email của bạn, chúng tôi sẽ gửi cho bạn hướng dẫn để đặt lại mật khẩu.",
    "We just sent you an email to confirm your e-mail address. Please click on the link in the e-mail to reset your password.": "Chúng tôi vừa gửi cho bạn một email để xác nhận địa chỉ email của bạn. Vui lòng nhấn vào link trong email để đặt lại mật khẩu của bạn.",
    "Welcome To Metaverse": "Chào mừng đến với {n} Metaverse",
    "Commissions": "Hoa hồng",
    "Refund of electricity vouchers": "Hoàn trả tiền điện",
    "Import Custom Token": "Nhập Token tùy chỉnh",
    "Add Token To Wallet": "Thêm Token vào ví",
    "Token contract address": "Địa chỉ hợp đồng Token",
    "Token symbol": "Mã Token",
    "Provided address is not a supported token address": "Địa chỉ được cung cấp không phải là địa chỉ Token được hỗ trợ",
    "Are you sure you want to claim your rewards": "Bạn có chắc chắn muốn nhận phần thưởng của mình không",
    "OTP Code": "Mã OTP",
    "OTP Code is required": "Bắt buộc phải nhập mã OTP",
    "Enter OTP code you have received at email": "Nhập mã OTP bạn đã nhận được tại  {email}",
    "Asset": "Tài sản",
    "Gas": "Gas",
    "Send crypto": "Gửi tiền mã hóa",
    "Enter wallet address": "Nhập địa chỉ ví",
    "NFTs": "NFT",
    "Activity": "Hoạt động",
    "Assets": "Tài sản",
    "Import token": "Nhập Token",
    "Don't see your token?": "Không thấy Token của bạn?",
    "more": "thêm",
    "Send": "Gửi",
    "Sending success": "Gửi thành công",
    "View account in explorer": "Xem tài khoản trong Explorer",
    "estimated": "ước tính",
    "Copy your address": "Sao chép địa chỉ của bạn",
    "Export private key": "Xuất private key",
    "Receive": "Nhận",
    "Account Details": "Chi tiết tài khoản",
    "View on blockchain": "Xem trên blockchain",
    "GymStreet Parcels": "Lô đất tại GymStreet",
    "GymStreet Miners": "Máy đào tại GymStreet",
    "Kabutocho Parcels": "Lô đất tại Kabutocho",
    "Kabutocho Miners": "Máy đào tại Kabutocho",
    "Invalid wallet address": "Địa chỉ ví không hợp lệ",
    "Calculate": "Tính toán",
    "Sending": "Đang gửi",
    "Reject": "Bị từ chối",
    "Confirmation": "Xác nhận",
    "Gas fees are paid to crypto miners who process transactions on the network. Gas fees are set by the network and fluctate based on network traffic and transaction complexity.": "Phí gas được trả cho những người đào tiền mã hóa để xử lý các giao dịch trên mạng. Phí gas do mạng đặt ra và dao động dựa trên lưu lượng mạng và độ phức tạp của giao dịch.",
    "Insufficient TOKEN balance": "Số dư {token} không đủ",
    "Confirm Swap": "Xác nhận Swap",
    "Approve Allowance": "Phê duyệt Cấp phép ",
    "Help Center": "Trung tâm Trợ giúp",
    "Confirm the Allowance Transaction in order to be able to use Token through our services. Please note that this is a one-time action.": "Xác nhận Cấp phép Giao dịch để có thể sử dụng Token <strong>{n}</strong> thông qua các dịch vụ của chúng tôi. Xin lưu ý rằng đây là hành động diễn ra một lần.",
    "No Activities yet": "Chưa có hoạt động nào",
    "To": "Đến",
    "Contract interaction": "Tương tác hợp đồng",
    "Are you sure you want to delete this token": "Bạn có chắc chắn muốn xóa Token này không",
    "Send only using  {CHAIN}  network to receive funds on this deposit address.": "Chỉ gửi bằng mạng {CHAIN} để nhận tiền trên địa chỉ gửi tiền này.",
    "Leadership Incentives": "Thưởng Lãnh đạo",
    "Pool statistics": "Thống kê Pool",
    "Hourly": "Mỗi giờ",
    "Turnover rule": "Quy tắc doanh thu",
    "Your purchases": "Giao dịch mua của bạn",
    "Turnover rule percentage": "{n}% Quy tắc doanh thu",
    "Miner sales contribution": "Đóng góp bán Máy đào",
    "Pool": "Pool",
    "percent of NFT sales": "{n}% doanh số bán NFT",
    "partner": "Đối tác",
    "Main area": "Khu vực chính",
    "Turnover percentage rule": "Quy tắc doanh thu {n}%",
    "With dollar each": "Với mỗi đô la",
    "Direct partners": "Đối tác trực tiếp",
    "Your next bonus will appear here": "Phần thưởng tiếp theo của bạn sẽ xuất hiện ở đây",
    "Your incentive": "Thưởng của bạn",
    "Your cash bonus": "Thưởng tiền mặt của bạn",
    "Your bonus history": "Lịch sử thưởng của bạn",
    "At the current moment you don't have any rewards": "Hiện tại bạn chưa có phần thưởng nào",
    "Partners with pin qualification": "Đối tác có tư cách pin {n}",
    "Team of": "Đội ngũ gồm",
    "Total generated turnover": "Tổng doanh thu tạo ra",
    "Active Miners": "Máy đào đang hoạt động",
    "Total pool rewards": "Tổng phần thưởng Pool",
    "Your pool share": "Thị phần Pool của bạn",
    "Total gGymnet": "Tổng gGymnet",
    "GYMNET Commissions Total": "Tổng hoa hồng GYMNET",
    "Your attached wallet is {walletAddress}. You have tried to make request with wallet {currentWallet}. Please change connected wallet to {walletAddress}": "Ví liên kết của bạn là <b>{walletAddress}</b>. Bạn đã thử gửi yêu cầu bằng ví <b>{currentWallet}</b>. Vui lòng thay đổi ví kết nối thành <b>{walletAddress}</b>",
    "Available for limited time": "Cơ hội có hạn",
    "Bonus": "Thưởng",
    "Utility": "Utility",
    "The URL must begin with 'https://bitopex.io/home/' and include your referral ID": "URL phải bắt đầu bằng \"https://bitopex.io/home/\" và chứa ID giới thiệu của bạn",
    "Insufficient funds: to continue with payment use utility balance": "Không đủ tiền: tiếp tục thanh toán sử dụng số dư utility",
    "My Team": "Đội nhóm của tôi",
    "Team Statistics": "Thống kê đội nhóm",
    "All partners": "Tất cả Đối tác",
    "Active partners": "Đối tác đang hoạt động",
    "Inactive partners": "Đối tác không hoạt động",
    "Binary Points": "Điểm nhị phân",
    "Points in left leg": "Điểm ở Nhánh trái",
    "Points in right leg": "Điểm ở Nhánh phải",
    "Left leg": "Chân trái",
    "Right leg": "Chân phải",
    "Holding Tank": "Holding Tank",
    "Enrollment Tree": "Cây Giới thiệu",
    "Binary Tree": "Cây Nhị phân",
    "CV Points History": "Lịch sử điểm CV",
    "Time Left To Activation": "Thời gian còn lại để <b>kích hoạt</b>",
    "Search by email or wallet": "Tìm kiếm bằng email hoặc ví",
    "Refresh Data": "Làm mới dữ liệu",
    "Joined at": "Tham gia vào",
    "Placement": "Vị trí",
    "Select": "Lựa chọn",
    "Own": "Cá nhân",
    "Team": "Đội nhóm",
    "Position": "Vị trí",
    "CV Points": "Điểm CV",
    "CV = commission volume": "CV = khối lượng hoa hồng",
    "Genealogy": "Cây cấu trúc",
    "Unilevel": "Đa cấp độ",
    "Select view": "Chọn chế độ xem",
    "Team size": "Kích thước nhóm",
    "Rank": "Hạng",
    "Own purchases": "Mua cá nhân",
    "Navigation": "Điều hướng",
    "Search by wallet": "Tìm kiếm theo ví",
    "no user": "không có người dùng",
    "Wallet address is not valid": "Địa chỉ ví không hợp lệ",
    "Free Spot": "Chỗ miễn phí",
    "Search by wallet or username": "Tìm kiếm theo ví hoặc tên người dùng",
    "Info": "Thông tin",
    "Benefits": "Lợi ích",
    "Objectives Completed": "Mục tiêu đã hoàn thành",
    "TO DO`s": "CẦN LÀM",
    "Purchase NFTs In Total Value Of 100 USDT": "Mua NFT với tổng giá trị là 100 USDT",
    "Purchase Binary Pass - 19 USDT": "Mua Binary Pass - 19 USDT",
    "Team Turnover": "Doanh thu đội nhóm",
    "BASED ON TEAM": "{n}% + DỰA TRÊN ĐỘI NHÓM",
    "Qualification Turnover": "Điều kiện về Doanh thu",
    "Current Rank": "Hạng hiện tại",
    "Next Rank": "Hạng tiếp theo",
    "Binary Spot Reservation": "Chỗ trên cây nhị phân",
    "Holding Tank Ends In": "Holding Tank <b>kết thúc vào</b>",
    "With every parcel purchase, you will receive two types of tokens—Gymnet and Bitcoin.": "Với mỗi lần mua bưu kiện, bạn sẽ nhận được hai loại mã thông báo—Gymnet và Bitcoin.",
    "A minimum of USDT is required for Purchase": "Cần có tối thiểu ${n} USDT để mua hàng",
    "Become Affiliate Partner": "Trở thành Đối tác Affiliate",
    "Placement in Unilevel": "Vị trí trong cây đa cấp",
    "Matching bonuses": "Phần thưởng Cân cặp",
    "Placement in Binary": "Vị trí trong cây nhị phân",
    "Product Autoship Incentive": "Thưởng Autoship Sản phẩm",
    "For limited time only": "Cơ hội có hạn",
    "Free 30 days Product Autoship": "Miễn phí Autoship Sản phẩm trong 30 ngày",
    "Fast Start Incentive": "Thưởng Khởi đầu nhanh",
    "Receive Direct Sales Commissions": "Nhận hoa hồng Bán hàng Trực tiếp",
    "Please select your role": "Vui lòng chọn vai trò của bạn",
    "Choose your role before diving in. Specify why user should choose each role and what opportunities are there.": "Hãy chọn vai trò của bạn trước khi bắt đầu. Nêu lý do tại sao người dùng nên chọn và những cơ hội của từng vai trò.",
    "is left to get": "Còn <span>${n}</span> thì được nhận",
    "Go to Products": "Đi đến trang Sản phẩm",
    "Rental Income": "Thu nhập cho thuê",
    "Left Leg Affiliate Link": "Link Giới thiệu Nhánh trái",
    "Right Leg Affiliate Link": "Link Giới thiệu Nhánh phải",
    "Qualification Conditions": "Tiêu chí đủ điều kiện",
    "Buy Products": "Mua sản phẩm",
    "My Current Rank": "Hạng hiện tại của tôi",
    "To Reach Rank You Need:": "Để đạt được Hạng {n} bạn cần:",
    "NFT Turnover In Weak Leg": "Doanh thu NFT ở Nhánh yếu",
    "NFT Own purchases": "Cá nhân mua NFT",
    "Required Qualified Partners": "Tiêu chí về Đối tác",
    "Progress Until Rank": "Thắng tiến cho đến Hạng {n}",
    "See Details": "Xem Chi tiết",
    "Default Advantages": "Lợi thế mặc định",
    "Binary Commission": "Hoa hồng nhị phân",
    "Upgrade Your Weekly Cap": "Nâng cấp giới hạn hàng tuần của bạn",
    "Rank Benefits": "Hạng lợi ích",
    "For 30 Days ONLY You Receive 10% Binary Commission Instead Of NUM": "Bạn sẽ nhận được 10% hoa hồng nhị phân thay vì {n}%, chỉ trong 30 NGÀY!",
    "My Rank Progress": "Tiến độ xếp hạng của tôi",
    "Own Invest": "Tự đầu tư",
    "Qualified Partners": "Đối tác đủ điều kiện",
    "You need to buy products in value of": "Bạn cần mua sản phẩm có giá trị ${n}",
    "You need to generate turnover in value of in your weak leg.": "Bạn cần tạo ra doanh thu trị giá ${n} ở Nhánh yếu trong cấu trúc của mình.",
    "Remaining": "Còn lại",
    "CV Points weak leg": "Điểm CV ở Nhánh yếu",
    "Details": "Chi tiết",
    "Partner": "Đối tác",
    "leg": "Nhánh",
    "Right": " phải",
    "Left": " trái",
    "You need qualified partner in left leg and qualified partner in right leg to complete this requirement.": "Bạn cần có {l} đối tác đủ điều kiện ở Nhánh trái và {r} đối tác đủ điều kiện ở Nhánh phải để hoàn thành yêu cầu này.",
    "partners in left leg and in right leg": "{l} {partner1} ở Nhánh trái và {r} đối tác ở Nhánh phải còn thiếu",
    "partners in left leg remaining": "{l} {đối tác1} ở Nhánh trái còn thiếu",
    "partners in right leg remaining": "{r} đối tác ở nhánh phải còn thiếu",
    "partner in right leg remaining": "{r} đối tác ở nhánh phải còn thiếu",
    "Holding Tank is over": "Holding Tank đã kết thúc",
    "Back To Rank Progress Details": "Về trang chi tiết tiến độ xếp hạng",
    "Super Bundle R10 or above": "Super Bundle R10\n hoặc trên",
    "Required Monthly Product Autoship": "Tiêu chí về Autoship SP hàng tháng",
    "All Customer Benefits": "Tất cả quyền lợi KH",
    "Product Autoship": "Autoship Sản phẩm",
    "Product Autoships": "Autoship Sản phẩm",
    "Products are resellable": "Sản phẩm có thể bán lại",
    "Activate binary commission": "Kích hoạt hoa hồng nhị phân",
    "Activate direct sales commission": "Kích hoạt hoa hồng bán hàng trực tiếp",
    "Left before": "Trái trước",
    "Left after": "Trái sau",
    "Right before": "Phải trước",
    "Right after": "Phải sau",
    "Activate your referral link to be able to invite your referrals to the platform and earn additional commissions.": "Kích hoạt Link Giới thiệu của bạn để có thể mời những đối tác của bạn đến nền tảng và kiếm thêm hoa hồng.",
    "Activate referral link": "Kích hoạt Link Giới thiệu",
    "Yes, I confirm": "Phải, tôi xác nhận",
    "Inactive": "Không hoạt động",
    "Finance": "Tài chính",
    "Total Income": "Tổng thu nhập",
    "Products": "Sản phẩm",
    "GymStreet Map": "Bản đồ GymStreet",
    "Kabutocho Map": "Bản đồ Kabutocho",
    "Don't ask again": "Không hỏi lại",
    "Filecoin Balance": "Số dư Filecoin",
    "Filecoin": "Filecoin",
    "Total available": "Tổng số có sẵn",
    "Total generated": "Tổng số được tạo",
    "Total used": "Tổng số đã sử dụng",
    "Transaction type": "Loại giao dịch",
    "To claim your commission, you must have a minimum of 1 USDT or (AMOUNT) GYMNET.": "Để nhận hoa hồng, bạn phải có tối thiểu 1 USDT hoặc {n} GYMNET.",
    "Loading balance...": "Đang tải số dư...",
    "Total Commissions": "Tổng hoa hồng",
    "Claimed Commissions": "Hoa hồng đã nhận",
    "Available Commissions": "Hoa hồng hiện có",
    "Total Commissions By Source": "Tổng hoa hồng theo nguồn",
    "Filter by currency": "Lọc theo loại tiền tệ",
    "No data to display": "Không có dữ liệu để hiển thị",
    "Year": "Năm",
    "Career Status": "Status Sự Nghiệp",
    "Current Incentive": "Thưởng hiện tại",
    "Fast Start": "Khởi đầu nhanh",
    "Product Autoship incentive": "Thưởng Autoship Sản phẩm",
    "Direct partner - left leg": "Đối tác trực tiếp - nhánh trái",
    "Direct partner - right leg": "Đối tác trực tiếp - nhánh phải",
    "Receive direct sales commission": "Nhận hoa hồng bán hàng trực tiếp",
    "Direct sales commission": "Hoa hồng bán hàng trực tiếp",
    "Receive binary commission": "Nhận hoa hồng nhị phân",
    "Affiliate ranks": "Cấp bậc Affiliate ",
    "1 Left, 1 Right": "1 trái, 1 phải",
    "See Benefits": "Xem Lợi ích",
    "2 Silver (1 in different teams)": "2 Silver (mỗi team khác nhau có 1)",
    "2 Gold (1 in different teams)": "2 Gold (mỗi team khác nhau có 1)",
    "3 Platin (1 in different teams)": "3 Platin (mỗi team khác nhau có 1)",
    "3 Double Platin (1 in different teams)": "3 Double Platin (mỗi team khác nhau có 1)",
    "4 Diamond (1 in different teams)": "4 Diamond (mỗi team khác nhau có 1)",
    "5 Double Diamond (1 in different teams)": "5 Double Diamond (mỗi team khác nhau có 1)",
    "5 Red Diamond (1 in different teams)": "5 Red Diamond (mỗi team khác nhau có 1)",
    "5 White Diamond (1 in different teams)": "5 White Diamond (mỗi team khác nhau có 1)",
    "Please note that $100 dollar will correspond to 100 points.": "Xin lưu ý rằng $100 sẽ tương ứng với 100 điểm.",
    "Home": "Trang chủ",
    "Last Left": "Ở cuối Nhánh trái",
    "Last Right": "Ở cuối Nhánh phải",
    "Binary leg": "Nhánh Nhị phân",
    "by admin": "bởi admin",
    "missed commission": "hoa hồng bị bỏ lỡ",
    "payment": "thanh toán",
    "commission": "hoa hồng",
    "Payment Currency": "Tiền tệ thanh toán",
    "BUSD Amount": "Số tiền BUSD",
    "CV Ratio": "Tỷ lệ CV",
    "Hrs": "Giờ",
    "Mins": "Phút",
    "Secs": "Giây",
    "Please enter a valid referral ID": "Vui lòng nhập ID giới thiệu hợp lệ",
    "Commission TXID": "TXID hoa hồng",
    "Qualification turnover": "Điều kiện về Doanh thu",
    "Binary commission": "Hoa hồng nhị phân",
    "Additional Advantages": "Ưu điểm bổ sung",
    "The referral ID you've entered is incorrect. Please copy and paste the correct referral link to proceed.": "ID giới thiệu bạn đã nhập không chính xác. Vui lòng sao chép và dán đúng Link Giới thiệu để tiếp tục.",
    "In order to receive binary commissions you need to have an active product autoship.": "Để nhận được hoa hồng nhị phân, bạn cần phải có chế độ tự động vận chuyển sản phẩm đang hoạt động.",
    "In order to receive binary commissions you need to have an active product autoship and a purchase for at least $100.": "Để nhận được hoa hồng nhị phân, bạn cần phải có sản phẩm tự động vận chuyển đang hoạt động và mua hàng với giá ít nhất là 100 USD.",
    "To qualify for binary commissions, a minimum product purchase of $100 is required.": "Để đủ điều kiện nhận hoa hồng nhị phân, bạn phải mua sản phẩm tối thiểu là 100 USD.",
    "Transaction in Process": "Giao dịch đang được xử lý",
    "Claim your parcel": "Yêu cầu bưu kiện của bạn",
    "You do not have any claimed slots to attach miners. To enable miner attachment, please claim parcels on the map.": "Bạn không có bất kỳ vị trí nào được xác nhận để gắn công cụ khai thác. Để kích hoạt tính năng đính kèm công cụ khai thác, vui lòng yêu cầu các bưu kiện trên bản đồ.",
    "Active binary commission": "Hoa hồng nhị phân đang hoạt động",
    "Active direct sales commission": "Hoa hồng bán hàng trực tiếp đang hoạt động",
    "Own purchase min {AMOUNT}": "Tự mua tối thiểu {AMOUNT}",
    "Active product autoship": "Tự động vận chuyển sản phẩm đang hoạt động",
    "Right partner own purchase min {AMOUNT}": "Giao dịch mua riêng của đối tác phù hợp tối thiểu {AMOUNT}",
    "Left partner own purchase min {AMOUNT}": "Giao dịch mua của đối tác còn lại tối thiểu {AMOUNT}",
    "Unfortunately, you won't receive the bonus for this rank since you met the qualification conditions (more than 50%) before September 18, 2023": "Rất tiếc, bạn sẽ không nhận được tiền thưởng cho cấp bậc này vì bạn đã đáp ứng các điều kiện đủ tiêu chuẩn (trên 50%) trước ngày 18 tháng 9 năm 2023",
    "You will be eligible for cash bonus based on your rank if you meet all qualification conditions between September 18 and November 8.Those who met the qualification conditions (more than 50%) before September 18, 2023 will not receive the Super Cash Bonus.": "Bạn sẽ đủ điều kiện nhận tiền thưởng dựa trên thứ hạng của mình nếu bạn đáp ứng tất cả các điều kiện đủ điều kiện trong khoảng thời gian từ ngày 18 tháng 9 đến ngày 31 tháng 1. Những người đáp ứng các điều kiện đủ điều kiện (trên 50%) trước ngày 18 tháng 9 năm 2023 sẽ không nhận được Tiền thưởng Siêu tiền mặt.",
    "When a new business building is being implemented, you will have the option to buy land by paying with GYMNET / USDT": "Khi một tòa nhà kinh doanh mới được dựng lên, bạn sẽ có, bạn sẽ có \n quyền chọn mua đất bằng cách dùng GYMNET / USDT để thanh toán",
    "yearly": "Hàng năm",
    "Unknown": "Không rõ",
    "Claim processing may take up to 2 minutes. Thank you for your patience.": "Quá trình xử lý yêu cầu có thể mất tới 2 phút. Cảm ơn vì sự kiên nhẫn của bạn.",
    "1 Person": "1 Người",
    "2 Persons": "2 Người",
    "Extended Referral Link": "Referral Link mở rộng",
    "Enter a username": "Điền Tên người dùng",
    "Possible only for usernames in your Binary team!": "Chỉ có thể là tên người dùng trong Team Binary của bạn!",
    "Select Team": "Chọn Team",
    "Create Link": "Tạo Link",
    "New users will be placed under in the team": "Người dùng mới sẽ được xếp vào {n} trong team {p}",
    "Invalid username!": "Tên người dùng không hợp lệ!",
    "Left team": "Team Trái",
    "Right team": "Team Phải",
    "Distribution In Progress": "Đang phân phối",
    "Next Binary Bonus Distribution In": "Còn cách đợt phân phối Binary Bonus tiếp theo",
    "Matching Bonus Commissions": "Hoa hồng Matching Bonus",
    "Binary Commissions": "Hoa hồng Binary",
    "Binary Weekly Cap": "Giới hạn Binary hàng tuần",
    "Binary Cycles": "Chu kỳ Binary",
    "Matching Bonus": "Matching Bonus",
    "Incomes from Product Autoships": "Thu nhập từ Product Autoships",
    "Missed Commissions": "Hoa hồng bị bỏ lỡ",
    "Plus Max Weekly Payout": "Cộng khoản thanh toán tối đa hàng tuần",
    "Import Token With Address": "Import Token kèm Địa chỉ",
    "Import Supported Token": "Import Token được hỗ trợ",
    "Shop": "Shop",
    "Filter by": "Lọc theo",
    "My Purchases": "GD mua của tôi",
    "Purchases for other members": "Mua cho các thành viên khác",
    "Product Autoship Purchases": "Mua Product Autoship",
    "Sort By": "Sắp xếp theo",
    "Top up": "Nạp tiền",
    "Attach your miners": "Gắn Máy đào",
    "Buy parcels": "Mua lô đất",
    "Number of parcels you want to buy": "Số lô đất bạn muốn mua",
    "Use utility balance": "Sử dụng số dư utility",
    "My Products": "Sản phẩm của tôi",
    "USDT Amount": "Số USDT",
    "Next Matching Bonus Distribution In": "Còn cách đợt phân phối Matching Bonus tiếp theo",
    "Please top up your wallet with AMOUNT_CURRENCY USDT for upgrade.": "Vui lòng nạp {AMOUNT_CURRENCY} USDT vào ví của bạn để nâng cấp.",
    "Please note that worth of USDT is required to complete the transaction.": "Xin lưu ý, bạn cần có một lượng USDT trị giá {n} để hoàn tất giao dịch này.",
    "Incentive Ends In:": "Ưu đãi sẽ kết thúc sau:",
    "Bonus History": "Lịch sử Bonus",
    "Incentive has ended": "Ưu đãi đã kết thúc",
    "Direct Sales Turnover": "Doanh thu Bán hàng Trực tiếp",
    "Commissions are paid out following a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "Hoa hồng được thanh toán theo tỷ lệ 70/30, với 70% dưới dạng stablecoin và 30% dưới dạng mã thông báo GYMNET.",
    "(effective as of 28.02.2024)": "(có hiệu lực kể từ ngày 28/02/2024)",
    "Free Product Autoship": "Tự động vận chuyển sản phẩm miễn phí",
    "10% Binary Commission": "Hoa hồng nhị phân 10%",
    "Enjoy More, Benefit More!": "Tận hưởng nhiều hơn, hưởng lợi nhiều hơn!",
    "To qualify for Binary Commissions, you must have a new member with at least $100 on your right team and another on your left team.": "Để đủ điều kiện nhận Hoa hồng nhị phân, bạn phải có một thành viên mới có ít nhất 100 USD ở nhóm bên phải và một thành viên khác ở nhóm bên trái của bạn.",
    "Buy products for at least $100 within 30 days to receive these benefits.": "Mua sản phẩm với giá ít nhất <span>$100</span> trong vòng <span>30 ngày</span> để nhận được những lợi ích này.",
    "Getting started": "Bắt đầu",
    "Confirm your email to continue": "Xác nhận email của bạn để tiếp tục",
    "Resend confirmation email": "Gửi lại email xác nhận",
    "Super Bundle Special Discount Expires In": "Giảm giá đặc biệt Super Bundle hết hạn vào",
    "Done": "Xong",
    "Get your bundle": "Nhận gói của bạn",
    "Gain Fresh Perspectives and Exclusive Benefits": "Có được những góc nhìn mới mẻ và những lợi ích độc quyền",
    "Super Bundle R8": "Siêu Gói R8",
    "Super Bundle R17": "Siêu Gói R17",
    "Starter Bundle": "Gói khởi đầu",
    "Travel": "Khuyến mãi du lịch",
    "Cash Promo": "Khuyến mãi tiền mặt",
    "Monthly Product Autoship is required to be an Affiliate Partner and earn commissions.": "Autoship sản phẩm hàng tháng phải là Đối tác liên kết và kiếm tiền hoa hồng.",
    "Affiliate": "liên kết",
    "Recommended": "Khuyến khích",
    "Binary Commissions based on the rank": "Hoa hồng nhị phân dựa trên thứ hạng",
    "Customer": "Khách hàng",
    "NFT Turbo": "NFT Turbo",
    "Purchase Digital Land": "Mua đất kỹ thuật số",
    "Participate in Partnership Deals": "Tham gia vào các giao dịch hợp tác",
    "Receive minting Rewards": "Nhận phần thưởng đúc tiền",
    "Up to": "Lên đến {n}%",
    "Weekly Distribution": "Phân phối hàng tuần",
    "A parcel with minting power will generate rewards in Filecoin tokens, which will be distributed every Monday.": "Một bưu kiện có khả năng đúc tiền sẽ tạo ra phần thưởng bằng mã thông báo Filecoin, sẽ được phân phối vào thứ Hai hàng tuần.",
    "It seems that a some request is already pending. Please check your wallet app or extension and try again.": "Có vẻ như một số yêu cầu đang chờ xử lý. Vui lòng kiểm tra ứng dụng hoặc tiện ích ví của bạn và thử lại.",
    "Add miner NFT to wallet": "Thêm Miner NFT vào ví",
    "Go to My NFTs": "Đi tới NFT của tôi",
    "Thank you for your participation": "Cảm ơn bạn vì sự tham gia của bạn",
    "No owner": "Không có chủ sở hữu",
    "Ownership of this bundle is limited to one per user. Please purchase for yourself and for those who do not have it.": "Quyền sở hữu gói này được giới hạn một gói cho mỗi người dùng. Hãy mua cho chính mình và cho những người chưa có.",
    "Buy parcels with minting power and generate income.": "Mua bưu kiện với sức mạnh đúc tiền và tạo thu nhập.",
    // Mystery box
    "Limited time offer": "Ưu đãi trong thời gian có hạn",
    "See Options": "Xem Tùy chọn",
    "Please select one of the currencies": "Vui lòng chọn một trong các loại tiền tệ",
    "Current balance": "Số dư hiện tại",
    "Try your luck": "Thử vận ​​may của bạn",
    "Offer ends in": "Ưu đãi kết thúc sau",
    "Parcel and Dual Miner": "Lô Và Công cụ khai thác kép",
    "Mystery Box": "Chiếc hộp bí ẩn",
    "Try your luck and win incredible Bundles, Parcels and Dual Miners to boost your earnings!": "Hãy thử vận may của bạn và giành được các Gói, Bưu kiện và Công cụ khai thác kép đáng kinh ngạc để tăng thu nhập của bạn!",
    "from MIN to MAX": "từ <b>{min}</b> đến <b>{max}</b>",
    "Choose a Box and Discover Rewards": "Chọn một hộp và khám phá phần thưởng",
    "Prizes you can win": "Giải thưởng bạn có thể giành được",
    "Item": "Mục",
    "Chance": "Cơ hội",
    "Promos": "Khuyến mãi",
    "Mystery Box results": "Kết quả hộp bí ẩn",
    "Total reward value": "Tổng giá trị phần thưởng",
    "My Rewards": "Giải thưởng của tôi",
    "Rewards Community Claimed": "Cộng đồng đã nhận được phần thưởng",
    "Hero image": "Hình ảnh anh hùng",
    "No rewards yet": "Chưa có phần thưởng",
    "No winners yet": "Chưa có người chiến thắng",
    "While no lucky participants have claimed their prizes yet, the excitement continues to build.": "Mặc dù chưa có người tham gia may mắn nào nhận được giải thưởng của họ nhưng sự phấn khích vẫn tiếp tục tăng lên.",
    "Mystery Box is Here!": "Chiếc hộp bí ẩn ở đây!",
    "Be the First to Discover Mystery Rewards": "Hãy là người đầu tiên khám phá phần thưởng bí ẩn",
    "Select Balance": "Chọn số dư",
    "Current Balance": "Số dư hiện tại",
    "Opening Mystery Box": "Mở hộp bí ẩn",
    "Oops, not this time 😔": "Rất tiếc, không phải lần này 😔",
    "Luck is on your side. Enjoy your prize!": "May mắn đang đứng về phía bạn. Tận hưởng giải thưởng của bạn!",
    "The more you try, the better your chances of winning. Keep going!": "Bạn càng cố gắng thì cơ hội chiến thắng càng cao. Tiếp tục đi!",
    "Try another box": "Thử hộp khác",
    "Go to My Products": "Đi tới Sản phẩm của tôi",
    "See promo results": "Xem kết quả khuyến mãi",
    "Payment enabled": "Đã kích hoạt thanh toán",
    "Please select the wallet that is attached to your account.": "Vui lòng chọn ví được đính kèm với tài khoản của bạn.",
    "Explore rewards": "Khám phá phần thưởng",
    "View rewards claimed by you and the community": "Xem phần thưởng mà bạn và cộng đồng đã nhận",
    "Open a Mystery Box to get rewards starting from Parcels to Super bundles.": "Mở hộp Bí ẩn để nhận phần thưởng bắt đầu từ gói Bưu kiện đến gói Siêu.",
    "Insufficient funds, please choose another box.": "Không đủ tiền, vui lòng chọn ô khác.",
    "Blockchain error": "Lỗi chuỗi khối",
    "Rewards claimed": "Đã nhận được phần thưởng",
    "We have encountered difficulties while processing the result of the reward. Please contact our support team for prompt assistance.": "Chúng tôi đã gặp khó khăn trong khi xử lý kết quả phần thưởng. Hãy liên hệ với nhóm hỗ trợ của chúng tôi để được hỗ trợ nhanh chóng.",
    "Boxes opened": "Hộp đã mở",
    // Mystery box

    // Partnership details
    "About": "Về",
    "Offer": "Lời đề nghị",
    "Social Media": "Truyền thông xã hội",
    "Additional Content": "Nội dung bổ sung",
    "Photo": "hình chụp",
    "Video": "Băng hình",
    // Partnership details

    // Miner Rewards
    "Single Miners": "Thợ mỏ đơn lẻ",
    "price today": "giá hôm nay",
    "Estimated daily rewards": "Phần thưởng ước tính hàng ngày",
    "day": "ngày",
    "day at current rate": "ngày theo tỷ giá hiện tại",
    "Estimated rewards until 2025": "Phần thưởng ước tính đến năm 2025",
    "at current rate": "ở mức hiện tại",
    "Estimated rewards from today to December 1, 2025. It may change based on your shares in the pool.": "Phần thưởng ước tính từ hôm nay đến ngày 1 tháng 12 năm 2025. Nó có thể thay đổi dựa trên cổ phần của bạn trong nhóm.",
    "Expected rewards calculator": "Máy tính phần thưởng dự kiến",
    "price": "giá ",
    "My total GYMNET value in USD": "Tổng giá trị GYMNET của tôi bằng USD",
    "claimed": "đã tuyên bố",
    "My Balances": "Số dư của tôi",
    "Tokens to be burned": "Token sẽ bị đốt",
    "You dont have any Claimed or Pending GYMNET rewards.": "Bạn không có bất kỳ phần thưởng GYMNET nào đã được xác nhận hoặc đang chờ xử lý.",
    "Claim now": "Yêu cầu ngay bây giờ",
    "No claimed GYMNET rewards are found.": "Không tìm thấy phần thưởng GYMNET được yêu cầu.",
    // Miner Rewards

    "Please note that the leg with the lesser amount of points is called weak leg and the one with the higher amount of points is called strong or power leg, and it can change over time based on which leg the most turnover is generated.": "Xin lưu ý rằng chặng có số điểm ít hơn được gọi là \"chân yếu\" và chặng có số điểm cao hơn được gọi là \"chân mạnh\" hoặc \"chân mạnh\" và nó có thể thay đổi theo thời gian tùy theo chặng nào có doanh thu nhiều nhất được tạo ra.",
    "Mystery Box price": "Giá hộp bí ẩn",

    "Registration Date": "Ngày đăng kí",
    "First Purchase Date": "Ngày mua đầu tiên",
    "Product Autoship Valid Until": "Sản phẩm Autoship có giá trị đến khi",

    // Suspicous activity
    "You have one attempt left": "Bạn còn một lần thử. Sau đó, các lần thử của bạn đối với tài khoản này sẽ tạm thời bị hạn chế.",
    "You have no attempts left": "Bạn không còn nỗ lực nào cho tài khoản này. Vui lòng thử lại sau {minutesText} <b>phút</b> hoặc đặt lại mật khẩu của bạn.",
    "Sorry, you’ve been blocked": "Xin lỗi, bạn đã bị chặn",
    "Your IP address has been temporarily blocked for security reasons.": "Địa chỉ IP của bạn đã tạm thời bị chặn vì lý do bảo mật. Vui lòng đợi, bạn sẽ sớm được tự động chuyển hướng.",
    "If you believe this was a mistake, please contact us through our Customer Support.": "Nếu bạn cho rằng đây là một sự nhầm lẫn, vui lòng liên hệ với chúng tôi qua <a href=\"mailto:support@gymstreet.io?subject=Suspicious Activity&body=I am blocked due to suspicious activity, but I think It's a mistake\" class=\"customer-support-button\">Hỗ trợ khách hàng.</a>",
    // Instant commissions
    "Own Deposit": "Tiền gửi riêng",
    "years ago": "những năm trước",
    "Possible only for usernames in your binary team!": "Chỉ khả dụng cho người dùng trong nhóm nhị phân của bạn!",
    "Rank Achievement Bonus": "Tiền thưởng thành tích xếp hạng",
    "Rank achievement bonus eligibility is based on rank achieved through qualifications.": "Tính đủ điều kiện của phần thưởng thành tích xếp hạng dựa trên thứ hạng đạt được thông qua trình độ chuyên môn.",
    "Bonuses": "Tiền thưởng",
    "Bonuses are paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET tokens": "Tiền thưởng được thanh toán theo tỷ lệ 70/30, với 70% ở dạng USDT và 30% ở dạng token GYMNET",
    "Rank current": "Xếp hạng {n}",
    "You will receive the bonus after the incentive is over, and you can view it in the 'My Wallet' section.": "Bạn sẽ nhận được tiền thưởng sau khi Chương trình khuyến mãi kết thúc, và bạn có thể xem nó trong phần 'Ví của tôi'.",
    "You must have at least RANK partners, with at least one in a different leg.": "Bạn phải có ít nhất {rank} đối tác, với ít nhất 1 đối tác ở nhánh khác.",
    "Additional Assets": "Tài sản bổ sung",
    "Back to My wallet": "Quay lại Ví của tôi",
    "Bonus points": "Điểm thưởng",
    "Fee": "Phí",
    "Bonus Details": "Chi tiết thưởng",
    "Details / weekly cap": "Chi tiết / hạn mức hàng tuần",
    "User / amount": "Người dùng / số lượng",
    "Extended Ref Link": "Liên kết giới thiệu mở rộng",
    "Leadership": "Lãnh đạo",
    "Travel Incentive": "Phần thưởng du lịch",
    "Unlock the full potential of your Filecoin rewards as they come to life following your purchase of any of our special bundles. Once your Filecoin Balance is available, you gain access to an overview of your total rewards, received rewards, and expected rewards. Furthermore, a countdown timer showcases the next rewards distribution, ensuring you never miss out on the rewards.": "Mở khóa phần thưởng Filecoin của bạn khi chúng trở nên có hiệu lực bằng việc mua bất kỳ bộ sản phẩm Bundles nào của chúng tôi. Khi Số dư Filecoin của bạn xuất hiện, bạn có quyền truy cập tổng quan về tổng số phần thưởng, phần thưởng đã nhận và phần thưởng dự kiến của bạn. Hơn nữa, chúng tôi có một bộ đếm hiển thị lịch phân phối điểm thưởng tiếp theo, đảm bảo bạn không bao giờ bỏ lỡ phần thưởng.",
    "is left to qualify": "${n} còn lại để đủ điều kiện",
    "Check transactions": "Kiểm tra giao dịch",
    "Direct partner own purchases": "Các giao dịch mua sắm riêng của đối tác trực tiếp",
    "Each of your direct partners on the left and right legs needs to generate a turnover value of $100.00.": "Mỗi đối tác trực tiếp của bạn ở nhánh trái và nhánh phải cần tạo ra một giá trị doanh số bán hàng là $100.00.",
    "Participation to GYMSTREETs Elite Vote": "Tham gia Bỏ phiếu Đặc quyền của GYMSTREET",
    "Prioritization ends in": "Ưu tiên kết thúc trong",
    "Start Prioritizing": "Bắt đầu Ưu tiên hóa",
    "Your changes have been saved": "Thay đổi của bạn đã được lưu",
    "features are still remaining": "<b>{n} tính năng</b> vẫn còn lại",
    "feature is still remaining": "<b>{n} tính năng</b> vẫn còn lại",
    "All prioritized": "Tất cả đã được ưu tiên",
    "GYMSTREETs Elite Vote Statistics": "Thống kê bình chọn ưu tú của GYMSTREET",
    "Just NUM features to go! Complete your prioritization to help us shape community's vision.": "Chỉ còn <b>{countText}</b> để hoàn thành! Hoàn tất việc ưu tiên hóa của bạn để giúp chúng tôi hình thành tầm nhìn của cộng đồng.",
    "You've prioritized the features. Please review your choices and hit Submit to finalize.": "Bạn đã ưu tiên hóa các tính năng. Vui lòng xem xét các lựa chọn của bạn và nhấn <b>“Gửi”</b> để hoàn thành.",
    "Back to All Features": "Quay lại Tất cả các tính năng",
    "Changes saved": "Thay đổi đã được lưu",
    "No changes": "Không có thay đổi",
    "Submit": "Gửi",
    "priority": "ưu tiên",
    "Set priority": "Đặt ưu tiên",
    "Feature details": "Chi tiết tính năng",
    "Select a feature to see more info": "Chọn một tính năng để xem thêm thông tin",
    "Prioritization Submitted": "Ưu tiên đã được gửi",
    "The results will be available after the voting period ends. You'll be able to change your priorities until then.": "Kết quả sẽ được công bố sau khi kết thúc giai đoạn bỏ phiếu. Bạn có thể thay đổi ưu tiên của mình cho đến khi đó.",
    "Go to Career Status page": "Đến trang Trạng thái Sự nghiệp",
    "Keep Prioritizing": "Tiếp tục Ưu tiên hóa",
    "Continue Prioritizing": "Tiếp tục Ưu tiên hóa",
    "Review and Submit": "Xem xét và Gửi",
    "Thinking of reprioritizing?": "Có ý định ưu tiên hóa lại?",
    "You've already submitted your priorities. If you want to reprioritize them, please make sure you hit Resubmit.": "Bạn đã gửi ưu tiên của mình rồi. Nếu bạn muốn thay đổi, hãy đảm bảo bạn nhấn <b>“Gửi lại”</b>.",
    "Got it": "Hiểu rồi",
    "Resubmit": "Gửi lại",
    "Priorities submitted": "Ưu tiên đã được gửi",
    "Submitted": "Đã gửi",
    "View Statistics": "Xem Thống kê",
    "Continue Prioritization": "Tiếp tục Ưu tiên hóa",
    "Reprioritize": "Ưu tiên hóa lại",
    "Start Prioritization": "Bắt đầu Ưu tiên hóa",
    "features": "các tính năng",
    "feature": "tính năng",
    "GYMSTREET Elite Vote": "Bỏ phiếu Đặc quyền của GYMSTREET",
    "Thanks for submitting! You can still reprioritize and resubmit your choices until the voting period ends.": "Cảm ơn bạn đã gửi! Bạn vẫn có thể thay đổi và gửi lại lựa chọn của mình cho đến khi kết thúc giai đoạn bỏ phiếu.",
    "Youre not done yet. Continue prioritizing the upcoming features and submit your final choices.": "Bạn chưa hoàn tất. Tiếp tục ưu tiên hóa các tính năng sắp tới và gửi lựa chọn cuối cùng của bạn.",
    "Exclusive for Platin and higher rank members. Prioritize the features you want to see next on GYMSTREET.": "Dành riêng cho thành viên hạng Platin và cao hơn. Ưu tiên hóa các tính năng bạn muốn thấy tiếp theo trên GYMSTREET.",
    "View rules": "Xem quy tắc",
    "Prioritization has ended. See the community's vote results.": "Ưu tiên hóa đã kết thúc. Xem kết quả bỏ phiếu của cộng đồng.",
    "Stay tuned": "Hãy theo dõi",
    "See current community prioritization statistics below. In 7 days, we'll also announce our decision right here, revealing the selected new features and their estimated release dates.": "Xem thống kê ưu tiên hiện tại của cộng đồng dưới đây. Trong 7 ngày, chúng tôi cũng sẽ công bố quyết định của mình ngay tại đây, tiết lộ các tính năng mới được chọn và ngày ra mắt ước tính của chúng.",
    "Terms and Conditions for GYMSTREETs Elite Vote": "Điều khoản và Điều kiện cho Bỏ phiếu Đặc quyền của GYMSTREET",

    "The period for earning mining rewards in BITOPEX tokens has officially concluded. Thanks for your participation. Enjoy your BPX earnings.": "Thời gian kiếm phần thưởng khai thác bằng token BITOPEX đã chính thức kết thúc. Cảm ơn sự tham gia của bạn. Tận hưởng thu nhập BPX của bạn.",
    "We're enhancing our claim feature, so it will be offline, for the next {hours} hours. Thank you and stay tuned!": "Chúng tôi đang nâng cao tính năng xác nhận quyền sở hữu của mình, vì vậy tính năng này sẽ ngoại tuyến trong {hours} giờ tới. Cảm ơn bạn và hãy theo dõi!",
    "Eligibility for a one-time 10% commission, based on the total turnover generated from direct sales, requires referring team members and achieving a turnover of $5,000 or more in direct sales.": "Để đủ điều kiện nhận hoa hồng 10% một lần, dựa trên tổng doanh thu được tạo ra từ việc bán hàng trực tiếp, yêu cầu phải giới thiệu các thành viên trong nhóm và đạt được doanh thu từ 5.000 USD trở lên trong bán hàng trực tiếp.",


    "Turnover Incentive": "Khuyến khích doanh thu",
    "Available For Limited time only": "Chỉ có sẵn trong <b>thời gian có hạn</b>",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving $20,000 in direct sales.": "Xin lưu ý rằng bạn đủ điều kiện nhận Gói R8 miễn phí khi đạt doanh thu trực tiếp 20.000 USD.",
    "Member": "Affiliate",
    "Trainee": "Trainee",
    "Rookie": "Rookie",
    "Silver": "Silver",
    "Gold": "Gold",
    "Platinum": "Platinum",
    "Double Platinum": "Double Platinum",
    "Diamond": "Diamond",
    "Double Diamond": "Double Diamond",
    "Red Diamond": "Red Diamond",
    "White Diamond": "White Diamond",
    "Crown Diamond": "Crown Diamond",
    "Platin": "Platinum",
    "Double Platin": "Double Platinum",
    "Single Pool": "Single Pool",
    "Explorer": "Explorer",
    "Mastery": "Mastery",
    "Features expected timeframes revealed": "Các tính năng được tiết lộ về khung thời gian dự kiến",
    "Find estimated timelines for features prioritized by our community and aligned with our strategy.": "Tìm các mốc thời gian ước tính cho các tính năng được cộng đồng ưu tiên và phù hợp với chiến lược của chúng tôi.",
    "Estimated timeframe": "Khoảng thời gian dự kiến",
    "Undefined": "Không xác định",
    "No Longer Valid": "Không còn giá trị",
    "Statistics": "Số liệu thống kê",
    "Minting Rewards": "Phần thưởng đúc tiền",
    "Please check transactions if your {asset} balance is not visible": "Vui lòng kiểm tra giao dịch nếu số dư {asset} của bạn không hiển thị",
    "In order to send {asset} you need to select {chain} network and wallet that is connected to your account.": "Để gửi {asset}, bạn cần chọn mạng {chain} và ví được kết nối với tài khoản của bạn.",
    "Cash bonus eligibility is based on rank achieved through qualifications. Participants qualifying with over 50% before March 4th, 2024, will not receive the bonus.": "Khả năng đủ điều kiện thưởng tiền mặt dựa trên cấp bậc đạt được thông qua trình độ chuyên môn. Những người tham gia đủ điều kiện đạt trên 50% trước ngày 4 tháng 3 năm 2024 sẽ không nhận được tiền thưởng.",
    "Please note that you are eligible to receive a complimentary R8 Bundle upon achieving 100k in team purchases.": "Xin lưu ý rằng bạn đủ điều kiện nhận Gói R8 miễn phí khi đạt được 100 nghìn khi mua theo nhóm.",

    "Convert": "Chuyển thành",
    "Convert your unused miners into hashpower to earn rewards in Gymnet and Filecoin": "Chuyển đổi các công cụ khai thác không sử dụng của bạn thành sức mạnh băm để kiếm phần thưởng trong Gymnet và Filecoin",
    "The 50% Turnover Rule is applied to this incentive": "Quy tắc Doanh thu 50% được áp dụng cho ưu đãi này",

    "Super Bundle Rank Benefits": "Lợi ích xếp hạng gói siêu",
    "Matching bonus up to NUM levels": "Phần thưởng phù hợp lên đến cấp {num}",
    "Up to NUM levels": "Lên tới {num} cấp độ",
    "Left leg partner": "Đối tác chân trái",
    "Right leg partner": "Đối tác chân phải",
    "Commission Activation Requirements": "Yêu cầu kích hoạt hoa hồng",
    "Up to 1 level": "Lên tới 1 cấp độ",
    "Bonus is paid out following a 70/30 ratio, with 70% in USDT and 30% in GYMNET": "Tiền thưởng được trả theo tỷ lệ 70/30, với 70% bằng USDT và 30% bằng GYMNET",
    "Current Turnover": "Doanh thu hiện tại",
    "You will receive the bonus once the incentive period is over and it will be deposited directly into your wallet.": "Bạn sẽ nhận được tiền thưởng sau khi thời gian khuyến khích kết thúc và tiền thưởng sẽ được gửi trực tiếp vào ví của bạn",
    "Purchase Amount": "Số tiền mua",
    "Spring": "Mùa xuân",
    "Spring Incentive": "Ưu đãi mùa xuân",
    "Commission Earned": "Hoa hồng kiếm được: <b>${n}</b>",
    "Bonus Earned": "Tiền thưởng đã kiếm được: <b>${n}</b>",
    "Earn 1 PAP point for every dollar spent on purchases using a utility balance or when you purchase a product autoship.": "Kiếm 1 điểm PAP cho mỗi đô la chi tiêu khi mua hàng bằng cách sử dụng số dư tiện ích hoặc khi bạn mua sản phẩm tự động vận chuyển.",
    "Deposit to Single pool": "Gửi tiền vào Single pool",
    "Max": "Tối đa",
    "Received": "Đã nhận",
    "About voting rights": "Về quyền biểu quyết",
    "Your first deposit must be over $25": "Tiền gửi đầu tiên của bạn phải từ $25 trở lên",
    "Staking period ends": "Thời gian staking kết thúc",
    "December": "Tháng 12",
    "Deposit & Stake": "Gửi tiền & stake",
    "Continue to KYC": "Tiếp tục để KYC",
    "KYC Verification": "Xác minh KYC",
    "Step": "Bước {n}",
    "Upload ID": "Tải ID lên",
    "Male": "Nam",
    "Female": "Nữ",
    "Gender": "Giới tính",
    "ID Type is required": "Thông tin loại ID là bắt buộc",
    "ID Document Language is required": "Thông tin ngôn ngữ trên ID là bắt buộc",
    "ID Expiration Date is required": "Thông tin thời hạn ID là bắt buộc",
    "ID Issue Date is required": "Thông tin ngày cấp ID là bắt buộc",
    "State must be at least 3 characters": "Bang phải có ít nhất 3 ký tự",
    "ID Type": "Loại ID",
    "National Identity Card": "Chứng minh nhân dân",
    "Driver's License": "Bằng lái xe",
    "Passport": "Hộ chiếu",
    "ID Document Language": "Ngôn ngữ trên ID",
    "ID Number be at least 3 characters": "Số ID phải có ít nhất 3 ký tự",
    "ID Issue Date": "Ngày cấp ID",
    "ID Expiration Date": "Ngày hết hạn trên ID",
    "You need to upload a photo (up to mb) of your document Please upload an image not a pdf.": "Bạn cần tải lên ảnh <span style='color: #1A6C99'>(tối đa {n}mb)</span> của tài liệu của bạn \n Vui lòng tải lên một hình ảnh không phải là pdf.",
    "File size exceeds 3.3 MB.": "Kích thước tệp vượt quá 3.3MB.",
    "Front side of ID": "Mặt trước của ID",
    "Back side of ID": "Mặt sau của ID",
    "Upload proof of address document": "Tải lên tài liệu chứng minh địa chỉ",
    "Upload proof of address, which must not be older than 75 days.": "Tải lên bằng chứng về địa chỉ, không được lâu hơn 75 ngày.",
    "Acceptable forms of proof of address include a credit card statement, utility bill, bank statement or bank letter.": "Các hình thức chứng minh địa chỉ được chấp nhận bao gồm bảng sao kê thẻ tín dụng, hóa đơn tiện ích, bảng sao kê ngân hàng hoặc thư gửi từ ngân hàng.",
    "Address Document Type is required": "Loại tài liệu chứng minh địa chỉ là bắt buộc",
    "Document Issued by is required": "Thông tin nơi cấp là bắt buộc",
    "Document Issued Date is required": "Thông tin ngày cấp là bắt buộc",
    "Address Document Type": "Loại tài liệu chứng minh địa chỉ",
    "Credit Card Statement": "Sao kê thẻ tín dụng",
    "Utility Bill": "Hoá đơn tiện ích",
    "Bank Statement": "Sao kê ngân hàng",
    "Bank Letter": "Thư gửi từ ngân hàng",
    "Document Issued Date": "Ngày cấp",
    "Please upload a legible document in image format not pdf, up to mb in size.": "Vui lòng tải lên tài liệu dễ đọc ở định dạng hình ảnh, không phải pdf, <span style='color: #1A6C99'>có kích thước tối đa {n} mb.</span>",
    "Submit for Verification": "Gửi để xác minh",
    "Your documents have been submitted for verification. Once verification has been approved, you can continue your purchase.": "Tài liệu của bạn đã được gửi để xác minh. Sau khi xác minh đã được phê duyệt, bạn có thể tiếp tục mua hàng.",
    "You need GYMNET to make a deposit in Single Pool": "Bạn cần <b>{n} GYMNET</b> để gửi tiền vào Single Pool",
    "Security Assurance": "Bảo mật\nĐảm bảo",
    "Global Accessibility": "Toàn cầu\nKhả năng truy cập",
    "Affiliate Earnings": "Liên kết\nThu nhập",
    "Effortless Integration": "Dễ dàng\nTích hợp",
    "Cards": "Thẻ",
    "Overview": "Tổng quan",
    "Information": "Thông tin",
    "Invoices": "Hoá đơn",
    "KYC Status": "Tình trạng KYC",
    "Try again": "Thử lại lần nữa",
    "Apply here": "Đăng ký ở đây",
    "Not verified": "Chưa xác minh",
    "Pending verification": "Đang chờ xác minh",
    "Rejected": "Bị từ chối",
    "Verified": "Đã xác minh",
    "Next service fee in": "Phí dịch vụ tiếp theo trong",
    "No active membership": "Trạng thái thành viên chưa được kích hoạt",
    "Card": "Thẻ",
    "Card number": "Số thẻ",
    "PIN Set Successfully": "Thiết lập PIN thành công",
    "Go to Dashboard": "Đến Bảng điều khiển",
    "4-digit PIN": "Mã PIN 4 ký tự",
    "Re-enter 4-digit PIN": "Điền lại mã PIN 4 ký tự",
    "Set PIN": "Thiết lập PIN",
    "Continue purchase": "Tiếp tục mua",
    "Your documents have been rejected from KYC provider.": "Tài liệu của bạn đã bị nhà cung cấp KYC từ chối.",
    "Please try again": "Vui lòng thử lại",
    "Your KYC is approved": "KYC của bạn đã được phê duyệt",
    "Your KYC is rejected": "KYC của bạn đã bị từ chối",
    "File is required": "Tập tin là bắt buộc",
    "Seamless Spending with GYMSTREET Cards": "Chi tiêu liền mạch với Thẻ GYMSTREET",
    "Our cards open a world of smooth transactions and rewarding opportunities for you.": "Thẻ của chúng tôi mở ra một thế giới giao dịch trơn tru và các cơ hội tuyệt vời cho bạn.",
    "Card Types Tailored to Your Needs": "Các loại thẻ phù hợp với nhu cầu của bạn",
    "Card design": "Thiết kế thẻ",
    "Card information": "Thông tin thẻ",
    "Your go-to choice for daily transactions and affiliate commissions, offering practical benefits and reliable service within GYMSTREET and beyond.": "Lựa chọn của bạn cho các giao dịch hàng ngày và hoa hồng tiếp thị liên kết, mang lại lợi ích thiết thực và dịch vụ đáng tin cậy trong GYMSTREET và hơn thế nữa.",
    "Elevate your financial experience with this premium option with expanded benefits, designed for those who seek luxury and exclusivity.": "Nâng cao trải nghiệm của bạn với tùy chọn cao cấp này với các lợi ích mở rộng, được thiết kế cho những người tìm kiếm sự sang trọng và độc quyền.",
    "Cards come in Bundles": "Các thẻ có trong các gói Bundle",
    "Explorer card and 2 parcels": "Thẻ Explorer và 2 mảnh đất bất động sản",
    "Mastery card and 3 parcels": "Thẻ Mastery và 3 mảnh đất bất động sản",
    "Getting Started with Your GYMSTREET Card Experience": "Bắt đầu với trải nghiệm thẻ GYMSTREET của bạn",
    "Start securely with our simple KYC process, ensuring a safe and personalized Card experience.": "Bắt đầu một cách an toàn với quy trình KYC đơn giản của chúng tôi, đảm bảo trải nghiệm Thẻ an toàn và được cá nhân hóa.",
    "Deposit Tokens": "Gửi tokens",
    "Stake the required GYMNET tokens - 500 for Explorer or 1500 for Mastery - to initiate your application.": "Đặt cược mã thông báo GYMNET tokens cần thiết - 500 cho Explorer hoặc 1500 cho Mastery - để bắt đầu ứng dụng của bạn.",
    "Order Your Card": "Đặt mua thẻ của bạn",
    "Make your final decision on card type and place your order buying one of the 2 card bundles.": "Đưa ra quyết định cuối cùng về loại thẻ và đặt hàng mua một trong 2 gói thẻ.",
    "Get Your Card": "Nhận thẻ của bạn",
    "Anticipate the arrival of your new financial companion, securely shipped and ready to integrate into your life.": "Thẻ, người bạn đồng hành mới cho các giao dịch tài chính của bạn, sẽ được vận chuyển an toàn và sẵn sàng để sử dụng.",
    "Use Your Card": "Dùng thẻ của bạn",
    "Begin making purchases with your card and start earning affiliate commissions and more.": "Bắt đầu mua hàng bằng thẻ của bạn, bắt đầu kiếm hoa hồng liên kết và hơn thế nữa.",
    "Card Operation Fees": "Phí hoạt động thẻ",
    "Fee Type": "Loại phí",
    "Both Cards (Explorer & Mastery)": "Cả hai thẻ (Explorer & Mastery)",
    "POS Transactions": "Giao dịch POS",
    "ATM Withdrawals": "Rút tiền ATM",
    "Foreign Currency Conversion": "Chuyển đổi ngoại tệ",
    "Card Replacement": "Thay thế thẻ",
    "Simple, Transparent Pricing": "Định giá đơn giản, minh bạch",
    "Monthly Membership Fee": "Phí thành viên hàng tháng",
    "Monthly Membership": "Thành viên hàng tháng",
    "Get Your Card Now": "Nhận thẻ của bạn ngay bây giờ",
    "Choose the card that best suits your lifestyle. With straightforward fees, a seamless onboarding process, and an 11-level deep affiliate commission reward system, these cards are your partner in financial empowerment.": "Hãy chọn loại thẻ phù hợp nhất với phong cách sống của bạn. Với các khoản phí đơn giản, quy trình giới thiệu liền mạch và hệ thống thưởng hoa hồng liên kết sâu 11 cấp, những thẻ này là đối tác của bạn trong việc trao quyền tài chính.",
    "How do I get a card?": "Làm thế nào để tôi nhận được thẻ?",
    "Start by filling out the KYC form on our site. After we verify you, you'll need to deposit tokens for the card you want in Single Pool: 500 for Explorer or 1500 for Mastery. Then, you can order your card.": "Bắt đầu bằng cách điền vào biểu mẫu KYC trên trang web của chúng tôi. Sau khi được xác minh, bạn sẽ cần gửi token cho thẻ bạn muốn trong Single Pool: 500 cho Explorer hoặc 1500 cho Mastery. Sau đó, bạn có thể đặt mua thẻ của mình.",
    "How many tokens do I need to deposit for each card?": "Tôi cần nạp bao nhiêu token cho mỗi thẻ?",
    "You need 500 tokens for the Explorer card and 1500 tokens for the Mastery card.": "Bạn cần 500 token cho thẻ Explorer và 1500 token cho thẻ Mastery.",
    "When will my card arrive?": "Khi nào tôi sẽ nhận được thẻ?",
    "After you order, your card will be sent out and should arrive quickly, so you can start using it soon.": "Sau khi bạn đặt hàng, thẻ của bạn sẽ được gửi đi và sẽ đến nơi nhanh chóng để bạn có thể bắt đầu sử dụng sớm.",
    "Can I earn affiliate commissions with my card?": "Tôi có thể kiếm hoa hồng liên kết bằng thẻ của mình không?",
    "Yes, activate your card, and you can begin earning through our 11-level deep affiliate program. Check our website for how the commissions work.": "Có, hãy kích hoạt thẻ của bạn và bạn có thể bắt đầu kiếm tiền thông qua chương trình liên kết sâu 11 cấp của chúng tôi. Kiểm tra trang web của chúng tôi để biết chính sách hoa hồng hoạt động như thế nào.",
    "Total Value At Time of Deposit": "Tổng Giá Trị Tại Thời Điểm Gửi Tiền",
    "Current Value": "Giá trị hiện tại",
    "My claimed GYMNET rewards": "Phần thưởng GYMNET được yêu cầu của tôi",
    "My pending GYMNET rewards": "Phần thưởng GYMNET đang chờ xử lý của tôi",
    "Deposit": "Tiền gửi",
    "Connected": "Đã kết nối",
    "No deposit fee for burn": "Không có phí đặt cọc khi đốt",
    "Min amount": "Số tiền tối thiểu",
    "Total GYMNET locked": "Tổng số GYMNET bị khóa",
    "APR": "APR",
    "Daily APR": "APR hàng ngày",
    "Total own claimed": "Tổng số yêu cầu riêng",
    "Reward per block": "Phần thưởng mỗi mảnh khối",
    "My Deposit": "Khoản tiền gửi của tôi",
    "Locked period until Dec 2027": "Thời gian khóa đến tháng 12 năm 2027",
    "Total Gymnet Deposit": "Tổng tiền gửi Gymnet",
    "Gymnet Rewards": "Phần thưởng Gymnet",
    "Total Gymnet earned": "Tổng số Gymnet kiếm được",
    "Active holdings": "Số tiền nắm giữ đang hoạt động",
    "With locking period": "Với thời gian khóa",
    "Withdrawn holdings": "Số tiền nắm giữ đã rút",
    "Locked": "Đã khoá",
    "Unlocked": "Đã mở khoá",
    "Deposit amount": "Số tiền gửi",
    "Claimed rewards": "Phần thưởng đã yêu ầu",
    "Start date": "Ngày bắt đầu",
    "End date": "Ngày kết thúc",
    "Withdraw": "Rút tiền",
    "First name": "Tên",
    "Last name": "Họ",
    "Address": "Địa chỉ",
    "State": "Thành phố/ Bang",
    "Phone number": "Số điện thoại",
    "Birth Date": "Ngày sinh",
    "Submit & Continue": "Gửi & tiếp tục",
    "ID Number": "Số ID",
    "ID Issued By": "Nơi cấp",
    "Proof of address document": "Giấy tờ chứng minh địa chỉ",
    "Proof of address document (optional)": "Giấy tờ chứng minh địa chỉ (tùy chọn)",
    "Select asset to auto swap and stake": "Chọn nội dung để tự động hoán đổi và đặt cọc (stake)",
    "Giving swap allowance": "Tặng trợ cấp hoán đổi",
    "Swap allowance given": "Trợ cấp hoán đổi đã tặng",
    "Swapping tokens to GYMNET": "Trao đổi token sang GYMNET",
    "Tokens swapped to GYMNET": "Token đã được đổi sang GYMNET",
    "Giving stake allowance": "Tặng phụ cấp stake",
    "Stake allowance given": "Phụ cấp stake được cấp",
    "Staking to single pool": "Đặt cọc (stake) vào single pool",
    "Staked to single pool": "Token đã được stake trong single pool",
    "1. Swap allowance (Only for USDT)": "1. Trợ cấp hoán đổi (Chỉ dành cho USDT)",
    "2. Swap to GYMNET": "2. Đổi sang GYMNET",
    "3. Stake allowance": "3. Trợ cấp đặt cọc (stake)",
    "4. Stake": "4. Stake",
    "Actual balance:": "Số dư thực tế:",
    "The transaction gas fee is reserved from the actual BNB balance.": "Phí gas giao dịch được bảo lưu từ số dư BNB thực tế.",
    "Insufficient balance to make a swap": "Số dư không đủ để thực hiện hoán đổi",
    "Transactions gas fee is considered in BNB available balance": "Phí giao dịch được tính bằng số dư BNB khả dụng",
    "We will ask for allowance and try to swap your tokens to GYMNET, and after, stake them in single pool.": "Chúng tôi sẽ yêu cầu trợ cấp và cố gắng trao đổi mã token của bạn sang GYMNET, sau đó đặt cọc (stake) chúng vào 1 single pool.",
    "Auto swap and stake": "Tự động hoán đổi và đặt cọc (stake)",
    "Continue transactions": "Tiếp tục giao dịch",
    "Pause": "Tạm ngừng",
    "Swap & Stake": "hoán đổi và đặt cọc (stake)",
    "Topup": "Nạp tiền",
    "Proceed anyways": "Vẫn tiếp tục",
    "Purchasing the card requires passing the KYC procedure.": "Việc mua thẻ yêu cầu phải vượt qua thủ tục KYC.",
    "You need to upload a photo of your document Please upload a png or jpeg, not a pdf.": "Bạn cần tải lên ảnh tài liệu của mình \n Vui lòng tải lên <span style='color: #1A6C99'>png hoặc jpeg, không phải pdf</span>.",
    "File size exceeds {n} MB.": "Kích thước tệp vượt quá {n} MB.",
    "Document Issued by": "Tài liệu do",
    "Please upload a legible document in png or jpeg format.": "Vui lòng tải lên tài liệu dễ đọc <span style='color: #1A6C99'>ở định dạng png hoặc jpeg.</span>",
    "You need more GYMNET to make a deposit in Single Pool": "Bạn cần <b>{n} thêm GYMNET</b> để đặt cọc vào Single Pool",
    "You need more USDT to purchase a bundle": "Bạn cần <b>{n} thêm USDT</b> để mua một gói",
    "Your account is now verified and you can continue your purchase of the bundle.": "Tài khoản của bạn hiện đã được xác minh và bạn có thể tiếp tục mua gói này.",
    "With Locking Period": "Với thời gian khóa",
    "Personal Information": "Thông tin cá nhân",
    "First name is required": "Tên là bắt buộc",
    "Last name is required": "Họ là bắt buộc",
    "Address is required": "Địa chỉ là bắt buộc",
    "Please enter your email address": "Hãy điền địa chỉ email của bạn",
    "Phone number is required": "Số điện thoại là bắt buộc",
    "Date of birth is required": "Ngày sinh là bắt buộc",
    "ID Number is required": "Số ID là bắt buộc",
    "ID Issued by is required": "ID do người cấp là bắt buộc",
    "Explorer Card": "ThẻExplorer",
    "Mastery Card": "ThẻMastery",
    "Monthly spending max": "Chi tiêu tối đa hàng tháng",
    "Monthly Balance limit": "Giới hạn số dư hàng tháng",
    "Go to Cards": "Đi đến Thẻ",
    "Contact Support": "Liên hệ hỗ trợ",
    "Deposit Fee": "Phí đặt cọc",
    "Switch to 'Utility' to view the price for each product with USDT & Utility. To partially pay for a product using Utility, you need to have enough USDT and Utility in your balance.": "Chuyển sang 'Utility' để xem giá từng sản phẩm bằng USDT & Utility. Để thanh toán một phần cho sản phẩm bằng Utility, bạn cần có đủ USDT và Utility trong số dư của mình.",
    "Deposit with locking period": "Tiền gửi có thời hạn khóa",
    "The state must be in the form of a two-letter abbreviation in uppercase.": "Trạng thái phải ở dạng viết tắt gồm hai chữ cái viết hoa.",
    "You need to upload a photo in JPEG or PNG format, not a PDF. The document can be in either English or Spanish.": "Bạn cần tải ảnh lên ở định dạng <span style='color: #1A6C99'>jpeg</span> hoặc <span style='color: #1A6C99'>png</span>, không phải ở dạng pdf. \nTài liệu có thể bằng <span style='color: #1A6C99'>tiếng Anh</span> hoặc <span style='color: #1A6C99'>tiếng Tây Ban Nha</span>.",
    "You need to upload a legible document in jpeg or png format, not a pdf. The document can be in either English or Spanish.": "Bạn cần tải lên tài liệu dễ đọc ở định dạng <span style='color: #1A6C99'>jpeg</span> hoặc <span style='color: #1A6C99'>png</span>, không phải bản pdf. \nTài liệu có thể bằng <span style='color: #1A6C99'>tiếng Anh</span> hoặc <span style='color: #1A6C99'>tiếng Tây Ban Nha</span>.",
    "Commissions are paid out using a 70/30 ratio, with 70% in stablecoins and 30% in GYMNET tokens.": "Hoa hồng được thanh toán theo tỷ lệ 70/30, với 70% dưới dạng stablecoin và 30% dưới dạng mã thông báo GYMNET.",
    "This policy is effective as of September 25, 2023.": "Chính sách này có hiệu lực kể từ ngày 25 tháng 9 năm 2023.",
    "Required turnover": "Doanh thu yêu cầu",
    "Switch to TOKEN to view the price for each product with USDT & TOKEN. To partially pay for a product using TOKEN, you need to have enough USDT and TOKEN in your balance.": "Chuyển sang '{TOKEN}' để xem giá từng sản phẩm bằng USDT & {TOKEN}. Để thanh toán một phần cho sản phẩm bằng {TOKEN}, bạn cần có đủ USDT và {TOKEN} trong số dư của mình.",
    "Alternatively, you can use your CURRENCY balance to cover the gas fee.": "Ngoài ra, bạn có thể sử dụng số dư {currency_yg} của mình để trả phí gas.",
    "Insufficient funds: to continue with payment use Utility or Gymnet balance": "Không đủ tiền: để tiếp tục thanh toán, hãy sử dụng số dư Utility hoặc Gymnet",
    "Use": "Sử dụng",
    "Use Utility or Gymnet": "Sử dụng Utility hoặc Gymnet",
    "Achieved PRICE turnover": "Đã đạt được doanh thu ${price}",
    "Your current milestone": "Cột mốc hiện tại của bạn",
    "Reward History": "Lịch sử phần thưởng",
    "Leading Direct Partner": "Đối tác trực tiếp hàng đầu",
    "Remaining Direct Partners": "Đối tác trực tiếp còn lại",
    "Bonus Split": "Chia tiền thưởng",
    "Monthly Countdown": "Đếm ngược hàng tháng",
    "No direct partners": "Không có đối tác trực tiếp",
    "To receive the reward, you must have an active product autoship.": "Để nhận được phần thưởng, bạn phải có chế độ tự động vận chuyển sản phẩm đang hoạt động.",
    "This incentive is subject to the 50% Turnover Rule.": "Ưu đãi này tuân theo Quy tắc doanh thu 50%.",
    "Income From Product Authoship": "Thu nhập từ bản quyền sản phẩm",
    "Your documents have been submitted for verification. The bank may take up to 3 business days to review your application. Once approved, you can continue with your purchase.": "Tài liệu của bạn đã được gửi để xác minh. Ngân hàng có thể mất tới 3 ngày làm việc để xem xét đơn đăng ký của bạn. Sau khi được phê duyệt, bạn có thể tiếp tục mua hàng.",
    "To purchase the card, you need to agree to the T&C and complete the KYC verification process.": "Để mua thẻ, bạn cần đồng ý với T&C và hoàn tất quy trình xác minh KYC.",
    "Only Latin letters are allowed": "Chỉ cho phép các chữ cái Latinh",
    "First name must be at least 3 characters": "Tên phải có ít nhất 3 ký tự",
    "First name should not exceed 10 characters": "Tên không được vượt quá 10 ký tự",
    "Should be only letters": "Chỉ nên có chữ cái",
    "Address must be at least 3 characters": "Địa chỉ phải có ít nhất 3 ký tự",
    "Only latin letters, numbers and special characters are allowed": "Chỉ cho phép chữ cái Latinh, số và ký tự đặc biệt",
    "Email is not valid": "Email không hợp lệ",
    "Zip must contain only numbers with min length of 3": "Zip chỉ được chứa các số có độ dài tối thiểu là 3",
    "The phone number must be at least 6 digits": "Số điện thoại phải có ít nhất 6 chữ số",
    "The phone number must not exceed 12 digits": "Số điện thoại không được vượt quá 12 chữ số",
    "Phone number should contain only numbers": "Số điện thoại chỉ được chứa số",
    "ID Number must have at least 3 characters": "Số CMND phải có ít nhất 3 ký tự",
    "ID Issued by must have at least 3 characters": "ID do người cấp phải có ít nhất 3 ký tự",
    "ID Issued by should not exceed 191 characters": "ID do người cấp phát không được vượt quá 191 ký tự",
    "Last name must be at least 3 characters": "Họ phải có ít nhất 3 ký tự",
    "State is required": "Tiểu bang là bắt buộc",
    "The incentive countdown starts at the beginning of each month and ends at the end of the month. If you do not qualify for the bonus within that month, you will need to start again in the next month.": "Việc đếm ngược ưu đãi bắt đầu vào đầu mỗi tháng và kết thúc vào cuối tháng. Nếu bạn không đủ điều kiện nhận tiền thưởng trong tháng đó, bạn sẽ cần phải bắt đầu lại vào tháng tiếp theo.",
    "Activated": "Đã kích hoạt",
    "Deposit & fee commission": "Tiền đặt cọc và hoa hồng phí",
    "Web3 wallet not connected. Please connect to proceed.": "Ví Web3 chưa được kết nối. Vui lòng kết nối để tiếp tục.",
    "Total investors profit": "Tổng lợi nhuận của nhà đầu tư",
    "Commissions from vault investors": "Hoa hồng từ nhà đầu tư vault",
    "Please Claim your rewards from to activate this section.": "Vui lòng nhận phần thưởng của bạn từ {n} để kích hoạt phần này.",
    "Or use Button Below": "Hoặc sử dụng Nút bên dưới",
    "Partner Activity": "Hoạt động của đối tác",
    "Pending Commissions": "Hoa hồng đang chờ xử lý",
    "Binary Turnover": "Doanh thu nhị phân",
    "Unilevel Turnover": "Doanh thu đơn cấp",
    "Right Direct Partners": "Đối tác trực tiếp phù hợp",
    "Left Direct Partners": "Đối tác trực tiếp còn lại",
    "50% Compensation:": "Bồi thường 50%:",
    "When you reach the next rank, you will receive 50% of the lost bonus from the previous rank.": "Khi lên cấp tiếp theo, bạn sẽ nhận được 50% số tiền thưởng đã mất của cấp trước đó.",
    "Please note that username can be changed only once in 6 months.": "Xin lưu ý rằng tên người dùng chỉ có thể được thay đổi một lần trong 6 tháng.",
    "Binary Turnover in weak leg": "Doanh thu nhị phân ở chân yếu",
    "Unilevel turnover requirement": "Yêu cầu doanh thu đơn cấp",
    "Connect a WEB3 wallet such as Metamask or Trustwallet and pay with {currency}. This process requires a small BNB fee ($0.30) to handle transaction costs!": "Kết nối ví WEB3 như Metamask hoặc Trustwallet và thanh toán bằng {currency}. Quá trình này yêu cầu một khoản phí BNB nhỏ ($0,30) để xử lý chi phí giao dịch!",
    "Use your account's wallet balance to purchase the product.": "Sử dụng số dư ví của tài khoản để mua sản phẩm.",
    "Seems like your crypto hasn't arrived yet": "Có vẻ như tiền điện tử của bạn vẫn chưa đến",
    "Wait more": "Đợi thêm",
    "Or topup with": "Hoặc nạp tiền bằng",
    "Connected to web3": "Đã kết nối với web3",
    "Reset": "Cài lại",
    "Top Up Successful": "Nạp tiền thành công",
    "Top Up Successful. Card balance will be updated soon": "Nạp tiền thành công. Số dư thẻ sẽ được cập nhật sớm",
    "Please activate your card and top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "Vui lòng kích hoạt thẻ của bạn và nạp số dư để trả phí hàng tháng. Nếu số dư không được cập nhật, thẻ của bạn sẽ bị vô hiệu hóa và bạn sẽ cần mua thẻ mới.",
    "PERCENTAGE allocation from Global Pool": "Phân bổ {perc}% từ Global Pool",
    "You need to reach rank RANK_NAME to participate in this pool.": "Bạn cần đạt thứ hạng \"{rank_name}\" để tham gia vào nhóm này.",
    "Global Pool": "Nhóm toàn cầu",
    "Next Distribution In:": "Phân phối tiếp theo trong:",
    "50% Rule": "Quy tắc 50%",
    "The maximum share each direct partner can contribute to the required turnover is up to 50%": "Tỷ lệ chia sẻ tối đa mà mỗi đối tác trực tiếp có thể đóng góp vào doanh thu yêu cầu lên tới 50%",
    "Sub Pools": "Bể phụ",
    "Congratulations! You have qualified for a share of the Global Pool.": "Chúc mừng! Bạn đã đủ điều kiện để được chia sẻ trong Global Pool.",
    "You will receive your share once the countdown is complete.": "Bạn sẽ nhận được phần của mình sau khi đếm ngược hoàn tất.",
    "Please top up the balance to cover the monthly fee. If the balance isn't updated, your card will be deactivated and you'll need to purchase a new one.": "Vui lòng nạp số dư để trang trải phí hàng tháng. Nếu số dư không được cập nhật, thẻ của bạn sẽ bị vô hiệu hóa và bạn sẽ cần mua thẻ mới.",
    "You need to deposit VALUE worth of GYMNET to upgrade your weekly binary cap": "Bạn cần gửi GYMNET trị giá {dvalue} để nâng cấp giới hạn nhị phân hàng tuần của mình",
    "Binary Max Weekly Payout": "Khoản thanh toán hàng tuần tối đa nhị phân",
    "Required Single Pool Deposit To Upgrade": "Yêu cầu đặt cọc một lần để nâng cấp",
    "Upgrade Binary Max Weekly Payout": "Nâng cấp khoản thanh toán hàng tuần tối đa nhị phân",
    "Weekly Cap Upgraded": "Đã nâng cấp giới hạn hàng tuần",
    "Your 8 months period for earning token rewards in Filecoin has officially concluded. Thank you for your participation. Enjoy your FIL earnings.": "Khoảng thời gian 8 tháng để nhận phần thưởng token Filecoin của bạn đã chính thức kết thúc. Cảm ơn sự tham gia của bạn. Hãy tận hưởng thu nhập FIL   của bạn nhé.",
    "As previously announced Filecoin will be replaced by Moonberg token, which will be distributed for a period of 24 months. The Moonberg launch is expected for August 2024 and the distribution will start one week after that.": "Như đã thông báo trước đó, Filecoin sẽ được thay thế bằng mã thông báo Moonberg, mã thông báo này sẽ được phân phối trong thời gian 24 tháng.  Việc ra mắt Moonberg dự kiến diễn ra     vào tháng 8 năm 2024 và việc phân phối sẽ bắt đầu sau đó một tuần.",
    "Username must not exceed 255 characters": "Tên người dùng không được vượt quá 255 ký tự",
    "Upgrade": "Nâng cấp",
    "Product Autoship Monthly": "Sản phẩm tự động giao hàng hàng tháng",
    "Product Autoship Yearly": "Sản phẩm tự động giao hàng hàng năm",
    "Product Owner": "Chủ sở hữu sản phẩm",
    "Myself": "Tôi",
    "Another user": "Người dùng khác",
    "Payment": "Thanh toán",
    "PAP Balance": "Số dư PAP",
    "Purchased By": "Người mua",
    "Purchased For": "Người mua cho",
    "Single parcel": "Một bưu kiện duy nhất",
    "Single miner": "Người khai thác duy nhất",
    "completed": "Hoàn thành",
    "pending": "Đang chờ",
    "expired": "Hết hạn",

    "Select {n} blockchain to send the transaction": "Chọn {n} blockchain để gửi giao dịch",
    "We recommend waiting to claim your rewards when the gas fee is lower than the amount you are claiming.": "Chúng tôi khuyên bạn nên đợi để nhận phần thưởng khi phí gas thấp hơn số tiền bạn yêu cầu.",
    "Total Value Of Purchases": "Tổng giá trị mua hàng",
    "The total value of all purchases excluding purchases related to Card Bundles, granted products and product autoship subscriptions.": "Tổng giá trị của tất cả các giao dịch mua hàng không bao gồm các giao dịch mua hàng liên quan đến Gói thẻ, sản phẩm được cấp và đăng ký tự động giao hàng sản phẩm.",
    "Total Receivable Tokens": "Tổng số mã thông báo phải thu",
    "The number of Moonberg tokens you will receive over the next 24 months.": "Số lượng mã thông báo Moonberg bạn sẽ nhận được trong 24 tháng tới.",
    "Realtime ROI": "ROI theo thời gian thực",
    "Current Return on Investment (ROI) based on the real-time price of the Moonberg token.": "Lợi tức đầu tư hiện tại (ROI) dựa trên giá theo thời gian thực của mã thông báo Moonberg.",
    "Token Calculation Breakdown": "Phân tích tính toán mã thông báo",
    "{n}% of your total purchase value is allocated for purchasing {asset} tokens": "{n}% tổng giá trị giao dịch mua hàng của bạn được phân bổ để mua mã thông báo {asset}",
    "Moonberg tokens will be distributed upon the official release of the Moonberg token.": "Mã thông báo Moonberg sẽ được phân phối khi mã thông báo Moonberg được phát hành chính thức.",
    "Moonberg Allocation": "Phân bổ Moonberg",
    "BTC Balance": "Số dư BTC",
    "The funds will be transferred via the BEP-20 BNB network.": "Tiền sẽ được chuyển qua mạng BEP-20 BNB.",
    "A bundle with minting power will generate BTC token rewards, which will be distributed every Monday.": "Một gói có sức mạnh đúc sẽ tạo ra phần thưởng token BTC, sẽ được phân phối vào mỗi thứ Hai.",
    "Weekly rewards expected": "Phần thưởng hàng tuần dự kiến",
    "Direct Sales Incentive": "Khuyến khích bán hàng trực tiếp",
    "You are about to activate your affiliate referral link.": "Bạn sắp kích hoạt liên kết giới thiệu liên kết của mình.",
    "Upon activation, a period of free Product Autoship will be initiated starting from the date of your registration.": "Sau khi kích hoạt, một khoảng thời gian Tự động giao hàng sản phẩm miễn phí sẽ được bắt đầu kể từ ngày bạn đăng ký.",
    "If you confirm the activation, a mandatory monthly Product Autoship will be required to be eligible to receive commissions.": "Nếu bạn xác nhận kích hoạt, bạn sẽ phải thực hiện Tự động giao hàng sản phẩm hàng tháng bắt buộc để đủ điều kiện nhận hoa hồng.",
    "Keep your product autoship active to maintain your commission eligibility for commission ranks Platinum and above.": "Duy trì chế độ tự động giao hàng cho sản phẩm của bạn để duy trì quyền hưởng hoa hồng cho cấp bậc hoa hồng Bạch kim trở lên.",
    "Buy Product Autoship": "Mua sản phẩm Tự động giao hàng",
    "Your current rank is achieved through qualification.": "Thứ hạng hiện tại của bạn đạt được thông qua trình độ.",
    "Your commission rank is determined by your actual rank or a super bundle purchase—whichever is higher will be considered.": "Thứ hạng hoa hồng của bạn được xác định bởi thứ hạng thực tế của bạn hoặc giao dịch mua siêu gói—thứ hạng nào cao hơn sẽ được xem xét.",
    "Your card has been deactivated due to an unpaid monthly subscription. For assistance or any questions, please reach out to our customer support team.": "Thẻ {card_name} của bạn đã bị vô hiệu hóa do chưa thanh toán phí đăng ký hàng tháng. Để được hỗ trợ hoặc có bất kỳ câu hỏi nào, vui lòng liên hệ với nhóm hỗ trợ khách hàng của chúng tôi.",
    
    "Subject": "Chủ đề",
    "topup": "Nạp tiền",
    "withdraw": "Rút tiền",
    "failed": "Thất bại",
    "3rd Anniversary": "Kỷ niệm 3 năm",
    "3rd Anniversary Promo": "Khuyến mãi kỷ niệm 3 năm",
    "Your current bonus amount": "Số tiền thưởng hiện tại của bạn",
    "All products are eligible for the incentive, except for Product Autoship. Rewards will be distributed on November 1, 2024, and the bonus will be deposited directly into your internal wallet.": "Tất cả các sản phẩm đều đủ điều kiện nhận ưu đãi, ngoại trừ Sản phẩm tự động giao hàng. Phần thưởng sẽ được phân phối vào ngày 1 tháng 11 năm 2024 và tiền thưởng sẽ được gửi trực tiếp vào ví nội bộ của bạn.",
























    // Affility translations
    "close": "Đóng",
    "cancel": "Huỷ",
    "yes_i_confirm": "Tôi xác nhận",
    "something_went_wrong_try_again": "Đã xảy ra lỗi. Vui lòng thử lại",
    "or": "Hoặc",
    "success": "Thành công",
    "ok": "OK",
    "retry": "Thử lại",
    "home": "Trang chủ",
    "log_in": "Đăng nhập",
    "join_now": "Tham gia ngay",
    "toggle_notifications": "Chuyển đổi thông báo",
    "active": "Tài khoản đang hoạt động",
    "inactive": "Tài khoản không hoạt động",
    "product_autoship": "Product Autoship",
    "balances": "Số dư",
    "contract_addresses": "Địa chỉ hợp đồng",
    "address_add_to_wallet_success": "Địa chỉ đã được thêm vào ví của bạn thành công",
    "language": "Ngôn ngữ",
    "choose_language": "Lựa chọn ngôn ngữ",
    "profile": "Hồ sơ",
    "settings": "Cài đặt",
    "learn": "Học",
    "help_center": "Trung tâm trợ giúp",
    "log_out": "Đăng xuất",
    "theme": "Chủ đề",
    "back": "Quay lại",
    "forward": "Phía trước",
    "previous": "Trước đó",
    "next": "Tiếp theo",
    "jump_to_top": "Nhảy lên đầu trang",
    "load_more": "Tải thêm",
    "proceed": "Thực hiện",
    "notifications": "Thông báo",
    "notifications_loading_error_description": "Đảm bảo thiết bị của bạn được kết nối với Internet hoặc thử khởi động lại bộ định tuyến hoặc modem.",
    "notifications_empty_title": "Danh sách thông báo đang trống",
    "notifications_empty_description": "Theo dõi tất cả các cập nhật và lời nhắc của bạn ở một nơi thuận tiện duy nhất, với tính năng thông báo của chúng tôi.",
    "email_verification_sent_title": "Xác thực email",
    "email_verification_sent_description": "Chúng tôi đã gửi 1 email kèm đường link đến hoàn tất thủ tục đăng ký đến {email}",
    "email_verification_required": "Cần phải xác minh email",
    "email_verification_confirm": "Vui lòng xác nhận email của bạn để tiếp tục",
    "email_verification_verify": "Xác minh email của bạn để tiếp tục",
    "new_email": "Email mới",
    "enter_new_email": "Nhập email mới",
    "change_email": "Đổi email",
    "resend_email": "Gửi lại email",
    "upgrade_to_affiliate": "Nâng cấp lên Affiliate",
    "become_affiliate": "Trở thành Affiliate",
    "upgrade_to_affiliate_description_free_autoship": "Bạn sắp kích hoạt link giới thiệu Affiliate của mình. Sau khi kích hoạt, một khoảng thời gian Product Autoshop miễn phí sẽ được bắt đầu, kể từ ngày bạn đăng ký.",
    "upgrade_to_affiliate_description_mandatory_autoship": "Bạn sắp kích hoạt link giới thiệu Affiliate của mình. Nếu bạn xác nhận kích hoạt, bạn sẽ phải chi trả phí Product Autoship hàng tháng để đủ điều kiện nhận hoa hồng.",
    "t&c": "Điều khoản & Điều kiện",
    "accept_t&c": "Chấp nhận và Đóng",
    "affiliate_t&c": "Điều khoản và điều kiện Affiliate",
    "cards_t&c": "Điều khoản & Điều kiện Thẻ",
    "i_have_read_and_accept_the_{prop}": "Tôi đã đọc và chấp nhận {prop}",
    "switch_language": "Đổi ngôn ngữ",
    "switch_theme": "Đổi chủ đề",
    "enjoy_more_benefit_more": "Tận hưởng nhiều hơn! Được hưởng nhiều lợi ích hơn!",
    "buy_products_at_least_${n}": "Mua sản phẩm ít nhất ${n}",
    "go_to_products": "Đến Sản phẩm",
    "free_autoship": "Giao hàng tự động miễn phí cho hạng Bạch kim trở lên",
    "{n}%_binary_commission": "{n}% Hoa hồng nhị phân",
    "rank": "Cấp bậc",
    "role": "Vai trò",
    "bundle": "Gói siêu cấp",
    "current_rank": "Cấp bậc hiện tại",
    "current_role": "Vai trò hiện tại",
    "customer": "Khách hàng",
    "get_your_bundle": "Mua siêu gói",
    "nft_turbo_expires_in": "Giảm giá đặc biệt cho gói siêu cấp sẽ hết hạn vào",
    "left_leg_link": "Link nhánh trái",
    "right_leg_link": "Link nhánh phải",
    "sponsor_id": "ID người giới thiệu",
    "total_income": "Tổng thu nhập",
    "current_cv_balance": "Số dư CV hiện tại",
    "progress_until_rank_{n}": "Tiến triển cho đến khi đạt hạng {n}",
    "to_reach_rank_{n}_you_need": "Để đạt được thứ hạng {n}, bạn cần",
    "nft_own_purchases": "Món hàng NFT của bạn",
    "direct_partner_own_purchases": "Món hàng đối tác trực tiếp mua",
    "team_turnover": "Doanh thu nhóm",
    "required_qualified_partners": "Điều kiện bắt buộc cho đối tác",
    "see_details": "Xem chi tiết",
    "soon": "Sớm thôi",
    "new": "Mới",
    "promos": "Khuyến mãi",
    "coming_soon": "Sắp ra mắt",
    "team_statistics": "Thống kê của nhóm",
    "all_time": "Mọi thời gian",
    "weekly": "Theo tuần",
    "monthly": "Theo tháng",
    "yearly": "Theo năm",
    "left_leg": "Nhánh trái",
    "right_leg": "Nhánh phải",
    "all_partners": "Mọi đối tác",
    "direct_partners": "Đối tác trực tiếp",
    "all_active_partners": "Tất cả đối tác đang hoạt động",
    "all_inactive_partners": "Tất cả đối tác đang hoạt động",
    "become_affiliate_partner": "Trở thành Đối tác Affiliate",
    "gain_fresh_perspectives": "Nhận được góc nhìn mới mẻ và lợi ích độc quyền.",
    "autoship_required_to_be_affiliate": "Phí Product Autoship hàng tháng là bắt buộc để trở thành Đối tác Affiliate và kiếm hoa hồng.",
    "for_limited_time_only": "Chỉ trong thời gian có hạn",
    "placement_in_unilevel": "Vị trí trong Unilevel",
    "placement_in_binary": "Vị trí trong nhị phân",
    "cv_points": "Điểm CV",
    "matching_bonuses": "Tiền thưởng Matching",
    "leadership_incentives": "Thưởng lãnh đạo",
    "autoship_incentive": "Thưởng Product Autoship",
    "binary_tree_statistics": "Thống kê cây nhị phân",
    "unilevel_tree_statistics": "Thống kê cây Unilevel",
    "active_partners": "Đối tác đang hoạt động",
    "inactive_partners": "Đối tác không hoạt động",
    "The minimum amount should be equal to or greater than NUM GYMNET": "Số tiền tối thiểu phải bằng hoặc lớn hơn {num} GYMNET",
    "upgrade_to_affiliate_success": "Bạn đã nâng cấp thành công lên Affiliate",
    "select_the_team": "Chọn nhóm",
    "new_users_will_be_placed_under_username_in_the_left_team": "Người dùng mới sẽ được đặt dưới {USERNAME} trong nhóm {LEG}.",
    "done": "Xong",
    "right": "phải",
    "left": "trái",
};
