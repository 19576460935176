import { render, staticRenderFns } from "./Desktop.vue?vue&type=template&id=7960aac8&scoped=true"
import script from "./Desktop.vue?vue&type=script&setup=true&lang=ts"
export * from "./Desktop.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Desktop.vue?vue&type=style&index=0&id=7960aac8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7960aac8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseOverlaySimple: require('/root/dashboard/core/components/base/overlay/Simple.vue').default,BaseSidebarSliding: require('/root/dashboard/core/components/base/sidebar/Sliding.vue').default,ComposedSelectMetaverse: require('/root/dashboard/core/components/composed/select/Metaverse.vue').default,ComposedCountdownAutoship: require('/root/dashboard/core/components/composed/countdown/Autoship.vue').default})
