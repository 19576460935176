import { render, staticRenderFns } from "./ProfileContent.vue?vue&type=template&id=fb1ec55a&scoped=true"
import script from "./ProfileContent.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProfileContent.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ProfileContent.vue?vue&type=style&index=0&id=fb1ec55a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb1ec55a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedCardProfile: require('/root/dashboard/core/components/composed/card/Profile.vue').default,BaseDividerSimple: require('/root/dashboard/core/components/base/divider/Simple.vue').default,ComposedMenuItemCollapsible: require('/root/dashboard/core/components/composed/menu-item/Collapsible.vue').default,ComposedMenuItemContractAddressesOpener: require('/root/dashboard/core/components/composed/menu-item/ContractAddressesOpener.vue').default,ComposedMenuItemThemeSwitcher: require('/root/dashboard/core/components/composed/menu-item/ThemeSwitcher.vue').default,ComposedMenuItemLanguagesOpener: require('/root/dashboard/core/components/composed/menu-item/LanguagesOpener.vue').default})
